import { fieldTypes } from '../../../../framework/formBuilder/utils';

export const layoutSchema = {
  layout: 'column',
  className: 'activity-form-wrapper',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: '',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'mechanismOfActions',
          size: 3,
          className: '',
        },
        {
          layout: 'drugDrugTypeClassifications',
          size: 3,
          className: '',
        },
        {
          layout: 'biologicalTargets',
          size: 3,
          className: '',
        },
        {
          layout: 'automaticTargetFamilies',
          size: 3,
          className: '',
        },
        {
          layout: 'manualTargetFamilies',
          size: 3,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      size: 12,
      className: '',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'deliveryRoutes',
          size: 3,
          className: '',
        },
        {
          layout: 'deliveryMediums',
          size: 3,
          className: '',
        },
        {
          layout: 'deliveryTechnologies',
          size: 3,
          className: '',
        },
        {
          layout: 'trialTroveDeliveryRoutes',
          size: 3,
          className: '',
        },
        {
          layout: 'trialTroveDeliveryMediums',
          size: 3,
          className: '',
        },
        {
          layout: 'trialTroveDeliveryTechnologies',
          size: 3,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      size: 12,
      className: '',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'trialTroveDeliveryRoutesFromChild',
          size: 3,
          className: '',
        },
        {
          layout: 'trialTroveDeliveryMediumsFromChild',
          size: 3,
          className: '',
        },
        {
          layout: 'trialTroveDeliveryTechnologiesFromChild',
          size: 3,
          className: '',
        },
      ],
    },
  ],
};

export const formSchema = {
  mechanismOfActions: {
    name: 'mechanismOfActions',
    type: fieldTypes.select_async,
    label: 'Mechanism Of Action',
    props: {
      isSearchable: true,
      isMulti: true,
      showSelectedData: true,
    },
  },
  drugDrugTypeClassifications: {
    name: 'drugDrugTypeClassifications',
    type: fieldTypes.select_tree_async,
    label: 'DRUG TYPE',
    props: {
      isSearchable: true,
      isMulti: true,
      showSelectedData: true,
      singleNodeSelectFlag: true,
      isMandatory: true,
    },
  },
  trialTroveDeliveryMediums: {
    name: 'trialTroveDeliveryMediums',
    type: fieldTypes.select_tag,
    label: 'TrialTrove Delivery Medium',
    options: [],
  },
  trialTroveDeliveryRoutes: {
    name: 'trialTroveDeliveryRoutes',
    type: fieldTypes.select_tag,
    label: 'TrialTrove Delivery Route',
    options: [],
  },
  trialTroveDeliveryTechnologies: {
    name: 'trialTroveDeliveryTechnologies',
    type: fieldTypes.select_tag,
    label: 'TrialTrove Delivery Technology',
    options: [],
  },
  trialTroveDeliveryMediumsFromChild: {
    name: 'trialTroveDeliveryMediumsFromChild',
    type: fieldTypes.select_tag,
    label: 'TrialTrove Delivery Medium',
    options: [],
    props: {
      isDisabled: true,
      isMulti: true,
    },
  },
  trialTroveDeliveryRoutesFromChild: {
    name: 'trialTroveDeliveryRoutesFromChild',
    type: fieldTypes.select_tag,
    label: 'TrialTrove Delivery Route',
    options: [],
    props: {
      isDisabled: true,
      isMulti: true,
    },
  },
  trialTroveDeliveryTechnologiesFromChild: {
    name: 'trialTroveDeliveryTechnologiesFromChild',
    type: fieldTypes.select_tag,
    label: 'TrialTrove Delivery Technology',
    options: [],
    props: {
      isDisabled: true,
      isMulti: true,
    },
  },
  deliveryMediums: {
    name: 'deliveryMediums',
    type: fieldTypes.select_tag,
    label: 'Delivery Medium',
    options: [],
    props: {
      isSearchable: true,
    },
  },
  deliveryRoutes: {
    name: 'deliveryRoutes',
    type: fieldTypes.select_tag,
    label: 'Delivery Route',
    options: [],
    props: {
      isSearchable: true,
    },
  },
  deliveryTechnologies: {
    name: 'deliveryTechnologies',
    type: fieldTypes.select_tag,
    label: 'Delivery Technology',
    options: [],
    props: {
      isSearchable: true,
    },
  },
  biologicalTargets: {
    name: 'biologicalTargets',
    type: fieldTypes.select_async,
    label: 'Biological Target',
    props: {
      isSearchable: true,
      showSelectedData: true,
      isMulti: true,
    },
  },
  automaticTargetFamilies: {
    name: 'automaticTargetFamilies',
    type: fieldTypes.select_async,
    label: 'Automatic Target Family',
    props: {
      isDisabled: true,
      showSelectedData: true,
      isMulti: true,
    },
  },
  manualTargetFamilies: {
    name: 'manualTargetFamilies',
    type: fieldTypes.select_async,
    label: 'Manual Target Family',
    props: {
      isSearchable: true,
      showSelectedData: true,
      isMulti: true,
    },
  },
};
export const validateMandatoryActivity = activity => {
  return !activity.therapeuticClasses || activity.therapeuticClasses.length === 0
    ? { error: true }
    : {};
};

export const validate = (
  activity,
  {
    names,
    summary,
    trialTrovePharmaProjectDrug,
    drugType,
    drugDrugTypeClassifications,
    productionStatusId,
    isPayloadMandatory,
  },
) => {
  let isCDExists = false;
  if (names && names.length > 0) {
    isCDExists =
      names.filter(name => name.descriptiveName.toLowerCase().includes('companion diagnostic'))
        .length > 0;
  }
  if (
    productionStatusId !== 5 &&
    drugDrugTypeClassifications &&
    !drugDrugTypeClassifications.length &&
    drugType.value == 'Trialtrove Only Drug'
  ) {
    return 'Please select drug type';
  }

  let isTCExists = false;
  if (activity.therapeuticClasses && activity.therapeuticClasses.length > 0) {
    isTCExists =
      activity.therapeuticClasses.filter(tc =>
        tc.therapeuticClass.description.toLowerCase().includes('companion diagnostic'),
      ).length > 0;
  }

  if (activity.manualTargetFamilies?.length > 0 && activity.automaticTargetFamilies?.length > 0) {
    let isSameTargetFamilies = activity.manualTargetFamilies.filter(manualTarget =>
      activity.automaticTargetFamilies.some(
        automaticTarget => manualTarget.targetFamily.id === automaticTarget.targetFamily.id,
      ),
    );
    if (isSameTargetFamilies.length) {
      return 'You can not set same target family as Automatic target family and Manual target family';
    }
  }

  if (
    trialTrovePharmaProjectDrug &&
    (!activity.mechanismOfActions || activity.mechanismOfActions.length === 0)
  ) {
    return 'A drug record should have mechanism of action.';
  } else if (
    !activity.therapeuticClasses ||
    (activity.therapeuticClasses.length === 0 && trialTrovePharmaProjectDrug)
  ) {
    return 'A drug record should have at least one therapautic class status.';
  } else if (drugType.value === 'Pharmaprojects Drug' && activity.biologicalTargets.length === 0) {
    return 'A drug record should have at least one biological targets';
  } else if (isCDExists && !isTCExists) {
    return 'As the Descriptive name contains ‘companion diagnostic’, it should be selected in Therapeutic class as well.';
  } else if (!isCDExists && isTCExists) {
    return 'As the Descriptive name does not contain ‘companion diagnostic’, it should not be selected in Therapeutic class.';
  } else if (
    isPayloadMandatory &&
    (!activity?.immunoconjugatePayload || activity?.immunoconjugatePayload?.length === 0)
  ) {
    return 'A drug record should have at least one Immunoconjugate Payload value';
  } else {
    // const result =
    //   activity.therapeuticClasses && summary.globalStatus
    //     ? activity.therapeuticClasses.filter(o => {
    //       return (
    //         o.therapeuticClassStatus.indicationRanking < summary.globalStatus.indicationRanking
    //       );
    //     })
    //     : [];
    // if (result.length > 0) {
    //   return 'Therapeutic class status should not be higher than the drug summary global Status';
    // }
  }

  return '';
};
