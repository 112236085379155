import { createAction } from '@reduxjs/toolkit';

// action definitions
const requirementRoot = 'requirement/tabs/';

export const setSelectedTabIndex = createAction(`${requirementRoot}setSelectedTabIndex`);
export const requirementNextFeatureSelection = createAction(`${requirementRoot}goToNextFeature`);
export const requirementFeatureSelection = createAction(`${requirementRoot}selected`);
export const requirementTabsUpdate = createAction(`${requirementRoot}tabsUpdate`);

export const getRequirementList = createAction(`${requirementRoot}getRequirementList`);
export const getRequirementListSuccess = createAction(
  `${requirementRoot}getRequirementListSuccess`,
);
export const setError = createAction(`${requirementRoot}error`);

export const deleteRequirement = createAction(`${requirementRoot}deleteRequirement`);
export const deleteRequirementSuccess = createAction(`${requirementRoot}deleteRequirementSuccess`);

export const saveRequirementDetails = createAction(`${requirementRoot}saveRequirementDetails`);
export const saveRequirementDetailsSuccess = createAction(
  `${requirementRoot}saveRequirementDetailsSuccess`,
);
export const getRequirementMasterData = createAction(`${requirementRoot}getRequirementMasterData`);
export const getRequirementMasterDataSuccess = createAction(
  `${requirementRoot}getRequirementMasterDataSuccess`,
);

export const getRequirementDetails = createAction(`${requirementRoot}getRequirementDetails`);
export const getRequirementDetailsSuccess = createAction(
  `${requirementRoot}getRequirementDetailsSuccess`,
);

export const moveOutofScopeRequirement = createAction(
  `${requirementRoot}moveOutofScopeRequirement`,
);
export const getStagedIdRequirement = createAction(`${requirementRoot}getStagedIdRequirement`);
export const moveOutofScopeRequirementSuccess = createAction(
  `${requirementRoot}moveOutofScopeRequirementSuccess`,
);
export const getStagedIdRequirementSuccess = createAction(
  `${requirementRoot}getStagedIdRequirementSuccess`,
);
export const resetStagedIdRequirement = createAction(`${requirementRoot}resetStagedIdRequirement`);

export const setReqErrorDetails = createAction(`${requirementRoot}serErrorDetails`);
export const setRequirementValidationStatus = createAction(
  `${requirementRoot}setRequirementValidationStatus`,
);
export const setRequirementUnSavedStatus = createAction(
  `${requirementRoot}setRequirementUnSavedStatus`,
);
export const setValidate = createAction(`${requirementRoot}setValidate`);
export const publishRequirement = createAction(`${requirementRoot}publishRequirement`);
export const publishRequirementSuccess = createAction(
  `${requirementRoot}publishRequirementSuccess`,
);

export const validateRequirement = createAction(`${requirementRoot}validateRegistry`);
export const validateRequirementSuccess = createAction(`${requirementRoot}validateRegistrySuccess`);
export const setTabNames = createAction(`${requirementRoot}setTabNames`);

export const getRequirementRecordChangeFieldsList = createAction(
  `${requirementRoot}getRequirementRecordChangeFieldsList`,
);
export const requirementRecordChangeFldsListSuccess = createAction(
  `${requirementRoot}requirementRecordChangeFldsListSuccess`,
);
export const getRequirementStampingDetails = createAction(
  `${requirementRoot}getRequirementStampingDetails`,
);
export const getRequirementStampingDetailsSuccess = createAction(
  `${requirementRoot}getRequirementStampingDetailsSuccess`,
);
export const updateRequirementRecordDatesDetail = createAction(
  `${requirementRoot}updateRequirementRecordDatesDetail`,
);
export const updateRequirementUrl = createAction(`${requirementRoot}updateRequirementUrl`);

// Locking Actions
export const getRequirementUserLockDetails = createAction(
  `${requirementRoot}getRequirementUserLockDetails`,
);
export const releaseRequirementUserLock = createAction(
  `${requirementRoot}releaseRequirementUserLock`,
);
export const resetRequirementUserLock = createAction(`${requirementRoot}resetRequirementUserLock`);
export const updateRequirementLockDetails = createAction(
  `${requirementRoot}updateRequirementLockDetails`,
);

// compare actions
export const getRequirementCompareData = createAction(
  `${requirementRoot}getRequirementCompareData`,
);
export const getRequirementCompareDataSuccess = createAction(
  `${requirementRoot}getRequirementCompareDataSuccess`,
);
export const resetRequirementCompareData = createAction(
  `${requirementRoot}resetRequirementCompareData`,
);
