import { fieldTypes } from '../../../framework/formBuilder/utils';

export const initialValues = {
  assignee: [],
};

export const layoutSchema = {
  layout: 'row',
  className: 'molecular-data-container mb-20',
  spacing: 3,
  justify: 'center',
  alignItems: 'center',
  content: [
    {
      layout: 'column',
      size: 12,
      className: 'dh-row mt-20',
      // spacing: 3,
      header: ' ',
      headerProps: {
        fontStyle: 'bold',
        size: '14',
      },
      content: [
        {
          layout: 'assignee',
          size: 12,
          className: 'assignee-type',
        },
      ],
    },
    {
      layout: 'row',
      className: 'dh-row',
      size: 12,
      // spacing: 3,
      justify: 'flex-end',
      alignItems: 'flex-end',
      content: [
        {
          layout: 'cancel',
          size: 4,
          className: 'add-button assign-btn cancel',
        },
        {
          layout: 'button',
          size: 4,
          className: 'add-button assign-btn',
        },
      ],
    },
  ],
};

export const formSchema = {
  assignee: {
    name: 'assignee',
    type: fieldTypes.selectGroup,
    label: 'ASSIGNEE -',
    labelSuffix: '(team or user)',
    options: [],
    props: {
      inputHeight: 'ht-lg',
      isMandatory: false,
      isMulti: true,
      placeholder: 'Select',
      isSearchable: true,
      allowMultipleSelections: true,
    },
  },
  cancel: {
    name: 'button',
    type: fieldTypes.customButton,
    label: 'Cancel',
    props: {
      buttonType: 'secondary-btn',
      buttonSize: 'md',
    },
  },
  button: {
    name: 'button',
    type: fieldTypes.button,
    label: 'Submit',
    props: {
      buttonType: 'primary-btn',
      buttonSize: 'md',
    },
  },
};
