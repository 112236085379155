import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { MODAL_TYPES } from '../../../utils/generic/constants';

import Modal from '../../../framework/modal';
import { InputButton } from '../../../framework/inputs';
import Typography from '../../../framework/typography';
import { Link, MenuItem } from '@material-ui/core';
import './index.scss';

const ModalMessage = ({ isOpen, modalType, message, modalListMessage, onClose }) => {
  const [showModal, update] = useState(isOpen);
  useEffect(() => update(isOpen), [isOpen]);

  const handleClick = () => {
    update(false);
    onClose();
  };

  const modalTypes = () => {
    switch (modalType) {
      case MODAL_TYPES.EXPANDING_ERROR_LIST:
      case MODAL_TYPES.ERROR:
      case MODAL_TYPES.ERROR_LIST: {
        return (
          <div className="message-modal-header-content">
            <FontAwesomeIcon className="modal-icon error" icon={faExclamationCircle} />
            <Typography
              variant="h6"
              text="error!"
              textType="uppercase"
              fontStyle="bold"
              color="red"
              padding="top"
            />
          </div>
        );
      }
      case MODAL_TYPES.SUCCESS: {
        return (
          <div className="message-modal-header-content">
            <FontAwesomeIcon className="modal-icon success" icon={faCheckCircle} />
            <Typography
              variant="h6"
              text="success!"
              textType="uppercase"
              fontStyle="bold"
              color="green"
              padding="top"
            />
          </div>
        );
      }
      case MODAL_TYPES.WARNING: {
        return (
          <div className="message-modal-header-content">
            <FontAwesomeIcon className="modal-icon warning" icon={faExclamationTriangle} />
            <Typography
              variant="h6"
              text="Warning!"
              textType="uppercase"
              fontStyle="bold"
              color="orange"
              padding="top"
            />
          </div>
        );
      }
      case MODAL_TYPES.INFO:
      case MODAL_TYPES.INFO_LIST:
      case MODAL_TYPES.CUSTOM_INFO_LIST: {
        return (
          <div className="message-modal-header-content">
            <FontAwesomeIcon className="modal-icon info" icon={faInfoCircle} />
            <Typography
              variant="h6"
              text="Info!"
              textType="uppercase"
              fontStyle="bold"
              color="blue"
              padding="top"
            />
          </div>
        );
      }
      default:
        return null;
    }
  };

  const renderInfoList = () => {
    return (
      <div className="message-modal-content-list">
        <ul>
          {modalListMessage.map((element, index) => {
            return (
              <MenuItem key={index}>
                {typeof element === 'object' ? (
                  <Link href={element.link} target="_blank">
                    {element.name}
                  </Link>
                ) : (
                  <>{element}</>
                )}
              </MenuItem>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderCustomInfoList = () => {
    return (
      <div className="message-modal-content-list">
        <ul>
          {modalListMessage.map((element, index) => {
            return (
              <MenuItem key={index}>
                <>{element}</>
              </MenuItem>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <>
      {showModal && (
        <Modal isOpen={showModal} showCloseButton={false}>
          <section className="message-modal-container">
            <header>{modalTypes()}</header>
            {message && <p className="message-modal-content">{message}</p>}
            {modalType === MODAL_TYPES.INFO_LIST || modalType === MODAL_TYPES.ERROR_LIST
              ? renderInfoList()
              : null}
            {modalType === MODAL_TYPES.EXPANDING_ERROR_LIST ||
            modalType === MODAL_TYPES.CUSTOM_INFO_LIST
              ? renderCustomInfoList()
              : null}
            <footer>
              <InputButton
                variant="contained"
                buttonType="primary-btn"
                text="CLOSE"
                onClick={handleClick}
              />
            </footer>
          </section>
        </Modal>
      )}
    </>
  );
};

ModalMessage.propTypes = {
  isOpen: PropTypes.bool,
  modalType: PropTypes.string,
  message: PropTypes.string,
  modalListMessage: PropTypes.array,
  onClose: PropTypes.func,
};

ModalMessage.defaultProps = {
  isOpen: true,
  modalType: MODAL_TYPES.ERROR,
  message: '',
  modalListMessage: [],
  onClose: () => {},
};

export default ModalMessage;
