import { createReducer } from '@reduxjs/toolkit';
import { omit, isNil } from 'lodash';

import InitialState from '../initialState';

import {
  getDrugListSuccess,
  getGlobalStatusSuccess,
  getCountriesSuccess,
  getCountryGroups,
  getMasterDataSuccess,
  featureSelection,
  getDrugDetailSuccess,
  getDPLDataSuccess,
  resetDrugState,
  postDrugsDataSuccess,
  nextFeatureSelection,
  setSelectedTabIndex,
  updateDrugDetails,
  resetDrugDetails,
  resetDrugDetailsWithKey,
  fetchActivityMaster,
  setActivityMaster,
  activityMasterLoader,
  getEventTypesSuccess,
  getTrendsDataSuccess,
  getEventMasterDataSuccess,
  deleteDrugSuccess,
  getDrugTypesSuccess,
  getTaskQueueTypesSuccess,
  updateDrugTypeDetails,
  getTaskQueueRecordsSuccess,
  getNotesTypeSuccess,
  getOriginSuccess,
  getDevelopmentPhaseSuccess,
  setSelectedQueueType,
  getDrugQueueRecordsSuccess,
  getQueueTypesSuccess,
  setPreviewSelection,
  getDrugIngestedDataSuccess,
  updateDrugIngestedAction,
  updateDrugChildDetails,
  resetDrugIngestedData,
  getDrugUserLockDetails,
  getDrugList,
  getDrugQueueRecords,
  resetDrugUserLock,
  getDrugAssociationSuccess,
  getDrugTrialAssociationSuccess,
  getMolecularParametersSuccess,
  setDPLAncillaryMasterData,
  setQueueExpandedList,
  setQueueTransactionId,
  setDrugValidationStatus,
  setDrugValidationErrors,
  postDrugsData,
  setDrugChangeStatus,
  getDrugsProximityDataSuccess,
  getDPLDataForViewSuccess,
  resetDrugsProximityData,
  getDrugsCompareDataSuccess,
  resetDrugsCompareData,
  getDrugStampingDetailsSuccess,
  resetDPLFilter,
  setGridGroupedKey,
} from '../actions/drugs';
import {
  getNextIngestedTab,
  getChemicalMolecularParameters,
  formatDrugDataToStore,
  getDrugStampDates,
  updateDrugStampingDetails,
} from '../helpers/drugs';
import { setCurrentAndIngestedData } from '../helpers/drugs';
import {
  validateIngestionAction,
  checkForIngestionAction,
  resetIngestionAction,
  resetChildIngestionAction,
  checkIfObjectIsEmpty,
} from '../../utils/generic/helper';
import validateTabs from '../../utils/generic/validation';
import { drugFeatureKeys, drugFeatureProperities } from '../../utils/drugs/constants';

const initialState = InitialState.drugs;

const drugs = createReducer(initialState, {
  [getDrugList]: state => {
    state.dashboard.queues.queueRecords = {};
  },
  [getDrugListSuccess]: (state, { payload }) => {
    state.dashboard.queues.queueRecords = payload;
  },
  [getGlobalStatusSuccess]: (state, { payload }) => {
    const data = state.masterData.drugGlobalStatusGroup;
    const result = data.map(val => {
      const filtered = payload.filter(status => status.statusGroupId === val.id);
      return {
        value: val.id,
        label: val.value,
        options: filtered.map(({ id, status, ...otherProps }) => ({
          id,
          status,
          ...otherProps,
          value: id,
          label: status,
        })),
      };
    });
    state.ancillaryMasterData.globalStatus = result;
    // state.globalStatusRaw = payload;
  },
  [getCountriesSuccess]: (state, { payload }) => {
    state.ancillaryMasterData.countries = payload;
  },
  [getOriginSuccess]: (state, { payload }) => {
    state.origin = payload;
  },
  [getCountryGroups]: (state, { payload }) => {
    state.ancillaryMasterData.countryGroups = payload;
  },
  [getMasterDataSuccess]: (state, { payload }) => {
    state.masterData = payload;
  },
  [setGridGroupedKey]: (state, { payload }) => {
    state.gridGroupKey = payload;
  },
  [featureSelection]: (state, { payload: { value } }) => {
    state.selected = value;
  },
  [nextFeatureSelection]: (state, { payload }) => {
    const filteredTabs = state.tabs.filter(i => i.value === payload);
    const index = state.tabs.findIndex(i => i.value === payload);
    const isSingleModeActive = state.isSinglePageMode;
    if (filteredTabs.length > 0) {
      const { isTab, selectedTabIndex, tabs } = filteredTabs[0];
      if (isTab) {
        if (selectedTabIndex < tabs.length - 1) {
          state.tabs[index].selectedTabIndex = selectedTabIndex + 1;
        } else if (selectedTabIndex === tabs.length - 1 && !isSingleModeActive) {
          state.selected = state.tabs[index + 1].value;
          state.tabs[index].selectedTabIndex = 0;
        } else {
          const { ingestedTab, activeTabIndex } = getNextIngestedTab(
            [...state.tabs],
            state.previewSelected,
          );
          state.previewSelected = ingestedTab
            ? ingestedTab.value
            : state.tabs[activeTabIndex + 1].value;
          state.tabs[activeTabIndex].selectedTabIndex = 0;
        }
      } else if (isSingleModeActive) {
        const { ingestedTab, activeTabIndex } = getNextIngestedTab(
          [...state.tabs],
          state.previewSelected,
        );
        state.previewSelected = ingestedTab
          ? ingestedTab.value
          : state.tabs[activeTabIndex + 1].value;
      } else {
        state.selected = state.tabs[index + 1].value;
      }
    }
  },
  [setSelectedTabIndex]: (state, { payload: { id, tabIndex } }) => {
    const index = state.tabs.findIndex(i => i.value === id);
    if (index > -1) {
      state.tabs[index].selectedTabIndex = tabIndex;
    }
  },
  [getDrugDetailSuccess]: (state, { payload }) => {
    const { masterDrug } = payload;
    const updatedData = formatDrugDataToStore(payload);
    state.original = { ...state.original, ...updatedData };
    state.current = { ...state.current, ...updatedData };
    if (masterDrug) {
      const { tabs } = initialState;
      state.tabs = tabs.filter(tab => tab.isChildTab);
    }
    state.originalStampDates = getDrugStampDates(state.original.summary);
  },
  [getDPLDataSuccess]: (
    state,
    { payload: { results, rowCount, pageIndex, pageSize, ingestedId, currentPage } },
  ) => {
    const { landscapeStatuses } = state.current;
    const { landscapeStatuses: originalLandscapeStatuses } = state.original;
    const dplResults = results || [];
    const dplTabInfo = state.tabs.filter(
      tab => tab.key === drugFeatureProperities.DRUG_PROGRAM_LANDSCAPE,
    );
    state.original.landscapeStatuses = dplResults;
    // to add ingested data for first time load alone
    state.current.landscapeStatuses =
      originalLandscapeStatuses.length === 0 &&
      ingestedId > 0 &&
      dplTabInfo.length > 0 &&
      dplTabInfo[0].hasIngestedData
        ? [
            ...landscapeStatuses,
            ...dplResults.map(data => ({
              ...data,
              isDisabled: checkForIngestionAction(landscapeStatuses),
            })),
          ]
        : dplResults;
    state.dplPagination = {
      rowCount,
      pageIndex,
      pageSize,
      currentPage,
    };
  },
  [deleteDrugSuccess]: (state, { payload }) => {
    const results = state.dashboard.queues.queueRecords.results;
    state.dashboard.queues.queueRecords.results = results.filter(drug => drug.id !== payload);
  },
  [resetDrugState]: state => {
    const {
      selected,
      original,
      current,
      tabs,
      ingested,
      drugAssociation,
      dplPagination,
      errors,
      originalStampDates,
    } = initialState;
    state.selected = selected;
    state.tabs = tabs;
    state.original = original;
    state.current = current;
    state.ingested = ingested;
    state.dplPagination = dplPagination;
    state.drugAssociation = drugAssociation;
    state.errors = errors;
    state.isStamped = false;
    state.originalStampDates = originalStampDates;
  },
  [postDrugsData]: (
    state,
    {
      payload: {
        childTabKey,
        body: { forceToFieldUpdate },
      },
    },
  ) => {
    const key = drugFeatureKeys[forceToFieldUpdate];
    const selected = state.tabs.find(tab => tab.key === key);
    if (selected && !childTabKey) {
      selected.hasError = false;
    } else if (selected && selected.hasIngestedData) {
      selected.hasError =
        selected.hasError &&
        !validateIngestionAction({
          data: state.current[key],
          ingestedActions: state.ingested[key]['fieldActions'],
          type: 'drugs',
        }).isValid;
      selected.tabs[selected.selectedTabIndex].hasError = false;
    } else if (selected) {
      selected.tabs[selected.selectedTabIndex].hasError = false;
      selected.hasError =
        selected.hasError &&
        validateTabs.reducer[key] &&
        validateTabs.reducer.chemStructure(
          state.current[key],
          state.original,
          state.errors[key],
          childTabKey[0],
        );
    }
  },
  [postDrugsDataSuccess]: (state, { payload: { key, childTabKey, data, history } }) => {
    // commented for JEDI-1034
    // state.current.id = data.id;
    // state.original.id = data.id;
    // state.current.key = data.key;
    // state.original.key = data.key;
    // state.original[key] = data[key];
    // state.current[key] = data[key];

    const { ingested } = initialState;
    const { landscapeStatuses, deletedLandscapeStatuses } = state.original;
    state.original = {
      ...state.original,
      ...formatDrugDataToStore(data),
      ingestedId: state.current.ingestedId,
      landscapeStatuses,
      deletedLandscapeStatuses,
    };

    if (state.current.id === 0 && state.current.ingestedId === 0) {
      state.current = state.original;
    }
    // to maintain the other child tab data
    let updatedCurrentData = { ...state.current };
    if (childTabKey && childTabKey.length > 0) {
      childTabKey.map(
        childKey => (updatedCurrentData[key][childKey] = state.original[key][childKey]),
      );
    } else if (key !== drugFeatureProperities.DRUG_PROGRAM_LANDSCAPE) {
      updatedCurrentData[key] = state.original[key];
    }
    state.current = { ...state.current, ...updatedCurrentData };
    if (history?.location?.state === 'convertedPharmaDrug') {
      state.current = {
        ...state.current,
        ...formatDrugDataToStore(data),
        ingestedId: state.current.ingestedId,
        landscapeStatuses,
        deletedLandscapeStatuses,
      };
    }
    state.current.id = data.id;
    state.current.key = data.key;
    // TO DO: add code for inception date.

    // TO DO: to update record dates in drug summary
    state.current.summary = {
      ...state.current.summary,
      ...omit(data.summary, [
        'globalStatus',
        'latestChangeDatePublishOnFos',
        'devStatus',
        'latestChangeText',
      ]),
    };

    state.originalStampDates = getDrugStampDates(state.original.summary);
    state.isStamped = false;

    const tabIndex = state.tabs.findIndex(tab => tab.key === key);
    if (tabIndex > -1) {
      const selected = state.tabs[tabIndex];
      if (selected.isTab) {
        let changed = false;
        selected.tabs.forEach((child, index) => {
          if (index === selected.selectedTabIndex) {
            child.hasUnSavedData = false;
          } else {
            changed = changed || child.hasUnSavedData;
          }
        });
        selected.hasUnSavedData = changed;
      } else {
        selected.hasUnSavedData = false;
      }
    }

    if (state.ingested[key] && ingested[key]) {
      let hasIngestedData = false;
      if (childTabKey && childTabKey.length > 0) {
        childTabKey.forEach(childKey => {
          state.ingested[key].data = omit(state.ingested[key].data, [childKey]);
          state.ingested[key].fieldActions = omit(state.ingested[key].fieldActions, [childKey]);
        });
        const { isValid } = validateIngestionAction({
          ingestedActions: state.ingested[key].fieldActions,
          data: state.current[key],
        });
        hasIngestedData = !isValid;
      } else {
        state.ingested[key] = ingested[key];
      }
      if (tabIndex > -1) {
        const selected = state.tabs[tabIndex];
        if (!hasIngestedData) {
          selected.hasIngestedData = false;
        }
        if (selected.isTab) {
          selected.tabs[selected.selectedTabIndex].hasIngestedData = false;
        }
      }
    }

    state.resetFilter = true;
  },
  [resetDPLFilter]: state => {
    state.resetFilter = false;
  },
  [getDrugStampingDetailsSuccess]: (state, { payload: { data } }) => {
    state.isStamped = true;
    const { current, original } = updateDrugStampingDetails(
      data.summary,
      state.current,
      state.original,
    );
    state.current = current;
    state.original = original;
  },
  [updateDrugDetails]: (state, { payload: { key, data } }) => {
    state.current[key] = data;
  },
  [updateDrugChildDetails]: (state, { payload: { parentKey, childKey, data } }) => {
    const currentData = state.current[parentKey];
    currentData[childKey] = data;
    state.current[parentKey] = currentData;
  },
  [setDrugValidationStatus]: (state, { payload: { tabs = [], childTabs = [] } }) => {
    tabs.forEach(({ index, isValid }) => {
      state.tabs[index].hasError = !isValid;
    });
    childTabs.forEach(({ index, tabs: children }) =>
      children.forEach(({ index: childIndex, isValid }) => {
        state.tabs[index]['tabs'][childIndex].hasError = !isValid;
      }),
    );
  },
  [setDrugChangeStatus]: (state, { payload: { tabs = [], childTabs = [] } }) => {
    tabs.forEach(({ index, hasUnSavedData }) => {
      state.tabs[index].hasUnSavedData = hasUnSavedData;
    });
    childTabs.forEach(({ index, tabs: children }) =>
      children.forEach(({ index: childIndex, hasUnSavedData }) => {
        state.tabs[index]['tabs'][childIndex].hasUnSavedData = hasUnSavedData;
      }),
    );
  },
  [resetDrugDetails]: (state, { payload: { key } }) => {
    const selected = state.tabs.find(tab => tab.key === key);
    if (selected && selected.hasIngestedData) {
      selected.hasUnSavedData = false;
      const { ingestedData, updatedCurrentData } = resetIngestionAction(
        state.ingested[key],
        state.current[key],
        state.original[key],
      );
      state.ingested[key] = ingestedData;
      state.current[key] = updatedCurrentData;
    } else {
      if (selected) {
        selected.hasUnSavedData = false;
        selected.hasError =
          selected.hasError &&
          Boolean(validateTabs.drugs[key]) &&
          !checkIfObjectIsEmpty(
            validateTabs.drugs[key](state.original[key], state.original, state.errors[key]),
          );
      }
      state.current[key] = state.original[key];
    }
  },
  [resetDrugDetailsWithKey]: (state, { payload: { childKey, parentKey, updateState = true } }) => {
    const selected = state.tabs.find(tab => tab.key === parentKey);
    const selectedChildTab = selected.tabs[selected.selectedTabIndex];

    if (selectedChildTab && selectedChildTab.hasIngestedData) {
      if (updateState) {
        const { ingestedData, updatedCurrentData } = resetChildIngestionAction({
          ingestedData: state.ingested[parentKey],
          current: state.current[parentKey],
          original: state.original[parentKey],
          childKey,
        });
        state.ingested[parentKey] = ingestedData;
        state.current[parentKey] = updatedCurrentData;
      }
      selectedChildTab.hasUnSavedData = false;

      selected.hasUnSavedData = selected.tabs.findIndex(child => child.hasUnSavedData) > -1;
    } else {
      if (updateState) {
        const newValue = state.original[parentKey][childKey];
        state.current[parentKey][childKey] = newValue;
      }

      selectedChildTab.hasError =
        selectedChildTab.hasError &&
        Boolean(validateTabs.drugs[selectedChildTab.key]) &&
        !checkIfObjectIsEmpty(
          validateTabs.drugs[selectedChildTab.key](
            state.current[parentKey],
            state.original,
            state.errors[parentKey],
          ),
        );
      selectedChildTab.hasUnSavedData = false;

      if (selected) {
        selected.hasUnSavedData = selected.tabs.findIndex(child => child.hasUnSavedData) > -1;
        selected.hasError = selected.tabs.findIndex(child => child.hasError) > -1;
      }
    }
  },
  [setActivityMaster]: (state, { payload }) => {
    const { deliveryRoute, deliveryMedium, deliveryTechnology, parameter, unit } = payload;
    state.ancillaryMasterData.deliveryRoute = deliveryRoute.data.data;
    state.ancillaryMasterData.deliveryMedium = deliveryMedium.data.data;
    state.ancillaryMasterData.deliveryTechnology = deliveryTechnology.data.data;
    state.ancillaryMasterData.parameter = parameter.data.data;
    state.ancillaryMasterData.unit = unit.data.data;
    state.isActivityMasterLoading = false;
  },
  [fetchActivityMaster]: state => {
    state.isActivityMasterLoading = true;
  },
  [activityMasterLoader]: (state, { payload }) => {
    state.isActivityMasterLoading = payload;
  },
  [getEventTypesSuccess]: (state, { payload }) => {
    state.ancillaryMasterData.eventTypes = payload;
  },
  [getTrendsDataSuccess]: (state, { payload }) => {
    state.trends = payload;
  },
  [getDevelopmentPhaseSuccess]: (state, { payload }) => {
    state.developmentPhase = payload;
  },
  [getEventMasterDataSuccess]: (state, { payload: { diseases, companies, countries } }) => {
    state.eventMasterData.diseases = diseases.data.data;
    state.eventMasterData.companies = companies.data.data;
    state.eventMasterData.countries = countries.data.data;
  },
  [getDrugTypesSuccess]: (state, { payload }) => {
    state.drugTypes = payload;
  },
  [updateDrugTypeDetails]: (state, { payload: { drugType, masterDrug } }) => {
    const { tabs } = initialState;
    state.current['drugType'] = drugType;
    state.current['masterDrug'] = masterDrug;
    state.original['drugType'] = drugType;
    state.original['masterDrug'] = masterDrug;
    let updatedtabs = [...tabs];
    if (masterDrug) {
      updatedtabs = tabs.filter(tab => tab.isChildTab);
    }
    state.tabs = updatedtabs;
  },
  [getNotesTypeSuccess]: (state, { payload }) => {
    state.notesType = payload;
  },

  //queues
  [setSelectedQueueType]: (state, { payload }) => {
    state.dashboard.queues.queueTypeSelected = payload;
  },
  [setQueueExpandedList]: (state, { payload }) => {
    state.dashboard.queues.queueExpandedList = payload;
  },
  [getDrugQueueRecords]: (state, { payload: { emptyRecords } }) => {
    let queueRecords = emptyRecords ? {} : state.dashboard.queues.queueRecords;
    state.dashboard.queues.queueRecords = queueRecords;
  },
  [setQueueTransactionId]: (state, { payload }) => {
    state.queueTransactionId = payload;
  },

  [getDrugQueueRecordsSuccess]: (state, { payload }) => {
    state.dashboard.queues.queueRecords = payload;
  },
  [getQueueTypesSuccess]: (state, { payload }) => {
    let { data = [] } = payload;
    state.dashboard.queues.queueTypes = data;
  },
  [getTaskQueueTypesSuccess]: (state, { payload }) => {
    let { data = [] } = payload;
    state.dashboard.taskQueues.taskQueueTypes = data;
  },
  [getTaskQueueRecordsSuccess]: (state, { payload }) => {
    state.dashboard.taskQueues.taskQueueRecords = payload;
  },
  [getDrugIngestedDataSuccess]: (state, { payload }) => {
    const { updatedIngestedData, updatedCurrentData, updatedTabs } = setCurrentAndIngestedData(
      { ...state.current },
      { ...payload },
      [...state.tabs],
    );
    state.ingested = { ...updatedIngestedData };
    state.current = { ...state.current, ...updatedCurrentData };
    state.tabs = [...updatedTabs];
  },
  [updateDrugIngestedAction]: (state, { payload: { parentKey, childKey, ingestionAction } }) => {
    const ingestedData = state.ingested[parentKey];
    ingestedData.fieldActions[childKey] = ingestionAction;
    state.ingested[parentKey] = ingestedData;
  },
  [resetDrugIngestedData]: state => {
    const { ingested } = initialState;
    state.ingested = ingested;
  },
  [setPreviewSelection]: (state, { payload: { val, isActive } }) => {
    state.isSinglePageMode = isActive;
    state.previewSelected = val;
  },
  [getDrugUserLockDetails]: (state, { payload: { isEditable, lockedBy, userLockPeriod } }) => {
    const updatedData = {
      isLocked: !isEditable,
      lockedBy,
      userLockPeriod,
    };
    state.drugLockDetails = updatedData;
  },
  [resetDrugUserLock]: state => {
    const { drugLockDetails } = initialState;
    state.drugLockDetails = drugLockDetails;
  },
  [getDrugAssociationSuccess]: (
    state,
    { payload: { pageSize, currentPage, rowCount, results } },
  ) => {
    state.drugAssociation.associatedDrugs = {
      associatedDrugsData: results || [],
      pagination: {
        pageSize,
        pageIndex: currentPage,
        rowCount,
      },
    };
  },
  [getDrugTrialAssociationSuccess]: (
    state,
    { payload: { pageSize, currentPage, rowCount, results } },
  ) => {
    state.drugAssociation.associatedTrials = {
      associatedTrialsData: results && results.length > 0 ? results[0].result || [] : [],
      pagination: {
        pageSize,
        pageIndex: currentPage,
        rowCount,
      },
    };
  },
  [getMolecularParametersSuccess]: (state, { payload }) => {
    const currentState = getChemicalMolecularParameters(state.current, payload);
    state.current = currentState;
  },
  [setDPLAncillaryMasterData]: (state, { payload: { data, key } }) => {
    state.ancillaryMasterData[key] = data;
  },
  [setDrugValidationErrors]: (state, { payload: { parentKey, childTabKey, data } }) => {
    state.errors[parentKey][childTabKey] = data;
  },
  [getDrugsProximityDataSuccess]: (
    state,
    { payload, payload: { ingestedRecordData, possibleDuplicatesData } },
  ) => {
    const { tabs } = initialState;
    let updatedTabs = [...tabs];
    const isChildDrug = [{ ...ingestedRecordData }, ...possibleDuplicatesData].every(
      ({ masterDrug }) => !isNil(masterDrug),
    );
    if (isChildDrug) {
      updatedTabs = tabs.filter(tab => tab.isChildTab);
    }
    console.log(payload, isChildDrug, updatedTabs);
    state.tabs = updatedTabs;
    state.proximityComparisonData = payload;
  },
  [getDPLDataForViewSuccess]: (state, { payload }) => {
    state.dplViewData = payload;
  },
  [resetDrugsProximityData]: state => {
    state.proximityComparisonData = { ...initialState.proximityComparisonData };
  },

  [getDrugsCompareDataSuccess]: (state, { payload }) => {
    state.compareRecordsData = payload;
  },
  [resetDrugsCompareData]: state => {
    state.compareRecordsData = { ...initialState.compareRecordsData };
  },
});

export default drugs;
