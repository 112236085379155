const organizationIngestionFields = [
  // Basic & Contact Info
  {
    fieldId: 2001,
    fieldName: 'name',
  },
  {
    fieldId: 2002,
    fieldName: 'organizationType',
  },
  {
    fieldId: 2003,
    fieldName: 'licenses',
  },
  {
    fieldId: 2004,
    fieldName: 'parentOrganization',
  },
  {
    fieldId: 2005,
    fieldName: 'supportingUrls',
  },
  {
    fieldId: 2006,
    fieldName: 'nextFullReviewDate', // ingestion not done for this field
  },
  // Address
  {
    fieldId: 2007,
    fieldName: 'contactInfo',
  },
  // Notes
  {
    fieldId: 2008,
    fieldName: 'notes',
  },
  {
    fieldId: 2009,
    fieldName: 'prodStatusID', // ingestion not done for this field
  },
];

export default organizationIngestionFields;
