/* eslint-disable no-plusplus */
import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';
import {
  deleteRequirementSuccess,
  requirementFeatureSelection,
  requirementNextFeatureSelection,
  setSelectedTabIndex,
  saveRequirementDetails,
  saveRequirementDetailsSuccess,
  getRequirementDetailsSuccess,
  getRequirementMasterDataSuccess,
  requirementTabsUpdate,
  moveOutofScopeRequirementSuccess,
  getStagedIdRequirementSuccess,
  setRequirementValidationStatus,
  setReqErrorDetails,
  setRequirementUnSavedStatus,
  setValidate,
  publishRequirementSuccess,
  setTabNames,
  requirementRecordChangeFldsListSuccess,
  getRequirementStampingDetailsSuccess,
  updateRequirementRecordDatesDetail,
  getRequirementUserLockDetails,
  resetRequirementUserLock,
  resetStagedIdRequirement,
  getRequirementCompareDataSuccess,
  resetRequirementCompareData,
} from '../actions/requirement';

const initialState = InitialState.requirement;
const requirement = createReducer(initialState, {
  [requirementFeatureSelection]: (state, { payload: { value } }) => {
    state.selected = value;
  },
  [requirementTabsUpdate]: (state, { payload }) => {
    state.tabs = payload;
  },
  [requirementNextFeatureSelection]: (state, { payload }) => {
    const filteredTabs = state.tabs.filter(i => i.value === payload);
    const index = state.tabs.findIndex(i => i.value === payload);
    const isSingleModeActive = state.isSinglePageMode;
    if (filteredTabs.length > 0) {
      const { isTab, selectedTabIndex, tabs } = filteredTabs[0];
      if (isTab) {
        if (selectedTabIndex < tabs.length - 1) {
          state.tabs[index].selectedTabIndex = selectedTabIndex + 1;
        } else if (selectedTabIndex === tabs.length - 1) {
          const tabValue = state.tabs[index + 1].value;
          isSingleModeActive ? (state.previewSelected = tabValue) : (state.selected = tabValue);
          state.tabs[index].selectedTabIndex = 0;
        }
      } else if (isSingleModeActive) {
        state.previewSelected = state.tabs[index + 1].value;
      } else {
        state.selected = state.tabs[index + 1].value;
      }
    }
  },
  [setSelectedTabIndex]: (state, { payload: { id, tabIndex } }) => {
    const index = state.tabs.findIndex(i => i.value === id);
    if (index > -1) {
      state.tabs[index].selectedTabIndex = tabIndex;
    }
  },
  [deleteRequirementSuccess]: (state, { payload }) => {
    const results = state.dashboard.queues.queueRecords.results;
    state.dashboard.queues.queueRecords.results = results.filter(entry => entry.id !== payload);
  },
  [saveRequirementDetails]: (state, { payload: { key } }) => {
    const selected = state.tabs.find(tab => tab.key === key);
    if (selected) {
      selected.hasError = false;
    }
  },
  [saveRequirementDetailsSuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
    for (let i = 0; i < state.tabs.length; i++) {
      state.tabs[i].hasUnSavedData = false;
      // state.tabs[i].hasError = false;
    }
    state.isStamped = false;
  },
  [getRequirementMasterDataSuccess]: (state, { payload }) => {
    state.masterData = payload;
  },
  [getRequirementDetailsSuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },
  [moveOutofScopeRequirementSuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },
  [getStagedIdRequirementSuccess]: (state, { payload }) => {
    state.stageId = payload;
  },
  [setRequirementValidationStatus]: (state, { payload }) => {
    const { tabId, hasError } = payload;
    if (state.tabs && state.tabs[tabId]) {
      state.tabs[tabId].hasError = hasError;
    }
  },
  [setReqErrorDetails]: (state, data = {}) => {
    state.tabs[data.payload?.tabId].error = data.payload.errorDetails;
  },
  [setRequirementUnSavedStatus]: (state, { payload }) => {
    const { tabId, isPresentChanged } = payload;
    state.tabs[tabId].hasUnSavedData = isPresentChanged;
  },
  [setValidate]: (state, { payload }) => {
    const { tabId, validate } = payload;
    state.tabs[tabId].validate = validate;
  },
  [publishRequirementSuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },
  [setTabNames]: (state, { payload }) => {
    const { abbreviatedName } = payload;
    state.tabs.forEach((tab, index) => {
      const tabName = state.tabs[index].name.split('-')[0];
      if (tab.tabId === payload.tabId) {
        if (abbreviatedName) {
          state.tabs[index].name = `${tabName} - ${abbreviatedName}`;
        } else {
          state.tabs[index].name = tabName;
        }
      }
    });
  },
  [requirementRecordChangeFldsListSuccess]: (state, { payload }) => {
    state.changeFields = payload;
  },
  [getRequirementStampingDetailsSuccess]: (state, { payload: { data } }) => {
    state.isStamped = true;
    state.current = data;
    state.original = data;
  },
  [updateRequirementRecordDatesDetail]: (state, { payload: { key, data } }) => {
    state.current[key] = data;
    state.original[key] = data;
  },
  [getRequirementUserLockDetails]: (
    state,
    { payload: { isEditable, lockedBy, userLockPeriod } },
  ) => {
    const updatedData = {
      isLocked: !isEditable,
      lockedBy,
      userLockPeriod,
    };
    state.requirementLockDetails = updatedData;
  },
  [resetRequirementUserLock]: state => {
    const { requirementLockDetails } = initialState;
    state.requirementLockDetails = requirementLockDetails;
  },
  [resetStagedIdRequirement]: state => {
    state.stageId = null;
  },
  [getRequirementCompareDataSuccess]: (state, { payload }) => {
    state.compareRecordsData = payload;
  },
  [resetRequirementCompareData]: state => {
    state.compareRecordsData = { ...initialState.compareRecordsData };
  },
});

export default requirement;
