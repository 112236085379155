import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';

const Layout = ({ dashboard, user, children }) => {
  return (
    <>
      <Header dashboard={dashboard} user={user} />
      {children}
    </>
  );
};

Layout.propTypes = {
  dashboard: PropTypes.string,
  user: PropTypes.object,
  children: PropTypes.node,
};

export default Layout;
