import { createAction } from '@reduxjs/toolkit';

// action definitions
const clinicalTrials = 'clinicalTrials/features/';
export const featureSelection = createAction(`${clinicalTrials}selected`);
export const nextFeatureSelection = createAction(`${clinicalTrials}goToNextFeature`);
export const setError = createAction(`${clinicalTrials}error`);

export const fetchTrialFeatures = createAction(`${clinicalTrials}getData`);
export const setTrialsFeatureData = createAction(`${clinicalTrials}getDataSuccess`);
export const setTrialNotesData = createAction(`${clinicalTrials}setTrialNotesData`);
export const setTrialAssociationsData = createAction(`${clinicalTrials}setTrialAssociationsData`);
export const setTrialResultData = createAction(`${clinicalTrials}setTrialResultData`);
export const setShowTrialsValidationModal = createAction(
  `${clinicalTrials}setShowTrialsValidationModal`,
);

export const getLocationsData = createAction(`${clinicalTrials}getLocationData`);
export const setLocationsData = createAction(`${clinicalTrials}getLocationDataSuccess`);

export const getMasterData = createAction(`${clinicalTrials}getMasterData`);
export const getMasterDataSuccess = createAction(`${clinicalTrials}getMasterDataSuccess`);

export const updateTrialDetails = createAction(`${clinicalTrials}updateTrialDetails`);

export const getTrialStampingDetails = createAction(`${clinicalTrials}getTrialStampingDetails`);
export const getTrialStampingDetailsSuccess = createAction(
  `${clinicalTrials}getTrialStampingDetailsSuccess`,
);

export const saveTrialsData = createAction(`${clinicalTrials}saveTrialsData`);
export const saveTrialsDataSuccess = createAction(`${clinicalTrials}saveTrialsDataSuccess`);

export const resetTrialsData = createAction(`${clinicalTrials}resetTrialsData`);
export const resetTrialState = createAction(`${clinicalTrials}resetTrialState`);
export const resetMasterData = createAction(`${clinicalTrials}resetMasterData`);
export const resetImportContactsList = createAction(`${clinicalTrials}resetImportContactsList`);
export const setMaxTemplateID = createAction(`${clinicalTrials}setMaxTemplateID`);

export const getTrialList = createAction(`${clinicalTrials}getTrialList`);
export const getTrialListSuccess = createAction(`${clinicalTrials}getTrialListSuccess`);
export const deleteTrialData = createAction(`${clinicalTrials}deleteTrialData`);
export const deleteTrialDataSuccess = createAction(`${clinicalTrials}deleteTrialDataSuccess`);

export const setTrialValidationStatus = createAction(`${clinicalTrials}setTrialValidationStatus`);
export const setTrialChangeStatus = createAction(`${clinicalTrials}setTrialChangeStatus`);

export const searchOrganizationAndPersonData = createAction(
  `${clinicalTrials}searchOrganizationAndPersonData`,
);
export const searchOrganizationAndPersonDataSuccess = createAction(
  `${clinicalTrials}searchOrganizationAndPersonDataSuccess`,
);

export const dashboardFeatureSelection = createAction(`${clinicalTrials}dashboardFeatureSelection`);

//queues
export const setSelectedQueueType = createAction(`${clinicalTrials}setSelectedQueueType`);
export const setTaskSelectedQueueType = createAction(`${clinicalTrials}setTaskSelectedQueueType`);
export const getQueueDashboardData = createAction(`${clinicalTrials}getQueueDashboardData`);
export const exitQueue = createAction(`${clinicalTrials}exitQueue`);
export const setQueueExpandedList = createAction(`${clinicalTrials}setQueueExpandedList`);
export const setQueueTransactionId = createAction(`${clinicalTrials}setQueueTransactionId`);

export const getQueueRecords = createAction(`${clinicalTrials}getQueueRecords`);
export const getTaskQueueRecords = createAction(`${clinicalTrials}getTaskQueueRecords`);
export const getQueueTypesAndRecords = createAction(`${clinicalTrials}getQueueTypesAndRecords`);
export const getQueueTypes = createAction(`${clinicalTrials}getQueueTypes`);
export const getTaskQueueTypes = createAction(`${clinicalTrials}getTaskQueueTypes`);
export const getQueueRecordsSuccess = createAction(`${clinicalTrials}getQueueRecordsSuccess`);
export const getTaskQueueRecordsSuccess = createAction(
  `${clinicalTrials}getTaskQueueRecordsSuccess`,
);
export const getQueueTypesSuccess = createAction(`${clinicalTrials}getQueueTypesSuccess`);
export const getTaskQueueTypesSuccess = createAction(`${clinicalTrials}getTaskQueueTypesSuccess`);

export const searchTrialResults = createAction(`${clinicalTrials}searchTrialResults`);
export const searchTrialResultsSuccess = createAction(`${clinicalTrials}searchTrialResultsSuccess`);
export const resetTrialResultsSearchList = createAction(
  `${clinicalTrials}resetTrialResultsSearchList`,
);
export const updateSearchTrialResults = createAction(`${clinicalTrials}updateSearchTrialResults`);

export const searchTrialNotes = createAction(`${clinicalTrials}searchTrialNotes`);
export const getTrialNotes = createAction(`${clinicalTrials}getTrialNotes`);
export const searchTrialNotesSuccess = createAction(`${clinicalTrials}searchTrialNotesSuccess`);
export const resetTrialNotesSearchList = createAction(`${clinicalTrials}resetTrialNotesSearchList`);
export const updateSearchTrialNotes = createAction(`${clinicalTrials}updateSearchTrialNotes`);
export const getTrialAssociations = createAction(`${clinicalTrials}getTrialAssociations`);

export const getTrialsUserLockDetails = createAction(`${clinicalTrials}getTrialsUserLockDetails`);
export const releaseTrialUserLock = createAction(`${clinicalTrials}releaseTrialUserLock`);
export const resetTrialUserLock = createAction(`${clinicalTrials}resetTrialUserLock`);
export const updateTrialLockDetails = createAction(`${clinicalTrials}updateTrialLockDetails`);
export const setPreviewSelection = createAction(`${clinicalTrials}setPreviewSelection`);

export const setIngestedData = createAction(`${clinicalTrials}setIngestedData`);
export const resetIngestedData = createAction(`${clinicalTrials}resetIngestedData`);
export const updateCurrentDataAndIngestedAction = createAction(
  `${clinicalTrials}updateCurrentDataAndIngestedAction`,
);
export const updateTrialChildValues = createAction(`${clinicalTrials}updateTrialChildValues`);
export const setTrialTimingErrors = createAction(`${clinicalTrials}setTrialTimingErrors`);

export const getSponsorType = createAction(`${clinicalTrials}getSponsorType`);
export const getSponsorTypeSuccess = createAction(`${clinicalTrials}getSponsorTypeSuccess`);

// proximity
export const getProximityData = createAction(`${clinicalTrials}getProximityData`);
export const getProximityDataSuccess = createAction(`${clinicalTrials}getProximityDataSuccess`);
export const resetTrialsProximityData = createAction(`${clinicalTrials}resetTrialsProximityData`);

export const updateTrialResultsAndNotesSearchDataForIngestion = createAction(
  `${clinicalTrials}updateTrialResultsAndNotesSearchDataForIngestion`,
);

//Compare Records
export const getProximityCompareData = createAction(`${clinicalTrials}getProximityCompareData`);
export const getProximityCompareDataSuccess = createAction(
  `${clinicalTrials}getProximityCompareDataSuccess`,
);
export const resetCompareIngestedData = createAction(`${clinicalTrials}resetCompareIngestedData`);
