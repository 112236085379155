import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { faSearchPlus, faHome } from '@fortawesome/free-solid-svg-icons';
import { faFlag } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Typography from '../../typography';
import { InputSelect } from '../../inputs';
import CrossTeamAlertModal from '../../../generic/modals/CrossTeamAlertModal';
import './index.scss';

const NavbarHeader = ({
  // navigateAdvancedSearchView,
  detailPage,
  navBarConfig,
  showAlertFlag,
  recordData,
  recordData: { recordId, disabled },
  modalView,
  showAdvancedSearchIcon,
  showAdvancedSearchEntityDropdown,
  onChangeEntity,
  entityData,
  defaultSelectedEntity,
  showHomeIcon,
  navigateHome,
}) => {
  const [showTeamAlert, setCrossTeamAlert] = useState(false);

  const getGridsize = (length, index) => {
    let gridSize = 2;

    switch (index) {
      case 0:
        if (length === 1) {
          gridSize = 12;
        } else {
          // eslint-disable-next-line no-lonely-if
          if (modalView) {
            gridSize = length === 4 ? 3 : 4;
          } else {
            gridSize = 2;
          }
        }
        break;
      case 1:
        if (length === 2) {
          gridSize = 10;
        } else {
          gridSize = modalView ? 2 : 2;
        }

        break;
      case 2:
        if (length === 3) {
          gridSize = 9;
        } else {
          gridSize = modalView ? 4 : 6;
        }
        break;
      case 3:
        gridSize = 3;
        break;
      default:
        break;
    }
    return gridSize;
  };

  const generateSummary = () => {
    return navBarConfig.map((config, index) => {
      let gridSize = 2;

      gridSize = getGridsize(navBarConfig.length, index);

      return (
        <Grid
          item
          className={
            index === navBarConfig.length - 1 ? 'nav-bar__ele toolbar-grid' : 'toolbar-grid'
          }
          xs={gridSize}
          key={`${config.name}${index}`}
        >
          <div className="toolbar-items">
            <div className={modalView ? 'toolbar-key toolbar-key-modal' : 'toolbar-key'}>
              <Typography
                classes="testMe"
                text={`${config.name}\u00A0:`}
                size="14"
                lineHeight="22"
                fontStyle="bold"
                color="turq-blue"
              />
            </div>
            <div
              className={
                index === navBarConfig.length - 1
                  ? 'toolbar-value  toolbar-items__item'
                  : 'toolbar-items__item'
              }
            >
              <Tooltip title={config.value}>
                <span>{config.value}</span>
              </Tooltip>
            </div>
          </div>
        </Grid>
      );
    });
  };

  return (
    <div className="navbar-header ">
      {detailPage ? (
        <AppBar position="fixed" className="detailsPurpleBar">
          <Grid
            container
            direction="row"
            justify={navBarConfig && navBarConfig.length > 0 ? 'flex-start' : 'flex-end'}
            alignItems="center"
            wrap="nowrap"
          >
            {navBarConfig && generateSummary()}
            {/* {showAdvancedSearchIcon && (
              <Tooltip title="Search">
                <div className="search-plus-icon" onClick={navigateAdvancedSearchView}>
                  <FontAwesomeIcon icon={faSearchPlus}></FontAwesomeIcon>
                </div>
              </Tooltip>
            )} */}
            {showAlertFlag && recordId > 0 && (
              <Tooltip title="Cross Team Alert">
                <span>
                  <button disabled={disabled} type="button" onClick={() => setCrossTeamAlert(true)}>
                    <FontAwesomeIcon icon={faFlag} />
                  </button>
                </span>
              </Tooltip>
            )}
          </Grid>
        </AppBar>
      ) : (
        <>
          {/* <AppBar position="static"> */}
          <AppBar position="fixed" className="detailSubClinical">
            <div className="toolbar purpleBarCt">
              <div className="purpleBarCtRight">
                {showHomeIcon && (
                  <Tooltip title="Landing Page">
                    <div className="home-icon" onClick={navigateHome}>
                      <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
                    </div>
                  </Tooltip>
                )}
                {/* {showAdvancedSearchIcon && (
                  <Tooltip title="Search">
                    <div className="search-plus-icon" onClick={navigateAdvancedSearchView}>
                      <FontAwesomeIcon icon={faSearchPlus}></FontAwesomeIcon>
                    </div>
                  </Tooltip>
                )} */}
                {showAdvancedSearchEntityDropdown && (
                  <div className="entity-dropdown">
                    <InputSelect
                      data={entityData}
                      defaultValue={defaultSelectedEntity}
                      onChange={onChangeEntity}
                    ></InputSelect>
                  </div>
                )}
              </div>
            </div>
          </AppBar>
        </>
      )}
      {showTeamAlert && (
        <CrossTeamAlertModal
          isOpen
          onClose={() => setCrossTeamAlert(false)}
          message="Do you want to delete this row?"
          navBarConfig={navBarConfig}
          recordData={recordData}
        />
      )}
    </div>
  );
};

NavbarHeader.propTypes = {
  breadcrumbLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
  type: PropTypes.string,
  name: PropTypes.string,
  searchLabel: PropTypes.string,
  detailPage: PropTypes.bool,
  navBarConfig: PropTypes.array,
  advancedSearch: PropTypes.bool,
  showAdvancedSearchPanel: PropTypes.bool,
  handleAdvancedSearchView: PropTypes.func,
  showAlertFlag: PropTypes.bool,
  recordData: PropTypes.object,
  advancedSearchCategoryData: PropTypes.array,
  categoryChangeHandler: PropTypes.func,
  advancedSearchCategoryTitleData: PropTypes.array,
  advancedSearchHandler: PropTypes.func,
  navigateAdvancedSearchView: PropTypes.func,
  onChangeEntity: PropTypes.func,
  showAdvancedSearchIcon: PropTypes.bool,
  showAdvancedSearchEntityDropdown: PropTypes.bool,
  entityData: PropTypes.array,
  defaultSelectedEntity: PropTypes.object,
  modalView: PropTypes.bool,
  showHomeIcon: PropTypes.bool,
  navigateHome: PropTypes.func,
};

NavbarHeader.defaultProps = {
  navigateAdvancedSearchView: () => { },
  showSearchBox: false,
  type: '',
  name: '',
  navBarConfig: [],
  advancedSearch: false,
  showAdvancedSearchPanel: false,
  handleAdvancedSearchView: () => { },
  showAlertFlag: true,
  recordData: {
    recordId: 0,
    entity: 'NA',
    disabled: false,
  },
  advancedSearchCategoryData: [],
  categoryChangeHandler: () => { },
  advancedSearchCategoryTitleData: [],
  advancedSearchHandler: () => { },
  showAdvancedSearchIcon: true,
  showAdvancedSearchEntityDropdown: false,
  onChangeEntity: () => { },
  entityData: [],
  defaultSelectedEntity: { id: '', value: '', label: '' },
  showHomeIcon: false,
  navigateHome: () => { },
};

export default NavbarHeader;
