import { takeEvery, put, all, delay } from 'redux-saga/effects';
import * as TaskActions from '../actions/task';

import * as companyActions from '../actions/company';
import * as globalActions from '../actions/globalActions';
import { company as companyApi, queues as queuesApi, task as taskApi } from '../../api';
import {
  ALERT_MESSAGES,
  MODAL_TYPES,
  updateTypeOnProdStatusSave,
} from '../../utils/generic/constants';
import {
  getApiErrorMessage,
  getProdMessage,
  getLockMessageInfo,
  getCompanyDelValidateErrorMessage,
} from '../../utils/generic/helper';

export function* getCompanyListAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield companyApi.getCompanyList({ payload });
    const { state, data, messages } = response.data;
    if (state) {
      yield put(companyActions.getCompanyListSuccess(data));
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(companyActions.setError(err));
    yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
  }
}

export function* deleteCompanyAsync({ payload }) {
  try {
    const { companyId, postParam } = payload;
    yield put(globalActions.showSpinner());
    const validateDeleteResponse = yield companyApi.validateBeforeDelete(companyId);
    const { validateState: state, data: validateData } = validateDeleteResponse.data;
    if (validateData?.associatedIDModels?.length) {
      yield put(globalActions.hideSpinner());
      yield put(
        globalActions.showModal({
          isOpen: true,
          modalListMessage: getCompanyDelValidateErrorMessage(validateData?.associatedIDModels),
          modalType: MODAL_TYPES.EXPANDING_ERROR_LIST,
        }),
      );
    } else {
      const response = yield companyApi.deleteCompany(companyId);
      yield put(globalActions.hideSpinner());
      const { state, messages } = response.data;
      if (state) {
        yield put(companyActions.getCompanyList(postParam));
        yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_DELETE));
      } else {
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: getApiErrorMessage(messages),
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(companyActions.setError(err));
    yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
  }
}

export function* validateCompanyBeforeDeleteAsync({ payload }) {
  try {
    const { companyId, postParam } = payload;
    yield put(globalActions.showSpinner());
    const response = yield companyApi.validateBeforeDelete(companyId);
    yield put(globalActions.hideSpinner());
    const { state, messages } = response.data;
    if (state) {
      yield put(companyActions.getCompanyList(postParam));
      yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_DELETE));
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(companyActions.setError(err));
    yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
  }
}

export function* getCompanyDataAndMasterDataAsync({ payload: { companyId, ingestedId } }) {
  try {
    yield put(globalActions.showSpinner());

    let actions = {
      masterDetails: companyApi.getMasterData(),
    };
    const companyDataActions = {
      companyDetails: companyApi.getCompanyDataById(companyId),
    };
    const ingestedActions = { ingestedDetails: companyApi.getIngestedData(ingestedId) };
    const lockActions = {
      lockDetails: companyApi.getUserLockDetails(companyId),
    };

    if (parseInt(ingestedId) > 0) {
      actions = { ...actions, ...ingestedActions };
    }

    if (parseInt(companyId) > 0) {
      actions = { ...actions, ...companyDataActions, ...lockActions };
    }

    const { masterDetails, lockDetails, companyDetails, ingestedDetails } = yield all(actions);

    const {
      data: { state: masterState, data: masterData, messages: masterMessages },
    } = masterDetails;

    if (masterState) {
      yield put(companyActions.getMasterDataSuccess(masterData));
    } else {
      yield put(companyActions.resetMasterData());
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(masterMessages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }

    if (companyDetails) {
      const {
        data: { state: companyState, data: companyData, messages: companyMessages },
      } = companyDetails;
      if (companyState) {
        yield put(companyActions.getCompanyDataByIdSuccess(companyData));
      } else {
        yield put(companyActions.resetCompanyState());
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: getApiErrorMessage(companyMessages),
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    }

    if (lockDetails) {
      const {
        data: { state, data, messages },
      } = lockDetails;
      if (state) {
        yield put(companyActions.getCompanyUserLockDetails(data));
        const { isEditable, lockedBy } = data;
        if (!isEditable) {
          yield put(
            globalActions.showModal({
              isOpen: true,
              message: getLockMessageInfo(lockedBy),
              modalType: MODAL_TYPES.INFO,
            }),
          );
        }
      } else {
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: getApiErrorMessage(messages),
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    }

    if (ingestedDetails) {
      const {
        data: { state: ingestedState, data: ingestedData, messages: ingestedErrorMessages },
      } = ingestedDetails;
      if (ingestedState) {
        yield put(companyActions.setCompanyIngestedData(ingestedData));
      } else {
        yield put(companyActions.resetCompanyIngestedData());
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: getApiErrorMessage(ingestedErrorMessages),
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(companyActions.resetMasterData());
    yield put(companyActions.resetCompanyState());
    yield put(companyActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
  }
}

//QUEUES

export function* getQueueTypesAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield queuesApi.getQueueTypes(payload);
    yield put(globalActions.hideSpinner());
    const {
      data: { state, data },
    } = response;
    if (state) {
      yield put(companyActions.getQueueTypesSuccess({ data }));
      // yield put(companyActions.setSelectedQueueType(data[0]));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
    }
  } catch (err) {
    yield put(companyActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
  }
}

export function* getCompanyQueueRecordsAsync({ payload: { queueDetails, isQueue } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = isQueue
      ? yield queuesApi.getCompanyQueueRecords(queueDetails)
      : yield taskApi.getTaskCompanyQueueRecords(queueDetails);
    const {
      data: { state, data },
    } = response;
    if (state) {
      isQueue
        ? yield put(companyActions.getCompanyQueueRecordsSuccess(data))
        : yield put(companyActions.getTaskQueueRecordsSuccess(data));
      yield put(globalActions.hideSpinner());
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
      isQueue
        ? yield put(companyActions.getCompanyQueueRecordsSuccess([]))
        : yield put(companyActions.getTaskQueueRecordsSuccess([]));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    isQueue
      ? yield put(companyActions.getCompanyQueueRecordsSuccess([]))
      : yield put(companyActions.getTaskQueueRecordsSuccess([]));
    yield put(companyActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
  }
}

export function* saveCompanyDetailsAsync({ payload: { request, tabData, key, history } }) {
  yield put(globalActions.showSpinner());

  try {
    const response = yield companyApi.saveCompanyDetails(request);
    const {
      data: { state, data, messages },
    } = response;
    const { prodStatusID, previousProductionStatus } = request;
    if (state) {
      yield put(companyActions.saveCompanyDetailsSuccess({ data, key }));
      if (request.updateType && request.updateType === updateTypeOnProdStatusSave.company) {
        yield put(globalActions.showAlert(getProdMessage(prodStatusID, previousProductionStatus)));
        // history &&
        //   setTimeout(() => {
        //     history.push(`/company`);
        //   }, 2000);
      } else {
        yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_SAVE));
      }
      if (tabData) {
        yield delay(2000);
        yield put(companyActions.nextFeatureSelection(tabData.value));
      }
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
    yield put(companyActions.setError(err));
  }
}

export function* updateCompanyLockDetails({ payload }) {
  try {
    yield companyApi.getUserLockDetails(payload);
  } catch (err) {
    yield put(companyActions.setError());
  }
}

export function* getCompanyStampingDetailsAsync({ payload: { body } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield companyApi.saveCompanyDetails(body);
    const { state, data } = response.data;
    if (state) {
      yield put(companyActions.getCompanyStampingDetailsSuccess({ data }));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(companyActions.setError());
  }
}

export function* releaseCompanyUserLockAsync({ payload }) {
  try {
    yield companyApi.unlockUserRecords(payload);
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
  }
}

export function* getQueueDashboardDataAsync({
  payload: { entityId, listPostParam, assigneeUserGroup },
}) {
  try {
    yield put(globalActions.showSpinner());

    const actions = {
      queueTypesAction: queuesApi.getQueueTypes(entityId),
      taskQueueActions: taskApi.getTaskQueueTypes(entityId),
      companyListAction: companyApi.getCompanyList({ payload: listPostParam }),
      taskMasterActions: taskApi.getTaskMaster(),
      assigneeUserGroupListActions: taskApi.assigneeUserGroupList(assigneeUserGroup),
    };

    const {
      queueTypesAction,
      taskQueueActions,
      companyListAction,
      taskMasterActions,
      assigneeUserGroupListActions,
    } = yield all(actions);

    if (queueTypesAction) {
      const {
        data: { state, data },
      } = queueTypesAction;

      if (state) {
        yield put(companyActions.getQueueTypesSuccess({ data }));
      }
    }

    if (taskQueueActions) {
      const {
        data: { state, data },
      } = taskQueueActions;

      if (state) {
        yield put(companyActions.getTaskQueueTypesSuccess({ data }));
      }
    }

    if (companyListAction) {
      const {
        data: { state, data },
      } = companyListAction;

      if (state) {
        yield put(companyActions.getCompanyListSuccess(data));
      }
    }
    if (taskMasterActions) {
      const {
        data: { data, state },
      } = taskMasterActions;

      if (state) {
        yield put(TaskActions.getTaskMasterSuccess({ data }));
      }
    }
    if (assigneeUserGroupListActions) {
      const {
        data: { data, state },
      } = assigneeUserGroupListActions;

      if (state) {
        yield put(TaskActions.assigneeUserGroupListSuccess({ data }));
      }
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* postExitQueueAysnc({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { queueTransactionId } = payload;
    const postExitQueueResponse = yield queuesApi.postExitQueue({ id: queueTransactionId });
    const {
      data: { state, data },
    } = postExitQueueResponse;
    if (state && data) {
      const modalData = {
        isOpen: true,
        message: 'RECORD EXIT DONE SUCCESSFULLY',
        modalType: MODAL_TYPES.SUCCESS,
      };
      yield put({ type: globalActions.showModal.type, payload: modalData });
      yield put(companyActions.getQueueTypesAndRecords(payload));
    } else {
      yield put(globalActions.hideSpinner());
      yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
    }
  } catch (err) {
    yield put(companyActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
  }
}

export function* getQueueTypesAndRecordsAsync({ payload }) {
  try {
    const { queueType, entityId, rowCount, listPostParam, pageIndex, pageSize } = payload;

    const postQueueTypesResponse = yield queuesApi.getQueueTypes(entityId);
    const {
      data: { state, data },
    } = postQueueTypesResponse;

    if (state) {
      yield put(companyActions.getQueueTypesSuccess({ data }));
      if (rowCount === 1) {
        yield put(companyActions.setQueueExpandedList([]));
        const response = yield companyApi.getCompanyList({ payload: listPostParam });
        const { state, data, messages } = response.data;
        if (state) {
          yield put(companyActions.setSelectedQueueType(queueType));
          yield put(companyActions.getCompanyListSuccess(data));
        } else {
          yield put(
            globalActions.showModal({
              isOpen: true,
              message: getApiErrorMessage(messages),
              modalType: MODAL_TYPES.ERROR,
            }),
          );
        }
        yield put(globalActions.hideSpinner());
      } else {
        const { id, parentId, rootKey } = { ...queueType };
        const queueDetails = queueType.lpSection
          ? { groupKey: id, rootKey, pageIndex, pageSize }
          : { queueId: id, groupKey: parentId, rootKey, pageIndex, pageSize };
        yield put(companyActions.getCompanyQueueRecords({ queueDetails }));
        yield put(companyActions.setSelectedQueueType(queueType));
      }
    } else {
      yield put(globalActions.hideSpinner());
      yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
    }
  } catch (err) {
    yield put(companyActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
  }
}

export function* getCompanyAssociationDetailsAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield companyApi.getCompanyAssociationDetails(payload);
    const { state, data, messages } = response.data;
    if (state) {
      yield put(companyActions.getCompanyAssociationDetailsSuccess(data));
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(companyActions.setError(err));
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: getApiErrorMessage(null),
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* getTrialDetailsBasedOnDrugAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield companyApi.getTrialDetailsBasedOnDrug(payload);
    const { state, data, messages } = response.data;
    if (state) {
      yield put(
        companyActions.getTrialDetailsBasedOnDrugSuccess({
          drugId: payload.entityPrimaryKey,
          response: data,
        }),
      );
    } else {
      yield put(companyActions.getTrialDetailsBasedOnDrugError(payload));
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(companyActions.setError(err));
    yield put(globalActions.showAlert(ALERT_MESSAGES.COMPANY_ERROR));
  }
}

export function* getCompanyProximityDataAsync({ payload: { ingestedId, duplicateIds } }) {
  try {
    yield put(globalActions.showSpinner());
    const [ingestedDetails, duplicateDetails] = yield all([
      companyApi.getIngestedData(ingestedId),
      companyApi.getCompanyDataById(duplicateIds[0]),
    ]);
    console.log(ingestedDetails, duplicateDetails);
    if (ingestedDetails && duplicateDetails) {
      const { data: ingestedData, state: ingestedState } = ingestedDetails.data;
      const { data: duplicatesData, state: duplicatesState } = duplicateDetails.data;
      if (ingestedState && duplicatesState) {
        yield put(
          companyActions.getCompanyProximityDataSuccess({
            ingestedRecordData: ingestedData,
            possibleDuplicatesData: [{ ...duplicatesData }],
          }),
        );
      } else {
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: 'Something went wrong',
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Something went wrong',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
    yield put(globalActions.hideSpinner());
  }
}

export function* getCompanyCompareDataAsync({ payload: { selectedIds, isIngestedId } }) {
  try {
    yield put(globalActions.showSpinner());
    const selectedQueueAPI =
      isIngestedId === true ? companyApi.getIngestedData : companyApi.getCompanyDataById;
    const [idOneDetails, idTwoDetails, idThreeDetails, idFourDetails] = yield all([
      selectedQueueAPI(selectedIds[0]),
      selectedQueueAPI(selectedIds[1]),
      selectedIds[2] ? selectedQueueAPI(selectedIds[2]) : '',
      selectedIds[3] ? selectedQueueAPI(selectedIds[3]) : '',
    ]);
    if (idOneDetails && idTwoDetails && idThreeDetails && idFourDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      const { data: idThreeData, state: idThreeState } = idThreeDetails.data;
      const { data: idFourData, state: idFourState } = idFourDetails.data;
      if (idOneState && idTwoState && idThreeState && idFourState) {
        yield put(
          companyActions.getCompanyCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
            compareIdThree: idThreeData,
            compareIdFour: idFourData,
          }),
        );
      }
    } else if (idOneDetails && idTwoDetails && idThreeDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      const { data: idThreeData, state: idThreeState } = idThreeDetails.data;
      if (idOneState && idTwoState && idThreeState) {
        yield put(
          companyActions.getCompanyCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
            compareIdThree: idThreeData,
          }),
        );
      }
    } else if (idOneDetails && idTwoDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      if (idOneState && idTwoState) {
        yield put(
          companyActions.getCompanyCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
          }),
        );
      }
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Something went wrong',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
    yield put(globalActions.hideSpinner());
  }
}

export const watchers = [
  function* watchGetCompanyList() {
    yield takeEvery(companyActions.getCompanyList, getCompanyListAsync);
  },
  function* watchDeleteCompanyAsync() {
    yield takeEvery(companyActions.deleteCompany, deleteCompanyAsync);
  },
  function* watchValidateCompanyBeforeDeleteAsync() {
    yield takeEvery(companyActions.validateCompanyBeforeDelete, validateCompanyBeforeDeleteAsync);
  },
  function* watchGetCompanyDataAndMasterDataAsync() {
    yield takeEvery(companyActions.getCompanyDataAndMasterData, getCompanyDataAndMasterDataAsync);
  },
  function* watchSaveCompanyDetailsAsync() {
    yield takeEvery(companyActions.saveCompanyDetails, saveCompanyDetailsAsync);
  },
  function* watchGetCompanyStampDetailsAsync() {
    yield takeEvery(companyActions.getCompanyStampingDetails, getCompanyStampingDetailsAsync);
  },
  function* watchGetQueueTypes() {
    yield takeEvery(companyActions.getQueueTypes, getQueueTypesAsync);
  },
  function* watchGetCompanyQueueRecords() {
    yield takeEvery(companyActions.getCompanyQueueRecords, getCompanyQueueRecordsAsync);
  },
  function* watchGetTaskCompanyQueueRecords() {
    yield takeEvery(companyActions.getTaskCompanyQueueRecords, getCompanyQueueRecordsAsync);
  },
  function* watchReleaseCompanyUserLockAsync() {
    yield takeEvery(companyActions.releaseCompanyUserLock, releaseCompanyUserLockAsync);
  },
  function* watchUpdateCompanyLockDetailsAsync() {
    yield takeEvery(companyActions.updateCompanyLockDetails, updateCompanyLockDetails);
  },
  function* watchGetQueueDashboardData() {
    yield takeEvery(companyActions.getQueueDashboardData, getQueueDashboardDataAsync);
  },
  function* watchPostExitQueueRecords() {
    yield takeEvery(companyActions.exitQueue, postExitQueueAysnc);
  },
  function* watchGetCompanyAssociationDetailsAsync() {
    yield takeEvery(companyActions.getCompanyAssociationDetails, getCompanyAssociationDetailsAsync);
  },
  function* watchGetTrialDetailsBasedOnDrugAsync() {
    yield takeEvery(companyActions.getTrialDetailsBasedOnDrug, getTrialDetailsBasedOnDrugAsync);
  },
  function* watchGetCompanyProximityDataAsync() {
    yield takeEvery(companyActions.getCompanyProximityData, getCompanyProximityDataAsync);
  },
  function* watchGetProximityDataAsync() {
    yield takeEvery(companyActions.getQueueTypesAndRecords, getQueueTypesAndRecordsAsync);
  },
  function* watchGetCompanyCompareDataAsync() {
    yield takeEvery(companyActions.getCompanyCompareData, getCompanyCompareDataAsync);
  },
];
