import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const CustomIcon = ({ styleName, label, handleClick }) => {
  return (
    <>
      {styleName && (
        <div className="custom-icon-wrapper">
          <span className={`${styleName} `} onClick={handleClick}></span>
          {label && <p className="icon-label">{label}</p>}
        </div>
      )}
    </>
  );
};

CustomIcon.propTypes = {
  styleName: PropTypes.string,
  label: PropTypes.string,
  handleClick: PropTypes.func,
};

CustomIcon.defaultProps = {
  label: '',
  styleName: '',
  handleClick: () => {},
};

export default CustomIcon;