import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import './index.scss';

const InputCard = ({ data, label, labelSize }) => {
  return (
    <div className="card-container">
      <label className={`font-size-${labelSize} mb-20`}>{label}</label>
      <Card>
        <CardContent>{data}</CardContent>
      </Card>
    </div>
  );
};
InputCard.propTypes = {
  label: PropTypes.string,
  data: PropTypes.func,
  labelSize: PropTypes.string,
};
InputCard.defaultProps = {
  labelSize: '16',
  data: () => {},
};

export default InputCard;
