import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { Formik } from 'formik';
import _ from 'lodash';

import {
  DNADataGrid,
  FormBuilder,
  TableActions,
  FeatureFooter,
  IngestionActions,
} from '../../../../../../framework';
import {
  resetDrugDetailsWithKey,
  getCountries,
  updateDrugDetails,
  postDrugsData,
} from '../../../../../../store/actions/drugs';
import { showModal } from '../../../../../../store/actions/globalActions';
import { ClearConfirmation, ConfirmationMessage } from '../../../../../../generic/modals';
import {
  getGridRowIndex,
  checkForIngestionAction,
  updateIngestedDataBasedOnFieldType,
  validateIngestionAction,
  isDataEqual,
  checkUserIngestionAction,
} from '../../../../../../utils/generic/helper';
import { MODAL_MESSAGES } from '../../../../../../utils/generic/constants';
import DrugLicensingAvailabilityModal from './drugLicensingAvailabilityModal';
import { initialValues, formSchema, layoutSchema, columnDefs, validate } from './config';
import './index.scss';
import { InputButton, InputRadioOptions } from '../../../../../../framework/inputs';
import { rowData } from '../../../chemicalData/tabs/patent/config';

const DrugLicensingAvailability = forwardRef(({ data, permissions: { disableEdit } }, ref) => {
  const {
    current: {
      licensingAvailability,
      licensingAvailability: { licenses },
      id: drugsId,
      key,
      drugType,
      ingestedId,
    },
    original: { licensingAvailability: originallicensingAvailability },
    masterData: { licenseStatus },
    ancillaryMasterData: { countries, countryGroups },
  } = useSelector(state => state.drugs, shallowEqual);

  const [gridData, setGridData] = useState(licenses ? licenses : []);
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [deleteConfirmStatus, setDeleteConfirmStatus] = useState(false);
  const [deleteConfirmStatusMulti, setDeleteConfirmStatusMulti] = useState(false);
  const [rowDetails, setRowDetails] = useState(null);
  const gridDataRef = useRef([]);
  const [clearModal, setClearModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [cancelDelete, setCancelDelete] = useState(true);

  const formRef = useRef(null);

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    const updatedLicenses = licenses ? licenses : [];
    setGridData(updatedLicenses);
    gridDataRef.current = updatedLicenses;
  }, [licenses]);

  useImperativeHandle(ref, () => ({
    isChanged,
    validate: () =>
      !data.tabs[data.selectedTabIndex].hasIngestedData ||
      validateIngestionAction({
        ingestedActions: {},
        data: licenses,
        type: 'drugs',
        arrayKey: 'licenses',
      }).isValid,
  }));

  const isChanged = () => {
    if (data.tabs[data.selectedTabIndex].hasIngestedData) {
      return checkUserIngestionAction(licenses, {});
    }
    return (
      formRef.current.dirty ||
      !isDataEqual(licenses, originallicensingAvailability.licenses, 'array')
    );
  };

  // country region groups
  const groups = countryGroups.map(({ id, name }) => ({
    id,
    label: name,
    checked: false,
    countryName: name,
    countryGroup: null,
  }));

  const dispatch = useDispatch();
  const gridAPI = useRef(null);
  const updatedFormSchema = {
    ...formSchema,
    country: {
      ...formSchema.country,
      options: [
        ...groups,
        ...countries.map(country => ({
          label: country.countryName,
          checked: false,
          ...country,
        })),
      ],
    },
    statusId: {
      ...formSchema.statusId,
      options: licenseStatus.map(types => ({ id: types.id, display: types.value })),
    },
  };

  let statusOptions = licenseStatus.map(types => ({ id: types.id, display: types.value }));

  const onSubmitValidationError = () => {};
  const onGridReady = params => {
    gridAPI.current = params.api;
  };

  const handleDelete = (rowData, rowIndex) => {
    setRowDetails({ rowData, rowIndex });
    setDeleteConfirmStatus(true);
  };

  const proceedToDelete = status => {
    if (status && rowDetails) {
      const updatedList = [...gridData];
      let filteredIndex;
      if (!rowDetails?.rowData?.id) {
        filteredIndex = updatedList.findIndex(
          data => data?.country?.id === rowDetails?.rowData?.country?.id,
        );
      } else filteredIndex = getGridRowIndex(rowDetails, updatedList);
      updatedList.splice(filteredIndex, 1);
      dispatch(
        updateDrugDetails({
          key: 'licensingAvailability',
          data: { ...licensingAvailability, licenses: updatedList },
        }),
      );
    }
    setRowDetails(null);
    setTimeout(() => {
      setDeleteConfirmStatus(false);
    }, 1000);
  };

  const handleEdit = (rowData, rowIndex) => {
    setRowDetails({ rowData, rowIndex });
    setEditModalStatus(true);
  };

  const onRowDetailsUpdate = result => {
    if (result) {
      const updatedList = [...gridData];
      const filteredIndex = getGridRowIndex(result, updatedList);
      updatedList.splice(filteredIndex, 1, result.rowData);
      dispatch(
        updateDrugDetails({
          key: 'licensingAvailability',
          data: { ...licensingAvailability, licenses: updatedList },
        }),
      );
    }

    setEditModalStatus(false);
    setRowDetails(null);
  };

  const handleSave = tabData => {
    const { isValid, data: drugLicenses, ingestedContentResults } = validateIngestionAction({
      ingestedActions: {},
      data: licenses,
      type: 'drugs',
      arrayKey: 'licenses',
    });
    if (isValid) {
      dispatch(
        postDrugsData({
          id: drugsId,
          body: {
            key,
            licensingAvailability: {
              ...licensingAvailability,
              licenses: drugLicenses,
            },
            ingestedContentResults,
            forceToFieldUpdate: 3,
            drugType,
            ingestedId,
          },
          tabData,
          childTabKey: ['licenses'],
        }),
      );
    } else {
      dispatch(showModal(MODAL_MESSAGES.INGESTION_ERROR));
    }
  };

  const resetDrugNames = () => {
    setClearModal(true);
  };

  const proceedToClear = status => {
    if (status) {
      if (formRef.current) {
        formRef.current.resetForm();
      }
      dispatch(
        resetDrugDetailsWithKey({ childKey: 'licenses', parentKey: 'licensingAvailability' }),
      );
    } else {
      setClearModal(false);
    }
  };

  const handleIngestionActions = (option, rowData) => {
    const updatedData = updateIngestedDataBasedOnFieldType({
      type: 'array',
      ingestionAction: option,
      current: gridDataRef.current || [],
      ingested: rowData,
    });
    dispatch(
      updateDrugDetails({
        key: 'licensingAvailability',
        data: {
          ...licensingAvailability,
          licenses: JSON.parse(JSON.stringify(updatedData)),
        },
      }),
    );
    if (gridAPI && gridAPI.current) {
      gridAPI.current.setRowData(updatedData);
    }
  };

  let newGridData = _.cloneDeep(gridData);

  let selectedCountries = selectedRows.map(row => {
    return row.data.country.countryName;
  });
  let selectedIds = selectedRows.map(row => {
    return row.data.statusId;
  });

  let isAllSame = selectedIds.every((value, index, array) => {
    return value === array[0];
  });

  let displayKey = null;
  if (isAllSame) {
    displayKey = selectedIds[0];
  }

  const handleDeleteMultiple = () => {
    setDeleteConfirmStatusMulti(true);
  };

  const proceedToDeleteMulti = status => {
    if (status) {
      let filteredData = newGridData.filter(data => {
        return !selectedCountries.includes(data.country.countryName);
      });
      setGridData(filteredData);
      setSelectedRows([]);
      setCancelDelete(true);

      dispatch(
        updateDrugDetails({
          key: 'licensingAvailability',
          data: { ...licensingAvailability, licenses: filteredData },
        }),
      );
    }
    if (!status) {
      setCancelDelete(false);
    }

    setDeleteConfirmStatusMulti(false);
  };

  const handleChange = id => {
    if (id === 1) {
      selectedRows.map(row => {
        newGridData.map(data => {
          if (data.country.id === row.data.country.id) {
            data.statusId = 1;
            data.status = 'Yes';
          }
        });
      });
      setGridData(newGridData);
      setSelectedRows([]);
      dispatch(
        updateDrugDetails({
          key: 'licensingAvailability',
          data: { ...licensingAvailability, licenses: newGridData },
        }),
      );
    }
    if (id === 2) {
      selectedRows.map(row => {
        newGridData.map(data => {
          if (data.country.id === row.data.country.id) {
            data.statusId = 2;
            data.status = 'No';
          }
        });
      });
      setGridData(newGridData);
      setSelectedRows([]);
      dispatch(
        updateDrugDetails({
          key: 'licensingAvailability',
          data: { ...licensingAvailability, licenses: newGridData },
        }),
      );
    }
    if (id === 3) {
      selectedRows.map(row => {
        newGridData.map(data => {
          if (data.country.id === row.data.country.id) {
            data.statusId = 3;
            data.status = 'Unknown';
          }
        });
      });
      setGridData(newGridData);
      setSelectedRows([]);
      dispatch(
        updateDrugDetails({
          key: 'licensingAvailability',
          data: { ...licensingAvailability, licenses: newGridData },
        }),
      );
    }
  };
  return (
    <div className="license-wrapper">
      <FeatureFooter
        handleSaveAndNext={() => handleSave(data)}
        handleSave={() => handleSave(null)}
        data={data}
        handleClear={resetDrugNames}
        disabled={disableEdit}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          const { country, statusId } = values;
          let updatedList = [...gridData];
          country
            .filter(coun => coun.checked)
            .forEach(coun => {
              const updatedValue = {
                statusId,
                country: omit(coun, ['label', 'checked', 'value']),
              };
              const duplicateCountryIndex = updatedList.findIndex(
                lic => lic.country.id === updatedValue.country.id,
              );
              if (duplicateCountryIndex !== -1) {
                updatedList.splice(duplicateCountryIndex, 1);
                updatedList = [updatedValue, ...updatedList];
              } else if (duplicateCountryIndex === -1) {
                updatedList = [updatedValue, ...updatedList];
              }
            });
          dispatch(
            updateDrugDetails({
              key: 'licensingAvailability',
              data: { ...licensingAvailability, licenses: updatedList },
            }),
          );
          resetForm();
        }}
        validate={async values => validate(values, gridData)}
      >
        {props => {
          formRef.current = props;
          return (
            <FormBuilder
              formikProps={props}
              layoutSchema={layoutSchema}
              formSchema={updatedFormSchema}
              onSubmitValidationError={onSubmitValidationError}
              disableControls={disableEdit || checkForIngestionAction([...gridData])}
            />
          );
        }}
      </Formik>
      <div style={{ display: 'flex' }}>
        <InputRadioOptions
          data={statusOptions}
          name="Set Status for Selected Countries"
          label="Set Status for Selected Countries"
          disabled={selectedRows.length === 0}
          handleChange={handleChange}
          displayKey={displayKey}
        />

        <InputButton
          variant="contained"
          buttonType="secondary-btn"
          text="Delete selected countries"
          onClick={handleDeleteMultiple}
          isDisabled={selectedRows.length === 0 && cancelDelete}
          // buttonSize = 'sm'
          style={{ marginLeft: '10px' }}
        />
      </div>
      <div className="license-wrapper-grid">
        <DNADataGrid
          columnDefs={columnDefs({
            handleDelete,
            handleEdit,
            handleIngestionActions,
            licenseStatus,
            gridData,
            disableEdit,
          })}
          rowData={JSON.parse(JSON.stringify(gridData))}
          rowHeight={35}
          frameworkComponents={{ actions: TableActions, ingestionActions: IngestionActions }}
          onGridReady={onGridReady}
          getRowClass={({ data: rowData }) => {
            return rowData && rowData.isDisabled ? 'disable-grid-row' : '';
          }}
          showStickyHeader
          rowSelection="multiple"
          onSelectionChanged={event => {
            setSelectedRows(event.api.getSelectedNodes());
          }}
        />
      </div>
      {deleteConfirmStatus && (
        <ConfirmationMessage
          isOpen
          onClose={proceedToDelete}
          message="Do you want to delete this row?"
        />
      )}
      {deleteConfirmStatusMulti && (
        <ConfirmationMessage
          isOpen
          onClose={proceedToDeleteMulti}
          message="You are DELETING all selected countries. Are you sure you want to continue?"
        />
      )}
      {editModalStatus && (
        <DrugLicensingAvailabilityModal
          licenses={gridData}
          formSchema={updatedFormSchema}
          countries={countries}
          row={rowDetails}
          onClose={onRowDetailsUpdate}
        />
      )}
      {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
    </div>
  );
});

DrugLicensingAvailability.propTypes = {
  permissions: PropTypes.object.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isTab: PropTypes.bool.isRequired,
    tabs: PropTypes.array.isRequired,
    hasIngestedData: PropTypes.bool.isRequired,
    selectedTabIndex: PropTypes.number.isRequired,
  }),
};

export default DrugLicensingAvailability;
