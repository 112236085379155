import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '../InputLabel';
import MDEditor, { commands, EditorContext } from '@uiw/react-md-editor';
import './index.scss';

const MdEditor = ({
  name,
  label,
  value,
  placeholder,
  onChange,
  size,
  isMandatory,
  isError,
  errorMessage,
  disabled,
}) => {
  let commandArray = disabled ? [] : [commands.codeEdit, commands.codeLive, commands.codePreview];
  return (
    <div name={name} className="MdEditor-text-area">
      {label && (
        <InputLabel labelFor="inputLabel" text={label} size={size} isMandatory={isMandatory} />
      )}
      <MDEditor
        textareaProps={{
          placeholder,
          disabled: disabled,
        }}
        height={300}
        preview={disabled ? 'preview' : 'live'}
        value={value}
        onChange={onChange}
        extraCommands={commandArray}
      />
      {isError && <p className="error-text">{errorMessage}</p>}
    </div>
  );
};

MdEditor.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  isMandatory: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
};
MdEditor.defaultProps = {
  placeholder: 'Add content here',
  size: '16',
  isMandatory: false,
  isError: false,
  errorMessage: '',
  disabled: false,
};

export default MdEditor;
