import { fieldTypes } from '../../../../framework/formBuilder/utils';

export const initialValues = {
  stockSymbol: '',
  stockExchange: null
};

export const layoutSchema = {
  layout: 'row',
  className: '',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'stockSymbol',
      size: 3,
      className: '',
    },
    {
      layout: 'stockExchange',
      size: 3,
      className: '',
    }
  ],
};

export const formSchema = {
  stockSymbol: {
    name: 'stockSymbol',
    type: fieldTypes.text,
    label: 'STOCK SYMBOL',
    options: [],
    props: {
      disabled: true,
      inputHeight: 'ht-lg',
    },
  },
  stockExchange: {
    name: 'stockExchange',
    type: fieldTypes.select,
    label: 'STOCK EXCHANGE',
    options: [],
    props: {
      disabled: true,
      height: 'ht-lg',
      isClearable: true,
    },
  }
};
