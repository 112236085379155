import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';

import InputIcon from '../inputs/InputIcon';
import './index.scss';

export const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

export const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const TabFilter = ({ filterUrlValue, urlHandleChange }) => {
  return (
    <>
      <Grid item xs={12}>
        <span className="urls-input-icon">
          <AntTabs value={filterUrlValue} onChange={urlHandleChange} aria-label="ant example">
            <AntTab label="VIEW ALL" />
            <AntTab label={<InputIcon size="medium" color="green" label="ACTIVE" />} />
            <AntTab label={<InputIcon size="medium" color="red" label="IN ACTIVE" />} />
            <AntTab label={<InputIcon size="medium" color="black" label="BROKEN" />} />
          </AntTabs>
        </span>
      </Grid>
    </>
  );
};

TabFilter.propTypes = {
  urlHandleChange: PropTypes.func,
  filterUrlValue: PropTypes.number,
};

export default TabFilter;
