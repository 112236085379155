import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle as faCheckCircleSolid,
  faTimesCircle as faTimesCircleSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCheckCircle as faCheckCircleRegular,
  faTimesCircle as faTimesCircleRegular,
} from '@fortawesome/free-regular-svg-icons';

import './index.scss';

const IngestionActions = ({
  children,
  isDisabled,
  ingestionAction,
  className,
  handleIngestionActions,
}) => {
  const isAccepted = () => {
    return ingestionAction === 'accept';
  };

  const isRejected = () => {
    return ingestionAction === 'reject';
  };

  const onIngestionAction = (event, action) => {
    if (ingestionAction !== action) {
      handleIngestionActions(action, event);
    }
  };

  return (
    <div className="ingestion-action-wrapper">
      {ingestionAction === 'none' ? (
        <>{children}</>
      ) : (
        <>
          <div className="ingestion-children">{children}</div>
          <div className={`${className} ingestion-actions`}>
            <button
              type="button"
              disabled={isDisabled}
              onClick={e => onIngestionAction(e, 'accept')}
            >
              <FontAwesomeIcon
                className={`ingestion-icons ${isAccepted() ? 'icon-accept' : ''}`}
                icon={isAccepted() ? faCheckCircleSolid : faCheckCircleRegular}
              />
            </button>
            <button
              type="button"
              disabled={isDisabled}
              onClick={e => onIngestionAction(e, 'reject')}
            >
              <FontAwesomeIcon
                className={`ingestion-icons ${isRejected() ? 'icon-reject' : ''}`}
                icon={isRejected() ? faTimesCircleSolid : faTimesCircleRegular}
              />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

IngestionActions.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  ingestionAction: PropTypes.oneOf(['default', 'accept', 'reject', 'none']),
  className: PropTypes.string,
  handleIngestionActions: PropTypes.func,
};

IngestionActions.defaultProps = {
  children: null,
  isDisabled: false,
  ingestionAction: 'none',
  className: '',
  handleIngestionActions: () => {},
};

export default IngestionActions;
