import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import omit from 'lodash/omit';

import { FormBuilder, Modal } from '../../../../../framework';
import { fieldTypes } from '../../../../../framework/formBuilder/utils';
import { getGridRowIndex, getFormattedDate } from '../../../../../utils/generic/helper';

import { validate, isRegulatoryActionsExists } from '../config';
import './index.scss';

const PersonAdditionalInfoModal = ({
  regulatoryActions,
  formSchema,
  layoutSchema,
  row: { rowIndex, rowData },
  onClose,
}) => {
  const formRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const initialValues = {
    ...rowData,
    date: rowData.date ? new Date(rowData.date) : null,
    regulatoryActionFlag: rowData.regulatoryActionFlag
      ? {
          value: rowData.regulatoryActionFlag.id,
          label: rowData.regulatoryActionFlag.flag,
        }
      : null,
    regulatoryActionType: {
      ...rowData.regulatoryActionType,
      value: rowData.regulatoryActionType.id,
      label: rowData.regulatoryActionType.description,
      flag: rowData.regulatoryActionType.flag
        ? {
            value: rowData.regulatoryActionType.flag.id,
            label: rowData.regulatoryActionType.flag.value,
          }
        : null,
    },
  };

  const modalLayoutSchema = {
    layout: 'row',
    className: 'person-additional-info-container',
    spacing: 3,
    justify: 'flex-start',
    alignItems: 'flex-start',
    content: [
      {
        layout: 'column',
        size: 12,
        className: '',
        spacing: 3,
        justify: 'flex-start',
        alignItems: 'flex-start',
        content: [
          {
            layout: 'date',
            size: 3,
            className: 'reg-date',
          },
          {
            layout: 'regulatoryActionType',
            size: 4,
            className: '',
          },
          {
            layout: 'regulatoryActionFlag',
            size: 4,
            className: '',
          },
          {
            layout: 'actions',
            size: 11,
            className: '',
          },
          {
            layout: 'button',
            size:2,
            className: 'add-button',
          },
        ],
      },
    ],
  };

  const clearBtn = { layout: 'clearButton', size: 2, className: 'clear-button' };
  let updatedLayoutSchema = {
    ...modalLayoutSchema,
    content: [...modalLayoutSchema.content[0].content, clearBtn],
  };

  const updatedFormSchema = {
    ...formSchema,
    button: {
      ...formSchema.button,
      label: 'SAVE',
    },
    clearButton: {
      name: 'clearButton',
      type: fieldTypes.customButton,
      label: 'CLEAR',
      props: {
        onClick: () => {
          formRef.current.resetForm();
        },
      },
    },
  };

  return (
    <Modal isOpen onClose={onClose} heading="EDIT">
      <div className="person-reg-actions-modal">
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            const regActionData = {
              ...values,
              date: values.date ? getFormattedDate(values.date) : null,

              regulatoryActionType: {
                ...omit(values.regulatoryActionType, ['label', 'value']),
                flag: values.regulatoryActionType.flag
                  ? {
                      id: values.regulatoryActionType.flag.value,
                      value: values.regulatoryActionType.flag.label,
                    }
                  : null,
              },
              regulatoryActionFlag: values.regulatoryActionFlag
                ? {
                    id: values.regulatoryActionFlag.value,
                    flag: values.regulatoryActionFlag.label,
                  }
                : null,
            };
            const updatedRegulatoryActions = [...regulatoryActions];
            const filteredRowIndex = getGridRowIndex(
              { rowIndex, rowData },
              updatedRegulatoryActions,
            );
            updatedRegulatoryActions.splice(filteredRowIndex, 1);
            const updatedRegulatoryAction = omit(regActionData, ['flag']);
            if (isRegulatoryActionsExists(updatedRegulatoryActions, updatedRegulatoryAction)) {
              setErrorMessage('Combination already exists');
            } else {
              setErrorMessage('');
              onClose({ rowIndex, rowData: updatedRegulatoryAction });
            }
          }}
          validate={async values => validate(values)}
        >
          {props => {
            formRef.current = props;
            return (
              <>
                <FormBuilder
                  formikProps={props}
                  layoutSchema={updatedLayoutSchema}
                  formSchema={updatedFormSchema}
                  onSubmitValidationError={() => {}}
                />
                {errorMessage && <p className="error-text">{errorMessage}</p>}
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

PersonAdditionalInfoModal.propTypes = {
  regulatoryActions: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  formSchema: PropTypes.object.isRequired,
  layoutSchema: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PersonAdditionalInfoModal;
