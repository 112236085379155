/* eslint-disable react/prop-types */
import React, { useState, useEffect, Fragment } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';

import './index.scss';

const ExpansionPanelGenerator = ({
  title,
  panels,
  selectedKey,
  handleExpand = () => true,
  disablePanelClass = {},
  footer,
  isViewMode = false,
}) => {
  const [selectedTabGroup, setSelectedTabGroup] = useState({ tabKey: selectedKey });
  const [tabList, setTabList] = useState([...panels]);

  useEffect(() => {
    setTabList(([...panels]))
  }, [panels])

  const handleTabExpand = (tabKey, selectedState, isExpanded) => {
    if (isViewMode) {
      const updatedTabData = [...tabList].map(tab => {
        if (tab.tabKey === tabKey) {
          return {
            ...tab,
            isExpanded: !isExpanded,
          };
        } else {
          return {
            ...tab,
          };
        }
      });
      setTabList(updatedTabData);
    } else if (selectedTabGroup.tabKey === tabKey) {
      setSelectedTabGroup({ tabKey: '' });
    } else if (handleExpand(selectedState)) {
      setSelectedTabGroup({ tabKey });
    }
  };

  useEffect(() => {
    setSelectedTabGroup({ tabKey: selectedKey });
  }, [selectedKey]);

  // TO DO: should be created as child component, disabling lint issue as of now.
  const createPanel = ({
    tabName,
    details,
    tabKey,
    selectedState,
    disable = false,
    tabStyle,
    isExpanded = false,
  }) => {
    return (
      <ExpansionPanel
        expanded={isViewMode ? isExpanded : selectedTabGroup.tabKey === tabKey}
        style={disable ? disablePanelClass : {}}
        TransitionProps={{ unmountOnExit: true }}
      >
        <ExpansionPanelSummary>
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={
              <FontAwesomeIcon
                onClick={() => handleTabExpand(tabKey, selectedState, isExpanded)}
                icon={
                  selectedTabGroup.tabKey === tabKey || isExpanded ? faMinusSquare : faPlusSquare
                }
              />
            }
            style={tabStyle}
            label={tabName}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{details}</ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  return (
    <div className="expansion-panel-main-container">
      {title && <div className="expansion-panel-header">{title}</div>}
      <div className="expansion-panel-content">
        {!isViewMode &&
          panels.map(panel => <Fragment key={panel.tabKey}>{createPanel(panel)}</Fragment>)}
        {isViewMode && tabList.map(tab => <Fragment key={tab.tabKey}>{createPanel(tab)}</Fragment>)}
      </div>
      {footer && <div className="expansion-panel-footer">{footer}</div>}
    </div>
  );
};

ExpansionPanelGenerator.propTypes = {
  title: PropTypes.oneOfType(PropTypes.object, PropTypes.string),
  footer: PropTypes.any,
  panels: PropTypes.arrayOf(
    PropTypes.exact({
      tabName: PropTypes.string,
      tabKey: PropTypes.any,
      details: PropTypes.any,
      selectedState: PropTypes.any,
      disable: PropTypes.bool,
    }),
  ),
  selectedKey: PropTypes.string,
  disablePanelClass: PropTypes.object,
  handleExpand: PropTypes.func,
  isViewMode: PropTypes.bool,
};

export default ExpansionPanelGenerator;
