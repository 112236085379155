import { createAction } from '@reduxjs/toolkit';

// action definitions
const companyRoot = 'company/tabs/';
export const setSelectedTabIndex = createAction(`${companyRoot}setSelectedTabIndex`);
export const nextFeatureSelection = createAction(`${companyRoot}goToNextFeature`);
export const featureSelection = createAction(`${companyRoot}selected`);
export const setError = createAction(`${companyRoot}error`);

export const getCompanyList = createAction(`${companyRoot}getCompanyList`);
export const getCompanyListSuccess = createAction(`${companyRoot}getCompanyListSuccess`);
export const deleteCompany = createAction(`${companyRoot}deleteCompany`);
export const validateCompanyBeforeDelete = createAction(
  `${companyRoot}validateCompanyBeforeDelete`,
);
export const deleteCompanySuccess = createAction(`${companyRoot}deleteCompanySuccess`);

export const getCompanyDataAndMasterData = createAction(
  `${companyRoot}getCompanyDataAndMasterData`,
);
export const getMasterDataSuccess = createAction(`${companyRoot}getMasterDataSuccess`);

export const getCompanyDataById = createAction(`${companyRoot}getCompanyDataById`);
export const getCompanyDataByIdSuccess = createAction(`${companyRoot}getCompanyDataByIdSuccess`);

export const resetCompanyState = createAction(`${companyRoot}resetCompanyState`);
export const resetMasterData = createAction(`${companyRoot}resetMasterData`);

export const updateCompanyDetails = createAction(`${companyRoot}updateCompanyDetails`);

export const getCompanyStampingDetails = createAction(`${companyRoot}getCompanyStampingDetails`);
export const getCompanyStampingDetailsSuccess = createAction(
  `${companyRoot}getCompanyStampingDetailsSuccess`,
);

export const saveCompanyDetails = createAction(`${companyRoot}saveCompanyDetails`);
export const saveCompanyDetailsSuccess = createAction(`${companyRoot}saveCompanyDetailsSuccess`);

export const resetCompanyData = createAction(`${companyRoot}resetCompanyData`);
export const setPreviewSelection = createAction(`${companyRoot}setPreviewSelection`);

export const setCompanyValidationStatus = createAction(`${companyRoot}setCompanyValidationStatus`);
export const setCompanyChangeStatus = createAction(`${companyRoot}setCompanyChangeStatus`);

//Queues
export const setSelectedQueueType = createAction(`${companyRoot}setSelectedQueueType`);
export const getQueueDashboardData = createAction(`${companyRoot}getQueueDashboardData`);
export const exitQueue = createAction(`${companyRoot}exitQueue`);
export const setQueueExpandedList = createAction(`${companyRoot}setQueueExpandedList`);
export const setQueueTransactionId = createAction(`${companyRoot}setQueueTransactionId`);

export const getCompanyQueueRecords = createAction(`${companyRoot}getQueueRecords`);
export const getTaskCompanyQueueRecords = createAction(`${companyRoot}getTaskCompanyQueueRecords`);
export const getTaskQueueRecordsSuccess = createAction(`${companyRoot}getTaskQueueRecordsSuccess`);
export const getCompanyQueueRecordsSuccess = createAction(`${companyRoot}getQueueRecordsSuccess`);
export const getQueueTypes = createAction(`${companyRoot}getQueueTypes`);
export const getTaskQueueTypes = createAction(`${companyRoot}getTaskQueueTypes`);
export const getTaskQueueTypesSuccess = createAction(`${companyRoot}getTaskQueueTypesSuccess`);
export const getQueueTypesSuccess = createAction(`${companyRoot}getQueueTypesSuccess`);
export const getQueueTypesAndRecords = createAction(`${companyRoot}getQueueTypesAndRecords`);

export const setCompanyIngestedData = createAction(`${companyRoot}setCompanyIngestedData`);
export const resetCompanyIngestedData = createAction(`${companyRoot}resetCompanyIngestedData`);
export const updateCompanyIngestedActions = createAction(
  `${companyRoot}updateCompanyIngestedActions`,
);
export const updateCompanyChildValues = createAction(`${companyRoot}updateCompanyChildValues`);
export const getCompanyUserLockDetails = createAction(`${companyRoot}getCompanyUserLockDetails`);
export const releaseCompanyUserLock = createAction(`${companyRoot}releaseCompanyUserLock`);
export const resetCompanyUserLock = createAction(`${companyRoot}resetCompanyUserLock`);
export const updateCompanyLockDetails = createAction(`${companyRoot}updateCompanyLockDetails`);

export const getCompanyAssociationDetails = createAction(
  `${companyRoot}getCompanyAssociationDetails`,
);
export const getCompanyAssociationDetailsSuccess = createAction(
  `${companyRoot}getCompanyAssociationDetailsSuccess`,
);
export const getTrialDetailsBasedOnDrug = createAction(`${companyRoot}getTrialDetailsBasedOnDrug`);
export const getTrialDetailsBasedOnDrugSuccess = createAction(
  `${companyRoot}getTrialDetailsBasedOnDrugSuccess`,
);
export const getTrialDetailsBasedOnDrugError = createAction(
  `${companyRoot}getTrialDetailsBasedOnDrugError`,
);

export const getCompanyProximityData = createAction(`${companyRoot}getCompanyProximityData`);
export const getCompanyProximityDataSuccess = createAction(
  `${companyRoot}getCompanyProximityDataSuccess`,
);
export const resetCompanyProximityData = createAction(`${companyRoot}resetCompanyProximityData`);

// compare actions
export const getCompanyCompareData = createAction(`${companyRoot}getCompanyCompareData`);
export const getCompanyCompareDataSuccess = createAction(
  `${companyRoot}getCompanyCompareDataSuccess`,
);
export const resetCompanyCompareData = createAction(`${companyRoot}resetCompanyCompareData`);
