import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TreeView from '../treeView';

const QueueNavigation = ({
  treeData,
  handleQueueClick,
  toggleSideBar,
  isSideBarClose,
  queueExpandedList,
  queueTypeSelected,
  handlePinToggle,
  isSidePinEnable,
}) => {
  const [defaultId, setDefaultId] = useState(2);
  const [parentId, setParentId] = useState(0);

  useEffect(() => {
    // updated this logic to fix queue selection issue
    if (queueTypeSelected && queueTypeSelected.type === 1) {
      setDefaultId(queueTypeSelected.type);
      setParentId(queueTypeSelected.parentId);
    } else if (queueTypeSelected && queueTypeSelected.type === 2) {
      setDefaultId(2);
      setParentId(0);
    }
  }, [queueTypeSelected]);

  return (
    <TreeView
      type="sidebar"
      treeList={treeData}
      parentId={parentId}
      defaultId={defaultId}
      toggleSideBar={toggleSideBar}
      isSideBarClose={isSideBarClose}
      handleQueueClick={handleQueueClick}
      queueExpandedList={queueExpandedList}
      queueTypeSelected={queueTypeSelected}
      handlePinToggle={() => handlePinToggle(!isSidePinEnable)}
      isSidePinEnable={isSidePinEnable}
    />
  );
};

QueueNavigation.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  handleQueueClick: PropTypes.func,
  treeData: PropTypes.array.isRequired,
  toggleSideBar: PropTypes.func,
  isSideBarClose: PropTypes.bool,
  queueExpandedList: PropTypes.array,
  queueTypeSelected: PropTypes.object,
  isSidePinEnable: PropTypes.bool,
  handlePinToggle: PropTypes.func,
};

QueueNavigation.defaultProps = {};

export default QueueNavigation;
