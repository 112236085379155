import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faHistory } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';

import './index.scss';

const FeatureFooter = ({ handleClear, handleSave, disabled }) => {
  return (
    <Paper className={`footer-bar ${disabled ? 'disableIcon' : ''}  `} elevation={3} square>
      <Tooltip title="Save Changes">
        <div className="save-icon" onClick={handleSave}>
          <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
        </div>
      </Tooltip>
      <Tooltip title="Undo Changes">
        <div className="clear-icon" onClick={handleClear}>
          <FontAwesomeIcon icon={faHistory}></FontAwesomeIcon>
        </div>
      </Tooltip>
    </Paper>
  );
};

FeatureFooter.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FeatureFooter;
