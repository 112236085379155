const companyIngestionFields = [
  // Basic & Contact Info
  {
    fieldId: 1001,
    fieldName: 'companyStatus',
  },
  {
    fieldId: 1002,
    fieldName: 'ownership',
  },
  {
    fieldId: 1003,
    fieldName: 'employees',
  },
  {
    fieldId: 1004,
    fieldName: 'establishedYear',
  },
  {
    fieldId: 1005,
    fieldName: 'parentCompany',
  },
  {
    fieldId: 1006,
    fieldName: 'website',
  },
  {
    fieldId: 1007,
    fieldName: 'phone',
  },
  {
    fieldId: 1008,
    fieldName: 'fax',
  },
  {
    fieldId: 1009,
    fieldName: 'companySummary',
  },
  {
    fieldId: 1010,
    fieldName: 'street1',
  },
  {
    fieldId: 1011,
    fieldName: 'street2',
  },
  {
    fieldId: 1012,
    fieldName: 'country',
  },
  {
    fieldId: 1013,
    fieldName: 'state',
  },
  {
    fieldId: 1014,
    fieldName: 'city',
  },
  {
    fieldId: 1015,
    fieldName: 'postalCode',
  },
  {
    fieldId: 1016,
    fieldName: 'nextReviewDate', // ingestion not done for this field
  },
  // Additional Info
  {
    fieldId: 1017,
    fieldName: 'stockSymbol',
  },
  {
    fieldId: 1018,
    fieldName: 'stockExchange',
  },
  {
    fieldId: 1019,
    fieldName: 'formerName',
  },
  {
    fieldId: 1020,
    fieldName: 'acquiredOrSubsidiaryCompanies',
  },
  {
    fieldId: 1021,
    fieldName: 'financialEntires',
  },
  {
    fieldId: 1022,
    fieldName: 'supportingUrls',
  },
  {
    fieldId: 1023,
    fieldName: 'prodStatusID', // ingestion not done for this field
  },
  // Notes
  {
    fieldId: 1024,
    fieldName: 'notes', // ingestion not done for this field
  },
];

export default companyIngestionFields;
