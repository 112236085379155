import { fieldTypes } from '../../framework/formBuilder/utils';
import { TEXT_LIMIT } from '../../utils/generic/constants';
import { createCheckIsModalSelected } from '../../utils/generic/helper';

export const modalRadioOptions = ['fullyorMostlyPubliclyAvailable', 'notPubliclyAvailable'];
export const checkIsRegistryModalSelected = arr =>
  createCheckIsModalSelected(modalRadioOptions)(arr);
export const validate = (values, isValidate, isOtherTab) => {
  const errors = {};
  const isOthersSelected = values?.registryProtocolAdditionalInfo?.some(
    value => value?.registryProtocolAdditionalInfo?.value.trim() === 'Other (described below)',
  );
  if (values?.otherInformationSubmitted?.trim()?.length > TEXT_LIMIT[3000]) {
    errors.otherInformationSubmitted = 'Please enter only 3000 characters';
  }
  if (values?.trailRegistrationNoInformation?.trim()?.length > 400) {
    errors.trailRegistrationNoInformation = 'Please enter only 400 characters';
  }
  if (values?.registryStepsForProtocol?.trim()?.length > TEXT_LIMIT[3000]) {
    errors.registryStepsForProtocol = 'Please enter only 3000 characters';
  }
  if (values.isProtocolsRegistered === null && isValidate) {
    errors.isProtocolsRegistered = 'Please select can protocol be registered';
  }

  if (values.isProtocolsRegistered && isValidate) {
    if (!values.registryStepsForProtocol?.trim()) {
      errors.registryStepsForProtocol = 'Please enter registry steps for registration';
    }
    if (isOtherTab) {
      if (!checkIsRegistryModalSelected(values.registryProtocolRegistration)) {
        errors.registryProtocolRegistration = 'Please select the protocols format';
      }
      if (!checkIsRegistryModalSelected(values.registryProtocolAdditionalInfo)) {
        errors.registryProtocolAdditionalInfo = 'Please select the additional information';
      }
    }
  }
  if (isOthersSelected && isValidate) {
    if (!values.otherInformationSubmitted?.trim()) {
      errors.otherInformationSubmitted = 'Please enter other information';
    }

    if (!values.trailRegistrationNoInformation?.trim()) {
      errors.trailRegistrationNoInformation = 'Please enter trial registration number information';
    }
  }

  return errors;
};

export const layoutSchema = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  style: {
    gap: '1rem',
  },
  content: [
    {
      layout: 'isProtocolsRegistered',
      size: 12,
    },
  ],
};

export const layoutSchemaOtherInfo = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  style: {
    gap: '1rem',
  },
  className: 'otherInfo',
  content: [
    {
      layout: 'otherInformationSubmitted',
      size: 12,
    },

    {
      layout: 'trailRegistrationNoInformation',
      size: 4,
      className: 'trailRegistrationNoInformation',
    },

    {
      layout: 'registryStepsForProtocol',
      size: 4,
      className: 'registryStepsForProtocol',
    },
  ],
};

export const formSchema = {
  isProtocolsRegistered: {
    label: 'can protocols be registered?',
    name: 'isProtocolsRegistered',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: true,
    },
  },
};

export const formSchemaOtherInfo = {
  otherInformationSubmitted: {
    label: "'Other' information submitted with the protocol/CTA (or N/A)",
    name: 'otherInformationSubmitted',
    type: fieldTypes.textArea,
    props: {
      isMandatory: false,
      rowsMin: 2,
    },
  },
  trailRegistrationNoInformation: {
    label: 'Trial registration number information',
    name: 'trailRegistrationNoInformation',
    type: fieldTypes.textArea,
    props: {
      isMandatory: false,
      rowsMin: 2,
    },
  },
  registryStepsForProtocol: {
    label: 'Registry Steps for registration',
    name: 'registryStepsForProtocol',
    type: fieldTypes.textArea,
    props: {
      isMandatory: true,
      rowsMin: 2,
    },
  },
};

export const columnDefs = ({ handleChange, gridData, updateData, disableEdit }) => {
  return [
    {
      headerName: '',
      width: 300,
      headerClass: [],
      field: 'registryProtocolRegistration.isSelected',
      cellRenderer: 'checkbox',
      cellRendererParams: params => {
        const { api, rowIndex, value, data } = params;
        return {
          id: rowIndex.toString(),
          checked: value,
          value: '',
          label: data?.registryProtocolRegistration?.value,
          disabled: disableEdit,
          onChange: ({ isChecked }) => {
            const gridData = [];
            const reset = {};
            for (let index = 0; index < api.getDisplayedRowCount(); index++) {
              let rowData = api.getDisplayedRowAtIndex(index).data;
              rowData = {
                ...rowData,
                registryProtocolRegistration: {
                  ...rowData.registryProtocolRegistration,
                  isSelected:
                    index === rowIndex
                      ? isChecked
                      : rowData.registryProtocolRegistration.isSelected,
                },
                fullyorMostlyPubliclyAvailable:
                  index === rowIndex ? false : rowData.fullyorMostlyPubliclyAvailable,
                notPubliclyAvailable: index === rowIndex ? false : rowData.notPubliclyAvailable,
              };
              gridData.push(rowData);
            }
            const rowData = {
              ...data,
              registryProtocolRegistration: {
                ...data.registryProtocolRegistration,
                isSelected: isChecked,
              },
            };
            handleChange({ gridData, rowIndex, rowData });
          },
        };
      },
    },
    {
      headerName: 'FULLY OR MOSTLY PUBLICLY AVAILABLE',
      field: 'fullyorMostlyPubliclyAvailable',
      cellRenderer: 'radio',
      width: 200,
      // valueFormatter: params => isDataEmpty(params ? params.data.person.id : ''),
      cellRendererParams: ({ api, rowIndex, value, data }) => {
        const gridData = [];

        for (let index = 0; index < api.getDisplayedRowCount(); index += 1) {
          const rowInfo = api.getDisplayedRowAtIndex(index).data;
          const updatedRowData = {
            ...rowInfo,
            fullyorMostlyPubliclyAvailable:
              index === rowIndex
                ? data.registryProtocolRegistration.isSelected
                  ? true
                  : false
                : rowInfo.fullyorMostlyPubliclyAvailable,
            notPubliclyAvailable: index === rowIndex ? false : rowInfo.notPubliclyAvailable,
          };
          gridData.push(updatedRowData);
        }

        // handleChange({ gridData });

        return {
          disabled: data.isChildCopy || disableEdit,
          checked: false,
          onChange: event => {
            handleChange({ gridData });
          },
          size: 'small',
          name: 'radio-test1',
        };
      },
    },
    {
      headerName: 'NOT PUBLICLY AVAILABLE',
      field: 'notPubliclyAvailable',
      cellRenderer: 'radio',
      // valueFormatter: params => isDataEmpty(params ? params.data.role.id : ''),
      cellRendererParams: ({ api, rowIndex, value, data }) => {
        const gridData = [];
        for (let index = 0; index < api.getDisplayedRowCount(); index += 1) {
          const rowInfo = api.getDisplayedRowAtIndex(index).data;
          const updatedRowData = {
            ...rowInfo,
            fullyorMostlyPubliclyAvailable:
              index === rowIndex ? false : rowInfo.fullyorMostlyPubliclyAvailable,
            notPubliclyAvailable:
              index === rowIndex
                ? data.registryProtocolRegistration.isSelected
                  ? true
                  : false
                : rowInfo.notPubliclyAvailable,
          };
          gridData.push(updatedRowData);
        }
        return {
          disabled: data.isChildCopy || disableEdit,
          checked: false,
          onChange: () => {
            handleChange({ gridData });
          },
          name: 'radio-test2',
        };
      },
    },
  ];
};

export const additionalInfoColumnDefs = ({ handleChange, disableEdit }) => [
  {
    headerName: '',
    width: 250,
    field: 'registryProtocolAdditionalInfo.isSelected',
    // sortable: (entityName !== "drugs"),
    cellRenderer: 'checkbox',
    cellRendererParams: params => {
      const { api, rowIndex, value, data } = params;
      return {
        id: rowIndex.toString(),
        checked: value,
        value: '',
        label: data?.registryProtocolAdditionalInfo?.value,
        disabled: disableEdit,
        onChange: ({ isChecked }) => {
          const gridData = [];
          const reset = {};
          for (let index = 0; index < api.getDisplayedRowCount(); index++) {
            let rowData = api.getDisplayedRowAtIndex(index).data;
            rowData = {
              ...rowData,
              registryProtocolAdditionalInfo: {
                ...rowData.registryProtocolAdditionalInfo,
                isSelected:
                  index === rowIndex
                    ? isChecked
                    : rowData.registryProtocolAdditionalInfo.isSelected,
              },
              fullyorMostlyPubliclyAvailable:
                index === rowIndex ? false : rowData.fullyorMostlyPubliclyAvailable,
              notPubliclyAvailable: index === rowIndex ? false : rowData.notPubliclyAvailable,
            };
            gridData.push(rowData);
          }
          const rowData = {
            ...data,
            registryProtocolAdditionalInfo: {
              ...data.registryProtocolAdditionalInfo,
              isSelected: isChecked,
            },
          };
          handleChange({ gridData, rowIndex, rowData, type: 'additionalInfo' });
        },
      };
    },
  },
  // {
  //     headerName: '',
  //     field: 'protocolFormats.id',
  //     width: 500,
  //     // valueFormatter: params => isDataEmpty(params ? params.data.organization.id : ''),
  // },
  {
    headerName: 'FULLY OR MOSTLY PUBLICLY AVAILABLE',
    field: 'fullyorMostlyPubliclyAvailable',
    cellRenderer: 'radio',
    width: 200,
    // valueFormatter: params => isDataEmpty(params ? params.data.person.id : ''),
    cellRendererParams: ({ api, rowIndex, value, data }) => {
      const gridData = [];

      for (let index = 0; index < api.getDisplayedRowCount(); index += 1) {
        const rowInfo = api.getDisplayedRowAtIndex(index).data;
        const updatedRowData = {
          ...rowInfo,
          fullyorMostlyPubliclyAvailable:
            index === rowIndex
              ? data.registryProtocolAdditionalInfo.isSelected
                ? true
                : false
              : rowInfo.fullyorMostlyPubliclyAvailable,
          notPubliclyAvailable: index === rowIndex ? false : rowInfo.notPubliclyAvailable,
          noRequiredToBeUpload: index === rowIndex ? false : rowInfo.noRequiredToBeUpload,
        };
        gridData.push(updatedRowData);
      }

      // handleChange({ gridData });

      return {
        disabled: data.isChildCopy || disableEdit,
        checked: false,
        onChange: event => {
          handleChange({ gridData, type: 'additionalInfo' });
        },
        name: 'radio-test1',
      };
    },
  },
  {
    headerName: 'NOT PUBLICLY AVAILABLE',
    field: 'notPubliclyAvailable',
    cellRenderer: 'radio',
    width: 150,
    // valueFormatter: params => isDataEmpty(params ? params.data.role.id : ''),
    cellRendererParams: ({ api, rowIndex, value, data }) => {
      const gridData = [];
      for (let index = 0; index < api.getDisplayedRowCount(); index += 1) {
        const rowInfo = api.getDisplayedRowAtIndex(index).data;
        const updatedRowData = {
          ...rowInfo,
          fullyorMostlyPubliclyAvailable:
            index === rowIndex ? false : rowInfo.fullyorMostlyPubliclyAvailable,
          notPubliclyAvailable:
            index === rowIndex
              ? data.registryProtocolAdditionalInfo.isSelected
                ? true
                : false
              : rowInfo.notPubliclyAvailable,
        };
        gridData.push(updatedRowData);
      }
      return {
        disabled: data.isChildCopy || disableEdit,
        checked: false,
        onChange: () => {
          handleChange({ gridData, type: 'additionalInfo' });
        },
        name: 'radio-test2',
      };
    },
  },
];
