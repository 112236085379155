import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';

import InputLabel from '../InputLabel';


const InputMultiSelect = ({ label, maxWidth, data, variant, onChange }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  function onSelectChange(e) {
    setSelectedValues(e.target.value);
    onChange(e.target.value);
  }

  return (
    <>
      {label && <InputLabel labelFor="selectLabel" text={label} />}
      <Select
        style={{ maxWidth }}
        variant={variant}
        multiple
        value={selectedValues}
        renderValue={selected => selected.map(option => option.label).join(', ')}
        onChange={e => onSelectChange(e)}
      >
        {data.map(option => (
          <MenuItem key={option.value} value={option}>
            <Checkbox checked={selectedValues.indexOf(option) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

InputMultiSelect.propTypes = {
  label: PropTypes.string,
  maxWidth: PropTypes.number,
  data: PropTypes.array.isRequired,
  variant: PropTypes.string,
  onChange: PropTypes.func,
};

InputMultiSelect.defaultProps = {
  label: '',
  maxWidth: 250,
  variant: 'outlined',
  onChange: () => {},
};

export default InputMultiSelect;
