import React, { useEffect } from 'react';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputLabel from '../InputLabel';
import PropTypes from 'prop-types';
import Link from '../../link';
import './index.scss';

const InputFile = ({
  label,
  isMandatory,
  file,
  onChange,
  isError,
  errorMessage,
  isClearable,
  allowEditing,
  fileData,
  disableError,
  disabled,
  multiple,
  acceptFileType,
  customFilePicker,
}) => {
  const navigate = () => {
    window.open(fileData.url);
  };
  return (
    <div>
      {customFilePicker ? (
        <div className="custom-file-wrapper">
          <input
            type="file"
            id="inputfile-custom"
            style={{ visibility: 'hidden' }}
            accept={acceptFileType}
            onChange={onChange}
            multiple={multiple}
            disabled={disabled}
          />
          <label for="inputfile-custom" className="custom-file-btn">
            IMPORT
          </label>
        </div>
      ) : (
        <>
          <InputLabel
            labelFor="inputLabel"
            text={label}
            isMandatory={isMandatory}
            isClearable={isClearable}
          />
          <input
            type="file"
            id="inputfile"
            style={{ maxWidth: '200px', whiteSpace: 'pre-wrap' }}
            accept={acceptFileType}
            onChange={onChange}
            multiple={multiple}
            disabled={disabled}
          />
          {allowEditing && (
            <div style={{ marginTop: '20px' }}>
              <Link onClick={navigate} link={fileData.name} />
            </div>
          )}
          {!disableError && isError && <p className="error-text">{errorMessage}</p>}
        </>
      )}
    </div>
  );
};

InputFile.propTypes = {
  label: PropTypes.string,
  isMandatory: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  isClearable: PropTypes.bool,
  multiple: PropTypes.bool,
  acceptFileType: PropTypes.string,
  // onChange: PropTypes.func,
};

InputFile.defaultProps = {
  label: '',
  isMandatory: false,
  isError: false,
  errorMessage: '',
  isClearable: false,
  multiple: true,
  acceptFileType: 'application/pdf',
  // onChange: () => {},
};

export default InputFile;
