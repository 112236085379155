import { isValidDate } from '../../../../utils/generic/helper';

export const validate = values => {
  let errors = {};

  if(values.outcomeStatuses.length>0 || !(/^\s*$/.test(values.detail)) || values.detailsDate)
  {
    if(values.outcomeStatuses.length === 0){
      errors.outcomeStatuses = "Please select trial outcome"
    }
    if(/^\s*$/.test(values.detail))
    {
    errors.detail = 'Please enter trial outcome details';
    }
  }

  if(values.detailsDate && !isValidDate(values.detailsDate)) {
    errors.detailsDate = 'Please enter a valid date (MMM-dd-yyyy)';
  }
  return errors;
};
