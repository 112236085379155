import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';

import { getClassNameForIngestion, getRowId } from '../../../utils/generic/helper';
import { VALIDATION_REGEX } from '../../../utils/generic/constants';

import IngestionActions from '../../ingestionActions';
import InputTextField from '../InputTextField';
import InputSelect from '../InputSelect';
import './index.scss';

const InputComboEntries = forwardRef(
  (
    {
      name,
      options,
      entries,
      label,
      isMandatory,
      isSearchable,
      isClearable,
      size,
      placeholder,
      onEntry,
      error,
      helperText,
      height,
      selectProps,
      type,
      key,
      value,
      validate,
      errorMessages,
      disabled,
      isIngestionActionDisabled,
      handleIngestionActions,
      showSortedOrder,
      valueForSorting,
    },
    ref,
  ) => {
    const [localEntries, setLocalEntries] = useState([...entries]);
    const [input, setInput] = useState('');
    const [dropDownValue, setDropDownValue] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [isDropdownEmpty, setIsDropdownEmpty] = useState(false);
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [validateError, setValidateError] = useState(false);
    const NPI_NUMBER_VALUE = 'NPI Number';
    const NPI_NUMBER_ERROR = 'Please enter a 10 digit NPI number';

    useEffect(() => setLocalEntries([...entries]), [entries]);

    useImperativeHandle(ref, () => ({
      isChanged: () => input !== '' || dropDownValue !== null,
      reset: () => {
        setInput('');
        setIsEmpty(false);

        setDropDownValue(null);
        setIsDropdownEmpty(false);

        setIsDuplicate(false);
        setValidateError(false);
      },
    }));

    const addEntry = () => {
      if (input && dropDownValue) {
        const duplicate =
          localEntries.filter(
            l =>
              l[key].id === dropDownValue.value &&
              l[value].trim().toLowerCase() === input.trim().toLowerCase(),
          ).length > 0;
        if (duplicate) {
          setIsDuplicate(true);
        } else if (
          dropDownValue?.label === NPI_NUMBER_VALUE &&
          !VALIDATION_REGEX.NPINUMBER.test(input)
        ) {
          setValidateError(true);
        } else if (validate && !validate.rule.test(input)) {
          setValidateError(true);
        } else {
          let updated = [...localEntries];
          updated.push({
            id: getRowId(updated),
            [key]: { id: dropDownValue.id, value: dropDownValue.label },
            [value]: input,
          });
          setLocalEntries(updated);
          onEntry(updated);
          setInput('');
          setDropDownValue(null);
        }
      } else {
        !input && setIsEmpty(true);
        !dropDownValue && setIsDropdownEmpty(true);
      }
    };

    const deleteEntry = (index, entry) => {
      let updated = [...localEntries];
      updated = updated.filter(el => el.id != entry.id);
      // updated.splice(index, 1);
      setLocalEntries(updated);
      onEntry(updated);
      setInput('');
      setDropDownValue(null);
    };

    const generateHelperText = () => {
      let errorText = '';
      if (error) {
        errorText = helperText;
      } else if (isEmpty) {
        if (errorMessages) {
          errorText = errorMessages.entry;
        } else {
          errorText = 'Entry cannot be empty';
        }
      } else if (isDuplicate) {
        if (errorMessages) {
          errorText = errorMessages.duplicate;
        } else {
          errorText = 'Duplicate entry is not allowed';
        }
      } else if (validateError && dropDownValue?.label === NPI_NUMBER_VALUE) {
        errorText = NPI_NUMBER_ERROR;
      } else if (validateError) {
        errorText = validate.message;
      }
      return errorText;
    };

    const generateHelperTextForSelect = () => {
      let msg = '';
      if (isDropdownEmpty) {
        if (errorMessages) {
          msg = errorMessages.select;
        } else {
          msg = 'Please select the value';
        }
      }
      return msg;
    };
    const getSortedSelectedOptions = selectedOptions => {
      if (!showSortedOrder) return selectedOptions;
      let sortedSelectedOptions = selectedOptions
        ? [...selectedOptions].sort(function(a, b) {
            a = Number(a?.[valueForSorting]);
            b = Number(b?.[valueForSorting]);
            if (a == b) return 0;
            return a < b ? -1 : 1;
          })
        : [];
      return sortedSelectedOptions;
    };

    return (
      <div name={name} className="input-combo-entries">
        <div className="input-combo-select">
          <InputSelect
            height={height}
            defaultValue={dropDownValue}
            label={selectProps.label}
            data={options}
            error={isDropdownEmpty}
            helperText={generateHelperTextForSelect()}
            onChange={data => {
              setDropDownValue(data);
              setIsDropdownEmpty(false);
              setIsDuplicate(false);
              setValidateError(false);
            }}
            isDisabled={disabled}
            isSearchable={isSearchable}
            isClearable={isClearable}
          />
        </div>
        <div className="input-entries">
          <div className="input-entries-text-field">
            <InputTextField
              value={input}
              onChange={e => {
                setInput(e.target.value);
                setIsEmpty(false);
                setIsDuplicate(false);
                setValidateError(false);
              }}
              label={label}
              isMandatory={isMandatory}
              size={size}
              placeholder={placeholder}
              error={isEmpty || isDuplicate || validateError}
              helperText={generateHelperText()}
              inputHeight={height}
              type={type}
              disabled={disabled}
            />
            <button
              disabled={disabled}
              type="button"
              onClick={() => {
                addEntry();
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
            </button>
          </div>

          <div className="input-entries-data">
            {localEntries.length > 0 &&
              getSortedSelectedOptions(localEntries).map((entry, index) => {
                return (
                  <IngestionActions
                    isDisabled={isIngestionActionDisabled}
                    ingestionAction={entry.ingestionAction}
                    handleIngestionActions={action => handleIngestionActions(action, entry)}
                    key={index.toString()}
                  >
                    <div className="selected-data">
                      <span
                        className={`selected-value ${getClassNameForIngestion(
                          entry.ingestionAction,
                        )}`}
                      >
                        <p>{entry[key].value} - </p>
                        <Tooltip title={entry[value]}>
                          <p>{entry[value]}</p>
                        </Tooltip>
                      </span>
                      <button
                        disabled={disabled || entry.isDisabled}
                        type="button"
                        onClick={() => deleteEntry(index, entry)}
                      >
                        <FontAwesomeIcon icon={faTimes} className="close-icon" />
                      </button>
                    </div>
                  </IngestionActions>
                );
              })}
          </div>
        </div>
      </div>
    );
  },
);

InputComboEntries.displayName = 'InputComboEntries';

InputComboEntries.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isMandatory: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  size: PropTypes.string,
  entries: PropTypes.arrayOf(PropTypes.object),
  onEntry: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.string,
  selectProps: PropTypes.object,
  options: PropTypes.array,
  key: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  validate: PropTypes.shape({ rule: PropTypes.string, message: PropTypes.string }),
  errorMessages: PropTypes.shape({
    select: PropTypes.string,
    entry: PropTypes.string,
    duplicate: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  isIngestionActionDisabled: PropTypes.bool,
  handleIngestionActions: PropTypes.func,
};

InputComboEntries.defaultProps = {
  label: '',
  placeholder: '',
  isMandatory: false,
  isSearchable: false,
  isClearable: false,
  size: 'small',
  valueCheck: '',
  height: 'ht-md',
  type: 'number',
  key: 'licenseType',
  value: 'licenseValue',
  validate: null,
  disabled: false,
  isIngestionActionDisabled: false,
  handleIngestionActions: () => {},
};

export default InputComboEntries;
