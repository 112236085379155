import { createAction } from '@reduxjs/toolkit';

//store the selected side bar value
export const sideBarSelection = createAction('administrator/sidebar/slected');
export const setSelectedTabIndex = createAction('administrator/features/setSelectedTabIndex1');
export const setRoles = createAction('administrator/features/setRoles');

export const setRolesSearchText = createAction('administrator/features/setRolesSearchText');
export const searchRoles = createAction('administrator/features/searchRoles');
export const getRoleGroup = createAction('administrator/features/getRoleGroup');
export const getRoles = createAction('administrator/features/getRoles');
export const getRoleById = createAction('administrator/features/getRoleById');
export const getRolesPermissions = createAction('administrator/features/getRolesPermissions');
export const setRolesPermissions = createAction('administrator/features/setRolesPermissions');
export const setRoleById = createAction('administrator/features/setRoleById');
export const setRoleGroup = createAction('administrator/features/setRoleGroup');
export const saveRole = createAction('administrator/features/saveRole');
export const deleteRole = createAction('administrator/features/deleteRole');

export const getUsers = createAction('administrator/features/getUsers');
export const setUsers = createAction('administrator/features/setUsers');
export const searchUsers = createAction('administrator/features/searchUsers');
export const saveAssignRoles = createAction('administrator/features/saveAssignRoles');
export const getAdministratorData = createAction('administrator/getAdministratorData');
export const setAdministratorData = createAction('administrator/setAdministratorData');
export const addAdministratorData = createAction('administrator/addAdministratorData');
export const updateAdministratorData = createAction('administrator/updateAdministratorData');
export const deleteAdministratorData = createAction('administrator/deleteAdministratorData');
export const setAdministratorDeleteData = createAction('administrator/setAdministratorDeleteData');

export const getDataSource = createAction('administrator/features/getDataSource');
export const setDataSource = createAction('administrator/features/setDataSource');
export const postDataSource = createAction('administrator/features/postDataSource');
export const putDataSource = createAction('administrator/features/putDataSource');

export const getProcessor = createAction('administrator/features/getProcessor');
export const setProcessor = createAction('administrator/features/setProcessor');
export const deleteProcessor = createAction('administrator/features/deleteProcessor');
export const searchProcessor = createAction('administrator/features/searchProcessor');
export const saveProcessor = createAction('administrator/features/saveProcessor');
export const setSaveProcessorErrorMessage = createAction(
  'administrator/features/setSaveProcessorErrorMessage',
);
export const putProcessor = createAction('administrator/features/putProcessor');

export const getAgeing = createAction('administrator/features/getAgeing');
export const getAgeingSuccess = createAction('administrator/features/getAgeingSuccess');
export const putAgeing = createAction('administrator/features/putAgeing');

export const getUserGroup = createAction('administrator/features/getUserGroup');
export const getUserGroupSuccess = createAction('administrator/features/getUserGroupSuccess');
export const deleteUserGroup = createAction('administrator/features/deleteUserGroup');
export const searchUserGroup = createAction('administrator/features/searchUserGroup');
export const saveUserGroup = createAction('administrator/features/saveUserGroup');
export const putUserGroup = createAction('administrator/features/putUserGroup');
export const saveAssignUserGroups = createAction('administrator/features/saveAssignUserGroup');

export const getQueue = createAction('administrator/features/getQueue');
export const getQueueSuccess = createAction('administrator/features/getQueueSuccess');
export const putQueue = createAction('administrator/features/putQueue');
export const getQueueMaster = createAction('administrator/features/getQueueMaster');
export const getQueueMasterSuccess = createAction('administrator/features/getQueueMasterSuccess');