import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AzureAD } from 'react-aad-msal';

import { authProvider } from './auth/authProvider';

import './index.scss';
import * as serviceWorker from './serviceWorker';
import RouterComponent from './routes';
import { store } from './store/index';
import GlobalModal from './generic/modals/GlobalModal';
import GlobalSpinner from './generic/modals/GlobalSpinner';
import GlobalAlert from './generic/modals/GlobalAlert';
import IdleLogoutTimerComponent from './framework/WatchIdleLogoutComponent';

const onRelease = () => {
  authProvider.logout()
};


ReactDOM.render(
 // <IdleLogoutTimerComponent onRelease={onRelease} timeout={20}>
  <AzureAD provider={authProvider} forceLogin={true}>
    <Provider store={store}>
      <GlobalAlert />
      <GlobalModal />
      <GlobalSpinner />
      <RouterComponent />
    </Provider>
  </AzureAD>,
  // </IdleLogoutTimerComponent>,

  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
