import { createAction } from '@reduxjs/toolkit';

// action definitions
const drugRoot = 'drugs/features/';
export const getDrugList = createAction(`${drugRoot}getDrugList`);
export const postDrugDissociation = createAction(`${drugRoot}postDrugDissociation`);
export const postDrugTransfer = createAction(`${drugRoot}postDrugTransfer`);
export const getDrugListSuccess = createAction(`${drugRoot}getDrugListSuccess`);
export const getDPLData = createAction(`${drugRoot}getDPLData`);
export const getDPLDataSuccess = createAction(`${drugRoot}getDPLDataSuccess`);

export const getGlobalStatus = createAction(`${drugRoot}getGlobalStatus`);
export const getGlobalStatusSuccess = createAction(`${drugRoot}getGlobalStatusSuccess`);
export const getCountries = createAction(`${drugRoot}getCountries`);
export const getCountriesSuccess = createAction(`${drugRoot}getCountriesSuccess`);
export const getCountryGroups = createAction(`${drugRoot}getCountryGroups`);
export const getEventTypes = createAction(`${drugRoot}getEventTypes`);
export const getEventTypesSuccess = createAction(`${drugRoot}getEventTypesSuccess`);
export const getEventMasterData = createAction(`${drugRoot}getEventMasterData`);
export const getEventMasterDataSuccess = createAction(`${drugRoot}getEventMasterDataSuccess`);
export const getOrigin = createAction(`${drugRoot}getOrigin`);
export const getOriginSuccess = createAction(`${drugRoot}getOriginSuccess`);
export const getDevelopmentPhase = createAction(`${drugRoot}getDevelopmentPhase`);
export const getDevelopmentPhaseSuccess = createAction(`${drugRoot}getDevelopmentPhaseSuccess`);
export const setGridGroupedKey = createAction(`${drugRoot}setGridGroupedKey`);
export const getTrends = createAction(`${drugRoot}getTrends`);
export const postTrendsYear = createAction(`${drugRoot}postTrendsYear`);
export const getTrendsDataSuccess = createAction(`${drugRoot}getTrendsDataSuccess`);
export const saveTrendsData = createAction(`${drugRoot}saveTrendsData`);
export const deleteTrendsData = createAction(`${drugRoot}deleteTrendsData`);
export const deleteTrendsYearData = createAction(`${drugRoot}deleteTrendsYearData`);

export const getMasterData = createAction(`${drugRoot}getMasterData`);
export const getMasterDataSuccess = createAction(`${drugRoot}getMasterDataSuccess`);
export const featureSelection = createAction(`${drugRoot}selected`);
export const getDrugDetailSuccess = createAction(`${drugRoot}getDrugDetailSuccess`);
export const resetDrugState = createAction(`${drugRoot}resetDrugState`);
export const getDrugDetails = createAction(`${drugRoot}getDrugDetails`);
export const nextFeatureSelection = createAction(`${drugRoot}goToNextFeature`);
export const setSelectedTabIndex = createAction(`${drugRoot}setSelectedTabIndex`);

export const updateDrugDetails = createAction(`${drugRoot}updateDrugDetails`);
export const resetDrugDetails = createAction(`${drugRoot}resetDrugDetails`);
export const resetDrugDetailsWithKey = createAction(`${drugRoot}resetDrugDetailsWithKey`);

export const getDrugStampingDetails = createAction(`${drugRoot}getDrugStampingDetails`);
export const getDrugStampingDetailsSuccess = createAction(
  `${drugRoot}getDrugStampingDetailsSuccess`,
);

export const postDrugsData = createAction(`${drugRoot}postDrugsData`);
export const postDrugsDataSuccess = createAction(`${drugRoot}postDrugsDataSuccess`);

export const resetDPLFilter = createAction(`${drugRoot}resetDPLFilter`);

export const deleteDrug = createAction(`${drugRoot}deleteDrug`);
export const deleteDrugSuccess = createAction(`${drugRoot}deleteDrugSuccess`);

export const fetchActivityMaster = createAction(`${drugRoot}activityMaster`);
export const setActivityMaster = createAction(`${drugRoot}StoreActivityMaster`);
export const activityMasterLoader = createAction(`${drugRoot}activityMasterLoader`);

export const getProgramLandscapeMasterData = createAction(
  `${drugRoot}getProgramLandscapeMasterData`,
);

export const getChemicalMasterData = createAction(`${drugRoot}getChemicalMasterData`);
export const getDrugTypes = createAction(`${drugRoot}getDrugTypes`);
export const getDrugTypesSuccess = createAction(`${drugRoot}getDrugTypesSuccess`);
export const updateDrugTypeDetails = createAction(`${drugRoot}updateDrugTypeDetails`);

export const getNotesType = createAction(`${drugRoot}getNotesType`);
export const getNotesTypeSuccess = createAction(`${drugRoot}getNotesTypeSuccess`);
export const setDrugValidationErrors = createAction(`${drugRoot}setDrugValidationErrors`);

export const setDrugValidationStatus = createAction(`${drugRoot}setDrugValidationStatus`);
export const setDrugChangeStatus = createAction(`${drugRoot}setDrugChangeStatus`);

// ingestion
export const getDrugIngestedData = createAction(`${drugRoot}getDrugIngestedData`);
export const getDrugIngestedDataSuccess = createAction(`${drugRoot}getDrugIngestedDataSuccess`);
export const updateDrugIngestedAction = createAction(`${drugRoot}updateDrugIngestedAction`);
export const updateDrugChildDetails = createAction(`${drugRoot}updateDrugChildDetails`);
export const resetDrugIngestedData = createAction(`${drugRoot}resetDrugIngestedData`);

// queues
export const getQueueDashboardData = createAction(`${drugRoot}getQueueDashboardData`);

export const setQueueTransactionId = createAction(`${drugRoot}setQueueTransactionId`);

export const setSelectedQueueType = createAction(`${drugRoot}setSelectedQueueType`);
export const setQueueExpandedList = createAction(`${drugRoot}setQueueExpandedList `);

export const getDrugQueueRecords = createAction(`${drugRoot}getQueueRecords`);
export const getTaskDrugQueueRecords = createAction(`${drugRoot}getTaskDrugQueueRecords`);
export const getTaskQueueRecordsSuccess = createAction(`${drugRoot}getTaskQueueRecordsSuccess`);
export const getQueueTypes = createAction(`${drugRoot}getQueueTypes`);
export const getTaskQueueTypes = createAction(`${drugRoot}getTaskQueueTypes`);
export const getTaskQueueTypesSuccess = createAction(`${drugRoot}getTaskQueueTypesSuccess`);
export const getQueueTypesAndRecords = createAction(`${drugRoot}getQueueTypesAndRecords`);
export const getDrugQueueRecordsSuccess = createAction(`${drugRoot}getQueueRecordsSuccess`);
export const getQueueTypesSuccess = createAction(`${drugRoot}getQueueTypesSuccess`);
export const setPreviewSelection = createAction(`${drugRoot}setPreviewSelection`);
export const exitQueue = createAction(`${drugRoot}exitQueue`);

// Locking Actions
export const getDrugUserLockDetails = createAction(`${drugRoot}getDrugUserLockDetails`);
export const releaseDrugUserLock = createAction(`${drugRoot}releaseDrugUserLock`);
export const resetDrugUserLock = createAction(`${drugRoot}resetDrugUserLock`);
export const updateDrugsLockDetails = createAction(`${drugRoot}updateDrugsLockDetails`);

export const getDrugAssociation = createAction(`${drugRoot}getDrugAssociation`);
export const getDrugAssociationSuccess = createAction(`${drugRoot}getDrugAssociationSuccess`);

export const getDrugTrialAssociation = createAction(`${drugRoot}getDrugTrialAssociation`);
export const getDrugTrialAssociationSuccess = createAction(
  `${drugRoot}getDrugTrialAssociationSuccess`,
);
export const getMolecularParameters = createAction(`${drugRoot}getMolecularParameters`);
export const getMolecularParametersSuccess = createAction(
  `${drugRoot}getMolecularParametersSuccess`,
);
export const createMolecularStructureData = createAction(`${drugRoot}createMolecularStructureData`);

// actions related to adding ancillary fields and fetching master data

export const getDPLAncillaryMasterData = createAction(`${drugRoot}getDPLAncillaryMasterData`);

export const setDPLAncillaryMasterData = createAction(`${drugRoot}setDPLAncillaryMasterData`);

export const postDPLAncillaryData = createAction(`${drugRoot}postDPLAncillaryData`);

export const getDrugsProximityData = createAction(`${drugRoot}getDrugsProximityData`);
export const getDrugsProximityDataSuccess = createAction(`${drugRoot}getDrugsProximityDataSuccess`);
export const resetDrugsProximityData = createAction(`${drugRoot}resetDrugsProximityData`);

export const getDPLDataForView = createAction(`${drugRoot}getDPLDataForView`);
export const getDPLDataForViewSuccess = createAction(`${drugRoot}getDPLDataForViewSuccess`);

//compare actions
export const getDrugsCompareData = createAction(`${drugRoot}getDrugsCompareData`);
export const getDrugsCompareDataSuccess = createAction(`${drugRoot}getDrugsCompareDataSuccess`);
export const resetDrugsCompareData = createAction(`${drugRoot}resetDrugsCompareData`);
