import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';
import {
  getTaskMasterSuccess,
  assigneeUserGroupList,
  assigneeUserGroupListSuccess,
  assigneeUserGroupsByQueueIdSuccess,
  resetAssigneeUserGroupsByQueueId,
  advanceSearchTaskListSuccess,
} from '../actions/task';

const task = createReducer(InitialState.task, {
  [getTaskMasterSuccess]: (state, action) => {
    state.getTaskMasterData = action.payload.data;
  },
  [assigneeUserGroupList]: (state, action) => {
    state.userGroupsByQueueId = [];
  },
  [assigneeUserGroupListSuccess]: (state, action) => {
    state.assigneeUserGroupsListData = action.payload.data;
  },
  [assigneeUserGroupsByQueueIdSuccess]: (state, action) => {
    state.userGroupsByQueueId = action.payload.data;
  },
  [resetAssigneeUserGroupsByQueueId]: state => {
    state.userGroupsByQueueId = [];
  },
  [advanceSearchTaskListSuccess]: (state, action) => {
    state.getAdvanceSearchTaskList = action.payload.data;
  },
});
export default task;
