export const setB = ["RequirementClinicalTrialCharacteristicsB", "ProtocolRegistrationRegistryB", "ResultsDisclosureInRegistryB"]

export const setC = ["RequirementClinicalTrialCharacteristicsC", "ProtocolRegistrationRegistryC", "ResultsDisclosureInRegistryC"]

export const resetTab = (tab, isResetSet1 = false) => ({
    isResetSet1,
    forceUpdate: [
        5,
        6,
        7
    ],
    clinicalTrialCharacteristicsModel: {
        tab,
        studyTypeModel: [],
        interventionalTypeModel: [],
        studyPhasesModel: [],
        studyLocationsModel: [],
        clinicalTrialCharactersDetailsModel: []
    },
    resultDisclosureModel: {
        tab,
        resultDisclosureFormats: [],
        resultDisclosure: [],
        resultsDisclosureDueDate: [],
        resultDisclosureUpdateType: [],
        resultDisclosureUpdatesDue: [],
        requirementRedactionResultType: []
    },
    protocolRegistrationRequirement: {
        tab,
        protocolRegistrationDetailsModel: [],
        protocolsRegisteredModel: [],
        requirementMilestonesModel: [],
        protocolAdditionalInfoModel: [],
        publiclyAvailableAdditionalInfoModel: [],
        publiclyAvailableProtocolInformationModel: [],
        protocolRequirementEndPointsModel: [],
        protocolRequirementUpdateTypesModel: [],
        protocolRequirementimeFramesModel: []
    }
})