import { pick, isEmpty } from 'lodash';
import { format } from 'date-fns';

import { sitesUrl } from '../../api';
import { convertDataForSelectDropdown, convertObjectForApi, onSearch } from '../generic/helper';

export const onFilteringCompany = async (value, currentCompanyId) => {
  let searchResults = await onSearch({
    searchedData: value,
    url: `${sitesUrl}/api/company/keywordsearch`,
  });
  if (searchResults) {
    const index = searchResults.findIndex(d => d.id === currentCompanyId);
    if (index !== -1) {
      searchResults.splice(index, 1);
    }
    searchResults = convertDataForSelectDropdown(searchResults, 'id', 'name').map(d => {
      return { ...pick(d, ['label', 'value']) };
    });
  }
  return searchResults;
};

export const isDuplicateCompany = (data, companyId) => {
  let isDuplicate = false;
  data.map(c => {
    if (c.acquiredOrSubsidiaryCompanyName.id === companyId) {
      isDuplicate = true;
    }
  });
  return isDuplicate;
};

export const isFinancialEntriesExists = (data, { financialYearEnd, revenue, rdSpend }) => {
  let isDataExists = false;
  data.map(c => {
    if (format(new Date(c.financialYearEnd), 'MMM yyyy') ===
        format(new Date(financialYearEnd), 'MMM yyyy')) {
      isDataExists = true;
    }
  });
  return isDataExists;
};

export const formAcquiredOrSubsidiaryCompanyDetails = ({
  id,
  acquiredOrSubsidiaryCompanyName,
  companyType,
  yearAcquired,
}) => {
  return {
    id,
    acquiredOrSubsidiaryCompanyName: isEmpty(acquiredOrSubsidiaryCompanyName)
      ? null
      : convertObjectForApi({
          data: acquiredOrSubsidiaryCompanyName,
          key: 'value',
          value: 'label',
          apiKey: 'id',
          apiValue: 'value',
        }),
    companyType,
    yearAcquired: yearAcquired ? yearAcquired.getFullYear() : null,
  };
};

export const formFinancialEntryDetails = ({ id, financialYearEnd, revenue, rdSpend, noOfEmployees }) => {
  return {
    id,
    financialYearEnd:
      financialYearEnd && financialYearEnd.setDate(1) ? financialYearEnd.toISOString() : null,
    revenue,
    rdSpend,
		noOfEmployees
  };
};
