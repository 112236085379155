import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import { useTable, useSortBy } from 'react-table';

import withClass from '../withClass';
import './index.scss';

const Table = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  return (
    <table className="rt-table" {...getTableProps()}>
      <thead className="rt-thead">
        {headerGroups.map((headerGroup, index) => (
          <tr className="rt-tr" {...headerGroup.getHeaderGroupProps()} key={index.toString()}>
            {headerGroup.headers.map((column, colIndex) => (
              <th
                className="rt-th"
                {...column.getHeaderProps(column.getSortByToggleProps())}
                key={colIndex.toString()}
              >
                {column.render('Header')}
                <span className="sort-icon">
                  {column.isSorted &&
                    (column.isSortedDesc ? <Icon>arrow_upward</Icon> : <Icon>arrow_downward</Icon>)}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="rt-tbody">
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              className={`rt-tr ${i % 2 === 0 ? 'even' : 'odd'}`}
              {...row.getRowProps()}
              key={i.toString()}
            >
              {row.cells.map((cell, cellIndex) => (
                <td className="rt-td" {...cell.getCellProps()} key={cellIndex.toString()}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
};

Table.defaultProps = {
  columnDefs: [],
  rowData: [],
};

export default withClass(Table, 'table-wrapper');
