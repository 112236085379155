const features = [
  {
    name: 'BASIC & CONTACT INFO',
    value: 'BasicAndContactInfo',
    isTab: false,
    selectedTabIndex: 0,
    key: 'basicAndContactInfo',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'ADDITIONAL INFO',
    value: 'AdditionalInfo',
    isTab: false,
    selectedTabIndex: 0,
    key: 'additionalInfo',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'ASSOCIATIONS',
    value: 'Associations',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'NOTES',
    value: 'Notes',
    isTab: false,
    selectedTabIndex: 0,
    key: 'notes',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'AUDIT TRAIL',
    value: 'AuditTrial',
    key: 'auditTrial',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
];

export default features;
