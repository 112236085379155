import { taxonomyInitialState, taxonomyValues } from '../utils/ancillaryData/taxonomyInitialState';
import drugFeaturesInitialState from '../utils/drugs/featuresInitialState';
import features from '../utils/drugs/tabs';
import personFeatures from '../utils/person/tabs';
import companyFeatures from '../utils/company/tabs';
import organizationFeatures from '../utils/organization/tabs';
import trialFeatures from '../utils/clinicalTrials/tabs';
import trialFeaturesInitialState from '../utils/clinicalTrials/featuresInitialState';
import { personDetailsState } from '../utils/person/featureInitialState';
import organizationFeaturesInitialstate from '../utils/organization/featureInitialState';
import administratorInitialState from '../utils/administrator/featureInitialState';

import { reportingInitialState, reportingValues } from '../utils/reporting/reportingInitialState';

import companyFeaturesInitialState from '../utils/company/featuresInitialState';
import userRoles from '../utils/administrator/tabs';
import reportTabs from '../utils/reporting/tabs';
import { MODAL_TYPES, ALERT_TYPES, advancedSearchListQueueType } from '../utils/generic/constants';
import registryFeatures from '../utils/registry/tabs.';
import { registryDetailsState } from '../utils/registry/featureInitialState';

import countryFeatures from '../utils/country/tabs';
import { countryDetailsState } from '../utils/country/featureInitialState';

import requirementFeatures from '../utils/requirement/tabs';
import { requirementDetailsState } from '../utils/requirement/featureInitialState';

import sourceFeatures from '../utils/sourceDocuments/tabs';
import { sourceDocumentDetailsState } from '../utils/sourceDocuments/featureInitialState';
// import countryFeatures from '../utils/country/tabs';
// import { countryDetailsState } from '../utils/country/featureInitialState';
var loggedInUserGroup = localStorage.getItem('usergroup');

const InitialState = {
  auth: {
    isAuthenticating: true,
    isAuthenticated: true,
    user: null,
    businessUnit: [],
    featurePermissions: {
      administrator: null,
      drugs: null,
      clinicalTrials: null,
      person: null,
      company: null,
      organization: null,
      registry: null,
      country: null,
    },
  },
  globalData: {
    error: null,
    modalState: {
      isOpen: false,
      message: 'Saved Successfully',
      modalType: MODAL_TYPES.SUCCESS,
    },
    showSpinner: false,
    alertStatus: {
      isOpen: false,
      message: 'Saved Successfully',
      alertType: ALERT_TYPES.SUCCESS,
    },
    selectedFeature: '',
    showAdvancedSearchExpansionPanel: false,
    crossAlertUserGroupsData: {
      userGroupsList: [],
      // latestComment: '',
      latestComment: {
        comment: '',
        createdBy: '',
        createdDate: '',
      },
    },
    ancillaryRequests: [],
    auditTrial: {
      general: { rowCount: 1, pageIndex: 1, results: [], historicalData: '' },
    },
  },
  administrator: {
    ageing: {
      drugAgeing: [],
      personAgeing: [],
      companyAgeing: [],
      organizationAgeing: [],
      ageing: [],
      requirementAgeing: [],
      registryAgeing: [],
      sourceDocAgeing: [],
      countryAgeing: [],
    },

    current: administratorInitialState,
    selected: loggedInUserGroup == 'StarUser' ? 'DataSourceGroup' : 'UsersRolesPermissions',
    tabs: userRoles,
    roleGroup: [],
    rolesList: [],
    processorList: [],
    userGroupList: [],
    usersList: {
      data: [],
    },
    dataSource: [],
    dataSourceGroupList: [],
    rolesPermissions: {
      drugPermissions: [],
      trialPermissions: [],
      adminPermissions: [],
      companyPermissions: [],
      personPermissions: [],
      orgnizationPermissions: [],
      registryPermissions: [],
      roleActions: [],
      countryPermissions: [],
      requirementPermissions: [],
      sourceDocPermissions: [],
    },
    rolesPermissionsLoading: false,
    queueSetup: [],
    queueSetupMaster: null,
  },
  clinicalTrials: {
    dashboard: {
      queues: {
        queueTypes: [],
        queueRecords: {},
        queueTypeSelected: advancedSearchListQueueType,
        queueExpandedList: [],
      },
      taskQueues: {
        taskQueueTypes: [],
        taskQueueRecords: {},
        // taskQueueTypeSelected: advancedSearchListQueueType,
        taskQueueExpandedList: [],
      },
      tasks: {},
      dashboardSelected: 'MyQueues',
    },
    records: {
      results: [],
      rowCount: 0,
    },
    original: trialFeaturesInitialState,
    current: trialFeaturesInitialState,
    ingested: {
      trialSummary: {
        data: {},
        fieldActions: {},
      },
      trialObjective: {
        data: {},
        fieldActions: {},
      },
      trialContacts: {
        data: {},
        fieldActions: {},
      },
      trialOutcome: {
        data: {},
        fieldActions: {},
      },
      patientPopulation: {
        data: {},
        fieldActions: {},
      },
      trialTiming: {
        data: {},
        fieldActions: {},
      },
      trialTreatmentPlan: {
        data: {},
        fieldActions: {},
      },
      trialResults: {
        data: {},
        fieldActions: {},
      },
      trialNotes: {
        data: {},
        fieldActions: {},
      },
    },
    queueTransactionId: 0,
    ingestedApiData: trialFeaturesInitialState,
    tabs: trialFeatures,
    dashboardTabs: 'trialDashboardFeatures',
    selected: 'TrialSummary',
    error: null,
    masterData: {
      therapeuticAreaMasters: [],
      phaseMasters: [],
      globalStatusMasters: [],
      roleMasters: [],
      sourceMasters: [],
      productionStatusMasters: [],
      armTypeMasters: [],
      patientSegmentMasters: [],
      sponsorTypeMasters: [],
      terminationReasonMasters: [],
      personMasters: [],
      companyMasters: [],
      outcomeMasters: [],
      resultTypeMasters: [],
      sourceTypeMasters: [],
      journalNames: [],
      meetingNames: [],
      defaultSchemaForSourceType: [],
      noteTypeMasters: [],
      defaultSchemaForNoteType: [],
      supportingUrlStatuses: [],
      genderTypes: [
        { label: 'Male', value: 1 },
        { label: 'Female', value: 2 },
        { label: 'Both', value: 3 },
        { label: 'N/A', value: 4 },
      ],
      ageUnits: [
        { label: 'Year(s)', value: 1 },
        { label: 'Month(s)', value: 2 },
        { label: 'Week(s)', value: 3 },
        { label: 'Day(s)', value: 4 },
        { label: 'Minute(s)', value: 9 },
        { label: 'Hour(s)', value: 10 },
      ],
    },
    isActivityMasterLoading: false,
    importContactsList: [],
    trialResultsSearchList: [],
    trialNotesSearchList: [],
    trialLockDetails: {
      isLocked: false,
      lockedBy: '',
      userLockPeriod: 30,
    },
    previewSelected: '',
    isSinglePageMode: false,
    trialTimingErrors: {},
    sponsorTypeList: [],
    proximityComparisonData: {
      ingestedRecordData: null,
      possibleDuplicatesData: [],
    },
    proximityComparisonRecData: {
      ingestedCompRecordData: null,
      ingestedCompRecordData1: null,
      ingestedCompRecordData2: null,
      ingestedCompRecordData3: null,
    },
    recordStartDateTime: null,
    dataSourceRequest: [],
  },
  drugs: {
    notesType: [],
    drugTypes: [],
    dashboard: {
      queues: {
        queueTypes: [],
        queueRecords: {},
        queueTypeSelected: advancedSearchListQueueType,
        queueExpandedList: [],
      },
      taskQueues: {
        taskQueueTypes: [],
        taskQueueRecords: {},
        // taskQueueTypeSelected: advancedSearchListQueueType,
        taskQueueExpandedList: [],
      },
      tasks: {},
      dashboardSelected: 'MyQueues',
    },
    queueTransactionId: 0,
    records: {
      results: [],
      rowCount: 0,
    },
    errors: {
      chemStructure: {
        molecularData: { chemicalName: false, smilesFormula: false },
      },
    },
    original: drugFeaturesInitialState,
    current: drugFeaturesInitialState,
    dplPagination: {
      pageIndex: 1,
      pageSize: 50,
      rowCount: 0,
      currentPage: 1,
    },
    ingested: {
      summary: {
        data: {},
        fieldActions: {},
      },
      activity: {
        data: {},
        fieldActions: {},
      },
      names: {
        data: {},
        fieldActions: {},
      },
      landscapeStatuses: {
        data: {},
        fieldActions: {},
      },
      events: {
        data: {},
        fieldActions: {},
      },
      notes: {
        data: {},
        fieldActions: {},
      },
      supportingUrls: {
        data: {},
        fieldActions: {},
      },
      licensingAvailability: {
        data: {},
        fieldActions: {},
      },
      marketing: {
        data: {},
        fieldActions: {},
      },
      phases: {
        data: {},
        fieldActions: {},
      },
      chemStructure: {
        data: {},
        fieldActions: {},
      },
    },
    tabs: features,
    selected: 'DrugName',
    error: null,
    masterData: {
      drugGlobalStatusGroup: [],
      licenseStatus: [],
      nameTypes: [],
      companyType: [],
    },
    eventMasterData: {
      diseases: [],
      countries: [],
      companies: [],
    },
    ancillaryMasterData: {
      countries: [],
      countryGroups: [],
      researchFocus: [],
      origin: [],
      deliveryRoute: [],
      deliveryMedium: [],
      deliveryTechnology: [],
      parameter: [],
      unit: [],
      eventTypes: [],
      globalStatus: [],
      developmentPhase: [],
    },
    newRecord: false,
    previewSelected: '',
    isSinglePageMode: false,
    drugLockDetails: {
      isLocked: false,
      lockedBy: '',
      userLockPeriod: 30,
    },
    drugAssociation: {
      associatedTrials: {
        associatedTrialsData: [],
        pagination: { pageSize: 50, pageIndex: 1, rowCount: 0 },
      },
      associatedDrugs: {
        associatedDrugsData: [],
        pagination: { pageSize: 50, pageIndex: 1, rowCount: 0 },
      },
    },
    proximityComparisonData: {
      ingestedRecordData: null,
      possibleDuplicatesData: [],
    },
    dplViewData: {
      ingestedDPLData: {
        drugId: 0,
        parentKey: 'ingestedDPLData',
        pagination: { pageSize: 50, pageIndex: 1, rowCount: 0 },
        tableData: [],
      },
      duplicateDPLData: {
        drugId: 0,
        parentKey: 'duplicateDPLData',
        pagination: { pageSize: 50, pageIndex: 1, rowCount: 0 },
        tableData: [],
      },
    },
    compareRecordsData: {
      compareIdOne: null,
      compareIdTwo: null,
      compareIdThree: null,
      compareIdFour: null,
    },
    resetFilter: false,
  },
  ancillaryData: {
    selected: loggedInUserGroup == 'StarUser' ? 'armType' : 'sourceName',
    taxonomyValues,
    taxonomyDetails: taxonomyInitialState,
    isSideBarOpen: true,
    ancillaryRecordById: {},
  },
  person: {
    original: personDetailsState,
    current: personDetailsState,
    tabs: personFeatures,
    selected: 'BasicAndContactInfo',
    dashboard: {
      queues: {
        queueTypes: [],
        queueRecords: {},
        queueTypeSelected: advancedSearchListQueueType,
        queueExpandedList: [],
      },
      taskQueues: {
        taskQueueTypes: [],
        taskQueueRecords: {},
        // taskQueueTypeSelected: advancedSearchListQueueType,
        taskQueueExpandedList: [],
      },
      tasks: {},
      dashboardSelected: 'MyQueues',
    },
    queueTransactionId: 0,
    masterData: {
      notesTypes: [],
      supportingUrlStatuses: [],
      degrees: [],
      specialities: [],
      personTypes: [],
      personLicenseTypes: [],
      regulatoryActionTypes: [],
    },
    basicInfo: {
      recordDates: {
        createdDate: '2020-03-04T07:19:09.401633',
        createdBy: 'CT333',
        modifiedDate: '2020-03-04T07:19:09.401633',
        modifiedBy: 'CT333',
        lastFullReviewDate: '2020-03-04T07:19:09.401633',
        lastFullReviewBy: 'CT333',
        nextFullReviewDate: '2020-03-04T07:19:09.401633',
      },
    },
    previewSelected: '',
    isSinglePageMode: false,
    ingested: {
      personBasicInfo: {
        data: {},
        fieldActions: {},
      },
      personAdditionalInfo: {
        data: {},
        fieldActions: {},
      },
    },
    personLockDetails: {
      isLocked: false,
      lockedBy: '',
      userLockPeriod: 30,
    },
    advancedSearchCategoryData: [],
    advancedSearchCategoryTitleData: [],
    allSavedSearches: [],

    personAssociation: {
      associatedTrials: {
        lastTrialStartDate: null,
        associatedTrialsData: [],
        pagination: { pageSize: 50, pageIndex: 1, rowCount: 0 },
      },
      associatedOrganizations: {
        associatedOrganizationsData: [],
        lastTrialStartDate: null,
        pagination: { pageSize: 50, pageIndex: 1, rowCount: 0 },
      },
    },
    proximityComparisonData: {
      ingestedRecordData: null,
      possibleDuplicatesData: [],
    },
    compareRecordsData: {
      compareIdOne: null,
      compareIdTwo: null,
      compareIdThree: null,
      compareIdFour: null,
    },
  },
  organization: {
    tabs: organizationFeatures,
    selected: 'BasicAndContactInfo',
    dashboard: {
      queues: {
        queueTypes: [],
        queueRecords: {},
        queueTypeSelected: advancedSearchListQueueType,
        queueExpandedList: [],
      },
      taskQueues: {
        taskQueueTypes: [],
        taskQueueRecords: {},
        // taskQueueTypeSelected: advancedSearchListQueueType,
        taskQueueExpandedList: [],
      },
      tasks: {},
      dashboardSelected: 'MyQueues',
    },
    queueTransactionId: 0,
    masterData: {},
    original: organizationFeaturesInitialstate,
    current: organizationFeaturesInitialstate,
    treeView: [],
    awsLocationServiceData: null,
    previewSelected: '',
    isSinglePageMode: false,

    ingested: {
      organizationBasicInfo: {
        data: {},
        fieldActions: {},
      },
      contactInfo: {
        data: {},
        fieldActions: {},
      },
    },
    organizationLockDetails: {
      isLocked: false,
      lockedBy: '',
      userLockPeriod: 30,
    },
    organizationAssociation: {
      currentAssociatedPeople: {
        currentPeople: [],
        lastTrialStartDate: null,
        pagination: { pageSize: 50, pageIndex: 1, rowCount: 0 },
      },
      ongoingAssociatedTrials: {
        ongoingTrials: [],
        lastTrialStartDate: null,
        pagination: { pageSize: 50, pageIndex: 1, rowCount: 0 },
      },
      allAssociatedTrials: {
        allTrials: [],
        lastTrialStartDate: null,
        pagination: { pageSize: 50, pageIndex: 1, rowCount: 0 },
      },
    },
    proximityComparisonData: {
      ingestedRecordData: null,
      possibleDuplicatesData: [],
    },
    compareRecordsData: {
      compareIdOne: null,
      compareIdTwo: null,
      compareIdThree: null,
      compareIdFour: null,
    },
  },
  company: {
    tabs: companyFeatures,
    selected: 'BasicAndContactInfo',
    dashboard: {
      queues: {
        queueTypes: [],
        queueRecords: {},
        queueTypeSelected: advancedSearchListQueueType,
        queueExpandedList: [],
      },
      taskQueues: {
        taskQueueTypes: [],
        taskQueueRecords: {},
        // taskQueueTypeSelected: advancedSearchListQueueType,
        taskQueueExpandedList: [],
      },
      tasks: {},
      dashboardSelected: 'MyQueues',
    },
    queueTransactionId: 0,
    original: companyFeaturesInitialState,
    current: companyFeaturesInitialState,
    ingested: {
      basicAndContactInfo: {
        data: {},
        fieldActions: {},
      },
      additionalInfo: {
        data: {},
        fieldActions: {},
      },
    },
    masterData: {
      ownerships: [],
      companyStatuses: [],
      notesTypes: [],
      supportingUrlStatuses: [],
      stockExchangeTypes: [],
      researchFocusTypes: [],
      relatedCompanyTypes: [],
    },
    previewSelected: '',
    isSinglePageMode: false,
    companyLockDetails: {
      isLocked: false,
      lockedBy: '',
      userLockPeriod: 30,
    },
    companyAssociations: {
      drugsData: [],
      pagination: { pageSize: 50, pageIndex: 1, rowCount: 0 },
    },
    proximityComparisonData: {
      ingestedRecordData: null,
      possibleDuplicatesData: [],
    },
    compareRecordsData: {
      compareIdOne: null,
      compareIdTwo: null,
      compareIdThree: null,
      compareIdFour: null,
    },
    recordStartDateTime: null,
  },
  task: {
    getTaskMasterData: {
      recurrenceFrequency: [],
      taskStatus: [],
      taskType: [],
      taskUrgency: [],
      tat: [],
    },
    assigneeUserGroupsListData: [],
    userGroupsByQueueId: [],
    getAdvanceSearchTaskList: {
      results: [],
    },
  },
  ancillaryRequestsData: {
    activeCompanyData: null,
  },
  advancedSearch: {
    searchRecords: {
      results: [],
      rowCount: 0,
    },
    advancedSearchCategoryData: [],
    advancedSearchCategoryTitleData: [],
    allSavedSearches: [],
    listViewColumns: [],
    previousSearchedConditions: {},
    previousSearchGroup: [],
  },

  reporting: {
    selected: 'SourceURLStatistics',
    tabs: reportTabs,
    reportingValues,
    reportingDetails: reportingInitialState,
    sourceURLReportData: [],
    diseasingReportData: [],
    agingReportData: [],
  },

  registry: {
    selected: 'RecordUpdateAndSummary',
    tabs: registryFeatures,
    registryDetails: registryDetailsState,
    original: registryDetailsState,
    current: registryDetailsState,
    masterData: {
      countrys: [],
      abbreviatedNames: [],
      registryNames: [],
      otherPermissibleRegistry: [],
      registryGovernance: [],
    },
    whoEntersData: {
      registryResponsibleInfoModel: [],
      othersResponsibleForInformation: null,
      dataRestriction: null,
      registryAuthorizedInfoModel: [],
      othersAuthorizedForInformation: null,
    },
    generalInformation: {
      disclosureCountries: [],
      registryAbbreviatedName: null,
      registryName: null,
      otherCommonAbbreviation: null,
      isGeographicalRequirement: null,
      commentsOnAcceptance: null,
      primaryOrPartner: null,
      isICMJEComplaint: null,
      registryGovernance: null,
      launchDate: null,
      registryURL: [],
      isProspectiveRegistration: null,
      prospectiveTrialFees: null,
      isRetrospectiveRegistration: null,
      retrospectiveTrialFees: null,
      commentsForFees: null,
      registryLanguage: [],
      geographicCountry: [],
      permissibleRegistries: [],
      sourceDocs: [],
      isQcReview: true,
    },
    registryData: registryDetailsState,
    registryLockDetails: {
      isLocked: false,
      lockedBy: '',
      userLockPeriod: 30,
    },
    compareRecordsData: {
      compareIdOne: null,
      compareIdTwo: null,
      compareIdThree: null,
      compareIdFour: null,
    },
  },

  sourceDocuments: {
    original: sourceDocumentDetailsState,
    current: sourceDocumentDetailsState,
    selected: 'DataEntry',
    tabs: sourceFeatures,
    previewSelected: '',
    isSinglePageMode: false,
    masterData: {
      reasonForUpdate: [],
    },
    dataEntry: {
      reasonForUpdateDescription: null,
      reasonForUpdate: null,
    },
    sourceDocumentModel: {
      country: [],
      documentTitle: null,
      documentType: null,
      regulatoryDate: null,
      sourceDocUploads: {
        fileName: null,
        language: [],
        documentURL: null,
      },
    },
    srcDocumentsLockDetails: {
      isLocked: false,
      lockedBy: '',
      userLockPeriod: 30,
    },
    compareRecordsData: {
      compareIdOne: null,
      compareIdTwo: null,
      compareIdThree: null,
      compareIdFour: null,
    },
  },

  country: {
    original: countryDetailsState,
    current: countryDetailsState,
    selected: 'RecordUpdateAndSummary',
    tabs: countryFeatures,
    previewSelected: '',
    isSinglePageMode: false,
    masterData: {
      reasonForUpdate: [],
    },
    dataEntry: {
      reasonForUpdateDescription: null,
      reasonForUpdate: null,
    },
    countryLockDetails: {
      isLocked: false,
      lockedBy: '',
      userLockPeriod: 30,
    },
  },

  requirement: {
    original: requirementDetailsState,
    current: requirementDetailsState,
    selected: 'RecordUpdateAndSummary',
    tabs: requirementFeatures,
    previewSelected: '',
    isSinglePageMode: false,
    masterData: {
      reasonForUpdate: [],
    },
    dataEntry: {
      reasonForUpdate: null,
      reasonForUpdateDescription: null,
      executiveSummary: null,
    },
    requirementLockDetails: {
      isLocked: false,
      lockedBy: '',
      userLockPeriod: 30,
    },
    compareRecordsData: {
      compareIdOne: null,
      compareIdTwo: null,
      compareIdThree: null,
      compareIdFour: null,
    },
  },
};

export default InitialState;
