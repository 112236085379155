import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faStar } from '@fortawesome/free-solid-svg-icons';

import './index.scss';

const InputIcon = ({ size, color, label, labelSize, starIcon }) => {
  const classNames = `font-size-${size} icon-color-${color}`;
  return (
    <div className="icon-container">
      <FontAwesomeIcon
        className={`${classNames}`}
        icon={starIcon ? faStar : faCircle}
        color={['#d49a90', 'purple'].includes(color) ? color : ''}
      />
      <label className={`font-size-${labelSize}`}>{label}</label>
    </div>
  );
};

InputIcon.propTypes = {
  label: PropTypes.string,
  labelSize: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  starIcon: PropTypes.bool,
};

InputIcon.defaultProps = {
  size: '16',
  color: 'green',
  starIcon: false,
};

export default InputIcon;
