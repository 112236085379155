import React, { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { setError } from '../../store/actions/globalActions';
import TabComponents from '../../utils/dashboards/tabComponents';
import './index.scss';
import { Tooltip, ListItemIcon } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = index => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    boxShadow: 'none',
  },
}));

const TabComponent = forwardRef(
  (
    {
      data,
      screen,
      handleNextFeatureSelection,
      current,
      handleSelectedTab,
      masterData,
      permissions,
    },
    ref,
  ) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);

    useEffect(() => {
      if (data.isTab) {
        handleChange(null, data.selectedTabIndex);
      }
    }, [data]);
    useEffect(() => {
      setValue(data.selectedTabIndex);
    }, [data.selectedTabIndex]);

    const handleChange = (event, newValue) => {
      handleSelectedTab(newValue);
    };
    const generateTabs = () => {
      return data.tabs.map((item, index) => (
        <Tab
          key={`${item.name}${index}`}
          label={
            <>
              {item.name}
              {item.hasError && (
                <Tooltip title="Validation Error">
                  <ListItemIcon>
                    <FontAwesomeIcon
                      size="xs"
                      color="#ef426f"
                      icon={faExclamationTriangle}
                    ></FontAwesomeIcon>
                  </ListItemIcon>
                </Tooltip>
              )}
              {item.hasUnSavedData && (
                <Tooltip title="Unsaved Changes">
                  <ListItemIcon>
                    <FontAwesomeIcon
                      size="xs"
                      color="#efe342"
                      icon={faExclamationTriangle}
                    ></FontAwesomeIcon>
                  </ListItemIcon>
                </Tooltip>
              )}
            </>
          }
          className={classNames('tab', index === data.tabs.length - 1 ? '' : 'tab-partition')}
          {...a11yProps(index)}
        />
      ));
    };
    const generateTabChildren = (tab, index) => {
      try {
        let feature = data.value;
        let FeatureComponent = TabComponents[screen][feature][tab];

        return (
          <div className="">
            <FeatureComponent
              data={data}
              isTab={index !== data.tabs.length - 1}
              handleNextFeatureSelection={
                index === data.tabs.length - 1
                  ? handleNextFeatureSelection
                  : event => handleChange(event, index + 1)
              }
              current={current}
              masterData={masterData}
              permissions={permissions}
              ref={ref}
            />
          </div>
        );
      } catch (e) {
        dispatch(setError(e));
      }
    };
    const generateTabPanel = selectedTab => {
      return data.tabs.map((item, index) => (
        <TabPanel key={`${item.name}${index}`} value={selectedTab} index={index}>
          {generateTabChildren(item.value, index)}
        </TabPanel>
      ));
    };

    return (
      <div className={classNames(classes.root, 'tab-container')}>
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            value={data.selectedTabIndex}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="simple tabs example"
          >
            {generateTabs()}
          </Tabs>
        </AppBar>
        {generateTabPanel(value)}
      </div>
    );
  },
);

TabComponent.propTypes = {
  data: PropTypes.object.isRequired,
  screen: PropTypes.string.isRequired,
  handleNextFeatureSelection: PropTypes.func.isRequired,
  current: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  handleSelectedTab: PropTypes.func,
};
TabComponent.displayName = 'TabComponent';

export default TabComponent;
