const trialFeaturesInitialState = {
  id: 0,
  ingestedId: 0,
  trialSummary: {
    name: '',
    source: null,
    protocolIds: [],
    therapeuticDiseasePatients: [],
    phase: null,
    globalStatus: null,
    primaryDrugs: [],
    secondaryDrugs: [],
    sponsors: [],
    collaborators: [],
    associatedCros: [],
    tags: [],
    locations: [],
    allLocations: [],
    primaryUrl: null,
    supportingUrls: [],
    createdDate: null,
    createdBy: '',
    modifiedDate: null,
    modifiedBy: '',
    lastFullReviewDate: null,
    lastFullReviewedBy: '',
    nextFullReviewDate: null,
  },
  trialObjective: {
    title: '',
    primaryEndPointDetail: '',
    objectivePrimaryTerms: [],
    secondaryEndPointDetail: '',
    objectiveSecondaryTerms: [],
  },
  trialContacts: {
    lastReviewedBy: '',
    lastReviewDate: null,
    isContactReviewed: false,
    contactList: [],
  },
  trialOutcome: {
    outcomeStatuses: [],
    detailsDate: null,
    detail: '',
  },
  patientPopulation: {
    id: 0,
    patientPopulation: '',
    inclusionCriteria: '',
    exclusionCriteria: '',
    targetAccrual: '',
    targetAccrualComment: '',
    interimAccrual: '',
    interimAccrualComment: '',
    date: null,
    actualAccrual: '',
    dispositionOfPatients: '',
    priorConcurrentTherapy: '',
    patientGender: '',
    minimumAge: '',
    minimumAgeUnit: 0,
    maximumAge: '',
    maximumAgeUnit: 0,
  },
  trialTiming: {
    reportedSites: '',
    identifiedSites: '',
    actualStartDate: null,
    actualEnrollmentDuration: '',
    actualEnrollmentCloseDate: null,
    actualTreatmentDuration: '',
    actualPrimaryCompletionDate: null,
    actualTimeToPublish: '',
    actualPrimaryEndpointReported: null,
    actualFullCompletionDate: null,
    anticipatedStartDate: null,
    anticipatedEnrollmentDuration: '',
    anticipatedEnrollmentCloseDate: null,
    anticipatedTreatmentDuration: '',
    anticipatedPrimaryCompletionDate: null,
    anticipatedTimeToPublish: '',
    anticipatedPrimaryEndpointReported: null,
    anticipatedFullCompletionFinalDate: null,
  },
  trialTreatmentPlan: {
    studyKeywords: [],
    studyDesign: '',
    treatmentPlan: '',
    armDetails: [],
  },
  trialResults: {
    key: '',
    resultsData: [],
  },
  trialNotes: {
    key: '',
    notesData: [],
  },
  productionStatusID: 0,
};

export default trialFeaturesInitialState;
