import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import './index.scss';

const InputRadio = ({ type, value, onChange, name, options, size, disabled, ...props }) => {
  return (
    <>
      {type === 'single' ? (
        <div className="single-radio">
          <Radio checked={value} onChange={onChange} name={name} size={size} disabled={disabled} />
          {props.label && <div className="label">{props.label}</div>}
        </div>
      ) : (
        <RadioGroup name={name} value={value} onChange={onChange}>
          {options.map(({ value: optionValue, label, disabled: optionDisabled }, index) => (
            <FormControlLabel
              key={index.toString()}
              value={optionValue}
              control={<Radio size={size} />}
              disabled={optionDisabled || disabled}
              label={label}
            />
          ))}
        </RadioGroup>
      )}
    </>
  );
};
InputRadio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  type: PropTypes.oneOf(['single', 'group']),
  size: PropTypes.oneOf(['small', 'medium']),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  isMandatory: PropTypes.bool,
};

InputRadio.defaultProps = {
  value: false,
  type: 'single',
  options: [],
  size: 'small',
  disabled: false,
  label: '',
  isMandatory: false,
};
export default InputRadio;
