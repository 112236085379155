import { isEmpty, omit, isDate, isInteger } from 'lodash';
import companyFeaturesInitialState from '../../utils/company/featuresInitialState';

import {
  convertObjectForApi,
  formIngestedData,
  updateCurrentDataBasedOnIngestion,
  checkIfArrayIsEmpty,
  checkUserIngestionAction,
} from '../../utils/generic/helper';

export const setMasterData = data => {
  const {
    companyTypes,
    companyStatuses,
    notesTypes,
    supportingUrlStatuses,
    stockExchangeTypes,
    researchFocusTypes,
    relatedCompanyTypes,
  } = data;
  return {
    ownerships: companyTypes || [],
    companyStatuses: companyStatuses || [],
    notesTypes: notesTypes || [],
    supportingUrlStatuses: supportingUrlStatuses || [],
    stockExchangeTypes: stockExchangeTypes || [],
    researchFocusTypes: researchFocusTypes || [],
    relatedCompanyTypes: relatedCompanyTypes || [],
  };
};

export const getYear = date => {
  if (!date) return null;
  if (isDate(date)) return new Date(date)?.getFullYear();
  if (isInteger(date)) return date;
};

export const setCompanyData = response => {
  const { id, ingestedId, companyBasicInfo, companyAdditionalInfo, notes, prodStatusID } = response;
  const {
    name = '',
    companyType = null,
    researchFocus = null,
    summary = '',
    //noOfEmployees = '',
    establishedYear = null,
    parentCompany = null,
    relatedCompanyType = null,
    yearAcquired = null,
    contactInfo = null,
    companyStatus = null,
    lastFullReviewDate = null,
    lastFullReviewedBy = '',
    nextFullReviewDate = null,
    createdBy = '',
    createdDate = null,
    modifiedBy = '',
    modifiedDate = null,
  } = { ...companyBasicInfo };

  const {
    websites = [],
    phoneNumbers = [],
    faxNumbers = [],
    street1 = '',
    street2 = '',
    country = null,
    state = null,
    city = '',
    postalCode = '',
  } = { ...contactInfo };

  const {
    stockExchange = null,
    stockSymbol = '',
    relatedCompanies = [],
    financialEntires = [],
    supportingUrls = [],
  } = { ...companyAdditionalInfo };

  return {
    id,
    ingestedId: ingestedId || 0,
    basicAndContactInfo: {
      companyName: name,
      researchFocus,
      companyStatus,
      ownership: companyType,
      //employees: noOfEmployees ? noOfEmployees.toString() : '',
      establishedYear: establishedYear ? new Date(establishedYear, 1, 1) : null,
      parentCompany,
      relatedCompanyType,
      yearAcquired: yearAcquired ? new Date(yearAcquired, 1, 1) : null,
      website: websites && !checkIfArrayIsEmpty(websites) && websites[0] ? websites[0] : '',
      phone: phoneNumbers
        ? phoneNumbers.map(p => {
          return {
            text: p,
          };
        })
        : [],
      fax: faxNumbers
        ? faxNumbers.map(f => {
          return {
            text: f,
          };
        })
        : [],
      companySummary: summary || '',
      street1,
      street2,
      country,
      state,
      city,
      postalCode,
      recordDates: {
        createdDate,
        createdBy,
        modifiedDate,
        modifiedBy,
        lastFullReviewDate,
        lastFullReviewBy: lastFullReviewedBy,
        nextReviewDate: nextFullReviewDate,
      },
    },
    additionalInfo: {
      stockSymbol,
      stockExchange,
      acquiredOrSubsidiaryCompanies: relatedCompanies
        ? relatedCompanies.map(({ id, relatedCompany, relatedCompanyType, yearAcquired }) => {
          return {
            id,
            acquiredOrSubsidiaryCompanyName: relatedCompany,
            companyType: isEmpty(relatedCompanyType) ? 0 : relatedCompanyType.id,
            yearAcquired: yearAcquired > 0 ? yearAcquired : null,
          };
        })
        : [],
      financialEntires: financialEntires
        ? financialEntires.map(({ id, financialYearEnd, revenue, researchSpent, noOfEmployees }) => {
          return {
            id,
            financialYearEnd,
            revenue: revenue ? revenue.toString() : '',
            rdSpend: researchSpent ? researchSpent.toString() : '',
            noOfEmployees: noOfEmployees ? noOfEmployees.toString() : ''
          };
        })
        : [],
      supportingUrls: supportingUrls || [],
    },
    notes: notes || [],
    prodStatusID: prodStatusID || 0,
  };
};

export const mapFormDataToStore = ({
  companyName,
  companyStatus,
  ownership,
  //employees,
  establishedYear,
  parentCompany,
  relatedCompanyType,
  yearAcquired,
  website,
  phone,
  fax,
  companySummary,
  street1,
  street2,
  country,
  state,
  city,
  postalCode,
}) => {
  return {
    companyName,
    companyStatus: !isEmpty(companyStatus)
      ? convertObjectForApi({
        data: companyStatus,
        key: 'value',
        value: 'label',
        apiKey: 'id',
        apiValue: 'value',
      })
      : null,
    ownership: !isEmpty(ownership)
      ? convertObjectForApi({
        data: ownership,
        key: 'value',
        value: 'label',
        apiKey: 'id',
        apiValue: 'value',
      })
      : null,
    //employees,
    establishedYear,
    parentCompany: !isEmpty(parentCompany)
      ? convertObjectForApi({
        data: parentCompany,
        key: 'value',
        value: 'label',
        apiKey: 'id',
        apiValue: 'value',
      })
      : null,
    relatedCompanyType: !isEmpty(relatedCompanyType)
      ? convertObjectForApi({
        data: relatedCompanyType,
        key: 'value',
        value: 'label',
        apiKey: 'id',
        apiValue: 'value',
      })
      : null,
    yearAcquired,
    website,
    phone,
    fax,
    companySummary,
    street1,
    street2,
    country: !isEmpty(country)
      ? convertObjectForApi({
        data: country,
        key: 'value',
        value: 'label',
        apiKey: 'id',
        apiValue: 'value',
      })
      : null,
    state,
    city,
    postalCode,
  };
};

export const getBasicAndContactInfoRequest = data => {
  const {
    id,
    ingestedId,
    recordStartDateTime,
    ingestedContentResults,
    basicAndContactInfo: {
      companyName,
      researchFocus,
      ownership,
      //employees,
      establishedYear,
      parentCompany,
      website,
      phone,
      fax,
      companySummary,
      street1,
      street2,
      country,
      state,
      city,
      postalCode,
      relatedCompanyType,
      yearAcquired,
      recordDates: {
        createdDate,
        createdBy,
        modifiedDate,
        modifiedBy,
        lastFullReviewDate,
        lastFullReviewBy,
        nextReviewDate,
      },
    },
  } = data;
  return {
    companyBasicInfo: {
      name: companyName,
      researchFocus,
      companyType: ownership,
      //noOfEmployees: isNaN(parseInt(employees)) ? null : parseInt(employees),
      establishedYear: establishedYear ? establishedYear.getFullYear() : null,
      parentCompany,
      relatedCompanyType,
      yearAcquired: getYear(yearAcquired),
      summary: companySummary,
      contactInfo: {
        websites: website ? [website] : [],
        phoneNumbers: phone ? phone.map(p => p.text) : [],
        faxNumbers: fax ? fax.map(f => f.text) : [],
        street1,
        street2,
        country,
        state,
        city,
        postalCode,
      },
      createdDate,
      createdBy,
      modifiedDate,
      modifiedBy,
      lastFullReviewDate,
      lastFullReviewedBy: lastFullReviewBy,
      nextFullReviewDate: nextReviewDate ? new Date(nextReviewDate).toDateString() : null,
    },
    recordStartDateTime,
    ingestedContentResults,
    id: parseInt(id),
    ingestedId: parseInt(ingestedId),
    updateType: 1,
  };
};

export const updateCompanyDataOnSave = ({ apiData, key, currentData }) => {
  const updatedApiData = setCompanyData(apiData);
  const {
    id,
    prodStatusID,
    basicAndContactInfo: {
      recordDates: {
        createdDate,
        createdBy,
        modifiedDate,
        modifiedBy,
        lastFullReviewDate,
        lastFullReviewBy,
        nextReviewDate,
      },
    },
  } = updatedApiData;
  if (currentData.id === 0 && currentData.ingestedId === 0) {
    updatedCurrentData = {
      ...currentData,
      ...updatedApiData,
    };
  }

  let updatedCurrentData = {
    ...currentData,
    [key]: updatedApiData[key],
  };

  updatedCurrentData = {
    ...updatedCurrentData,
    id,
    prodStatusID: parseInt(prodStatusID, 10) || 0,
    basicAndContactInfo: {
      ...updatedCurrentData.basicAndContactInfo,
      recordDates: {
        ...updatedCurrentData.basicAndContactInfo.recordDates,
        createdDate,
        createdBy,
        modifiedDate,
        modifiedBy,
        lastFullReviewDate,
        lastFullReviewBy,
        nextReviewDate,
      },
    },
  };

  const updatedOriginalData = {
    ...updatedApiData,
    ingestedId: updatedCurrentData.ingestedId,
  };

  return {
    updatedCurrentData,
    updatedOriginalData,
  };
};

export const getAdditionalInfoRequest = ({
  id,
  ingestedId,
  recordStartDateTime,
  ingestedContentResults,
  stockSymbol,
  stockExchange,
  acquiredOrSubsidiaryCompanies,
  financialEntires,
  supportingUrls,
  relatedCompanyTypes,
}) => {
  return {
    companyAdditionalInfo: {
      stockSymbol,
      stockExchange,
      relatedCompanies: acquiredOrSubsidiaryCompanies.map(
        ({ id, acquiredOrSubsidiaryCompanyName, companyType, yearAcquired }) => {
          return {
            id,
            relatedCompany: acquiredOrSubsidiaryCompanyName,
            relatedCompanyType: relatedCompanyTypes.find(c => c.id === companyType),
            yearAcquired: yearAcquired || 0,
          };
        },
      ),
      financialEntires: financialEntires.map(({ id, financialYearEnd, revenue, rdSpend, noOfEmployees }) => {
        const revenueData = parseFloat(revenue);
        const rdSpendData = parseFloat(rdSpend);
        return {
          id,
          financialYearEnd,
          revenue: Number.isInteger(revenueData) ? revenueData : Number(revenueData.toFixed(2)),
          researchSpent: Number.isInteger(rdSpendData)
            ? rdSpendData
            : Number(rdSpendData.toFixed(2)),
          noOfEmployees: noOfEmployees ? parseInt(noOfEmployees) : null
        };
      }),
      supportingUrls,
    },
    recordStartDateTime,
    ingestedContentResults,
    id: parseInt(id),
    ingestedId: parseInt(ingestedId),
    updateType: 2,
  };
};

export const generateNavbarConfig = (original, companyId) => {
  const navBarConfig = [
    {
      id: 1,
      name: 'COMPANY ID',
      value: companyId,
    },
    {
      id: 2,
      name: 'COMPANY NAME',
      value: original.companyName,
    },
  ];
  return navBarConfig;
};

export const setCompanyCurrentAndIngestedData = (currentData, ingestedData, tabs) => {
  const mappedIngestedData = setCompanyData(ingestedData);
  // remove fields that are not required for ingestion
  const updatedData = {
    ...omit(mappedIngestedData, [
      'id',
      'ingestedId',
      'prodStatusID',
      'basicAndContactInfo.companyName',
      'basicAndContactInfo.researchFocus',
      'basicAndContactInfo.recordDates',
      'notes',
    ]),
  };

  const updatedIngestedData = formIngestedData(updatedData);

  const updatedCurrentData = {
    ...updateCurrentDataBasedOnIngestion(currentData, mappedIngestedData),
    ingestedId: ingestedData.ingestedId,
    ingestedProductionStatusId: ingestedData.prodStatusID,
  };

  const ingestedKeys = Object.keys(updatedIngestedData).filter(key =>
    checkUserIngestionAction(updatedCurrentData[key], updatedIngestedData[key].fieldActions, false),
  );

  const updatedTabs = tabs.map(tab => ({
    ...tab,
    hasIngestedData: ingestedKeys.includes(tab.key),
  }));
  return {
    updatedIngestedData,
    updatedCurrentData,
    updatedTabs,
  };
};

export const getNextIngestedTab = (tabs, selected) => {
  const activeTabIndex = tabs.findIndex(tab => tab.value === selected);
  const ingestedTab = tabs.find((tab, index) => tab.hasIngestedData && index > activeTabIndex);
  return {
    ingestedTab,
    activeTabIndex,
  };
};

export const updateTrialDetailsBasedOnDrugId = (
  drugsData,
  { drugId, response: { pageSize, currentPage, rowCount, results } },
) => {
  const updatedDrugsData = [...drugsData];
  const index = updatedDrugsData.findIndex(u => u.id === drugId);
  if (index > -1) {
    const apiData = Array.isArray(results) && results.length > 0 ? results[0] : null;
    const trialsData = apiData ? apiData.result || [] : [];
    updatedDrugsData.splice(index, 1, {
      ...updatedDrugsData[index],
      trials: {
        ...updatedDrugsData[index].trials,
        trialsData,
        pagination: { pageSize, pageIndex: currentPage, rowCount },
      },
    });
  }
  return updatedDrugsData;
};

export const updateCompanyStampingDetails = (
  { lastFullReviewDate, lastFullReviewBy, nextReviewDate },
  current = companyFeaturesInitialState,
  original = companyFeaturesInitialState,
) => {
  const updateStampDatesForObject = (object = companyFeaturesInitialState) => ({
    ...object,
    basicAndContactInfo: {
      ...object.basicAndContactInfo,
      recordDates: {
        ...object.basicAndContactInfo.recordDates,
        lastFullReviewDate,
        lastFullReviewBy,
        nextReviewDate,
      },
    },
  });
  return {
    current: updateStampDatesForObject(current),
    original: updateStampDatesForObject(original),
  };
};

export const getCompanyStampDates = ({ lastFullReviewDate, lastFullReviewBy, nextReviewDate }) => {
  return {
    lastFullReviewDate,
    lastFullReviewBy,
    nextReviewDate,
  };
};
