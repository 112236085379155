import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import omit from 'lodash/omit';

import Modal from '../../../../framework/modal';
import { layoutSchema, validate } from '../config';
import { FormBuilder } from '../../../../framework';
import './index.scss';
import InputIcon from '../../../../framework/inputs/InputIcon';

const SupportingUrlsModal = ({
  urls,
  formSchema,
  row: { rowIndex, rowData },
  onClose,
  statuses,
  validateSupportingUrl,
  entityName
}) => {
  const organizationEntity = entityName == 'organization'
  const getUrlStatusLabels = urlStatus => {
    const key = urlStatus ? urlStatus.value && urlStatus.value.toUpperCase() : null;
    let result = null;
    switch (key) {
      case 'BROKEN':
				result = <InputIcon label="Broken" color="black" />;
        break;
      case 'INACTIVE':
        result = <InputIcon label="InActive" color="red" />;
        break;

      default:
        result = <InputIcon label="Active" color="green" />;
        break;
    }
    return result;
  };

  const initialValues = {
    ...rowData,

    status: {
      ...rowData.status,
      label: getUrlStatusLabels(rowData.status),
    },
  };

  const updatedContent = [...layoutSchema.content];
  updatedContent.splice(updatedContent.length - 1, 0, {
    layout: 'status',
    size: organizationEntity ? 2 : 4,
    className: 'urlStatus-mt',
  });

  const updatedLayoutSchema = {
    ...layoutSchema,
    content: [...updatedContent],
    size: organizationEntity ? updatedContent.map((content) =>
    content.layout == 'url' ? content.size = 8 : content.size = 2) : ''
  };

  const updatedFormSchema = {
    ...formSchema,
    status: {
      ...formSchema.status,
      options: statuses.map(status => ({
        ...status,
        label: getUrlStatusLabels(status),
      })),
    },
    button: {
      ...formSchema.button,
      label: 'SAVE',
    },
  };

  const onSubmitValidationError = () => {};
  return (
    <Modal isOpen onClose={onClose}>
      <div className="generic-supporting-url-modal">
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            const updatedValues = {
              ...values,
              status: {
                ...omit(values.status, ['label']),
              },
            };

            onClose({ rowIndex, rowData: updatedValues });
          }}
          validate={async values => validate(values, urls, validateSupportingUrl)}
        >
          {props => {
            return (
              <FormBuilder
                formikProps={props}
                layoutSchema={updatedLayoutSchema}
                formSchema={updatedFormSchema}
                onSubmitValidationError={onSubmitValidationError}
              />
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

SupportingUrlsModal.propTypes = {
  urls: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  formSchema: PropTypes.object.isRequired,
  statuses: PropTypes.array.isRequired,
  validateSupportingUrl: PropTypes.func,
};

SupportingUrlsModal.defaultProps = {
  validateSupportingUrl: () => {},
};

export default SupportingUrlsModal;
