import { fieldTypes } from '../../framework/formBuilder/utils';
import { DROPDOWN_TYPES } from '../../utils/generic/constants';

export const validate = (values, isValidate, isOtherTab) => {
  const isOtherSelected = values.registryInterventionTypes?.find(obj => {
    if (isOtherTab) return obj?.interventionType?.value?.trim() === 'Other (describe below)';
    else return obj?.value?.trim() === 'Other (describe below)';
  });
  const errors = {};

  if (values.otherInterventionDesc?.trim() && values.otherInterventionDesc.length > 3000) {
    errors.otherInterventionDesc = 'Please enter only 3000 characters';
  }
  if (isValidate) {
    if (!values.registryStudyTypes?.length) {
      errors.studyTypes = 'Please select study types';
    }

    if (!values.registryStudyPhases?.length) {
      errors.studyPhases = 'Please select study phases';
    }

    if (!values.registryInterventionTypes?.length) {
      errors.interventionTypes = 'Please select intervention types';
    }
    if (isOtherTab) {
      if (isOtherSelected && !isOtherSelected.otherInterventionDesc?.trim()) {
        errors.otherInterventionDesc = 'Please enter other information';
      }
    } else {
      if (isOtherSelected && !values.otherInterventionDesc?.trim()) {
        errors.otherInterventionDesc = 'Please enter other information';
      }
    }
  }
  return errors;
};

export const layoutSchema = {
  layout: 'row',
  spacing: 2,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'studyTypes',
      size: 4,
    },
    {
      layout: 'studyPhases',
      size: 4,
    },
    {
      layout: 'empty',
      size: 4,
    },
    {
      layout: 'interventionTypes',
      size: 4,
    },
    {
      layout: 'otherInterventionDesc',
      size: 4,
      className: 'otherInterventionDesc',
    },
  ],
};

export const formSchema = {
  studyTypes: {
    name: 'studyTypes',
    type: fieldTypes.select_async,
    label: 'study type(s) that can be disclosed on the registry (check all that apply)',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMandatory: true,
    },
  },
  studyPhases: {
    name: 'studyPhases',
    type: fieldTypes.select_async,
    label: 'study phase that can be disclosed on the registry (check all that apply)',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMandatory: true,
    },
  },
  interventionTypes: {
    name: 'interventionTypes',
    type: fieldTypes.select_async,
    label: 'intervention types that can be disclosed on the registry (check all that apply)',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMandatory: true,
    },
  },
  otherInterventionDesc: {
    label: 'other intervention,description',
    name: 'otherInterventionDesc',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
    },
  },
  empty: {
    name: 'otherInterventionDesc',
    type: '',
  },
};
