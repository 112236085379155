/*eslint new-cap: ["error", { "capIsNew": false }]*/
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Grid from '@material-ui/core/Grid';

import {
  FormBuilder,
  DNADataGrid,
  FeatureFooter,
  Link,
  TableActions,
  ListView,
  IngestionActions,
  DefaultCellRender,
} from '../../../../framework';
import InputRadio from '../../../../framework/inputs/InputRadio';
import {
  updateIngestedDataBasedOnFieldType,
  validateIngestionAction,
  checkForIngestionAction,
  searchCountry,
  searchState,
  searchCity,
  isDataEqual,
  getGridRowIndex,
  checkUserIngestionAction,
  convertObjectForSelectDropdown,
} from '../../../../utils/generic/helper';
import {
  updateOrganizationDetails,
  postOrganization,
  resetOrganizationDetails,
  clearAddressValidation,
  getAddressValidation,
} from '../../../../store/actions/organization';
import {
  mapFormDataToStore,
  checkAddressChanges,
  formAddressPayload,
} from '../../../../store/helpers/organization';
import ConfirmationMessage from '../../../../generic/modals/ConfirmationMessage';
import ModalMessage from '../../../../generic/modals/ModalMessage';
import ClearConfirmation from '../../../../generic/modals/clearConfirmation';
import {
  MODAL_TYPES,
  MODAL_MESSAGES,
  childFormChangeObject,
} from '../../../../utils/generic/constants';
import { showModal, showSpinner, hideSpinner } from '../../../../store/actions/globalActions';
import {
  initialValues,
  layoutSchema,
  formSchema,
  columnDefs,
  validationMessages,
  doesAddressExists,
  mapGeoLocation,
  validateForm,
  validateOnChange,
  getStateData,
  getCityData,
} from './config';
import AddressModal from './addressModal';
import './index.scss';

const AdditionalInfo = forwardRef(({ data, permissions: { disableEdit } }, ref) => {
  const dispatch = useDispatch();
  const {
    current: { id, ingestedId, contactInfo },
    original: { contactInfo: originalContactInfo },
    ingested: {
      contactInfo: { fieldActions: ingestedActions },
    },
  } = useSelector(state => state.organization, shallowEqual);
  const awsLocationServiceData = useSelector(
    state => state.organization.awsLocationServiceData,
    shallowEqual,
  );
  const { processResult } = awsLocationServiceData ? awsLocationServiceData : '';
  const gridAPI = useRef(null);
  const formRef = useRef(null);
  const contactRef = useRef([]);
  const [gridData, setGridData] = useState([]);
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [deleteConfirmStatus, setDeleteConfirmStatus] = useState(false);
  const [addConfirmStatus, setAddConfirmStatus] = useState(false);
  const [errorModalStatus, setErrorModalStatus] = useState(null);
  const [rowDetails, setRowDetails] = useState(null);
  const [rowId, setRowId] = useState(-1);
  const [addressValidator, showAddressValidator] = useState(false);
  const [formData, setFormData] = useState({});
  const [clearModal, setClearModal] = useState(false);
  const [validationRequested, setValidationRequested] = useState(false);
  const [countryCodeVal, setCountryCode] = useState('');
  const [nddCodeVal, setNddCode] = useState('');

  const phoneNumbersFormRef = useRef(childFormChangeObject);
  const faxNumbersFormRef = useRef(childFormChangeObject);
  const emailsFormRef = useRef(childFormChangeObject);
  const websitesFormRef = useRef(childFormChangeObject);
  const customScroller = useRef(null);
  useImperativeHandle(ref, () => ({
    isChanged,
    validate: () =>
      !data.hasIngestedData ||
      validateIngestionAction({
        ingestedActions,
        data: contactInfo,
        type: 'organization',
        arrayKey: 'contactInfo',
      }).isValid,
  }));

  useEffect(() => {
    if (validationRequested) {
      if (awsLocationServiceData?.results && awsLocationServiceData?.results.length) {
        const validatedAddress = formatAwsLocationSeriveDataResults();
        const updatedData = {
          ...formData,
          rowData: mapApiToStore(formData.rowData, validatedAddress),
        };
        onValidateClose(updatedData);
      } else {
        handleAddClick();
      }
    }
  }, [awsLocationServiceData]);

  const mapApiToStore = (rowData, validatedAddress) => {
    const longitude = validatedAddress?.place?.geometry?.point?.[0];
    const latitude = validatedAddress?.place?.geometry?.point?.[1];
    const geoLocation = `${latitude ? latitude : ''}${longitude ? `,${longitude}` : ''}`;
    const subRegion = validatedAddress?.place?.subRegion;
    return {
      ...rowData,
      latitude: latitude ? latitude : null,
      longitude: longitude ? longitude : null,
      geoLocation: geoLocation ? geoLocation : null,
      subRegion: subRegion || null,
      isValidated: true,
    };
  };

  const formatAwsLocationSeriveDataResults = () => {
    const formattedArray = awsLocationServiceData?.results?.slice(0, 1).map(obj => {
      return {
        ...obj,
      };
    });
    return formattedArray[0];
  };

  const isChanged = () => {
    if (data.hasIngestedData) {
      return checkUserIngestionAction(contactInfo, ingestedActions);
    } else {
      return (
        formRef.current.dirty ||
        phoneNumbersFormRef.current.isChanged() ||
        faxNumbersFormRef.current.isChanged() ||
        emailsFormRef.current.isChanged() ||
        websitesFormRef.current.isChanged() ||
        !isDataEqual(contactInfo, originalContactInfo, 'array')
      );
    }
  };

  const handleCountryChange = data => {
    if (data) {
      const { countryCode, nddCode } = data;
      setCountryCode(countryCode);
      setNddCode(nddCode);
    }
    formRef.current.setFieldValue('state', null);
    formRef.current.setFieldValue('city', null);
  };
  const handleStateChange = () => {
    formRef.current.setFieldValue('city', null);
  };

  const handleValidate = async editFormData => {
    try {
      setCountryCode('');
      setNddCode('');
      setValidationRequested(true);
      if (editFormData) {
        setFormData(editFormData);
        setEditModalStatus(false);
      } else {
        const checkForErrors = validateForm(formRef.current.values);
        if (isEmpty(checkForErrors)) {
          setFormData({
            rowIndex: null,
            rowData: { ...formRef.current.values, latitude: null, longitude: null },
          });
          // showAddressValidator(true);
          const addPayload = formAddressPayload({
            ...formRef.current.values,
            latitude: null,
            longitude: null,
          });
          dispatch(getAddressValidation(addPayload));
          formRef.current.resetForm();
        } else {
          handleSubmit({ isAddBtn: false })(formRef.current.values, formRef.current);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const filterCountry = async value => {
    const filteredData = await searchCountry(value);
    if (!filteredData) {
      dispatch(showModal(MODAL_MESSAGES.API_ERROR));
    }
    return filteredData;
  };
  const filterState = async value => {
    const country = formRef.current && formRef.current.values.country;
    let filteredData = [];
    if (country && country.value) {
      filteredData = await searchState(value, country.value);
      if (!filteredData) {
        dispatch(showModal(MODAL_MESSAGES.API_ERROR));
      }
    }
    return filteredData;
  };
  const filterCity = async value => {
    const state = formRef.current && formRef.current.values.state;
    let filteredData = [];
    if (state && state.value) {
      filteredData = await searchCity(value, state.value);
      if (!filteredData) {
        dispatch(showModal(MODAL_MESSAGES.API_ERROR));
      }
    }
    return filteredData;
  };

  const handleAddClick = () =>
    handleSubmit({ isAddBtn: true })(formRef.current.values, formRef.current);

  const updatedFormSchema = {
    ...formSchema({
      handleCountryChange,
      handleStateChange,
      handleValidate,
      filterCountry,
      filterState,
      filterCity,
      handleAddClick,
      ref: {
        phoneNumbers: phoneNumbersFormRef,
        faxNumbers: faxNumbersFormRef,
        emails: emailsFormRef,
        websites: websitesFormRef,
      },
      countryCodeVal,
      nddCodeVal,
    }),
  };

  useEffect(() => {
    const updatedGridData = mapGeoLocation([...contactInfo]);
    setGridData(updatedGridData);
    contactRef.current = contactInfo;
  }, [contactInfo]);

  const onGridReady = params => {
    gridAPI.current = params.api;
  };

  const handleChange = useCallback(
    grid => {
      if (gridAPI && gridAPI.current) {
        gridAPI.current.setRowData(grid);
      }
      dispatch(
        updateOrganizationDetails({ key: 'contactInfo', data: JSON.parse(JSON.stringify(grid)) }),
      );
    },
    [dispatch],
  );
  const handleDelete = (rowData, rowIndex) => {
    setValidationRequested(false);
    setRowDetails({ rowData, rowIndex });
    setDeleteConfirmStatus(true);
  };

  const handleEdit = (rowData, rowIndex) => {
    setValidationRequested(false);
    setRowDetails({ rowData, rowIndex });
    setEditModalStatus(true);
  };

  const saveAddress = tabData => {
    const { isValid, data: validatedData, ingestedContentResults } = validateIngestionAction({
      ingestedActions,
      data: contactInfo,
      type: 'organization',
      arrayKey: 'contactInfo',
    });
    if (isValid) {
      const isPrimaryNotAvailable = contactInfo.filter(contact => contact.isPrimary).length === 0;
      if (isPrimaryNotAvailable && contactInfo.length > 0) {
        setErrorModalStatus(validationMessages.PRIMARY_NOT_EXISTS);
      } else {
        dispatch(
          postOrganization({
            data: {
              id,
              ingestedId,
              updateType: 2,
              contactInfo: validatedData.map(element => {
                return {
                  ...element,
                  validationCode: isEmpty(element.validationCode) ? 'E' : element.validationCode,
                };
              }),
              ingestedContentResults,
            },
            key: 'contactInfo',
            tabData,
          }),
        );
      }
    } else {
      dispatch(showModal(MODAL_MESSAGES.INGESTION_ERROR));
    }
  };

  const resetAddress = () => {
    setClearModal(true);
  };

  const proceedToClear = status => {
    if (status) {
      dispatch(resetOrganizationDetails({ key: 'contactInfo' }));
      formRef.current.resetForm();

      phoneNumbersFormRef.current.reset();
      faxNumbersFormRef.current.reset();
      emailsFormRef.current.reset();
      websitesFormRef.current.reset();
    } else {
      setClearModal(false);
    }
  };

  const checkRowDataStatus = (gridRowData, rowIndex) => {
    let rowData = gridRowData;
    const contactInfoList = [...contactInfo];
    const filteredIndex = getGridRowIndex({ rowData }, contactInfoList);
    contactInfoList.splice(filteredIndex, 1);
    if (doesAddressExists(contactInfoList, rowData)) {
      setErrorModalStatus('Combination already exists');
    } else {
      const updatedList = [...contactInfo];
      const beforeUpdateRowData = updatedList[rowIndex];
      const hasChanged = checkAddressChanges(beforeUpdateRowData, rowData);
      if (!rowData.isValidated && hasChanged) {
        rowData = {
          ...rowData,
          validationCode: 'E',
          isValidated: false,
          validationDescription: null,
        };
      }
      updatedList.splice(rowIndex, 1, rowData);
      dispatch(updateOrganizationDetails({ key: 'contactInfo', data: updatedList }));
    }
  };

  const onRowDetailsUpdate = gridRowData => {
    if (gridRowData) {
      const updatedList = [...contactInfo];
      const filteredIndex = getGridRowIndex(gridRowData, updatedList);
      checkRowDataStatus(gridRowData.rowData, filteredIndex);
    }
    setEditModalStatus(false);
    setRowDetails(null);
  };

  const onValidateClose = updatedData => {
    setValidationRequested(false);
    dispatch(clearAddressValidation());
    if (updatedData) {
      let { rowIndex, rowData } = updatedData;
      if (rowIndex === null && rowData) {
        addDataToTable(rowData);
      } else if (rowData != null && rowIndex >= 0) {
        rowData = rowData ? { ...mapFormDataToStore(rowData) } : '';
        checkRowDataStatus(rowData, rowIndex);
        setRowDetails(null);
      }
    }
  };

  const proceedToDelete = status => {
    if (status && rowDetails) {
      const updatedData = [...contactInfo];
      const filteredIndex = getGridRowIndex(rowDetails, updatedData);
      updatedData.splice(filteredIndex, 1);
      dispatch(updateOrganizationDetails({ key: 'contactInfo', data: updatedData }));
    }
    setRowDetails(null);
    setTimeout(() => {
      setDeleteConfirmStatus(false);
    }, 1000);
  };

  // eslint-disable-next-line react/prop-types
  const addressValidation = ({ isValid }) => {
    return isValid ? <FontAwesomeIcon icon={faCheck} /> : '';
  };

  const addDataToTable = (values, isAddBtn) => {
    let parsed = { ...mapFormDataToStore({ ...values, id: rowId }) };
    parsed = {
      ...parsed,
      isValidated: Boolean(parsed.isValidated),
    };
    if (doesAddressExists(contactInfo, parsed)) {
      setErrorModalStatus('Combination already exists');
    } else if (isAddBtn) {
      setAddConfirmStatus(true);
    } else {
      dispatchAddDataToTable(parsed);
    }
  };

  const handleAddConfirmation = status => {
    if (status) {
      let parsedValues = { ...mapFormDataToStore({ ...formRef.current.values, id: rowId }) };
      parsedValues = {
        ...parsedValues,
        isValidated: Boolean(parsedValues.isValidated),
      };
      dispatchAddDataToTable(parsedValues);
    }
    setAddConfirmStatus(false);
  };

  const dispatchAddDataToTable = parsedValues => {
    const updatedData = [...contactInfo];
    if (updatedData.length > 0) {
      updatedData.unshift({ ...parsedValues });
    } else {
      updatedData.unshift({ ...parsedValues, isPrimary: true });
    }
    dispatch(updateOrganizationDetails({ key: 'contactInfo', data: updatedData }));
    formRef.current.resetForm();
    setRowId(rowId - 1);
  };

  const handleIngestionActions = (option, rowData) => {
    const updatedData = JSON.parse(
      JSON.stringify(
        updateIngestedDataBasedOnFieldType({
          type: 'array',
          ingestionAction: option,
          current: contactRef.current,
          ingested: rowData,
        }),
      ),
    );
    dispatch(updateOrganizationDetails({ key: 'contactInfo', data: updatedData }));
  };

  const handleSubmit = ({ isAddBtn }) => (values, { setFieldTouched, setErrors }) => {
    const customErrors = validateForm(values, isAddBtn);
    if (isEmpty(customErrors)) {
      const formValues = { ...values, latitude: null, longitude: null };
      addDataToTable(formValues, isAddBtn);
    } else {
      for (let key in customErrors) {
        if (Object.prototype.hasOwnProperty.call(customErrors, key)) {
          setFieldTouched(key, true, false);
        }
      }
      setErrors(customErrors);
    }
  };
  const onScroll = e => {
    const scrollViewportSelector = '.organization-address';
    const scrollWrapperSelector = '.customScrollbar';
    const scrollViewportElement = document.querySelector(scrollViewportSelector);
    const scrollWrapperElement = document.querySelector(scrollWrapperSelector);
    scrollViewportElement.scrollTo(
      scrollWrapperElement.scrollLeft,
      scrollWrapperElement.scrollLeft,
    );
  };

  return (
    <>
      <div className="customScrollbar" onScroll={onScroll} ref={customScroller}>
        <div className="dummyEle"></div>
      </div>
      <div className="organization-address">
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          {/* <Grid xs={12} item className="separate-line scroll"> */}
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className="organization-address-container"
          >
            <div className="organization-address-feature-footer">
              <FeatureFooter
                handleSaveAndNext={() => saveAddress(data)}
                handleSave={() => saveAddress(null)}
                handleClear={() => resetAddress()}
                disabled={disableEdit}
              />
            </div>
            <Grid item xs={12}>
              <Formik
                key={id.toString()}
                initialValues={initialValues}
                validate={async values =>
                  validateOnChange({ values, errors: { ...formRef.current.errors } })
                }
                onSubmit={() => {}}
              >
                {formikProps => {
                  formRef.current = formikProps;
                  return (
                    <>
                      <FormBuilder
                        formikProps={formikProps}
                        layoutSchema={layoutSchema(false)}
                        formSchema={updatedFormSchema}
                        onSubmitValidationError={() => {}}
                        disableControls={disableEdit || checkForIngestionAction(gridData)}
                      />
                    </>
                  );
                }}
              </Formik>
            </Grid>
            <Grid item xs={12}>
              <div className="organization-address-grid">
                <DNADataGrid
                  columnDefs={columnDefs({
                    handleChange,
                    handleDelete,
                    handleEdit,
                    handleIngestionActions,
                    disableEdit,
                    gridData,
                  })}
                  rowData={JSON.parse(JSON.stringify(gridData))}
                  frameworkComponents={{
                    ingestionActions: IngestionActions,
                    checkBox: InputRadio,
                    actions: TableActions,
                    ListView,
                    Link,
                    addressValidation,
                    DefaultCellRender,
                  }}
                  rowHeight={60}
                  onGridReady={onGridReady}
                  getRowClass={({ data: rowData }) => {
                    return rowData && rowData.isDisabled ? 'disable-grid-row' : '';
                  }}
                />
              </div>
            </Grid>
          </Grid>
          {/* </Grid> */}
          <Grid item xs={3}></Grid>
        </Grid>

        {deleteConfirmStatus && (
          <ConfirmationMessage
            isOpen
            onClose={proceedToDelete}
            message="Do you want to delete this row?"
          />
        )}
        {addConfirmStatus && (
          <ConfirmationMessage
            isOpen
            onClose={handleAddConfirmation}
            message="The current address doesn't have a valid address code. Are you sure you want to continue without validating the address?"
          />
        )}
        {editModalStatus && (
          <AddressModal
            row={rowDetails}
            handleValidate={handleValidate}
            onClose={onRowDetailsUpdate}
          />
        )}
        {errorModalStatus && (
          <ModalMessage
            isOpen
            modalType={MODAL_TYPES.ERROR}
            onClose={() => setErrorModalStatus(null)}
            message={errorModalStatus}
          />
        )}
        {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
      </div>
    </>
  );
});

AdditionalInfo.propTypes = {
  data: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default AdditionalInfo;
