import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import InputDatePicker from '../InputDatePicker';
import './index.scss';


const InputYearRange = ({
  value: { fromDate: fromDateValue, toDate: toDateValue },
  onChange,
  format,
  orientation,
  size,
  isMandatory,
  disablePast,
  disableFuture,
  views,
  placeholder,
}) => {
  const getDateValue = date => (date ? new Date(date) : new Date());
  const [fromDate, setFromDate] = useState(getDateValue(fromDateValue));
  const [toDate, setToDate] = useState(getDateValue(toDateValue));

  useEffect(() => {
    setFromDate(getDateValue(fromDateValue));
  }, [fromDateValue]);
  useEffect(() => {
    setToDate(getDateValue(toDateValue));
  }, [toDateValue]);


  const handleFromDateChange = date => {
    const dateRange = {
      fromDate: date,
      toDate,
    };
    setFromDate(date);
    onChange(dateRange);
  };

  const handleToDateChange = date => {
    const dateRange = {
      fromDate,
      toDate: date,
    };
    setToDate(date);
    onChange(dateRange);
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid className="from-date-picker" item xs={6}>
        {/* <InputDatePicker {...fromDateProps}></InputDatePicker> */}
        <InputDatePicker value = {fromDate} format ='yyyy' label = 'From Year' views= {['year']} onChange = {date => handleFromDateChange(date)} disableFuture = {true}></InputDatePicker>
      </Grid>
      <Grid className="to-date-picker" item xs={6}>
        {/* <InputDatePicker {...toDateProps}></InputDatePicker> */}
        <InputDatePicker value = {toDate} format ='yyyy' label = 'To Year' views= {['year']} onChange = {(date) => handleToDateChange(date)} disableFuture = {true}></InputDatePicker>
      </Grid>
    </Grid>
  );
};

InputYearRange.propTypes = {
  value: PropTypes.shape({
    fromDate: PropTypes.instanceOf(Date),
    toDate: PropTypes.instanceOf(Date),
  }),
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string.isRequired,
  orientation: PropTypes.string,
  size: PropTypes.string,
  isMandatory: PropTypes.bool,
  openTo: PropTypes.string,
  autoOk: PropTypes.bool,
  disabled: PropTypes.bool,
  views: PropTypes.array,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
};
InputYearRange.defaultProps = {
  format: 'yyyy',
  views: ['year'],
  orientation: 'landscape',
  isError: false,
  errorMessage: '',
  size: '16',
  isMandatory: false,
  disablePast: false,
  disableFuture: false,
  autoOk: false,
  openTo: 'date',
  isKeyboardCtrl: false,
  disabled: false,
  value: { fromDate: new Date(), toDate: new Date()},
};

export default InputYearRange;
