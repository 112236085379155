import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';
import {
  updateOrganizationDetails,
  resetOrganizationUserLock,
  setOrganizationValidationStatus,
  setOrganizationChangeStatus,
} from '../actions/organization';
import {
  setSelectedTabIndex,
  resetOrganizationState,
  deleteOrganizationSuccess,
  getOrganizationListSuccess,
  getMasterDataSuccess,
  setError,
  postOrganization,
  postOrganizationSuccess,
  getTaskQueueRecordsSuccess,
  getTaskQueueTypesSuccess,
  resetOrganizationDetails,
  fetchOrganizationFeatureSuccess,
  featureSelection,
  nextFeatureSelection,
  getOrganizationTree,
  getOrganizationTreeSuccess,
  setAddressValidationSuccess,
  clearAddressValidation,
  setPreviewSelection,
  setSelectedQueueType,
  getQueueTypesSuccess,
  getOrganizationQueueRecordsSuccess,
  getOrganizationIngestedDataSuccess,
  updateOrganizationIngestedAction,
  resetOrganizationIngestedData,
  updateOrganizationChildValues,
  getOrganizationUserLockDetails,
  getOrganizationList,
  getOrganizationQueueRecords,
  setQueueExpandedList,
  setQueueTransactionId,
  getCurrentAssociatedPeopleSuccess,
  getOngoingAssociatedTrialSuccess,
  getAllAssociatedTrialSuccess,
  resetOrganizationAssociation,
  getOrgProximityDataSuccess,
  searchOrganizationNoteTypeSuccess,
  resetOrgProximityData,
  getOrgCompareDataSuccess,
  resetOrgCompareData,
  getOrganizationStampingDetailsSuccess,
} from '../actions/organization';

import {
  setCurrentAndIngestedData,
  getNextIngestedTab,
  convertParentOrganization,
  getOrganizationStampDates,
  updateOrganizationStampingDetails,
} from '../helpers/organization';
import { resetIngestionAction, checkIfObjectIsEmpty } from '../../utils/generic/helper';
import validateTabs from '../../utils/generic/validation';

const initialState = InitialState.organization;
const organization = createReducer(initialState, {
  [featureSelection]: (state, { payload: { value } }) => {
    state.selected = value;
  },
  [nextFeatureSelection]: (state, { payload }) => {
    const filteredTabs = state.tabs.filter(i => i.value === payload);
    const index = state.tabs.findIndex(i => i.value === payload);
    const isSingleModeActive = state.isSinglePageMode;
    if (filteredTabs.length > 0) {
      const { isTab, selectedTabIndex, tabs } = filteredTabs[0];
      if (isTab) {
        if (selectedTabIndex < tabs.length - 1) {
          state.tabs[index].selectedTabIndex = selectedTabIndex + 1;
        } else if (selectedTabIndex === tabs.length - 1) {
          const tabValue = state.tabs[index + 1].value;
          isSingleModeActive ? (state.previewSelected = tabValue) : (state.selected = tabValue);
          state.tabs[index].selectedTabIndex = 0;
        }
      } else if (isSingleModeActive) {
        const { ingestedTab, activeTabIndex } = getNextIngestedTab(
          [...state.tabs],
          state.previewSelected,
        );
        state.previewSelected = ingestedTab
          ? ingestedTab.value
          : state.tabs[activeTabIndex + 1].value;
      } else {
        state.selected = state.tabs[index + 1].value;
      }
    }
  },

  [setSelectedTabIndex]: (state, { payload: { id, tabIndex } }) => {
    const index = state.tabs.findIndex(i => i.value === id);
    if (index > -1) {
      state.tabs[index].selectedTabIndex = tabIndex;
    }
  },

  [updateOrganizationDetails]: (state, { payload: { key, data } }) => {
    state.current[key] = data;
  },
  [resetOrganizationState]: state => {
    const { original, current, tabs, selected, ingested, originalStampDates } = initialState;
    state.selected = selected;
    state.tabs = tabs;
    state.original = original;
    state.current = current;
    state.ingested = ingested;
    state.isStamped = false;
    state.originalStampDates = originalStampDates;
  },
  [getOrganizationList]: state => {
    state.dashboard.queues.queueRecords = {};
  },
  [getOrganizationListSuccess]: (state, { payload }) => {
    state.dashboard.queues.queueRecords = payload;
  },
  [deleteOrganizationSuccess]: (state, { payload }) => {
    const results = state.dashboard.queues.queueRecords.results;
    state.dashboard.queues.queueRecords.results = results.filter(org => org.id !== payload);
  },
  [getMasterDataSuccess]: (state, { payload }) => {
    state.masterData = payload;
  },
  [setError]: (state, { payload }) => {
    state.error = payload;
  },
  [postOrganization]: (state, { payload: { key } }) => {
    const selected = state.tabs.find(tab => tab.key === key);
    if (selected) {
      selected.hasError = false;
    }
  },
  [postOrganizationSuccess]: (state, { payload: { key, data: successData } }) => {
    const data = convertParentOrganization(successData);
    state.original = {
      ...data,
      prodStatusID: data.prodStatusID || 0,
      ingestedId: state.current.ingestedId,
    };
    if (state.current.id === 0 && state.current.ingestedId === 0) {
      state.current = state.original;
    }
    state.current = {
      ...state.current,
      id: data.id,
      [key]: data[key],
      prodStatusID: data.prodStatusID || 0,
      duplicateAddressOrganizations: data.duplicateAddressOrganizations,
    };
    const {
      organizationBasicInfo: {
        lastFullReviewDate,
        lastFullReviewedBy,
        nextFullReviewDate,
        createdBy,
        createdDate,
        modifiedBy,
        modifiedDate,
      },
    } = data;
    state.current.organizationBasicInfo = {
      ...state.current.organizationBasicInfo,
      lastFullReviewDate,
      lastFullReviewedBy,
      nextFullReviewDate,
      createdBy,
      createdDate,
      modifiedBy,
      modifiedDate,
    };
    //TODO make generic
    if (key === 'contactInfo' && data.notes) {
      state.original.notes = data.notes;
      state.current.notes = data.notes;
    }
    const { ingested } = initialState;

    state.originalStampDates = getOrganizationStampDates(state.original.organizationBasicInfo);
    state.isStamped = false;

    const tabIndex = state.tabs.findIndex(tab => tab.key === key);
    if (tabIndex > -1) {
      if (state.ingested[key] && ingested[key]) {
        state.ingested[key] = ingested[key];
        state.tabs[tabIndex].hasIngestedData = false;
      }
      state.tabs[tabIndex].hasUnSavedData = false;
    }
  },
  [getTaskQueueRecordsSuccess]: (state, { payload }) => {
    state.dashboard.taskQueues.taskQueueRecords = payload;
  },
  [getTaskQueueTypesSuccess]: (state, { payload }) => {
    let { data = [] } = payload;
    state.dashboard.taskQueues.taskQueueTypes = data;
  },
  [fetchOrganizationFeatureSuccess]: (state, { payload: { data } }) => {
    const convertedDate = convertParentOrganization(data);
    state.original = convertedDate;
    state.current = convertedDate;

    state.originalStampDates = getOrganizationStampDates(state.original.organizationBasicInfo);
  },
  [getOrganizationStampingDetailsSuccess]: (state, { payload: { data } }) => {
    state.isStamped = true;
    const { current, original } = updateOrganizationStampingDetails(
      data.organizationBasicInfo,
      state.current,
      state.original,
    );
    state.current = current;
    state.original = original;
  },

  [setOrganizationValidationStatus]: (state, { payload: tabs = [] }) => {
    tabs.forEach(({ index, isValid }) => {
      state.tabs[index].hasError = !isValid;
    });
  },
  [setOrganizationChangeStatus]: (state, { payload: tabs = [] }) => {
    tabs.forEach(({ index, hasUnSavedData }) => {
      state.tabs[index].hasUnSavedData = hasUnSavedData;
    });
  },
  [resetOrganizationDetails]: (state, { payload: { key } }) => {
    const selected = state.tabs.find(tab => tab.key === key);
    if (selected && selected.hasIngestedData) {
      selected.hasUnSavedData = false;
      const { ingestedData, updatedCurrentData } = resetIngestionAction(
        state.ingested[key],
        state.current[key],
        state.original[key],
      );
      state.ingested[key] = ingestedData;
      state.current[key] = updatedCurrentData;
    } else {
      if (selected) {
        selected.hasUnSavedData = false;
        selected.hasError =
          selected.hasError &&
          Boolean(validateTabs.organization[key]) &&
          !checkIfObjectIsEmpty(
            validateTabs.organization[key](state.original[key], state.original),
          );
      }
      state.current[key] = state.original[key];
    }
  },
  [getOrganizationTree]: state => {
    state.treeView = [];
  },
  [getOrganizationTreeSuccess]: (state, { payload: { data } }) => {
    let treeViewData = [];
    if (data && data.id && data.childNodes) {
      treeViewData = [data];
    }
    state.treeView = treeViewData;
  },
  [setAddressValidationSuccess]: (state, { payload: { data } }) => {
    state.awsLocationServiceData = data;
  },
  [clearAddressValidation]: (state, { payload }) => {
    state.awsLocationServiceData = null;
  },
  [setPreviewSelection]: (state, { payload: { val, isActive } }) => {
    state.isSinglePageMode = isActive;
    state.previewSelected = val;
  },
  //Queues
  [setSelectedQueueType]: (state, { payload }) => {
    state.dashboard.queues.queueTypeSelected = payload;
  },
  [setQueueExpandedList]: (state, { payload }) => {
    state.dashboard.queues.queueExpandedList = payload;
  },

  [getOrganizationQueueRecords]: (state, { payload: { emptyRecords } }) => {
    let queueRecords = emptyRecords ? {} : state.dashboard.queues.queueRecords;
    state.dashboard.queues.queueRecords = queueRecords;
  },

  [getOrganizationQueueRecordsSuccess]: (state, { payload }) => {
    state.dashboard.queues.queueRecords = payload;
  },
  [getQueueTypesSuccess]: (state, { payload }) => {
    let { data = [] } = payload;
    state.dashboard.queues.queueTypes = data;
  },
  [setQueueTransactionId]: (state, { payload }) => {
    state.queueTransactionId = payload;
  },
  [getOrganizationIngestedDataSuccess]: (state, { payload }) => {
    const { updatedIngestedData, updatedCurrentData, updatedTabs } = setCurrentAndIngestedData(
      { ...state.current },
      { ...payload },
      [...state.tabs],
    );
    state.ingested = { ...updatedIngestedData };
    state.current = { ...updatedCurrentData };
    state.original.ingestedId = updatedCurrentData.ingestedId;
    state.tabs = [...updatedTabs];
  },
  [updateOrganizationIngestedAction]: (
    state,
    { payload: { parentKey, childKey, ingestionAction } },
  ) => {
    const ingestedData = state.ingested[parentKey];
    ingestedData.fieldActions[childKey] = ingestionAction;
    state.ingested[parentKey] = ingestedData;
  },
  [updateOrganizationChildValues]: (state, { payload: { parentKey, childKey, data } }) => {
    const currentData = state.current[parentKey];
    currentData[childKey] = data;
    state.current[parentKey] = currentData;
  },
  [resetOrganizationIngestedData]: state => {
    const { ingested } = initialState;
    state.ingested = ingested;
  },
  [getOrganizationUserLockDetails]: (
    state,
    { payload: { isEditable, lockedBy, userLockPeriod } },
  ) => {
    const updatedData = {
      isLocked: !isEditable,
      lockedBy,
      userLockPeriod,
    };
    state.organizationLockDetails = updatedData;
  },
  [resetOrganizationUserLock]: state => {
    const { organizationLockDetails } = initialState;
    state.organizationLockDetails = organizationLockDetails;
  },
  [getCurrentAssociatedPeopleSuccess]: (
    state,
    { payload: { pageSize, currentPage, rowCount, results } },
  ) => {
    state.organizationAssociation.currentAssociatedPeople = {
      currentPeople: results && results.length > 0 ? results : [],
      pagination: {
        pageSize,
        pageIndex: currentPage,
        rowCount,
      },
    };
  },
  [getOngoingAssociatedTrialSuccess]: (
    state,
    { payload: { pageSize, currentPage, rowCount, results } },
  ) => {
    state.organizationAssociation.ongoingAssociatedTrials = {
      ongoingTrials: results && results.length > 0 ? results[0].result || [] : [],
      lastTrialStartDate: results && results.length > 0 ? results[0].lastTrialStartDate : '',
      pagination: {
        pageSize,
        pageIndex: currentPage,
        rowCount,
      },
    };
  },
  [getAllAssociatedTrialSuccess]: (
    state,
    { payload: { pageSize, currentPage, rowCount, results } },
  ) => {
    state.organizationAssociation.allAssociatedTrials = {
      allTrials: results && results.length > 0 ? results[0].result || [] : [],
      lastTrialStartDate: results && results.length > 0 ? results[0].lastTrialStartDate : '',
      pagination: {
        pageSize,
        pageIndex: currentPage,
        rowCount,
      },
    };
  },
  [resetOrganizationAssociation]: state => {
    state.organizationAssociation = { ...initialState.organizationAssociation };
  },
  [getOrgProximityDataSuccess]: (state, { payload }) => {
    state.proximityComparisonData = payload;
  },
  [searchOrganizationNoteTypeSuccess]: (state, { payload: { data } }) => {
    if (data) {
      state.original.notes = data;
      state.current.notes = data;
    }
  },
  [resetOrgProximityData]: state => {
    state.proximityComparisonData = { ...initialState.proximityComparisonData };
  },

  [getOrgCompareDataSuccess]: (state, { payload }) => {
    state.compareRecordsData = payload;
  },
  [resetOrgCompareData]: state => {
    state.compareRecordsData = { ...initialState.compareRecordsData };
  },
});

export default organization;
