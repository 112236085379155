import { createReducer } from '@reduxjs/toolkit';
import {
  setSelectedTabIndex,
  sourceDocumentsFeatureSelection,
  sourceDocumentsNextFeatureSelection,
  getSourceDocumentList,
  getSourceDocumentListSuccess,
  deleteSourceDocumentSuccess,
  saveSourceDocumentDetails,
  saveSourceDocumentDetailsSuccess,
  getSourceDocumentMasterDataSuccess,
  getSourceDocumentDetailsSuccess,
  saveUploadFiles,
  saveUploadFilesSuccess,
  saveDownlaodFiles,
  publishSourceDocumentSuccess,
  setErrorDetail,
  setSourceDocValidationStatus,
  getStagedIdSourceDocSuccess,
  getStagedIdSourceDoc,
  validateSourceDocSuccess,
  setSourceDocUnSavedStatus,
  moveOutofScopeSourceDocSuccess,
  sourceDocRecordChangeFldsListSuccess,
  getSourceDocumentStampingDetailsSuccess,
  updateSourceDocumentRecordDatesDetail,
  getSourceDocumentUserLockDetails,
  resetSourceDocumentUserLock,
  resetStagedIdSourceDoc,
  getSrcDocCompareDataSuccess,
  resetSrcDocCompareData,
} from '../actions/sourceDocuments';
import InitialState from '../initialState';

const initialState = InitialState.sourceDocuments;
const sourceDocuments = createReducer(initialState, {
  [sourceDocumentsFeatureSelection]: (state, { payload: { value } }) => {
    state.selected = value;
  },
  [sourceDocumentsNextFeatureSelection]: (state, { payload }) => {
    const filteredTabs = state.tabs.filter(i => i.value === payload);
    const index = state.tabs.findIndex(i => i.value === payload);
    const isSingleModeActive = state.isSinglePageMode;
    if (filteredTabs.length > 0) {
      const { isTab, selectedTabIndex, tabs } = filteredTabs[0];
      if (isTab) {
        if (selectedTabIndex < tabs.length - 1) {
          state.tabs[index].selectedTabIndex = selectedTabIndex + 1;
        } else if (selectedTabIndex === tabs.length - 1) {
          const tabValue = state.tabs[index + 1].value;
          isSingleModeActive ? (state.previewSelected = tabValue) : (state.selected = tabValue);
          state.tabs[index].selectedTabIndex = 0;
        }
      } else if (isSingleModeActive) {
        state.previewSelected = state.tabs[index + 1].value;
      } else {
        state.selected = state.tabs[index + 1].value;
      }
    }
  },
  [setSelectedTabIndex]: (state, { payload: { id, tabIndex } }) => {
    const index = state.tabs.findIndex(i => i.value === id);
    if (index > -1) {
      state.tabs[index].selectedTabIndex = tabIndex;
    }
  },

  [deleteSourceDocumentSuccess]: (state, { payload }) => {
    const results = state.dashboard.queues.queueRecords.results;
    state.dashboard.queues.queueRecords.results = results.filter(
      sourceDocument => sourceDocument.id !== payload,
    );
  },

  [saveSourceDocumentDetails]: (state, { payload: { key } }) => {
    const selected = state.tabs.find(tab => tab.key === key);
    if (selected) {
      selected.hasError = false;
    }
  },

  [saveUploadFiles]: (state, { payload: { key } }) => {
    const selected = state.tabs.find(tab => tab.key === key);
    if (selected) {
      selected.hasError = false;
    }
  },

  [saveDownlaodFiles]: (state, { payload: { key } }) => {
    const selected = state.tabs.find(tab => tab.key === key);
    if (selected) {
      selected.hasError = false;
    }
  },

  [saveSourceDocumentDetailsSuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
    for (let i = 0; i < state.tabs.length; i++) {
      state.tabs[i].hasError = false;
      state.tabs[i].hasUnSavedData = false;
      state.tabs[i].error = null;
    }
    state.isStamped = false;
  },

  [saveUploadFilesSuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },

  [getSourceDocumentMasterDataSuccess]: (state, { payload }) => {
    state.masterData = payload;
  },

  [getSourceDocumentDetailsSuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },
  [publishSourceDocumentSuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },
  [moveOutofScopeSourceDocSuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },
  [setErrorDetail]: (state, data = {}) => {
    const index = data.payload.tabs.findIndex(i => i.key === data.payload.tabIndex);
    state.tabs[index].error = data.payload.errorDetails;
  },
  [setSourceDocValidationStatus]: (state, { payload }) => {
    const { tabId, hasError } = payload;
    state.tabs[tabId].hasError = hasError;
  },
  [validateSourceDocSuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },
  [getStagedIdSourceDocSuccess]: (state, { payload }) => {
    state.stageId = payload;
  },
  [setSourceDocUnSavedStatus]: (state, { payload }) => {
    const selected = payload === 'DataEntry' ? 0 : 1;
    state.tabs[selected].hasUnSavedData = true;
  },
  [sourceDocRecordChangeFldsListSuccess]: (state, { payload }) => {
    state.changeFields = payload;
  },
  [getSourceDocumentStampingDetailsSuccess]: (state, { payload: { data } }) => {
    state.isStamped = true;
    state.current = data;
    state.original = data;
  },
  [updateSourceDocumentRecordDatesDetail]: (state, { payload: { key, data } }) => {
    state.current[key] = data;
    state.original[key] = data;
  },
  [getSourceDocumentUserLockDetails]: (
    state,
    { payload: { isEditable, lockedBy, userLockPeriod } },
  ) => {
    const updatedData = {
      isLocked: !isEditable,
      lockedBy,
      userLockPeriod,
    };
    state.srcDocumentsLockDetails = updatedData;
  },
  [resetSourceDocumentUserLock]: state => {
    const { srcDocumentsLockDetails } = initialState;
    state.srcDocumentsLockDetails = srcDocumentsLockDetails;
  },
  [resetStagedIdSourceDoc]: state => {
    state.stageId = null;
  },
  [getSrcDocCompareDataSuccess]: (state, { payload }) => {
    state.compareRecordsData = payload;
  },
  [resetSrcDocCompareData]: state => {
    state.compareRecordsData = { ...initialState.compareRecordsData };
  },
});
export default sourceDocuments;
