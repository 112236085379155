import {
  checkIfStringIsEmpty,
  regexToAllowOnlyPositiveNumbers,
  regexToAllowPositiveNumbersAndDecimals,
  checkIfArrayIsEmpty,
  isValidDate,
  checkIfObjectIsEmpty,
} from '../../../utils/generic/helper';

const diseaseTypes = [
  'testicular',
  'testicular cancer',
  'prostate',
  'prostate cancer',
  'ovarian',
  'ovarian cancer',
  'cervical',
  'cervical cancer',
];

export const isPatientGenderDisabled = data => {
  let isDisabled = false;
  if (!checkIfArrayIsEmpty(data) && data.length === 1) {
    const { diseaseType } = data[0];
    const { value = '' } = { ...diseaseType };
    const selectedDisease = value ? value.trim().toLowerCase() : '';
    isDisabled = diseaseTypes.includes(selectedDisease);
  }
  return isDisabled;
};

export const warningMessages = {
  TARGET_ACCURAL: 'Target accrual cannot be lower than Interim accrual',
  INTERIM_ACCURAL: 'Interim accrual cannot be more than Target accrual',
  ACTUAL_ACCURAL_AND_COMPLETED_TRIAL: 'Actual accrual should not be zero for completed trial',
  ACTUAL_ACCURAL_AND_OPEN_TRIAL: 'Actual accrual should not exist for open trial',
  ACTUAL_ACCURAL_AND_PLANNED_TRIAL: 'Actual accrual should not exist for planned trial',
  INTERIM_ACCURAL_AND_PLANNED_TRIAL: 'Interim accrual should not exist for planned trial'
};

// eslint-disable-next-line complexity
export const validatePatientPopulationDetails = (
  {
    targetAccrual,
    targetAccrualComment,
    interimAccrual,
    interimAccrualComment,
    actualAccrual,
    minimumAge,
    minimumAgeUnit,
    maximumAge,
    maximumAgeUnit,
    globalStatus,
    date,
  },
  publish = false,
) => {
  let errors = {};
  const trialStatus =
    globalStatus && globalStatus.value ? globalStatus.value.trim().toLowerCase() : '';

  if (date && !isValidDate(date)) {
    errors = {
      ...errors,
      date: 'Please enter a valid date (MMM-dd-yyyy)',
    };
  }

  if (
    !checkIfStringIsEmpty(targetAccrual) &&
    !regexToAllowOnlyPositiveNumbers.test(targetAccrual.trim())
  ) {
    errors = {
      ...errors,
      targetAccrual: 'Please enter a valid number',
    };
  }

  if (targetAccrualComment.length > 400) {
    errors = {
      ...errors,
      targetAccrualComment: 'Enter less than 400 characters',
    };
  }

  if (interimAccrualComment.length > 400) {
    errors = {
      ...errors,
      interimAccrualComment: 'Enter less than 400 characters',
    };
  }

  if (
    !checkIfStringIsEmpty(interimAccrual) &&
    !regexToAllowOnlyPositiveNumbers.test(interimAccrual.trim())
  ) {
    errors = {
      ...errors,
      interimAccrual: 'Please enter a valid number',
    };
  }

  if (
    !checkIfStringIsEmpty(actualAccrual) &&
    !regexToAllowOnlyPositiveNumbers.test(actualAccrual.trim())
  ) {
    errors = {
      ...errors,
      actualAccrual: 'Please enter a valid number',
    };
  }

  if (
    !checkIfStringIsEmpty(minimumAge) &&
    !regexToAllowPositiveNumbersAndDecimals.test(minimumAge.trim())
  ) {
    errors = {
      ...errors,
      minimumAge: 'Please enter a valid number',
    };
  }

  if (
    !checkIfStringIsEmpty(maximumAge) &&
    !regexToAllowPositiveNumbersAndDecimals.test(maximumAge.trim())
  ) {
    errors = {
      ...errors,
      maximumAge: 'Please enter a valid number',
    };
  }

  if (!checkIfStringIsEmpty(minimumAge) && checkIfObjectIsEmpty(minimumAgeUnit)) {
    errors = {
      ...errors,
      minimumAgeUnit: 'Please select the unit',
    };
  }

  if (!checkIfStringIsEmpty(maximumAge) && checkIfObjectIsEmpty(maximumAgeUnit)) {
    errors = {
      ...errors,
      maximumAgeUnit: 'Please select the unit',
    };
  }

  if (!checkIfObjectIsEmpty(minimumAgeUnit) && checkIfStringIsEmpty(minimumAge)) {
    errors = {
      ...errors,
      minimumAge: 'Please select minimum age',
    };
  }

  if (!checkIfObjectIsEmpty(maximumAgeUnit) && checkIfStringIsEmpty(maximumAge)) {
    errors = {
      ...errors,
      maximumAge: 'Please select maximum age',
    };
  }

  if (
    !publish &&
    !checkIfStringIsEmpty(targetAccrual) &&
    !checkIfStringIsEmpty(interimAccrual) &&
    parseInt(targetAccrual, 10) < parseInt(interimAccrual, 10)
  ) {
    errors = {
      ...errors,
      targetAccrual: warningMessages.TARGET_ACCURAL,
    };
  }

  if (
    !publish &&
    !checkIfStringIsEmpty(targetAccrual) &&
    !checkIfStringIsEmpty(interimAccrual) &&
    parseInt(interimAccrual, 10) > parseInt(targetAccrual, 10)
  ) {
    errors = {
      ...errors,
      interimAccrual: warningMessages.INTERIM_ACCURAL,
    };
  }

  if (
    !publish &&
    !checkIfStringIsEmpty(actualAccrual) &&
    parseInt(actualAccrual, 10) === 0 &&
    trialStatus === 'completed'
  ) {
    errors = {
      ...errors,
      actualAccrual: warningMessages.ACTUAL_ACCURAL_AND_COMPLETED_TRIAL,
    };
  }

  if (
    !publish &&
    !checkIfStringIsEmpty(actualAccrual) &&
    (trialStatus === 'open' || trialStatus === 'planned')
  ) {
    errors = {
      ...errors,
      actualAccrual:
        trialStatus === 'open'
          ? warningMessages.ACTUAL_ACCURAL_AND_OPEN_TRIAL
          : warningMessages.ACTUAL_ACCURAL_AND_PLANNED_TRIAL,
    };
  }

  if (
    !publish &&
    !checkIfStringIsEmpty(interimAccrual) &&
    (trialStatus === 'planned')
  ) {
    errors = {
      ...errors,
      interimAccrual: warningMessages.INTERIM_ACCURAL_AND_PLANNED_TRIAL,
    };
  }
  return errors;
};

const calculateAgeInDays = (ageUnit, age) => {
  let days = 0;
  const parsedAgeData = parseFloat(age);
  if (!checkIfObjectIsEmpty(ageUnit)) {
    switch (ageUnit.label) {
      case 'Year(s)':
        days = parsedAgeData * 365;
        break;
      case 'Month(s)':
        days = parsedAgeData * 30;
        break;
      case 'Week(s)':
        days = parsedAgeData * 7;
        break;
      case 'Hour(s)':
        days = parsedAgeData / 24;
        break;
      case 'Minute(s)':
        days = parsedAgeData / (24 * 60);
        break;
      default:
        days = parsedAgeData;
        break;
    }
  }
  return days.toFixed(4);
};

export const validateAge = ({ minimumAge, minimumAgeUnit, maximumAge, maximumAgeUnit }) => {
  let isError = false;
  if (
    !checkIfObjectIsEmpty(minimumAgeUnit) &&
    !checkIfStringIsEmpty(minimumAge) &&
    !checkIfObjectIsEmpty(maximumAgeUnit) &&
    !checkIfStringIsEmpty(maximumAge) &&
    regexToAllowPositiveNumbersAndDecimals.test(minimumAge.trim()) &&
    regexToAllowPositiveNumbersAndDecimals.test(maximumAge.trim())
  ) {
    isError =
      parseFloat(calculateAgeInDays(minimumAgeUnit, minimumAge)) >
      parseFloat(calculateAgeInDays(maximumAgeUnit, maximumAge));
  }
  return isError;
};
export const validatePatientPopulationOnPublish = (patientPopulation, globalStatus) => {
  if (validateAge(patientPopulation)) {
    return { age: true };
  } else {
    return validatePatientPopulationDetails({ ...patientPopulation, globalStatus }, true);
  }
};
