import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// import { TreeList, Column } from 'devextreme-react/tree-list';
import { Popover } from 'devextreme-react/popover';
import {
  TreeList,
  Scrolling,
  Paging,
  Pager,
  Column,
  Lookup,
  SearchPanel,
} from 'devextreme-react/tree-list';
import { AgGridReact } from 'ag-grid-react';
// import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService'
import 'devextreme/dist/css/dx.light.css';
import './index.scss';
import { ListView, TableActions, DefaultCellRender } from '../../framework';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import MechanismParentChild from '../../screens/ancillaryData/components/hierarchyIndicator/mechanism';
import HierarchyIndicator from '../../screens/ancillaryData/components/hierarchyIndicator/company';
import TableLink from '../../screens/ancillaryData/components/tableLink';
import DrugTypeParentChild from '../../screens/ancillaryData/components/hierarchyIndicator/drugType';
import TargetFamilyParentChild from '../../screens/ancillaryData/components/hierarchyIndicator/targetFamily';
const DNATreeDataGrid = ({
  styleName,
  height,
  showStickyHeader,
  defaultColDef,
  isHorizontalScrollBar,
  handleSortChanged,
  isAllDataLoaded,
  ...props
}) => {
  const customScroller = useRef(null);
  const scrollRef = useRef(null);
  const [isSticky, setSticky] = useState(false);
  const [gridWidth, setGridWidth] = useState(0);
  const [withAnimationOptionsVisible, setWithAnimationOptionsVisible] = useState([]);
  const [datasource, setDatasource] = useState([]);
  const [defaultOpen, setDefaultOpen] = useState([]);
  const [editEnabled, setEditEnabled] = useState(false);
  useEffect(() => {
    if (showStickyHeader) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (showStickyHeader) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const y = [];
    props.rowData.map(el => {
      if (!el.parentid) {
        y.push(el.id);
      }
    });
    setDefaultOpen(y);
    setDatasource(JSON.parse(JSON.stringify(props.rowData)));
  }, [props.rowData]);

  const ColumnVisibleEvent = params => {
    const ele = document.getElementsByClassName('ag-center-cols-viewport');
    const elementWidth = ele[0].scrollWidth;
    document.getElementsByClassName('dummyEle')[0].style.width = `${150 + elementWidth}px`;
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      setSticky(window.pageYOffset >= scrollRef.current.offsetTop);
    }
  };
  const onScroll = e => {
    const scrollViewportSelector = '.ag-center-cols-viewport';
    const scrollWrapperSelector = '.customScrollbar';
    const scrollViewportElement = document.querySelector(scrollViewportSelector);
    const scrollWrapperElement = document.querySelector(scrollWrapperSelector);
    scrollViewportElement.scrollTo(
      scrollWrapperElement.scrollLeft,
      scrollWrapperElement.scrollLeft,
    );
  };
  const onBodyScroll = () => {
    const scrollViewportSelector = '.ag-center-cols-viewport';
    const scrollWrapperSelector = '.customScrollbar';
    const scrollViewportElement = document.querySelector(scrollViewportSelector);
    const scrollWrapperElement = document.querySelector(scrollWrapperSelector);
    scrollWrapperElement.scrollTo(
      scrollViewportElement.scrollLeft,
      scrollViewportElement.scrollLeft,
    );
  };

  const convert = (array = []) => {
    if (!array.length) {
      return [];
    }
    var map = {};
    for (var i = 0; i < array.length; i++) {
      var obj = array[i];
      obj.items = [];
      obj.text = obj.displayValue;
      // obj.isExpanded = true
      map[obj.id] = obj;
      if (!obj.parentid) {
        obj.parentid = 0;
      }
      var parent = obj.parentid || obj.parentId || '-';
      if (obj.parent) {
        let parentDescription = array.filter(it => it.id == obj.parent.id);
        if (parentDescription.length) {
          obj.parent.description = parentDescription[0].description;
        }
      }
      if (!map[parent]) {
        map[parent] = {
          items: [],
        };
      }
      map[parent].items.push(obj);
    }
    let k = map['-'].items;
    return k;
  };
  const toggleWithAnimationOptions = id => {
    setWithAnimationOptionsVisible([...withAnimationOptionsVisible, id]);
  };

  const removePopOver = id => {
    let withAnimationOptionsVisibleCopy = JSON.parse(JSON.stringify(withAnimationOptionsVisible));
    withAnimationOptionsVisibleCopy = withAnimationOptionsVisibleCopy.filter(el => el != id);
    setWithAnimationOptionsVisible(withAnimationOptionsVisibleCopy);
  };

  const getCustomComponent = (col, data) => {
    if (col.cellRenderer == 'listView') {
      return <ListView {...col.cellRendererParams({ data: data.data, rowIndex: data.rowIndex })} />;
    } else if (col.cellRenderer == 'actions') {
      return (
        <TableActions {...col.cellRendererParams({ data: data.data, rowIndex: data.rowIndex })} />
      );
    } else if (col.cellRenderer == 'tableLink') {
      return (
        <TableLink {...col.cellRendererParams({ data: data.data, rowIndex: data.rowIndex })} />
      );
    } else if (col.cellRenderer == 'MechanismParentChild') {
      return <MechanismParentChild {...data} />;
    } else if (col.cellRenderer == 'DefaultCellRender') {
      return <DefaultCellRender {...data} />;
    } else if (col.cellRenderer == 'HierarchyIndicator') {
      return <HierarchyIndicator {...data} />;
    } else if (col.cellRenderer == 'DrugTypeParentChild') {
      return <DrugTypeParentChild {...data} />;
    } else if (col.cellRenderer == 'TargetFamilyParentChild') {
      return <TargetFamilyParentChild {...data} />;
    }
    return null;
  };

  return (
    <div
      className={`custom-tree-implementation ${styleName} ${isSticky ? 'stickyVisible' : ''}`}
      ref={scrollRef}
    >
      {isHorizontalScrollBar && (
        <div class="customScrollbar" onScroll={onScroll} ref={customScroller}>
          <div class="dummyEle"></div>
        </div>
      )}
      <div
        className="ag-theme-balham"
        style={{
          height,
          width: '100%',
          display: 'inline-block',
        }}
      >
        {datasource.length ? (
          <TreeList
            id="employees444"
            keyExpr="id"
            dataSource={datasource}
            // expandedRowKeys={[...defaultOpen]}
            autoExpandAll={true}
            showRowLines={true}
            // defaultExpandedRowKeys={}
            showBorders={true}
            parentIdExpr="parentid"
            // rootValue={0}
            // columnAutoWidth={false}
            // itemsExpr="items"
            // dataStructure="tree"
            showColumnLines={false}
            className="treelist-custom-style"
            filterMode="fullBranch"
            // onOptionChanged={(e) => { console.log("hjvvhvhjvjhvhvhv", e) }}
          >
            {isAllDataLoaded && <SearchPanel visible={true} width={300} />}
            {isAllDataLoaded && <Scrolling mode="standard" />}
            {isAllDataLoaded && <Paging enabled={true} defaultPageSize={50} />}
            {isAllDataLoaded && (
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 20, 50, 100]}
                showInfo={true}
              />
            )}
            {props.columnDefs.map(col => {
              return col.cellRenderer ? (
                <Column
                  dataField={col.field}
                  caption={col.headerName}
                  cellRender={data => getCustomComponent(col, data)}
                  minWidth={col.minWidth}
                  width={col.maxWidth}
                  allowSorting={col.sortable}
                  fixed={col.fixed}
                  fixedPosition={col.fixedPosition}
                />
              ) : (
                <Column
                  dataField={col.field}
                  caption={col.headerName}
                  allowSorting={col.sortable}
                  fixed={col.fixed}
                  fixedPosition={col.fixedPosition}
                />
              );
            })}
          </TreeList>
        ) : (
          <div>
            <TreeList
              id="employees"
              keyExpr="id"
              dataSource={[]}
              autoExpandAll={true}
              showRowLines={true}
              showBorders={false}
              parentIdExpr="parentid"
              itemsExpr="items"
              dataStructure="tree"
              showColumnLines={false}
              className="treelist-custom-style"
              // onOptionChanged={(e) => { console.log("hjvvhvhjvjhvhvhv", e) }}
            >
              {props.columnDefs.map(col => {
                return col.cellRenderer ? (
                  <Column
                    dataField={col.field}
                    caption={col.headerName}
                    cellRender={data => getCustomComponent(col, data)}
                    minWidth={col.minWidth}
                    width={col.maxWidth}
                    allowSorting={col.sortable}
                    fixed={col.fixed}
                    fixedPosition={col.fixedPosition}
                  />
                ) : (
                  <Column
                    dataField={col.field}
                    caption={col.headerName}
                    allowSorting={col.sortable}
                    fixed={col.fixed}
                    fixedPosition={col.fixedPosition}
                  />
                );
              })}
            </TreeList>
          </div>
        )}
      </div>
    </div>
  );
};

DNATreeDataGrid.propTypes = {
  columnDefs: PropTypes.array,
  rowData: PropTypes.array,
  headerHeight: PropTypes.number,
  styleName: PropTypes.string,
  height: PropTypes.string,
  showStickyHeader: PropTypes.bool,
  defaultColDef: PropTypes.object,
  rowHeight: PropTypes.number,
  handleSortChanged: PropTypes.func,
};

DNATreeDataGrid.defaultProps = {
  columnDefs: [],
  rowData: [],
  styleName: 'basic-table',
  height: '100%',
  showStickyHeader: false,
  defaultColDef: {},
  headerHeight: 60,
  rowHeight: 65,
};

export default DNATreeDataGrid;
