import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import InputDatePicker from '../InputDatePicker';
import './index.scss';
import { InputCustomFormatDatePicker, InputTextField } from '..';

const InputTextRange = ({
  value: { fromDate: fromDateValue, toDate: toDateValue },
  onChange,
}) => {
  const getDateValue = date => (date ? date : '');
  const [fromDate, setFromDate] = useState(getDateValue(fromDateValue));
  const [toDate, setToDate] = useState(getDateValue(toDateValue));

  

  useEffect(() => {
    setFromDate(getDateValue(fromDateValue));
  }, [fromDateValue]);
  useEffect(() => {
    setToDate(getDateValue(toDateValue));
  }, [toDateValue]);

  useEffect(() => {
    setFromDate(fromDateValue);
  }, [fromDateValue]);

  useEffect(() => {
    setToDate(toDateValue);
  }, [toDateValue]);



  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid className="from-date-picker" item xs={6}>
        <InputTextField value={fromDate} label="From" 
                      onChange={e => {
                        const dateRange = {
                            fromDate: e.target.value,
                            toDate,
                          };
                          setFromDate(e.target.value);
                          onChange(dateRange);
                      }}
                      isMandatory
       ></InputTextField>
      </Grid>
      <Grid className="to-date-picker" item xs={6}>
        <InputTextField value={toDate} label="To"
                       onChange={e => {
                        const dateRange = {
                            fromDate,
                           toDate: e.target.value,
                          };
                          setToDate(e.target.value);
                          onChange(dateRange) 
                      }}
                      isMandatory
        ></InputTextField>
      </Grid>
    </Grid>
  );
};

InputTextRange.propTypes = {
  value: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,

};
InputTextRange.defaultProps = {
  value: { fromDate: '', toDate: '' },
};

export default InputTextRange;
