const generateReportingInitialState = (data) => {
  const initialState = {};
  data.forEach((item) => {
    initialState[item.value] = {
      reportingLabel: item.name,
      reportingValue: item.value,
      tableData: {
        data: [],
        error: null,
        isError: false,
        isFetching: false,
      },
      formValues: null,
      filter: '',
    };
  });
  return initialState;
};
const reporting = 'reporting';
export const reportingValues = [
  {
    id: '1',
    name: 'Source URL Statistics',
    value: 'SourceURLStatistics',
    apiKey: 'SourceURLStatistics',
    group: reporting,
    // primaryField: 'desc',
  },
  {
    id: '2',
    name: 'Ageing Statistics',
    value: 'AgeingStatistics',
    apiKey: 'AgeingStatistics',
    group: reporting,
    // primaryField: 'desc',
  },
  {
    id: '3',
    name: 'Disease Statistics',
    value: 'DiseaseStatistics',
    apiKey: 'DiseaseStatistics',
    group: reporting,
    // listKey: ['therapeuticArea'],
    // primaryField: 'diseaseType.desc',
    // editConfirm: true,
  },
];

export const reportingInitialState = generateReportingInitialState(reportingValues);
