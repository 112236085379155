import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle as faOutlineCircle } from '@fortawesome/free-regular-svg-icons';
import CopyContentModal from '../../../../framework/copyContentModal';

export default function DrugTypeParentChild(params) {
  const { data: { description, level, isLast } } = params
  return (
    <div className={`copy-content-wrapper level-${level}`}>
      <CopyContentModal>
        <span>
          {level >= 0 && !isLast && <span><FontAwesomeIcon icon={faCircle} color="#d1497c" /></span>}
          {level > 0 && isLast && <span><FontAwesomeIcon icon={faOutlineCircle} color="#d1497c" /></span>}
          {" "}{description}
        </span>
      </CopyContentModal>
    </div>
  )
}