import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import {
  DNADataGrid,
  FormBuilder,
  TableActions,
  DefaultCellRender,
  IngestionActions,
  TabFilter,
} from '../../../framework';
import InputIcon from '../../../framework/inputs/InputIcon';
import InputCheckbox from '../../../framework/inputs/InputCheckbox';
import ConfirmationMessage from '../../../generic/modals/ConfirmationMessage';
import {
  getRowId,
  updateIngestedDataBasedOnFieldType,
  checkForIngestionAction,
} from '../../../utils/generic/helper';
import { initialValues, formSchema, layoutSchema, columnDefs, validate } from './config';
import SupportingUrlsModal from './urlsModal';
import './index.scss';

const SupportingUrls = forwardRef(
  (
    {
      id,
      data,
      updateData,
      entityName,
      statuses,
      onUrlChange,
      validateSupportingUrl,
      disableEdit,
      urlLabel,
    },
    ref,
  ) => {
    const updatedFormSchema = {
      ...formSchema,
      url: {
        ...formSchema.url,
        ...(urlLabel && { label: urlLabel }),
        props: {
          ...formSchema.url.props,
        },
      },
    };

    const updatedLayoutSchema = {
      ...layoutSchema,
      content: [
        {
          layout: 'url',
          size: 8,
          className: 'url-type',
        },
        {
          layout: 'button',
          size: 1,
          className: 'add-button',
        },
      ],
    };

    const gridAPI = useRef(null);
    const urlsRef = useRef(null);
    const formRef = useRef(null);
    const [gridData, setGridData] = useState(data);
    const [filterValue, setFilterValue] = useState(0);
    const [rowDetails, setRowDetails] = useState(null);
    const [editModalStatus, setEditModalStatus] = useState(false);
    const [deleteConfirmStatus, setDeleteConfirmStatus] = useState(false);
    const [rowId, setRowId] = useState(-1);

    const onGridReady = params => {
      gridAPI.current = params.api;
    };
    useEffect(() => {
      updateGridData(filterValue);
      urlsRef.current = [...data];
      setRowId(getRowId([...data]));
    }, [data]);

    useImperativeHandle(ref, () => ({
      isChanged: () => {
        if (formRef.current) {
          return formRef.current.dirty;
        }
        return false;
      },
      reset: () => {
        if (formRef.current) {
          formRef.current.resetForm();
        }
      },
    }));

    const filterHandleChangeData = (event, newValue) => {
      setFilterValue(newValue);
      updateGridData(newValue);
    };

    const handleChange = rowDetailsData => {
      if (rowDetailsData && urlsRef && urlsRef.current) {
        if (gridAPI && gridAPI.current) {
          gridAPI.current.setRowData(rowDetailsData.gridData);
        }
        const updatedData = [...urlsRef.current];
        const rowIndex = getRowIndex(rowDetailsData, updatedData);
        updatedData.splice(rowIndex, 1, rowDetailsData.rowData);
        updateData(JSON.parse(JSON.stringify(updatedData)), rowDetailsData.rowData);
      }
    };

    const onSubmitValidationError = () => {};

    const getRowIndex = ({ rowData, rowIndex }, urls) => {
      let rowIdx = rowIndex;
      if (rowData && rowData.id) {
        const filteredRowIndex = [...urls].findIndex(url => url.id === rowData.id);
        rowIdx = filteredRowIndex > -1 ? filteredRowIndex : rowIndex;
      }
      return rowIdx;
    };

    const proceedToDelete = status => {
      if (status && rowDetails) {
        const updatedData = [...data];
        const rowIndex = getRowIndex(rowDetails, updatedData);
        updatedData.splice(rowIndex, 1);
        onUrlChange('delete', rowDetails.rowData, rowDetails);
        updateData(JSON.parse(JSON.stringify(updatedData)), rowDetails.rowData);
      }
      setRowDetails(null);
      setTimeout(() => {
        setDeleteConfirmStatus(false);
      }, 1000);
    };

    const onRowDetailsUpdate = rowDetailsData => {
      if (rowDetailsData) {
        const updatedData = [...data];
        const rowIndex = getRowIndex(rowDetailsData, updatedData);
        updatedData.splice(rowIndex, 1, rowDetailsData.rowData);
        if (gridAPI && gridAPI.current) {
          gridAPI.current.setRowData(JSON.parse(JSON.stringify(updatedData)));
        }
        onUrlChange('update', rowDetailsData.rowData, rowDetails);
        updateData(JSON.parse(JSON.stringify(updatedData)), rowDetails.rowData);
      }

      setEditModalStatus(false);
      setRowDetails(null);
    };

    const handleEdit = (rowData, rowIndex) => {
      setRowDetails({ rowData, rowIndex });
      setEditModalStatus(true);
    };

    const handleDelete = (rowData, rowIndex) => {
      setRowDetails({ rowData, rowIndex });
      setDeleteConfirmStatus(true);
    };

    const updateGridData = value => {
      switch (value) {
        // view all
        case 0:
          setGridData([...data]);

          break;
        // active
        case 1:
          setGridData(
            [...data].filter(
              source =>
                source.status.value != null && source.status.value.toUpperCase() === 'ACTIVE',
            ),
          );
          break;
        // in active
        case 2:
          setGridData(
            [...data].filter(
              source =>
                source.status.value != null && source.status.value.toUpperCase() === 'INACTIVE',
            ),
          );
          break;
        // broken
        case 3:
          setGridData(
            [...data].filter(
              source =>
                source.status.value != null && source.status.value.toUpperCase() === 'BROKEN',
            ),
          );
          break;
        default:
          setGridData([...data]);
          break;
      }
    };

    const handleIngestionActions = (option, rowData) => {
      const updatedData = JSON.parse(
        JSON.stringify(
          updateIngestedDataBasedOnFieldType({
            type: 'array',
            ingestionAction: option,
            current: urlsRef.current || [],
            ingested: rowData,
          }),
        ),
      );
      if (gridAPI && gridAPI.current) {
        gridAPI.current.setRowData(updatedData);
      }
      updateData(updatedData);
    };

    return (
      <div className="support-urls-wrapper">
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { resetForm }) => {
                let updatedUrls = [...data];
                updatedUrls.unshift({ ...values, id: rowId });
                updateData(updatedUrls);
                resetForm();
              }}
              validate={async values => validate(values, data, validateSupportingUrl)}
            >
              {props => {
                formRef.current = props;
                return (
                  <FormBuilder
                    formikProps={props}
                    layoutSchema={entityName == 'organization' ? updatedLayoutSchema : layoutSchema}
                    formSchema={updatedFormSchema}
                    onSubmitValidationError={onSubmitValidationError}
                    disableControls={disableEdit || checkForIngestionAction(gridData)}
                  />
                );
              }}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <TabFilter filterUrlValue={filterValue} urlHandleChange={filterHandleChangeData} />
          </Grid>
          <Grid item xs={12}>
            <DNADataGrid
              key={id.toString()}
              columnDefs={columnDefs({
                handleChange,
                handleDelete,
                handleEdit,
                handleIngestionActions,
                disableEdit,
                gridData,
                entityName,
              })}
              rowData={JSON.parse(JSON.stringify(gridData))}
              rowHeight={35}
              frameworkComponents={{
                circle: InputIcon,
                actions: TableActions,
                checkbox: InputCheckbox,
                ingestionActions: IngestionActions,
                DefaultCellRender,
              }}
              onGridReady={onGridReady}
              getRowClass={({ data: rowData }) => {
                return rowData && rowData.isDisabled ? 'disable-grid-row' : '';
              }}
            />
          </Grid>
        </Grid>
        {deleteConfirmStatus && (
          <ConfirmationMessage
            isOpen
            onClose={proceedToDelete}
            message="Do you want to delete this row?"
          />
        )}

        {editModalStatus && (
          <SupportingUrlsModal
            urls={data}
            row={rowDetails}
            onClose={onRowDetailsUpdate}
            formSchema={formSchema}
            statuses={statuses}
            validateSupportingUrl={validateSupportingUrl}
            entityName={entityName}
          />
        )}
      </div>
    );
  },
);

SupportingUrls.displayName = 'SupportingUrls';

SupportingUrls.propTypes = {
  updateData: PropTypes.func.isRequired,
  data: PropTypes.array,
  statuses: PropTypes.array,
  onUrlChange: PropTypes.func,
  validateSupportingUrl: PropTypes.func,
  disableEdit: PropTypes.bool,
  id: PropTypes.number,
  name: PropTypes.string,
};

SupportingUrls.defaultProps = {
  statuses: [
    { id: 1, value: 'Active' },
    { id: 2, value: 'InActive' },
    { id: 3, value: 'Broken' },
  ],
  onUrlChange: () => {},
  validateSupportingUrl: () => {},
  disableEdit: false,
  id: 0,
};

export default SupportingUrls;
