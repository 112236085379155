import DescriptiveData from '../../../screens/drugs/features/chemicalData/tabs/descriptiveData';
import MolecularData from '../../../screens/drugs/features/chemicalData/tabs/molecularData';
import Patent from '../../../screens/drugs/features/chemicalData/tabs/patent';
import AssociatedDrugs from '../../../screens/drugs/features/drugAssociations/tabs/associatedDrugs';
import AssociatedTrials from '../../../screens/drugs/features/drugAssociations/tabs/associatedTrials';
import Agreement from '../../../screens/drugs/features/drugLicensingAvailability/tabs/agreement';
import Availability from '../../../screens/drugs/features/drugLicensingAvailability/tabs/availability';
import DrugLicensingAvailability from '../../../screens/drugs/features/drugLicensingAvailability/tabs/drugLicensingAvailability';
import Approvals from '../../../screens/drugs/features/marketing/tabs/approvals';
import ExpeditedReviewDesign from '../../../screens/drugs/features/marketing/tabs/expeditedReviewDesign';
import Filings from '../../../screens/drugs/features/marketing/tabs/filings';
import OrphanDrugStatus from '../../../screens/drugs/features/marketing/tabs/orphanDrugStatus';
import Warnings from '../../../screens/drugs/features/marketing/tabs/warnings';
import Phase1 from '../../../screens/drugs/features/phases/tabs/phase1';
import Phase2 from '../../../screens/drugs/features/phases/tabs/phase2';
import Phase3 from '../../../screens/drugs/features/phases/tabs/phase3';
import PreClinicalInVitro from '../../../screens/drugs/features/phases/tabs/preClinicalInVitro';
import PreClinicalInVivo from '../../../screens/drugs/features/phases/tabs/preClinicalInVivo';
import PreClinicalPatents from '../../../screens/drugs/features/phases/tabs/preClinicalPatents';

import PersonBasicInfo from '../../../screens/person/features/basicAndContactInfo';
import PersonAdditionalInfo from '../../../screens/person/features/additionalInfo';
import AssociatedTrialsPerson from '../../../screens/person/features/associations/tabs/associatedTrials';
import AssociatedOrganization from '../../../screens/person/features/associations/tabs/associatedOrganization/';
import PersonNotes from '../../../screens/person/features/notes';

import CompanynBasicInfo from '../../../screens/company/features/basicAndContactInfo';
import CompanyAdditionalInfo from '../../../screens/company/features/additionalInfo';
import CompanyAssociations from '../../../screens/company/features/associations';
import CompanyNotes from '../../../screens/company/features/notes';

import OrganizationBasicInfo from '../../../screens/organization/features/basicAndContactInfo';
import OrganizationAdditionalInfo from '../../../screens/organization/features/additionalInfo';
import CurrentAssociatedPeople from '../../../screens/organization/features/associations/tabs/currentAssociatedPeople';
import OngoingAssociatedTrials from '../../../screens/organization/features/associations/tabs/ongoingAssociatedTrials';
import AllAssociatedTrials from '../../../screens/organization/features/associations/tabs/allAssociatedTrials';
import OrganizationNotes from '../../../screens/organization/features/notes';

const DrugTabComponents = {
  ChemicalData: {
    DescriptiveData,
    MolecularData,
    Patent,
  },
  DrugAssociations: {
    AssociatedDrugs,
    AssociatedTrials,
  },
  DrugLicensingAvailability: {
    Agreement,
    Availability,
    DrugLicensingAvailability,
  },
  Marketing: {
    Approvals,
    Filings,
    ExpeditedReviewDesign,
    OrphanDrugStatus,
    Warnings,
  },
  Phases: {
    Phase1,
    Phase2,
    Phase3,
    PreClinicalInVitro,
    PreClinicalInVivo,
    PreClinicalPatents,
  },
};

const TabComponents = {
  drugs: DrugTabComponents,
  person: {
    // person: {
    BasicAndContactInfo: PersonBasicInfo,
    Associations: { AssociatedTrialsPerson, AssociatedOrganization },
    AdditionalInfo: PersonAdditionalInfo,
    Notes: PersonNotes,
    // },
  },
  organization: {
    // organization: {
    BasicAndContactInfo: OrganizationBasicInfo,
    Associations: { CurrentAssociatedPeople, OngoingAssociatedTrials, AllAssociatedTrials },
    AdditionalInfo: OrganizationAdditionalInfo,
    Notes: OrganizationNotes,
    // },
  },
  company: {
    company: {
      BasicAndContactInfo: CompanynBasicInfo,
      Associations: CompanyAssociations,
      AdditionalInfo: CompanyAdditionalInfo,
      Notes: CompanyNotes,
    },
  },
};

export default TabComponents;
