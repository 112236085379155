import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

import './index.scss';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const AlertDialog = ({ variant, severity, message, title, isDialog }) => {
  const classes = useStyles();
  return (
    <>
      {!isDialog && (
        <div>
          <Alert variant={variant} severity={severity}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {message}
          </Alert>
        </div>
      )}
      {isDialog && (
        <div className="alert-wrapper">
          <Backdrop className={classes.backdrop} open={true}>
            <Alert variant={variant} severity={severity} className="alert-dialog">
              {title && <AlertTitle>{title}</AlertTitle>}
              {message}
            </Alert>
          </Backdrop>
        </div>
      )}
    </>
  );
};

AlertDialog.propTypes = {
  variant: PropTypes.string,
  severity: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  isDialog: PropTypes.bool,
};

AlertDialog.defaultProps = {
  variant: 'standard',
  severity: 'success',
  message: '',
  title: null,
  isDialog: true,
};

export default AlertDialog;
