import { format, isValid as isValidDate } from 'date-fns';
import { isEqual } from 'lodash';

import { fieldTypes } from '../../../../../../framework/formBuilder/utils';
import {
  getClassNameForIngestion,
  checkForIngestionAction,
} from '../../../../../../utils/generic/helper';

export const validate = (values, { isMandatoryField }) => {
  let errors = {};

  if (!values.patentNumber && !values.priorityDate && !values.country && !values.priorityCountry) {
    isMandatoryField = false
  }

  if (isMandatoryField) {
    if (!values.patentNumber) {
      errors = {
        ...errors,
        patentNumber: 'Please enter the patent id',
      };
    }
    if (!values.priorityDate) {
      errors = {
        ...errors,
        priorityDate: 'Please enter the priority date',
      };
    }
    if (!values.country) {
      errors = {
        ...errors,
        country: 'Please select the country',
      };
    }
    if (!values.priorityCountry) {
      errors = {
        ...errors,
        priorityCountry: 'Please select the priority country',
      };
    }
  }

  let numbers = /^[0-9]+$/;

  if (values.patentNumber.trim().length > 15) {
    errors.patentNumber = 'Patent id should be less than 15 characters.';
  } else if (values.patentNumber && !values.patentNumber.match(numbers)) {
    errors.patentNumber = 'Patent id should be Numeric';
  }

  if (values.priorityDate) {
    if (!isValidDate(values.priorityDate)) {
      errors.priorityDate = 'Please enter a valid date (MMM-dd-yyyy)';
    }
  }
  return errors;
};

export const isPatentDataExists = (
  data,
  { patentNumber, priorityDate, country, priorityCountry },
) => {
  let isDataExists = false;
  data.forEach(c => {
    if (
      c.patentNumber.toLowerCase().trim() === patentNumber.toLowerCase().trim() &&
      c.priorityDate === priorityDate &&
      isEqual(c.country && c.country.countryName, country && country.countryName) &&
      isEqual(
        c.priorityCountry && c.priorityCountry.countryName,
        priorityCountry && priorityCountry.countryName,
      )
    ) {
      isDataExists = true;
    }
  });
  return isDataExists;
};

export const dateFormatter = date => {
  return date
    ? `${date.getUTCFullYear()}-${parseInt(date.getMonth(), 10) + 1}-${date.getDate()}`
    : null;
};

export const initialValues = {
  patentNumber: '',
  priorityDate: null,
  country: null,
  priorityCountry: null,
};

export const layoutSchema = {
  layout: 'row',
  className: 'main-section',
  spacing: 2,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'patentNumber',
      size: 3,
      className: '',
    },
    {
      layout: 'priorityDate',
      size: 2,
      className: '',
    },
    {
      layout: 'country',
      size: 3,
      className: '',
    },
    {
      layout: 'priorityCountry',
      size: 3,
      className: '',
    },
    {
      layout: 'button',
      size: 1,
      className: 'add-button',
    },
  ],
};

export const formSchema = {
  patentNumber: {
    name: 'patentNumber',
    type: fieldTypes.text,
    label: 'PATENT ID',
    props: {
      isMandatory: false,
      inputHeight: 'ht-lg',
    },
  },
  priorityDate: {
    name: 'priorityDate',
    type: fieldTypes.customDate,
    label: 'PRIORITY DATE',
    props: {
      disablePast: false,
      clearable: true,
      isKeyboardCtrl: true,
      autoOk: true,
    },
  },
  country: {
    name: 'country',
    type: fieldTypes.select,
    label: 'COUNTRY',
    options: [
      { label: 'DATE1', value: '1' },
      { label: 'DATE1', value: '2' },
      { label: 'DATE1', value: '3' },
    ],
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true
    },
  },
  priorityCountry: {
    name: 'priorityCountry',
    type: fieldTypes.select,
    label: 'PRIORITY COUNTRY',
    props: {
      isMandatory: false,
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true
    },
    options: [
      { label: 'DATE1', value: '1' },
      { label: 'DATE1', value: '2' },
      { label: 'DATE1', value: '3' },
    ],
  },
  button: {
    name: 'button',
    type: fieldTypes.button,
    label: '+ ADD',
  },
};

export const rowData = [];

// ag-grid configuration
export const columnDefs = ({
  handleEdit,
  handleDelete,
  handleIngestionActions,
  disableEdit,
  gridData,
}) => [
    {
      headerName: 'PATENT ID',
      field: 'patentNumber',
      width: 220,
      sortable: true,
      cellRenderer: ({ value }) => {
        return value || 'NA';
      },
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    },

    {
      headerName: 'PRIORITY DATE',
      field: 'priorityDate',
      width: 160,
      sortable: true,
      cellRenderer: ({ value }) => (value ? format(new Date(value), 'MMM-dd-yyyy') : 'NA'),
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    },
    {
      headerName: 'COUNTRY',
      field: 'country.countryName',
      width: 220,
      sortable: true,
      cellRenderer: ({ value }) => {
        return value || 'NA';
      },
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    },
    {
      headerName: 'PRIORITY COUNTRY',
      field: 'priorityCountry.countryName',
      width: 150,
      sortable: true,
      cellRenderer: ({ value }) => {
        return value || 'NA';
      },
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { rowIndex, data } = params;
        return {
          canDisableEdit: disableEdit || data.isDisabled,
          handleEdit: () => {
            handleEdit(data, rowIndex);
          },
        };
      },
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { rowIndex, data } = params;
        return {
          canDisableDelete: disableEdit || data.isDisabled,
          handleDelete: () => {
            handleDelete(data, rowIndex);
          },
        };
      },
    },
    {
      headerName: '',
      minWidth: 100,
      maxWidth: 100,
      field: '',
      hide: !checkForIngestionAction(gridData, true),
      cellRenderer: 'ingestionActions',
      cellRendererParams: ({ data }) => {
        return {
          isDisabled: disableEdit,
          ingestionAction: data ? data.ingestionAction : 'none',
          handleIngestionActions: option => handleIngestionActions(option, data),
        };
      },
    },
  ];
