import { createAction } from '@reduxjs/toolkit';

const advancedSearch = 'advancedSearch/';

export const getAdvancedSearchCategoryData = createAction(
  `${advancedSearch}getAdvancedSearchCategoryData`,
);
export const getAdvancedSearchCategoryDataSuccess = createAction(
  `${advancedSearch}getAdvancedSearchCategoryDataSuccess`,
);
export const getAdvancedSearchCategoryTitleData = createAction(
  `${advancedSearch}getAdvancedSearchCategoryTitleData`,
);
export const getAdvancedSearchCategoryTitleDataSuccess = createAction(
  `${advancedSearch}getAdvancedSearchCategoryTitleDataSuccess`,
);

export const getAllSavedSearches = createAction(`${advancedSearch}getAllSavedSearches`);
export const getAllSavedSearchesSuccess = createAction(
  `${advancedSearch}getAllSavedSearchesSuccess`,
);
export const addSavedSearch = createAction(`${advancedSearch}addSavedSearch`);
export const editSavedSearch = createAction(`${advancedSearch}editSavedSearch`);
export const updateSavedSearch = createAction(`${advancedSearch}updateSavedSearch`);
export const deleteSavedSearch = createAction(`${advancedSearch}deleteSavedSearch`);
export const getSearchResults = createAction(`${advancedSearch}getSearchResults`);
export const getSearchResultsSuccess = createAction(`${advancedSearch}getSearchResultsSuccess`);
export const getEntityColDefSuccess = createAction(`${advancedSearch}getEntityColDefSuccess`);
export const getPreviousSearchedConditions = createAction(
  `${advancedSearch}getPreviousSearchedConditions`,
);
export const getPreviousSearchedGroup = createAction(`${advancedSearch}getPreviousSearchedGroup`);

export const getAdvancedSearchDashboard = createAction(
  `${advancedSearch}getAdvamncedSearchDashboard`,
);
export const resetPagintionCount = createAction(`${advancedSearch}resetPagintionCount`,);
