import omit from 'lodash/omit';

export const getNavbarConfig = data => {
  const { id, name } = data;
  return [
    {
      id: 1,
      name: 'TRIAL ID',
      value: id.toString(),
    },
    {
      id: 1,
      name: 'TRIAL TITLE',
      value: name,
    },
  ];
};

export const onlyPositiveNumber = value => {
  if (parseFloat(value) && parseFloat(value) < 0) {
    value = '';
  }
  return value;
};

export const mappingForTrialSummaryDropdowns = key => {
  let value = '';
  switch (key) {
    case 'primaryDrugs':
    case 'secondaryDrugs':
      value = 'drug';
      break;
    case 'sponsors':
      value = 'sponsor';
      break;
    case 'collaborators':
      value = 'collaborator';
      break;
    case 'associatedCros':
      value = 'associatedCRO';
      break;
    case 'tags':
      value = 'tag';
      break;
    case 'locations':
      value = 'location';
      break;
    case 'allLocations':
      value = 'allLocation';
      break;
    default:
      break;
  }
  return value;
};

export const convertDataForTrialSummaryDropdowns = (data, apiKey, searchKey = '') => {
  const convertedData = Array.isArray(data) ? [...data] : [];
  if (searchKey) {
    return convertedData.map(d => {
      return {
        label: searchKey === 'descriptiveName' ? d.primaryName[searchKey] : d[searchKey],
        // Adding drugNameId as part of data modelling changes
        value: apiKey === 'drug' ? d.id : d?.drugNameId ? d.drugNameId : d.id,
        ...(searchKey === 'descriptiveName' && d?.drugNameId && { drugNameId: d.drugNameId }),
        ...(d?.prodStatus && { prodStatus: d.prodStatus }),
        ...(d?.sponsorType && {
          sponsorType: { id: d?.sponsorType?.id, value: d?.sponsorType?.desc },
        }),
        ...(apiKey === 'drug' && d?.parentID && { parentID: d.parentID }),
      };
    });
  } else {
    return convertedData.map(d => {
      return {
        ...d,
        label: d[apiKey]?.value,
        value: d[apiKey]?.id,
      };
    });
  }
};

export const dateViews = [
  {
    id: 1,
    label: 'Date',
    value: 'date',
  },
  {
    id: 2,
    label: 'Month',
    value: 'month',
  },
  {
    id: 3,
    label: 'Year',
    value: 'year',
  },
];

export const dateViewsConfig = {
  date: {
    format: 'MMM-dd-yyyy',
    views: ['date'],
  },
  month: {
    format: 'MMM yyyy',
    views: ['month', 'year'],
  },
  year: {
    format: 'yyyy',
    views: ['year'],
  },
};

export const getFormattedDataForDropdowns = (key, data) => {
  const result = Array.isArray(data) ? [...data] : [];
  return result.map(d => {
    return {
      ...omit(d, ['label', 'value']),
      id: d.id || -1,
      [key]: {
        ...d[key],
        id: d.value,
        value: d.label,
      },
    };
  });
};
