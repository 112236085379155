import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import format from 'date-fns/format';

import { ListView, Link, DNADataGrid, DefaultCellRender } from '../../../../../../framework';
import { getOngoingAssociatedTrial } from '../../../../../../store/actions/organization';
import PaginationWrapper from '../../../../../../generic/features/paginationWrapper';

const OngoingAssociatedTrials = () => {
  const {
    current: { id: organizationId },
    organizationAssociation: {
      ongoingAssociatedTrials: {
        ongoingTrials,
        pagination,
        pagination: { pageSize, pageIndex },
      },
    },
  } = useSelector(state => state.organization, shallowEqual);

  const history = useHistory();
  const dispatch = useDispatch();

  const [pageDetails, setPageDetails] = useState({ pageIndex: 1, pageSize: 50 });
  const currPage = pageDetails.pageIndex;
  const currpageSize = pageDetails.pageSize;
  const currentPageRef = useRef({ pageIndex: currPage, pageSize: currpageSize });
  const [sortedColumnName, setSortedColumnName] = useState('');
  const [sortingOrder, setSortingOrder] = useState(false);
  const prevPageState = useRef({ prevSortingColumn: '', prevSortingOrder: false });

  useEffect(() => {
    if (ongoingTrials.length === 0) {
      dispatch(
        getOngoingAssociatedTrial({
          pageSize,
          pageIndex,
          entityPrimaryKey: organizationId,
          sortOrder: [{ columnName: 'All', isDescendingOrder: false }],
        }),
      );
    }
  }, []);

  useEffect(() => {
    currentPageRef.current = { pageIndex: currPage, pageSize: currpageSize };
  }, [currPage, currpageSize]);

  const navigateToTrial = id => {
    window.open(`/clinicalTrials/${id}`);
  };

  let prevSortingOrder = false;
  let prevSortingColumn = '';
  const handleTableHeaderClick = (columnName, isDescendingOrder) => {
    setSortedColumnName(columnName);
    setSortingOrder(isDescendingOrder);
    // if condition added to avoid dispatching the action multiple times due to comparator function
    if (prevSortingOrder !== isDescendingOrder || prevSortingColumn !== columnName) {
      prevSortingColumn = columnName;
      prevSortingOrder = isDescendingOrder;
      dispatch(
        getOngoingAssociatedTrial({
          pageIndex: currentPageRef.current.pageIndex,
          pageSize: currentPageRef.current.pageSize,
          entityPrimaryKey: organizationId,
          sortOrder: [
            {
              columnName: columnName,
              isDescendingOrder: isDescendingOrder,
            },
          ],
        }),
      );
    }
  };

  const handleSortChanged = args => {
    const { api } = args;
    const sortState = api.getSortModel();
    if (sortState.length === 0) {
      dispatch(
        getOngoingAssociatedTrial({
          pageIndex: currentPageRef.current.pageIndex,
          pageSize: currentPageRef.current.pageSize,
          entityPrimaryKey: organizationId,
          sortOrder: [
            {
              columnName: 'All',
              isDescendingOrder: false,
            },
          ],
        }),
      );
    }
  };

  const columnDefs = ({ handleTableHeaderClick }) => [
    {
      headerName: 'CTID',
      field: 'trialId',
      width: 100,
      sortable: true,
      cellRenderer: 'Link',
      cellRendererParams: ({ data }) => {
        if (data.trialId) {
          return {
            link: data.trialId ? data.trialId.toString() : '',
            onClick: () => {
              navigateToTrial(data.trialId);
            },
          };
        }
      },
      comparator: (...args) => {
        let isDescendingOrder = args[4];
        handleTableHeaderClick('ctid', isDescendingOrder);
      },
    },
    {
      headerName: 'TRIAL TITLE',
      field: 'title',
      width: 100,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4];
        handleTableHeaderClick('trail_title', isDescendingOrder);
      },
    },
    {
      headerName: 'DISEASE TYPE',
      headerClass: 'diseaseTypesOnAssociatedTrialsOrg',
      field: 'diseaseTypes',
      width: 100,
      sortable: true,
      cellRenderer: 'ListView',
      cellRendererParams: ({ value }) => {
        return {
          list: value,
        };
      },
    },
    {
      headerName: 'STATUS',
      field: 'status',
      width: 100,
      sortable: true,
      comparator: (...args) => {
        let isDescendingOrder = args[4];
        handleTableHeaderClick('trail_status', isDescendingOrder);
      },
    },
    {
      headerName: 'START DATE',
      field: 'startDate',
      width: 100,
      sortable: true,
      cellRenderer: ({ data: { startDate } }) => {
        return startDate ? format(new Date(startDate), 'MMM-dd-yyyy') : 'NA';
      },
      comparator: (...args) => {
        let isDescendingOrder = args[4];
        handleTableHeaderClick('start_date', isDescendingOrder);
      },
    },
    {
      headerName: 'PRIMARY COMPLETION DATE',
      field: 'endDate',
      width: 100,
      sortable: true,
      cellRenderer: ({ data: { endDate } }) => {
        return endDate ? format(new Date(endDate), 'MMM-dd-yyyy') : 'NA';
      },
      comparator: (...args) => {
        let isDescendingOrder = args[4];
        handleTableHeaderClick('completion_date', isDescendingOrder);
      },
    },
  ];

  return (
    <PaginationWrapper
      pageInfo={pagination}
      onChange={pageDetails => {
        setPageDetails(pageDetails);
        dispatch(
          getOngoingAssociatedTrial({
            ...pageDetails,
            entityPrimaryKey: organizationId,
            sortOrder: [
              {
                columnName: sortedColumnName,
                isDescendingOrder: sortingOrder,
              },
            ],
          }),
        );
      }}
    >
      <DNADataGrid
        handleSortChanged={handleSortChanged}
        // columnDefs={[...columnDefs]}
        columnDefs={columnDefs({ handleTableHeaderClick })}
        rowData={JSON.parse(JSON.stringify(ongoingTrials))}
        frameworkComponents={{ ListView, Link, DefaultCellRender }}
        rowHeight={60}
      />
    </PaginationWrapper>
  );
};

export default OngoingAssociatedTrials;
