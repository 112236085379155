const countryFeatures = [
  {
    name: 'Record Update And Summary',
    value: 'RecordUpdateAndSummary',
    key: 'recordUpdateAndSummary',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    error: null,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'General Information',
    value: 'GeneralInformation',
    key: 'generalInformation',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    error: null,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'Audit Trail',
    value: 'AuditTrail',
    key: 'auditTrail',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
];

export default countryFeatures;
