import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { isEqual, differenceWith, differenceBy } from 'lodash';
import PropTypes from 'prop-types';

import FeatureFooter from '../../../../framework/featureFooter';
import {
  savePersonDetails,
  resetPersonDetails,
  updatePersonDetails,
  searchPersonNoteType,
} from '../../../../store/actions/person';
import { personAPIKeys } from '../../../../store/helpers/person';
import { isDataEqual } from '../../../../utils/generic/helper';
import FeatureNotes from '../../../../generic/features/notes';
import ClearConfirmation from '../../../../generic/modals/clearConfirmation';
import { showModal } from '../../../../store/actions/globalActions';
import { ALERT_MESSAGES } from '../../../../utils/generic/constants';
import './index.scss';

export const Notes = forwardRef(({ data, permissions: { disableEdit } }, ref) => {
  const dispatch = useDispatch();
  const {
    current: { notes, id },
    original: { notes: originalNotes },
    masterData,
  } = useSelector(state => state.person, shallowEqual);
  const { notesTypes = [] } = masterData;
  const [clearModal, setClearModal] = useState(false);
  const [rowId, setRowId] = useState(-1);
  const gridRef = useRef(null);

  const hasNotesChanged = () => !isDataEqual(notes, originalNotes, 'array');
  const isChanged = () =>
    gridRef.current.isChanged() || !isDataEqual(notes, originalNotes, 'array');

  useImperativeHandle(ref, () => ({
    isChanged,
  }));

  const saveNotes = tabData => {
    const updatedPersonNotes = differenceWith(notes, originalNotes, isEqual).reverse();
    const deletedPersonNotes = differenceBy(originalNotes, notes, 'id');
    if (updatedPersonNotes.length === 0 && deletedPersonNotes.length === 0) {
      dispatch(showModal(ALERT_MESSAGES.NOTES_SAVE_ERROR));
    } else {
      dispatch(
        savePersonDetails({
          body: { id, updateType: 3, notes: updatedPersonNotes, deletedNotes: deletedPersonNotes },
          tabData,
          key: personAPIKeys.NOTES,
        }),
      );
      gridRef.current.resetFilters();
    }
  };

  const handleClear = () => {
    setClearModal(true);
  };

  const proceedToClear = status => {
    if (status) {
      dispatch(resetPersonDetails(personAPIKeys.NOTES));
      gridRef.current.reset();
    } else {
      setClearModal(false);
    }
  };

  return (
    <div className="person-notes-wrapper">
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item xs={12} className="separate-line">
          <FeatureFooter
            handleSaveAndNext={() => saveNotes(data)}
            handleSave={() => saveNotes(null)}
            handleClear={() => handleClear()}
            disabled={disableEdit}
          />
          <div className="person-notes-form">
          <FeatureNotes
            notesData={notes}
            noteTypes={notesTypes}
            isChanged={hasNotesChanged}
            ref={gridRef}
            type="person"
            handleSearchText={searchValue =>
              dispatch(searchPersonNoteType({ id, searchKey: searchValue }))
            }
            updateNotes={(notesData, flag) => {
              dispatch(
                updatePersonDetails({
                  key: 'notes',
                  data: flag
                    ? notesData.map(item => ({
                        id: rowId,
                        ...item,
                      }))
                    : notesData,
                }),
              );
              setRowId(rowId - 1);
            }}
            disableEdit={disableEdit}
          />
          </div>
        </Grid>
      </Grid>
      {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
    </div>
  );
});

Notes.propTypes = {
  permissions: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

Notes.displayName = 'Notes';

export default Notes;
