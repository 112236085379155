import { getFormattedDate } from './helper';
import { isEmpty } from 'lodash';
import { ENTITY_TYPES } from './constants';
import { toolTipValues } from '../../utils/generic/helper';

export const getCellRenderer = ({
  configObj,
  handleEdit,
  handleManualQueueExit,
  handleProximityView,
  handleCrossTeamNavigation,
  entity,
  selectedFeature
}) => {
  const rendererEnum = {
    default: 0,
    listView: 1,
    linkView: 2,
    dateView: 3,
    circleIcon: 4,
    unassignedLink: 5,
    manualExit: 6,
    editable: 7,
    proximity: 8,
    crossTeamNavigation: 9,
  };


  let parsedObj = { cellClass: [`ag-${configObj.field}`], headerName: configObj.headerName };

  switch (configObj.formatterType) {
    case rendererEnum.listView:
      parsedObj = {
        ...parsedObj,
        field: '',
        cellRenderer: 'ListView',
        minWidth: 200,
        cellRendererParams: ({ data }) => {
          const itemsToShow = entity === ENTITY_TYPES.ORGANIZATION ? 3 : 1;
          return {
            list: data[configObj.field]
              ? data[configObj.field].filter(element => !isEmpty(element))
              : [],
            keyValue: configObj.keyValue || '',
            itemsToShow,
          };
        },
      };
      break;
    case rendererEnum.linkView:
      parsedObj = {
        ...parsedObj,
        field: '',
        cellRenderer: 'Link',
        minWidth: 200,
        cellRendererParams: ({ data }) => {
          return {
            urlLink: data[configObj.field],
            isDynamic: true,
          };
        },
      };
      break;
    case rendererEnum.dateView:
      parsedObj = {
        ...parsedObj,
        field: '',
        minWidth: 150,
        cellRenderer: params => {
          const dateParam = params.data[configObj.field] || '';
          return getFormattedDate(dateParam);
        },
      };
      break;
    case rendererEnum.circleIcon: //may be object or prodstatus number
      parsedObj = {
        ...parsedObj,
        cellRenderer: 'ProductionStatus',
        headerName: 'PS',
        minWidth: 70,
        maxWidth: 70,
        resizable: false,
        cellRendererParams: ({ data }) => {
          if (data[configObj.field] == 0) {
            return {
              prodStatus: 6,
            };
          }

          return {
            prodStatus: data[configObj.field],
          };
        },
        tooltipValueGetter: (params) => {
          const { productionStatus } = params.data
          if (selectedFeature == 'clinicalTrials' || selectedFeature == 'drugs') {
            if (!productionStatus || productionStatus > 6 || productionStatus < 0) {
              return '';
            }
            const tooltip = toolTipValues[productionStatus]
            return tooltip.display;
          } else {
            if (params.data.prodStatus?.id == null) {
              if (!params.data.trialProductionStatus || params.data.trialProductionStatus > 6 || params.data.trialProductionStatus < 0) {
                if (params.data.trialProductionStatus == 0) {
                  const tooltip = toolTipValues[params.data.trialProductionStatus]
                  return tooltip.display;
                }
                return '';
              }
              const tooltip = toolTipValues[params.data.trialProductionStatus]
              return tooltip.display;
            } else if (!params.data.prodStatus?.id || params.data.prodStatus.id > 6 || params.data.prodStatus.id < 0) {
              return '';
            }
            const tooltip = toolTipValues[params.data.prodStatus.id]
            return tooltip.display;
          }
        }
      };
      break;
    case rendererEnum.unassignedLink:
      parsedObj = {
        headerName: '',
        headerClass: 'disableResize',
        minWidth: 50,
        maxWidth: 50,
        resizable: false,
        pinned: 'right',
        field: '',
        cellRenderer: 'IngestedUnassignedModal',
        cellRendererParams: params => {
          const { data, api, rowIndex } = params;
          return {
            rowParams: { data, api, rowIndex },
          };
        },
      };
      break;
    case rendererEnum.manualExit:
      parsedObj = {
        headerName: '',
        headerClass: 'disableResize',
        minWidth: 50,
        maxWidth: 50,
        resizable: false,
        pinned: 'right',
        field: '',
        cellRenderer: 'ManualQueueExit',
        cellRendererParams: params => {
          const { data: rowData } = params;
          return {
            rowData,
            handleManualQueueExit,
          };
        },
      };
      break;
    case rendererEnum.editable:
      parsedObj = {
        headerName: '',
        headerClass: 'disableResize',
        minWidth: 50,
        maxWidth: 50,
        resizable: false,
        pinned: 'right',
        field: '',
        cellRenderer: 'actions',
        cellRendererParams: params => {
          const { data } = params;
          return {
            handleEdit: () => {
              handleEdit(data);
            },
          };
        },
      };
      break;
    case rendererEnum.proximity:
      parsedObj = {
        headerName: '',
        field: '',
        headerClass: 'disableResize',
        minWidth: 50,
        maxWidth: 50,
        resizable: false,
        pinned: 'right',
        cellRenderer: 'ComparisonView',
        cellRendererParams: params => {
          const { data: rowData } = params;
          return {
            rowData,
            handleProximityView,
          };
        },
      };
      break;
    case rendererEnum.crossTeamNavigation:
      parsedObj = {
        ...parsedObj,
        field: '',
        cellRenderer: 'Link',
        minWidth: 150,
        cellRendererParams: ({ data }) => {
          const link = data[configObj.field]; //trial id
          const ingestedId = data['ingestedId']; //ingested id
          return {
            link,
            onClick: () => handleCrossTeamNavigation({ id: link, ingestedId }),
          };
        },
      };
      break;
    default:
      //may be single object, string or number
      parsedObj = {
        ...parsedObj,
        field: '',
        cellRenderer: 'DefaultCellRender',
        minWidth: 200,
        cellRendererParams: ({ data }) => {
          let value = data[configObj.field];
          if (
            configObj.field &&
            configObj.field
              .toLowerCase()
              .trim()
              .indexOf('tat') === 0
          ) {
            value = `${data[configObj.field]} hrs`;
          }
          return {
            value,
            keyValue: configObj.keyValue,
          };
        },
      };
      return parsedObj;
  }
  return parsedObj;
};
