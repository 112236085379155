import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal as MaterialModal } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import './index.scss';

const Modal = ({
  isOpen,
  children,
  heading,
  showCloseButton,
  disableBackdropClick,
  onClose,
  showSpinner,
}) => {
  // to show spinner on top of modal
  const styles = showSpinner ? { zIndex: 1100 } : {};
  const [open, updateOpenState] = useState(isOpen);

  const handleClose = () => {
    updateOpenState(false);
    onClose && onClose();
  };

  useEffect(() => {
    updateOpenState(isOpen);
  }, [isOpen]);

  return (
    <MaterialModal
      style={styles}
      className="modal-container"
      disableBackdropClick={disableBackdropClick}
      open={open}
      onClose={handleClose}
    >
      <div className="modal-body">
        <div className="modal-body-header">
          <div className="title">{heading && <h3>{heading}</h3>}</div>
          {showCloseButton && (
            <FontAwesomeIcon className={`close-icon`} icon={faTimesCircle} onClick={handleClose} />
          )}
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </MaterialModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  showCloseButton: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  heading: PropTypes.string,
  showSpinner: PropTypes.bool,
};

Modal.defaultProps = {
  showCloseButton: true,
  disableBackdropClick: true,
  heading: '',
  showSpinner: false,
};

export default Modal;
