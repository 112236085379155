import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ConfirmationMessage from '../ConfirmationMessage';
import ModalMessage from '../ModalMessage';
import { MODAL_TYPES, CLEAR_CONFIRMATION } from '../../../utils/generic/constants';

const ClearConfirmation = ({ isChanged, onClose }) => {
  const [clearModal, setClearModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  useEffect(() => {
    if (isChanged()) {
      setClearModal(true);
      setErrorModal(false);
    } else {
      setErrorModal(true);
      setClearModal(false);
    }
  }, []);

  const onModalClose = () => {
    setConfirmModal(false);
    setErrorModal(false);
    onClose(false);
  };

  const proceedToClear = status => {
    setConfirmModal(status);
    onClose(status);
  };

  return (
    <>
      {clearModal && (
        <ConfirmationMessage
          isOpen
          onClose={proceedToClear}
          message={CLEAR_CONFIRMATION.CLEAR_MODAL.message}
        />
      )}
      {confirmModal && (
        <ModalMessage
          isOpen
          modalType={MODAL_TYPES.SUCCESS}
          onClose={onModalClose}
          message={CLEAR_CONFIRMATION.CONFIRM_MODAL.message}
        />
      )}
      {errorModal && (
        <ModalMessage
          isOpen
          onClose={onModalClose}
          message={CLEAR_CONFIRMATION.ERROR_MODAL.message}
        />
      )}
    </>
  );
};

ClearConfirmation.propTypes = {
  isChanged: PropTypes.func,
  onClose: PropTypes.func,
};

export default ClearConfirmation;
