import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import TabSingleTextWrapper from '../../../../common/tabSingleTextWrapper';

const Agreement = forwardRef(({ permissions: { disableEdit }, data }, ref) => {
  const {
    current: { licensingAvailability, id: drugId, ingestedId },
    original: { licensingAvailability: originalData },
    ingested: {
      licensingAvailability: { data: ingestedData, fieldActions: ingestedActions },
    },
  } = useSelector(state => state.drugs, shallowEqual);
  return (
    <TabSingleTextWrapper
      disableEdit={disableEdit}
      footerData={data}
      data={licensingAvailability}
      parentKey="licensingAvailability"
      childKey="agreement"
      drugId={drugId}
      ingestedId={ingestedId}
      originalData={originalData}
      ingestedData={ingestedData['agreement']}
      ingestionAction={ingestedActions['agreement']}
      ref={ref}
      parentIngestionAction={ingestedActions}
    />
  );
});

Agreement.propTypes = {
  permissions: PropTypes.object.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isTab: PropTypes.bool.isRequired,
    tabs: PropTypes.array.isRequired,
  }),
};

Agreement.displayName = 'Agreement';

export default Agreement;
