export const heading = 'Administrator feature';
export const screenType = 'administrator';

export const urlType = [
  {
    id: 1,
    display: 'AUTOMATIC',
  },
  {
    id: 2,
    display: 'MANUAL',
  },
];
export const ageingType = {
  drug: 'drugAgeing',
  trial: 'ageing',
  person: 'personAgeing',
  company: 'companyAgeing',
  organization: 'organizationAgeing',
  country: 'countryAgeing',
  registry: 'registryAgeing',
  requirement: 'requirementAgeing',
  sourceDoc: 'sourceDocAgeing',
};
