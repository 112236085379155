import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { FormBuilder, Modal } from '../../../../../../framework';
import { updateCompanyDetails } from '../../../../../../store/actions/company';
import {
  isFinancialEntriesExists,
  formFinancialEntryDetails,
} from '../../../../../../utils/company/helper';
import { layoutSchema, formSchema, validate } from '../config';

const AdditionalInfoFinancialEntriesModal = ({ rowDetails, onClose }) => {
  const [errorMessage, updateErrorMessage] = useState('');
  const dispatch = useDispatch();
  const company = useSelector(state => state.company, shallowEqual);
  const {
    current: {
      additionalInfo,
      additionalInfo: { financialEntires },
    },
  } = company;

  let initialValues = { ...rowDetails };
  let updatedFormSchema = {
    ...formSchema,
    button: {
      ...formSchema.button,
      label: 'SAVE',
    },
  };

  const updatedLayoutSchema = {
    ...layoutSchema,
    content: [
      {
        layout: 'financialYearEnd',
        size: 3,
        className: '',
      },
      {
        layout: 'revenue',
        size: 2,
        className: '',
      },
      {
        layout: 'rdSpend',
        size: 3,
        className: '',
      },
      {
        layout: 'noOfEmployees',
        size: 2,
        className: '',
      },
      {
        layout: 'button',
        size: 1,
        className: ' ',
      },
    ],
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validate={async values => validate(values)}
        onSubmit={async values => {
          const currentData = [...financialEntires];
          const rowId = financialEntires.findIndex(f => f.id === rowDetails.id);
          currentData.splice(rowId, 1);
          const { revenue, rdSpend, noOfEmployees } = values;
          if (!rdSpend && !revenue && !noOfEmployees) {
            updateErrorMessage('Please add details for at least one of the following: Revenue or R&D Spend or Employees');
          } else if (isFinancialEntriesExists(currentData, values)) {
            updateErrorMessage('Entry already exists for the Financial Year');
          } else {
            const currentAdditionalInfo = { ...additionalInfo };
            const currentFinancialEntires = [...financialEntires];
            const dataToUpdate = {
              ...rowDetails,
              ...formFinancialEntryDetails({ id: rowDetails.id, ...values }),
            };
            currentFinancialEntires.splice(rowId, 1, dataToUpdate);
            currentAdditionalInfo['financialEntires'] = [...currentFinancialEntires];
            dispatch(
              updateCompanyDetails({
                key: 'additionalInfo',
                data: currentAdditionalInfo,
              }),
            );
            updateErrorMessage('');
            onClose();
          }
        }}
      >
        {props => {
          return (
            <>
              <FormBuilder
                formikProps={props}
                layoutSchema={updatedLayoutSchema}
                formSchema={updatedFormSchema}
                onSubmitValidationError={() => {}}
              />
              {errorMessage && <p className="error-text">{errorMessage}</p>}
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

AdditionalInfoFinancialEntriesModal.propTypes = {
  rowDetails: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default AdditionalInfoFinancialEntriesModal;
