import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';

import {
  FormBuilder,
  DNADataGrid,
  FeatureFooter,
  TableActions,
  IngestionActions,
} from '../../../../../../framework';
import {
  postDrugsData,
  updateDrugDetails,
  resetDrugDetailsWithKey,
} from '../../../../../../store/actions/drugs';
import { showModal } from '../../../../../../store/actions/globalActions';
import {
  ConfirmationMessage,
  ModalMessage,
  ClearConfirmation,
} from '../../../../../../generic/modals';
import {
  getGridRowIndex,
  updateIngestedDataBasedOnFieldType,
  checkForIngestionAction,
  validateIngestionAction,
  isDataEqual,
  checkUserIngestionAction,
} from '../../../../../../utils/generic/helper';
import { MODAL_MESSAGES } from '../../../../../../utils/generic/constants';
import PatentModal from './patentModal';
import {
  formSchema,
  initialValues,
  layoutSchema,
  validate,
  columnDefs,
  dateFormatter,
  isPatentDataExists,
} from './config';
import './index.scss';

const Patent = forwardRef(({ data, permissions: { disableEdit } }, ref) => {
  const [isMandatoryField, setIsMandatoryField] = useState(false);
  const {
    ancillaryMasterData: { countries },
    current: { chemStructure, id: drugsId, key, drugType, ingestedId },
    original: { chemStructure: originalChemStructure },
    ingested: {
      chemStructure: { fieldActions: ingestedActions },
    },
  } = useSelector(state => state.drugs, shallowEqual);
  const updatedFormSchema = {
    ...formSchema,
    patentNumber: {
      ...formSchema.patentNumber,
      props: {
        ...formSchema.patentNumber.props,
        isMandatory: isMandatoryField,
      },
    },
    priorityDate: {
      ...formSchema.priorityDate,
      props: {
        ...formSchema.priorityDate.props,
        isMandatory: isMandatoryField,
      },
    },
    country: {
      ...formSchema.country,
      options: countries
        ? countries.map(e => {
            return {
              value: e.id,
              label: e.countryName,
            };
          })
        : [],
        props: {
          ...formSchema.country.props,
          isMandatory: isMandatoryField,
        },
    },
    priorityCountry: {
      ...formSchema.priorityCountry,
      options: countries
        ? countries.map(e => {
            return {
              value: e.id,
              label: e.countryName,
            };
          })
        : [],
        props: {
          ...formSchema.priorityCountry.props,
          isMandatory: isMandatoryField,
        },
    },
  };
  const gridAPI = useRef(null);
  const gridDataRef = useRef([]);
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const [gridData, setGridData] = useState([]);
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [deleteConfirmStatus, setDeleteConfirmStatus] = useState(false);
  const [rowDetails, setRowDetails] = useState(null);
  const [rowId, setRowId] = useState(-1);
  const [error, setError] = useState('');
  const [clearModal, setClearModal] = useState(false);

  useEffect(() => {
    gridDataRef.current = chemStructure && chemStructure.patents ? [...chemStructure.patents] : [];
  }, [chemStructure]);

  useEffect(() => {
    setGridData(chemStructure && chemStructure.patents ? chemStructure.patents : []);
  }, [chemStructure.patents]);

  useImperativeHandle(ref, () => ({
    isChanged,
    validate: () => {
      return (
        !data.tabs[data.selectedTabIndex].hasIngestedData ||
        validateIngestionAction({
          ingestedActions: {},
          data: gridData,
          type: 'drugs',
          arrayKey: 'patents',
        }).isValid
      );
      // : !(handleValidations(activity, chemStructure) || chemicalName || smilesFormula);
    },
  }));

  const isChanged = () => {
    return data.tabs[data.selectedTabIndex].hasIngestedData
      ? checkUserIngestionAction(gridData, ingestedActions)
      : formRef.current.dirty ||
          !isDataEqual(chemStructure.patents, originalChemStructure.patents, 'array');
  };

  const onGridReady = params => {
    gridAPI.current = params.api;
  };

  const handleEdit = (rowData, rowIndex) => {
    setEditModalStatus(true);
    setRowDetails({ rowData, rowIndex });
  };

  const handleDelete = (rowData, rowIndex) => {
    setRowDetails({ rowData, rowIndex });
    setDeleteConfirmStatus(true);
  };

  const generatePatentData = (dataRowId, values) => {
    let updatedList = [...gridData];
    const date = values.priorityDate ? dateFormatter(values.priorityDate) : null;

    const updatedData = {
      id: dataRowId,
      patentNumber: values.patentNumber ? values.patentNumber.trim() : '',
      country: values.country
        ? {
            id: values.country.value,
            countryName: values.country.label,
          }
        : null,
      priorityCountry: values.priorityCountry
        ? {
            id: values.priorityCountry.value,
            countryName: values.priorityCountry.label,
          }
        : null,
      priorityDate: date,
    };
    updatedList = [updatedData, ...updatedList];
    if (
      !isEmpty(values.patentNumber.trim()) ||
      !isEmpty(values.country) ||
      !isEmpty(values.priorityCountry) ||
      !isEmpty(date)
    ) {
      if (isPatentDataExists(chemStructure.patents ? chemStructure.patents : [], updatedData)) {
        setError('Combination already exists');
      } else {
        dispatch(
          updateDrugDetails({
            key: 'chemStructure',
            data: { ...chemStructure, patents: updatedList },
          }),
        );
      }
    }
  };

  const proceedToDelete = status => {
    if (status && rowDetails) {
      const updatedList = [...gridData];
      const filteredIndex = getGridRowIndex(rowDetails, updatedList);
      updatedList.splice(filteredIndex, 1);
      dispatch(
        updateDrugDetails({
          key: 'chemStructure',
          data: { ...chemStructure, patents: updatedList },
        }),
      );
    }
    setRowDetails(null);
    setTimeout(() => {
      setDeleteConfirmStatus(false);
    }, 1000);
  };

  const onRowDetailsUpdate = result => {
    if (result) {
      const updatedList = [...gridData];
      const filteredIndex = getGridRowIndex(result, updatedList);
      updatedList.splice(filteredIndex, 1, result.rowData);

      dispatch(
        updateDrugDetails({
          key: 'chemStructure',
          data: { ...chemStructure, patents: updatedList },
        }),
      );
    }

    setEditModalStatus(false);
    setRowDetails(null);
  };

  const handleSave = tabData => {
    const { isValid, data: updatedPatents, ingestedContentResults } = validateIngestionAction({
      ingestedActions: {},
      data: gridData,
      type: 'drugs',
      arrayKey: 'patents',
    });
    if (isValid) {
      dispatch(
        postDrugsData({
          id: drugsId,
          body: {
            key,
            chemStructure: {
              ...chemStructure,
              patents: updatedPatents,
            },
            forceToFieldUpdate: 15,
            drugType,
            ingestedContentResults,
            ingestedId,
          },
          tabData,
          childTabKey: ['patents', 'id'],
        }),
      );
    } else {
      dispatch(showModal(MODAL_MESSAGES.INGESTION_ERROR));
    }
  };

  const proceedToClear = status => {
    if (status) {
      if (formRef.current) {
        formRef.current.resetForm();
      }
      dispatch(resetDrugDetailsWithKey({ childKey: 'patents', parentKey: 'chemStructure' }));
    } else {
      setClearModal(false);
    }
  };

  const handleIngestionActions = (option, rowData) => {
    const updatedData = updateIngestedDataBasedOnFieldType({
      type: 'array',
      ingestionAction: option,
      current: gridDataRef.current || [],
      ingested: rowData,
    });

    dispatch(
      updateDrugDetails({
        key: 'chemStructure',
        data: { ...chemStructure, patents: JSON.parse(JSON.stringify(updatedData)) },
      }),
    );
    if (gridAPI && gridAPI.current) {
      gridAPI.current.setRowData(updatedData);
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={2}
        className="patents-container"
      >
        <FeatureFooter
          handleSaveAndNext={() => handleSave(data)}
          handleSave={() => handleSave(null)}
          handleClear={() => setClearModal(true)}
          data={data}
          disabled={disableEdit}
        />
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { resetForm }) => {
              generatePatentData(rowId, values);
              resetForm();
              setRowId(rowId - 1);
            }}
            validate={async values => validate(values,{isMandatoryField})}
          >
            {props => {
              if(props.values.patentNumber || props.values.priorityDate || props.values.country || props.values.priorityCountry){
              setIsMandatoryField(true);
              }
              else{
              setIsMandatoryField(false);
              }
              formRef.current = props;
              return (
                <FormBuilder
                  formikProps={props}
                  layoutSchema={layoutSchema}
                  formSchema={updatedFormSchema}
                  onSubmitValidationError={() => {}}
                  disableControls={disableEdit || checkForIngestionAction([...gridData])}
                />
              );
            }}
          </Formik>
        </Grid>
        <Grid item xs={12}>
          <div className="grid-source-data">
            <DNADataGrid
              columnDefs={columnDefs({
                handleEdit,
                handleDelete,
                handleIngestionActions,
                disableEdit,
                gridData,
              })}
              rowData={JSON.parse(JSON.stringify(gridData))}
              frameworkComponents={{ actions: TableActions, ingestionActions: IngestionActions }}
              rowHeight={35}
              onGridReady={onGridReady}
              getRowClass={({ data: rowData }) => {
                return rowData && rowData.isDisabled ? 'disable-grid-row' : '';
              }}
            />
          </div>
        </Grid>
      </Grid>
      {editModalStatus && (
        <PatentModal
          chemStructure={chemStructure}
          row={rowDetails}
          onClose={onRowDetailsUpdate}
          formSchema={updatedFormSchema}
        />
      )}
      {deleteConfirmStatus && (
        <ConfirmationMessage
          isOpen
          onClose={proceedToDelete}
          message="Do you want to delete this row?"
        />
      )}
      {error && (
        <ModalMessage isError isOpen={true} message={error} onClose={() => setError(null)} />
      )}
      {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
    </>
  );
});

Patent.propTypes = {
  permissions: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default Patent;
