import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { MODAL_TYPES } from '../../../utils/generic/constants';

import Modal from '../../../framework/modal';
import { InputButton } from '../../../framework/inputs';
import Typography from '../../../framework/typography';

const ModalOptions = ({ isOpen, modalType, message, canContinue, children, confirmText }) => {
  const [showModal, update] = useState(isOpen);
  useEffect(() => update(isOpen), [isOpen]);

  const handleClick = action => {
    update(false);
    canContinue(action);
  };

  const modalTypes = () => {
    switch (modalType) {
      case MODAL_TYPES.ERROR: {
        return (
          <div className="message-modal-header-content">
            <FontAwesomeIcon className="modal-icon error" icon={faExclamationCircle} />
            <Typography
              variant="h6"
              text="error!"
              textType="uppercase"
              fontStyle="bold"
              color="red"
              padding="top"
            />
          </div>
        );
      }
      case MODAL_TYPES.SUCCESS: {
        return (
          <div className="message-modal-header-content">
            <FontAwesomeIcon className="modal-icon success" icon={faCheckCircle} />
            <Typography
              variant="h6"
              text="success!"
              textType="uppercase"
              fontStyle="bold"
              color="green"
              padding="top"
            />
          </div>
        );
      }
      case MODAL_TYPES.WARNING: {
        return (
          <div className="message-modal-header-content">
            <FontAwesomeIcon className="modal-icon warning" icon={faExclamationTriangle} />
            <Typography
              variant="h6"
              text="Warning!"
              textType="uppercase"
              fontStyle="bold"
              color="orange"
              padding="top"
            />
          </div>
        );
      }
      case MODAL_TYPES.INFO: {
        return (
          <div className="message-modal-header-content">
            <FontAwesomeIcon className="modal-icon info" icon={faInfoCircle} />
            <Typography
              variant="h6"
              text="Info!"
              textType="uppercase"
              fontStyle="bold"
              color="blue"
              padding="top"
            />
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={showModal} showCloseButton={false}>
      <section className="message-modal-container">
        <header>{modalTypes()}</header>
        <p className="message-modal-content">{message}</p>
        <footer>
          <InputButton
            variant="contained"
            buttonType="primary-btn"
            text={confirmText}
            onClick={() => handleClick(false)}
          />
          <InputButton
            variant="contained"
            buttonType="secondary-btn"
            text="Continue"
            onClick={() => handleClick(true)}
          />
        </footer>
      </section>
      {children}
    </Modal>
  );
};

ModalOptions.propTypes = {
  isOpen: PropTypes.bool,
  modalType: PropTypes.string,
  message: PropTypes.string,
  canContinue: PropTypes.func,
  confirmText: PropTypes.string,
};

ModalOptions.defaultProps = {
  isOpen: true,
  modalType: MODAL_TYPES.ERROR,
  message: '',
  canContinue: () => {},
  confirmText: '',
};

export default ModalOptions;
