import { fieldTypes } from '../../framework/formBuilder/utils';
import { DROPDOWN_TYPES } from '../../utils/generic/constants';
import format from 'date-fns/format';

export const validate = (values, isValidate, isOthertab) => {
  const errors = {};
  const disclosureRegulationInternalComments = values.disclosureRegulationInternalComments
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const transitionFutherDetails = values.transitionFutherDetails
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const applicableSectionOfDisclosureReq = values.applicableSectionOfDisclosureReq
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  if (values.authorityInCharge && values.authorityInCharge.length > 400) {
    errors.authorityInCharge = 'Please enter only 400 characters';
  }
  if (
    values.disclosureRegulationInternalComments?.trim() &&
    disclosureRegulationInternalComments.trim().length > 7000
  ) {
    errors.disclosureRegulationInternalComments = 'Please enter only 7000 characters';
  }
  if (values.transitionFutherDetails?.trim() && transitionFutherDetails.trim().length > 3000) {
    errors.transitionFutherDetails = 'Please enter only 3000 characters';
  }
  if (
    (values.isConductOfClinicalTrial == 3 || values.isConductOfClinicalTrial == 4) &&
    values.furtherDetailsOnOtherRequirement.length > 3000
  ) {
    errors.furtherDetailsOnOtherRequirement = 'Cannot enter more than 3000 characters';
  }
  if (
    values.isTransitionPeriod &&
    values.transitionPeriodDates?.trim() &&
    values.transitionPeriodDates.trim().length > 150
  ) {
    errors.transitionPeriodDates = 'Please enter only 150 characters';
  }
  if (
    values.applicableSectionOfDisclosureReq?.trim() &&
    applicableSectionOfDisclosureReq.trim().length > 1000
  ) {
    errors.applicableSectionOfDisclosureReq = 'Please enter only 1000 characters';
  }
  if (isValidate) {
    if (
      values.isMandatory &&
      values.isTransitionPeriod &&
      (transitionFutherDetails === null || transitionFutherDetails.trim().length === 0)
    ) {
      console.log('further details error');
      errors.transitionFutherDetails = 'Please enter further details';
    }
    if (values.abbreviatedName === null) {
      errors.abbreviatedName = 'Please select abbreviated name';
    }
    if (values.abbreviatedName !== null && typeof values.abbreviatedName.value === 'undefined') {
      errors.abbreviatedName = 'Please select abbreviated name';
    }
    if (values.country.length === 0) {
      errors.country = 'Please select country';
    }
    if (
      (values.isConductOfClinicalTrial == 3 || values.isConductOfClinicalTrial == 4) &&
      values.furtherDetailsOnOtherRequirement.length > 3000
    ) {
      errors.furtherDetailsOnOtherRequirement = 'Cannot enter more than 3000 characters';
    }

    if (
      (values.isConductOfClinicalTrial == 3 || values.isConductOfClinicalTrial == 4) &&
      values.furtherDetailsOnOtherRequirement.length === 0
    ) {
      errors.furtherDetailsOnOtherRequirement = 'Please enter further details on requirement';
    }
    if (values.isConductOfClinicalTrial === null) {
      errors.isConductOfClinicalTrial = 'Please select disclosure requirements.';
    }

    if (
      !values[isOthertab ? 'linkingDisclosureRegulationDoc' : 'linkingCTRegulationDoc'] ||
      !values[isOthertab ? 'linkingDisclosureRegulationDoc' : 'linkingCTRegulationDoc']?.value
    ) {
      if (values.isMandatory) {
        errors.linkingCTRegulationDoc = 'Please select valid data';
      }
    }
  }
  return errors;
};

const columnDefs = [
  {
    headerName: 'DOCUMENT ID',
    field: 'id',
    width: 250,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'DOCUMENT TITLE',
    field: 'documentTitle',
    width: 400,
  },
  {
    headerName: 'Regulatory Date',
    field: 'regulatoryDate',
    width: 350,
    sortable: true,
    cellRenderer: ({ value }) => {
      return value ? format(new Date(value), 'dd-MMM-yyyy') : 'NA';
    },
  },
];

const columnDefs1 = [
  {
    headerName: 'DOCUMENT ID',
    field: 'value',
    width: 250,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'DOCUMENT TITLE',
    field: 'documentTitle',
    width: 400,
  },
  {
    headerName: 'Regulatory Date',
    field: 'regulatoryDate',
    width: 350,
    sortable: true,
    cellRenderer: ({ value }) => {
      return value ? format(new Date(value), 'dd-MMM-yyyy') : 'NA';
    },
  },
];

export const layoutSchema = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'country',
      size: 4,
    },
    {
      layout: 'isConductOfClinicalTrial',
      size: 12,
    },
    {
      layout: 'abbreviatedName',
      size: 6,
    },
    {
      layout: 'row',
      size: 12,
      className: '',
      spacing: 6,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'isTransitionPeriod',
          size: 2,
        },
        {
          layout: 'transitionFutherDetails',
          size: 10,
        },
      ],
    },
    {
      layout: 'transitionPeriodDates',
      size: 12,
    },

    {
      layout: 'linkingCTRegulationDoc',
      size: 4,
      className: 'linkingCTRegulationDoc',
    },
    {
      layout: 'empty',
      size: 8,
    },
    {
      layout: 'linkingCTRegulationTable',
      size: 12,
      className: 'linkingCTRegulationTable',
    },

    {
      layout: 'fullNameofCT',
      size: 4,
    },
    {
      layout: 'languages',
      size: 4,
    },
    {
      layout: 'currentVersion',
      size: 4,
    },
    {
      layout: 'applicableSectionOfDisclosureReq',
      size: 12,
    },
    {
      layout: 'sourceDocs',
      size: 4,
      className: 'sourceDocs',
    },
    {
      layout: 'empty',
      size: 8,
    },
    {
      layout: 'sourceDocsTable',
      size: 12,
      className: 'sourceDocsTable',
    },
    {
      layout: 'disclosureRegulationInternalComments',
      size: 12,
    },
  ],
};

export const formSchema = {
  isConductOfClinicalTrial: {
    label: 'Are there any disclosure Requirements in this country?',
    name: 'isConductOfClinicalTrial',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
      { id: 2, display: 'Yes, Follows EU Regulation' },
      { id: 3, display: 'Yes, Follows Other Mandatory Requirement' },
      { id: 4, display: 'Yes, Follows Other Voluntary Requirement' },
    ],
    props: {
      isMandatory: true,
    },
  },
  furtherDetailsOnOtherRequirement: {
    label: 'Further details on other requirement',
    name: 'furtherDetailsOnOtherRequirement',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
  country: {
    name: 'country',
    type: fieldTypes.select_tag,
    label: 'To what country, countries or regions is this Requirement applicable?',
    options: [],
    props: {
      height: 'ht-lg',
      // isSearchable: true,
      // isClearable: true,
      // isMulti: true,
      // showSelectedData: true,
      // type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  abbreviatedName: {
    name: 'abbreviatedName',
    type: fieldTypes.select_async,
    label: 'Abbreviated name of the disclosure regulation or requirement (or N/A)',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: false,
      showSelectedData: false,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  isTransitionPeriod: {
    label: 'Transition period',
    name: 'isTransitionPeriod',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
  },
  transitionFutherDetails: {
    name: 'transitionFutherDetails',
    type: fieldTypes.textArea,
    label: 'Further details',
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
  transitionPeriodDates: {
    name: 'transitionPeriodDates',
    type: fieldTypes.textArea,
    label: 'Transition Period Dates',
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
  authorityInCharge: {
    label: 'What Authority or Agency is in charge of CT authorization/supervision?',
    name: 'authorityInCharge',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      isMandatory: true,
    },
  },
  linkingCTRegulationDoc: {
    name: 'linkingCTRegulationDoc',
    type: fieldTypes.select_async,
    label: 'LINKING TO DISCLOSURE REQUIREMENT',
    props: {
      isSearchable: true,
      isClearable: true,
      isMulti: false,
      showSelectedData: false,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  linkingCTRegulationTable: {
    name: 'linkingCTRegulationTable',
    type: fieldTypes.dndDataGrid,
    props: {
      columnDefs,
      rowHeight: 35,
    },
  },
  fullNameofCT: {
    label: 'Full (formal) name of the disclosure regulation',
    name: 'fullNameofCT',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      disabled: true,
      placeholder: '',
    },
  },
  languages: {
    name: 'languages',
    type: fieldTypes.textArea,
    label: 'Language(s) in which the disclosure regulation is written',
    props: {
      rowsMin: 2.4,
      disabled: true,
      placeholder: '',
    },
  },
  currentVersion: {
    label: 'Current version or revision number of the regulation/requirement',
    name: 'currentVersion',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      disabled: true,
      placeholder: '',
    },
  },
  applicableSectionOfDisclosureReq: {
    label: 'APPLICABLE SECTION(S) OF DISCLOSURE REQUIREMENT',
    name: 'applicableSectionOfDisclosureReq',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
      placeholder: '',
    },
  },
  sourceDocs: {
    name: 'sourceDocs',
    type: fieldTypes.select_async,
    label: 'ADDITIONNAL LINKING TO Other Source Documents',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  sourceDocsTable: {
    name: 'sourceDocsTable',
    type: fieldTypes.dndDataGrid,
    props: {
      columnDefs: columnDefs1,
      rowHeight: 35,
    },
  },
  disclosureRegulationInternalComments: {
    name: 'disclosureRegulationInternalComments',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 10,
      rowsMax: 5,
      cols: 150,
      isMandatory: false,
    },
  },
  empty: {},
};
