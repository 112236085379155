export const requirementDetailsState = {
    id: 0,
    ingestedId: 0,
    updateType: 0,
    notes: [],
    prodStatusID: 0,
    // recordUpdateAndSummary: {
    //   updateDesc: null,
    //   reasonForUpdate: []
    // }
  };
