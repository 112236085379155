/* eslint-disable array-bracket-newline */
import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { FormBuilder, Modal } from '../../../../../../framework';
import { updateCompanyDetails } from '../../../../../../store/actions/company';
import {
  convertDataForRadioOptions,
  convertObjectForSelectDropdown,
} from '../../../../../../utils/generic/helper';
import {
  isDuplicateCompany,
  formAcquiredOrSubsidiaryCompanyDetails,
} from '../../../../../../utils/company/helper';
import { layoutSchema, formSchema, validateAcquiredOrSubsidiaryCompanyDetails } from '../config';

const AdditionalInfoAcquiredCompanyModal = ({
  rowDetails,
  onClose,
  searchAcquiredOrSubsidiaryCompanyName,
}) => {
  const [errorMessage, updateErrorMessage] = useState('');
  const dispatch = useDispatch();
  const company = useSelector(state => state.company, shallowEqual);
  const {
    current: {
      additionalInfo,
      additionalInfo: { acquiredOrSubsidiaryCompanies },
    },
    masterData: { relatedCompanyTypes },
  } = company;
  const initialValues = {
    ...rowDetails,
    acquiredOrSubsidiaryCompanyName: isEmpty(
      rowDetails && rowDetails.acquiredOrSubsidiaryCompanyName,
    )
      ? null
      : convertObjectForSelectDropdown(rowDetails.acquiredOrSubsidiaryCompanyName, 'id', 'value'),
  };

  let updatedFormSchema = { ...formSchema(searchAcquiredOrSubsidiaryCompanyName) };
  updatedFormSchema = {
    ...updatedFormSchema,
    companyType: {
      ...updatedFormSchema.companyType,
      options: convertDataForRadioOptions([...relatedCompanyTypes], 'id', 'value'),
    },
    button: {
      ...updatedFormSchema.button,
      label: 'SAVE',
    },
  };

  const updatedLayoutSchema = {
    ...layoutSchema,
    content: [
      {
        layout: 'acquiredOrSubsidiaryCompanyName',
        size: 5,
        className: '',
      },
      {
        layout: 'companyType',
        size: 3,
        className: '',
      },
      {
        layout: 'yearAcquired',
        size: 2,
        className: 'ml-20',
      },
      {
        layout: 'button',
        size: 2,
        className: ' ',
      },
    ],
  };
  return (
    <Modal isOpen onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validate={async values => validateAcquiredOrSubsidiaryCompanyDetails(values)}
        onSubmit={async values => {
          const { acquiredOrSubsidiaryCompanyName } = values;
          const currentData = [...acquiredOrSubsidiaryCompanies];
          const rowId = acquiredOrSubsidiaryCompanies.findIndex(a => a.id === rowDetails.id);
          currentData.splice(rowId, 1);
          if (isDuplicateCompany(currentData, acquiredOrSubsidiaryCompanyName.value)) {
            updateErrorMessage('Company already exists');
          } else {
            const currentAdditionalInfo = { ...additionalInfo };
            const currentAcquiredOrSubsidiaryCompanyNames = [...acquiredOrSubsidiaryCompanies];
            const dataToUpdate = {
              ...rowDetails,
              ...formAcquiredOrSubsidiaryCompanyDetails({ id: rowDetails.id, ...values }),
            };
            currentAcquiredOrSubsidiaryCompanyNames.splice(rowId, 1, dataToUpdate);
            currentAdditionalInfo['acquiredOrSubsidiaryCompanies'] = [
              ...currentAcquiredOrSubsidiaryCompanyNames,
            ];
            dispatch(
              updateCompanyDetails({
                key: 'additionalInfo',
                data: currentAdditionalInfo,
              }),
            );
            updateErrorMessage('');
            onClose();
          }
        }}
      >
        {props => {
          return (
            <>
              <FormBuilder
                formikProps={props}
                layoutSchema={updatedLayoutSchema}
                formSchema={updatedFormSchema}
                onSubmitValidationError={() => {}}
              />
              {errorMessage && <p className="error-text">{errorMessage}</p>}
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

AdditionalInfoAcquiredCompanyModal.propTypes = {
  rowDetails: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  searchAcquiredOrSubsidiaryCompanyName: PropTypes.func.isRequired,
};

export default AdditionalInfoAcquiredCompanyModal;
