export const getModalMessage = locationType => {
  let data = '';
  switch (locationType) {
    case 'region':
      data = `Region`;
      break;
    case 'subRegion':
      data = `Subregion`;
      break;
    case 'country':
      data = `Country`;
      break;
    default:
      break;
  }
  return data;
};

export const getLocationSelectedIds = (locationType, response) => {
  let data = null;
  const responseRegionId = response.data.find(o => o.regionId);
  const responseSubRegionId = response.data.find(o => o.subRegionId);
  switch (locationType) {
    case 'region':
      data = null;
      break;
    case 'subRegion':
      data = {
        countryId: 0,
        regionId: responseRegionId.regionId,
        subRegionId: 0,
      };
      break;
    case 'country':
      data = {
        countryId: 0,
        regionId: null,
        subRegionId: responseSubRegionId.subRegionId,
      };
      break;
    default:
      break;
  }
  return data;
};

export const getUpdatedIds = (locationType, response) => {
  let data = null;

  switch (locationType) {
    case 'region':
      data = null;
      break;
    case 'subRegion':
      data = {
        countryId: 0,
        regionId: response.data.regionId,
        subRegionId: response.data.id,
      };
      break;
    case 'country':
      data = {
        countryId: response.data.id,
        regionId: null,
        subRegionId: response.data.subRegionId,
      };
      break;
    default:
      break;
  }
  return data;
};
