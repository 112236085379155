import React from 'react';
import PropTypes from 'prop-types';

import NavbarHeader from './navbarHeader';
import NavbarFooter from './navbarFooter';
import './index.scss';

const Navbar = ({
  children,
  showFooter,
  breadcrumbLinks,
  type,
  name,
  detailPage,
  navBarConfig,
  setSinglePagePreviewState,
  psData,
  onPsStatusSubmit,
  onOutofscopeSubmit,
  disablePreview,
  recordData,
  isIngestedRecord,
  showAdvancedSearchIcon,
  showAdvancedSearchEntityDropdown,
  onChangeEntity,
  defaultSelectedEntity,
  entityData,
  showHomeIcon,
  paginationState,
  navigateHome,
  searchPage,
  isStamped,
  onStamp,
  showStampIcon,
}) => {
  return (
    <div className="navbar-container">
      {!searchPage && <NavbarHeader
        breadcrumbLinks={breadcrumbLinks}
        type={type}
        name={name}
        detailPage={detailPage}
        navBarConfig={navBarConfig}
        recordData={recordData}
        showAdvancedSearchIcon={showAdvancedSearchIcon}
        showAdvancedSearchEntityDropdown={showAdvancedSearchEntityDropdown}
        onChangeEntity={onChangeEntity}
        defaultSelectedEntity={defaultSelectedEntity}
        entityData={entityData}
        showHomeIcon={showHomeIcon}
        navigateHome={navigateHome}
      />}
      <div className="navbar-child">{children}</div>
      {showFooter && (
        <NavbarFooter
          setSinglePagePreviewState={setSinglePagePreviewState}
          psData={psData}
          paginationState={paginationState}
          recordData={recordData}
          onPsStatusSubmit={onPsStatusSubmit}
          onOutofscopeSubmit={onOutofscopeSubmit}
          disablePreview={disablePreview}
          isIngestedRecord={isIngestedRecord}
          isStamped={isStamped}
          onStamp={onStamp}
          showStampIcon={showStampIcon}
        />
      )}
    </div>
  );
};

Navbar.propTypes = {
  showSearchBox: PropTypes.bool,
  breadcrumbLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }).isRequired,
  ),
  type: PropTypes.string,
  name: PropTypes.string,
  showFooter: PropTypes.bool,
  children: PropTypes.node.isRequired,
  detailPage: PropTypes.bool,
  navBarConfig: PropTypes.array,
  setSinglePagePreviewState: PropTypes.func,
  psData: PropTypes.object,
  onPsStatusSubmit: PropTypes.func,
  onOutofscopeSubmit: PropTypes.func,
  disablePreview: PropTypes.bool,
  recordData: PropTypes.object,
  isIngestedRecord: PropTypes.bool,
  onChangeEntity: PropTypes.func,
  defaultSelectedEntity: PropTypes.object,
  entityData: PropTypes.array,
  showAdvancedSearchIcon: PropTypes.bool,
  showAdvancedSearchEntityDropdown: PropTypes.bool,
  showHomeIcon: PropTypes.bool,
  navigateHome: PropTypes.func,
  isStamped: PropTypes.bool,
  onStamp: PropTypes.func,
  showStampIcon: PropTypes.bool,
};

Navbar.defaultProps = {
  showSearchBox: false,
  showFooter: false,
  type: '',
  name: '',
  navBarConfig: [],
  setSinglePagePreviewState: () => { },
  psData: {
    psStatus: 0,
    disabled: true,
  },
  onPsStatusSubmit: () => { },
  onOutofscopeSubmit: () => { },
  disablePreview: false,
  recordData: {
    recordId: 0,
    entity: 'NA',
    disabled: false,
  },
  showAdvancedSearchEntityDropdown: false,
  showAdvancedSearchIcon: false,
  onChangeEntity: () => { },
  defaultSelectedEntity: { id: '', value: '', lable: '' },
  entityData: [],
  showHomeIcon: false,
  navigateHome: () => { },
  isStamped: false,
  onStamp: () => { },
  showStampIcon: true,
};

export default Navbar;
