import { omit } from 'lodash';
import {
  convertObjectForApi,
  convertObjectForSelectDropdown,
  formIngestedData,
  updateCurrentDataBasedOnIngestion,
  checkUserIngestionAction,
} from '../../utils/generic/helper';
import organizationInitialState from '../../utils/organization/featureInitialState';

export const mapFormDataToStore = values => {
  let {
    id,
    street1,
    street2,
    city,
    state,
    country,
    postalCode,
    phoneNumbers,
    faxNumbers,
    websites,
    emails,
    latitude,
    longitude,
    geoLocation,
    validationCode,
    validationDescription,
    isValidated,
    isPrimary,
    citySynonym,
    stateSynonym,
    subRegion,
  } = values;

  return {
    id,
    websites: websites?.map(w => w.text),
    phoneNumbers: phoneNumbers?.map(p => p.text),
    faxNumbers: faxNumbers?.map(f => f.text),
    emails: emails?.map(e => e.text),
    street1,
    street2,
    country: country
      ? convertObjectForApi({
          data: country,
          key: 'value',
          value: 'label',
          apiKey: 'id',
          apiValue: 'value',
        })
      : null,
    state: state
      ? convertObjectForApi({
          data: state,
          key: 'value',
          value: 'label',
          apiKey: 'id',
          apiValue: 'value',
        })
      : null,
    city: city
      ? convertObjectForApi({
          data: city,
          key: 'value',
          value: 'label',
          apiKey: 'id',
          apiValue: 'value',
        })
      : null,
    postalCode,
    isPrimary: isPrimary ? isPrimary : false,
    stateSynonym: stateSynonym ? stateSynonym : null,
    citySynonym: citySynonym ? citySynonym : null,
    latitude,
    longitude,
    geoLocation: geoLocation ? geoLocation : null,
    validationCode,
    validationDescription: validationDescription ? validationDescription : null,
    isValidated,
    subRegion,
  };
};
export const mapRowDataToForm = ({
  id,
  street1,
  street2,
  city,
  state,
  country,
  postalCode,
  phoneNumbers,
  faxNumbers,
  websites,
  emails,
  geoLocation,
  validationCode,
  validationDescription,
  isValidated,
  subRegion,
}) => {
  return {
    id,
    websites: websites?.map(w => {
      return { text: w };
    }),
    phoneNumbers: phoneNumbers?.map(p => {
      return { text: p };
    }),
    faxNumbers: faxNumbers?.map(f => {
      return { text: f };
    }),
    emails: emails?.map(e => {
      return { text: e };
    }),
    street1,
    street2,
    country: country ? convertObjectForSelectDropdown(country, 'id', 'value') : null,
    state: state ? convertObjectForSelectDropdown(state, 'id', 'value') : null,
    city: city ? convertObjectForSelectDropdown(city, 'id', 'value') : null,
    postalCode,
    isPrimary: false,
    geoLocation: geoLocation ? geoLocation : null,
    validationCode,
    validationDescription,
    isValidated,
    subRegion,
  };
};

export const generateNavbarConfig = (original, id) => {
  const navBarConfig = [
    {
      id: 2,
      name: 'ORGANIZATION ID',
      value: id,
    },
    {
      id: 1,
      name: original.organizationType.value,
      value: original.name,
    },
  ];
  return navBarConfig;
};

const checkIfExists = (val, hideComma = false) => (val ? `${val}${hideComma ? '' : ', '}` : '');

export const formAddressPayload = (values, data) => {
  let addressPayload = {};
  const { street1, street2, city, state, country, postalCode } = values;
  addressPayload.fullAddress = `${checkIfExists(street1)}${checkIfExists(street2)}${checkIfExists(
    city?.label,
  )}${checkIfExists(state?.label)}${checkIfExists(country?.label)}${checkIfExists(
    postalCode,
    true,
  )}`;

  return addressPayload;
};

export const checkAddressChanges = (beforUpdate, rowData) => {
  let isChanged = false;
  if (
    !(
      beforUpdate.street1 == rowData.street1 &&
      beforUpdate.street2 == rowData.street2 &&
      (beforUpdate.country && beforUpdate.country.id) == (rowData.country && rowData.country.id) &&
      (beforUpdate.state && beforUpdate.state.id) == (rowData.state && rowData.state.id) &&
      (beforUpdate.city && beforUpdate.city.id) == (rowData.city && rowData.city.id) &&
      beforUpdate.postalCode == rowData.postalCode
    )
  ) {
    isChanged = true;
  }
  return isChanged;
};

export const setCurrentAndIngestedData = (currentData, ingestedData, tabs) => {
  let mappedIngestedData = {
    ...omit(ingestedData, ['ingestedId', 'id', 'updateType']),
  };

  // remove unwanted data from main object
  mappedIngestedData = {
    ...omit(mappedIngestedData, ['id', 'prodStatusID', 'isReviewed', 'notes']),
  };

  // remove unwanted data from organizationBasicInfo
  mappedIngestedData = {
    ...mappedIngestedData,
    organizationBasicInfo: mappedIngestedData.organizationBasicInfo
      ? omit(mappedIngestedData.organizationBasicInfo, [
          'nextFullReviewDate',
          'createdBy',
          'createdDate',
          'modifiedBy',
          'modifiedDate',
          'lastFullReviewDate',
        ])
      : null,
  };

  const updatedIngestedData = formIngestedData(mappedIngestedData);

  const updatedCurrentData = {
    ...updateCurrentDataBasedOnIngestion(currentData, mappedIngestedData),
    ingestedId: ingestedData.ingestedId,
    ingestedProductionStatusId: ingestedData.prodStatusID,
  };

  const ingestedKeys = Object.keys(updatedIngestedData).filter(key =>
    checkUserIngestionAction(updatedCurrentData[key], updatedIngestedData[key].fieldActions, false),
  );

  const updatedTabs = tabs.map(tab => ({
    ...tab,
    hasIngestedData: ingestedKeys.includes(tab.key),
  }));

  return {
    updatedIngestedData,
    updatedCurrentData,
    updatedTabs,
  };
};
export const getNextIngestedTab = (tabs, selected) => {
  const activeTabIndex = tabs.findIndex(tab => tab.value === selected);
  const ingestedTab = tabs.find((tab, index) => tab.hasIngestedData && index > activeTabIndex);
  return {
    ingestedTab,
    activeTabIndex,
  };
};

export const convertParentOrganization = data => {
  if (data.organizationBasicInfo) {
    let obj = { ...data };
    if (data.organizationBasicInfo.parentOrganization) {
      const { id, value } = data.organizationBasicInfo.parentOrganization;
      const updateValue = `${id} - ${value}`;
      obj = {
        ...obj,
        organizationBasicInfo: {
          ...obj.organizationBasicInfo,
          parentOrganization: {
            ...obj.organizationBasicInfo.parentOrganization,
            value: updateValue,
          },
        },
      };
    }
    if (data.organizationBasicInfo.healthSystemNetworkId) {
      const { id, value } = data.organizationBasicInfo.healthSystemNetworkId;
      const updateValue2 = `${id} - ${value}`;
      obj = {
        ...obj,
        organizationBasicInfo: {
          ...obj.organizationBasicInfo,
          healthSystemNetworkId: {
            ...obj.organizationBasicInfo.healthSystemNetworkId,
            value: updateValue2,
          },
        },
      };
    }
    return {
      ...obj,
      organizationBasicInfo: {
        ...obj.organizationBasicInfo,
        synonyms: obj.organizationBasicInfo.synonyms.map(val => {
          return { id: val.id, text: val.synonym };
        }),
      },
    };
  }

  return {
    ...data,
    organizationBasicInfo: {
      ...data.organizationBasicInfo,
      synonyms: data.organizationBasicInfo.synonyms?.map(val => {
        return { id: val.id, text: val.synonym };
      }),
    },
  };
};

export const updateOrganizationStampingDetails = (
  { lastFullReviewDate, lastFullReviewedBy, nextFullReviewDate },
  current = organizationInitialState,
  original = organizationInitialState,
) => {
  const updateStampDatesForObject = (object = organizationInitialState) => ({
    ...object,
    organizationBasicInfo: {
      ...object.organizationBasicInfo,
      lastFullReviewDate,
      lastFullReviewedBy,
      nextFullReviewDate,
    },
  });
  return {
    current: updateStampDatesForObject(current),
    original: updateStampDatesForObject(original),
  };
};

export const getOrganizationStampDates = ({
  lastFullReviewDate,
  lastFullReviewedBy,
  nextFullReviewDate,
}) => {
  return {
    lastFullReviewDate,
    lastFullReviewedBy,
    nextFullReviewDate,
  };
};
