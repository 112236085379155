import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { TEXT_LIMIT, VALIDATION_REGEX, DROPDOWN_TYPES } from '../../../../utils/generic/constants';
import {
  checkIngestionActionForMandatoryFields,
  isValidDate,
} from '../../../../utils/generic/helper';

export const validate = (values, nextReviewDate) => {
  const errors = {};
  if (!values.reasonForUpdate) {
    errors.reasonForUpdate = 'Please enter reason for update';
  }
  if (!values.updateDesc) {
    errors.updateDesc = 'Please enter the description';
  }
  if (!values.executiveSummary) {
    errors.executiveSummary = 'Please enter the Summary';
  }
  if (values?.updateDesc?.length > TEXT_LIMIT[400]) {
    errors.updateDesc = 'Please enter only 400 characters';
  }
  if (
    values.executiveSummary &&
    values.executiveSummary.replace(/(\r\n|\n|\r)/gm, '').trim().length > TEXT_LIMIT[7000]
  ) {
    errors.executiveSummary = 'Please enter only 7000 characters';
  }
  if (nextReviewDate && !isValidDate(nextReviewDate)) {
    errors.nextFullReviewDate = 'Please enter a valid date (dd-MMM-yyyy)';
  }
  return errors;
};

export const layoutSchema = {
  layout: 'row',
  className: 'record-update-container',
  spacing: 2,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: 'reason-for-update',
      // spacing: 3,
      type: fieldTypes.select,
      options: [],
      props: {
        height: 'ht-lg',
      },
      content: [
        {
          layout: 'reasonForUpdate',
          size: 5,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      className: 'description',
      size: 12,
      // spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'updateDesc',
          size: 5,
          className: '',
        },
      ],
    },
    { layout: 'numOfRequirementsLinked', size: 5, className: 'timeline' },
    { layout: 'whatAreTheRequirementsModel', size: 5 },
    {
      layout: 'row',
      size: 12,
      // spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'executiveSummaryRequirementId',
          size: 5,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      className: 'executive-Summary',
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'executiveSummary',
          // className: 'person-suffix',
        },
      ],
    },
  ],
};

export const formSchema = {
  reasonForUpdate: {
    name: 'reasonForUpdate',
    type: fieldTypes.select,
    label: 'REASON FOR UPDATE',
    props: {
      height: 'ht-lg',
      isMandatory: true,
      isSearchable: true,
      isClearable: true,
    },
  },
  updateDesc: {
    name: 'updateDesc',
    type: fieldTypes.text,
    label: 'REASON FOR UPDATE - DESCRIPTION',
    props: {
      isMandatory: true,
      inputHeight: 'ht-lg',
    },
  },
  numOfRequirementsLinked: {
    name: 'numOfRequirementsLinked',
    label: 'HOW MANY REQUIREMENT(S) DOES THIS COUNTRY LINK TO?',
    type: fieldTypes.radio,
    options: [
      {
        id: 1,
        value: '0',
        label: '0',
      },
      {
        id: 2,
        value: '1',
        label: '1',
      },
      {
        id: 3,
        value: '2',
        label: '2',
      },
    ],
    props: {
      type: 'group',
      className: 'timelineRadioClass',
    },
  },
  whatAreTheRequirementsModel: {
    name: 'whatAreTheRequirementsModel',
    type: fieldTypes.select,
    label: 'WHAT IS/ARE THE REQUIREMENT(S)',
    props: {
      height: 'ht-lg',
      isMandatory: false,
      isSearchable: true,
      isClearable: true,
      isMulti: true,
    },
  },
  executiveSummaryRequirementId: {
    name: 'executiveSummaryRequirementId',
    type: fieldTypes.select,
    label: 'REQUIREMENT RECORD EXECUTIVE SUMMARY TO BE DISPLAYED',
    props: {
      height: 'ht-lg',
      isMandatory: false,
      isSearchable: true,
      isClearable: true,
      isMulti: false,
    },
  },
  executiveSummary: {
    name: 'executiveSummary',
    type: fieldTypes.mdEditor,
    label: 'EXECUTIVE SUMMARY',
    props: {
      rowsMin: 10,
      rowsMax: 5,
      cols: 150,
      isMandatory: true,
    },
  },
};
