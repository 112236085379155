import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { isEqual, differenceWith, differenceBy } from 'lodash';

import FeatureFooter from '../../../../framework/featureFooter';
import {
  updateOrganizationDetails,
  postOrganization,
  resetOrganizationDetails,
  searchOrganizationNoteType,
} from '../../../../store/actions/organization';
import FeatureNotes from '../../../../generic/features/notes';
import ClearConfirmation from '../../../../generic/modals/clearConfirmation';
import { isDataEqual } from '../../../../utils/generic/helper';
import { showModal } from '../../../../store/actions/globalActions';
import { ALERT_MESSAGES } from '../../../../utils/generic/constants';
import './index.scss';

const Notes = forwardRef(({ permissions: { disableEdit } }, ref) => {
  const dispatch = useDispatch();
  const [clearModal, setClearModal] = useState(false);
  const [rowId, setRowId] = useState(-1);

  const {
    current: { notes, id },
    original: { notes: originalNotes },
    masterData,
  } = useSelector(state => state.organization, shallowEqual);
  const gridRef = useRef(null);
  const { notesTypes = [] } = masterData;

  const saveNotes = () => {
    const updatedOrganizationNotes = differenceWith(notes, originalNotes, isEqual).reverse();
    const deletedOrganizationNotes = differenceBy(originalNotes, notes, 'id');

    if (updatedOrganizationNotes.length === 0 && deletedOrganizationNotes.length === 0) {
      dispatch(showModal(ALERT_MESSAGES.NOTES_SAVE_ERROR));
    } else {
      dispatch(
        postOrganization({
          data: {
            id,
            updateType: 3,
            notes: updatedOrganizationNotes,
            deletedNotes: deletedOrganizationNotes,
          },
          key: 'notes',
        }),
      );
      gridRef.current.resetFilters();
    }
  };

  const hasNotesChanged = () => !isDataEqual(notes, originalNotes, 'array');
  const isChanged = () =>
    gridRef.current.isChanged() || !isDataEqual(notes, originalNotes, 'array');

  useImperativeHandle(ref, () => ({
    isChanged,
  }));

  const resetNotes = () => {
    setClearModal(true);
  };

  const proceedToClear = status => {
    if (status) {
      dispatch(resetOrganizationDetails({ key: 'notes' }));
      gridRef.current.reset();
    } else {
      setClearModal(false);
    }
  };
  return (
    <div className="organization-notes-wrapper">
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item xs={12} className="separate-line">
          <FeatureFooter
            disableNext
            handleSaveAndNext={() => saveNotes()}
            handleSave={() => saveNotes()}
            handleClear={() => resetNotes()}
            disabled={disableEdit}
          />
          <div className="org-notes-form">
            <FeatureNotes
              ref={gridRef}
              notesData={notes}
              noteTypes={notesTypes}
              isChanged={hasNotesChanged}
              updateNotes={(notesData, flag) => {
                dispatch(
                  updateOrganizationDetails({
                    key: 'notes',
                    data: flag
                      ? notesData.map(item => ({
                          id: rowId,
                          ...item,
                        }))
                      : notesData,
                  }),
                );
                setRowId(rowId - 1);
              }}
              handleSearchText={searchValue =>
                dispatch(searchOrganizationNoteType({ id, searchKey: searchValue }))
              }
              disableEdit={disableEdit}
              type="organization"
            />
          </div>
        </Grid>
      </Grid>
      {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
    </div>
  );
});

Notes.propTypes = {
  permissions: PropTypes.object.isRequired,
};
Notes.displayName = 'Notes';

export default Notes;
