import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Modal from '../../../../framework/modal';
import { FormBuilder } from '../../../../framework';
import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { getFormattedDate, htmlencode, htmldecode } from '../../../../utils/generic/helper';
import { validate } from '../notesConfig';
import './index.scss';

const NotesModal = ({
  row: { rowIndex, rowData },
  onClose,
  notesConfig,
  formSchema,
  noteTypes,
  notes,
  type,
}) => {
  const [initialValues, setInitialValues] = useState({ ...rowData });
  const [errorMessage, setErrorMessage] = useState('');
  const formRef = useRef(null);
  const { layoutSchema, isNotesExists, ModallayoutSchema } = notesConfig;
  const onSubmitValidationError = () => {};
  useEffect(() => {
    let updatedInitialValues = {
      ...initialValues,
      date: initialValues.date ? new Date(initialValues.date) : null,
      type: initialValues.type.id,
      text: htmldecode(initialValues.text)
    };
    setInitialValues(updatedInitialValues);
  }, []);

  // const clearBtn = { layout: 'clearButton', size: 2, className: 'add-button' };
  let updatedLayoutSchema = {
    ...ModallayoutSchema,
    content: [...ModallayoutSchema.content],
  };

  return (
    <Modal isOpen onClose={onClose} heading="EDIT">
      <div className="generic-notes-modal">
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            let updatedValues = {
              ...values,
              type: noteTypes.filter(n => n.id === values.type)[0],
              date: values.date ? getFormattedDate(values.date) : null,
              text: htmlencode(values.text)
            };

            const updatedNotes = [...notes];
            updatedNotes.splice(rowIndex, 1);
            if (isNotesExists(updatedNotes, updatedValues)) {
              setErrorMessage('Combination already exists');
            } else {
              setErrorMessage('');
              onClose({ rowIndex, rowData: updatedValues });
            }
          }}
          validate={async values => validate(values, type)}
          enableReinitialize={true}
        >
          {props => {
            formRef.current = props;
            return (
              <>
                <FormBuilder
                  formikProps={props}
                  layoutSchema={updatedLayoutSchema}
                  formSchema={{
                    ...formSchema,
                    button: {
                      ...formSchema.button,
                      label: 'SAVE',
                    },
                    clearButton: {
                      name: 'clearButton',
                      type: fieldTypes.customButton,
                      label: 'CLEAR',
                      props: {
                        onClick: () => {
                          formRef.current.resetForm();
                        },
                      },
                    },
                  }}
                  onSubmitValidationError={onSubmitValidationError}
                />
                {errorMessage && <p className="error-text">{errorMessage}</p>}
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

NotesModal.propTypes = {
  row: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  notesConfig: PropTypes.object.isRequired,
  formSchema: PropTypes.object.isRequired,
  noteTypes: PropTypes.array.isRequired,
  notes: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default NotesModal;
