import { fieldTypes } from '../../../framework/formBuilder/utils';
import {
  validateUrl,
  getClassNameForIngestion,
  checkForIngestionAction,
} from '../../../utils/generic/helper';

export const validate = (values, urls, validateSupportingUrl) => {
  const errors = {};
  const errorMessage = validateUrl(values.url);
  const duplicates = urls.filter(
    val => val.url.toLowerCase().trim() === values.url.toLowerCase().trim() && val.id !== values.id,
  );
  const customValidationError = validateSupportingUrl(values.url);
  if (errorMessage) {
    errors.url = errorMessage;
  } else if (duplicates.length > 0) {
    errors.url = 'URL already exists';
  } else if (customValidationError) {
    errors.url = customValidationError;
  }
  return errors;
};

export const initialValues = {
  url: '',
  publishOnFos: true,
  status: {
    id: 1,
    value: 'Active',
  },
};

export const layoutSchema = {
  layout: 'row',
  className: 'url-container',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'url',
      size: 6,
      className: 'url-type',
    },
    {
      layout: 'button',
      size: 2,
      className: 'add-button',
    },
  ],
};

export const formSchema = {
  url: {
    name: 'url',
    type: fieldTypes.text,
    label: 'URL',
    props: {
      placeholder: 'Insert URL...',
      inputHeight: 'ht-lg',
    },
  },
  status: {
    name: 'status',
    type: fieldTypes.select,
    label: 'STATUS',
    options: [],
    props: {
      isMandatory: false,
      height: 'ht-lg',
      // width:120
    },
  },
  button: {
    name: 'button',
    type: fieldTypes.button,
    label: '+ ADD',
    props: {
      buttonType: 'secondary-btn',
      buttonSize: 'md',
    },
  },
};

// ag-grid configuration
export const columnDefs = ({
  handleChange,
  handleDelete,
  handleEdit,
  handleIngestionActions,
  disableEdit,
  gridData,
  entityName
}) => [
    {
      headerName: 'PUBLISH ON FOS',
      width: 120,
      headerClass: ['supporting-url-fos'],
      field: 'publishOnFos',
      sortable: (entityName !== "drugs"),
      cellRenderer: 'checkbox',
      cellRendererParams: params => {
        const { api, rowIndex, value, data } = params;
        return {
          id: rowIndex.toString(),
          checked: value,
          value: '',
          label: '',
          disabled: disableEdit || data.isDisabled,
          onChange: ({ isChecked }) => {
            const gridData = [];
            for (let index = 0; index < api.getDisplayedRowCount(); index++) {
              let rowData = api.getDisplayedRowAtIndex(index).data;
              rowData = {
                ...rowData,
                publishOnFos: index === rowIndex ? isChecked : rowData.publishOnFos,
              };
              gridData.push(rowData);
            }
            const rowData = {
              ...data,
              publishOnFos: isChecked,
            };
            handleChange({ gridData, rowIndex, rowData });
          },
        };
      },
    },
    {
      headerName: 'URL',
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction) || 'url-link',
      field: 'url',
      width: 320,
      sortable: (entityName !== "drugs"),
      cellRenderer: (params) => {
        if (params.value.startsWith('http')) {
          return `<a href='${params.value}' target='_blank'>${params.value}</a>`
        }
        else {
          return `<a href='//${params.value}' target='_blank'>${params.value}</a>`
        }
      },
    },
    {
      headerName: 'STATUS',
      field: 'status',
      cellRenderer: 'circle',
      width: 100,
      cellRendererParams: ({ value }) => {
        let color = 'green';
        if (value && value.value && value.value.toUpperCase() === 'INACTIVE') {
          color = 'red';
        } else if (value && value.value && value.value.toUpperCase() === 'BROKEN') {
          color = 'black';
        }
        return {
          color,
        };
      },
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { rowIndex, data } = params;
        return {
          handleEdit: () => {
            handleEdit(data, rowIndex);
          },
          canDisableEdit: disableEdit || data.isDisabled,
        };
      },
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { rowIndex, data } = params;
        return {
          handleDelete: () => {
            handleDelete(data, rowIndex);
          },
          canDisableDelete: disableEdit || data.isDisabled,
        };
      },
    },
    {
      headerName: '',
      minWidth: 100,
      maxWidth: 100,
      field: '',
      hide: !checkForIngestionAction(gridData, true),
      cellRenderer: 'ingestionActions',
      cellRendererParams: ({ data: rowData }) => {
        return {
          ingestionAction: rowData ? rowData.ingestionAction : 'none',
          isDisabled: disableEdit,
          handleIngestionActions: option => handleIngestionActions(option, rowData),
        };
      },
    },
  ];
