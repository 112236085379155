import React, { useEffect } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';

import { ExpansionPanel } from '../../../../framework';
import PaginationWrapper from '../../../../generic/features/paginationWrapper';
import { getCompanyAssociationDetails } from '../../../../store/actions/company';
import { checkIfArrayIsEmpty } from '../../../../utils/generic/helper';
import { COMPANY_ASSOCIATIONS } from '../../../../utils/company/constants';
import AssociationsTrialsGrid from './associationsTrialsGrid';
import './index.scss';

const Associations = () => {
  const {
    original: {
      id,
      basicAndContactInfo: { parentCompany },
      additionalInfo: { acquiredOrSubsidiaryCompanies },
    },
    companyAssociations: {
      drugsData,
      pagination,
      pagination: { pageSize, pageIndex },
    },
  } = useSelector(state => state.company, shallowEqual);

  const dispatch = useDispatch();
  const defaultData = {
    trialsData: [],
    pagination: { pageSize: 50, pageIndex: 1, rowCount: 0 },
  };

  useEffect(() => {
    if (checkIfArrayIsEmpty(drugsData)) {
      dispatch(
        getCompanyAssociationDetails({
          pageSize,
          pageIndex,
          companyIds: getCompanyIds(),
        }),
      );
    }
  }, []);

  const getCompanyIds = () => {
    let companyIds = [id];
    if (parentCompany && parentCompany.id) {
      companyIds.push(parentCompany.id);
    }
    return [
      ...companyIds,
      ...acquiredOrSubsidiaryCompanies
        .map(a => a.acquiredOrSubsidiaryCompanyName && a.acquiredOrSubsidiaryCompanyName.id)
        .filter(f => f > 0),
    ];
  };

  const associationPanels = drugsData.map(({ id: drugId, key, primaryName, trials }) => ({
    tabName: `${COMPANY_ASSOCIATIONS.DRUG_ID} : ${drugId} | ${COMPANY_ASSOCIATIONS.DRUG_KEY} : ${key} | ${COMPANY_ASSOCIATIONS.DRUG_NAME} : ${primaryName}`,
    tabKey: `${drugId} - ${key}`,
    details: <AssociationsTrialsGrid drugId={drugId} trials={trials || defaultData} />,
  }));

  return (
    <div className="company-associations">
      {checkIfArrayIsEmpty(drugsData) ? (
        <div className="no-data">{COMPANY_ASSOCIATIONS.NO_DATA_AVAILABLE}</div>
      ) : (
        <PaginationWrapper
          pageInfo={pagination}
          onChange={pageDetails =>
            dispatch(
              getCompanyAssociationDetails({
                ...pageDetails,
                companyIds: getCompanyIds(),
              }),
            )
          }
        >
          <ExpansionPanel panels={associationPanels} />
        </PaginationWrapper>
      )}
    </div>
  );
};

export default Associations;
