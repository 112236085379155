import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Modal from '../copyContentModal/modal';
import CopyContent from '../copyContentModal';
import './index.scss';

const ListView = ({ list, keyValue, itemsToShow }) => {
  const [showCopyContentModal, setShowCopyContentModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setShowCopyContentModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const itemsToDisplay = [];
  if (list && list.length) {
    for (let i = 0; i < itemsToShow; i += 1) {
      if (i < list.length) {
        let item = '';
        if (typeof list[i] === 'object') {
          item = isEmpty(list[i]) ? '' : list[i][keyValue || 'value'];
        } else {
          item = typeof list[i].toString() === 'string' ? list[i].toString() : '';
        }
        item && itemsToDisplay.push(item);
      }
    }
  }

  const getListValues =
    list &&
    list.length > 1 &&
    list.map((element, index) => {
      let listValue = '';
      if (element) {
        if (typeof element === 'object') {
          listValue = isEmpty(element) ? null : element[keyValue || 'value'];
        } else {
          listValue = typeof element.toString() === 'string' ? element.toString() : null;
        }
      }
      return <div key={index}>{listValue}</div>;
    });

  const renderListItem = itemList => {
    let htmlElement = '';
    if (list.length <= itemsToShow) {
      htmlElement = itemList.map((ele, index) => (
        <CopyContent key={index} lineCount={1}>
          {ele.trim()}
        </CopyContent>
      ));
    } else {
      htmlElement = itemList.map((ele, index) => (
        <div key={index} className="list-item">
          {ele.trim()}
        </div>
      ));
    }
    return htmlElement;
  };

  return (
    <>
      {list && list.length ? renderListItem(itemsToDisplay) : 'NA'}
      {list && list.length > itemsToShow && (
        <a className="view-button" onClick={handleClick}>
          +{list.length - itemsToShow} More
        </a>
      )}
      {showCopyContentModal && (
        <Modal
          handleClick={handleClick}
          list={getListValues}
          type="list"
          anchorEl={anchorEl}
          handleClose={handleClose}
        ></Modal>
      )}
    </>
  );
};

export default ListView;

ListView.propTypes = {
  list: PropTypes.array.isRequired,
  keyValue: PropTypes.string,
  itemsToShow: PropTypes.number,
};

ListView.defaultProps = {
  list: [],
  keyValue: '',
  itemsToShow: 1,
};
