import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { VALIDATION_REGEX } from '../../../../utils/generic/constants';
import {
  checkIngestionActionForMandatoryFields,
  isValidDate,
} from '../../../../utils/generic/helper';

export const validate = (values, isValidate, emails, phones) => {
  const errors = {};
  // if (values.contactPhone && values.contactPhone.length > 15) {
  //   errors.contactPhone = 'Please enter valid phone numbers';
  // }
  if (values.contactInformation?.trim() && values.contactInformation.trim().length > 500) {
    errors.contactInformation = 'Please enter only 500 characters';
  }
  const contactEmail = values?.contactEmail?.split(', ')[0];
  const contactPhone = values?.contactPhone?.split(', ')[0];
  if (isValidate) {
    if (contactPhone?.trim() && !VALIDATION_REGEX.PHONE.test(contactPhone)) {
      errors.contactPhone = 'Please enter valid phone numbers';
    }
    if (contactEmail?.trim() && contactEmail.length > 200) {
      errors.contactEmail = 'Please enter only 200 characters';
    }
    if (contactEmail?.trim() && !VALIDATION_REGEX.EMAIL.test(contactEmail)) {
      errors.contactEmail = 'Please enter valid email address';
    }
  } else {
    if (values.contactPhone?.trim() && !VALIDATION_REGEX.PHONE.test(values.contactPhone)) {
      errors.contactPhone = 'Please enter valid phone numbers';
    }
    if (values.contactEmail?.trim() && values.contactEmail.length > 200) {
      errors.contactEmail = 'Please enter only 200 characters';
    }
    if (values.contactEmail?.trim() && !VALIDATION_REGEX.EMAIL.test(values.contactEmail)) {
      errors.contactEmail = 'Please enter valid email address';
    }
    if (emails?.length > 0) {
      for (let i = 0; i < emails.length; i++) {
        let emailField = emails[i].name;
        let emailValue = emails[i].value;
        if (emailValue?.trim() && emailValue.length > 200) {
          errors[emailField] = 'Please enter only 200 characters';
        }
        if (emailValue?.trim() && !VALIDATION_REGEX.EMAIL.test(emailValue)) {
          errors[emailField] = 'Please enter valid email address';
        }
      }
    }

    if (phones?.length > 0) {
      for (let i = 0; i < phones.length; i++) {
        let phoneField = phones[i].name;
        let phoneValue = phones[i].value;
        if (phoneValue?.trim() && !VALIDATION_REGEX.PHONE.test(phoneValue)) {
          errors[phoneField] = 'Please enter valid phone numbers';
        }
      }
    }
  }
  // if (isValidate) {
  //   if (!values.contactEmail?.trim()) {
  //     errors.contactEmail = 'Please enter email';
  //   }

  //   if (!values.contactPhone?.trim()) {
  //     errors.contactPhone = 'Please enter phone';
  //   }
  // }

  return errors;
};

// export const validateEmails = list => {
//   let emails = list?.filter(x => x.value).map(x => x.value);
//   if (emails) {
//     for (let i = 0; i < emails.length; i++) {
//       let title = `title${i + 1}`;
//       if (values[title]?.trim() && values[title].length > 400) {
//         errors[title] = 'Please enter only 400 characters';
//       }
//     }
//   }
// };
export const validateEmail = value => {
  let errors = '';
  if (value?.trim() && value?.length > 200) {
    errors = 'Please enter only 200 characters';
  }
  if (value?.trim() && !VALIDATION_REGEX.EMAIL.test(value)) {
    errors = 'Please enter valid email address';
  }
  return errors;
};

export const validatePhone = value => {
  let errors = '';
  if (value?.trim() && !VALIDATION_REGEX.PHONE.test(value)) {
    errors = 'Please enter valid phone numbers';
  }
  return errors;
};

export const getMultiInputData = data => {
  if (data) {
    const arr = data?.split(', ');
    if (arr.length > 1) {
      arr.shift();
      return arr;
    }
  }
};

export const getDefaultVal = data => {
  if (data) {
    const arr = data.split(', ');
    if (arr.length) {
      if (arr.length > 1) {
        return arr.shift();
      } else {
        return arr[0];
      }
    }
  }
};

export const initialValues = {
  // id: 0,
  contactPhone: '',
  contactEmail: '',
  //   executiveSummary: '',
  // p:'',
};

export const layoutSchema = {
  layout: 'row',
  className: 'record-update-container',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: 'reason-for-update',
      spacing: 3,
      type: fieldTypes.select,
      options: [],
      props: {
        height: 'ht-lg',
      },
      content: [
        {
          layout: 'contactInformation',
          size: 12,
          className: '',
        },
        {
          layout: 'contactEmail',
          size: 4,
          className: '',
        },
        {
          layout: 'contactPhone',
          size: 4,
          className: '',
        },
      ],
    },
  ],
};

export const formSchema = {
  contactInformation: {
    name: 'contactInformation',
    type: fieldTypes.text,
    label: 'Contact Information - Name / Organization',
    // props: {
    //   rowsMin: 15,
    //   rowsMax: 2.9,
    //   cols: 150,
    //   // isMandatory: true,
    // },
  },
  contactEmail: {
    name: 'contactEmail',
    type: fieldTypes.text,
    label: 'REGISTRY CONTACT INFORMATION - EMAIL',
    props: {
      height: 'ht-lg',
      // isMandatory: true,
      // isSearchable: true,
      // isClearable: true,
      validate: {
        rule: VALIDATION_REGEX.EMAIL,
        message: 'Please enter a valid email address',
      },
      showIcons: true,
    },
  },
  contactPhone: {
    name: 'contactPhone',
    // type: fieldTypes.text,
    type: fieldTypes.phoneNumberWithCountryCode,
    label: 'REGISTRY CONTACT INFORMATION - PHONE',
    props: {
      // isMandatory: true,
      validate: {
        rule: VALIDATION_REGEX.PHONE,
        message: 'Please enter a valid phone',
      },
      showIcons: true,
    },
  },
};
