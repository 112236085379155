import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';
import { omit } from 'lodash';
import { components } from 'react-select';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import List from 'devextreme-react/list';
import { TreeView, SearchEditorOptions } from 'devextreme-react/tree-view';
import 'devextreme/dist/css/dx.light.css';

import { faTimes, faSearch, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { DROPDOWN_TYPES } from '../../../utils/generic/constants';
import {
  getClassNameForIngestion,
  checkIfStringIsEmpty,
  checkIfArrayIsEmpty,
} from '../../../utils/generic/helper';
import IngestionActions from '../../ingestionActions';
import InputLabel from '../InputLabel';
import InputToolTip from '../../toolTip/inputToolTip';
import './index.scss';
import { faCircle as faOuterCircle } from '@fortawesome/free-regular-svg-icons';
import { ConfirmationMessage } from '../../../generic/modals';
import { TextBox, Button as TextBoxButton } from 'devextreme-react';
import { LoadIndicator } from 'devextreme-react/load-indicator';
const InputSelectTreeAsync = ({
  name,
  label,
  size,
  isElasticSearch,
  isMulti,
  isSearchable,
  isClearable,
  placeholder,
  hideSelectedOptions,
  showSelectedData,
  keyValue,
  onChange,
  onFilter,
  value,
  selected,
  isDisabled,
  isMandatory,
  isError,
  errorMessage,
  blurInputOnSelect,
  ingestionAction,
  ingestedData,
  defaultOptions,
  handleIngestionActions,
  isIngestionActionDisabled,
  type,
  isDependent,
  handleBlur,
  isSelectedValuesDisplayedParallelly,
  handleViewBtn,
  showDeleteConfirmationModal,
  enableSingleFamilySelect,
  parentDataArr,
}) => {
  const [selectedValue, updateSelectedValue] = useState(null);
  const [isFocussed, updateFocusState] = useState(false);
  const [focu, setFocu] = useState(true);
  const [selectedOptions, updateSelectedOptions] = useState(selected);
  const [inputValue, updateInputValue] = useState('');
  const [dropdownData, updateDropdownData] = useState({
    defaultData: [],
    searchedData: [],
  });
  const [isLoading, updateLoadingState] = useState(false);
  const [deleteConfirmStatus, setDeleteConfirmStatus] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [showLoadingStatus, setShowLoadingStatus] = useState(false);
  // const [selectedOptions, updateSelectedOptions] = useState([])
  const [currentTextValue, setCurrentTextValue] = useState('');
  const [hideDropDown, setHideDropDown] = useState(true);
  const [disabledOptions, setDisabledOptions] = useState([]);
  const textRef = useRef(null);
  const [fullDrugTypeData, setUpdateDrugTypeData] = useState([]);

  useEffect(() => {
    updateSelectedOptions(selected);
    updateSelectedValue(selected);
  }, [selected, value]);

  if (name === 'parentOrganization') {
    handleViewBtn(selectedValue);
  }

  useEffect(() => {
    if (isDependent) {
      updateDropdownData({
        defaultData: [],
        searchedData: [],
      });
    }
    // handleMenuClick()
  }, [isDependent]);

  const formOptionsData = (regions, countries, subRegions) => {
    return [
      {
        label: 'Regions',
        options: regions ? [...regions] : [],
      },
      {
        label: 'SubRegions',
        options: subRegions ? [...subRegions] : [],
      },
      {
        label: 'Countries',
        options: countries ? [...countries] : [],
      },
    ];
  };

  const updateSelectedData = (data, selectedData) => {
    return data
      ? data.map(d => {
          const matchedData = selectedData.find(s => {
            if (s.drugNameId) {
              return s.drugNameId === d.drugNameId;
            }
            return s.value === d.value;
          });
          return {
            ...d,
            isSelected: Boolean(matchedData),
            selected: Boolean(matchedData),
            isDisabled: (matchedData && matchedData.isDisabled) || false,
          };
        })
      : [];
  };

  const loadOptions = async searchValue => {
    setShowLoadingStatus(true);
    if (!checkIfStringIsEmpty(searchValue)) {
      const response = (await onFilter(searchValue)) || [];
      const updatedData = showSelectedData
        ? updateSelectedData(response, selectedOptions)
        : response;
      updateDropdownData({
        ...dropdownData,
        searchedData: updatedData,
      });
      if(name==='drugDrugTypeClassifications'){
      if (fullDrugTypeData?.length > 0) {
        const newArr = [...fullDrugTypeData, ...updatedData ];
        const uniqueArr = newArr?.filter((obj, index) => {
          return index === newArr?.findIndex(o => obj.id === o.id);
        });
        setUpdateDrugTypeData(uniqueArr);
      } else {
        setUpdateDrugTypeData(updatedData);
      }
    }

      setShowLoadingStatus(false);
    } else {
      updateDropdownData({
        ...dropdownData,
        searchedData: [],
      });
      setShowLoadingStatus(false);
    }
  };

  const handleInputChange = (val, { action }) => {
    let isInputToBeUpdated = showSelectedData
      ? action === 'input-change'
      : action === 'input-change' || action === 'set-value';
    if (isInputToBeUpdated) {
      updateInputValue(val);
      loadOptions(val);
    }
  };

  const updateDropdownValuesBasedOnSelectedData = data => {
    const { defaultData, searchedData } = dropdownData;
    updateSelectedOptions(data);
    updateDropdownData({
      defaultData: updateSelectedData(defaultData, data),
      searchedData: updateSelectedData(searchedData, data),
    });
  };

  const handleChange = e => {
    updateFocusState(false);
    if (showSelectedData) {
      let data = [...selectedOptions];
      const index = data.findIndex(s => {
        if (s.drugNameId) {
          return s.drugNameId === e.drugNameId;
        }
        return s.value === e.value;
      });
      if (index > -1) {
        data.splice(index, 1);
      } else {
        data.push(e);
      }
      if (
        name === 'mechanismOfActions' ||
        name === 'drugDrugTypeClassifications' ||
        name === 'chemicalDrugTypesSearch'
      ) {
        let rootIds = data.map(dat => dat.id);
        if (rootIds.includes(e.parentid) && e.level > 0) {
          data.pop();
        }
        data = data.filter(dat => {
          return dat.parentid !== e.id;
        });
      }

      updateDropdownValuesBasedOnSelectedData(data);
    } else {
      updateSelectedValue(e);
      onChange(e);
    }
  };

  const handleDelete = index => {
    const data = [...selectedOptions];
    data.splice(index, 1);
    updateDropdownValuesBasedOnSelectedData(data);
    onChange(data);
  };

  const onClear = () => {
    if (showDeleteConfirmationModal) {
      setDeleteConfirmStatus(true);
    } else if (!showSelectedData) {
      updateSelectedValue(null);
      updateInputValue('');
      updateDropdownData({ ...dropdownData, searchedData: [] });
      updateFocusState(false);
      updateLoadingState(false);
      onChange(null);
    }
  };

  const getOptionsClassName = data => {
    let classNames = [];
    if (data.isSelected || data.value === value) {
      classNames.push('selected-option');
      data.isDisabled && classNames.push('disable-option');
    }
    return classNames.join(' ');
  };

  let rootParentIds = [];

  if (
    name === 'mechanismOfActions' ||
    name === 'drugDrugTypeClassifications' ||
    name === 'chemicalDrugTypesSearch'
  ) {
    rootParentIds = selectedOptions.map(option => option.id);
  }

  const Option = props => {
    const { data } = props;
    const { value = '' } = { ...selectedValue };
    return (
      <components.Option {...props} className={getOptionsClassName(data)}>
        <div className="option-wrapper" onClick={() => handleChange(data)}>
          {name === 'mechanismOfActions' ||
          name === 'drugDrugTypeClassifications' ||
          name === 'chemicalDrugTypesSearch' ? (
            <div className={`level-${data.level}`}>
              {(data.level > 0 && data.isLast === false) || (data.level === 0 && data.hasChild) ? (
                <FontAwesomeIcon icon={faCircle} color="#e60073" size="xs" />
              ) : null}
              {data.level > 0 && data.isLast === true ? (
                <FontAwesomeIcon icon={faOuterCircle} color="#e60073" size="xs" />
              ) : null}{' '}
              <span
                className={
                  rootParentIds.includes(data.parentid) && data.level > 0 ? 'disabledCursor' : ''
                }
              >
                {data.label}
              </span>
            </div>
          ) : (
            data.label
          )}
        </div>
      </components.Option>
    );
  };

  const handleMenuClick = async () => {
    if (
      type === DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD &&
      (checkIfArrayIsEmpty(dropdownData.defaultData) || isDependent)
    ) {
      updateLoadingState(true);
      // to load default options
      const response = (await onFilter('')) || [];
      updateDropdownData({
        ...dropdownData,
        defaultData: showSelectedData ? updateSelectedData(response, selectedOptions) : response,
      });
      updateLoadingState(false);
    } else if (showSelectedData) {
      updateDropdownData({
        ...dropdownData,
        defaultData: updateSelectedData(dropdownData.defaultData, selectedOptions),
      });
    }
  };

  const handleMenu = () => {
    //console.log('hhhhhhh');
    // handleMenuClick()
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        {type === DROPDOWN_TYPES.TYPEAHEAD ? (
          <FontAwesomeIcon icon={faSearch} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </components.DropdownIndicator>
    );
  };

  const ClearIndicator = props => {
    return (
      <components.ClearIndicator {...props}>
        <div
          onMouseDown={({ button }) => {
            // clear only on leftclick
            if (button === 0) {
              onClear();
            }
          }}
        >
          {!isMulti && value?.label && <FontAwesomeIcon icon={faTimes} />}
        </div>
      </components.ClearIndicator>
    );
  };

  // eslint-disable-next-line react/prop-types
  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <InputToolTip>
          <div>{children}</div>
        </InputToolTip>
      </components.SingleValue>
    );
  };

  const onFocus = () => {
    updateFocusState(true);
  };

  const onBlur = () => {
    updateFocusState(false);
    if (showSelectedData) {
      onChange(selectedOptions);
    }
    updateInputValue('');
    handleBlur();
  };
  const getDefaultOptions = () => {
    return defaultOptions?.map(d => {
      return {
        ...d,
        label: d.name,
        value: d.id,
        locationType: d.type,
      };
    });
  };

  const convert = (array = []) => {
    let childsPresent = [];
    const currentSelectedNodesFamilyId = selectedOptions.map(
      op => op.familyId || op.id || op.value,
    );
    const currentSelectedNodesId = selectedOptions.map(op => op.id || op.value);
    if (!array.length) {
      return [];
    }
    var map = {};
    for (var i = 0; i < array.length; i++) {
      let obj = array[i];
      if (currentSelectedNodesId.includes(obj.id || obj.value)) {
        obj.selected = true;
      } else {
        obj.selected = false;
      }
      obj.items = [];
      obj.text = obj.displayValue || obj.description;
      obj.isExpanded = true;
      map[obj.id || obj.value] = obj;
      var parent = obj.parentid || '-';
      if (obj.parentid && map[parent] && map[parent].familyId) {
        obj.familyId = map[parent].familyId;
      } else {
        obj.familyId = obj.id || obj.value;
      }
      if (enableSingleFamilySelect) {
        if (
          currentSelectedNodesFamilyId.includes(obj.familyId) &&
          !currentSelectedNodesId.includes(obj.id || obj.value)
        ) {
          obj.disabled = true;
        } else {
          obj.disabled = false;
        }
      }

      if (!map[parent]) {
        map[parent] = {
          items: [],
          familyId: parent,
        };
      }
      map[parent].items.push(obj);
    }
    return map['-'].items;
  };
  const onSearchTyped = val => {
    console.log(val);
    loadOptions(val);
  };
  const treeViewSelectionChanged = e => {
    syncSelection(e.component);
  };

  const treeViewContentReady = e => {
    syncSelection(e.component);
  };

  const syncSelection = treeView => {
    const selectedData = treeView.getSelectedNodes().map(node => node.itemData);
    // updateSelectedOptions([...new Set([...selectedOptions, ...selectedData])])
    // updateSelectedValue([...new Set([...selectedOptions, ...selectedData])])
    // let k = replaceCommon(selectedOptions, selectedData)

    // let contains = cars1IDs.some(element => {
    //   return cars2IDs.includes(element);
    // });
    // let combined = []

    // if (contains) {
    //   combined = [
    //     ...selectedData,
    //     ...selectedOptions.filter(({ id }) => !cars1IDs.includes(id))
    //   ];
    // } else {
    //   combined = [
    //     ...selectedData,
    //     ...selectedOptions
    //   ];
    // }

    // setCurrent(selectedData)

    // const cars1IDs = [...new Set(selectedOptions.map(({ id }) => id))];
    // const cars2IDs = [...new Set(selectedData.map(({ id }) => id))];

    // cars1IDs.filter((id) => { })

    // console.log(selectedData);
    updateSelectedOptions(selectedData);
    updateSelectedValue(selectedData);
  };

  const replaceCommon = (array1, array2) => {
    var merged = _.merge(_.keyBy(array1, 'id'), _.keyBy(array2, 'id'));
    var values = _.values(merged);
    return values;
  };

  const renderListItem = item => {
    return `${item.text}`;
  };

  const handleItemClicks = node => {
    // if (isElasticSearch) {
    //   if (node.selected && node.itemData.items.length) {
    //     let childsData = getRecursiveChildData(node.itemData)
    //     let childsPresent = childsData.map(ch => ch.id)
    //     let temp = selectedOptions;
    //     temp = temp.filter(el => !childsPresent.includes(el.id));
    //     temp = [...selectedOptions, node.itemData, ...childsData]
    //     setDisabledOptions([...disabledOptions, ...childsPresent])
    //     updateSelectedOptions(temp);
    //     updateSelectedValue(temp);
    //   } else if (node.selected) {
    //     updateSelectedOptions([...selectedOptions, node.itemData]);
    //     updateSelectedValue([...selectedOptions, node.itemData]);
    //   } else if (!node.selected && node.itemData.items.length) {
    //     let childsData = getRecursiveChildData(node.itemData)
    //     let childsPresent = childsData.map(ch => ch.id)
    //     let temp = selectedOptions;
    //     temp = temp.filter(el => !childsPresent.includes(el.id));
    //     temp = temp.filter(el => el.id != node.key);
    //     let removedItemsList = disabledOptions.filter(op => !childsPresent.includes(op))
    //     setDisabledOptions([...removedItemsList])
    //     updateSelectedOptions(temp);
    //     updateSelectedValue(temp);
    //   } else {
    //     let temp = selectedOptions;
    //     temp = temp.filter(el => el.id != node.key);
    //     updateSelectedOptions([...temp]);
    //     updateSelectedValue([...temp]);
    //   }
    // } else {
    if (node.selected) {
      updateSelectedOptions([...selectedOptions, node.itemData]);
      updateSelectedValue([...selectedOptions, node.itemData]);
    } else {
      let temp = selectedOptions;
      temp = temp.filter(el => el.value != node.itemData.value);
      updateSelectedOptions([...temp]);
      updateSelectedValue([...temp]);
    }
    // }
  };

  const getRecursiveChildData = data => {
    let u = data.items;
    data.items.forEach(el => {
      if (el.items.length) {
        u = [...u, ...getRecursiveChildData(el)];
      }
    });
    return u;
  };

  const getRecursiveFormatData = (raw, result = []) => {
    let allData = [...fullDrugTypeData];
    for (let node of allData) {
      if (node.id === raw.parent.id) {
        result.unshift(node.displayValue);
        if (node.parent) {
          const found = getRecursiveFormatData(node, result);
        }
      }
    }

    return result.join(' > ');
  };

  const addFormatedString = arr => {
    const upArr = arr?.map(el => {
      if (el.parent) {
        const formatParStr = getRecursiveFormatData(el);
        return { ...el, formatString: `${formatParStr} > ${el.displayValue || el.description}` };
      } else {
        return { ...el, formatString: el.displayValue };
      }
    });
    return upArr;
  };

  useEffect(() => {
    setUpdateDrugTypeData(parentDataArr);
  }, [parentDataArr]);

  let defaultLocations = getDefaultOptions();
  defaultLocations = defaultLocations?.map(x => ({ ...omit(x, ['type']) }));
  const locationsData = updateSelectedData(defaultLocations, [...selectedOptions]);
  const regions = locationsData?.filter(opt => opt.locationType === 'Region');
  const subRegions = locationsData?.filter(c => c.locationType === 'Subregion');
  const countries = locationsData?.filter(c => c.locationType === 'Country');
  const options = formOptionsData(regions, countries, subRegions);
  return (
    <div name={name} className="input-select-async-wrapper">
      {label && (
        <InputLabel labelFor="selectLabel" size={size} text={label} isMandatory={isMandatory} />
      )}

      <div className={`${isSelectedValuesDisplayedParallelly ? 'async-selected-wrapper' : ''}`}>
        <div className={`${isSelectedValuesDisplayedParallelly ? 'async-ingestion-wrapper' : ''}`}>
          {!isMulti && deleteConfirmStatus && (
            <ConfirmationMessage
              isOpen
              message="Do you want to delete this row?"
              onClose={status => {
                if (status) {
                  updateSelectedValue(null);
                  updateInputValue('');
                  updateDropdownData({ ...dropdownData, searchedData: [] });
                  updateFocusState(false);
                  updateLoadingState(false);
                  onChange(null);
                }
                setDeleteConfirmStatus(false);
              }}
            />
          )}
          <TextBox
            ref={textRef}
            style={{
              borderRadius: '4px 4px 0px 0px',
            }}
            placeholder="Type to search drug type..."
            // onOptionChanged={(e) => { if (e.name == "text") { loadOptions(e.value) } }}
            onKeyUp={e => {
              loadOptions(e.event.target.value);
              setCurrentTextValue(e.event.target.value);
            }}
          ></TextBox>
          <div
            style={{
              marginBottom: '5px',
              maxHeight: '200px',
              display: !currentTextValue.length ? 'none' : 'block',
              overflow: 'auto',
              padding: '5px',
              borderRight: '1px solid #ddd',
              borderLeft: '1px solid #ddd',
              borderBottom: '1px solid #ddd',
              borderRadius: '0px 0px 4px 4px',
              background: '#fff',
            }}
            onMouseLeave={onBlur}
          >
            <TreeView
              id="treeview"
              key="treee"
              items={JSON.parse(JSON.stringify(convert(dropdownData.searchedData)))}
              load={true}
              searchMode={'contains'}
              showCheckBoxesMode={'normal'}
              noDataText={currentTextValue.length ? 'No results found' : 'No data'}
              selectionModes={'multiple'}
              onItemSelectionChanged={e => {
                handleItemClicks(e.node);
              }}
              selectByClick={true}
              searchEnabled={false}
              selectNodesRecursive={false}
              // searchTimeout={500}
              onMouse
              expandedExpr="isExpanded"
              visible={!showLoadingStatus}
              draw

              // itemRender={renderTreeViewItem}
              // onOptionChanged={(e) => { if (e.name == "searchValue") { handleInputChange(e.value) } }}
            ></TreeView>
            {showLoadingStatus ? (
              <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                {' '}
                <LoadIndicator id="small-indicator" height={20} width={20} />
              </div>
            ) : null}
          </div>

          {isError && <p className="error-text">{errorMessage}</p>}
          <IngestionActions
            className="pt-10"
            isDisabled={isIngestionActionDisabled}
            ingestionAction={showSelectedData ? 'none' : ingestionAction}
            handleIngestionActions={handleIngestionActions}
          >
            {!isEmpty(ingestedData) && <p className="input-ingested-text">{ingestedData.value}</p>}
          </IngestionActions>
        </div>
        {showSelectedData && (
          <div
            className={`selected-container ${
              isSelectedValuesDisplayedParallelly ? 'selected-rows-wrapper' : ''
            }`}
          >
            {((name==='drugDrugTypeClassifications')?addFormatedString(selectedOptions) : selectedOptions).map((s, index) => {
              return (
                <IngestionActions
                  isDisabled={isIngestionActionDisabled}
                  ingestionAction={s.ingestionAction}
                  handleIngestionActions={action => handleIngestionActions(action, s)}
                  key={index.toString()}
                >
                  <div
                    className={`selected-container__selected-value ${
                      isSelectedValuesDisplayedParallelly ? 'selected-rows' : ''
                    }`}
                  >
                    {keyValue === 'primaryDrugs' || keyValue === 'secondaryDrugs' ? (
                      <Link to={`/drugs/${s.value}`} target="_blank">
                        <span
                          className={`${getClassNameForIngestion(s.ingestionAction)} default-link`}
                        >
                          {s.label}
                        </span>
                      </Link>
                    ) : (
                      <span
                        className={`${getClassNameForIngestion(s.ingestionAction)} ${
                          name === 'chemicalDrugTypesSearch' || isElasticSearch
                            ? 'elastic-color'
                            : ''
                        }`}
                      >
                        {!s.formatString ? s.label : s.formatString}
                      </span>
                    )}

                    <button
                      type="button"
                      onClick={() => {
                        if (showDeleteConfirmationModal) {
                          setDeleteConfirmStatus(true);
                          setDeleteIndex(index);
                        } else handleDelete(index);
                      }}
                      disabled={isDisabled || s.isDisabled}
                    >
                      <FontAwesomeIcon icon={faTimes} className="close-icon" />
                    </button>
                  </div>
                </IngestionActions>
              );
            })}
            {deleteConfirmStatus && (
              <ConfirmationMessage
                isOpen
                message="Do you want to delete this row?"
                onClose={status => {
                  if (status) {
                    handleDelete(deleteIndex);
                  }
                  setDeleteConfirmStatus(false);
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

InputSelectTreeAsync.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  showSelectedData: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.object,
  selected: PropTypes.array,
  onChange: PropTypes.func,
  handleViewBtn: PropTypes.func,
  isMandatory: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onFilter: PropTypes.func,
  blurInputOnSelect: PropTypes.bool,
  ingestionAction: PropTypes.string,
  ingestedData: PropTypes.object,
  handleIngestionActions: PropTypes.func,
  isIngestionActionDisabled: PropTypes.bool,
  type: PropTypes.string,
  isDependent: PropTypes.bool,
  handleBlur: PropTypes.func,
  isSelectedValuesDisplayedParallelly: PropTypes.bool,
  keyValue: PropTypes.string,
  showDeleteConfirmationModal: PropTypes.bool,
};

InputSelectTreeAsync.defaultProps = {
  label: '',
  size: '16',
  placeholder: '',
  isMulti: false,
  isSearchable: false,
  isClearable: false,
  hideSelectedOptions: false,
  showSelectedData: false,
  isDisabled: false,
  value: null,
  selected: [],
  onChange: () => {},
  handleViewBtn: () => {},
  isMandatory: false,
  isError: false,
  errorMessage: '',
  blurInputOnSelect: false,
  ingestionAction: 'none',
  ingestedData: null,
  handleIngestionActions: () => {},
  isIngestionActionDisabled: false,
  type: DROPDOWN_TYPES.TYPEAHEAD,
  isDependent: false,
  handleBlur: () => {},
  isSelectedValuesDisplayedParallelly: false,
  showDeleteConfirmationModal: false,
};

export default InputSelectTreeAsync;
