import { ContactSupportOutlined } from '@material-ui/icons';
import { takeEvery, put, select } from 'redux-saga/effects';

import { auth as authApi } from '../../api';
import * as authActions from '../actions/auth';
import * as globalActions from '../actions/globalActions';

// workers
export function* getUserInfoAsync() {
  try {
    const response = yield authApi.getUserInfo();
    let userGroup = localStorage.getItem('usergroup');
    const businessUnit = response.data.data.businessUnit;
    const found = businessUnit.some(el => el.businessUserName === 'StarUser');
    localStorage.setItem('usergroupinfo', response.data.data.businessUnit.length);
    if (userGroup == null) {
      localStorage.setItem(
        'usergroup',
        found ? 'StarUser' : response.data.data.businessUnit[0].businessUserName,
      );
    }
    const { state, data } = response.data;
    if (state) {
      yield put(authActions.getUserInfoSuccess(data));
    }
  } catch (err) {}
}

// watchers
export const watchers = [
  function* watchgetUserInfoAsync() {
    yield takeEvery(authActions.getUserInfo, getUserInfoAsync);
  },
];
