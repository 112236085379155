import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import omit from 'lodash/omit';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
  FormBuilder,
  DNADataGrid,
  TableActions,
  FeatureFooter,
  IngestionActions,
  DefaultCellRender,
} from '../../../../framework';
import { InputLabel } from '../../../../framework/inputs';
import { personAPIKeys } from '../../../../store/helpers/person';
import {
  updatePersonDetails,
  resetPersonDetails,
  savePersonDetails,
  updatePersonChildValues,
} from '../../../../store/actions/person';
import { showModal } from '../../../../store/actions/globalActions';
import ClearConfirmation from '../../../../generic/modals/clearConfirmation';
import {
  MODAL_TYPES,
  MODAL_MESSAGES,
  childFormChangeObject,
} from '../../../../utils/generic/constants';
import ConfirmationMessage from '../../../../generic/modals/ConfirmationMessage';
import ModalMessage from '../../../../generic/modals/ModalMessage';
import {
  getGridRowIndex,
  updateIngestedDataBasedOnFieldType,
  validateIngestionAction,
  checkForIngestionAction,
  isDataEqual,
  checkUserIngestionAction,
  getFormattedDate,
} from '../../../../utils/generic/helper';
import {
  layoutSchema,
  formSchema,
  validate,
  initialValues,
  columnDefs,
  isRegulatoryActionsExists,
} from './config';
import PersonAdditionalInfoModal from './additionalInfoModal';
import './index.scss';

export const AdditionalInfo = forwardRef(({ data, permissions: { disableEdit } }, ref) => {
  const {
    current: { id, ingestedId, personAdditionalInfo },
    original: { personAdditionalInfo: originalPersonAdditionalInfo },
    masterData,
    ingested: {
      personAdditionalInfo: { fieldActions: ingestedActions },
    },
  } = useSelector(state => state.person, shallowEqual);

  const { regulatoryActionTypes, regulatoryActionFlags } = masterData;
  const { regulatoryActions } = personAdditionalInfo;

  const gridAPI = useRef(null);
  const additionalInfoRef = useRef(null);
  const dispatch = useDispatch();

  const [gridData, setGridData] = useState([]);
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [deleteConfirmStatus, setDeleteConfirmStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [rowDetails, setRowDetails] = useState(null);
  const [rowId, setRowId] = useState(-1);
  const [clearModal, setClearModal] = useState(false);

  const regulatoryFormRef = useRef(null);

  useEffect(() => {
    setGridData([...regulatoryActions]);
    additionalInfoRef.current = personAdditionalInfo;
  }, [personAdditionalInfo]);

  useImperativeHandle(ref, () => ({
    isChanged,
    validate: () =>
      !data.hasIngestedData ||
      validateIngestionAction({
        ingestedActions,
        data: personAdditionalInfo,
        type: 'person',
      }).isValid,
  }));

  const isChanged = () => {
    return data.hasIngestedData
      ? checkUserIngestionAction(personAdditionalInfo, ingestedActions)
      : regulatoryFormRef.current.dirty ||
          !isDataEqual(personAdditionalInfo, originalPersonAdditionalInfo);
  };

  const onGridReady = params => {
    gridAPI.current = params.api;
  };

  const onRowDetailsUpdate = rowData => {
    if (rowData) {
      const updatedRegulatoryActions = [...regulatoryActions];
      const rowIndex = getGridRowIndex(rowData, updatedRegulatoryActions);
      updatedRegulatoryActions.splice(rowIndex, 1, rowData.rowData);
      dispatch(
        updatePersonDetails({
          key: personAPIKeys.ADDITIONAL_INFO,
          data: {
            ...personAdditionalInfo,
            regulatoryActions: updatedRegulatoryActions,
          },
        }),
      );
    }

    setEditModalStatus(false);
    setRowDetails(null);
  };

  const proceedToDelete = status => {
    if (status && rowDetails) {
      const updatedRegulatoryActions = [...regulatoryActions];
      const rowIndex = getGridRowIndex(rowDetails, updatedRegulatoryActions);
      updatedRegulatoryActions.splice(rowIndex, 1);
      dispatch(
        updatePersonDetails({
          key: personAPIKeys.ADDITIONAL_INFO,
          data: {
            ...personAdditionalInfo,
            regulatoryActions: updatedRegulatoryActions,
          },
        }),
      );
    }
    setRowDetails(null);
    setTimeout(() => {
      setDeleteConfirmStatus(false);
    }, 1000);
  };

  const handleEdit = (rowData, rowIndex) => {
    setRowDetails({ rowData, rowIndex });
    setEditModalStatus(true);
  };

  const handleDelete = (rowData, rowIndex) => {
    setRowDetails({ rowData, rowIndex });
    setDeleteConfirmStatus(true);
  };

  const saveAdditionalInfo = tabData => {
    const { isValid, data: updatedData, ingestedContentResults } = validateIngestionAction({
      ingestedActions,
      data: { ...personAdditionalInfo },
      type: 'person',
    });
    if (isValid) {
      dispatch(
        savePersonDetails({
          body: {
            id,
            ingestedId,
            updateType: 2,
            personAdditionalInfo: updatedData,
            ingestedContentResults,
          },
          tabData,
          key: personAPIKeys.ADDITIONAL_INFO,
        }),
      );
    } else {
      dispatch(showModal(MODAL_MESSAGES.INGESTION_ERROR));
    }
  };

  const proceedToClear = status => {
    if (status) {
      dispatch(resetPersonDetails(personAPIKeys.ADDITIONAL_INFO));

      regulatoryFormRef.current.resetForm();
    } else {
      setClearModal(false);
    }
  };

  const updatedFormSchema = {
    ...formSchema,
    regulatoryActionType: {
      ...formSchema.regulatoryActionType,
      options: regulatoryActionTypes.map(type => ({
        ...type,
        value: type.id,
        label: type.description,
        flag:
          type.flag && type.flag.id > 0
            ? {
                value: type.flag.id,
                label: type.flag.value,
              }
            : null,
      })),
    },
    regulatoryActionFlag: {
      ...formSchema.regulatoryActionFlag,
      options: regulatoryActionFlags.map(type => ({
        ...type,
        value: type.id,
        label: type.flag,
      })),
    },
  };

  const handleIngestionActions = (option, rowData) => {
    const updatedData = updateIngestedDataBasedOnFieldType({
      type: 'array',
      ingestionAction: option,
      current: (additionalInfoRef.current && additionalInfoRef.current.regulatoryActions) || [],
      ingested: rowData,
    });
    dispatch(
      updatePersonChildValues({
        parentKey: 'personAdditionalInfo',
        childKey: 'regulatoryActions',
        data: JSON.parse(JSON.stringify(updatedData)),
      }),
    );
    if (gridAPI && gridAPI.current) {
      gridAPI.current.setRowData(updatedData);
    }
  };

  return (
    <div className="person-additional-info-wrapper">
      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid xs={12} item className="separate-line">
          <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
            <FeatureFooter
              handleSaveAndNext={() => saveAdditionalInfo(data)}
              handleSave={() => saveAdditionalInfo(null)}
              handleClear={() => setClearModal(true)}
              disabled={disableEdit}
            />
            <Grid item xs={12}>
              <Formik
                initialValues={initialValues}
                onSubmit={async (values, { resetForm }) => {
                  const regActionData = {
                    ...values,
                    date: values.date ? getFormattedDate(values.date) : null,
                    id: rowId,
                    regulatoryActionType: values.regulatoryActionType
                      ? {
                          ...omit(values.regulatoryActionType, ['label', 'value']),
                          flag: values.regulatoryActionType.flag
                            ? {
                                id: values.regulatoryActionType.flag.value,
                                value: values.regulatoryActionType.flag.label,
                              }
                            : null,
                        }
                      : null,
                    regulatoryActionFlag: values.regulatoryActionFlag
                      ? {
                          id: values.regulatoryActionFlag.value,
                          flag: values.regulatoryActionFlag.label,
                        }
                      : null,
                  };
                  const updatedRegulatoryActions = [...regulatoryActions];
                  const updatedRegulatoryAction = omit(regActionData, ['flag']);

                  if (
                    isRegulatoryActionsExists(updatedRegulatoryActions, updatedRegulatoryAction)
                  ) {
                    setErrorMessage('Combination already exists');
                  } else {
                    updatedRegulatoryActions.unshift(updatedRegulatoryAction);
                    dispatch(
                      updatePersonDetails({
                        key: personAPIKeys.ADDITIONAL_INFO,
                        data: {
                          ...personAdditionalInfo,
                          regulatoryActions: updatedRegulatoryActions,
                        },
                      }),
                    );
                    resetForm();
                    setRowId(rowId - 1);
                  }
                }}
                validate={async values => validate(values)}
              >
                {props => {
                  regulatoryFormRef.current = props;
                  return (
                    <FormBuilder
                      formikProps={props}
                      layoutSchema={layoutSchema}
                      formSchema={updatedFormSchema}
                      onSubmitValidationError={() => {}}
                      disableControls={
                        disableEdit || checkForIngestionAction([...regulatoryActions])
                      }
                    />
                  );
                }}
              </Formik>
              <Grid item xs={12} className="regulatory-actions-table-wrapper">
                <DNADataGrid
                  columnDefs={columnDefs({
                    handleDelete,
                    handleEdit,
                    handleIngestionActions,
                    disableEdit,
                    gridData,
                  })}
                  rowData={JSON.parse(JSON.stringify(gridData))}
                  rowHeight={60}
                  frameworkComponents={{
                    actions: TableActions,
                    ingestionActions: IngestionActions,
                    DefaultCellRender,
                  }}
                  onGridReady={onGridReady}
                  getRowClass={({ data: rowData }) => {
                    return rowData && rowData.isDisabled ? 'disable-grid-row' : '';
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {deleteConfirmStatus && (
        <ConfirmationMessage
          isOpen
          onClose={proceedToDelete}
          message="Do you want to delete this row?"
        />
      )}
      {errorMessage && (
        <ModalMessage
          isOpen
          modalType={MODAL_TYPES.ERROR}
          onClose={() => setErrorMessage(null)}
          message={errorMessage}
        />
      )}
      {editModalStatus && (
        <PersonAdditionalInfoModal
          regulatoryActions={regulatoryActions}
          row={rowDetails}
          onClose={onRowDetailsUpdate}
          layoutSchema={layoutSchema}
          formSchema={updatedFormSchema}
        />
      )}
      {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
    </div>
  );
});

AdditionalInfo.propTypes = {
  data: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default AdditionalInfo;
