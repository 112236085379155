import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { omit, isEmpty, isEqual } from 'lodash';
import Grid from '@material-ui/core/Grid';

import { getTypeaheadSearchUrls } from '../../../../api';
import { FormBuilder, FeatureFooter, TimeStamp } from '../../../../framework';
import { InputLabel } from '../../../../framework/inputs';
import {
  updateOrganizationDetails,
  postOrganization,
  resetOrganizationDetails,
  getOrganizationTree,
  updateOrganizationIngestedAction,
} from '../../../../store/actions/organization';
import { showModal } from '../../../../store/actions/globalActions';
import SupportingUrls from '../../../../generic/features/urls';
import ConfirmationMessage from '../../../../generic/modals/ConfirmationMessage';
import ClearConfirmation from '../../../../generic/modals/clearConfirmation';
import {
  MODAL_MESSAGES,
  childFormChangeObject,
  MODAL_TYPES,
} from '../../../../utils/generic/constants';
import {
  formatDate,
  convertObjectForSelectDropdown,
  updateIngestedDataBasedOnFieldType,
  validateIngestionAction,
  checkForIngestionAction,
  convertObjectForApi,
  onSearch,
  isDataEqual,
  checkUserIngestionAction,
  checkIfObjectIsEmpty,
  addInceptionDate,
} from '../../../../utils/generic/helper';
import OrganizationTreeView from './organizationTreeView';
import { layoutSchema, formSchema, validate, pPostArr } from './config';
import './index.scss';
import { ModalMessage } from '../../../../generic/modals';

const BasicAndContactInfo = forwardRef(
  ({ data, permissions: { disableEdit }, inceptionDate }, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [viewModal, setViewModal] = useState(false);
    const [updateNextReviewConfirmation, setUpdateNextReviewConfirmation] = useState(false);
    const [firstTimeAlert, setFirstTimeAlert] = useState(true);
    const [nextReviewDate, setNextReviewDate] = useState(null);
    const [clearModal, setClearModal] = useState(false);
    const primaryInvestigatorsRef = useRef([]);
    // const [warningMessage, setWarningMessage] = useState(null);
   
    const licensesFormRef = useRef(childFormChangeObject);
    const supportingUrlsFormRef = useRef(childFormChangeObject);

    const {
      original: { organizationBasicInfo: originalOrganizationBasicInfo, recordInceptionDate },
      current: { organizationBasicInfo, id, ingestedId, ingestedProductionStatusId },
      ingested: {
        organizationBasicInfo: { data: ingestedData, fieldActions: ingestedActions },
      },
      masterData,
      treeView,
    } = useSelector(state => state.organization, shallowEqual);

    const getInvestigators = primaryInvestigators => {
      let updatedInvestigators = '';
      if (primaryInvestigators.length) {
        primaryInvestigators.map(
          m =>
            (updatedInvestigators = updatedInvestigators.concat(m.id, ' - ', m.value.concat('\n'))),
        );
      }
      return updatedInvestigators;
    };
    const [isPermClosed, setIsPermClosed] = useState(false);
    const { organizationTypes = [], organizationLicenseTypes = [] } = masterData;
    const basicInfoRef = useRef(null);
    const initialValues = {
      ...organizationBasicInfo,
      id,
      organizationType: organizationBasicInfo.organizationType
        ? convertObjectForSelectDropdown(organizationBasicInfo.organizationType, 'id', 'value')
        : null,
      parentOrganization: organizationBasicInfo.parentOrganization
        ? convertObjectForSelectDropdown(organizationBasicInfo.parentOrganization, 'id', 'value')
        : null,
      primaryInvestigators: getInvestigators(organizationBasicInfo.primaryInvestigators),
      healthSystemNetworkId: organizationBasicInfo.healthSystemNetworkId
        ? convertObjectForSelectDropdown(organizationBasicInfo.healthSystemNetworkId, 'id', 'value')
        : null,
      synonyms:
        organizationBasicInfo.synonyms?.map(element => ({
          id: element.id || 0,
          text: element.text,
        })) || [],
    };
    let updatedBasicAndContactInfoDetails = {
      data: {},
      ingestedContentResults: [],
      tabData: null,
    };

    useEffect(() => {
      if (data.hasError && !data.hasIngestedData) {
        saveBasicInfo(null);
      }

      return () => {
        if (basicInfoRef.current) {
          dispatch(
            updateOrganizationDetails({
              key: 'organizationBasicInfo',
              data: convertFormDataToApi(basicInfoRef.current.values),
            }),
          );
        }
      };
    }, []);

    useEffect(() => {
      setNextReviewDate(organizationBasicInfo.nextFullReviewDate);
      primaryInvestigatorsRef.current = organizationBasicInfo.primaryInvestigators;
      setIsPermClosed(organizationBasicInfo?.permanentlyClosed?.id===1 ? true : false);
    }, [organizationBasicInfo]);

    useEffect(() => {
      setFirstTimeAlert(true);
    }, [originalOrganizationBasicInfo.nextFullReviewDate]);

    useImperativeHandle(ref, () => ({
      isChanged,
      validate: () => {
        if (basicInfoRef.current) {
          if (data.hasIngestedData) {
            return validateIngestionAction({
              ingestedActions,
              data: convertFormDataToApi(basicInfoRef.current.values),
              type: 'organization',
            }).isValid;
          }
          if (!checkIfObjectIsEmpty(validate(basicInfoRef.current.values))) {
            saveBasicInfo();
            return false;
          }
        }
        return true;
      },
    }));

    const isChanged = () => {
      if (data.hasIngestedData) {
        return checkUserIngestionAction(basicInfoRef.current.values, ingestedActions);
      }
      return (
        licensesFormRef.current.isChanged() ||
        supportingUrlsFormRef.current.isChanged() ||
        !isDataEqual(
          convertFormDataToApi(basicInfoRef.current.values),
          originalOrganizationBasicInfo,
        )
      );
    };

    const handleIngestionActionsForMultiSelect = ({
      option,
      ingested,
      key,
      childKey,
      disableKey,
    }) => {
      const { values, setFieldValue } = basicInfoRef.current;
      const updatedData = updateIngestedDataBasedOnFieldType({
        type: 'array',
        ingestionAction: option,
        current: values[key],
        ingested,
        childKey,
      });
      setFieldValue(key, updatedData);
      updatedFormSchema = {
        ...updatedFormSchema,
        [key]: {
          ...updatedFormSchema[key],
          props: {
            ...updatedFormSchema[key].props,
            [disableKey]: checkForIngestionAction(updatedData),
          },
        },
      };
    };

    const handleIngestionActions = (option, key, type) => {
      let ingested = ingestedData[key];
      let original = originalOrganizationBasicInfo[key];

      const { setFieldValue } = basicInfoRef.current;
      const updatedData = updateIngestedDataBasedOnFieldType({
        type,
        original,
        ingestionAction: option,
        current: organizationBasicInfo[key],
        ingested,
      });

      setFieldValue(key, updatedData);
      dispatch(
        updateOrganizationIngestedAction({
          parentKey: 'organizationBasicInfo',
          childKey: key,
          ingestionAction: option,
        }),
      );
    };

    const fetchParentOrg = async input => {
      let filteredData = await onSearch({
        searchedData: input,
        url: getTypeaheadSearchUrls('sites', 'Organization'),
      });
      if (filteredData) {
        filteredData = filteredData.filter(d => d.id !== id);
        filteredData = filteredData.map(m => {
          return {
            ...m,
            value: m.id,
            label: `${m.id} - ${m.name}`,
          };
        });
      } else {
        dispatch(showModal(MODAL_MESSAGES.API_ERROR));
      }
      return filteredData;
    };

    const updateOrganizationInfo = (key, value) => {
      let updatedDetails = { ...organizationBasicInfo };
      updatedDetails[key] = value;
      dispatch(updateOrganizationDetails({ key: 'organizationBasicInfo', data: updatedDetails }));
    };

    const convertFormDataToApi = ({
      organizationType,
      supportingUrls,
      parentOrganization,
      healthSystemNetworkId,
      ...remainingValues
    }) => {
      let updated = {
        ...remainingValues,
        organizationType: convertObjectForApi({
          data: organizationType,
          key: 'value',
          value: 'label',
          apiKey: 'id',
          apiValue: 'value',
        }),
        supportingUrls,
        parentOrganization: isEmpty(parentOrganization)
          ? null
          : convertObjectForApi({
              data: parentOrganization,
              key: 'value',
              value: 'label',
              apiKey: 'id',
              apiValue: 'value',
            }),
        healthSystemNetworkId: isEmpty(healthSystemNetworkId)
          ? null
          : convertObjectForApi({
              data: healthSystemNetworkId,
              key: 'value',
              value: 'label',
              apiKey: 'id',
              apiValue: 'value',
            }),
        primaryInvestigators: primaryInvestigatorsRef.current,
      };
      return omit(updated, ['id']);
    };

    const saveBasicInfo = tabData => {
      if (basicInfoRef.current) {
        const { submitForm, values } = basicInfoRef.current;
        let updateValues = {
          ...values,
        };
        if (values.parentOrganization) {
          updateValues = {
            ...updateValues,
            parentOrganization: {
              ...values.parentOrganization,
              label: values.parentOrganization.label.replace(
                `${values.parentOrganization.value} - `,
                '',
              ),
            },
          };
        }
        if (values.healthSystemNetworkId) {
          updateValues = {
            ...updateValues,
            healthSystemNetworkId: {
              ...values.healthSystemNetworkId,
              label: values.healthSystemNetworkId.label.replace(
                `${values.healthSystemNetworkId.value} - `,
                '',
              ),
            },
          };
        }
        const { isValid, data: result, ingestedContentResults } = validateIngestionAction({
          ingestedActions,
          data: {
            ...convertFormDataToApi(updateValues),
            nextFullReviewDate: values.nextFullReviewDate
              ? new Date(values.nextFullReviewDate).toDateString()
              : null,
          },
          type: 'organization',
        });
        if (isValid) {
          updatedBasicAndContactInfoDetails = {
            data: result,
            ingestedContentResults,
            tabData,
          };
          submitForm();
        } else {
          dispatch(showModal(MODAL_MESSAGES.INGESTION_ERROR));
        }
      }
    };

    const resetBasicInfo = () => {
      setClearModal(true);
    };

    const proceedToClear = status => {
      if (status) {
        if (basicInfoRef && basicInfoRef.current) {
          basicInfoRef.current.resetForm();

          licensesFormRef.current.reset();
          supportingUrlsFormRef.current.reset();
        }
        dispatch(resetOrganizationDetails({ key: 'organizationBasicInfo' }));
      } else {
        setClearModal(false);
      }
    };

    const handleView = () => {
      dispatch(getOrganizationTree(id));
      setViewModal(true);
    };

    const onOrgNavigation = treeId => {
      setViewModal(false);
      if (treeId !== id) {
        history.push(`/organization/${treeId}`);
      }
    };

    const onUrlChange = updatedSupportingUrls => {
      if (basicInfoRef && basicInfoRef.current) {
        const basicInfoData = convertFormDataToApi(basicInfoRef.current.values);

        dispatch(
          updateOrganizationDetails({
            key: 'organizationBasicInfo',
            data: {
              ...basicInfoData,
              supportingUrls: updatedSupportingUrls,
            },
          }),
        );
      }
    };

    const handleNextReviewDateChange = selectedDate => {
      const formatSelectedDate = selectedDate ? formatDate(selectedDate) : null;
      const formatPrevSelectedDate = nextReviewDate ? formatDate(nextReviewDate) : null;
      if (
        !isEqual(formatSelectedDate, formatPrevSelectedDate) &&
        firstTimeAlert &&
        nextReviewDate
      ) {
        setUpdateNextReviewConfirmation(true);
        setNextReviewDate(selectedDate);
      } else if (!isEqual(formatSelectedDate, formatPrevSelectedDate)) {
        setNextReviewDate(selectedDate);
        updateOrganizationInfo('nextFullReviewDate', selectedDate);
      }
    };
    const proceedToUpdateNextReviewDate = flag => {
      if (flag) {
        updateOrganizationInfo('nextFullReviewDate', nextReviewDate);
        setFirstTimeAlert(false);
      } else {
        updateOrganizationInfo('nextFullReviewDate', organizationBasicInfo.nextFullReviewDate);
      }
      setUpdateNextReviewConfirmation(false);
    };

    let [viewBtn, setViewBtn] = useState(null);

    const handleViewBtn = value => {
      setViewBtn(Boolean(value));
    };

    // const handleOnFocus = () => {
    //   if (id !== 0) {
    //     setWarningMessage(true)

    //   }
    // }

    let updatedFormSchema = {
      ...formSchema,
      name: {
        ...formSchema.name,
        props: {
          ...formSchema.name.props,
          disabled: checkForIngestionAction(ingestedActions['name']),
          isIngestionActionDisabled: disableEdit,
          ingestionAction: ingestedActions['name'],
          ingestedData: ingestedData['name'],
          handleIngestionActions: option => handleIngestionActions(option, 'name', 'text'),
          // onFocus: handleOnFocus
        },
      },
      organizationType: {
        ...formSchema.organizationType,
        props: {
          ...formSchema.organizationType.props,
          ingestionAction: ingestedActions['organizationType'],
          ingestedData: ingestedData['organizationType'],
          handleIngestionActions: option =>
            handleIngestionActions(option, 'organizationType', 'object'),
          isDisabled: checkForIngestionAction(ingestedActions['organizationType']),
          isIngestionActionDisabled: disableEdit,
        },
        options: organizationTypes.length
          ? organizationTypes.map(type => convertObjectForSelectDropdown(type, 'id', 'value'))
          : [],
      },
      licenses: {
        ...formSchema.licenses,
        props: {
          ...formSchema.licenses.props,
          showSortedOrder: true,
          valueForSorting: 'licenseValue',
          handleIngestionActions: (option, ingested) =>
            handleIngestionActionsForMultiSelect({
              option,
              ingested,
              key: 'licenses',
              childKey: 'id',
              disableKey: 'disabled',
            }),
          disabled: checkForIngestionAction(
            basicInfoRef.current ? basicInfoRef.current.values.licenses : [],
          ),
          isIngestionActionDisabled: disableEdit,
          ref: licensesFormRef,
        },
        options: organizationLicenseTypes.map(m => {
          return { ...m, label: m.value, value: m.id };
        }),
      },
      parentOrganization: {
        ...formSchema.parentOrganization,
        props: {
          ...formSchema.parentOrganization.props,
          onFilter: fetchParentOrg,
          ingestionAction: ingestedActions['parentOrganization'],
          ingestedData: ingestedData['parentOrganization'],
          handleIngestionActions: option =>
            handleIngestionActions(option, 'parentOrganization', 'object'),
          isDisabled: checkForIngestionAction(ingestedActions['parentOrganization']),
          isIngestionActionDisabled: disableEdit,
          handleViewBtn,
        },
      },
      primaryInvestigators: {
        ...formSchema.primaryInvestigators,
        props: {
          ...formSchema.primaryInvestigators.props,
          onChange: () => {},
        },
      },
      button: {
        ...formSchema.button,
        props: {
          ...formSchema.button.props,
          onClick: handleView,
          isDisabled: id == 0 || !viewBtn,
        },
      },
      healthSystemNetworkId: {
        ...formSchema.healthSystemNetworkId,
        props: {
          ...formSchema.healthSystemNetworkId.props,
          onFilter: fetchParentOrg,
        },
      },
      permanentlyClosed:{
        ...formSchema.permanentlyClosed,
        props: {
          ...formSchema.permanentlyClosed.props,
          checked:isPermClosed,
          onChange:({ permanentlyClosed }) =>{setIsPermClosed(permanentlyClosed)},        
        },
      }
    };

    const getSaveBodyPostParams = savePostBodyParam => {
      let postParam = savePostBodyParam;
      if (id === 0 && ingestedId) {
        postParam = { ...postParam, prodStatusID: ingestedProductionStatusId };
      }
      const recordInceptionDateInfo = addInceptionDate(recordInceptionDate, inceptionDate);

      return { ...postParam, ...recordInceptionDateInfo };
    };

    const generateArrayPayload = (obj, title) => {
      let response = [];
      for (let j = 0; j < obj.length; j++) {
        let element = {
          id: obj[j].id || 0,
          [title]: obj[j].text || '',
        };
        response.push(element);
      }
      return response;
    };
    return (
      <div className="org-basic-contact-form">
        <FeatureFooter
          handleSaveAndNext={() => saveBasicInfo(data)}
          handleSave={() => saveBasicInfo(null)}
          handleClear={() => resetBasicInfo()}
          disabled={disableEdit}
        />

        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item xs={12}>
            <Formik
              key={id.toString()}
              initialValues={initialValues}
              validate={async values => validate(values)}
              enableReinitialize={true}
              onSubmit={async () => {
                const {
                  data: result,
                  ingestedContentResults,
                  tabData,
                } = updatedBasicAndContactInfoDetails;
                const res = { ...result };
                const synonymsUpd = res.synonyms
                  ? generateArrayPayload(res.synonyms, 'synonym')
                  : [];
                const pClosed = pPostArr(res.permanentlyClosed);
                const resultUpd = { ...result, synonyms: synonymsUpd, permanentlyClosed:pClosed };
                dispatch(
                  postOrganization({
                    data: getSaveBodyPostParams({
                      id,
                      ingestedId,
                      updateType: 1,
                      organizationBasicInfo: resultUpd,
                      ingestedContentResults,
                    }),
                    key: 'organizationBasicInfo',
                    tabData,
                  }),
                );
              }}
            >
              {props => {
                basicInfoRef.current = props;
                return (
                  <FormBuilder
                    formikProps={props}
                    layoutSchema={layoutSchema}
                    formSchema={updatedFormSchema}
                    onSubmitValidationError={() => {}}
                    disableControls={disableEdit}
                  />
                );
              }}
            </Formik>
          </Grid>
        </Grid>
        <div className="org-label org-support-url">
          <InputLabel
            text="SUPPORTING URLs"
            labelFor="SUPPORTING URLS"
            size="16"
            fontStyle="bold"
          />
        </div>
        <div className="org-urls-wrapper">
          <SupportingUrls
            data={organizationBasicInfo.supportingUrls}
            updateData={urls => onUrlChange(urls)}
            disableEdit={disableEdit}
            id={id}
            ref={supportingUrlsFormRef}
            entityName="organization"
          />
        </div>

        <div className="org-label org-record-dates">
          <InputLabel text="RECORD DATES" labelFor="RECORD DATES" size="16" fontStyle="bold" />
        </div>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid xs={12} item>
            <div className="org-timestamp">
              <TimeStamp
                data={{
                  ...organizationBasicInfo,
                  nextReviewDate,
                  lastFullReviewBy: organizationBasicInfo.lastFullReviewedBy,
                }}
                handleDateChange={e => handleNextReviewDateChange(e)}
                disabled={disableEdit || id === 0}
              />
            </div>
          </Grid>
        </Grid>
        {/* {warningMessage && (
          <ModalMessage
            isOpen
            modalType={MODAL_TYPES.WARNING}
            onClose={() => setWarningMessage(null)}
            message="Changes to the Organization name will be displayed in the child records as well"
          />
        )} */}

        {treeView && treeView.length ? (
          <OrganizationTreeView
            key={id.toString()}
            onClose={() => setViewModal(false)}
            isOpen={viewModal}
            orgId={id}
            treeData={treeView}
            onOrgNavigation={treeId => onOrgNavigation(treeId)}
            name={organizationBasicInfo.name}
          />
        ) : null}
        {updateNextReviewConfirmation && (
          <ConfirmationMessage
            isOpen
            onClose={proceedToUpdateNextReviewDate}
            message="Next Review Data has been over written. Are you sure you want to continue?"
          />
        )}
        {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
      </div>
    );
  },
);

BasicAndContactInfo.propTypes = {
  data: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default BasicAndContactInfo;
