/* eslint-disable complexity */
//
// ----roles setup-------
// disableEdit - CRUDL - Read
// disableDelete - CRUDL - Read, write
// disableFeature - feature - No
//

export const roleDefinitions = {
  READ: 1,
  WRITE: 2,
  DELETE: 3,
  YES: 5,
  NO: 4,
};

export const getUserPermissions = ({
  trialPermissions,
  drugPermissions,
  personPermissions,
  companyPermissions,
  orgnizationPermissions,
  adminPermissions,
  registryPermissions,
  requirementPermissions,
  sourceDocPermissions,
  countryPermissions,
}) => {
  // administrator
  let administrator = null;
  if (adminPermissions && adminPermissions.length > 0) {
    adminPermissions.forEach(feature => {
      switch (feature.permission.id) {
        case featureOperations.ADMIN.DATA_SOURCE_CRUDL:
          administrator = {
            ...administrator,
            dataSourceCrudl: feature.roleAction.id,
          };
          break;
        case featureOperations.ADMIN.DATA_SOURCE_GROUP_CRUDL:
          administrator = {
            ...administrator,
            dataSourceGroupCrudl: feature.roleAction.id,
          };
          break;
        case featureOperations.ADMIN.ANCILIARY_CRUDL:
          administrator = {
            ...administrator,
            anciliary: {
              ...administrator.anciliary,
              disableEdit: roleDefinitions.READ === feature.roleAction.id,
              disableDelete:
                roleDefinitions.READ === feature.roleAction.id ||
                roleDefinitions.WRITE === feature.roleAction.id,
            },
          };
          break;
        case featureOperations.ADMIN.ROLES_PERMISSION_CRUDL:
          administrator = {
            ...administrator,
            rolesPermissionCrudl: feature.roleAction.id,
          };
          break;
        default:
          break;
      }
    });
  }

  // trials
  let clinicalTrials = null;
  if (trialPermissions && trialPermissions.length > 0) {
    trialPermissions.forEach(feature => {
      switch (feature.permission.id) {
        case featureOperations.TRAILS.CRUDL:
          clinicalTrials = {
            ...clinicalTrials,
            disableEdit: roleDefinitions.READ === feature.roleAction.id,
            disableDelete:
              roleDefinitions.READ === feature.roleAction.id ||
              roleDefinitions.WRITE === feature.roleAction.id,
          };
          break;
        case featureOperations.TRAILS.IMPORT_CONTACT:
          clinicalTrials = {
            ...clinicalTrials,
            disableFeature: roleDefinitions.NO === feature.roleAction.id,
          };
          break;
        case featureOperations.TRAILS.PREVIEW:
          clinicalTrials = {
            ...clinicalTrials,
            disablePreview: roleDefinitions.NO === feature.roleAction.id,
          };
          break;
        case featureOperations.TRAILS.QUEUE_ADMIN:
          clinicalTrials = {
            ...clinicalTrials,
            isAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.TRAILS.SEARCH_ADMIN:
          clinicalTrials = {
            ...clinicalTrials,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        default:
          break;
      }
    });
  }

  // drugs
  let drugs = null;
  if (drugPermissions && drugPermissions.length > 0) {
    drugPermissions.forEach(feature => {
      switch (feature.permission.id) {
        case featureOperations.DRUGS.CRUDL:
          drugs = {
            ...drugs,
            disableEdit: roleDefinitions.READ === feature.roleAction.id,
            disableDelete:
              roleDefinitions.READ === feature.roleAction.id ||
              roleDefinitions.WRITE === feature.roleAction.id,
          };
          break;
        case featureOperations.DRUGS.PREVIEW:
          drugs = {
            ...drugs,
            disablePreview: roleDefinitions.NO === feature.roleAction.id,
          };
          break;
        case featureOperations.DRUGS.QUEUE_ADMIN:
          drugs = {
            ...drugs,
            isAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.DRUGS.SEARCH_ADMIN:
          drugs = {
            ...drugs,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        default:
          break;
      }
    });
  }

  // person
  let person = null;
  if (personPermissions && personPermissions.length > 0) {
    personPermissions.forEach(feature => {
      switch (feature.permission.id) {
        case featureOperations.PERSON.CRUDL:
          person = {
            ...person,
            disableEdit: roleDefinitions.READ === feature.roleAction.id,
            disableDelete:
              roleDefinitions.READ === feature.roleAction.id ||
              roleDefinitions.WRITE === feature.roleAction.id,
          };
          break;
        case featureOperations.PERSON.PREVIEW:
          person = {
            ...person,
            disablePreview: roleDefinitions.NO === feature.roleAction.id,
          };
          break;
        case featureOperations.PERSON.QUEUE_ADMIN:
          person = {
            ...person,
            isAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.PERSON.SEARCH_ADMIN:
          person = {
            ...person,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        default:
          break;
      }
    });
  }
  // company
  let company = null;
  if (companyPermissions && companyPermissions.length > 0) {
    companyPermissions.forEach(feature => {
      switch (feature.permission.id) {
        case featureOperations.COMPANY.CRUDL:
          company = {
            ...company,
            disableEdit: roleDefinitions.READ === feature.roleAction.id,
            disableDelete:
              roleDefinitions.READ === feature.roleAction.id ||
              roleDefinitions.WRITE === feature.roleAction.id,
          };
          break;
        case featureOperations.COMPANY.PREVIEW:
          company = {
            ...company,
            disablePreview: roleDefinitions.NO === feature.roleAction.id,
          };
          break;
        case featureOperations.COMPANY.QUEUE_ADMIN:
          company = {
            ...company,
            isAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.COMPANY.SEARCH_ADMIN:
          company = {
            ...company,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        default:
          break;
      }
    });
  }
  // organization
  let organization = null;
  if (orgnizationPermissions && orgnizationPermissions.length > 0) {
    orgnizationPermissions.forEach(feature => {
      switch (feature.permission.id) {
        case featureOperations.ORGANIZATION.CRUDL:
          organization = {
            ...organization,
            disableEdit: roleDefinitions.READ === feature.roleAction.id,
            disableDelete:
              roleDefinitions.READ === feature.roleAction.id ||
              roleDefinitions.WRITE === feature.roleAction.id,
          };
          break;
        case featureOperations.ORGANIZATION.PREVIEW:
          organization = {
            ...organization,
            disablePreview: roleDefinitions.NO === feature.roleAction.id,
          };
          break;
        case featureOperations.ORGANIZATION.QUEUE_ADMIN:
          organization = {
            ...organization,
            isAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.ORGANIZATION.SEARCH_ADMIN:
          organization = {
            ...organization,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        default:
          break;
      }
    });
  }
  //REGISTRY
  let registry = null;
  if (registryPermissions && registryPermissions.length > 0) {
    registryPermissions.forEach(feature => {
      switch (feature.permission.id) {
        case featureOperations.REGISTRY.DATA_SOURCE_GROUP_CRUDL:
          registry = {
            ...registry,
            disableEdit: roleDefinitions.READ === feature.roleAction.id,
            disableDelete:
              roleDefinitions.READ === feature.roleAction.id ||
              roleDefinitions.WRITE === feature.roleAction.id,
          };
          break;
        case featureOperations.REGISTRY.PRIVIEW:
          registry = {
            ...registry,
            disablePreview: roleDefinitions.NO === feature.roleAction.id,
          };
          break;
        case featureOperations.REGISTRY.DELETE_RECORD:
          registry = {
            ...registry,
            isAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.REGISTRY.MANAGE_ROLE_PERMISSION:
          registry = {
            ...registry,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.REGISTRY.DATA_SOURCE_GROUP_CRUDL_BUSINESS_RECORD:
          registry = {
            ...registry,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.REGISTRY.EXPORT_DATA:
          registry = {
            ...registry,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        default:
          break;
      }
    });
  }
  //REQUIREMENT
  let requirement = {
    MANAGE_ROLE_PERMISSION: 33,

    DATA_SOURCE_GROUP_CRUDL: 34,

    DELETE_RECORD: 43,

    PRIVIEW: 44,

    PRIVEIW_SENIOR: 45,

    DATA_SOURCE_GROUP_CRUDL_BUSINESS_RECORD: 46,

    EXPORT_DATA: 47,

    SEARCH_ADMIN: 35,
  };
  //console.log('requirementPermissions', requirementPermissions);
  if (requirementPermissions && requirementPermissions.length > 0) {
    requirementPermissions.forEach(feature => {
      switch (feature.permission.id) {
        case featureOperations.REQUIREMENT.DATA_SOURCE_GROUP_CRUDL:
          requirement = {
            ...requirement,
            disableEdit: roleDefinitions.READ === feature.roleAction.id,
            disableDelete:
              roleDefinitions.READ === feature.roleAction.id ||
              roleDefinitions.WRITE === feature.roleAction.id,
          };
          break;
        case featureOperations.REQUIREMENT.PRIVIEW:
          requirement = {
            ...requirement,
            disablePreview: roleDefinitions.NO === feature.roleAction.id,
          };
          break;
        case featureOperations.REQUIREMENT.DELETE_RECORD:
          requirement = {
            ...requirement,
            isAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.REQUIREMENT.MANAGE_ROLE_PERMISSION:
          requirement = {
            ...requirement,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.REQUIREMENT.DATA_SOURCE_GROUP_CRUDL_BUSINESS_RECORD:
          requirement = {
            ...requirement,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.REQUIREMENT.EXPORT_DATA:
          requirement = {
            ...requirement,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        default:
          break;
      }
    });
  }
  //COUNTRY
  let country = null;
  if (countryPermissions && countryPermissions.length > 0) {
    countryPermissions.forEach(feature => {
      // console.log('feature.permission.id', feature.permission.id);
      switch (feature.permission.id) {
        case featureOperations.COUNTRY.DATA_SOURCE_GROUP_CRUDL:
          // console.table(roleDefinitions.READ, roleDefinitions.WRITE, feature.roleAction.id);
          country = {
            ...country,
            disableEdit: roleDefinitions.READ === feature.roleAction.id,
            disableDelete:
              roleDefinitions.READ === feature.roleAction.id ||
              roleDefinitions.WRITE === feature.roleAction.id,
          };
          break;
        case featureOperations.COUNTRY.PRIVIEW:
          country = {
            ...country,
            disablePreview: roleDefinitions.NO === feature.roleAction.id,
          };
          break;
        case featureOperations.COUNTRY.DELETE_RECORD:
          country = {
            ...country,
            isAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.COUNTRY.MANAGE_ROLE_PERMISSION:
          country = {
            ...country,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.COUNTRY.DATA_SOURCE_GROUP_CRUDL_BUSINESS_RECORD:
          country = {
            ...country,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.COUNTRY.EXPORT_DATA:
          country = {
            ...country,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        default:
          break;
      }
    });
  }
  //SOURCEDOCUMENT
  let sourceDocument = null;
  //console.log('sourceDocumentPermissions', sourceDocumentPermissions);
  //console.log('sourceDocumentPermissions', countryPermissions);
  if (sourceDocPermissions && sourceDocPermissions.length > 0) {
    sourceDocPermissions.forEach(feature => {
      switch (feature.permission.id) {
        case featureOperations.SOURCEDOCUMENT.DATA_SOURCE_GROUP_CRUDL:
          sourceDocument = {
            ...sourceDocument,
            disableEdit: roleDefinitions.READ === feature.roleAction.id,
            disableDelete:
              roleDefinitions.READ === feature.roleAction.id ||
              roleDefinitions.WRITE === feature.roleAction.id,
          };
          break;
        case featureOperations.SOURCEDOCUMENT.PRIVIEW:
          sourceDocument = {
            ...sourceDocument,
            disablePreview: roleDefinitions.NO === feature.roleAction.id,
          };
          break;
        case featureOperations.SOURCEDOCUMENT.DELETE_RECORD:
          sourceDocument = {
            ...sourceDocument,
            isAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.SOURCEDOCUMENT.MANAGE_ROLE_PERMISSION:
          sourceDocument = {
            ...sourceDocument,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.SOURCEDOCUMENT.DATA_SOURCE_GROUP_CRUDL_BUSINESS_RECORD:
          sourceDocument = {
            ...sourceDocument,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        case featureOperations.SOURCEDOCUMENT.EXPORT_DATA:
          sourceDocument = {
            ...sourceDocument,
            searchAdmin: roleDefinitions.YES === feature.roleAction.id,
          };
          break;
        default:
          break;
      }
    });
  }
  return {
    drugs,
    clinicalTrials,
    person,
    company,
    organization,
    administrator,
    registry,
    requirement,
    country,
    sourceDocument,
  };
};

export const featureOperations = {
  DRUGS: {
    CRUDL: 1,
    PREVIEW: 16,
    QUEUE_ADMIN: 11,
    SEARCH_ADMIN: 22,
  },
  TRAILS: {
    CRUDL: 2,
    IMPORT_CONTACT: 3,
    PREVIEW: 17,
    QUEUE_ADMIN: 12,
    SEARCH_ADMIN: 23,
  },
  PERSON: {
    CRUDL: 6,
    PREVIEW: 20,
    QUEUE_ADMIN: 15,
    SEARCH_ADMIN: 26,
  },
  COMPANY: {
    CRUDL: 4,
    PREVIEW: 18,
    QUEUE_ADMIN: 13,
    SEARCH_ADMIN: 24,
  },
  ORGANIZATION: {
    CRUDL: 5,
    PREVIEW: 19,
    QUEUE_ADMIN: 14,
    SEARCH_ADMIN: 25,
  },
  ADMIN: {
    DATA_SOURCE_CRUDL: 7,
    DATA_SOURCE_GROUP_CRUDL: 8,
    ANCILIARY_CRUDL: 9,
    ROLES_PERMISSION_CRUDL: 10,
  },
  REGISTRY: {
    MANAGE_ROLE_PERMISSION: 27,
    DATA_SOURCE_GROUP_CRUDL: 28,
    DELETE_RECORD: 29,
    PRIVIEW: 30,
    PRIVEIW_SENIOR: 31,
    DATA_SOURCE_GROUP_CRUDL_BUSINESS_RECORD: 32,
    EXPORT_DATA: 33,
  },
  REQUIREMENT: {
    MANAGE_ROLE_PERMISSION: 33,
    DATA_SOURCE_GROUP_CRUDL: 34,
    DELETE_RECORD: 43,

    PRIVIEW: 44,

    PRIVEIW_SENIOR: 45,

    DATA_SOURCE_GROUP_CRUDL_BUSINESS_RECORD: 46,

    EXPORT_DATA: 47,
    SEARCH_ADMIN: 35,
  },

  SOURCEDOCUMENT: {
    MANAGE_ROLE_PERMISSION: 30,
    DATA_SOURCE_GROUP_CRUDL: 31,
    DELETE_RECORD: 36,
    PRIVIEW: 37,
    PRIVEIW_SENIOR: 38,
    DATA_SOURCE_GROUP_CRUDL_BUSINESS_RECORD: 39,
    EXPORT_DATA: 40,
    SEARCH_ADMIN: 32,
  },
  COUNTRY: {
    MANAGE_ROLE_PERMISSION: 36,
    DATA_SOURCE_GROUP_CRUDL: 37,
    DELETE_RECORD: 50,
    PRIVIEW: 51,
    PRIVEIW_SENIOR: 52,
    DATA_SOURCE_GROUP_CRUDL_BUSINESS_RECORD: 53,
    EXPORT_DATA: 54,
  },
};
