export const heading = 'Clinical Trial feature';
export const screenType = 'clinicalTrial';
export const TIME_POINT_UNIT = {
  SECONDS: 'Second(s)',
  MINUTES: 'Minute(s)',
  HOURS: 'Hour(s)',
  DAYS: 'Day(s)',
  WEEKS: 'Week(s)',
  MONTHS: 'Month(s)',
  YEARS: 'Year(s)',
  OTHER: 'Other',
};

export const UNDATED_NOTE_TYPES = [
  'Extension Study',
  'Extension Study (Main Study)',
  'Enrolling by Invitation only',
  'Full Study Protocol',
];

export const preDefinedDiseaseGenders = [
  { diseaseName: 'Testicular', value: { label: 'Male', value: 'Male' } },
  { diseaseName: 'Prostate', value: { label: 'Male', value: 'Male' } },
  { diseaseName: 'Ovarian', value: { label: 'Female', value: 'Female' } },
];

export const trialStatusConstants = {
  planned: 'planned',
  open: 'open',
  terminated: 'terminated',
  completed: 'completed',
  closed: 'closed',
  temporarilyClosed: 'temporarily closed',
};
export const warningConstants = {
  efficacy: 'efficacy',
  phaseOne: 'I',
};
export const trialSummaryWarningMessages = {
  timing: 'Actual dates conflict with the selected trial status.',
  actualAccrualOpenStatus: 'Actual accrual should not exist for open trial.</br></br>',
  actualAccrualPlannedStatus: 'Actual accrual should not exist for planned trial.</br></br>',
  interimAccrualWarning: 'Interim accrual should not exist for planned trial.</br></br>',
};

export const trialSummaryRoles = [
  {
    id: 1,
    value: 'SPONSOR',
    label: 'SPONSOR',
  },
  {
    id: 2,
    value: 'COLLABORATOR',
    label: 'COLLABORATOR',
  },
];
