const companyFeaturesInitialState = {
  id: 0,
  ingestedId: 0,
  basicAndContactInfo: {
    companyName: '',
    researchFocus: null,
    companyStatus: null,
    ownership: null,
    establishedYear: null,
    parentCompany: null,
    relatedCompanyType: null,
    yearAcquired: null,
    website: '',
    phone: [],
    fax: [],
    companySummary: '',
    street1: '',
    street2: '',
    country: null,
    state: null,
    city: '',
    postalCode: '',
    recordDates: {
      createdDate: null,
      createdBy: '',
      modifiedDate: null,
      modifiedBy: '',
      lastFullReviewDate: null,
      lastFullReviewBy: '',
      nextReviewDate: null,
    },
  },
  additionalInfo: {
    stockSymbol: '',
    stockExchange: null,
    acquiredOrSubsidiaryCompanies: [],
    financialEntires: [],
    supportingUrls: [],
  },
  notes: [],
  prodStatusID: 0,
};

export default companyFeaturesInitialState;
