import { validate as organizationBasicInfo } from '../../screens/organization/features/basicAndContactInfo/config';
import { validate as personBasicInfo } from '../../screens/person/features/basicAndContactInfo/config.js';
import { validateBasicAndContactInfoDetails as companyBasicAndContactInfo } from '../../screens/company/features/basicAndContactInfo/config';
import { validateTrialSummaryDetailsForSubmit } from '../../screens/clinicalTrials/helpers/trialSummary';
import { validate as trialOutcome } from '../../screens/clinicalTrials/features/trialOutcome/config';
import { validatePatientPopulationOnPublish } from '../../screens/clinicalTrials/helpers/patientPopulation';
import {
  validate as trialTiming,
  validateDates as trialTimingDates,
} from '../../screens/clinicalTrials/helpers/trialTimings.js';
import { handleValidations as descriptiveData } from '../../screens/drugs/features/chemicalData/tabs/descriptiveData/config';
import { validate as drugSummary } from '../../screens/drugs/features/drugSummary/config';
import {
  validateDPL,
  validateDPLOriginator,
} from '../../screens/drugs/features/drugProgramLandscapeStatus/config';
import { validateDrugName } from '../../screens/drugs/features/drugName/config';
import { convertObjectForSelectDropdown } from './helper';
import { validate as recordUpdateAndSummary } from '../../screens/country/features/recordUpdateAndSummary/config.js';
import { validate as activityTabValidation } from '../../screens/drugs/features/activity/config';
import { validate as generalInformation } from '../../screens/country/features/generalInformation/config.js';
import { validate as dataEntry } from '../../screens/sourceDocument/features/dataEntry/config.js';
import { validate as sourceDocumentModel } from '../../screens/sourceDocument/features/sourceDocument/config.js';
import { validate as requirmentRecordUpdateModal } from '../../screens/requirement/features/recordUpdateAndSummary/config.js';
import { validate as requirmentClinicalRegulationModal } from '../../framework/clinicalTrialRegulation/config.js';
import { validate as registryRecordUpdateAndSummary } from '../../screens/registry/features/recordUpdateAndSummary/config.js';
import { validate as registryGeneralInformation } from '../../screens/registry/features/registryGeneralInformation/config.js';
import { validate as searchAndStatistics } from '../../framework/searchAndStatistics/config';
import { validate as whoEntersData } from '../../screens/registry/features/registryWhoEntersTheData/config';
import { validate as characteristics } from '../../framework/clinicalTrialCharacteristics/config';
import { validate as protocolRegistration } from '../../framework/protocolRegistration/config';
import { validate as resultsDisclosure } from '../../framework/resultsDisclosure/config';
import { validate as contactInfo } from '../../screens/registry/features/registryContactInformation/config';
import { validate as disclosureRegulationModel } from '../../framework/disclosureRegulationModel/config';
import { validate as discloseRequirement } from '../../screens/requirement/features/requirementWhoEntersTheData/config';
import { validate as clinicalTrialCharacteristicsModel } from '../../framework/clinicalTrialCharacteristicsModel/validhelper';
import { validate as protocolRegistrationRequirement } from '../../framework/protocolRegisrationRequirement/validhelper';
import { validate as resultDisclosureModel } from '../../screens/requirement/features/resultsDisclosureInRegistry/validhelper';
import { validate as enforcement } from '../../screens/requirement/features/enforcement/config';
import { validate as contactInfoModel } from '../../screens/requirement/features/requirementContactInformation/config';
import { validate as ethicsCommittee } from '../../screens/requirement/features/requirementEthics/config';
import { validate as plsProtocol } from '../../screens/requirement/features/requirementPLSPProtocol/config';
import { validate as plsResult } from '../../screens/requirement/features/requirementPLSResult/config';
const validateTabs = {
  clinicalTrial: {
    trialSummary: (trialSummary, original) =>
      validateTrialSummaryDetailsForSubmit(
        {
          ...trialSummary,
          phase: convertObjectForSelectDropdown(trialSummary.phase, 'id', 'value'),
          globalStatus: convertObjectForSelectDropdown(trialSummary.globalStatus, 'id', 'value'),
        },
        original,
      ),
    trialOutcome,
    trialTiming: (timingData, original) => {
      if (original.id) {
        return {
          ...trialTiming(
            timingData,
            {
              setFieldValue: () => null,
              setFieldError: () => null,
              errors: {},
            },
            original.trialSummary.globalStatus.value || '',
            false,
          ),
          ...trialTimingDates(timingData),
        };
      }
    },
    patientPopulation: (patientPopulation, { trialSummary: { globalStatus } }) =>
      validatePatientPopulationOnPublish({ ...patientPopulation, globalStatus }),
  },
  drugs: {
    names: (names, original) => validateDrugName(names, original, false),
    summary: (summary, { masterDrug }) => drugSummary(summary, masterDrug),
    landscapeStatuses: (
      landscapeStatuses,
      { validations: { landscapeStatuses: validationData } },
      errors,
      drugType,
    ) => {
      return drugType.id !== 4 && validationData
        ? validateDPLOriginator(landscapeStatuses, validationData)
        : drugType.id !== 4
        ? validateDPL(landscapeStatuses, validationData)
          ? { dplError: true }
          : {}
        : {};
    },
    chemStructure: (
      chemStructure,
      { activity },
      { molecularData: { chemicalName, smilesFormula } },
    ) => {
      return chemicalName || smilesFormula
        ? { molecularDataError: true }
        : descriptiveData(activity, chemStructure);
    },
    activity: (data, currentData) => {
      if (currentData.drugType.value == 'Trialtrove Only Drug') {
        return activityTabValidation(data, {
          ...currentData,
          drugDrugTypeClassifications: data.drugDrugTypeClassifications,
        });
      }
      return activityTabValidation(data, { ...currentData });
    },
    // child tab validations seperetely
    descriptiveData: (chemStructure, { activity }, errors, drugType) => {
      if (drugType?.id !== 4) {
        return descriptiveData(chemStructure, activity);
      }
    },
    molecularData: (_current, _original, { chemicalName, smilesFormula }) => {
      return chemicalName || smilesFormula ? { molecularDataError: true } : {};
    },
  },
  company: {
    basicAndContactInfo: ({ recordDates: { nextReviewDate }, ...values }) =>
      companyBasicAndContactInfo(values, nextReviewDate),
  },
  person: {
    personBasicInfo,
  },
  organization: {
    organizationBasicInfo,
  },
  country: {
    recordUpdateAndSummary,
    generalInformation,
  },
  sourceDocuments: {
    dataEntry,
    sourceDocumentModel,
  },
  requirement: {
    recordUpdateAndSummary: requirmentRecordUpdateModal,
    clinicalTrialRegulation: requirmentClinicalRegulationModal,
    disclosureRegulationModel,
    discloseRequirement,
    clinicalTrialCharacteristicsModel,
    protocolRegistrationRequirement,
    resultDisclosureModel,
    enforcement,
    contactInfoModel,
    ethicsCommittee,
    plsProtocol,
    plsResult,
  },
  registry: {
    recordUpdateAndSummary: registryRecordUpdateAndSummary,
    generalInformation: registryGeneralInformation,
    searchAndStatistics,
    whoEntersData,
    characteristics,
    protocolRegistration,
    resultsDisclosure,
    contactInfo,
  },
  // return true if there is error
  reducer: {
    chemStructure: (
      chemStructure,
      { activity },
      { molecularData: { chemicalName, smilesFormula } },
      childKey,
      // eslint-disable-next-line max-params
    ) => {
      switch (childKey) {
        case 'molecularData':
          return Boolean(descriptiveData(activity, chemStructure));
        case 'patents':
          return Boolean(descriptiveData(activity, chemStructure)) || chemicalName || smilesFormula;
        default:
          return chemicalName || smilesFormula;
      }
    },
  },
};
export default validateTabs;
