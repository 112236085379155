import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { TEXT_LIMIT } from '../../../../utils/generic/constants';

export const validate = (values, isValidate) => {
  const errors = {};
  const commentsOnInspection = values.commentsOnInspection?.replace(/(\r\n|\n|\r)/gm, '').trim();
  const commentsOnPenalties = values.commentsOnPenalties?.replace(/(\r\n|\n|\r)/gm, '').trim();
  const enforcementInternalComments = values.enforcementInternalComments
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  if (
    values.enforcementInternalComments?.trim() &&
    enforcementInternalComments.length > TEXT_LIMIT[7000]
  ) {
    errors.enforcementInternalComments = 'Please enter only 7000 characters';
  }
  if (values.commentsOnInspection?.trim() && commentsOnInspection.length > TEXT_LIMIT[7000]) {
    errors.commentsOnInspection = 'Please enter only 7000 characters';
  }
  if (values.commentsOnPenalties?.trim() && commentsOnPenalties.length > TEXT_LIMIT[7000]) {
    errors.commentsOnPenalties = 'Please enter only 7000 characters';
  }
  if (isValidate && values.isMandatory) {
    if (values.isAuditOrInspection === null) {
      errors.isAuditOrInspection = 'Please select inspection/audit';
    }
    if ([true, 1].includes(values.isAuditOrInspection) && commentsOnInspection === '') {
      errors.commentsOnInspection = 'Please enter description';
    }
    if (values.isPenaltiesNonComplaint === null) {
      errors.isPenaltiesNonComplaint = 'Please select non compliance';
    }
    if ([true, 1].includes(values.isPenaltiesNonComplaint) && commentsOnPenalties === '') {
      errors.commentsOnPenalties = 'Please enter description';
    }
  }
  return errors;
};

export const initialValues = {
  isAuditOrInspection: '',
  commentsOnInspection: '',
  isPenaltiesNonComplaint: '',
  commentsOnPenalties: '',
  enforcementInternalComments: '',
};

export const layoutSchema = {
  layout: 'row',
  className: 'record-update-container',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: 'reason-for-update',
      spacing: 3,
      type: fieldTypes.select,
      options: [],
      props: {
        height: 'ht-lg',
      },
      content: [
        {
          layout: 'isAuditOrInspection',
          size: 4,
          className: '',
        },
        {
          layout: 'commentsOnInspection',
          size: 8,
          className: '',
        },
        {
          layout: 'isPenaltiesNonComplaint',
          size: 4,
          className: '',
        },
        {
          layout: 'commentsOnPenalties',
          size: 8,
          className: '',
        },
        {
          layout: 'enforcementInternalComments',
          size: 12,
        },
      ],
    },
  ],
};

export const formSchema = {
  isAuditOrInspection: {
    name: 'isAuditOrInspection',
    label: 'Inspections/audits',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: true,
    },
  },
  commentsOnInspection: {
    name: 'commentsOnInspection',
    type: fieldTypes.textArea,
    label: 'If yes, describe here',
    props: {
      rowsMin: 10,
      rowsMax: 2.3,
      cols: 150,
      isMandatory: true,
    },
  },
  isPenaltiesNonComplaint: {
    name: 'isPenaltiesNonComplaint',
    label: 'Enforcement & Penalties for non-compliance',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: true,
    },
  },
  commentsOnPenalties: {
    name: 'commentsOnPenalties',
    type: fieldTypes.textArea,
    label: 'If yes, describe here',
    props: {
      rowsMin: 10,
      rowsMax: 2.3,
      cols: 150,
      isMandatory: true,
    },
  },
  enforcementInternalComments: {
    name: 'enforcementInternalComments',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 10,
      rowsMax: 5,
      cols: 150,
    },
  },
};
