import { isEmpty } from 'lodash';

import { fieldTypes } from '../../../../../framework/formBuilder/utils';
import {
  getClassNameForIngestion,
  checkForIngestionAction,
} from '../../../../../utils/generic/helper';

export const initialValues = {
  acquiredOrSubsidiaryCompanyName: null,
  companyType: 0,
  yearAcquired: null,
};

export const layoutSchema = {
  layout: 'row',
  className: 'company-form mt-30 mb-40',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'acquiredOrSubsidiaryCompanyName',
      size: 4,
      className: '',
    },
    {
      layout: 'companyType',
      size: 2,
      className: '',
    },
    {
      layout: 'yearAcquired',
      size: 2,
      className: 'year-aq',
    },
    {
      layout: 'button',
      size: 1,
      className: ' ',
    },
  ],
};

export const formSchema = searchAcquiredOrSubsidiaryCompanyName => ({
  acquiredOrSubsidiaryCompanyName: {
    name: 'acquiredOrSubsidiaryCompanyName',
    type: fieldTypes.select_async,
    label: 'ACQUIRED / SUBSIDIARY COMPANY NAME',
    options: [],
    props: {
      onFilter: searchAcquiredOrSubsidiaryCompanyName,
      isSearchable: true,
      blurInputOnSelect: true,
      isClearable: true,
    },
  },
  yearAcquired: {
    name: 'yearAcquired',
    type: fieldTypes.date,
    label: 'YEAR ACQUIRED',
    options: [],
    props: {
      views: ['year'],
      format: 'yyyy',
      clearable: true,
      disableFuture: true,
      minDate: new Date('1200-01-01'),
      maxDate: new Date(),
    },
  },
  button: {
    name: 'button',
    type: fieldTypes.button,
    label: '+ ADD',
  },
});

const companyTypeFormatter = (params, relatedCompanyTypes) => {
  if (params && params.data) {
    const companyTypeData = relatedCompanyTypes.find(c => c.id === params.data.companyType);
    return companyTypeData ? companyTypeData.value : '';
  }
};

export const columnDefs = ({
  relatedCompanyTypes,
  onClick,
  handleDelete,
  handleEdit,
  handleIngestionActions,
  disableEdit,
  acquiredOrSubsidiaryCompanies,
}) => [
  {
    headerName: 'ACQUIRED / SUBSIDIARY COMPANY NAME',
    field: 'acquiredOrSubsidiaryCompanyName.value',
    width: 400,
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    sortable: true,
    cellRenderer: 'link',
    cellRendererParams: ({ data }) => {
      const { value = '' } = { ...data.acquiredOrSubsidiaryCompanyName };
      return {
        link: value,
        onClick: () => onClick(data.acquiredOrSubsidiaryCompanyName),
        customTextToolTip:
          (data &&
            data.acquiredOrSubsidiaryCompanyName &&
            data.acquiredOrSubsidiaryCompanyName.value) ||
          '',
      };
    },
  },
  {
    headerName: 'COMPANY TYPE',
    field: 'companyType.value',
    width: 200,
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    sortable: true,
    valueFormatter: params => companyTypeFormatter(params, relatedCompanyTypes),
  },
  {
    headerName: 'YEAR ACQUIRED',
    field: 'yearAcquired',
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    sortable: true,
  },
  {
    headerName: '',
    minWidth: 50,
    maxWidth: 50,
    field: '',
    cellRenderer: 'actions',
    cellRendererParams: params => {
      const { data } = params;
      return {
        handleEdit: () => {
          handleEdit(data);
        },
        canDisableEdit: disableEdit || data.isDisabled,
      };
    },
  },
  {
    headerName: '',
    minWidth: 50,
    maxWidth: 50,
    field: '',
    cellRenderer: 'actions',
    cellRendererParams: params => {
      const { data } = params;
      return {
        handleDelete: () => {
          handleDelete(data);
        },
        canDisableDelete: disableEdit || data.isDisabled,
      };
    },
  },
  {
    headerName: '',
    minWidth: 100,
    maxWidth: 100,
    field: '',
    hide: !checkForIngestionAction(acquiredOrSubsidiaryCompanies, true),
    cellRenderer: 'ingestionActions',
    cellRendererParams: ({ data: rowData }) => {
      return {
        ingestionAction: rowData ? rowData.ingestionAction : 'none',
        isDisabled: disableEdit,
        handleIngestionActions: option => handleIngestionActions(option, rowData),
      };
    },
  },
];

export const validateAcquiredOrSubsidiaryCompanyDetails = values => {
  const { acquiredOrSubsidiaryCompanyName, companyType } = values;
  let errors = {};
  if (isEmpty(acquiredOrSubsidiaryCompanyName)) {
    errors = {
      ...errors,
      acquiredOrSubsidiaryCompanyName:
        'Please provide details for the acquired/subsidiary company name',
    };
  }

  if (companyType <= 0) {
    errors = {
      ...errors,
      companyType: 'Please provide details for the company type',
    };
  }
  return errors;
};
