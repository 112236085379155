/* eslint-disable new-cap */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  faBell,
  faChevronDown,
  faCog,
  faSignOutAlt,
  faRandom,
} from '@fortawesome/free-solid-svg-icons';
import Logo from '../../../assets/images/logonew.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { authProvider } from '../../../auth/authProvider';
import { dashboards } from './routerParams';
import { ConfirmationMessage } from '../../../generic/modals';

const Header = ({ dashboard, user }) => {
  const highlightDashboard = name => {
    if (dashboard === name) {
      return true;
    } else {
      return false;
    }
  };

  const [confirmStatus, setConfirmStatus] = React.useState(false);
  const [confirmSwitchStatus, setSwtichConfirmStatus] = React.useState(false);
  let userGroup = localStorage.getItem('usergroup');
  let userGroupInfo = localStorage.getItem('usergroupinfo');

  const [gearMenu, setGearMenu] = React.useState(null);
  const handleGearClick = event => {
    setGearMenu(event.currentTarget);
  };

  const handleGearClose = () => {
    setGearMenu(null);
  };

  function SimpleMenu(loggedinuser) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    let switchItem = '';
    if (userGroupInfo > 1 && loggedinuser) {
      switchItem = (
        <MenuItem
          onClick={() => {
            setSwtichConfirmStatus(true);
            handleClose();
          }}
        >
          <FontAwesomeIcon icon={faRandom} style={{ marginRight: '10px' }} color="#d1497c" />
          {userGroup === 'RegIntelUser' ? 'SWITCH TO CLINICAL' : 'SWITCH TO DISCLOSE'}
        </MenuItem>
      );
    }

    return (
      <div>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <Tooltip title={user && user.name}>
            <div className="userNameTxt">
              <span className="userNameEllipsis">{user && user.name}</span>
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ marginLeft: '5px;', marginBottom: '1vh' }}
              />
            </div>
          </Tooltip>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ marginTop: '40px' }}
        >
          {switchItem}

          <MenuItem
            onClick={() => {
              setConfirmStatus(true);
              handleClose();
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '10px' }} color="#d1497c" />
            LOG OUT
          </MenuItem>
        </Menu>
      </div>
    );
  }

  const proceedToLogOut = status => {
    if (status) {
      localStorage.removeItem('usergroup');
      localStorage.removeItem('usergroupinfo');
      authProvider.logout();
    }
    setConfirmStatus(false);
  };
  const proceedToSwitch = status => {
    if (status) {
      let switchToGroup = userGroup == 'RegIntelUser' ? 'StarUser' : 'RegIntelUser';
      localStorage.setItem('usergroup', switchToGroup);
      window.location.href = '/';
    }
    setSwtichConfirmStatus(false);
  };
  const getMenuLinks = () => {
    let menuItems = [];
    if (userGroup === 'RegIntelUser') {
      menuItems['RegIntelUser'] = [
        { name: 'Dashboard', link: 'dashboard' },
        // { name: 'Administrator', link: 'administrator' },
        { name: 'Registry', link: 'registry' },
        { name: 'Source Documents', link: 'sourceDocument' },
        { name: 'Country', link: 'country' },
        { name: 'Requirement', link: 'requirement' },
        { name: 'Ancillary Data', link: 'ancillaryData/sourceName' },
      ];
    } else {
      menuItems['StarUser'] = [
        // { name: 'Administrator', link: 'administrator' },
        { name: 'Clinical Trials', link: 'clinicalTrials' },
        { name: 'Drugs', link: 'drugs' },
        { name: 'Company', link: 'company' },
        { name: 'Person', link: 'person' },
        { name: 'Organization', link: 'organization' },
        // { name: 'Sponsors', link: 'sponsors' },
        { name: 'Ancillary Data', link: 'ancillaryData/armType' },
        // { name: 'Task', link: 'task' },
        { name: 'Ancillary Request', link: 'addAncillaryData' },
        { name: 'Reporting', link: 'reporting' },
      ];
    }
    return menuItems[userGroup].map((item, index) => (
      <Link
        className={highlightDashboard(item.link) ? 'active link' : 'link'}
        to={`/${item.link}`}
        key={`${index}${item.link}`}
      >
        {item.name}
      </Link>
    ));
  };
  const generateLinks = () => {
    if (userGroup) {
      //console.log('Generate links', userGroup, dashboards);
      return getMenuLinks();
    } else {
      return '';
    }
  };
  return (
    <>
      <div className="header">
        <div className="headerLeft">
          <div className="logo">
            <a href="">
              <img src={Logo}></img>
            </a>
          </div>
          <div className="header-child">
            <div className="links">{generateLinks()}</div>
          </div>
        </div>
        <div className="headerRight">
          <Tooltip title="Alerts">
            <div className="userNotificationIcon">
              <FontAwesomeIcon icon={faBell} />
            </div>
          </Tooltip>
          <Tooltip title="Account Settings">
            <div className="userSettingsIcon">
              <FontAwesomeIcon icon={faCog} onClick={handleGearClick} />
            </div>
          </Tooltip>
          <Menu
            id="simple-menu"
            anchorEl={gearMenu}
            keepMounted
            open={Boolean(gearMenu)}
            onClose={handleGearClose}
            style={{ marginTop: '40px' }}
          >
            <MenuItem
              onClick={() => {
                handleGearClose();
              }}
            >
              <Link className="gearLink" to={`/administrator`}>
                ADMIN
              </Link>
            </MenuItem>
          </Menu>
          {/* <div className="userNameTxt">{user && user.name}</div> */}
          {SimpleMenu(user)}
          {confirmSwitchStatus && (
            <ConfirmationMessage
              isOpen
              onClose={proceedToSwitch}
              message="Are you sure you want to continue?"
            />
          )}
          {confirmStatus && (
            <ConfirmationMessage
              isOpen
              onClose={proceedToLogOut}
              message="You are logging out of the application. Are you sure you want to continue?"
            />
          )}
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  dashboard: PropTypes.string,
  user: PropTypes.object,
};

export default Header;