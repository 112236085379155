import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import IngestionActions from '../../ingestionActions';
import InputLabel from '../InputLabel';
import InputToolTip from '../../toolTip/inputToolTip/index';
import InputAddSubIcons from '../InputAddIcons';
import './index.scss';

const InputTextField = ({
  label,
  labelSuffix,
  labelSize,
  isMandatory,
  inputHeight,
  showToolTip,
  ingestedData,
  ingestionAction,
  handleIngestionActions,
  isIngestionActionDisabled,
  onBlurDisable,
  noOfFields,
  showIcons,
  addDelEntryRow,
  getAllMultiInputData,
  showInputErrors,
  resetInputs,
  updateResetInputs,
  name,
  validateData,
  multInputValues,
  disabled,
  ...inputProps
}) => {
  const className = `width-100 ${inputHeight}`;
  const [textInputs, setTextInputs] = useState([]);
  const addTextInput = () => {
    setTextInputs([...textInputs, { name: `${name}${textInputs.length + 1}`, value: '' }]);
  };
  const onInputValueChange = (val, id) => {
    const textInputsCopy = JSON.parse(JSON.stringify(textInputs));
    textInputsCopy.forEach((item, index) => {
      if (item.name == id) {
        item.value = val;
      }
    });
    setTextInputs(textInputsCopy);
    getAllMultiInputData(textInputsCopy);
  };

  const callToAddInputs = () => {
    if (textInputs?.length < 9) {
      addTextInput();
    }
    addDelEntryRow(textInputs?.length);
  };

  const removeTextInput = name => {
    let textInputsCopy = JSON.parse(JSON.stringify(textInputs));
    textInputsCopy = textInputsCopy.filter(el => el.name != name);
    setTextInputs(textInputsCopy);
    getAllMultiInputData(textInputsCopy);
  };

  useEffect(() => {
    if (resetInputs) {
      textInputs.length > 0 && setTextInputs([]);
      updateResetInputs();
    }
  }, [resetInputs]);

  useEffect(() => {
    if (multInputValues.length > 0) {
      const multiInputValueArr = multInputValues.map((x, i) => {
        return { name: `${name}${i + 1}`, value: x };
      });
      setTextInputs(multiInputValueArr);
      getAllMultiInputData(multiInputValueArr);
    } else {
      setTextInputs([]);
      getAllMultiInputData([]);
    }
  }, [multInputValues]);

  const showErrors = element => {
    if (element?.value) {
      const error = validateData(element.value);
      if (showInputErrors && error) {
        return <p className="error-text multiInput-er">{error}</p>;
      }
    }
  };
  return (
    <div className="text-field">
      {label && (
        <InputLabel
          labelFor="inputLabel"
          text={label}
          textSuffix={labelSuffix}
          size={labelSize}
          isMandatory={isMandatory}
        />
      )}
      <div className={showIcons && 'inputWithIcon mb-15'}>
        <InputToolTip showToolTip={showToolTip}>
          <TextField
            className={className}
            {...inputProps}
            onBlur={
              onBlurDisable
                ? inputProps.onBlur
                : () => {
                    inputProps.onChange({ target: { value: inputProps.value.trim() } });
                  }
            }
            disabled={disabled}
          />
        </InputToolTip>
        {showIcons && (
          <InputAddSubIcons
            showInputs={true}
            showMinus={false}
            addTextInput={addTextInput}
            callToAddInputs={callToAddInputs}
            disabled={disabled}
          />
        )}
      </div>
      <IngestionActions
        className="pt-10"
        isDisabled={isIngestionActionDisabled}
        ingestionAction={ingestionAction}
        handleIngestionActions={handleIngestionActions}
      >
        {ingestedData && <p className="input-ingested-text">{ingestedData}</p>}
      </IngestionActions>

      {textInputs.map((element, index) => {
        return (
          <>
            <div key={index} className={showIcons && 'inputWithIcon mb-15'}>
              <InputToolTip showToolTip={showToolTip}>
                <TextField
                  key={element.id}
                  name={element.name}
                  className={className}
                  value={element.value}
                  onChange={e => {
                    onInputValueChange(e.target.value, element.name);
                  }}
                  variant="outlined"
                  disabled={disabled}
                />
              </InputToolTip>

              {showIcons && (
                <InputAddSubIcons
                  index={index + 1}
                  showInputs={true}
                  addTextInput={addTextInput}
                  callToAddInputs={callToAddInputs}
                  removeTextInput={removeTextInput}
                  name={element.name}
                  disabled={disabled}
                  //className="childItem2"
                />
              )}
            </div>
            {showIcons && showErrors(element)}
          </>
        );
      })}
    </div>
  );
};

InputTextField.propTypes = {
  label: PropTypes.string,
  labelSuffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  variant: PropTypes.string,
  size: PropTypes.string,
  labelSize: PropTypes.string,
  isMandatory: PropTypes.bool,
  inputHeight: PropTypes.oneOf(['ht-lg', 'ht-md']),
  showToolTip: PropTypes.bool,
  ingestionAction: PropTypes.string,
  ingestedData: PropTypes.string,
  handleIngestionActions: PropTypes.func,
  isIngestionActionDisabled: PropTypes.bool,
  onBlurDisable: PropTypes.bool,
  noOfFields: PropTypes.number,
  showIcons: PropTypes.bool,
  addDelEntryRow: PropTypes.func,
  showInputErrors: PropTypes.any,
  resetInputs: PropTypes.bool,
  updateResetInputs: PropTypes.func,
  multInputValues: PropTypes.array,
  getAllMultiInputData: PropTypes.func,
};
InputTextField.defaultProps = {
  variant: 'outlined',
  size: 'small',
  labelSize: '16',
  onChange: () => {},
  isMandatory: false,
  inputHeight: 'ht-md',
  showToolTip: true,
  ingestionAction: 'none',
  ingestedData: '',
  handleIngestionActions: () => {},
  isIngestionActionDisabled: false,
  onBlurDisable: false,
  noOfFields: 1,
  showIcons: false,
  addDelEntryRow: () => {},
  showInputErrors: '',
  resetInputs: false,
  updateResetInputs: () => {},
  validateData: () => {},
  multInputValues: [],
  getAllMultiInputData: () => {},
};
export default InputTextField;
