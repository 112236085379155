import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';

import { getClassNameForIngestion } from '../../../utils/generic/helper';
import InputTextField from '../InputTextField';
import IngestionActions from '../../ingestionActions';
import './index.scss';

const InputEntries = forwardRef(
  (
    {
      name,
      entries,
      label,
      isMandatory,
      size,
      placeholder,
      valueCheck,
      onEntry,
      error,
      helperText,
      height,
      validate,
      errorMessages,
      max,
      disabled,
      isIngestionActionDisabled,
      handleIngestionActions,
      countryCode,
      nddCode,
      countryNddFlag,
    },
    ref,
  ) => {
    const [localEntries, setLocalEntries] = useState(entries ? [...entries] : []);
    const [input, setInput] = useState('');
    const [isEmpty, setIsEmpty] = useState(false);
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [validateError, setValidateError] = useState(false);
    const [isMaxLength, setIsMaxLength] = useState(false);

    useEffect(() => {
      setCountryAndNddCode();
    }, [countryCode, nddCode]);

    const setCountryAndNddCode = () => {
      if (countryCode || nddCode) {
        let nddCodeTemp = nddCode ? `(${nddCode}) ` : '';
        let countryCodeTemp = countryCode ? `+${countryCode} ` : '';
        setInput(`${countryCodeTemp}${nddCodeTemp}`);
      } else if (countryNddFlag) {
        setInput('');
      }
    };

    useEffect(() => setLocalEntries(entries ? [...entries] : []), [entries]);
    const addEntry = () => {
      const validateStatus = validate && validate.rule.test(input);
      if (input && input.trim()) {
        let duplicate =
          localEntries.filter(l => l.text.trim().toLowerCase() === input.trim().toLowerCase())
            .length > 0 || input === valueCheck.trim().toLowerCase();
        if (duplicate) {
          setIsDuplicate(true);
        } else if (max > 0 && input.trim().length > max) {
          setIsMaxLength(true);
        } else if (validate && !validateStatus) {
          setValidateError(true);
        } else {
          let updated = [...localEntries];
          updated.push({ text: input });
          setLocalEntries(updated);
          onEntry(updated);
          if (countryCode || nddCode) setCountryAndNddCode();
          else setInput('');
        }
      } else {
        setIsEmpty(true);
      }
    };

    useImperativeHandle(ref, () => ({
      isChanged: () => (countryNddFlag ? false : input !== ''),
      reset: () => {
        setInput('');
        setIsEmpty(false);
        setIsDuplicate(false);
        setValidateError(false);
        setIsMaxLength(false);
      },
    }));

    const deleteEntry = index => {
      let updated = [...localEntries];
      updated.splice(index, 1);
      setLocalEntries(updated);
      onEntry(updated);
    };

    const generateHelperText = () => {
      let errorText = '';
      if (error) {
        errorText = helperText;
      } else if (isEmpty) {
        errorText = errorMessages.empty;
      } else if (isDuplicate) {
        errorText = errorMessages.duplicate;
      } else if (isMaxLength) {
        errorText = errorMessages.maxLength;
      } else if (validateError) {
        errorText = validate.message;
      }
      return errorText;
    };
    return (
      <div name={name} className="input-entries">
        <div className="input-entries-text-field">
          <InputTextField
            value={input}
            onChange={e => {
              setInput(e.target.value);
              setIsEmpty(false);
              setIsDuplicate(false);
              setValidateError(false);
              setIsMaxLength(false);
            }}
            label={label}
            isMandatory={isMandatory}
            labelSize={size}
            placeholder={placeholder}
            error={error || isEmpty || isDuplicate || validateError || isMaxLength}
            helperText={generateHelperText()}
            inputHeight={height}
            disabled={disabled}
          />
          <button
            disabled={disabled}
            type="button"
            onClick={() => {
              addEntry();
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </button>
        </div>

        <div className="input-entries-data">
          {localEntries.length > 0 &&
            localEntries.map((entry, index) => {
              return (
                <IngestionActions
                  isDisabled={isIngestionActionDisabled}
                  ingestionAction={entry.ingestionAction}
                  handleIngestionActions={action => handleIngestionActions(action, entry)}
                  key={index.toString()}
                >
                  <div className="selected-data">
                    <Tooltip title={entry.text}>
                      <span
                        className={`selected-value ${getClassNameForIngestion(
                          entry.ingestionAction,
                        )}`}
                      >
                        {entry.text}
                      </span>
                    </Tooltip>
                    <button
                      type="button"
                      disabled={disabled || entry.isDisabled}
                      onClick={() => deleteEntry(index)}
                    >
                      <FontAwesomeIcon icon={faTimes} className="close-icon" />
                    </button>
                  </div>
                </IngestionActions>
              );
            })}
        </div>
      </div>
    );
  },
);

InputEntries.displayName = 'InputEntries';

InputEntries.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isMandatory: PropTypes.bool,
  size: PropTypes.string,
  entries: PropTypes.array,
  valueCheck: PropTypes.string,
  onEntry: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  height: PropTypes.string,
  validate: PropTypes.shape({ rule: PropTypes.any, message: PropTypes.string }),
  errorMessages: PropTypes.object,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  isIngestionActionDisabled: PropTypes.bool,
  handleIngestionActions: PropTypes.func,
  countryCode: PropTypes.string,
  nddCode: PropTypes.string,
  countryNddFlag: PropTypes.bool,
};

InputEntries.defaultProps = {
  label: '',
  placeholder: '',
  isMandatory: false,
  size: '16',
  valueCheck: '',
  height: 'ht-md',
  error: false,
  helperText: '',
  validate: null,
  errorMessages: {
    duplicate: 'Duplicate entry is not allowed',
    empty: 'Entry cannot be empty',
  },
  max: 0,
  disabled: false,
  isIngestionActionDisabled: false,
  handleIngestionActions: () => {},
  countryCode: '',
  nddCode: '',
  countryNddFlag: false,
};

export default InputEntries;
