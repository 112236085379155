import React from 'react';
import { Alert } from '../../../framework';
import './index.scss';
import LoadingPage from '../loadingPage';

const UnauthorizedPage = ({ title, isAuthenticating }) => {
  return (
    <div className="unauthorized-wrapper">
      {!isAuthenticating ? (
        <Alert
          severity="warning"
          isDialog={false}
          message={`You do not have permission to access this ${title}`}
          title="Permission Denied!"
        />
      ) : (
        <LoadingPage />
      )}
    </div>
  );
};

export default UnauthorizedPage;
