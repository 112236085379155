import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const InputLabel = ({ labelFor, text, textSuffix, size, fontStyle, padding, isMandatory }) => {
  const classNames = `font-size-${size} font-style-${fontStyle} padding-${padding}`;
  const requiredClass = isMandatory ? 'required-mark' : '';
  return (
    <div className="label-wrapper mb-10">
      <label className={`input-label ${classNames} ${requiredClass}`} htmlFor={labelFor}>
        {text}
      </label>
      <label className="label-suffix" htmlFor={labelFor}>
        {textSuffix}
      </label>
    </div>
  );
};

InputLabel.propTypes = {
  labelFor: PropTypes.string,
  text: PropTypes.string.isRequired,
  textSuffix: PropTypes.string,
  size: PropTypes.string,
  fontStyle: PropTypes.oneOf(['regular', 'bold', 'italics']),
  padding: PropTypes.string,
  isMandatory: PropTypes.bool,
};

InputLabel.defaultProps = {
  labelFor: '',
  size: '16',
  padding: '',
  isMandatory: false,
  textSuffix: '',
};

export default InputLabel;
