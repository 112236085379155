import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faBell, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';

import {
  treeViewConstants,
  bellIconSideBar,
  triangleIconSideBar,
} from '../../utils/generic/constants';
import './index.scss';

const useTreeItemStyles = makeStyles(theme => {
  return {
    root: {
      flexGrow: 1,
      minWidth: 500,
      maxWidth: 800,
    },
    content: {
      margin: 0,
      padding: 0,
      fontWeight: 'inherit',
      '$expanded > &': {
        fontWeight: 'inherit',
      },
      borderBottom: '1px solid #c3c7cf',
    },
    group: {
      '& $content': {},
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: '#CE5182',
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    iconContainer: {
      color: '#484A59',
      fontWeight: 'inherit',
    },
    labelSelected: {
      width: 'max-content',
      backgroundColor: '#F6F09B',
    },
    labelRoot: {},
  };
});

const useQueueTreeStyles = makeStyles(theme => {
  return {
    root: {
      flexGrow: 1,
      minWidth: 380,
      maxWidth: 450,
      backgroundColor: '#F0F1F3',
    },
    content: {
      margin: 0,
      padding: 0,
      fontWeight: 'inherit',
      '$expanded > &': {
        fontWeight: 'inherit',
      },
      borderBottom: '1px solid #c3c7cf',
      backgroundColor: '#F0F1F3',
    },
    group: {
      '& $content': {},
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: '#484A59',
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    iconContainer: {
      color: '#484A59',
      fontWeight: 'inherit',
    },
    labelSelected: {
      width: 'max-content',
      color: '#FFFFFF',
    },
    labelRoot: {
      fontWeight: 'inherit',
      color: '#FFFFFF',
    },
    contentSelected: {
      backgroundColor: '#5F4893 !important',
    },
    rootContent: {
      backgroundColor: '#484A59 !important',
    },
    rootIconContainer: {
      color: '#FFFFFF',
      fontWeight: 'inherit',
    },
  };
});

const renderSideBarIcon = nodeName => {
  const isTriangleIcon = triangleIconSideBar.includes(nodeName.toLowerCase().trim());
  const isBellIcon = bellIconSideBar.includes(nodeName.toLowerCase().trim());
  if (isTriangleIcon) {
    return (
      <span className="sidebar-icon">
        <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>
      </span>
    );
  }
  if (isBellIcon) {
    return (
      <span className="sidebar-icon">
        <FontAwesomeIcon icon={faBell}></FontAwesomeIcon>
      </span>
    );
  }
  return null;
};

const RecursiveTreeView = ({
  viewType,
  treeList,
  defaultId,
  handleQueueClick,
  type,
  parentId,
  toggleSideBar,
  isSideBarClose,
  queueExpandedList,
  queueTypeSelected,
  handleTreeNodeClick,
  handlePinToggle,
  isSidePinEnable,
}) => {
  const sidebarStyles = useQueueTreeStyles();
  const treeStyles = useTreeItemStyles();
  const [expanded, updateExpanded] = useState([]);
  const [selectedSideContent, setSelectedSideContent] = useState(null);
  let idList = [];

  useEffect(() => {
    if (type === treeViewConstants.sideBar && expanded.length === 0 && queueExpandedList.length) {
      updateExpanded(queueExpandedList);
    }
  }, []);

  useEffect(() => {
    if (queueTypeSelected && queueTypeSelected.type === 1) {
      setSelectedSideContent({
        id: queueTypeSelected.id,
        rId: queueTypeSelected.rootKey,
        pId: queueTypeSelected.parentId,
      });
    } else if (queueTypeSelected && queueTypeSelected.type === 2) {
      updateExpanded([]);
      setSelectedSideContent(null);
    }
  }, [queueTypeSelected]);

  const renderSideBarDefaultRow = () => {
    return (
      <div className="default-container addPinIcon">
        <div className='pinAndArrow'>
          <Tooltip title={isSidePinEnable ? 'Un-pin' : 'Pin'}>
            <span className={`showPinIcon ${isSidePinEnable ? 'pinDeactivate' : 'pinActive'}`} onClick={() => handlePinToggle(!isSidePinEnable)}></span>
          </Tooltip>
          <Tooltip title="Collapse Tab">
            <div className="toggle-collapse" onClick={() => toggleSideBar(false)}></div>
          </Tooltip>
        </div>
        <div className='overDuetat'>
          <div className="default-triangle">
            <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>
            <span>Overdue & Backlog</span>
          </div>
          <div className="default-bell">
            <FontAwesomeIcon icon={faBell}></FontAwesomeIcon>
            <span>{`{ TAT < 24 hrs }`}</span>
          </div>
        </div>
      </div>
    );
  };

  const getAllIds = treeData => {
    treeData.forEach(node => {
      idList.push(node.id.toString());
      if (Array.isArray(node.childNodes) && node.childNodes.length) {
        getAllIds(node.childNodes);
      }
    });
  };

  getAllIds(treeList);

  idList.splice(idList.indexOf(defaultId.toString()) + 1);

  const getRoot = nodeObj => {
    const { id, rootKey: rId, parentId: pId } = nodeObj;
    if (type === treeViewConstants.sideBar) {
      if (
        selectedSideContent &&
        selectedSideContent.id === id &&
        selectedSideContent.pId === pId &&
        selectedSideContent.rId === rId
      ) {
        return sidebarStyles.contentSelected;
      } else if (id === rId) {
        return sidebarStyles.rootContent;
      } else {
        return sidebarStyles.content;
      }
    } else {
      return treeStyles.content;
    }
  };

  const getIconStyle = nodeObj => {
    const { id, rootKey } = nodeObj;
    if (type === treeViewConstants.sideBar) {
      if (id === rootKey) {
        return sidebarStyles.rootIconContainer;
      } else {
        return sidebarStyles.iconContainer;
      }
    } else {
      return treeStyles.iconContainer;
    }
  };

  const getLabelStyle = nodeObj => {
    const { id, rootKey: rId, parentId: pId } = nodeObj;
    if (type === treeViewConstants.sideBar) {
      if (
        selectedSideContent &&
        selectedSideContent.id === id &&
        selectedSideContent.pId === pId &&
        selectedSideContent.rId === rId
      ) {
        return sidebarStyles.labelSelected;
      } else if (id === rId) {
        return sidebarStyles.labelRoot;
      } else {
        return sidebarStyles.label;
      }
    } else {
      return treeStyles.label;
    }
  };

  const expandTree = (event, node) => {
    event.preventDefault();
    if (type === treeViewConstants.sideBar) {
      const id = `${node.id}`;
      const pId = `${node.parentId || ''}`;
      const nodeExpansionIndex = expanded.indexOf(id);
      const parentExpansionIndex = expanded.indexOf(pId);
      if (node.id !== defaultId || (node.id === defaultId && node.parentId !== parentId)) {
        const expandedList =
          nodeExpansionIndex > -1
            ? expanded.slice(0, nodeExpansionIndex)
            : [...expanded.slice(0, parentExpansionIndex + 1), id];
        handleQueueClick(event, node, expandedList);
        updateExpanded(expandedList);
      } else {
        nodeExpansionIndex > -1
          ? updateExpanded(expanded.slice(0, nodeExpansionIndex))
          : updateExpanded([...expanded, id]);
      }
    } else {
      node.id !== defaultId && handleTreeNodeClick(event, node);
    }
  };

  const getLabelElementProps = nodes => {
    let labelProp = {
      className: 'sidebar-content-outer',
    };
    if (type === treeViewConstants.hierarchy) {
      labelProp = { ...labelProp, onClick: event => expandTree(event, nodes) };
    }
    return labelProp;
  };

  const treeItemProps = nodes => {
    let treeItemProp = {
      key: nodes.id,
      nodeId: nodes.id.toString(),
      label: (
        <div {...getLabelElementProps(nodes)}>
          <p
            style={{
              backgroundColor:
                nodes.id == defaultId && type !== treeViewConstants.sideBar ? '#F6F09B' : '',
            }}
          >
            {(viewType === "Parent Organization") ?
              nodes.count ? `${nodes.id} - ${nodes.name}  (${nodes.count})` : `${nodes.id} - ${nodes.name}`
              :
              nodes.count ? `${nodes.name}  (${nodes.count})` : nodes.name}
          </p>
          {type === treeViewConstants.sideBar && renderSideBarIcon(nodes.name)}
        </div>
      ),
      classes: {
        root: type === treeViewConstants.sideBar ? sidebarStyles.root : treeStyles.root,
        content: getRoot(nodes),
        expanded: type === treeViewConstants.sideBar ? sidebarStyles.expanded : treeStyles.expanded,
        group: type === treeViewConstants.sideBar ? sidebarStyles.group : treeStyles.group,
        label: getLabelStyle(nodes),
        iconContainer: getIconStyle(nodes),
      },
    };

    if (type === treeViewConstants.sideBar) {
      return { ...treeItemProp, onClick: event => expandTree(event, nodes) };
    }

    return treeItemProp;
  };

  const renderTree = nodes => {
    return (
      <TreeItem {...treeItemProps(nodes)}>
        {Array.isArray(nodes.childNodes) ? nodes.childNodes.map(node => renderTree(node)) : null}
      </TreeItem>
    );
  };

  const getTreeViewProps = () => {
    let treeViewProps = {
      defaultCollapseIcon: <FontAwesomeIcon icon={faMinusSquare} />,
      defaultExpandIcon: <FontAwesomeIcon icon={faPlusSquare} />,
      defaultExpanded: idList,
      className: treeStyles.root,
    };
    if (type === treeViewConstants.sideBar) {
      treeViewProps = {
        ...treeViewProps,
        className: sidebarStyles.root,
        expanded,
      };
    }
    return treeViewProps;
  };

  return (
    <div className={`sideBarTreeView ${isSideBarClose ? 'sideBarClose' : 'sideBarOpen'}`}>
      {type === treeViewConstants.sideBar && renderSideBarDefaultRow()}
      <TreeView {...getTreeViewProps()}>
        {treeList && treeList.length ? treeList.map(node => renderTree(node)) : null}
      </TreeView>
    </div>
  );
};

RecursiveTreeView.propTypes = {
  treeList: PropTypes.array.isRequired,
  defaultId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleQueueClick: PropTypes.func,
  type: PropTypes.oneOf([treeViewConstants.sideBar, treeViewConstants.hierarchy]),
  toggleSideBar: PropTypes.func,
  isSideBarClose: PropTypes.bool,
  queueExpandedList: PropTypes.array,
  queueTypeSelected: PropTypes.object,
  handleTreeNodeClick: PropTypes.func,
  isSidePinEnable: PropTypes.bool,
  handlePinToggle: PropTypes.func,
};

RecursiveTreeView.defaultProps = {
  handleTreeNodeClick: () => { },
  handleQueueClick: () => { },
};

export default RecursiveTreeView;
