import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import { checkIfStringIsEmpty } from '../../utils/generic/helper';
import CopyContentModal from '../copyContentModal';
import './index.scss';

const Link = ({ link, onClick, customTextToolTip, urlLink, isDynamic }) => {
  const renderCellContent = ({ list }) => {
    const htmlElement = list.map((element, index) => {
      const { keyValue } = element;
      const url = element[keyValue ? 'keyValue' : 'url'];
      const validLink = url.startsWith('http') ? url : `//${url}`;
      return (
        <>
          <span className="link" key={index} onClick={() => window.open(validLink)}>
            {url}
          </span>
          <br />
        </>
      );
    });
    return <CopyContentModal>{htmlElement}</CopyContentModal> || 'NA';
  };

  const renderLinkView = () => {
    if (urlLink) {
      let linkObj = {};
      if (Array.isArray(urlLink) && urlLink.length) {
        if (urlLink[0] && typeof urlLink[0] === 'object') {
          linkObj = { list: urlLink };
        } else if (urlLink[0] && typeof urlLink[0] === 'string') {
          linkObj = {
            list: urlLink.map(element => {
              return { url: element };
            }),
          };
        }
      } else if (typeof urlLink === 'string') {
        linkObj = { list: [{ url: urlLink }] };
      }
      return linkObj && linkObj.list && linkObj.list.length ? renderCellContent(linkObj) : 'NA';
    } else {
      return 'NA';
    }
  };

  const renderDefault = () => {
    if (!checkIfStringIsEmpty(link)) {
      const html = (
        <span className="default-link" onClick={() => onClick(link)}>
          {link}
        </span>
      );
      return customTextToolTip ? <Tooltip title={customTextToolTip}>{html}</Tooltip> : html;
    }
    return '';
  };

  return <CopyContentModal>{isDynamic ? renderLinkView() : renderDefault()}</CopyContentModal>;
};

Link.propTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func,
  urlLink: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
  isDynamic: PropTypes.bool,
  customTextToolTip: PropTypes.string,
};

Link.defaultProps = {
  isDynamic: false,
  customTextToolTip: '',
};

export default Link;
