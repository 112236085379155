import * as Yup from 'yup';
import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { DROPDOWN_TYPES, TEXT_LIMIT } from '../../../../utils/generic/constants';
import { VIEW_FIELD_TYPES } from '../../../../framework/formBuilder/utils';
import format from 'date-fns/format';

export const validate = (values, isValidate, isOtherTab) => {
  const errors = {};
  const transitionFutherDetails = values.transitionFutherDetails
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  if (values.otherCommonAbbreviation?.trim() && values.otherCommonAbbreviation.length > 400) {
    errors.otherCommonAbbreviation = 'Please enter only 400 characters';
  }
  if (values.registryLocation?.trim() && values.registryLocation.length > 150) {
    errors.registryLocation = 'Please enter only 150 characters';
  }
  if (
    values.commentsOnAcceptance?.trim() &&
    values.commentsOnAcceptance.length > TEXT_LIMIT[3000]
  ) {
    errors.commentsOnAcceptance = 'Please enter only 3000 characters';
  }
  if (
    values.permissibleRegistriesComments?.trim() &&
    values.permissibleRegistriesComments.length > 400
  ) {
    errors.permissibleRegistriesComments = 'Please enter only 400 characters';
  }
  if (values.prospectiveTrialFees?.trim() && values.prospectiveTrialFees.length > 400) {
    errors.prospectiveTrialFees = 'Please enter only 400 characters';
  }
  if (values.retrospectiveTrialFees?.trim() && values.retrospectiveTrialFees.length > 400) {
    errors.retrospectiveTrialFees = 'Please enter only 400 characters';
  }
  if (
    values.ifOtherWhatIsRegistryRequirement?.trim() &&
    values.ifOtherWhatIsRegistryRequirement.length > 500
  ) {
    errors.ifOtherWhatIsRegistryRequirement = 'Please enter only 500 characters';
  }
  if (values.commentsForFees?.trim() && values.commentsForFees.length > 400) {
    errors.commentsForFees = 'Please enter only 400 characters';
  }
  if (
    values.transitionFutherDetails?.trim() &&
    values.transitionFutherDetails.trim().length > 3000
  ) {
    errors.transitionFutherDetails = 'Please enter only 3000 characters';
  }
  if (isValidate) {
    if (values.isTransitionPeriod && transitionFutherDetails?.length === 0) {
      errors.transitionFutherDetails = 'Please enter further details';
    }
    if (isOtherTab && !values.registryURL?.length) {
      errors.url = 'Please enter registry link url';
    }
    if (!values.disclosureCountries?.length) {
      errors.disclosureCountries = 'Please select disclosure countries';
    }
    if (!values.registryAbbreviatedName) {
      errors.registryAbbreviatedName = 'Please select abbreviated name';
    }
    if (!values.registryName) {
      errors.registryName = 'Please select registry name';
    }
    if (values.isGeographicalRequirement === 0) {
      errors.isGeographicalRequirement = 'Please select geographical requirement';
    }
    if (values.primaryOrPartner === null) {
      errors.primaryOrPartner = 'Please select primary or partner';
    }
    if (values.isICMJEComplaint === 0) {
      errors.isICMJEComplaint = 'Please select ICMJE compliant';
    }
    if (values.registryGovernance === null) {
      errors.registryGovernance = 'Please select registry governance';
    }
    if (values.isProspectiveRegistration === 0) {
      errors.isProspectiveRegistration = 'Please select prospective registration';
    }
    if (values.isRetrospectiveRegistration === 0) {
      errors.isRetrospectiveRegistration = 'Please select retrospective registration';
    }
    if (!values.registryLanguage.length) {
      errors.registryLanguage = 'Please select registry language';
    }
    if (values.isQcReview === 0) {
      errors.isQcReview = 'Please select QC review';
    }
    if (
      [true, 1].includes(values.isProspectiveRegistration) &&
      !values.prospectiveTrialFees?.trim()
    ) {
      errors.prospectiveTrialFees = 'Please enter prospective trial fees';
    }
    if (
      [true, 1].includes(values.isRetrospectiveRegistration) &&
      !values.retrospectiveTrialFees?.trim()
    ) {
      errors.retrospectiveTrialFees = 'Please enter retrospective trial fees';
    }
    if ([true, 1].includes(values.isGeographicalRequirement) && !values.geographicCountry?.length) {
      errors.geographicCountry = 'Please select geographic country';
    }
    if (
      values.permissibleRegistries?.length > 0 &&
      (isOtherTab
        ? !values.permissibleRegistries[0]?.comment?.trim()
        : !values.permissibleRegistriesComments?.trim())
    ) {
      errors.permissibleRegistriesComments = 'Please enter comments';
    }
  }
  return errors;
};

export const registryMandatoryVoluntaryOptions = [
  {
    id: 1,
    value: 'Mandatory',
    label: 'Mandatory',
  },
  {
    id: 2,
    value: 'Voluntary',
    label: 'Voluntary',
  },
  {
    id: 3,
    value: 'Other',
    label: 'Other',
  },
];

export const registryMandatoryVoluntaryOptionsObj = {
  Mandatory: 1,
  Voluntary: 2,
  Other: 3,
};

// export const validationSchema = Yup.object({
//   registryOptions: Yup.string()
//     .trim()
//     .required('value is required'),
// });

export const initialValues = {
  disclosureCountries: [],
  registryAbbreviatedName: null,
  registryName: null,
  otherCommonAbbreviation: null,
  isGeographicalRequirement: null,
  commentsOnAcceptance: null,
  primaryOrPartner: null,
  isICMJEComplaint: null,
  registryGovernance: null,
  launchDate: null,
  registryURL: [],
  isProspectiveRegistration: null,
  prospectiveTrialFees: null,
  isRetrospectiveRegistration: null,
  retrospectiveTrialFees: null,
  commentsForFees: null,
  registryLanguage: [],
  geographicCountry: [],
  permissibleRegistries: [],
  sourceDocs: [],
  isQcReview: true,
};

export const layoutSchema = {
  layout: 'row',
  className: 'person-basic-info-container mb-20',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'disclosureCountries',
      size: 6,
      className: '',
    },
    {
      layout: 'registryAbbreviatedName',
      size: 6,
      className: 'singleLine',
    },
    {
      layout: 'otherCommonAbbreviation',
      size: 6,
      className: '',
    },
    {
      layout: 'registryName',
      size: 6,
      className: '',
    },
    {
      layout: 'registryTimezone',
      size: 6,
      className: '',
    },
    {
      layout: 'registryLocation',
      size: 6,
      className: '',
    },
    {
      layout: 'isRegistryMandatoryOrVoluntary',
      size: 6,
      className: 'timeline',
    },
    {
      layout: 'ifOtherWhatIsRegistryRequirement',
      size: 6,
      className: '',
    },
    {
      layout: 'row',
      className: 'person-basic-info-container mb-20',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      size: 12,
      content: [
        {
          layout: 'linkingToRequirement',
          size: 6,
          className: '',
        },
      ],
    },

    {
      layout: 'isGeographicalRequirement',
      size: 12,
      className: '',
    },
    {
      layout: 'geographicCountry',
      size: 6,
      className: '',
    },
    {
      layout: 'commentsOnAcceptance',
      size: 6,
      className: '',
    },
    {
      layout: 'isTransitionPeriod',
      size: 6,
    },
    {
      layout: 'transitionFutherDetails',
      size: 6,
    },
    {
      layout: 'permissibleRegistries',
      size: 6,
      className: '',
    },
    {
      layout: 'permissibleRegistriesComments',
      size: 6,
      className: '',
    },
    {
      layout: 'primaryOrPartner',
      size: 6,
      className: '',
    },
    {
      layout: 'isICMJEComplaint',
      size: 6,
      className: '',
    },
    {
      layout: 'registryGovernance',
      size: 6,
      className: '',
    },
    {
      layout: 'launchDate',
      size: 6,
      className: '',
    },
    {
      layout: 'isProspectiveRegistration',
      size: 6,
    },
    {
      layout: 'prospectiveTrialFees',
      size: 6,
    },
    {
      layout: 'isRetrospectiveRegistration',
      size: 6,
    },
    {
      layout: 'retrospectiveTrialFees',
      size: 6,
    },
    {
      layout: 'commentsForFees',
      size: 6,
      className: 'singleLine',
    },
    {
      layout: 'registryLanguage',
      size: 6,
    },
    {
      layout: 'isQcReview',
      size: 6,
    },
    // content: [
    {
      layout: 'row',
      className: 'person-name-row',
      size: 12,
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'sourceDocs',
          size: 6,
          className: '',
        },
      ],
    },

    // ]
  ],
};

export const layoutSchema2 = {
  layout: 'row',
  className: 'person-basic-info-container mb-20',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'sourceDocs2',
      size: 4,
      className: 'sourceDocs2',
    },
    {
      layout: 'sourceDocs2Table',
      size: 12,
      className: 'sourceDocs2Table',
    },
  ],
};

// export const layoutSchema1 = {
//   layout: 'row',
//   className: 'person-basic-info-container mb-20',
//   spacing: 3,
//   justify: 'flex-start',
//   alignItems: 'flex-start',
//   content: [
//     {
//       layout: 'row',
//       className: 'person-name-row',
//       size: 12,
//       spacing: 3,
//       justify: 'flex-start',
//       alignItems: 'flex-start',
//       content: [
//         {
//           layout: 'sourceDocs',
//           size: 6,
//           className: '',
//         },
//       ]
//     },
//   ]
// };

// export const layoutSchema2 = {
//   layout: 'row',
//   className: 'person-basic-info-container mb-20',
//   spacing: 3,
//   justify: 'flex-start',
//   alignItems: 'flex-start',
//   content:
//   {
//     layout: 'row',
//     className: 'description',
//     size: 12,
//     spacing: 3,
//     justify: 'flex-start',
//     alignItems: 'flex-start',
//     content: [
//       {
//         layout: 'isProspectiveRegistration',
//         size: 6
//       },
//       {
//         layout: 'prospectiveTrialFees',
//         size: 6
//       },
//       {
//         layout: 'isRetrospectiveRegistration',
//         size: 6
//       },
//       {
//         layout: 'retrospectiveTrialFees',
//         size: 6
//       },
//       {
//         layout: 'commentsForFees',
//         size: 6
//       },
//       {
//         layout: 'registryLanguage',
//         size: 6
//       },
//       {
//         layout: 'isQcReview',
//         size: 6
//       },
//     ]
//   }
// };
const columnDefs1 = [
  {
    headerName: 'DOCUMENT ID',
    field: 'value',
    width: 250,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'DOCUMENT TITLE',
    field: 'documentTitle',
    width: 400,
  },
  {
    headerName: 'Regulatory Date',
    field: 'regulatoryDate',
    width: 350,
    sortable: true,
    cellRenderer: ({ value }) => {
      return value ? format(new Date(value), 'dd-MMM-yyyy') : 'NA';
    },
  },
];

export const formSchema = {
  disclosureCountries: {
    name: 'disclosureCountries',
    type: fieldTypes.select_tag,
    label:
      'For Which countries/regions this Registry Supports disclosure requirements  (Recommended or Mandatory registration)?',
    options: [],
    props: {
      height: 'ht-lg',
      // isSearchable: true,
      // isClearable: true,
      // type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      // isMulti: true,
      // showSelectedData: true,
      isMandatory: true,
    },
  },
  registryAbbreviatedName: {
    name: 'registryAbbreviatedName',
    type: fieldTypes.select_async,
    label: 'REGISTRIES ABBREVIATED NAME',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMandatory: true,
    },
  },
  otherCommonAbbreviation: {
    name: 'otherCommonAbbreviation',
    type: fieldTypes.textArea,
    label: 'ADD OTHER COMMON ABBREVIATION, ACRONYM, OR INITIALISM',
    props: {
      rowsMin: 2,
      cols: 10,
      disabled: false,
    },
  },
  registryName: {
    name: 'registryName',
    type: fieldTypes.select_async,
    label: 'REGISTRY NAME',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMandatory: true,
    },
  },
  registryTimezone: {
    name: 'registryTimezone',
    type: fieldTypes.select_timezone,
    label: 'REGISTRY TIMEZONE',
    props: {
      height: 'ht-lg',
      disabled: false,
      isMandatory: false,
      placeholder: '',
      isClearable: true,
      isSearchable: true,
    },
  },
  registryLocation: {
    name: 'registryLocation',
    type: fieldTypes.textArea,
    label: 'REGISTRY Location',
    props: {
      rowsMin: 2,
      cols: 10,
      disabled: false,
    },
  },
  isRegistryMandatoryOrVoluntary: {
    name: 'isRegistryMandatoryOrVoluntary',
    label: 'Is the registry Mandatory or Voluntary?',
    type: fieldTypes.radio,
    options: registryMandatoryVoluntaryOptions,
    props: {
      type: 'group',
      className: 'timelineRadioClass',
    },
  },
  ifOtherWhatIsRegistryRequirement: {
    name: 'ifOtherWhatIsRegistryRequirement',
    type: fieldTypes.textArea,
    label: 'If other, what is the registry requirement?',
    props: {
      rowsMin: 2.4,
      placeholder: '',
      rowsMax: 5,
    },
  },
  linkingToRequirement: {
    name: 'linkingToRequirement',
    type: fieldTypes.select_async,
    label: 'Linking to requirement record',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  isGeographicalRequirement: {
    name: 'isGeographicalRequirement',
    type: fieldTypes.custom_radio,
    label:
      'Does this registry permit disclosure of clinical data for trials not required by regulation, including trials conducted in other countries (Voluntary registration)? ',
    options: [
      { id: 1, display: 'YES' },
      { id: 2, display: 'NO' },
    ],
    props: {
      isMandatory: true,
    },
  },
  geographicCountry: {
    name: 'geographicCountry',
    type: fieldTypes.select_async,
    label: 'If Yes List Of Countries Or Regions  Of Studies Location accepted in country',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMulti: true,
      showSelectedData: true,
      isMandatory: false,
    },
  },
  commentsOnAcceptance: {
    name: 'commentsOnAcceptance',
    type: fieldTypes.textArea,
    label: 'COMMENTS ON ACCEPTANCE OF CLINICAL DATA CONDUCTED IN OTHER COUNTRIES',
    props: {
      rowsMin: 2,
      cols: 10,
      disabled: false,
    },
  },
  isTransitionPeriod: {
    label: 'Transition period',
    name: 'isTransitionPeriod',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
  },
  transitionFutherDetails: {
    name: 'transitionFutherDetails',
    type: fieldTypes.textArea,
    label: 'Further details',
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
  permissibleRegistries: {
    name: 'permissibleRegistries',
    type: fieldTypes.select_async,
    label: 'OTHER PERMISSIBLE REGISTRIES',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMulti: true,
      showSelectedData: true,
      isMandatory: false,
    },
  },
  permissibleRegistriesComments: {
    name: 'permissibleRegistriesComments',
    type: fieldTypes.textArea,
    label: 'COMMENTS ON ADDITIONAL PERMISSIBLE REGISTRIES',
    props: {
      rowsMin: 2,
      cols: 10,
      disabled: false,
      isMandatory: true,
    },
  },
  primaryOrPartner: {
    name: 'primaryOrPartner',
    type: fieldTypes.custom_radio,
    label: 'WHO PRIMARY OR PARTNER REGISTRY?',
    options: [
      { id: 1, value: 'PRIMARY', display: 'PRIMARY' },
      { id: 2, value: 'PARTNER', display: 'PARTNER' },
      { id: 3, value: 'NONE', display: 'NONE' },
    ],
    props: {
      isMandatory: true,
    },
  },
  isICMJEComplaint: {
    name: 'isICMJEComplaint',
    type: fieldTypes.custom_radio,
    label: 'ICMJE COMPLIANT REGISTRY?',
    options: [
      { id: 1, display: 'YES' },
      { id: 2, display: 'NO' },
    ],
    props: {
      isMandatory: true,
    },
  },
  registryGovernance: {
    name: 'registryGovernance',
    type: fieldTypes.select_async,
    label: ' REGISTRY GOVERNANCE',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMandatory: true,
    },
  },
  launchDate: {
    name: 'launchDate',
    type: fieldTypes.date,
    label: 'REGISTRY LAUNCH DATE',
    props: {
      format: 'dd-MMM-yyyy',
      views: ['year', 'month', 'date'],
      clearable: true,
      disableFuture: true,
      minDate: new Date('1200-01-01'),
      maxDate: new Date(),
    },
  },
  isProspectiveRegistration: {
    name: 'isProspectiveRegistration',
    type: fieldTypes.custom_radio,
    label: 'DOES THE REGISTRY ACCEPT PROSPECTIVE TRIAL REGISTRATION?',
    options: [
      { id: 1, display: 'YES' },
      { id: 2, display: 'NO' },
    ],
    props: {
      isMandatory: true,
    },
  },
  prospectiveTrialFees: {
    name: 'prospectiveTrialFees',
    type: fieldTypes.textArea,
    label: 'ARE THERE SPECIFIC FEES FOR PROSPECTIVE TRIAL REGISTRATION?',
    props: {
      rowsMin: 2,
      cols: 10,
      placeholder: '',
      isMandatory: false,
    },
  },
  isRetrospectiveRegistration: {
    name: 'isRetrospectiveRegistration',
    type: fieldTypes.custom_radio,
    label: 'Does the Registry accept retrospective trial registration?',
    options: [
      { id: 1, display: 'YES' },
      { id: 2, display: 'NO' },
    ],
    props: {
      isMandatory: true,
    },
  },
  retrospectiveTrialFees: {
    name: 'retrospectiveTrialFees',
    type: fieldTypes.textArea,
    label: 'ARE THERE SPECIFIC FEES FOR RETROSPECTIVE TRIAL REGISTRATION?',
    props: {
      rowsMin: 2,
      cols: 10,
      placeholder: '',
      isMandatory: false,
    },
  },
  commentsForFees: {
    name: 'commentsForFees',
    type: fieldTypes.textArea,
    label: 'COMMENTS RELATED TO REGISTRY FEES (AS NEEDED)',
    props: {
      rowsMin: 2,
      cols: 10,
      placeholder: '',
      isMandatory: false,
    },
  },
  registryLanguage: {
    name: 'registryLanguage',
    type: fieldTypes.select_tag,
    label:
      'Registry language(s) In which languages does clinical trial information can be submitted?',
    // options: [],
    props: {
      isMandatory: true,
    },
  },
  isQcReview: {
    name: 'isQcReview',
    type: fieldTypes.custom_radio,
    label: 'DOES THE REGISTRY PERFORM A QC REVIEW?',
    options: [
      { id: 1, display: 'YES' },
      { id: 2, display: 'NO' },
    ],
    props: {
      isMandatory: true,
    },
  },
  sourceDocs: {
    name: 'sourceDocs',
    type: fieldTypes.select_async,
    label: 'Legal basis or requirements for the registry',
    props: {
      isSearchable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
};

export const formSchema2 = {
  sourceDocs2: {
    name: 'sourceDocs2',
    type: fieldTypes.select_async,
    label: 'ADDITIONNAL LINKING TO Other Source Documents',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  sourceDocs2Table: {
    name: 'sourceDocs2Table',
    type: fieldTypes.dndDataGrid,
    props: {
      columnDefs: columnDefs1,
      rowHeight: 35,
    },
  },
};

export const getTimezoneObj = obj => {
  if (obj) {
    return { timezoneLocation: obj.value, timezone: obj.label };
  }
};
