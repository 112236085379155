import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { Logger, LogLevel } from "msal";

// Msal Configurations
// Fix data issue
const config = {
  auth: {
    authority: process.env.REACT_APP_CONFIG_AUTH_AUTHORITY,
    clientId: process.env.REACT_APP_CONFIG_AUTH_CLIENTID,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [ process.env.REACT_APP_AUTHENTICATIONPARAMETERS_SCOPES ]
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: `${window.location.origin}/auth.html`,
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
