/* eslint-disable generator-star-spacing */
import { takeEvery, put, select, all } from 'redux-saga/effects';
import * as countryAction from '../actions/country';
import * as globalActions from '../actions/globalActions';
import { country as countryApi } from '../../api';
import { ALERT_MESSAGES, COUNTRY_TYPES, MODAL_TYPES } from '../../utils/generic/constants';
import { getLockMessageInfo, getApiErrorMessage } from '../../utils/generic/helper';

export function* deleteCountryAsync({ payload: { countryId, postParam, productionStatusId } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield countryApi.deleteCountry(countryId, productionStatusId);
    yield put(globalActions.hideSpinner());
    const {
      data: { state, messages },
    } = response;

    if (state) {
      // yield put(countryAction.getCountryList(postParam));
      yield put(globalActions.showAlert(ALERT_MESSAGES.COUNTRY_DELETE));
      window.location.reload();
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.COUNTRY_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* getCountryAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { id } = payload;
    const countryDetails = yield countryApi.getCountryDetails(id);
    if (countryDetails) {
      const { state, data, messages } = countryDetails.data;
      if (state) {
        yield put(countryAction.saveCountryDetailsSuccess(data));
      }
      if (data?.productionStatus !== 2) {
        const lockDetails = yield countryApi.getUserLockDetails(id);
        if (lockDetails) {
          const {
            data: { state, data, messages },
          } = lockDetails;
          if (state) {
            yield put(countryAction.getCountryUserLockDetails(data));
            const { isEditable, lockedBy } = data;
            if (!isEditable) {
              yield put(
                globalActions.showModal({
                  isOpen: true,
                  message: getLockMessageInfo(lockedBy),
                  modalType: MODAL_TYPES.INFO,
                }),
              );
            }
          } else {
            yield put(
              globalActions.showModal({
                isOpen: true,
                message: getApiErrorMessage(messages),
                modalType: MODAL_TYPES.ERROR,
              }),
            );
          }
        }
      }
    }

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(countryAction.setError());
  }
}

export function* getStageIdCountryAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const id = payload;
    const response = yield countryApi.getStageRecordId(id);
    const { state, data, messages } = response.data;
    if (state) {
      yield put(countryAction.getStagedIdCountrySuccess(data));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.COUNTRY_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(countryAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.COUNTRY_ERROR));
  }
}

export function* saveCountryAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.country);
    const { id = 0, countryName = null } = original;
    const forceUpdate = [COUNTRY_TYPES[Object.keys(payload)[0]]];
    const response = yield countryApi.saveCountryDetails({
      id,
      forceUpdate,
      countryName,
      ...payload,
    });
    const { state, data, messages } = response.data;
    if (state) {
      yield put(countryAction.saveCountryDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.COUNTRY_SAVE));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.COUNTRY_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(countryAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.COUNTRY_ERROR));
  }
}

export function* validateCountryAsync() {
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.country);
    const { id = 0, countryName = null } = original;
    const forceUpdate = [3];
    const productionStatus = 1;
    const response = yield countryApi.saveCountryDetails({
      id,
      forceUpdate,
      countryName,
      productionStatus,
    });
    const { state, data, messages } = response.data;
    if (state) {
      yield put(countryAction.saveCountryDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.COUNTRY_VALIDATE));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.COUNTRY_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(countryAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.COUNTRY_ERROR));
  }
}

export function* publishCountryAsync({ payload }) {
  const { id, auditProperties } = payload;
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.country);
    const { countryName = null } = original;
    const forceUpdate = [3];
    const productionStatus = 2;
    const response = yield countryApi.saveCountryDetails({
      id,
      forceUpdate,
      countryName,
      productionStatus,
      auditProperties,
    });
    const { state, data, messages } = response.data;
    if (state) {
      yield put(countryAction.saveCountryDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.COUNTRY_PUBLISHED));
      if (auditProperties) {
        window.location.href = '/dashboard';
      } else {
        window.location.reload();
      }
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.COUNTRY_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(countryAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.COUNTRY_ERROR));
  }
}

export function* moveOutofScopeCountryAsync() {
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.country);
    const { id = 0 } = original;
    const forceUpdate = [3];
    const productionStatus = 3;
    const response = yield countryApi.saveCountryDetails({
      id,
      forceUpdate,
      productionStatus,
    });
    const { state, data, messages } = response.data;
    if (state) {
      yield put(countryAction.saveCountryDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.COUNTRY_OUTOFSCOPE));
      window.location.reload();
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.COUNTRY_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(countryAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.COUNTRY_ERROR));
  }
}

export function* getCountryMasterDataAsync() {
  try {
    yield put(globalActions.showSpinner());

    const response = yield countryApi.getCountryMasterData();

    const { state, data } = response.data;
    if (state) {
      yield put(countryAction.getCountryMasterDataSuccess(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(countryAction.setError());
  }
}

export function* getCountryRecordChangeFldsListAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { id } = payload;
    const response = yield countryApi.getCountryRecordChangeFieldsList(id);

    //const { state, data } = response.data;
    if (response.status) {
      yield put(countryAction.countryRecordChangeFldsListSuccess(response.data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(countryAction.setError());
  }
}

export function* getCountryStampingDetailsAsync({ payload: { body } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield countryApi.saveCountryDetails(body);
    const { state, data } = response.data;
    if (state) {
      yield put(countryAction.getCountryStampingDetailsSuccess({ data }));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(countryAction.setError());
  }
}

export function* releaseCountryUserLockAsync({ payload }) {
  try {
    yield countryApi.unlockUserRecords(payload);
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export function* updateCountryLockDetailsAsync({ payload }) {
  try {
    yield countryApi.getUserLockDetails(payload);
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export const watchers = [
  function* watchDeleteCountryAsyncAsync() {
    yield takeEvery(countryAction.deleteCountry, deleteCountryAsync);
  },
  function* watchGeCountryList() {
    yield takeEvery(countryAction.getCountryDetails, getCountryAsync);
  },
  function* watchGeCountryList() {
    yield takeEvery(countryAction.saveCountryDetails, saveCountryAsync);
  },
  function* watchGeCountryList() {
    yield takeEvery(countryAction.getCountryMasterData, getCountryMasterDataAsync);
  },
  function* watchValidateCountry() {
    yield takeEvery(countryAction.validateCountry, validateCountryAsync);
  },
  function* watchPublishCountry() {
    yield takeEvery(countryAction.publishCountry, publishCountryAsync);
  },
  function* watchOutofScopeCountry() {
    yield takeEvery(countryAction.moveOutofScopeCountry, moveOutofScopeCountryAsync);
  },
  function* watchGetStageRecordId() {
    yield takeEvery(countryAction.getStagedIdCountry, getStageIdCountryAsync);
  },
  function* watchGetCountryRecordChangeFieldsList() {
    yield takeEvery(
      countryAction.getCountryRecordChangeFieldsList,
      getCountryRecordChangeFldsListAsync,
    );
  },
  function* watchGetCountryStampingDetails() {
    yield takeEvery(countryAction.getCountryStampingDetails, getCountryStampingDetailsAsync);
  },
  function* watchGetCountryList() {
    yield takeEvery(countryAction.releaseCountryUserLock, releaseCountryUserLockAsync);
  },

  function* watchSaveCountryAsync() {
    yield takeEvery(countryAction.updateCountryLockDetails, updateCountryLockDetailsAsync);
  },
];
