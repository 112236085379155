import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useDispatch } from 'react-redux';

import ModalMessage from '../ModalMessage';
import { hideModal } from '../../../store/actions/globalActions';

const GlobalModal = () => {
  const dispatch = useDispatch();
  const { isOpen, modalType, message, modalListMessage } = useSelector(
    state => state.globalState.modalState,
    shallowEqual,
  );

  const onClose = () => {
    dispatch(hideModal({ isOpen: false }));
  };

  const modalProps = {
    isOpen,
    message,
    modalType,
    onClose,
    modalListMessage,
  };

  return <ModalMessage {...modalProps} />;
};

export default GlobalModal;
