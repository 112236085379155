const registryFeatures = [
  {
    name: 'Record Update And Summary',
    value: 'RecordUpdateAndSummary',
    key: 'recordUpdateAndSummary',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    error: null,
    hasUnSavedData: false,
    validate: false,
  },
  {
    name: 'General Information',
    value: 'RegistryGeneralInformation',
    key: 'generalInformation',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    error: null,
    hasUnSavedData: false,
    validate: false,
  },
  {
    name: 'Search & statistics',
    value: 'RegistrySearchStatistics',
    key: 'searchAndStatistics',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    error: null,
    hasUnSavedData: false,
    validate: false,
  },
  {
    name: 'Who enters the data',
    value: 'RegistryWhoEntersTheData',
    key: 'whoEntersData',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    error: null,
    hasUnSavedData: false,
    validate: false,
  },
  {
    name: 'Clinical Trial Characteristics',
    value: 'RegistryClinicalTrialCharacteristics',
    key: 'characteristics',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    error: null,
    hasUnSavedData: false,
    validate: false,
  },
  {
    name: 'Protocol registration',
    value: 'ProtocolRegistrationInRegistry',
    key: 'protocolRegistration',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    error: null,
    hasUnSavedData: false,
    validate: false,
  },
  {
    name: 'Results disclosure',
    value: 'ResultsDisclosureInRegistry',
    key: 'resultsDisclosure',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    error: null,
    hasUnSavedData: false,
    validate: false,
  },
  {
    name: 'Contact information',
    value: 'RegistryContactInformation',
    key: 'contactInfo',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    error: null,
    hasUnSavedData: false,
    validate: false,
  },
  {
    name: 'Audit Trail',
    value: 'RegistryAuditTrail',
    key: 'registryAuditTrail',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    error: null,
    hasUnSavedData: false,
    validate: false,
  },
];

export default registryFeatures;
