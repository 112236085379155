import React, { useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons'; //, faMinus
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputLabel from '../InputLabel';
import PropTypes from 'prop-types';
import './index.scss';

const InputNumber = ({ setNumber, label, isMandatory, number, disabled }) => {
  const [inputValue, setInputValue] = useState(number);
  // const handleDecrease = () => {
  //   if (number > 1) {
  //     setNumber(number - 1);
  //     setInputValue(inputValue - 1);
  //   }
  // };

  const handleIncrease = () => {
    //setNumber(number + 1);
    //setInputValue(inputValue + 1);
    setNumber(Number(inputValue));
  };

  useEffect(() => {
    //setNumber(number);
    setInputValue(number);
  }, [number]);

  const drawrow = e => {
    const numb = Number(
      e.target.value ? e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') : '',
    );
    if (numb === 0 || numb === '') {
      setInputValue(0);
    } else {
      setInputValue(Number(e.target.value));
    }
  };

  return (
    <div>
      <InputLabel labelFor="inputLabel" text={label} isMandatory={isMandatory} />
      <div className="inputNumber">
        {/* <span onClick={handleDecrease}>
          <FontAwesomeIcon icon={faMinus} />
        </span> */}
        <input
          className="number"
          type="text"
          value={inputValue}
          style={{ border: 'none', width: '50px', textAlign: 'center' }}
          onChange={e => {
            drawrow(e);
          }}
          disabled={disabled}
        />
        {/* <span className="number">{number}</span> */}
        <button disabled={disabled} className="button-none">
          <span onClick={handleIncrease}>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        </button>
      </div>
    </div>
  );
};

InputNumber.propTypes = {
  setNumber: PropTypes.func,
  label: PropTypes.string,
  isMandatory: PropTypes.bool,
  number: PropTypes.number,
};

InputNumber.defaultProps = {
  setNumber: () => {},
  label: '',
  isMandatory: false,
  number: 1,
};

export default InputNumber;
