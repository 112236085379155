import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '../InputLabel';
import InputToolTip from '../../toolTip/inputToolTip';

import './index.scss';

const InputHyperlink = ({ label, labelSize, value, onNavigation }) => {
  return (
    <div className="hyperlink-wrapper">
      {label && <InputLabel labelFor="inputLabel" text={label} size={labelSize} />}
      <a className="hyperlink-textField" href={onNavigation()} target="blank">
        <InputToolTip>{value}</InputToolTip>
      </a>
    </div>
  );
};

InputHyperlink.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onNavigation: PropTypes.func,
  size: PropTypes.string,
  labelSize: PropTypes.string,
};
InputHyperlink.defaultProps = {
  size: 'small',
  labelSize: '16',
  onNavigation: () => {},
};
export default InputHyperlink;
