import React from 'react';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InputLabel from '../InputLabel';
import './index.scss';

// TO DO: basic search box since we are unaware of functionality
const InputSearch = ({
  label,
  name,
  placeholder,
  size,
  onClickSearchIcon,
  isClearable,
  handleClearSearch,
  disabled,
  ...inputProps
}) => {
  return (
    <div className="input-search-container">
      {label && <InputLabel labelFor={name} text={label} size={size} padding="right" />}
      <div className="search-box">
        <InputBase
          placeholder={placeholder}
          inputProps={{ 'aria-label': { label } }}
          disabled={disabled}
          {...inputProps}
        />
        {isClearable && (
          <button type="button" onClick={() => handleClearSearch()}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        <button disabled={disabled} type="button" onClick={() => onClickSearchIcon()}>
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
    </div>
  );
};

InputSearch.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onClickSearchIcon: PropTypes.func,
  handleClearSearch: PropTypes.func,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
};

InputSearch.defaultProps = {
  label: '',
  size: '16',
  placeholder: '',
  onClickSearchIcon: () => {},
  handleClearSearch: () => {},
  isClearable: false,
  disabled: false,
};

export default InputSearch;
