import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { VALIDATION_REGEX } from '../../../../utils/generic/constants';
import {
  checkIngestionActionForMandatoryFields,
  isValidDate,
} from '../../../../utils/generic/helper';

export const validate = values => {
  const errors = {};
  if (!isValidDate(values.recordDates.nextReviewDate)) {
    errors.nextFullReviewDate = 'Please enter a valid date (MMM-dd-yyyy)';
  }
  if (!values.personType) {
    errors.personType = 'Please select the Person Type';
  }
  if (values.firstName.trim() === '') {
    errors.firstName = 'Please enter the First Name';
  }
  if (values.lastName.trim() === '') {
    errors.lastName = 'Please enter the Last Name';
  }

  if (
    !values.specialities ||
    (values.specialities &&
      (values.specialities.length === 0 ||
        checkIngestionActionForMandatoryFields(values.specialities)))
  ) {
    errors.specialities = 'Please select the Specialty';
  }

  if (!values.parentOrganization) {
    errors.parentOrganization = 'Please select the Primary Organization';
  }
  if (values.lastName && values.lastName.length > 32) {
    errors.lastName = 'The text entered exceeds 32 characters';
  }
  if (values.firstName && values.firstName.length > 32) {
    errors.firstName = 'The text entered exceeds 32 characters';
  }
  if (values.middleName && values.middleName.length > 32) {
    errors.middleName = 'The text entered exceeds 32 characters';
  }
  if (values.nameSuffix && values.nameSuffix.length > 32) {
    errors.nameSuffix = 'The text entered exceeds 32 characters';
  }
  return errors;
};

export const initialValues = {
  id: 0,
  name: '',
  personType: null,
  firstName: '',
  lastName: '',
  middleName: '',
  nameSuffix: '',
  specialities: null,
  degrees: null,
  licenses: [],
  synonyms: [],
  parentOrganization: null,
  parentOrganizationName: '',
  address: '',
  phone: '',
  fax: '',
  email: '',
  phoneNumbers: [],
  faxNumbers: [],
  emails: [],
  supportingUrls: [],
};

export const layoutSchema = {
  layout: 'row',
  className: 'person-basic-info-container mb-20',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: 'person-fullname-row mt-20',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      header: 'BASIC INFO',
      headerProps: {
        fontStyle: 'bold',
        size: '16',
      },
      content: [
        {
          layout: 'name',
          size: 6,
          className: '',
        },
        {
          layout: 'personType',
          size: 6,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      className: 'person-name-row',
      size: 12,
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'lastName',
          size: 3,
          className: '',
        },
        {
          layout: 'firstName',
          size: 3,
          className: '',
        },
        {
          layout: 'middleName',
          size: 3,
          className: 'person-middle-initial',
        },
        {
          layout: 'nameSuffix',
          size: 2,
          className: 'person-suffix',
        },
        {
          layout: 'synonyms',
          size: 2,
          className: 'person-contact-info',
        },
      ],
    },
    {
      layout: 'row',
      className: 'spl-degree-physician',
      size: 12,
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'specialities',
          size: 3,
          className: '',
        },
        {
          layout: 'degrees',
          size: 2,
          className: '',
        },
        {
          layout: 'licenses',
          size: 7,
          className: 'physician-id-number',
        },
      ],
    },
    {
      layout: 'row',
      className: '',
      size: 12,
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'parentOrganization',
          size: 9,
          className: '',
        },
        {
          layout: 'button',
          size: 3,
          className: 'create-org-btn',
        },
      ],
    },
    {
      layout: 'row',
      className: '',
      size: 12,
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'parentOrganizationName',
          size: 9,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      className: '',
      size: 12,
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'address',
          size: 6,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      className: '',
      size: 12,
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'phone',
          size: 4,
          className: '',
        },
        {
          layout: 'fax',
          size: 4,
          className: '',
        },
        {
          layout: 'email',
          size: 4,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      className: 'mt-20',
      size: 12,
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      header: 'CONTACT INFO',
      headerProps: {
        fontStyle: 'bold',
        size: '14',
      },
      content: [
        {
          layout: 'phoneNumbers',
          size: 4,
          className: 'person-contact-info',
        },
        {
          layout: 'faxNumbers',
          size: 4,
          className: 'person-contact-info',
        },
        {
          layout: 'emails',
          size: 4,
          className: 'person-contact-info',
        },
      ],
    },
  ],
};

export const formSchema = {
  name: {
    name: 'name',
    type: fieldTypes.text,
    label: 'PERSON FULL NAME',
    props: {
      disabled: true,
    },
  },
  personType: {
    name: 'personType',
    type: fieldTypes.select,
    label: 'PERSON TYPE',
    options: [],
    props: {
      height: 'ht-lg',
      isMandatory: true,
      isSearchable: true,
      isClearable: true,
    },
  },
  firstName: {
    name: 'firstName',
    type: fieldTypes.text,
    label: 'PERSON FIRST NAME',
    props: {
      isMandatory: true,
    },
  },
  lastName: {
    name: 'lastName',
    type: fieldTypes.text,
    label: 'PERSON LAST NAME',
    props: {
      isMandatory: true,
    },
  },
  middleName: {
    name: 'middleName',
    type: fieldTypes.text,
    label: 'PERSON MIDDLE INITIAL',
  },
  nameSuffix: {
    name: 'nameSuffix',
    type: fieldTypes.text,
    label: 'SUFFIX',
  },
  synonyms: {
    name: 'synonyms',
    type: fieldTypes.entry,
    label: 'SYNONYMS',
    props: {
      height: 'ht-lg'
    },
  },
  specialities: {
    name: 'specialities',
    type: fieldTypes.select_async,
    label: 'specialty',
    props: {
      isMandatory: true,
      isSearchable: true,
      showSelectedData: true,
      isMulti: true,
    },
  },
  degrees: {
    name: 'degrees',
    type: fieldTypes.select_async,
    label: 'degree',
    props: {
      isSearchable: true,
      showSelectedData: true,
      isMulti: true,
    },
  },
  licenses: {
    name: 'licenses',
    type: fieldTypes.comboEntry,
    label: 'physician Id Number',
    options: [],
    props: {
      height: 'ht-lg',
      selectProps: { label: 'physician Id Type', isSearchable: true, isClearable: true },
      type: 'text',

      errorMessages: {
        select: 'Please select physician type',
        entry: 'Please enter physician ID number',
        duplicate: 'Physician data already exists',
      },
      validate: {
        rule: VALIDATION_REGEX.EXTENDEDLICENSE,
        message: 'The number entered exceeds 40 digits',
      },
    },
  },
  parentOrganization: {
    name: 'parentOrganization',
    type: fieldTypes.select_async,
    label: 'Primary Organization with Address',
    props: {
      key: 'label',
      dependents: [
        { fieldName: 'addressId', fieldKey: 'addressId' },
        { fieldName: 'parentOrganizationName', fieldKey: 'name' },
        { fieldName: 'address', fieldKey: 'address' },
        { fieldName: 'phone', fieldKey: 'phone' },
        { fieldName: 'fax', fieldKey: 'fax' },
        { fieldName: 'email', fieldKey: 'email' },
      ],
      isSearchable: true,
      isClearable: true,
      placeholder: 'Search Organization with Address',
      isMandatory: true,
    },
  },
  parentOrganizationName: {
    name: 'parentOrganizationName',
    type: fieldTypes.hyperlink,
    label: 'PRIMARY ORGANIZATION NAME',
    props: {},
  },
  button: {
    name: 'button',
    type: fieldTypes.customButton,
    label: 'Create New Organization',
    props: {},
  },
  address: {
    name: 'address',
    type: fieldTypes.text,
    label: 'ADDRESS',
    props: {
      disabled: true,
    },
  },
  phone: {
    name: 'phone',
    type: fieldTypes.text,
    label: 'PHONE',
    props: {
      disabled: true,
    },
  },
  fax: {
    name: 'fax',
    type: fieldTypes.text,
    label: 'FAX',
    props: {
      disabled: true,
    },
  },
  email: {
    name: 'email',
    type: fieldTypes.text,
    label: 'EMAIL',
    props: {
      disabled: true,
    },
  },
  phoneNumbers: {
    name: 'phoneNumbers',
    type: fieldTypes.entry,
    label: 'PHONE',
    props: {
      height: 'ht-lg',
      validate: {
        rule: VALIDATION_REGEX.PHONE,
        message: 'Please enter valid phone number',
      },
      countryNddFlag: true,
    },
  },
  faxNumbers: {
    name: 'faxNumbers',
    type: fieldTypes.entry,
    label: 'FAX',
    props: {
      height: 'ht-lg',
      validate: {
        rule: VALIDATION_REGEX.PHONE,
        message: 'Please enter valid fax number',
      },
      countryNddFlag: true,
    },
  },
  emails: {
    name: 'emails',
    type: fieldTypes.entry,
    label: 'EMAIL',
    props: {
      height: 'ht-lg',
      validate: {
        rule: VALIDATION_REGEX.EMAIL,
        message: 'Please enter the valid email',
      },
    },
  },
};
