import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';

import {
  getAdvancedSearchCategoryDataSuccess,
  getAdvancedSearchCategoryTitleDataSuccess,
  getAllSavedSearchesSuccess,
  getSearchResultsSuccess,
  getEntityColDefSuccess,
  getPreviousSearchedConditions,
  getPreviousSearchedGroup,
  resetPagintionCount
} from '../actions/advancedSearch';

const initialState = InitialState.advancedSearch;

const advancedSearch = createReducer(initialState, {
  [getAdvancedSearchCategoryDataSuccess]: (state, { payload }) => {
    state.advancedSearchCategoryData = payload;
  },
  [getAdvancedSearchCategoryTitleDataSuccess]: (state, { payload }) => {
    state.advancedSearchCategoryTitleData = payload;
  },
  [getAllSavedSearchesSuccess]: (state, { payload }) => {
    state.allSavedSearches = payload;
  },
  [getSearchResultsSuccess]: (state, { payload }) => {
    state.searchRecords = payload;
  },
  [getEntityColDefSuccess]: (state, { payload }) => {
    state.listViewColumns = payload;
  },
  [getPreviousSearchedConditions]: (state, { payload }) => {
    state.previousSearchedConditions = payload;
  },
  [getPreviousSearchedGroup]: (state, { payload }) => {
    state.previousSearchGroup = payload;
  },
  [resetPagintionCount]: (state, { payload }) => {
    state.searchRecords = payload;
  },
});

export default advancedSearch;
