const trialFeatures = [
  {
    name: 'TRIAL SUMMARY',
    value: 'TrialSummary',
    isTab: false,
    sectionName: 'Trial Summary',
    key: 'trialSummary',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'TRIAL OBJECTIVE',
    value: 'TrialObjective',
    isTab: false,
    sectionName: 'Trial Objective',
    key: 'trialObjective',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'TRIAL OUTCOME',
    value: 'TrialOutcome',
    isTab: false,
    isMandatory: false,
    key: 'trialOutcome',
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'TRIAL TIMING',
    value: 'TrialTiming',
    isTab: false,
    sectionName: 'Trial Timing',
    key: 'trialTiming',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'PATIENT POPULATION',
    value: 'PatientPopulation',
    isTab: false,
    sectionName: 'Patient Population',
    key: 'patientPopulation',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'TREATMENT PLAN',
    value: 'TreatmentPlan',
    isTab: false,
    sectionName: 'Treatment Plan',
    key: 'trialTreatmentPlan',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'ASSOCIATIONS',
    value: 'Contacts',
    isTab: false,
    sectionName: 'Contacts',
    key: 'trialContacts',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'TRIAL RESULTS',
    value: 'TrialResults',
    isTab: false,
    sectionName: 'Trial Results',
    key: 'trialResults',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'TRIAL NOTES',
    value: 'TrialNotes',
    isTab: false,
    sectionName: 'Trial Notes',
    key: 'trialNotes',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'AUDIT TRAIL',
    value: 'AuditTrial',
    key: 'auditTrial',
    sectionName: 'Audit Trial',
    isTab: false,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
];

export default trialFeatures;
