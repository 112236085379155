import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';
import { difference } from 'lodash';
import {
  sideBarSelection,
  setSelectedTabIndex,
  setRoleGroup,
  setRoles,
  setRolesPermissions,
  getRoleById,
  setRoleById,
  setRolesSearchText,
  setAdministratorData,
  setAdministratorDeleteData,
  setUsers,
  setDataSource,
  setProcessor,
  getAgeingSuccess,
  getUserGroupSuccess,
  getQueueSuccess,
  getQueueMasterSuccess,
} from '../actions/administrator';

const administrator = createReducer(InitialState.administrator, {
  [sideBarSelection]: (state, action) => {
    state.selected = action.payload.value;
  },
  [setSelectedTabIndex]: (state, { payload: { id, tabIndex } }) => {
    const index = state.tabs.findIndex(i => i.value === id);
    if (index > -1) {
      state.tabs[index].selectedTabIndex = tabIndex;
    }
  },
  [setAdministratorData]: (state, action) => {
    if (action.payload.data.data) {
      state.dataSourceGroupList = action.payload.data.data;
    } else {
      state.dataSourceGroupList = [...state.dataSourceGroupList, action.payload.data];
    }
  },
  [setAdministratorDeleteData]: (state, action) => {
    state.dataSourceGroupList = [...action.payload.data];
  },
  [setRoleGroup]: (state, action) => {
    state.roleGroup = action.payload.data;
  },
  [setRoles]: (state, action) => {
    state.rolesList = action.payload.data;
  },
  [setRolesPermissions]: (state, action) => {
    const validPermissions = [
      'drugPermissions',
      'trialPermissions',
      'adminPermissions',
      'companyPermissions',
      'personPermissions',
      'orgnizationPermissions',
      'registryPermissions',
      'requirementPermissions',
      'sourceDocPermissions',
      'countryPermissions',
    ];
    let permissionGroup = {};
    let roleActions = {};

    action.payload.data.roleActions.forEach(role => {
      switch (role.description) {
        case 'R':
          roleActions.readAction = role;
          break;
        case 'W':
          roleActions.writeAction = role;
          break;
        case 'D':
          roleActions.deleteAction = role;
          break;
        case 'Y':
          roleActions.yesAction = role;
          break;
        case 'N':
          roleActions.noAction = role;
          break;
        default:
      }
    });

    for (const permissionGroupName in action.payload.data) {
      if (validPermissions.includes(permissionGroupName)) {
        let permissions = [];
        action.payload.data[permissionGroupName].forEach(permission => {
          permissions.push({
            ...permission,
            roleActions,
            selectedPermissionId: permission.isfeature
              ? roleActions.noAction.id
              : roleActions.readAction.id,
          });
        });
        permissionGroup[permissionGroupName] = permissions;
      }
    }
    state.rolesPermissions = permissionGroup;
  },
  [getRoleById]: state => {
    state.rolesPermissionsLoading = true;
  },
  [setRoleById]: (state, action) => {
    const validPermissions = [
      'drugPermissions',
      'trialPermissions',
      'adminPermissions',
      'companyPermissions',
      'personPermissions',
      'orgnizationPermissions',
      'registryPermissions',
      'requirementPermissions',
      'sourceDocPermissions',
      'countryPermissions',
    ];
    let permissionGroup = {};
    let roleActions = {};

    action.payload.permissions.roleActions.forEach(role => {
      switch (role.description) {
        case 'R':
          roleActions.readAction = role;
          break;
        case 'W':
          roleActions.writeAction = role;
          break;
        case 'D':
          roleActions.deleteAction = role;
          break;
        case 'Y':
          roleActions.yesAction = role;
          break;
        case 'N':
          roleActions.noAction = role;
          break;
        default:
      }
    });

    for (const permissionGroupName in action.payload.roleDetail) {
      if (validPermissions.includes(permissionGroupName)) {
        let permissions = [];
        let groupPermissionList = action.payload.permissions[permissionGroupName].map(
          p => p.description,
        );
        let detailPermissionList = action.payload.roleDetail[permissionGroupName].map(
          p => p.permission.description,
        );
        const newlyAddedPermissions = difference(groupPermissionList, detailPermissionList);
        newlyAddedPermissions.forEach(newPermission => {
          const newPermissionData = action.payload.permissions[permissionGroupName].filter(
            p => p.description === newPermission,
          )[0];
          permissions.push({
            ...newPermissionData,
            roleActions,
            selectedPermissionId: newPermissionData.isfeature
              ? roleActions.noAction.id
              : roleActions.readAction.id,
          });
        });
        action.payload.roleDetail[permissionGroupName].forEach(permissionData => {
          permissions.push({
            permissionId: permissionData.id,
            ...permissionData,
            ...permissionData.permission,
            roleActions,
            selectedPermissionId: permissionData.roleAction.id,
          });
        });
        permissionGroup[permissionGroupName] = permissions;
      }
    }

    state.rolesPermissions = permissionGroup;
    state.rolesPermissionsLoading = false;
  },

  [setRolesSearchText]: (state, action) => {
    state.rolesSearchText = action.payload;
  },
  [setUsers]: (state, action) => {
    state.usersList = action.payload;
  },
  [setDataSource]: (state, action) => {
    state.dataSource = action.payload.data;
  },
  [setProcessor]: (state, action) => {
    state.processorList = action.payload.data;
  },
  [getAgeingSuccess]: (state, action) => {
    state.ageing[action.payload.type] = action.payload.data;
  },
  [getUserGroupSuccess]: (state, action) => {
    state.userGroupList = action.payload.data;
  },
  [getQueueSuccess]: (state, action) => {
    state.queueSetup = action.payload.data;
  },
  [getQueueMasterSuccess]: (state, action) => {
    state.queueSetupMaster = action.payload.data;
  },
});
export default administrator;
