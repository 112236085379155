import { takeEvery, put } from 'redux-saga/effects';
import * as reportingActions from '../actions/reporting';
import * as globalActions from '../actions/globalActions';
import { reporting as reportingApi } from '../../api';

//fetch and put report data for sourceURL report with input DATE
export function *getSourceURLDataAsync(payload) {
  try {
    const response = yield reportingApi.getSourceURLReport(payload.payload);

    if (response.data.data.areas.length > 0) {
      yield put(reportingActions.getSourceURLReportSuccess(response.data.data.areas));
    } else {
      yield put(reportingActions.getSourceURLReportSuccess(response.data.data.areas));
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.setError());
  }
}

//fetch and put report data for ageing report with input DATE
export function *getAgingDataAsync(payload) {
  try {
    const response = yield reportingApi.getAgingReport(payload.payload);
    if (response.data.data.areas.length > 0) {
      yield put(reportingActions.getAgingReportSuccess(response.data.data.areas));
    } else {
      yield put(reportingActions.getAgingReportSuccess(response.data.data.areas));
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.setError());
  }
}

//fetch and put report data for diseasing report with input DATE
export function *getDiseasingDataAsync(payload) {
  try {
    const response = yield reportingApi.getDiseasingReport(payload.payload);
    if (response.data.data.areas.length > 0) {
      yield put(reportingActions.getDiseasingReportSuccess(response.data.data.areas));
    } else {
      yield put(reportingActions.getDiseasingReportSuccess(response.data.data.areas));
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.setError());
  }
}

//watchers for sourceURL, diseasing and ageing report data
export const watchers = [
  function *watchGetSourceURLData() {
    yield takeEvery(reportingActions.getSourceURLReport, getSourceURLDataAsync);
    yield takeEvery(reportingActions.getAgingReport, getAgingDataAsync);
    yield takeEvery(reportingActions.getDiseasingReport, getDiseasingDataAsync);
  },
];
