/* eslint-disable generator-star-spacing */
import { takeEvery, put, all, delay } from 'redux-saga/effects';
import * as personActions from '../actions/person';
import * as globalActions from '../actions/globalActions';
import * as TaskActions from '../actions/task';
import { person as personsApi, queues as queuesApi, task as taskApi } from '../../api';
import {
  ALERT_MESSAGES,
  MODAL_TYPES,
  updateTypeOnProdStatusSave,
} from '../../utils/generic/constants';
import {
  getProdMessage,
  getLockMessageInfo,
  getMultipleDeleteErrorMessage,
} from '../../utils/generic/helper';
import { getApiErrorMessage } from '../../utils/generic/helper';

export function* getPersonListAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield personsApi.getPersonList(payload);

    const { state, data } = response.data;
    if (state) {
      yield put(personActions.getPersonListSuccess(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(personActions.setError());
  }
}

export function* deletePersonAsync({ payload: { personId, postParam } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield personsApi.deletePerson(personId);
    yield put(globalActions.hideSpinner());
    const {
      data: { state, messages },
    } = response;

    if (state) {
      yield put(personActions.getPersonList(postParam));
      yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_DELETE));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.PERSONS_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* deleteMultiplePersonAsync({ payload: { personIds, postParam } }) {
  try {
    yield put(globalActions.showSpinner());
    const actions = {};
    personIds.map(id => {
      actions[id] = personsApi.deletePerson(id);
    });
    const responses = yield all(actions);
    const { errorMessage, deletedRecordCount } = getMultipleDeleteErrorMessage(responses);
    if (deletedRecordCount) {
      yield put(personActions.getPersonList(postParam));
    }
    const modalData = {
      isOpen: true,
      modalListMessage: errorMessage,
      modalType: MODAL_TYPES.CUSTOM_INFO_LIST,
    };
    yield put(globalActions.showModal(modalData));

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* getPersonMasterDataAsync() {
  try {
    yield put(globalActions.showSpinner());

    const response = yield personsApi.getPersonMasterData();

    const { state, data } = response.data;
    if (state) {
      yield put(personActions.getPersonMasterDataSuccess(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(personActions.setError());
  }
}

export function* updatePersonLockDetails({ payload }) {
  try {
    yield personsApi.getUserLockDetails(payload);
  } catch (err) {
    yield put(personActions.setError());
  }
}

export function* getPersonDetailsAsync({ payload: { id, ingestedId } }) {
  try {
    yield put(globalActions.showSpinner());

    let actions = {
      masterActions: personsApi.getPersonMasterData(),
    };

    if (ingestedId > 0) {
      actions = { ...actions, ingestedActions: personsApi.getIngestedData(ingestedId) };
    }

    if (id > 0) {
      actions = {
        ...actions,
        lockDetails: personsApi.getUserLockDetails(id),
        personDetailsActions: personsApi.getPersonDetails(id),
      };
    }

    const { masterActions, personDetailsActions, lockDetails, ingestedActions } = yield all(
      actions,
    );

    if (masterActions) {
      const { state: masterState, data: masterData } = masterActions.data;
      if (masterState) {
        yield put(personActions.getPersonMasterDataSuccess(masterData));
      }
    }

    if (personDetailsActions) {
      const { state: personState, data: personData } = personDetailsActions.data;
      if (personState) {
        yield put(personActions.getPersonDetailsSuccess(personData));
      }
    }

    if (ingestedActions) {
      const { state: ingestedState, data: ingestedData } = ingestedActions.data;
      if (ingestedState) {
        yield put(personActions.getPersonIngestedDataSuccess(ingestedData));
      }
    }

    if (lockDetails) {
      const {
        data: { state, data, messages },
      } = lockDetails;
      if (state) {
        yield put(personActions.getPersonLockDetails(data));
        const { isEditable, lockedBy } = data;
        if (!isEditable) {
          yield put(
            globalActions.showModal({
              isOpen: true,
              message: getLockMessageInfo(lockedBy),
              modalType: MODAL_TYPES.INFO,
            }),
          );
        }
      } else {
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: getApiErrorMessage(messages),
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    }

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(personActions.setError());
  }
}

export function* savePersonDetailsAsync({ payload: { body, tabData, key, history } }) {
  try {
    yield put(globalActions.showSpinner());
    const { prodStatusID, previousProductionStatus } = body;
    const response = yield personsApi.savePersonDetails(body);
    const { state, data, messages } = response.data;
    if (state) {
      yield put(personActions.savePersonDetailsSuccess({ data, key }));
      if (body.updateType && body.updateType === updateTypeOnProdStatusSave.person) {
        yield put(globalActions.showAlert(getProdMessage(prodStatusID, previousProductionStatus)));
        // history &&
        //   setTimeout(() => {
        //     history.push(`/person`);
        //   }, 2000);
      } else {
        yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_SAVE));
      }
      if (tabData) {
        yield delay(2000);
        yield put({ type: personActions.personNextFeatureSelection.type, payload: tabData.value });
      }
    } else {
      yield put(
        personActions.setShowPersonValidationModal({
          isOpen: true,
          messages,
        }),
      );
      yield put(globalActions.hideSpinner());
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(personActions.setError());
  }
}

export function* getPersonStampingDetailsAsync({ payload: { body } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield personsApi.savePersonDetails(body);
    const { state, data } = response.data;
    if (state) {
      yield put(personActions.getPersonStampingDetailsSuccess({ data }));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(personActions.setError());
  }
}

export function* getQueueRecordsAsync({ payload: { queueDetails, isQueue } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = isQueue
      ? yield queuesApi.getPersonQueueRecords(queueDetails)
      : yield taskApi.getTaskPersonQueueRecords(queueDetails);

    const {
      data: { state, data },
    } = response;
    if (state) {
      isQueue
        ? yield put(personActions.getQueueRecordsSuccess(data))
        : yield put(personActions.getTaskQueueRecordsSuccess(data));
      yield put(globalActions.hideSpinner());
    } else {
      isQueue
        ? yield put(personActions.getQueueRecordsSuccess([]))
        : yield put(personActions.getTaskQueueRecordsSuccess([]));
      yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    isQueue
      ? yield put(personActions.getQueueRecordsSuccess([]))
      : yield put(personActions.getTaskQueueRecordsSuccess([]));
    yield put(personActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
  }
}

// export function* getTaskQueueRecordsAsync({ payload: { queueDetails } }) {
//   try {
//     yield put(globalActions.showSpinner());
//     const response = yield taskApi.getTaskPersonQueueRecords(queueDetails);
//     const {
//       data: { state, data },
//     } = response;
//     if (state) {
//       yield put(personActions.getTaskQueueRecordsSuccess(data));
//       yield put(globalActions.hideSpinner());
//     } else {
//       yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
//       yield put(personActions.getTaskQueueRecordsSuccess([]));
//       yield put(globalActions.hideSpinner());
//     }
//   } catch (err) {
//     yield put(personActions.getTaskQueueRecordsSuccess([]));
//     yield put(personActions.setError(err));
//     yield put(globalActions.hideSpinner());
//     yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
//   }
// }

export function* getQueueTypesAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield queuesApi.getQueueTypes(payload);
    yield put(globalActions.hideSpinner());
    const {
      data: { state, data },
    } = response;
    if (state) {
      yield put(personActions.getQueueTypesSuccess({ data }));
      // yield put(personActions.setSelectedQueueType(data[0]));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
    }
  } catch (err) {
    yield put(personActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
  }
}

export function* releasePersonUserLockAsync({ payload }) {
  try {
    yield personsApi.unlockUserRecords(payload);
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
  }
}

export function* getQueueDashboardDataAsync({
  payload: { entityId, listPostParam, assigneeUserGroup },
}) {
  try {
    yield put(globalActions.showSpinner());

    const actions = {
      queueTypesAction: queuesApi.getQueueTypes(entityId),
      taskQueueActions: taskApi.getTaskQueueTypes(entityId),
      personListAction: personsApi.getPersonList(listPostParam),
      taskMasterActions: taskApi.getTaskMaster(),
      assigneeUserGroupListActions: taskApi.assigneeUserGroupList(assigneeUserGroup),
    };

    const {
      queueTypesAction,
      taskQueueActions,
      personListAction,
      taskMasterActions,
      assigneeUserGroupListActions,
    } = yield all(actions);

    if (queueTypesAction) {
      const {
        data: { state, data },
      } = queueTypesAction;

      if (state) {
        yield put(personActions.getQueueTypesSuccess({ data }));
      }
    }
    if (taskQueueActions) {
      const {
        data: { state, data },
      } = taskQueueActions;

      if (state) {
        yield put(personActions.getTaskQueueTypesSuccess({ data }));
      }
    }
    if (personListAction) {
      const {
        data: { state, data },
      } = personListAction;

      if (state) {
        yield put(personActions.getPersonListSuccess(data));
      }
    }
    if (taskMasterActions) {
      const {
        data: { data, state },
      } = taskMasterActions;

      if (state) {
        yield put(TaskActions.getTaskMasterSuccess({ data }));
      }
    }
    if (assigneeUserGroupListActions) {
      const {
        data: { data, state },
      } = assigneeUserGroupListActions;

      if (state) {
        yield put(TaskActions.assigneeUserGroupListSuccess({ data }));
      }
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* getPersonAssociationAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield personsApi.getPersonAssociation(payload);
    const { state, data } = response.data;
    if (state) {
      yield put({ type: personActions.getPersonAssociationSuccess.type, payload: data });
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put({ type: personActions.setError.type, payload: err });
  }
}

export function* getPersonOrgAssociationAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield personsApi.getPersonOrgAssociation(payload);
    const { state, data } = response.data;
    if (state) {
      yield put({ type: personActions.getPersonOrgAssociationSuccess.type, payload: data });
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put({ type: personActions.setError.type, payload: err });
  }
}

export function* postExitQueueAysnc({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { queueTransactionId } = payload;

    const postExitQueueResponse = yield queuesApi.postExitQueue({ id: queueTransactionId });
    const {
      data: { state, data },
    } = postExitQueueResponse;

    if (state && data) {
      const modalData = {
        isOpen: true,
        message: 'RECORD EXIT DONE SUCCESSFULLY',
        modalType: MODAL_TYPES.SUCCESS,
      };
      yield put({ type: globalActions.showModal.type, payload: modalData });
      yield put(personActions.getQueueTypesAndRecords(payload));
    } else {
      yield put(globalActions.hideSpinner());
      yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
    }
  } catch (err) {
    yield put(personActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
  }
}
export function* searchPersonNoteTypeAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield personsApi.searchPersonNoteType(payload);

    const { state, data } = response.data;
    if (state) {
      yield put(personActions.searchPersonNoteTypeSuccess({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(personActions.setError());
  }
}

export function* getPersonProximityDataAsync({ payload: { ingestedId, duplicateIds } }) {
  try {
    yield put(globalActions.showSpinner());
    const [ingestedDetails, duplicateDetails] = yield all([
      personsApi.getIngestedData(ingestedId),
      personsApi.getPersonDetails(duplicateIds[0]),
    ]);
    if (ingestedDetails && duplicateDetails) {
      const { data: ingestedData, state: ingestedState } = ingestedDetails.data;
      const { data: duplicatesData, state: duplicatesState } = duplicateDetails.data;
      if (ingestedState && duplicatesState) {
        yield put(
          personActions.getPersonProximitySuccess({
            ingestedRecordData: ingestedData,
            possibleDuplicatesData: [{ ...duplicatesData }],
          }),
        );
      } else {
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: 'Something went wrong',
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Something went wrong',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
    yield put(globalActions.hideSpinner());
  }
}

export function* getQueueTypesAndRecordsAsync({ payload }) {
  try {
    const { queueType, entityId, rowCount, listPostParam, pageIndex, pageSize } = payload;

    const postQueueTypesResponse = yield queuesApi.getQueueTypes(entityId);
    const {
      data: { state, data },
    } = postQueueTypesResponse;

    if (state) {
      yield put(personActions.getQueueTypesSuccess({ data }));
      if (rowCount === 1) {
        yield put(personActions.setQueueExpandedList([]));
        const response = yield personsApi.getPersonList({ payload: listPostParam });
        const { state, data, messages } = response.data;
        if (state) {
          yield put(personActions.setSelectedQueueType(queueType));
          yield put(personActions.getPersonListSuccess(data));
        } else {
          yield put(
            globalActions.showModal({
              isOpen: true,
              message: getApiErrorMessage(messages),
              modalType: MODAL_TYPES.ERROR,
            }),
          );
        }
        yield put(globalActions.hideSpinner());
      } else {
        const { id, parentId, rootKey } = { ...queueType };
        const queueDetails = queueType.lpSection
          ? { groupKey: id, rootKey, pageIndex, pageSize }
          : { queueId: id, groupKey: parentId, rootKey, pageIndex, pageSize };
        yield put(personActions.getQueueRecords({ queueDetails }));
        yield put(personActions.setSelectedQueueType(queueType));
      }
    } else {
      yield put(globalActions.hideSpinner());
      yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
    }
  } catch (err) {
    yield put(personActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.PERSONS_ERROR));
  }
}

export function* getPersonCompareDataAsync({ payload: { selectedIds, isIngestedId } }) {
  try {
    yield put(globalActions.showSpinner());
    const selectedQueueAPI =
      isIngestedId === true ? personsApi.getIngestedData : personsApi.getPersonDetails;

    const [idOneDetails, idTwoDetails, idThreeDetails, idFourDetails] = yield all([
      selectedQueueAPI(selectedIds[0]),
      selectedQueueAPI(selectedIds[1]),
      selectedIds[2] ? selectedQueueAPI(selectedIds[2]) : '',
      selectedIds[3] ? selectedQueueAPI(selectedIds[3]) : '',
    ]);
    if (idOneDetails && idTwoDetails && idThreeDetails && idFourDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      const { data: idThreeData, state: idThreeState } = idThreeDetails.data;
      const { data: idFourData, state: idFourState } = idFourDetails.data;
      if (idOneState && idTwoState && idThreeState && idFourState) {
        yield put(
          personActions.getPersonCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
            compareIdThree: idThreeData,
            compareIdFour: idFourData,
          }),
        );
      }
    } else if (idOneDetails && idTwoDetails && idThreeDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      const { data: idThreeData, state: idThreeState } = idThreeDetails.data;
      if (idOneState && idTwoState && idThreeState) {
        yield put(
          personActions.getPersonCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
            compareIdThree: idThreeData,
          }),
        );
      }
    } else if (idOneDetails && idTwoDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      if (idOneState && idTwoState) {
        yield put(
          personActions.getPersonCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
          }),
        );
      }
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Something went wrong',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
    yield put(globalActions.hideSpinner());
  }
}

export const watchers = [
  function* watchGetPersonList() {
    yield takeEvery(personActions.getPersonList, getPersonListAsync);
  },
  function* watchDeletePersonAsync() {
    yield takeEvery(personActions.deletePerson, deletePersonAsync);
  },
  function* watchMultipleDeletePersonAsync() {
    yield takeEvery(personActions.deleteMultiplePerson, deleteMultiplePersonAsync);
  },
  function* watchUpdatePersonLockDetailsAsync() {
    yield takeEvery(personActions.updatePersonLockDetails, updatePersonLockDetails);
  },
  function* watchGetPersonDetailsAsync() {
    yield takeEvery(personActions.getPersonDetails, getPersonDetailsAsync);
  },
  function* watchSavePersonDetailsAsync() {
    yield takeEvery(personActions.savePersonDetails, savePersonDetailsAsync);
  },
  function* watchGetPersonStampingDetailsAsync() {
    yield takeEvery(personActions.getPersonStampingDetails, getPersonStampingDetailsAsync);
  },
  function* watchGetPersonMasterDataAsync() {
    yield takeEvery(personActions.getPersonMasterData, getPersonMasterDataAsync);
  },
  function* watchGetQueueRecords() {
    yield takeEvery(personActions.getQueueRecords, getQueueRecordsAsync);
  },
  function* watchGetTaskQueueRecords() {
    yield takeEvery(personActions.getTaskQueueRecords, getQueueRecordsAsync);
  },
  function* watchGetQueueTypes() {
    yield takeEvery(personActions.getQueueTypes, getQueueTypesAsync);
  },
  function* watchReleasePersonUserLockAsync() {
    yield takeEvery(personActions.releasePersonUserLock, releasePersonUserLockAsync);
  },
  function* watchGetQueueDashboardData() {
    yield takeEvery(personActions.getQueueDashboardData, getQueueDashboardDataAsync);
  },

  function* watchGetPersonAssociationAsync() {
    yield takeEvery(personActions.getPersonAssociation, getPersonAssociationAsync);
  },
  function* watchGetPersonOrgAssociationAsync() {
    yield takeEvery(personActions.getPersonOrgAssociation, getPersonOrgAssociationAsync);
  },
  function* watchPostExitQueueRecords() {
    yield takeEvery(personActions.exitQueue, postExitQueueAysnc);
  },
  function* watchsearchPersonNoteTypeAsync() {
    yield takeEvery(personActions.searchPersonNoteType, searchPersonNoteTypeAsync);
  },

  function* watchPersonProximityDataAsync() {
    yield takeEvery(personActions.getPersonProximityData, getPersonProximityDataAsync);
  },
  function* watchGetQueueTypesAndRecordsAsync() {
    yield takeEvery(personActions.getQueueTypesAndRecords, getQueueTypesAndRecordsAsync);
  },

  function* watchPersonCompareDataAsync() {
    yield takeEvery(personActions.getPersonCompareData, getPersonCompareDataAsync);
  },
];
