import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
// import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService'

import './index.scss';

const DNADataGrid = ({
  styleName,
  height,
  showStickyHeader,
  defaultColDef,
  isHorizontalScrollBar,
  handleSortChanged,
  ...props
}) => {
  const customScroller = useRef(null);
  const scrollRef = useRef(null);
  const [isSticky, setSticky] = useState(false);
  const [gridWidth, setGridWidth] = useState(0);

  useEffect(() => {
    if (showStickyHeader) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (showStickyHeader) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const ColumnVisibleEvent = params => {
    const ele = document.getElementsByClassName('ag-center-cols-viewport');
    const elementWidth = ele[0].scrollWidth;
    document.getElementsByClassName('dummyEle')[0].style.width = `${150 + elementWidth}px`;
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      setSticky(window.pageYOffset >= scrollRef.current.offsetTop);
    }
  };
  const onScroll = e => {
    const scrollViewportSelector = '.ag-center-cols-viewport';
    const scrollWrapperSelector = '.customScrollbar';
    const scrollViewportElement = document.querySelector(scrollViewportSelector);
    const scrollWrapperElement = document.querySelector(scrollWrapperSelector);
    scrollViewportElement.scrollTo(
      scrollWrapperElement.scrollLeft,
      scrollWrapperElement.scrollLeft,
    );
  };
  const onBodyScroll = () => {
    const scrollViewportSelector = '.ag-center-cols-viewport';
    const scrollWrapperSelector = '.customScrollbar';
    const scrollViewportElement = document.querySelector(scrollViewportSelector);
    const scrollWrapperElement = document.querySelector(scrollWrapperSelector);
    scrollWrapperElement.scrollTo(
      scrollViewportElement.scrollLeft,
      scrollViewportElement.scrollLeft,
    );
  };
  return (
    <div className={`${styleName} ${isSticky ? 'stickyVisible' : ''}`} ref={scrollRef}>
      {isHorizontalScrollBar && (
        <div class="customScrollbar" onScroll={onScroll} ref={customScroller}>
          <div class="dummyEle"></div>
        </div>
      )}
      <div
        className="ag-theme-balham"
        style={{
          height,
          width: '100%',
          display: 'inline-block',
        }}
      >
        <AgGridReact
          suppressDragLeaveHidesColumns
          enableCellTextSelection
          accentedSort
          suppressScrollOnNewData
          suppressColumnVirtualisation
          suppressRowClickSelection
          //suppressHorizontalScroll={Boolean(props?.rowData?.length)} //supress .ag-body-horizontal-scroll
          overlayNoRowsTemplate="No Data Available"
          defaultColDef={{
            ...defaultColDef,
            lockPinned: true, //not to pin the col on dragging
          }}
          onColumnVisible={ColumnVisibleEvent}
          onGridSizeChanged={params => {
            if (params && params.clientWidth && gridWidth === 0) {
              setGridWidth(params.clientWidth);
            } else if (gridWidth !== 0 && params.clientWidth !== gridWidth) {
              params.api.sizeColumnsToFit();
              setGridWidth(params.clientWidth);
            }
          }}
          onModelUpdated={params => {
            params.api.sizeColumnsToFit();
          }}
          {...props}
          tooltipShowDelay={0}
          // rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
          onSortChanged={handleSortChanged}
          onBodyScroll={isHorizontalScrollBar && onBodyScroll}
        ></AgGridReact>
      </div>
    </div>
  );
};

DNADataGrid.propTypes = {
  columnDefs: PropTypes.array,
  rowData: PropTypes.array,
  headerHeight: PropTypes.number,
  styleName: PropTypes.string,
  height: PropTypes.string,
  showStickyHeader: PropTypes.bool,
  defaultColDef: PropTypes.object,
  rowHeight: PropTypes.number,
  handleSortChanged: PropTypes.func,
};

DNADataGrid.defaultProps = {
  columnDefs: [],
  rowData: [],
  styleName: 'basic-table',
  height: '100%',
  showStickyHeader: false,
  defaultColDef: {},
  headerHeight: 60,
  rowHeight: 65,
};

export default DNADataGrid;
