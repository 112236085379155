import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import InputLabel from '../inputs/InputLabel';
import InputCheckbox from '../inputs/InputCheckbox';
import { uniqBy } from 'lodash';
import './index.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getClassNameForIngestion } from '../../utils/generic/helper';
import IngestionActions from '../ingestionActions';

const CountryMultiselectDropdown = ({
  isModal,
  label,
  data,
  handleChange,
  isSearchable,
  isMandatory,
  error,
  helperText,
  isDisabled,
  placeholder,
  showDefaultSelectetValues,
  masterData,
  name,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(
    data.filter(d => {
      return d.checked;
    }),
  );
  const formOptionsData = (regions, countries, worldWide) => {
    return [
      {
        label: '',
        options: [
          {
            label: 'Worldwide',
            id: 'worldwide',
            countryName: '',
            countryGroup: null,
            checked: worldWide,
          },
        ],
      },
      {
        label: 'All countries in:',
        options: [...regions],
      },
      {
        label: '',
        options: [...countries],
      },
    ];
  };
  const regions = data.filter(opt => opt.countryGroup === null);
  const countries = data.filter(c => c.countryGroup !== null);
  countries.unshift({
    label: 'Select all',
    id: '',
    countryName: '',
    countryGroup: null,
    checked: false,
  });
  const options = formOptionsData(regions, countries, false);

  const [inputValue, updateInput] = useState('');
  const [multiSelectOptions, updateOptions] = useState([...options]);

  useEffect(() => {
    updateOptions([...options]);
  }, [data]);

  const updateIndividualValues = (currentData, { id, isChecked }) => {
    return currentData.map(m => {
      if (m.id === id) {
        return {
          ...m,
          checked: isChecked,
        };
      }
      return { ...m };
    });
  };

  const updateAllValues = (values, isChecked) => {
    return values.map(d => {
      return {
        ...d,
        checked: isChecked,
      };
    });
  };

  const updateCountriesForRegion = (currentData, { id, isChecked }) => {
    return currentData.map(item => {
      const isRegionMatched = item.countryGroup
        ? item.countryGroup.some(ctyGrp => ctyGrp.id === id)
        : false;
      if (isRegionMatched) {
        return {
          ...item,
          checked: isChecked,
        };
      }
      return item;
    });
  };

  const getOptionsData = result => {
    const { id, isChecked, countryGroup } = result;
    let currentRegions = [...multiSelectOptions[1].options];
    let currentCountries = [...multiSelectOptions[2].options];
    let worldWide = multiSelectOptions[0].options[0].checked;
    if (id) {
      if (id === 'worldwide') {
        worldWide = isChecked;
        currentCountries = updateAllValues(multiSelectOptions[2].options, isChecked);
        currentRegions = updateAllValues(multiSelectOptions[1].options, isChecked);
      } else if (countryGroup === null) {
        currentRegions = updateIndividualValues(multiSelectOptions[1].options, result);
        currentCountries = updateCountriesForRegion(multiSelectOptions[2].options, result);
      } else {
        currentCountries = updateIndividualValues(multiSelectOptions[2].options, result);
      }
    } else {
      currentCountries = updateAllValues(multiSelectOptions[2].options, isChecked);
      currentRegions = isChecked
        ? currentRegions
        : updateAllValues(multiSelectOptions[1].options, isChecked);
    }
    return { currentRegions, currentCountries, worldWide };
  };

  const onChange = result => {
    const { currentRegions, currentCountries, worldWide } = getOptionsData(result);
    updateOptions(formOptionsData(currentRegions, currentCountries, worldWide));
  };

  const onInputChange = (value, { action }) => {
    if (action === 'input-change') {
      updateInput(value);
    }
  };

  const filterOptions = (result, input) => {
    if (input && input.trim()) {
      const { label: optionLabel } = result;
      if (
        optionLabel
          ?.toLowerCase()
          ?.trim()
          ?.startsWith(input.toLowerCase().trim())
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const onBlur = (e, multiSelectOptionsUpdated) => {
    let multiSelectOptionsCopy = [];
    if (multiSelectOptionsUpdated) {
      multiSelectOptionsCopy = [...multiSelectOptionsUpdated[2].options];
    }
    updateInput('');

    let selectedCountriesList = [];
    // convert it to single selected list
    if (multiSelectOptions.length > 0) {
      const countryList = multiSelectOptionsCopy.length
        ? [...multiSelectOptionsCopy]
        : [...multiSelectOptions[2].options];
      if (countryList[0]?.checked) {
        selectedCountriesList = [...countryList];
        selectedCountriesList.splice(0, 1);
      } else {
        const selectedRegions = multiSelectOptionsCopy.length
          ? multiSelectOptionsCopy.filter(region => region.checked)
          : multiSelectOptions[1].options.filter(region => region.checked);
        let newCountries = [];
        if (selectedRegions.length > 0) {
          selectedRegions.forEach(region => {
            newCountries = [
              ...newCountries,
              ...countryList.filter(country => {
                return country.countryGroup
                  ? country.countryGroup.filter(ctyGrp => ctyGrp.id === region.id) > 0
                  : false;
              }),
            ];
          });
        }
        countryList.forEach(country => {
          const isCountryExists =
            newCountries.filter(newCountry => newCountry.id === country.id).length > 0;
          if (!isCountryExists && country.checked) {
            newCountries = [...newCountries, country];
          }
        });
        selectedCountriesList = uniqBy([...newCountries], 'id');
      }
    }
    if (label === 'EVENT COUNTRY' && isModal) {
      setSelectedData(selectedCountriesList);
    }
    handleChange(selectedCountriesList);
  };

  const Option = props => {
    const {
      data: { id, label: optionLabel, countryName, checked, countryGroup },
    } = props;
    return (
      <components.Option {...props}>
        <div
          className={checked ? 'selected-option' : ''}
          onClick={() => onChange({ id, isChecked: !checked, countryGroup, countryName })}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
        >
          <InputCheckbox
            id={`${id}-${countryName}`}
            label=""
            value={countryName}
            checked={checked}
          />
          <label className="option-label">{optionLabel}</label>
        </div>
      </components.Option>
    );
  };

  const handleCustomSelect = ({ id }) => {
    const updated = selectedData.filter(u => u.id !== id);
    if (multiSelectOptions.length > 0) {
      for (let i = 0; i < multiSelectOptions[2].options.length; i++) {
        if (multiSelectOptions[2].options[i].id === id) {
          multiSelectOptions[2].options[i].checked = false;
        }
      }
    }
    setSelectedData(updated);
    handleChange(updated);
  };

  const getListOfDPLMasterData = () => {
    return (
      <ul>
        {masterData.length ? (
          [...masterData].map((item, index) => (
            <li
              style={{ cursor: 'pointer' }}
              onClick={() => onItemClick(item?.displayValue)}
            >{`${index + 1}. ${item?.displayValue}`}</li>
          ))
        ) : (
          <li>No results found</li>
        )}
      </ul>
    );
  };

  const onItemClick = itemName => {
    let k = '';
    data.forEach(item => {
      if (item.displayValue == itemName) {
        k = item.id;
      }
    });

    let multiSelectOptionsCopy = JSON.parse(JSON.stringify(multiSelectOptions));
    multiSelectOptionsCopy[2].options = updateIndividualValues(
      [...multiSelectOptionsCopy[2].options],
      { id: k, isChecked: true },
    );
    updateOptions(multiSelectOptionsCopy);
    onBlur({}, multiSelectOptionsCopy);
  };

  return (
    <>
      <InputLabel labelFor="selectLabel" text={label} isMandatory={isMandatory} size="16" />
      <Select
        className={`multiselect-container ${error ? 'error-control' : ''}`}
        classNamePrefix="multiselect"
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        options={multiSelectOptions}
        components={{ Option }}
        isMulti
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        isSearchable={isSearchable}
        placeholder={placeholder ? placeholder : 'Select Country Name...'}
        inputValue={inputValue}
        onInputChange={(value, event) => onInputChange(value, event)}
        filterOption={filterOptions}
        onBlur={onBlur}
        controlShouldRenderValue={false}
        isDisabled={isDisabled}
      />
      {error && <p className="error-text">{helperText}</p>}
      {label === 'EVENT COUNTRY' && isModal && (
        <div
          className={`selected-container ${
            isMenuOpen ? 'custom-menuList-opened' : 'custom-menuList-closed'
          }`}
        >
          {selectedData.map(item => {
            return (
              <IngestionActions
                isDisabled={false}
                ingestionAction={item.ingestionAction}
                key={`${item.id}${item.value}`}
              >
                <div className="selected-container__selected-value">
                  <span className={getClassNameForIngestion(item.ingestionAction)}>
                    {item.label}
                  </span>
                  <button
                    type="button"
                    onClick={() =>
                      handleCustomSelect({
                        id: item.id,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faTimes} className="close-icon" />
                  </button>
                </div>
              </IngestionActions>
            );
          })}
        </div>
      )}
      {showDefaultSelectetValues && masterData ? (
        <div className="disease-container-body">{getListOfDPLMasterData()}</div>
      ) : null}
    </>
  );
};

CountryMultiselectDropdown.propTypes = {
  data: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isMandatory: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

CountryMultiselectDropdown.defaultProps = {
  data: [],
  label: '',
  helperText: '',
  error: false,
  isDisabled: false,
  isMandatory: false,
  handleChange: () => {},
};

export default CountryMultiselectDropdown;
