import { all } from 'redux-saga/effects';

import { watchers as drugs } from './drugs';
import { watchers as ancillary } from './ancillary';
import { watchers as trials } from './trials';
import { watchers as administrator } from './administrator';
import { watchers as organization } from './organization';
import { watchers as person } from './person';
import { watchers as company } from './company';
import { watchers as global } from './global';
import { watchers as auth } from './auth';
import { watchers as task } from './task';
import { watchers as advancedSearch } from './advancedSearch';
import {watchers as reporting} from './reporting';
import { watchers as sourceDocument } from './sourceDocument';
import { watchers as registry } from './registry';
import { watchers as country } from './country';
import { watchers as requirement } from './requirement';
const transform = watchers => watchers.map(watcher => watcher());

export function* rootSaga() {
  yield all([
    ...transform(auth),
    ...transform(drugs),
    ...transform(ancillary),
    ...transform(trials),
    ...transform(administrator),
    ...transform(organization),
    ...transform(person),
    ...transform(company),
    ...transform(global),
    ...transform(task),
    ...transform(advancedSearch),
    ...transform(reporting),
    ...transform(sourceDocument),
    ...transform(registry),
    ...transform(country),
    ...transform(requirement),
  ]);
}
