/* eslint-disable generator-star-spacing */
import { takeEvery, put, all } from 'redux-saga/effects';

import * as globalActions from '../actions/globalActions';
import * as CompanyActions from '../actions/company';
import * as DrugActions from '../actions/drugs';
import * as TrialActions from '../actions/clinicalTrials';
import * as PersonActions from '../actions/person';
import * as OrganizationActions from '../actions/organization';
import {
  task as taskApi,
  globalAudit as globalApi,
  queues as queuesApi,
  registry as registryApi,
  country as countryApi,
  requirement as requirementApi,
  sourceDocument as sourceDocumentApi,
} from '../../api';
import { getApiErrorMessage } from '../../utils/generic/helper';
import {
  MODAL_TYPES,
  SEARCH_OPERATOR,
  SEARCH_CONDITION,
  ALERT_MESSAGES,
} from '../../utils/generic/constants';

import axios from 'axios';

// TO DO - Remove this once url validation implemented in API side
export function* validateSupportingURLsAsync(payload) {
  try {
    let urlList = [];

    payload.forEach(({ url, status }) => {
      if (status.value === 'Active') {
        const getApi = axios.get(
          url.includes('https') || url.includes('http') ? url : `https://${url}`,
        );
        const getCall = getApi.catch(e => e);
        urlList.push(getCall);
      }
    });

    const [...response] = yield all(urlList);

    return response;
  } catch (err) {}
}

export function* assigneeGroupListAsync({ payload }) {
  const {
    assignee,
    queueTransactionIds,
    queueTypeSelected,
    selectedType,
    pageIndex,
    pageSize,
  } = payload;
  const { id, parentId, rootKey } = { ...queueTypeSelected };

  try {
    yield put({ type: globalActions.showSpinner.type });
    const response = yield taskApi.postAssigneeGroupDetails({ assignee, queueTransactionIds });
    yield put({ type: globalActions.hideSpinner.type });
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        modalData = {
          isOpen: true,
          message: getApiErrorMessage(respData.messages),
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: 'Assigned successfully',
          modalType: MODAL_TYPES.SUCCESS,
        };
      }
      yield put({ type: globalActions.showModal.type, payload: modalData });
      switch (selectedType) {
        case 'drugs':
          yield put(DrugActions.getQueueTypesAndRecords(payload));
          break;
        case 'trials':
          yield put(TrialActions.getQueueTypesAndRecords(payload));
          break;
        case 'company':
          yield put(CompanyActions.getQueueTypesAndRecords(payload));
          break;
        case 'person':
          yield put(PersonActions.getQueueTypesAndRecords(payload));
          break;
        case 'organization':
          yield put(OrganizationActions.getQueueTypesAndRecords(payload));
          break;
        default:
          break;
      }
    }
  } catch (err) {
    yield put({ type: globalActions.hideSpinner.type });
  }
}

export function* getUserGroupsDataAsync() {
  try {
    yield globalActions.showSpinner();
    const response = yield taskApi.getUserGroupsList();
    const {
      data: { data: userGroupList, state: userGroupListState },
    } = response;
    if (userGroupListState) {
      yield put(globalActions.setUserGroupsData({ data: userGroupList, field: 'userGroupsList' }));
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Something went wrong',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* fetchlatestCrossTeamCommentAsync({ payload: { recordId, queueId } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield taskApi.getCrossTeamRecordLatestComment(recordId, queueId);
    const {
      data: { data, state },
    } = response;
    if (state) {
      yield put(globalActions.setUserGroupsData({ data, field: 'latestComment' }));
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Unable to fetch latest comment',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
    yield put(globalActions.hideSpinner());
  }
}

export function* postCrossTeamAlertAsync({ payload: { postAlertData } }) {
  try {
    yield globalActions.showSpinner();
    const response = yield taskApi.postCrossAlertData(postAlertData);
    const {
      data: { state },
    } = response;
    if (state) {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Cross Team Alert sent successfully',
          modalType: MODAL_TYPES.SUCCESS,
        }),
      );
    } else {
      globalActions.showModal({
        isOpen: true,
        message: 'Something went wrong!',
        modalType: MODAL_TYPES.ERROR,
      });
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* fetchAncillaryRequestsAsync() {
  try {
    yield put(globalActions.showSpinner());
    const response = yield taskApi.getAncillaryRequestsData();
    const {
      data: { data, state },
    } = response;
    if (state) {
      yield put(globalActions.setAncillaryRequestsData(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* postAdditionalWorkFlowAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield taskApi.postAncillaryWorkFlowRequest(payload);
    const {
      data: { state },
    } = response;
    if (state) {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Ancillary Request sent successfully',
          modalType: MODAL_TYPES.SUCCESS,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* updateAncillaryRequestStatusAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield taskApi.updateAncillaryRequestStatus(payload);
    const {
      data: { state },
    } = response;
    if (state) {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Ancillary Request updated successfully',
          modalType: MODAL_TYPES.SUCCESS,
        }),
      );
      yield put(globalActions.fetchAncillaryRequests());
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Something went wrong',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}
export function* getAuditDataAsync({
  payload: {
    key,
    id,
    selectSearchCriteria,
    pagination,
    screen,
    isHistoricalDataNotExists,
    dateSearchCriteria,
    sortOrder,
  },
}) {
  try {
    yield put(globalActions.showSpinner());
    let req = {
      ...pagination,
      searchCriteria: [
        {
          columnName: 'EntityPrimaryKey',
          operator: SEARCH_OPERATOR.EQUALS,
          searchValue: id,
          condition: SEARCH_CONDITION.AND,
        },
        ...dateSearchCriteria,
      ],
      sortOrder,
      // sortOrder: [
      //   {
      //     columnName: 'Id',
      //     isDescendingOrder: true,
      //   },
      // ],
      resultOptions: {},
    };
    if (selectSearchCriteria && selectSearchCriteria.searchValue) {
      req = {
        ...req,
        searchCriteria: [...req.searchCriteria, selectSearchCriteria],
      };
    }
    let actions = {
      auditTrialDetails: globalApi.getAuditData(screen, req),
    };
    if (isHistoricalDataNotExists) {
      actions = {
        ...actions,
        historicalAuditData: globalApi.getHistoricalAuditData(screen, id),
      };
    }

    const { auditTrialDetails, historicalAuditData } = yield all(actions);

    const {
      data: { state, data, messages },
    } = auditTrialDetails;
    if (state) {
      yield put(globalActions.getAuditDataSuccess({ data, key }));
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }

    if (historicalAuditData) {
      const {
        data: {
          state: historicalAuditState,
          data: historicalAuditResults,
          messages: historicalAuditMessages,
        },
      } = historicalAuditData;
      if (historicalAuditState) {
        yield put(
          globalActions.setHistoricalAuditData({
            key,
            historicalData: historicalAuditResults || '',
          }),
        );
      } else {
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: getApiErrorMessage(historicalAuditMessages),
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    }
  } catch (err) {
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  } finally {
    yield put(globalActions.hideSpinner());
  }
}

export function* postQueueReferenceRecordAysnc({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const postQueueReferenceResponse = yield queuesApi.postQueueReferenceRecord(payload);
    const {
      data: { state, data },
    } = postQueueReferenceResponse;
    yield put(globalActions.hideSpinner());
    if (!(state && data)) {
      yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
  }
}

export function* multiplePublishAsync({ payload: { list } }) {
  try {
    yield put(globalActions.showSpinner());
    const actions = [];
    list.map(obj => {
      switch (obj?.recordEntity) {
        case 'Sourcedocument':
          actions.push(sourceDocumentApi.getSDRecordChangeFieldsList(obj?.recordId));
          break;
        case 'Requirement':
          actions.push(requirementApi.getReqRecordChangeFieldsList(obj?.recordId));
          break;
        case 'Registry':
          actions.push(registryApi.getRegRecordChangeFieldsList(obj?.recordId));
          break;
        case 'Country':
          actions.push(countryApi.getCountryRecordChangeFieldsList(obj?.recordId));
          break;
        default:
          break;
      }
    });
    const responses = yield all(actions);
    let firstTimePublishActions = [];
    responses.map(obj => {
      let dataObj = obj.data;
      if (dataObj.isFirstTimePublish) {
        switch (dataObj.entityName) {
          case 'Source Document':
            firstTimePublishActions.push(
              sourceDocumentApi.saveSourceDocumentDetails({
                id: dataObj.recordId,
                forceUpdate: [3],
                sourceDocuments: null,
                productionStatus: 2,
                auditProperties: null,
                disableCancel: true,
              }),
            );
            break;
          case 'Requirement':
            firstTimePublishActions.push(
              requirementApi.saveRequirementDetails({
                id: dataObj.recordId,
                forceUpdate: [10],
                requirementName: null,
                productionStatus: 2,
                auditProperties: null,
                disableCancel: true,
              }),
            );
            break;
          case 'Registry':
            firstTimePublishActions.push(
              registryApi.saveRegistryDetails({
                id: dataObj.recordId,
                forceUpdate: [9],
                productionStatus: 2,
                auditProperties: null,
                disableCancel: true,
              }),
            );
            break;
          case 'Country':
            firstTimePublishActions.push(
              countryApi.saveCountryDetails({
                id: dataObj.recordId,
                forceUpdate: [3],
                countryName: null,
                productionStatus: 2,
                auditProperties: null,
                disableCancel: true,
              }),
            );
            break;
          default:
            break;
        }
      }
    });
    if (firstTimePublishActions.length) {
      const firstTimePublishResponse = yield all(firstTimePublishActions);
      const flag = firstTimePublishResponse?.some(res => !res?.data?.state);
      if (!flag) {
        yield put(globalActions.showAlert(ALERT_MESSAGES.RECORDS_PUBLISHED));
      }
    }
    let changedFieldList = responses
      ?.filter(obj => !obj?.data?.isFirstTimePublish)
      ?.map(obj => obj?.data);
    if (changedFieldList?.length)
      yield put(globalActions.multiplePublishChangeFieldsSuccess(changedFieldList));
    else {
      window.location.reload();
    }

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* multipleChangeFieldPublishAsync({ payload: { list } }) {
  try {
    yield put(globalActions.showSpinner());
    let publishActions = [];
    list.map(dataObj => {
      const { entityName, id, auditProperties } = dataObj;
      switch (entityName) {
        case 'Source Document':
          publishActions.push(
            sourceDocumentApi.saveSourceDocumentDetails({
              id: id,
              forceUpdate: [3],
              sourceDocuments: null,
              productionStatus: 2,
              auditProperties: auditProperties,
              disableCancel: true,
            }),
          );
          break;
        case 'Requirement':
          publishActions.push(
            requirementApi.saveRequirementDetails({
              id: id,
              forceUpdate: [10],
              requirementName: null,
              productionStatus: 2,
              auditProperties: auditProperties,
              disableCancel: true,
            }),
          );
          break;
        case 'Registry':
          publishActions.push(
            registryApi.saveRegistryDetails({
              id: id,
              forceUpdate: [9],
              productionStatus: 2,
              auditProperties: auditProperties,
              disableCancel: true,
            }),
          );
          break;
        case 'Country':
          publishActions.push(
            countryApi.saveCountryDetails({
              id: id,
              forceUpdate: [3],
              countryName: null,
              productionStatus: 2,
              auditProperties: auditProperties,
              disableCancel: true,
            }),
          );
          break;
        default:
          break;
      }
    });
    if (publishActions.length) {
      const publishResponse = yield all(publishActions);
      //success message for publish
      const flag = publishResponse?.some(res => !res?.data?.state);
      if (!flag) {
        yield put(globalActions.showAlert(ALERT_MESSAGES.RECORDS_PUBLISHED));
      }
      const delay = ms => new Promise(res => setTimeout(res, ms));

      yield delay(2000);
      yield put(globalActions.multiplePublishChangeFieldsSuccess([]));

      window.location.href = '/dashboard';
    }

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export const watchers = [
  function* watchValidateSupportingURLs() {
    yield takeEvery(globalActions.validateSupportingURLs, validateSupportingURLsAsync);
  },
  function* watchAssigneeGroupListUpdate() {
    yield takeEvery(globalActions.postAssigneeGroupDetails, assigneeGroupListAsync);
  },
  function* watchUserGroupListGet() {
    yield takeEvery(globalActions.getUserGroupsData, getUserGroupsDataAsync);
  },
  function* watchSendCrossTeamAlert() {
    yield takeEvery(globalActions.sendCrossTeamAlert, postCrossTeamAlertAsync);
  },
  function* watchPostAdditionalWorkFlow() {
    yield takeEvery(globalActions.postAdditionalWorkFlow, postAdditionalWorkFlowAsync);
  },
  function* watchFetchAncillaryRequests() {
    yield takeEvery(globalActions.fetchAncillaryRequests, fetchAncillaryRequestsAsync);
  },
  function* watchUpdateAncillaryReqStatus() {
    yield takeEvery(globalActions.updateAncillaryRequestStatus, updateAncillaryRequestStatusAsync);
  },
  function* watchFetchLatestCrossTeamComment() {
    yield takeEvery(globalActions.fetchCrossTeamComment, fetchlatestCrossTeamCommentAsync);
  },
  function* watchGetAuditDataAsync() {
    yield takeEvery(globalActions.getAuditData, getAuditDataAsync);
  },
  function* watchPostQueueReferenceRecords() {
    yield takeEvery(globalActions.queueReferenceRecord, postQueueReferenceRecordAysnc);
  },
  function* watchMultiplePublish() {
    yield takeEvery(globalActions.multiplePublish, multiplePublishAsync);
  },
  function* watchMultipleChangeFieldPublish() {
    yield takeEvery(globalActions.multipleChangeFieldPublish, multipleChangeFieldPublishAsync);
  },
];
