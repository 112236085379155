import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import ClockIcon from '@material-ui/icons/AccessTime';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IconButton, InputAdornment } from '@material-ui/core';

import InputLabel from '../InputLabel';
import './index.scss';

const CustomTimePicker = ({
  name,
  label,
  value,
  onChange,
  isError,
  errorMessage,
  size,
  isMandatory,
  ...restInputProps
}) => {
  const [selectedTime, setSelectedTime] = useState(value);

  useEffect(() => {
    setSelectedTime(value);
  }, [value]);

  const handleTimeChange = date => {
    setSelectedTime(date);
    onChange(date);
  };

  const renderTimePicker = () => {
    return (
      <TimePicker
        clearable
        ampm={false}
        value={selectedTime}
        onChange={handleTimeChange}
        inputVariant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <ClockIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...restInputProps}
      />
    );
  };

  return (
    <div name={name} className={`width-100 time-picker-wrapper ${isError && 'error-class'}`}>
      <InputLabel text={label} size={size} isMandatory={isMandatory} labelFor="dateLabel" />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>{renderTimePicker()}</MuiPickersUtilsProvider>
      {isError && <p className="error-text">{errorMessage}</p>}
    </div>
  );
};

CustomTimePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date).isRequired], null),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  size: PropTypes.string,
  isMandatory: PropTypes.bool,
};
CustomTimePicker.defaultProps = {
  isError: false,
  errorMessage: '',
  size: '16',
  isMandatory: false,
};

export default CustomTimePicker;
