import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { InputSelect } from '../../../framework/inputs';
import { TABLE_FILTER_OPTIONS } from '../../../utils/generic/constants';
import { Pagination } from '../../../framework';
import './index.scss';

const PaginationWrapper = ({
  pageInfo: { rowCount, pageIndex, pageSize, currentPage },
  onChange,
  disabled,
  children,
}) => {
  const [dropDown, setDropDown] = useState({ value: pageSize, label: `${pageSize} Results` });
  const [paginationText, setPaginationText] = useState('');

  return (
    <div className="pagination-wrapper">
      <div className="pagination-header">
        <p className="top-pagination-text">{rowCount ? paginationText : <></>}</p>
        <InputSelect
          height="ht-lg"
          defaultValue={dropDown}
          data={TABLE_FILTER_OPTIONS}
          isDisabled={disabled}
          onChange={res => {
            onChange({ pageIndex: 1, pageSize: res.value });
            setDropDown(res);
          }}
        />
      </div>
      {children}
      <div className={`pagination-footer ${disabled ? 'pagination-footer-disabled' : ''}`}>
        <Pagination
          pageSize={pageSize}
          totalRows={rowCount || 1}
          pageNo={pageIndex ? pageIndex : currentPage}
          onChange={pageNum => onChange({ pageIndex: pageNum, pageSize: dropDown.value })}
          showPaginationText={text => setPaginationText(text)}
          showBottomPaginationText={false}
        />
      </div>
    </div>
  );
};

PaginationWrapper.propTypes = {
  pageInfo: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

PaginationWrapper.defaultProps = {
  disabled: false
}

export default PaginationWrapper;
