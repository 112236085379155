import React from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import InputLabel from '../../InputLabel';

import TodayIcon from '@material-ui/icons/Today';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import InputToolTip from '../../../toolTip/inputToolTip';
import './index.scss';
import { format as formatDate } from 'date-fns';

const InputTextField = ({
  label,
  valueChange,
  forwardedRef,
  value,
  openCalendar,
  isMandatory,
  setShouldFocus,
  inputHeight,
  showToolTip,
  labelSize,
  disabled,
  onBlur,
  dateFormat,
  ...inputProps
}) => {
  const handleDateChange = e => {
    valueChange(e.target.value, e.target.selectionStart);
  };
  const className = `width-100 ${inputHeight}`;
  return (
    <>
      <div className="custom-date-picker-wrapper text-field">
        {label && (
          <InputLabel
            labelFor="inputLabel"
            text={label}
            size={labelSize}
            isMandatory={isMandatory}
          />
        )}

        <InputToolTip showToolTip={showToolTip}>
          <TextField
            style={{ background: disabled ? '#a6aab6' : '' }}
            className={className}
            {...inputProps}
            // value={value}
            // Setting the default date for catagory title items
            value={
              label === 'category Title' ? value || formatDate(new Date(), 'MMM-dd-yyyy') : value
            }
            disabled={disabled}
            onChange={handleDateChange}
            onFocus={() => setShouldFocus(true)}
            onBlur={onBlur}
            placeholder={dateFormat} //'MMM-dd-yyyy'
            inputRef={forwardedRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {value && !disabled && (
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        valueChange('', 0);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={disabled ? null : () => openCalendar(true)}>
                    <TodayIcon style={{ width: '1.24vw', color: '#ce5182' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </InputToolTip>
      </div>
    </>
  );
};

InputTextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isMandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  labelSize: PropTypes.string,
  showToolTip: PropTypes.bool,
  inputHeight: PropTypes.string,
  setShouldFocus: PropTypes.func.isRequired,
  openCalendar: PropTypes.func.isRequired,
  forwardedRef: PropTypes.func,
  valueChange: PropTypes.func,
  dateFormat: PropTypes.string,
};

InputTextField.defaultProps = {
  variant: 'outlined',
  size: 'small',
  labelSize: '16',
  onChange: () => {},
  isMandatory: false,
  inputHeight: 'ht-lg',
  showToolTip: true,
  value: '',
  dateFormat: 'MMM-dd-yyyy',
};

export default InputTextField;
