/* eslint-disable camelcase */
export const fieldTypes = {
  text: 'text',
  textArea: 'textarea',
  text_area: 'text_area',
  textAreaAdvanced: 'textAreaAdvanced',
  select: 'select',
  select_tag: 'select_tag',
  select_async: 'select_async',
  select_tree_async: 'select_tree_async',
  date: 'date',
  checkbox: 'checkbox',
  radio: 'radio',
  custom_radio: 'custom_radio',
  button: 'submit',
  customButton: 'button',
  countryMultiselect: 'countryMultiselect',
  time: 'time',
  entry: 'inputEntry',
  comboEntry: 'inputComboEntry',
  selectGroup: 'selectGroup',
  customDate: 'customDate',
  dateRange: 'dateRange',
  hyperlink: 'hyperlink',
  textRange: 'textRange',
  yearRange: 'yearRange',
  multi_select_async: 'multi_select_async',
  selectNumber: 'selectNumber',
  inputCheckboxWithRadio: 'inputCheckboxWithRadio',
  attachFile: 'attachFile',
  phoneNumberWithCountryCode: 'phoneNumberWithCountryCode',
  dndDataGrid: 'dndDataGrid',
  icons: 'icons',
  select_timezone: 'select_timezone',
  mdEditor: 'mdEditor',
};

export const VIEW_FIELD_TYPES = {
  PLAIN: 'Plain',
  TABLE: 'Table',
  NOTE: 'Note',
  LABEL: 'Label',
};
