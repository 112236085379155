import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { getUserInfo } from '../store/actions/auth';
import ProtectedRoutes from './protectedRoutes';
import AuthRoutes from './authRoutes';
import Layout from '../framework/layout';

var appEnv = process.env.REACT_APP_ENV;
const RouterComponent = () => {
  const { isAuthenticated, isAuthenticating, user } = useSelector(
    state => state.auth,
    shallowEqual,
  );
  const { selectedFeature } = useSelector(state => state.globalState, shallowEqual);
  const dispatch = useDispatch();
  if (user) {
    const USER_NAME = user;
    let userGroup = localStorage.getItem('usergroup') == 'RegIntelUser' ? 'Disclose' : 'Clinical';
    window.clarity('set', 'Name', USER_NAME.name);
    window.clarity('set', 'environment', appEnv);
    window.clarity('set', 'domain', userGroup);
  }
  const getTitle = () => {
    let title = '';
    switch (selectedFeature) {
      case 'administrator':
        return (title = 'STAR | Administrator');
        break;
      case 'clinicalTrials':
        return (title = 'STAR | Clinical Trials');
        break;
      case 'drugs':
        return (title = 'STAR | Drugs');
        break;
      case 'company':
        return (title = 'STAR | Company');
        break;
      case 'person':
        return (title = 'STAR | Person');
        break;
      case 'organization':
        return (title = 'STAR | Organization');
        break;
      case 'sponsors':
        return (title = 'STAR | Sponsors');
        break;
      case 'ancillaryData/sourceName':
        return (title = 'STAR | Ancillary Data');
        break;
      case 'task':
        return (title = 'STAR | Task');
        break;
      case 'addAncillaryData':
        return (title = 'STAR | Ancillary Request');
        break;

      case 'reporting':
        return (title = 'STAR | Reporting');
        break;
      case 'Registry':
        return (title = 'STAR | Registry');
        break;
      case 'SourceDocument':
        return (title = 'STAR | SourceDocument');
        break;
      case 'Country':
        return (title = 'STAR | Country');
        break;
      case 'Requirement':
        return (title = 'STAR | Requirement');
        break;
      case 'Dashboard':
        return (title = 'STAR | Dashboard');
        break;
      default:
        return (title = 'STAR');
        break;
    }
  };

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    document.title = getTitle();
  }, [selectedFeature, window.location.pathname]);
  //console.log('logged in user : ', user);

  return (
    <Router>
      <Layout dashboard={selectedFeature} user={user}>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {isAuthenticated ? (
              <ProtectedRoutes />
            ) : (
              <AuthRoutes isAuthenticating={isAuthenticating} />
            )}
          </Switch>
        </Suspense>
      </Layout>
    </Router>
  );
};

export default RouterComponent;
