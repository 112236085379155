import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import TabSingleTextWrapper from '../../../../common/tabSingleTextWrapper';

const Warnings = forwardRef(({ permissions: { disableEdit }, data }, ref) => {
  const {
    current: { marketing, id: drugId, ingestedId },
    original: { marketing: originalData },
    ingested: {
      marketing: { data: ingestedData, fieldActions: ingestedActions },
    },
  } = useSelector(state => state.drugs, shallowEqual);
  return (
    <TabSingleTextWrapper
      disableEdit={disableEdit}
      footerData={data}
      data={marketing}
      parentKey="marketing"
      childKey="warnings"
      drugId={drugId}
      ingestedId={ingestedId}
      originalData={originalData}
      ingestedData={ingestedData['warnings']}
      ingestionAction={ingestedActions['warnings']}
      parentIngestionAction={ingestedActions}
      ref={ref}
    />
  );
});

Warnings.propTypes = {
  permissions: PropTypes.object.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isTab: PropTypes.bool.isRequired,
    tabs: PropTypes.array.isRequired,
  }),
};

Warnings.displayName = 'Warnings';

export default Warnings;
