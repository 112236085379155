const drugIngestionFields = [
  // Drug Name
  {
    fieldId: 4001,
    fieldName: 'names',
  },
  // Drug Summary
  {
    fieldId: 4002,
    fieldName: 'globalStatus',
  },
  {
    fieldId: 4003,
    fieldName: 'devStatus', // ingestion not done for this field
  },
  {
    fieldId: 4004,
    fieldName: 'latestChangeText',
  },
  {
    fieldId: 4005,
    fieldName: 'latestChangeDatePublishOnFos', // ingestion not done for this field
  },
  // Drug Program Landscape Status
  {
    fieldId: 4006,
    fieldName: 'landscapeStatuses',
  },
  // Activity
  {
    fieldId: 4007,
    fieldName: 'mechanismOfActions',
  },
  {
    fieldId: 4008,
    fieldName: 'deliveryMediums',
  },
  {
    fieldId: 4009,
    fieldName: 'deliveryRoutes',
  },
  {
    fieldId: 4010,
    fieldName: 'deliveryTechnologies',
  },
  {
    fieldId: 4011,
    fieldName: 'biologicalTargets',
  },
  {
    fieldId: 4012,
    fieldName: 'therapeuticClasses',
  },
  {
    fieldId: 4013,
    fieldName: 'phramacokinetics',
  },
  // Drug Licensing Availablity
  {
    fieldId: 4014,
    fieldName: 'licenses',
  },
  {
    fieldId: 4015,
    fieldName: 'agreement',
  },
  {
    fieldId: 4016,
    fieldName: 'availability',
  },
  // Events
  {
    fieldId: 4017,
    fieldName: 'events',
  },
  // Chemical Data
  {
    fieldId: 4018,
    fieldName: 'origin', // ingestion not done for this field
  },
  {
    fieldId: 4019,
    fieldName: 'nce', // ingestion not done for this field
  },
  {
    fieldId: 4020,
    fieldName: 'nbe', // ingestion not done for this field
  },
  {
    fieldId: 4021,
    fieldName: 'casRegistrys', // ingestion not done for this field
  },
  {
    fieldId: 4022,
    fieldName: 'patents', // ingestion not done for this field
  },
  {
    fieldId: 4023,
    fieldName: 'formula', // ingestion not done for this field
  },
  {
    fieldId: 4024,
    fieldName: 'weight', // ingestion not done for this field
  },
  {
    fieldId: 4025,
    fieldName: 'logp', // ingestion not done for this field
  },
  {
    fieldId: 4026,
    fieldName: 'hBondDonors', // ingestion not done for this field
  },
  {
    fieldId: 4027,
    fieldName: 'hBondAcceptors', // ingestion not done for this field
  },
  {
    fieldId: 4028,
    fieldName: 'rotatableBonds', // ingestion not done for this field
  },
  {
    fieldId: 4029,
    fieldName: 'chemicalName', // ingestion not done for this field
  },
  {
    fieldId: 4030,
    fieldName: 'smilesFormula', // ingestion not done for this field
  },
  // Marketing
  {
    fieldId: 4031,
    fieldName: 'approvals',
  },
  {
    fieldId: 4032,
    fieldName: 'fillings',
  },
  {
    fieldId: 4033,
    fieldName: 'orphanDrugStatus',
  },
  {
    fieldId: 4034,
    fieldName: 'expeditedReview',
  },
  {
    fieldId: 4035,
    fieldName: 'warnings',
  },
  // Phases
  {
    fieldId: 4036,
    fieldName: 'inVivo',
  },
  {
    fieldId: 4037,
    fieldName: 'inVitro',
  },
  {
    fieldId: 4038,
    fieldName: 'patents',
  },
  {
    fieldId: 4039,
    fieldName: 'phaseI',
  },
  {
    fieldId: 4040,
    fieldName: 'phaseII',
  },
  {
    fieldId: 4041,
    fieldName: 'phaseIII',
  },
  // Urls
  {
    fieldId: 4042,
    fieldName: 'supportingUrls',
  },
  // Notes
  {
    fieldId: 4043,
    fieldName: 'notes',
  },
  // Drug Associations
  {
    fieldId: 4044,
    fieldName: 'associatedTrials', // ingestion not done for this field
  },
  {
    fieldId: 4045,
    fieldName: 'associatedCompanies', // ingestion not done for this field
  },
  // Activity
  {
    fieldId: 4046,
    fieldName: 'trialTroveDeliveryMediums',
  },
  {
    fieldId: 4047,
    fieldName: 'trialTroveDeliveryRoutes',
  },
  {
    fieldId: 4048,
    fieldName: 'trialTroveDeliveryTechnologies',
  },
];

export default drugIngestionFields;
