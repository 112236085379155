import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import './index.scss';

const InputCheckbox = ({
  id,
  value,
  label,
  checked,
  disabled,
  onChange,
  size,
  color,
  className,
  options,
  name,
}) => {
  const [data, setData] = useState(options);
  const change = ({ target }) => {
    if (options.length > 0) {
      const updatedData = data.map(data => {
        if (data?.id === Number(target?.id)) {
          return {
            ...data,
            isChecked: target?.checked,
          };
        }
        if (name.includes('voluntaryOrMandatory') || name.includes('publicOrPrivate')) {
          return {
            ...data,
            disabled: target?.checked,
          };
        }
        return data;
      });
      setData(updatedData);
      onChange({ isChecked: updatedData });
    } else {
      onChange({ id: target?.id, isChecked: target?.checked });
    }
  };

  useEffect(() => {
    setData(options);
  }, options);

  return (
    <div className="input-checkbox">
      {options.length > 0 ? (
        data.map(data => {
          return (
            <FormControlLabel
              className={className}
              control={
                <Checkbox
                  size={size}
                  id={data?.id}
                  color={color}
                  checked={Boolean(data?.isChecked)}
                  disabled={data?.disabled || disabled}
                  onChange={change}
                  value={data?.value}
                  inputProps={{ 'aria-label': { label } }}
                />
              }
              label={<div className={'group'}>{data?.value}</div>}
              key={data?.id}
            />
          );
        })
      ) : (
        <FormControlLabel
          className={className}
          control={
            <Checkbox
              size={size}
              id={id}
              color={color}
              checked={checked}
              disabled={disabled}
              onChange={change}
              value={value}
              inputProps={{ 'aria-label': { label } }}
            />
          }
          label={<div className={'single'}>{label}</div>}
        />
      )}
    </div>
  );
};

InputCheckbox.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

InputCheckbox.defaultProps = {
  id: '',
  checked: false,
  disabled: false,
  onChange: () => {},
  size: 'small',
  color: 'default',
  className: '',
  type: '',
  options: [],
};

export default InputCheckbox;
