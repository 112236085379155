import React from 'react';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';

const LinearProgressBar = ({ value, min, max }) => {
  const normalizedProgress = () => (value * 100) / (max - min);

  const BorderLinearProgress = withStyles({
    root: {
      height: 10,
      backgroundColor: lighten('#ff6c5c', 0.5),
    },
    bar: {
      borderRadius: 20,
      backgroundColor: '#ff6c5c',
    },
  })(LinearProgress);

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    margin: {
      margin: theme.spacing(1),
    },
  }));
  return (
    <div className={useStyles().root}>
      <BorderLinearProgress
        className={useStyles().margin}
        variant="determinate"
        color="secondary"
        value={normalizedProgress(value)}
      />
    </div>
  );
};
LinearProgressBar.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};
LinearProgressBar.defaultProps = {
  value: 50,
  min: 0,
  max: 100,
};
export default LinearProgressBar;
