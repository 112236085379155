const personIngestionFields = [
  // Basic & Contact Info
  {
    fieldId: 3001,
    fieldName: 'firstName',
  },
  {
    fieldId: 3002,
    fieldName: 'lastName',
  },
  {
    fieldId: 3003,
    fieldName: 'middleName',
  },
  {
    fieldId: 3004,
    fieldName: 'nameSuffix',
  },
  {
    fieldId: 3005,
    fieldName: 'personType',
  },
  {
    fieldId: 3006,
    fieldName: 'specialities',
  },
  {
    fieldId: 3007,
    fieldName: 'degrees',
  },
  {
    fieldId: 3008,
    fieldName: 'licenses',
  },
  {
    fieldId: 3009,
    fieldName: 'parentOrganization',
  },
  {
    fieldId: 3010,
    fieldName: 'phoneNumbers',
  },
  {
    fieldId: 3011,
    fieldName: 'faxNumbers',
  },
  {
    fieldId: 3012,
    fieldName: 'emails',
  },
  // Additional Info
  {
    fieldId: 3013,
    fieldName: 'regulatoryActions',
  },
  {
    fieldId: 3014,
    fieldName: 'supportingUrls',
  },
  // Notes
  {
    fieldId: 3015,
    fieldName: 'notes', // ingestion not done for this field
  },
];

export default personIngestionFields;
