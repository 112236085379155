import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';
import { setActiveCompany } from '../actions/ancillaryRequests';

const ancillaryRequest = createReducer(InitialState.ancillaryRequestsData, {
  [setActiveCompany]: (state, { payload }) => {
    state.activeCompanyData = payload;
  },
});
export default ancillaryRequest;
