import { isEqual, omit } from 'lodash';

import { fieldTypes } from '../../../../framework/formBuilder/utils';
import {
  getClassNameForIngestion,
  checkForIngestionAction,
} from '../../../../utils/generic/helper';

export const initialValues = {
  disease: null,
  company: null,
  companyParent: '',
  companyType: null,
  country: null,
  currentDevStatus: null,
  year: null,
  highestDevStatus: null,
};

export const validate = values => {
  const errors = {};
  if (!values.disease) {
    errors.disease = 'Please select the Disease';
  }
  if (!values.company) {
    errors.company = 'Please select the Company';
  }
  if (!values.companyType) {
    errors.companyType = 'Please select the Originator/Licensee';
  }
  if (!values.currentDevStatus) {
    errors.currentDevStatus = 'Please select the Current development status';
  }
  if (!values.highestDevStatus) {
    errors.highestDevStatus = 'Please select the Highest development status';
  }
  if (!values.country) {
    errors.country = 'Please select the Country';
  }

  if (values.currentDevStatus && values.highestDevStatus) {
    if (values.highestDevStatus.indicationRanking < values.currentDevStatus.indicationRanking) {
      errors.highestDevStatus =
        'Highest development status should never be lower than the current development status';
    }
  }

  return errors;
};

export const validateDPL = (landscapeStatuses, validationData) => {
  const companyType = landscapeStatuses.map(status => status.companyType.value);
  let errorMessage = '';
  // if (landscapeStatuses.length === 0 && validationData.totalCount === 0) {
  //   errorMessage = 'A drug record should have atleast one Drug Program Landscape entry.';
  // }
  if (companyType.indexOf('Originator') === -1) {
    // errorMessage =
    //   'You must add at least one row which contains a Company tagged as Originator before Saving.';
  } else {
    errorMessage = '';
  }
  return errorMessage;
};

export const validateDPLOriginator = (landscapeStatuses, validationData) => {
  let errorMessage = '';
  if (!validationData.originatorCompanyId) {
    // errorMessage =
    //   'You must add at least one row which contains a Company tagged as Originator before Saving.';
  } else {
    errorMessage = '';
  }
  return errorMessage;
};

export const isLandscapeStatusesExists = (data, { disease, company, country }) => {
  let isDataExists = false;
  data.forEach(c => {
    if (
      isEqual(c.disease, disease) &&
      isEqual(c.company.id, company.id) &&
      isEqual(c.country, country)
    ) {
      isDataExists = true;
    }
  });
  return isDataExists;
};

export const isOriginatorComapnyExists = (data, { companyType, company }) => {
  let isDataExists = false;
  const orinatorEntries = data.filter(status => status.companyType.id === 1);
  if (
    orinatorEntries.length > 0 &&
    orinatorEntries[0].company.id !== company.id &&
    companyType &&
    companyType.id === 1
  ) {
    isDataExists = true;
  }
  return isDataExists;
};

export const validateDPLAdditionMessages = (resData, landscapeStatuses, payload) => {
  let errorMessages = [];
  // if (resData && resData.length > 0) {
  //   errorMessages = [...resData.map(error => error.errorMessage)];
  // }
  // else {
  // if (isLandscapeStatusesExists(landscapeStatuses, payload)) {
  //   errorMessages.push('The combination of Disease, Company & Country already exists.');
  // }
  // if (isOriginatorComapnyExists(landscapeStatuses, payload)) {
  //   errorMessages.push('Only one Company can be an Originator per drug.');
  // }

  // let duplicates = landscapeStatuses.filter(lic => lic.country.id === payload.country['0'].id);
  // if (rowIndex > -1) {
  //   duplicates = landscapeStatuses.filter(
  //     (lic, index) => lic.country.id === payload.country.id && rowIndex !== index,
  //   );
  // }
  // if (duplicates.length > 0) {
  //   errorMessages.push('Selected Country already exists');
  // }

  return errorMessages;
};

export const layoutSchema = {
  layout: 'row',
  className: 'top-layer',
  spacing: 2,
  justify: 'flex-start',
  alignItems: 'flex-start',

  content: [
    {
      layout: 'disease',
      size: 2,
      className: 'item disease',
    },
    {
      layout: 'company',
      size: 2,
      className: 'item company-item',
    },
    {
      layout: 'companyParent',
      size: 1,
      className: 'item',
    },
    {
      layout: 'companyType',
      size: 1,
      className: 'item company-type',
    },
    {
      layout: 'country',
      size: 1,
      className: 'item country-drop-down',
    },
    {
      layout: 'currentDevStatus',
      size: 2,
      className: 'item',
    },
    {
      layout: 'year',
      size: 1,
      className: 'item',
    },
    {
      layout: 'highestDevStatus',
      size: 2,
      className: 'item',
    },
    {
      layout: 'addButton',
      size: 1,
      className: 'add-button',
    },
  ],
};

export const searchColumns = [
  {
    value: 'disease',
    label: 'DISEASE',
  },
  {
    value: 'company',
    label: 'COMPANY',
  },
  {
    value: 'originatorlicensee',
    label: 'ORIGINATOR/LICENSEE',
  },
  {
    value: 'country',
    label: 'COUNTRY',
  },
  {
    value: 'currentdevelopmentstatus',
    label: 'CURRENT DEVELOPMENT STATUS',
  },
  {
    value: 'launchyear',
    label: 'LAUNCH YEAR',
  },
  {
    value: 'highestdevelopmentstatus',
    label: 'HIGHEST DEVELOPMENT STATUS',
  },
];

export const formSchema = (handleCompanyChange, handleCurrentDevStatusChange) => ({
  disease: {
    name: 'disease',
    type: fieldTypes.select_async,
    label: 'DISEASE',
    props: {
      isMandatory: true,
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
    },
  },
  company: {
    name: 'company',
    type: fieldTypes.select_async,
    label: 'COMPANY',
    props: {
      isMandatory: true,
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      handleSelectChange: handleCompanyChange,
      dependents: [{ fieldName: 'companyParent', fieldKey: 'parentName' }],
      hasAddFeature: true,
    },
  },
  companyParent: {
    name: 'companyParent',
    type: fieldTypes.text,
    label: 'COMPANY PARENT',
    options: [],
    props: {
      disabled: true,
      inputHeight: 'ht-lg',
    },
  },
  companyType: {
    name: 'companyType',
    type: fieldTypes.select,

    label: 'ORIGINATOR/LICENSEE',
    options: [],
    props: {
      isMandatory: true,
      height: 'ht-lg',
    },
  },
  country: {
    name: 'country',
    type: fieldTypes.countryMultiselect,
    label: 'COUNTRY',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMandatory: true,
    },
  },
  currentDevStatus: {
    name: 'currentDevStatus',
    type: fieldTypes.select,
    label: 'CURRENT DEVELOPMENT STATUS',
    options: [],
    props: {
      isMandatory: true,
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      handleSelectChange: handleCurrentDevStatusChange,
      customClassName: 'dpl-drop-down',
    },
  },
  year: {
    name: 'year',
    type: fieldTypes.date,
    label: 'LAUNCH YEAR',
    options: [],
    props: {
      autoOk: true,
      views: ['year'],
      format: 'yyyy',
      clearable: true,
      maxDate: new Date('2099-12-31'),
    },
  },
  highestDevStatus: {
    name: 'highestDevStatus',
    type: fieldTypes.select,
    label: 'HIGHEST DEVELOPMENT STATUS',
    props: {
      isMandatory: true,
      isClearable: true,
      height: 'ht-lg',
      isSearchable: true,
      customClassName: 'dpl-drop-down',
    },
    options: [],
  },
  addButton: {
    name: 'addButton',
    type: fieldTypes.button,
    label: '+ ADD',
  },
});

const getCompanyParentLookup = masterCompanies => {
  let parents = [];
  masterCompanies.map(({ parentCompany }) => {
    if (parentCompany) {
      parents.push({
        ID: parentCompany.displayValue,
        Name: parentCompany.displayValue,
      });
    }
  });
  return parents;
};

const parsedGlobalStatus = globalStatus => {
  let globalStatusArray = [];
  if (globalStatus.length > 0) {
    globalStatus.map(category => {
      const options = category.options.filter(
        option => option.status !== 'Clinical Trial' && option.status.trim() !== '',
      );
      options.map(({ displayValue }) => {
        globalStatusArray.push({ ID: displayValue, Name: displayValue });
      });
    });
  }

  return globalStatusArray;
};

const parsedHighestGlobalStatus = globalStatus => {
  let highestGlobalStatusArray = [];
  if (globalStatus.length > 0) {
    const globalStatuses = globalStatus.filter(gs => gs.value !== 3);
    globalStatuses.map(category => {
      const options = category.options.filter(
        option => option.status !== 'Clinical Trial' && option.status.trim() !== '',
      );
      options.map(({ displayValue }) => {
        highestGlobalStatusArray.push({ ID: displayValue, Name: displayValue });
      });
    });
  }
  return highestGlobalStatusArray;
};

export const columnDefs = ({
  handleDelete,
  handleEdit,
  handleIngestionActions,
  disableEdit,
  gridData,
  handleTableHeaderClick,
  masterCompanies,
  masterDiseases,
  masterCountries,
  companyType,
  searchArray,
  globalStatus,
}) => [
  {
    headerName: 'DISEASE',
    field: 'disease.indication',
    // width: 160,
    maxWidth: 270,
    cellRenderer: 'DefaultCellRender',
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    sortable: true,
    comparator: (...args) => {
      let isDescendingOrder = args[4];
      handleTableHeaderClick('disease', isDescendingOrder);
    },
    isLookup: true,
    lookupValues: masterDiseases
      ?.filter(item => item.displayValue !== 'Unspecified')
      .map(({ displayValue }) => ({
        ID: displayValue,
        Name: displayValue,
      })),
    filteredValue:
      searchArray.filter(item => item.columnName === 'disease')[0]?.searchValue || undefined,
  },
  {
    headerName: 'COMPANY',
    field: 'company.name',
    // width: 160,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    comparator: (...args) => {
      let isDescendingOrder = args[4];
      handleTableHeaderClick('company', isDescendingOrder);
    },
    isLookup: true,
    lookupValues: masterCompanies
      ?.filter(item => item.displayValue !== 'Unspecified')
      .map(({ displayValue }) => ({
        ID: displayValue,
        Name: displayValue,
      })),
    filteredValue:
      searchArray.filter(item => item.columnName === 'company')[0]?.searchValue || undefined,
  },
  {
    headerName: 'COMPANY PARENT',
    headerClass: 'companyParentDPLS',
    field: 'company.parentCompany.value',
    maxWidth: 185,
    cellRenderer: 'DefaultCellRender',
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    isLookup: true,
    lookupValues: getCompanyParentLookup(
      masterCompanies?.filter(item => item.displayValue !== 'Unspecified'),
    ),
    filteredValue:
      searchArray.filter(item => item.columnName === 'companyparent')[0]?.searchValue || undefined,
  },
  {
    headerName: 'ORIGINATOR/LICENSEE',
    field: 'companyType.value',
    maxWidth: 200,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    comparator: (...args) => {
      let isDescendingOrder = args[4];
      handleTableHeaderClick('company_type', isDescendingOrder);
    },
    isLookup: true,
    lookupValues: companyType.map(({ displayValue }) => ({
      ID: displayValue,
      Name: displayValue,
    })),
    filteredValue:
      searchArray.filter(item => item.columnName === 'originatorlicensee')[0]?.searchValue ||
      undefined,
  },
  {
    headerName: 'COUNTRY',
    field: 'country.countryName',
    maxWidth: 160,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    comparator: (...args) => {
      let isDescendingOrder = args[4];
      handleTableHeaderClick('country', isDescendingOrder);
    },
    isLookup: true,
    lookupValues: masterCountries.map(({ displayValue }) => ({
      ID: displayValue,
      Name: displayValue,
    })),
    filteredValue:
      searchArray.filter(item => item.columnName === 'country')[0]?.searchValue || undefined,
  },
  {
    headerName: 'CURRENT DEVELOPMENT STATUS',
    field: 'currentDevStatus.status',
    maxWidth: 220,
    sortable: true,
    cellRendererParams: ({ data }) => {
      return {
        children: (data && data.currentDevStatus && data.currentDevStatus.status) || undefined,
      };
    },
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    comparator: (...args) => {
      let isDescendingOrder = args[4];
      handleTableHeaderClick('currentdev_status', isDescendingOrder);
    },
    isLookup: true,
    lookupValues: parsedGlobalStatus(globalStatus),
    filteredValue:
      searchArray.filter(item => item.columnName === 'currentdevelopmentstatus')[0]?.searchValue ||
      undefined,
  },
  {
    headerName: 'LAUNCH YEAR',
    field: 'year',
    maxWidth: 120,
    sortable: true,
    cellRenderer: params => {
      return params.value ? params.value : 'NA';
    },
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    comparator: (...args) => {
      let isDescendingOrder = args[4];
      handleTableHeaderClick('year_launched', isDescendingOrder);
    },
  },
  {
    headerName: 'HIGHEST DEVELOPMENT STATUS',
    field: 'highestDevStatus.status',
    maxWidth: 220,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    comparator: (...args) => {
      let isDescendingOrder = args[4];
      handleTableHeaderClick('highestdev_status', isDescendingOrder);
    },
    isLookup: true,
    lookupValues: parsedHighestGlobalStatus(globalStatus),
    filteredValue:
      searchArray.filter(item => item.columnName === 'highestdevelopmentstatus')[0]?.searchValue ||
      undefined,
  },
  {
    headerName: '',
    minWidth: 50,
    maxWidth: 50,
    field: '',
    cellRenderer: 'actions',
    cellRendererParams: params => {
      const { rowIndex, data } = params;
      return {
        handleEdit: () => {
          handleEdit(data, rowIndex);
        },
        canDisableEdit: disableEdit || data.isDisabled,
      };
    },
  },
  {
    headerName: '',
    minWidth: 50,
    maxWidth: 50,
    field: '',
    cellRenderer: 'actions',
    cellRendererParams: params => {
      const { rowIndex, data } = params;
      return {
        handleDelete: () => {
          handleDelete(data, rowIndex);
        },
        canDisableDelete: disableEdit || data.isDisabled,
      };
    },
  },
  {
    headerName: '',
    // minWidth: 100,
    maxWidth: 1,
    field: '',
    hide: !checkForIngestionAction(gridData, true),
    cellRenderer: 'ingestionActions',
    cellRendererParams: ({ data: rowData }) => {
      return {
        isDisabled: disableEdit,
        ingestionAction: rowData ? rowData.ingestionAction : 'none',
        handleIngestionActions: option => handleIngestionActions(option, rowData),
      };
    },
  },
];

export const convertDPLSForAPI = (id, values) => {
  let data = { id };
  const {
    currentDevStatus: { label: currentStatus, value: currentId, ...otherCurrentDevStatus },
  } = values;

  // check non mandatory fields
  let highestDevStatus = null;
  if (values.highestDevStatus) {
    const {
      highestDevStatus: { label: highestStatus, value: highestId, ...otherHighestDevStatus },
    } = values;
    highestDevStatus = {
      id: highestId,
      status: highestStatus,
      ...otherHighestDevStatus,
    };
  }
  let selectedYear = 0;
  if (values.year) {
    selectedYear = new Date(values.year).getFullYear();
  }

  data = {
    ...data,
    disease: omit(values.disease, ['label', 'value']),
    company: omit(values.company, [
      'label',
      'value',
      'contactInfo',
      'parentName',
      'lastFullReviewDate',
      'modifiedBy',
      'modifiedDate',
      'nextFullReviewDate',
      'prodStatus',
      'numberOfActiveDrugs',
      'createdDate',
    ]),
    companyType: {
      id: values.companyType.value === 'Originator' || values.companyType.value === 1 ? 1 : 2,
      value: values.companyType.label,
      displayValue: values.companyType.label,
    },
    year: selectedYear,
    country: values.country ? omit(values.country, ['label', 'value']) : null,
    highestDevStatus,
    currentDevStatus: {
      id: currentId,
      status: currentStatus,
      ...otherCurrentDevStatus,
    },
  };
  return data;
};
