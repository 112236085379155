import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { DROPDOWN_TYPES, TEXT_LIMIT } from '../../../../utils/generic/constants';
import format from 'date-fns/format';

export const validate = values => {
  const errors = {};
  if (
    values?.languagePlsResultsDisclosed?.trim() &&
    values?.languagePlsResultsDisclosed?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.languagePlsResultsDisclosed = 'Please enter only 500 characters';
  }
  if (
    values?.targetAudiencePlsRes?.trim() &&
    values?.targetAudiencePlsRes?.trim().length > TEXT_LIMIT[300]
  ) {
    errors.targetAudiencePlsRes = 'Please enter only 300 characters';
  }
  if (
    values?.ethicsReviewedPlsDescription?.trim() &&
    values?.ethicsReviewedPlsDescription?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.ethicsReviewedPlsDescription = 'Please enter only 500 characters';
  }
  if (
    values?.characterLimitPls?.trim() &&
    values?.characterLimitPls?.trim().length > TEXT_LIMIT[150]
  ) {
    errors.characterLimitPls = 'Please enter only 150 characters';
  }
  if (values?.pageLimitPls?.trim() && values?.pageLimitPls?.trim().length > TEXT_LIMIT[150]) {
    errors.pageLimitPls = 'Please enter only 150 characters';
  }
  if (
    values?.languageRequirementPls?.trim() &&
    values?.languageRequirementPls?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.languageRequirementPls = 'Please enter only 500 characters';
  }
  if (
    values?.plsResultsSubmissionTiming?.trim() &&
    values?.plsResultsSubmissionTiming?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.plsResultsSubmissionTiming = 'Please enter only 500 characters';
  }
  if (
    values?.otherPlsResultsSubmissionTiming?.trim() &&
    values?.otherPlsResultsSubmissionTiming?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.otherPlsResultsSubmissionTiming = 'Please enter only 500 characters';
  }
  if (
    values?.updatesRequiredDesc?.trim() &&
    values?.updatesRequiredDesc?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.updatesRequiredDesc = 'Please enter only 500 characters';
  }
  if (
    values?.timingForUpdates?.trim() &&
    values?.timingForUpdates?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.timingForUpdates = 'Please enter only 500 characters';
  }
  if (
    values?.optionToDelayPlsResultDescription?.trim() &&
    values?.optionToDelayPlsResultDescription?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.optionToDelayPlsResultDescription = 'Please enter only 500 characters';
  }

  if (
    values?.pediatricResultDiffPlsDesc?.trim() &&
    values?.pediatricResultDiffPlsDesc?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.pediatricResultDiffPlsDesc = 'Please enter only 500 characters';
  }
  if (
    values?.productStatusAffectPlsDesc?.trim() &&
    values?.productStatusAffectPlsDesc?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.productStatusAffectPlsDesc = 'Please enter only 500 characters';
  }
  if (
    values?.trialStatusAffectPlsDesc?.trim() &&
    values?.trialStatusAffectPlsDesc?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.trialStatusAffectPlsDesc = 'Please enter only 500 characters';
  }
  if (
    values?.interimVersionPlsDesc?.trim() &&
    values?.interimVersionPlsDesc?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.interimVersionPlsDesc = 'Please enter only 500 characters';
  }

  if (values.requirementProposedPlsResult?.length > 0) {
    for (let i = 0; i < values.requirementProposedPlsResult.length; i++) {
      let title = `section${i + 1}`;
      let description = `description${i + 1}`;
      if (values[title]?.trim() && values[title].length > TEXT_LIMIT[300]) {
        errors[title] = 'Please enter only 300 characters';
      }
      if (values[description]?.trim() && values[description].length > TEXT_LIMIT[500]) {
        errors[description] = 'Please enter only 500 characters';
      }
    }
  }
  return errors;
};

const columnDefs1 = [
  {
    headerName: 'DOCUMENT ID',
    field: 'value',
    width: 250,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'DOCUMENT TITLE',
    field: 'documentTitle',
    width: 400,
  },
  {
    headerName: 'Regulatory Date',
    field: 'regulatoryDate',
    width: 350,
    sortable: true,
    cellRenderer: ({ value }) => {
      return value ? format(new Date(value), 'dd-MMM-yyyy') : 'NA';
    },
  },
];

export const layoutSchema = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    { layout: 'isFollowsEuRegulationPlsResults', size: 12 },
    { layout: 'languagePlsResultsDisclosed', size: 12 },
    { layout: 'isFollowsRequirementsPlsResults', size: 12 },
    {
      layout: 'submissionRequirementsPlsResult',
      size: 6,
      className: 'timeline',
    },
    {
      layout: 'isTemplateAvailable',
      size: 6,
    },
  ],
};
export const layoutSchema2 = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'targetAudiencePlsRes',
      size: 6,
    },
    {
      layout: 'isEthicsReviewedPls',
      size: 6,
    },
    {
      layout: 'ethicsReviewedPlsDescription',
      size: 6,
    },
  ],
};

export const layoutSchema3 = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'characterLimitPls',
      size: 6,
    },
    {
      layout: 'pageLimitPls',
      size: 6,
    },
    {
      layout: 'languageRequirementPls',
      size: 6,
    },
    {
      layout: 'requirementPlsResultSubmissionFormat',
      size: 6,
    },
    {
      layout: 'plsResultsSubmissionTiming',
      size: 6,
    },
    {
      layout: 'otherPlsResultsSubmissionTiming',
      size: 6,
    },
    {
      layout: 'areUpdatesRequired',
      size: 6,
    },
    {
      layout: 'updatesRequiredDesc',
      size: 6,
    },
    {
      layout: 'timingForUpdates',
      size: 6,
    },
    {
      layout: 'isOptionToDelayPlsResult',
      size: 6,
    },
    {
      layout: 'optionToDelayPlsResultDescription',
      size: 6,
    },
    {
      layout: 'isPediatricResultDiffPls',
      size: 6,
    },
    {
      layout: 'pediatricResultDiffPlsDesc',
      size: 6,
    },
    {
      layout: 'isProductStatusAffectPls',
      size: 6,
    },
    {
      layout: 'productStatusAffectPlsDesc',
      size: 6,
    },
    {
      layout: 'isTrialStatusAffectPls',
      size: 6,
    },
    {
      layout: 'trialStatusAffectPlsDesc',
      size: 6,
    },
    {
      layout: 'isInterimVersionPls',
      size: 6,
    },
    {
      layout: 'interimVersionPlsDesc',
      size: 6,
    },
  ],
};

const endPointTypes = [
  {
    id: 1,
    value: 'Primary endpoints',
  },
  {
    id: 2,
    value: 'All Secondary endpoints',
  },
  {
    id: 3,
    value: 'Only Key Secondary Endpoints',
  },
  {
    id: 4,
    value: 'Exploratory/Tertiary/Other Endpoints',
  },
  {
    id: 5,
    value: 'Not applicable',
  },
];

export const submissionReqObj = {
  'Submitted as part of CSR': 1,
  'Submitted as a separate document': 2,
};

export const formSchema = {
  isFollowsEuRegulationPlsResults: {
    label: 'FOLLOWS EU REGULATION FOR PLS RESULTS',
    name: 'isFollowsEuRegulationPlsResults',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  languagePlsResultsDisclosed: {
    label:
      'ACCORDING TO THE REGULATION OR REQUIREMENT, IN WHAT LANGUAGE(S) MUST THE PLS RESULTS BE DISCLOSED ?',
    name: 'languagePlsResultsDisclosed',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
  isFollowsRequirementsPlsResults: {
    label: 'FOLLOWS LOCAL REQUIREMENTS FOR PLS RESULTS',
    name: 'isFollowsRequirementsPlsResults',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  submissionRequirementsPlsResult: {
    label: 'What are submission requirements for PLS',
    name: 'submissionRequirementsPlsResult',
    type: fieldTypes.radio,
    options: [
      {
        id: 1,
        value: 'Submitted as part of CSR',
        label: 'Submitted as part of CSR',
      },
      {
        id: 2,
        value: 'Submitted as a separate document',
        label: 'Submitted as a separate document',
      },
    ],
    props: {
      type: 'group',
      className: 'timelineRadioClass',
    },
  },
  isTemplateAvailable: {
    label: 'Template available',
    name: 'isTemplateAvailable',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
};

export const formSchema2 = {
  targetAudiencePlsRes: {
    label: 'Target audience',
    name: 'targetAudiencePlsRes',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  isEthicsReviewedPls: {
    label: 'Is Ethics Committee review required?',
    name: 'isEthicsReviewedPls',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  ethicsReviewedPlsDescription: {
    label: 'If yes, describe',
    name: 'ethicsReviewedPlsDescription',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
};

export const formSchema3 = {
  characterLimitPls: {
    label: 'Character limit',
    name: 'characterLimitPls',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  pageLimitPls: {
    label: 'Page limit',
    name: 'pageLimitPls',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  languageRequirementPls: {
    label: 'Language requirements',
    name: 'languageRequirementPls',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  requirementPlsResultSubmissionFormat: {
    label: 'File format for submission',
    name: 'requirementPlsResultSubmissionFormat',
    type: fieldTypes.select_async,
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  plsResultsSubmissionTiming: {
    label: 'Timing for results submission',
    name: 'plsResultsSubmissionTiming',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
      isMandatory: false,
    },
  },
  otherPlsResultsSubmissionTiming: {
    label: 'If Other, Timing for results submission',
    name: 'otherPlsResultsSubmissionTiming',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
      isMandatory: false,
    },
  },
  areUpdatesRequired: {
    label: 'Are updates required?',
    name: 'areUpdatesRequired',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  updatesRequiredDesc: {
    label: 'If yes, describe',
    name: 'updatesRequiredDesc',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  timingForUpdates: {
    label: 'If yes, timing for updates',
    name: 'timingForUpdates',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  isOptionToDelayPlsResult: {
    label: 'Is there an option to delay the PLS?',
    name: 'isOptionToDelayPlsResult',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  optionToDelayPlsResultDescription: {
    label: 'If yes, describe',
    name: 'optionToDelayPlsResultDescription',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  isPediatricResultDiffPls: {
    label: 'Is there a difference for pediatric results?',
    name: 'isPediatricResultDiffPls',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  pediatricResultDiffPlsDesc: {
    label: 'If yes, describe',
    name: 'pediatricResultDiffPlsDesc',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  isProductStatusAffectPls: {
    label: 'Does product status affect the PLS disclosure requirement?',
    name: 'isProductStatusAffectPls',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  productStatusAffectPlsDesc: {
    label: 'If yes, describe',
    name: 'productStatusAffectPlsDesc',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
  isTrialStatusAffectPls: {
    label: 'Does trial status affect the PLS disclosure requirement?',
    name: 'isTrialStatusAffectPls',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  trialStatusAffectPlsDesc: {
    label: 'If yes, describe',
    name: 'trialStatusAffectPlsDesc',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  isInterimVersionPls: {
    label: 'Is an interim version required?',
    name: 'isInterimVersionPls',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  interimVersionPlsDesc: {
    label: 'If yes, describe',
    name: 'interimVersionPlsDesc',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
};

export const voluntaryOrMandatoryOptions = [
  { id: 1, value: 'Mandatory' },
  { id: 2, value: 'Voluntary' },
];

export const publicOrPrivateOptions = [
  { id: 1, value: 'Public' },
  { id: 2, value: 'Private' },
];

export const createDataEntry = ({
  numOfDataEntry,
  values,
  requirementId,
  requirementProposedPlsResult,
}) =>
  [...Array(numOfDataEntry)].map((_, index) => ({
    id: requirementProposedPlsResult[index]?.id || 0,
    requirementId,
    productionId: values[`productionId${index + 1}`] || 0,
    section: values[`section${index + 1}`],
    description: values[`description${index + 1}`],
    voluntaryOrMandatory:
      values[`voluntaryOrMandatory${index + 1}`]?.filter(obj => obj.isChecked)?.[0] || undefined,
    publicOrPrivate:
      values[`publicOrPrivate${index + 1}`]?.filter(obj => obj.isChecked)?.[0] || undefined,
    orderId: index + 1,
    voluntaryOrMandatory:
      values[`voluntaryOrMandatory${index + 1}`]?.filter(obj => obj.isChecked)?.[0] || undefined,
    publicOrPrivate:
      values[`publicOrPrivate${index + 1}`]?.filter(obj => obj.isChecked)?.[0] || undefined,
  }));

export const createDataEntryInitialValues = (values = []) => {
  const updatedValues =
    values.length > 0 ? values : [{ section: '', description: '', productionId: 0 }];
  return updatedValues.reduce(
    (init, value, index) => ({
      ...init,
      [`productionId${index + 1}`]: value.productionId,
      [`section${index + 1}`]: value.section,
      [`description${index + 1}`]: value.description,
      [`voluntaryOrMandatory${index + 1}`]: voluntaryOrMandatoryOptions.map(obj => {
        if (obj.id === value.voluntaryOrMandatory?.id) {
          return { ...obj, isChecked: true };
        } else if (value.voluntaryOrMandatory?.id) {
          return { ...obj, disabled: true, isChecked: false };
        } else return obj;
      }),
      [`publicOrPrivate${index + 1}`]: publicOrPrivateOptions.map(obj => {
        if (obj.id === value.publicOrPrivate?.id) {
          return { ...obj, isChecked: true };
        } else if (value.publicOrPrivate?.id) {
          return { ...obj, disabled: true, isChecked: false };
        } else return obj;
      }),
    }),
    {},
  );
};

export const createDateEntryFields = (number, handleAddDelEntry) => {
  const layout = [...Array(number)].reduce(
    (init, _, index) => [
      ...init,
      {
        layout: 'row',
        spacing: 0.5,
        alignItems: 'space-between',
        justify: 'space-between',
        content: [
          {
            layout: `section${index + 1}`,
            size: 2,
          },
          {
            layout: `description${index + 1}`,
            size: 5,
          },
          {
            layout: `icons${index + 1}`,
            size: 1,
          },
          {
            layout: `voluntaryOrMandatory${index + 1}`,
            size: 2,
            className: 'mt-20',
          },
          {
            layout: `publicOrPrivate${index + 1}`,
            size: 1,
            className: 'mt-20',
          },
        ],
      },
    ],
    [],
  );

  const form = [...Array(number)].reduce(
    (init, _, index) => ({
      ...init,
      [`section${index + 1}`]: {
        label: 'section',
        name: `section${index + 1}`,
        type: fieldTypes.text,
        props: {
          isMandatory: false,
          style: {},
        },
      },
      [`description${index + 1}`]: {
        label: 'description',
        name: `description${index + 1}`,
        type: fieldTypes.text,
        props: {
          isMandatory: false,
          style: {},
        },
      },
      [`icons${index + 1}`]: {
        type: fieldTypes.icons,
        props: {
          index: index + 1,
          addDelEntryRow: (index, todo) => {
            handleAddDelEntry(index, todo);
          },
        },
      },
      [`voluntaryOrMandatory${index + 1}`]: {
        type: fieldTypes.checkbox,
        name: `voluntaryOrMandatory${index + 1}`,
        options: voluntaryOrMandatoryOptions,
        props: {},
      },
      [`publicOrPrivate${index + 1}`]: {
        type: fieldTypes.checkbox,
        name: `publicOrPrivate${index + 1}`,
        options: publicOrPrivateOptions,
        props: {},
      },
    }),
    {},
  );

  return {
    layout,
    form,
  };
};
