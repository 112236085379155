import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import Modal from '../../../framework/modal';
import { InputButton } from '../../../framework/inputs';
import './index.scss';
import Typography from '../../../framework/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ConfirmationMessage = ({ isOpen, message, onClose, cancelBtnText, confirmBtnText }) => {
  const [showModal, update] = useState(isOpen);
  const handleClick = status => {
    onClose(status);
    update(false);
  };
  return (
    <Modal isOpen={showModal} showCloseButton={false}>
      <section className="message-modal-container">
        {/* <ErrorIcon className="icon_confirm" /> */}
        <FontAwesomeIcon className="icon_confirm" icon={faExclamationTriangle} />
        <Typography
          variant="h4"
          text="confirm!"
          textType="uppercase"
          fontStyle="bold"
          color="orange"
          padding="top"
        />
        <p className="message-modal-content" dangerouslySetInnerHTML={{ __html: message }}></p>
        <footer>
          <InputButton text={cancelBtnText} onClick={() => handleClick(false)} />
          <InputButton
            buttonType="primary-btn"
            text={confirmBtnText}
            onClick={() => handleClick(true)}
          />
        </footer>
      </section>
    </Modal>
  );
};

ConfirmationMessage.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  cancelBtnText: PropTypes.string,
  confirmBtnText: PropTypes.string,
};

ConfirmationMessage.defaultProps = {
  isOpen: true,
  message: '',
  cancelBtnText: 'CANCEL',
  confirmBtnText: 'CONFIRM',
};

export default ConfirmationMessage;
