import { fieldTypes } from '../../../../../../framework/formBuilder/utils';
import {
  getClassNameForIngestion,
  checkForIngestionAction,
} from '../../../../../../utils/generic/helper';

export const validationMessages = {
  duplicateDescriptiveName: 'Descriptive name already exists',
};

export const validateModalForm = (values, license, rowIndex) => {
  const errors = {};
  if (!values.country) {
    errors.country = 'Please select the Country Name';
  }
  let duplicates = license.filter(lic => lic.country.id === values.country.id);
  if (rowIndex > -1) {
    duplicates = license.filter(
      (lic, index) => lic.country.id === values.country.id && rowIndex !== index,
    );
  }
  if (duplicates.length > 0) {
    errors.country = 'Selected Country already exists';
  }

  return errors;
};

export const validate = (values, license) => {
  const errors = {};
  if (!values.country) {
    errors.country = 'Please select the Country Name';
  }

  let duplicates = [];
  if (values.country && values.country.length > 0) {
    values.country.forEach(ctry => {
      const dupCtry = license.find(lic => lic.country.id === ctry.id);
      dupCtry && duplicates.push(dupCtry);
    });
    if (values.country.length === duplicates.length) {
      errors.country = 'Selected Country already exists';
    }
  }

  return errors;
};

export const initialValues = {
  country: null,
  statusId: 3,
};

export const layoutSchema = {
  layout: 'row',
  className: 'drug-license-availability-form',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'country',
      size: 4,
      className: '',
    },
    {
      layout: 'statusId',
      size: 4,
      className: 'license-status',
    },
    {
      layout: 'button',
      size: 2,
      className: 'add-button',
    },
  ],
};

export const formSchema = {
  country: {
    name: 'country',
    type: fieldTypes.countryMultiselect,
    label: 'COUNTRY NAME',
    options: [],
    props: {
      isMandatory: true,
      height: 'ht-lg',
      isSearchable: true,
    },
  },
  statusId: {
    name: 'statusId',
    type: fieldTypes.custom_radio,
    label: 'STATUS',
    options: [],
    props: {
      isMandatory: true,
    },
  },
  button: {
    name: 'button',
    type: fieldTypes.button,
    label: '+ ADD',
  },
};

// ag-grid configuration
export const columnDefs = ({
  handleDelete,
  handleEdit,
  handleIngestionActions,
  licenseStatus,
  gridData,
  disableEdit,
}) => [
    {
      field: '',
      cellClass: ['custom-checkbox'],
      headerClass: 'disableResize',
      minWidth: 50,
      maxWidth: 52,
      resizable: false,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      lockPosition: true,
      cellRendererParams: ({ rowIndex, data }) => {
        return {
          id: rowIndex.toString(),
          checked: data,
          value: '',
          label: '',
        };
      }
    },
    {
      headerName: 'COUNTRY NAME',
      field: 'country.countryName',
      width: 200,
      sortable: true,
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    },
    {
      headerName: 'STATUS',
      field: 'statusId',
      headerClass: 'statusDrugLicensing',
      sortable: true,
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
      cellRenderer: ({ value }) => {
        const filteredStatus = licenseStatus.filter(status => status.id === value);
        return filteredStatus.length > 0 ? filteredStatus[0].value.toUpperCase() : value;
      },
      comparator: (a, b) => {
        let x, y;
        x = a === 1 ? 'YES' : (a === 2 ? 'NO' : 'UNKNOWN')
        y = b === 1 ? 'YES' : (b === 2 ? 'NO' : 'UNKNOWN')
        return x.localeCompare(y, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      }
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: ({ rowIndex, data }) => {
        return {
          handleEdit: () => {
            handleEdit(data, rowIndex);
          },
          canDisableEdit: disableEdit,
        };
      },
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: ({ rowIndex, data }) => {
        return {
          handleDelete: () => {
            handleDelete(data, rowIndex);
          },
          canDisableDelete: disableEdit,
        };
      },
    },
    {
      headerName: '',
      minWidth: 100,
      maxWidth: 100,
      field: '',
      hide: !checkForIngestionAction(gridData, true),
      cellRenderer: 'ingestionActions',
      cellRendererParams: ({ data: rowData }) => {
        return {
          isDisabled: disableEdit,
          ingestionAction: rowData ? rowData.ingestionAction : 'none',
          handleIngestionActions: option => handleIngestionActions(option, rowData),
        };
      },
    },
  ];
