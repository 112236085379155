import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import CopyContentModal from '../copyContentModal';

const ToolTip = ({ value, keyValue, customClassName }) => {
  // console.log({ value, keyValue, customClassName });
  const cellValue = typeof value === 'number' ? value.toString() : value;

  if (cellValue) {
    let renderCellValue = cellValue;
    if (typeof cellValue === 'object') {
      renderCellValue = keyValue && cellValue[keyValue] ? cellValue[keyValue] : cellValue['value'];
    }
    let renderCellValueCopy = JSON.parse(JSON.stringify(renderCellValue))
    if (!isEmpty(renderCellValue)) {
      return (
        <div className={`copy-content-wrapper ${customClassName}`}>
          <CopyContentModal renderCellValue={renderCellValueCopy}>
            <span className="font-size-16">{typeof renderCellValue == "string" && renderCellValue.length > 100 ? renderCellValue.substring(0, 100) : renderCellValue}</span>
          </CopyContentModal>
        </div>
      );
    }
  }
  return 'NA';
};

ToolTip.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  keyValue: PropTypes.string,
};

ToolTip.defaultProps = {
  keyValue: '',
};

export default React.memo(ToolTip);
