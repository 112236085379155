import { isValidDate } from '../../../../utils/generic/helper';

export const validate = (summary, masterDrug, nextReviewDate = summary.nextReviewDate, isMasterDrug) => {
  let errors = {};

  if (!isValidDate(nextReviewDate)) {
    errors.nextFullReviewDate = 'Please enter a valid date (MMM-dd-yyyy)';
  }

  // eslint-disable-next-line no-negated-condition
  if (
    !summary.globalStatus ||
    (!masterDrug && isMasterDrug && !summary.latestChangeText) || 
    (isMasterDrug && !summary.drugSummary) ||
    (summary.latestChangeText && summary.latestChangeText.trim() === '') ||
    (summary.drugSummary && summary.drugSummary.trim() === '')
  ) {
    //empty check for global status
    if (!summary.globalStatus) {
      errors.globalStatus = 'Please select the Global Status';
    }
    //empty check for global status
    if (
      (!summary.latestChangeText && isMasterDrug) ||
      (summary.latestChangeText && summary.latestChangeText.trim() === '')
    ) {
      errors.latestChangeText = 'Please enter the Latest Change Text';
    }
    if (
      (!summary.drugSummary && isMasterDrug) ||
      (summary.drugSummary && summary.drugSummary.trim() === '')
    ) {
      errors.drugSummary = 'Please enter the Drug Summary';
    }
  }
  return errors;
};
