import React from 'react';
import TimezoneSelect from 'react-timezone-select';
import InputLabel from '../InputLabel';
import PropTypes from 'prop-types';
import './index.scss';
const TimezoneSelector = ({
  label,
  value,
  isMandatory,
  onChange,
  isError,
  errorMessage,
  placeholder,
  disabled,
}) => {
  return (
    <div className="timezone-select input-select-async-wrapper">
      <InputLabel labelFor="timezoneselect-label" text={label} isMandatory={isMandatory} />
      <div className="">
        <TimezoneSelect
          className="async-select-container"
          classNamePrefix="async-select"
          value={value}
          onChange={timezone => {
            if (timezone) {
              onChange(timezone);
            } else {
              onChange('');
            }
          }}
          labelStyle="abbrev"
          displayValue="UTC"
          placeholder={placeholder}
          isDisabled={disabled}
          isClearable={true}
        />
      </div>
      {isError && <p className="error-text">{errorMessage}</p>}
    </div>
  );
};

TimezoneSelector.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  isMandatory: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

TimezoneSelector.defaultProps = {
  label: '',
  isMandatory: false,
  isError: false,
  errorMessage: '',
  onChange: () => {},
  disabled: false,
};

export default TimezoneSelector;
