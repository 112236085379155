/* eslint-disable camelcase */
/* eslint-disable generator-star-spacing */
/* eslint-disable max-depth */
import { takeEvery, put, all, delay, select } from 'redux-saga/effects';
import { cloneDeep, omit } from 'lodash';

import * as DrugActions from '../actions/drugs';
import * as TaskActions from '../actions/task';
import * as globalActions from '../actions/globalActions';
import {
  getProdMessage,
  getLockMessageInfo,
  getAncillaryRequestPostData,
  getApiErrorMessage,
} from '../../utils/generic/helper';
import {
  ALERT_MESSAGES,
  MODAL_TYPES,
  updateTypeOnProdStatusSave,
} from '../../utils/generic/constants';
import { drugFeatureKeys, drugFeatureValues } from '../../utils/drugs/constants';
import { drugs as drugsApi, queues as queuesApi, task as taskApi } from '../../api';

const getMessage = (id, masterDrug) => {
  let msg = ALERT_MESSAGES.DRUGS_SAVE;
  if (id === 0 && masterDrug) {
    msg = ALERT_MESSAGES.CHILD_SAVE;
  } else if (masterDrug) {
    msg = ALERT_MESSAGES.CHILD_EDIT;
  }
  return msg;
};

// workers
export function* getDrugListAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield drugsApi.getDrugList(payload);
    const { state, data } = response.data;
    if (state) {
      yield put(DrugActions.getDrugListSuccess(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* postDrugDissociationAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield drugsApi.postDrugDissociation(payload.selectedIds);
    if (response) {
      const result = yield drugsApi.getDrugsData(payload.id);
      const { data } = result.data;
      yield put(DrugActions.getDrugDetailSuccess(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* postDrugTransferAsync({
  payload: { id, ingestedId, selectedIds, transferToDrugID },
}) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield drugsApi.postDrugTransfer(transferToDrugID, selectedIds);
    const { state, messages } = response.data;
    if (!messages) {
      const result = yield drugsApi.getDrugsData(id, ingestedId);
      const { data } = result.data;
      yield put(DrugActions.getDrugDetailSuccess(data));
      yield put(globalActions.hideSpinner());
      if (response) {
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: 'Drug Transfered Successfully.',
            modalType: MODAL_TYPES.SUCCESS,
          }),
        );
      }
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: messages[0].errorMessage,
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getGlobalStatusAsync() {
  try {
    const response = yield drugsApi.getGlobalStatus();
    const { state, data } = response.data;
    if (state) {
      yield put(DrugActions.getGlobalStatusSuccess(data));
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getCountriesAsync() {
  try {
    yield put(globalActions.showSpinner());
    const [countryGroups, countries] = yield all([
      yield drugsApi.getCountryGroups(),
      yield drugsApi.getCountries(),
    ]);

    const { state: countryGroupState, data: countryGroupData } = countryGroups.data;
    const { state: countriesState, data: countriesData } = countries.data;

    if (countryGroupState) {
      yield put(DrugActions.getCountryGroups(countryGroupData));
    }
    if (countriesState) {
      yield put(DrugActions.getCountriesSuccess(countriesData));
    }

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getProgramLandscapeMasterDataAsync() {
  try {
    yield put(globalActions.showSpinner());
    const [globalStatus] = yield all([yield drugsApi.getGlobalStatus()]);

    const { state: globalStatusState, data: globalStatusData } = globalStatus.data;

    if (globalStatusState) {
      yield put(DrugActions.getGlobalStatusSuccess(globalStatusData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getEventTypeAsync() {
  try {
    const response = yield drugsApi.getEventTypes();
    const { state, data } = response.data;
    if (state) {
      yield put(DrugActions.getEventTypesSuccess(data));
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getTrendsAsync({ payload }) {
  yield put(globalActions.showSpinner());
  try {
    const response = yield drugsApi.getTrends({ payload });
    const { state, messages, data } = response.data;
    if (state) {
      yield put(DrugActions.getTrendsDataSuccess(data));
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: messages.length > 0 ? messages[0].errorMessage : ALERT_MESSAGES.DRUGS_ERROR,
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* postTrendsYearAsync({ payload }) {
  yield put(globalActions.showSpinner());
  try {
    const { data, drugKey } = payload;
    const response = yield drugsApi.postTrends({ data });
    const { state, messages, data: responseData } = response.data;
    if (state) {
      yield put(DrugActions.getTrendsDataSuccess(responseData));
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: messages.length > 0 ? messages[0].errorMessage : ALERT_MESSAGES.DRUGS_ERROR,
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* saveTrendsDataAsync({ payload }) {
  yield put(globalActions.showSpinner());
  try {
    const { data, type, drugKey } = payload;
    let response;
    switch (type) {
      case 'Class':
      case 'Global Status':
      case 'Originator':
        response = yield drugsApi.postTrendsGlobalStatus({ data });
        break;
      case 'Country':
        response = yield drugsApi.postTrendsCountryStatus({ data });
        break;
      case 'Therapeutic Class':
        response = yield drugsApi.postTrendsTherapeutic({ data });
        break;
      case 'Licensee':
        response = yield drugsApi.postTrendsLicensee({ data });
        break;
      case 'Disease':
        response = yield drugsApi.postTrendsDisease({ data });
        break;
    }
    const { state, messages, data: responseData } = response?.data;
    if (state) {
      yield put(DrugActions.getTrends({ drugKey: drugKey, forceToFieldLoad: 0 }));
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: messages.length > 0 ? messages[0].errorMessage : ALERT_MESSAGES.DRUGS_ERROR,
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* deleteTrendsDataAsync({ payload }) {
  yield put(globalActions.showSpinner());
  try {
    const response = yield drugsApi.deleteTrendsData({ payload });
    const { state, messages, data: responseData } = response.data;
    if (state) {
      yield put(DrugActions.getTrendsDataSuccess(responseData));
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: messages.length > 0 ? messages[0].errorMessage : ALERT_MESSAGES.DRUGS_ERROR,
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* deleteTrendsYearDataAsync({ payload }) {
  yield put(globalActions.showSpinner());
  try {
    const { data, drugKey } = payload;
    const response = yield drugsApi.deleteTrendsYearData({ data });
    const { state, messages, data: responseData } = response.data;
    if (state) {
      yield put(DrugActions.getTrends({ drugKey: drugKey, forceToFieldLoad: 0 }));
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: messages.length > 0 ? messages[0].errorMessage : ALERT_MESSAGES.DRUGS_ERROR,
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getDevelopmentPhaseAsync() {
  try {
    const response = yield drugsApi.getDevelopmentPhase();
    const { state, data } = response.data;
    if (state) {
      yield put(DrugActions.getDevelopmentPhaseSuccess(data));
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getEventMasterDataAsync({ payload }) {
  yield put(globalActions.showSpinner());
  try {
    const [diseases, companies, countries] = yield all([
      drugsApi.getDPLDiseases(payload),
      drugsApi.getDPLCompanies(payload),
      drugsApi.getDPLCountries(payload),
    ]);
    yield put(
      DrugActions.getEventMasterDataSuccess({
        diseases,
        companies,
        countries,
      }),
    );
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getMasterDataAsync() {
  try {
    yield put(globalActions.hideSpinner());
    const response = yield drugsApi.getMasterData();
    const { state, data } = response.data;
    if (state) {
      yield put(DrugActions.getMasterDataSuccess(data));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getDrugsDataAsync({ payload: { id, ingestedId } }) {
  try {
    yield put(globalActions.showSpinner());

    let actions = {
      masterActions: drugsApi.getMasterData(),
    };

    if (id > 0) {
      actions = {
        ...actions,
        drugDetailsActions: drugsApi.getDrugsData(id),
        lockDetails: drugsApi.getUserLockDetails(id),
      };
    }

    if (ingestedId > 0) {
      actions = { ...actions, ingestedActions: drugsApi.getIngestedData(ingestedId) };
    }

    const { masterActions, lockDetails, drugDetailsActions, ingestedActions } = yield all(actions);

    if (masterActions) {
      const { state: masterState, data: masterData } = masterActions.data;
      if (masterState) {
        yield put(DrugActions.getMasterDataSuccess(masterData));
      }
    }

    if (lockDetails) {
      const {
        data: { state, data, messages },
      } = lockDetails;
      if (state) {
        yield put(DrugActions.getDrugUserLockDetails(data));
        const { isEditable, lockedBy } = data;
        if (!isEditable) {
          yield put(
            globalActions.showModal({
              isOpen: true,
              message: getLockMessageInfo(lockedBy),
              modalType: MODAL_TYPES.INFO,
            }),
          );
        }
      } else {
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: getApiErrorMessage(messages),
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    }

    if (drugDetailsActions) {
      const { state: drugState, data: drugsData } = drugDetailsActions.data;
      if (drugState) {
        yield put(DrugActions.getDrugDetailSuccess(drugsData));
      } else {
        yield put(DrugActions.resetDrugState());
      }
    }

    if (ingestedActions) {
      const { state: ingestedState, data: ingestedData } = ingestedActions.data;
      if (ingestedState) {
        yield put(DrugActions.getDrugIngestedDataSuccess(ingestedData));
      }
    }

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(DrugActions.resetDrugState());
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* getDPLDataAsync({
  payload: { drugId, pageIndex, pageSize, ingestedId, sortOrder, searchCriteria },
}) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield drugsApi.getDPLData({
      drugId,
      pageIndex,
      pageSize,
      sortOrder,
      searchCriteria,
    });
    const { state, data } = response.data;
    if (state) {
      yield put(DrugActions.getDPLDataSuccess({ ...data, ingestedId }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getQueueDashboardDataAsync({
  payload: { entityId, listPostParam, assigneeUserGroup },
}) {
  try {
    yield put(globalActions.showSpinner());

    const actions = {
      queueTypesAction: queuesApi.getQueueTypes(entityId),
      taskQueueActions: taskApi.getTaskQueueTypes(entityId),
      drugTypesAction: drugsApi.getDrugTypes(),
      drugListAction: drugsApi.getDrugList(listPostParam),
      taskMasterActions: taskApi.getTaskMaster(),
      assigneeUserGroupListActions: taskApi.assigneeUserGroupList(assigneeUserGroup),
    };

    const {
      queueTypesAction,
      taskQueueActions,
      drugTypesAction,
      drugListAction,
      taskMasterActions,
      assigneeUserGroupListActions,
    } = yield all(actions);

    if (queueTypesAction) {
      const {
        data: { state, data },
      } = queueTypesAction;

      if (state) {
        yield put(DrugActions.getQueueTypesSuccess({ data }));
      }
    }
    if (taskQueueActions) {
      const {
        data: { state, data },
      } = taskQueueActions;

      if (state) {
        yield put(DrugActions.getTaskQueueTypesSuccess({ data }));
      }
    }
    if (drugTypesAction) {
      const {
        data: { state, data },
      } = drugTypesAction;

      if (state) {
        yield put(DrugActions.getDrugTypesSuccess(data));
      }
    }
    if (drugListAction) {
      const {
        data: { state, data },
      } = drugListAction;

      if (state) {
        yield put(DrugActions.getDrugListSuccess(data));
      }
    }
    if (taskMasterActions) {
      const {
        data: { data, state },
      } = taskMasterActions;

      if (state) {
        yield put(TaskActions.getTaskMasterSuccess({ data }));
      }
    }
    if (assigneeUserGroupListActions) {
      const {
        data: { data, state },
      } = assigneeUserGroupListActions;

      if (state) {
        yield put(TaskActions.assigneeUserGroupListSuccess({ data }));
      }
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* getDrugTypesAsync() {
  try {
    const response = yield drugsApi.getDrugTypes();
    const { state, data } = response.data;
    if (state) {
      yield put({ type: DrugActions.getDrugTypesSuccess.type, payload: data });
    }
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
  }
}
export function* getNotesTypeAsync() {
  try {
    const response = yield drugsApi.getNotesType();
    const { state, data } = response.data;
    if (state) {
      yield put({ type: DrugActions.getNotesTypeSuccess.type, payload: data });
    }
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
  }
}

export function* getActivityMasterAsync() {
  try {
    //effects will get executed in parallel
    yield put(globalActions.showSpinner());
    const [deliveryRoute, deliveryMedium, deliveryTechnology, parameter, unit] = yield all([
      drugsApi.getActivityMaster('deliveryRoute'),
      drugsApi.getActivityMaster('deliveryMedium'),
      drugsApi.getActivityMaster('deliveryTechnology'),
      drugsApi.getActivityMaster('pharmacokineticsParameter'),
      drugsApi.getActivityMaster('pharmacokineticsUnit'),
    ]);
    yield put(
      DrugActions.setActivityMaster({
        deliveryRoute,
        deliveryMedium,
        deliveryTechnology,
        parameter,
        unit,
      }),
    );
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(DrugActions.activityMasterLoader(false));
  }
}

export function* postDrugsDataAsync({
  payload: { id, body, tabData, childTabKey, pageDetails, history },
}) {
  try {
    yield put(globalActions.showSpinner());
    const { productionStatusId, previousProductionStatus } = body;
    const response = yield drugsApi.postDrugsData({ id, body });
    const { state, data, messages } = response.data;
    if (state) {
      const key = drugFeatureKeys[body.forceToFieldUpdate];
      yield put(DrugActions.postDrugsDataSuccess({ key, childTabKey, data, history }));
      if (key === 'landscapeStatuses') {
        yield put(DrugActions.getEventMasterData(id));
      }
      if (body.forceToFieldUpdate) {
        if (body.forceToFieldUpdate === updateTypeOnProdStatusSave.drugs) {
          yield put(
            globalActions.showAlert(getProdMessage(productionStatusId, previousProductionStatus)),
          );
          // TO DO: use to redirect to list page after submit
          // history &&
          //   setTimeout(() => {
          //     history.push(`/drugs`);
          //   }, 2000);
        } else if (body.forceToFieldUpdate === drugFeatureValues.DRUG_PROGRAM_LANDSCAPE) {
          yield put(globalActions.showAlert(getMessage(id, data.masterDrug)));
          yield delay(2000);
          yield put(DrugActions.getDPLData(pageDetails));
        } else {
          yield put(globalActions.showAlert(getMessage(id, data.masterDrug)));
        }
      }
      if (tabData) {
        yield delay(2000);
        yield put(DrugActions.nextFeatureSelection(tabData.value));
      }
      // TO DO: enable this to replace the id in route url and added for ingestion logic as well
      // if (id === 0 && history) {
      //   yield delay(2000);
      //   history.push(`/drugs/${data.id}`);
      // }
    } else {
      const deletedDrugName =
        body.deletedName &&
        body.deletedName.length &&
        body?.deletedNames?.map(names => names.descriptiveName);
      const message =
        messages.length > 1
          ? `${deletedDrugName?.join(', ')} already associated with trials.`
          : messages.length === 1
          ? messages[0].errorMessage
          : ALERT_MESSAGES.DRUGS_ERROR;
      let modalData = {
        isOpen: true,
        message: typeof message === 'object' ? message.message : message,
        modalType: MODAL_TYPES.ERROR,
      };
      if (body.forceToFieldUpdate === drugFeatureValues.DRUG_PROGRAM_LANDSCAPE) {
        modalData = {
          isOpen: true,
          message: messages?.map(element => element.errorMessage),
          modalListMessage:
            messages && messages.legth && messages?.map(element => element.errorMessage),
          modalType: MODAL_TYPES.ERROR_LIST,
        };
      }
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* getDrugStampingDetailsAsync({ payload: { id, body } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield drugsApi.postDrugsData({ id, body });
    const { state, data } = response.data;
    if (state) {
      yield put(DrugActions.getDrugStampingDetailsSuccess({ data }));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* deleteDrugAsync({ payload }) {
  try {
    const { drugId, postParam } = payload;
    yield put(globalActions.showSpinner());
    const response = yield drugsApi.deleteDrug(drugId);
    yield put(globalActions.hideSpinner());
    const { state, messages } = response.data;
    if (state) {
      //yield put({ type: DrugActions.deleteDrugSuccess.type, payload });
      yield put(DrugActions.getDrugList(postParam));
      yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_DELETE));
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: messages.length > 0 ? messages[0].errorMessage : ALERT_MESSAGES.DRUGS_ERROR,
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}
export function* getDrugQueueRecordsAsync({ payload: { queueDetails, isQueue } }) {
  try {
    yield put(globalActions.showSpinner());
    // const response = yield queuesApi.getDrugQueueRecords(queueDetails);
    const response = isQueue
      ? yield queuesApi.getDrugQueueRecords(queueDetails)
      : yield taskApi.getTaskDrugQueueRecords(queueDetails);
    const {
      data: { state, data },
    } = response;
    if (state) {
      isQueue
        ? yield put(DrugActions.getDrugQueueRecordsSuccess(data))
        : yield put(DrugActions.getTaskQueueRecordsSuccess(data));
      yield put(globalActions.hideSpinner());
    } else {
      isQueue
        ? yield put(DrugActions.getDrugQueueRecordsSuccess([]))
        : yield put(DrugActions.getTaskQueueRecordsSuccess([]));
      yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    isQueue
      ? yield put(DrugActions.getDrugQueueRecordsSuccess([]))
      : yield put(DrugActions.getTaskQueueRecordsSuccess([]));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
  }
}
export function* getQueueTypesAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield queuesApi.getQueueTypes(payload);
    yield put(globalActions.hideSpinner());
    const {
      data: { state, data },
    } = response;
    if (state) {
      yield put(DrugActions.getQueueTypesSuccess({ data }));
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getProgramChemicalMasterDataAsync() {
  try {
    yield put(globalActions.showSpinner());
    const [origin, countries] = yield all([
      yield drugsApi.getOrigin(),
      yield drugsApi.getCountries(),
    ]);
    const { state: originState, data: originData } = origin.data;
    const { state: countriesState, data: countriesData } = countries.data;
    if (originState) {
      yield put(DrugActions.getOriginSuccess(originData));
    }
    if (countriesState) {
      yield put(DrugActions.getCountriesSuccess(countriesData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* updateDrugLockDetails({ payload }) {
  try {
    yield drugsApi.getUserLockDetails(payload);
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
  }
}

export function* releaseDrugUserLockAysnc({ payload }) {
  try {
    yield drugsApi.unlockUserRecords(payload);
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
  }
}

export function* postExitQueueAysnc({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { queueTransactionId } = payload;
    const postExitQueueResponse = yield queuesApi.postExitQueue({ id: queueTransactionId });
    const {
      data: { state, data },
    } = postExitQueueResponse;

    if (state && data) {
      const modalData = {
        isOpen: true,
        message: 'RECORD EXIT DONE SUCCESSFULLY',
        modalType: MODAL_TYPES.SUCCESS,
      };
      yield put({ type: globalActions.showModal.type, payload: modalData });
      yield put(DrugActions.getQueueTypesAndRecords(payload));
    } else {
      yield put(globalActions.hideSpinner());
      yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
  }
}

export function* getDrugAssociationAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield drugsApi.getDrugAssociation(payload);
    const { state, data } = response.data;
    if (state) {
      yield put({ type: DrugActions.getDrugAssociationSuccess.type, payload: data });
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getDrugTrialAssociationAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield drugsApi.getDrugTrialAssociation(payload);
    const { state, data } = response.data;
    if (state) {
      yield put(DrugActions.getDrugTrialAssociationSuccess(data));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getMolecularParametersAysnc({ payload: { cd_id, smilesFormula, chemicalName } }) {
  try {
    const requestPayload = {
      inputFormat: 'smiles',
      outputFormat: 'mrv',
      structure: smilesFormula,
    };
    yield put(globalActions.showSpinner());
    const response = yield drugsApi.getMolecularParameters(requestPayload);
    const { status, data } = response;
    if (status === 200) {
      yield put(
        DrugActions.getMolecularParametersSuccess({
          ...data.dbData,
          cd_id,
          smilesFormula,
          chemicalName,
          logP: data?.logp,
          intDonorCount: data?.h_bond_donors || 0,
          intAcceptorCount: data?.h_bond_acceptors || 0,
          intRotatableBondCount: data?.rotatable_bonds || 0,
          cd_molweight: data?.weight,
          cd_formula: data?.formula,
        }),
      );
      yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_CHEM_PARAMS_SUCCESS));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_CHEM_PARAMS_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_CHEM_PARAMS_ERROR));
  }
}

export function* createMolecularStructureDataAsync({ payload: { requestData, params } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield drugsApi.createMolecularParameters(requestData);
    const { status, data } = response;
    if (status === 200) {
      yield put(DrugActions.getMolecularParameters({ ...params, cd_id: data.cd_id }));
    } else {
      yield put(globalActions.hideSpinner());
      yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_CHEM_PARAMS_ERROR));
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_CHEM_PARAMS_ERROR));
  }
}

export function* getDPLAncillaryMasterDataAsync({ payload: { group, key } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield drugsApi.getAncillaryMasterData(group, key);
    const {
      data: { data, state },
    } = response;
    if (state) {
      yield put(DrugActions.setDPLAncillaryMasterData({ data, key }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
  }
}

export function* postDPLAncillaryDataAsync({ payload: { recordId, body, primaryField } }) {
  const { postObj, group, key } = body;
  try {
    yield put(globalActions.showSpinner());
    const response = yield drugsApi.postAncillaryData(postObj, group, key);
    const { data } = response;
    if (data) {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Record already exist.',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    } else {
      const postWorkFlowObject = getAncillaryRequestPostData({
        ...body,
        recordId,
        value: postObj[primaryField],
      });
      yield put(globalActions.postAdditionalWorkFlow(postWorkFlowObject));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getDrugsProximityDataAsync({ payload: { ingestedId, duplicateIds } }) {
  try {
    yield put(globalActions.showSpinner());
    const [ingestedDetails, duplicateDetails] = yield all([
      drugsApi.getIngestedData(ingestedId),
      drugsApi.getDrugsData(duplicateIds[0]),
    ]);
    if (ingestedDetails && duplicateDetails) {
      const { data: ingestedData, state: ingestedState } = ingestedDetails.data;
      const { data: duplicatesData, state: duplicatesState } = duplicateDetails.data;
      if (ingestedState && duplicatesState) {
        yield put(
          DrugActions.getDrugsProximityDataSuccess({
            ingestedRecordData: ingestedData,
            possibleDuplicatesData: [{ ...duplicatesData }],
          }),
        );
      } else {
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: 'Something went wrong',
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Something went wrong',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
    yield put(globalActions.hideSpinner());
  }
}

export function* getDPLDataForViewAsync({ payload: { drugIds, paginationData, parentKey } }) {
  try {
    const state = yield select();
    yield put(globalActions.showSpinner());
    if (parentKey) {
      const response = yield drugsApi.getDPLData({
        drugId: drugIds[0],
        ...paginationData[0],
      });
      if (response) {
        const {
          data: { data, state: responseState },
        } = response;
        if (responseState) {
          yield put(
            DrugActions.getDPLDataForViewSuccess({
              ...state.drugs.dplViewData,
              [parentKey]: {
                ...state.drugs.dplViewData[parentKey],
                pagination: { ...omit(data, ['results', 'pageCount']) },
                tableData: data.results,
              },
            }),
          );
        } else {
          yield put(
            globalActions.showModal({
              isOpen: true,
              message: 'Something went wrong',
              modalType: MODAL_TYPES.ERROR,
            }),
          );
        }
      }
    } else {
      const [dplDetailsI, dplDetailsII] = yield all([
        drugsApi.getDPLData({ drugId: drugIds[0], ...omit(paginationData[0], ['rowCount']) }),
        drugsApi.getDPLData({ drugId: drugIds[1], ...omit(paginationData[1], ['rowCount']) }),
      ]);
      if (dplDetailsI && dplDetailsII) {
        const { data: ingestedDPLData, state: stateI } = dplDetailsI.data;
        const { data: duplicateDPLData, state: stateII } = dplDetailsII.data;
        if (stateI && stateII) {
          yield put(
            DrugActions.getDPLDataForViewSuccess({
              ...state.drugs.dplViewData,
              ingestedDPLData: {
                ...state.drugs.dplViewData.ingestedDPLData,
                drugId: drugIds[0],
                pagination: { ...omit(ingestedDPLData, ['pageCount', 'results']) },
                tableData: ingestedDPLData.results,
              },
              duplicateDPLData: {
                ...state.drugs.dplViewData.duplicateDPLData,
                drugId: drugIds[1],
                pagination: { ...omit(duplicateDPLData, ['pageCount', 'results']) },
                tableData: duplicateDPLData.results,
              },
            }),
          );
        } else {
          yield put(
            globalActions.showModal({
              isOpen: true,
              message: 'Something went wrong',
              modalType: MODAL_TYPES.ERROR,
            }),
          );
        }
      } else {
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: 'Something went wrong',
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
    yield put(globalActions.hideSpinner());
  }
}

export function* getQueueTypesAndRecordsAsync({ payload }) {
  try {
    const { queueType, entityId, rowCount, listPostParam, pageIndex, pageSize } = payload;

    const postQueueTypesResponse = yield queuesApi.getQueueTypes(entityId);
    const {
      data: { state, data },
    } = postQueueTypesResponse;

    if (state) {
      yield put(DrugActions.getQueueTypesSuccess({ data }));
      if (rowCount === 1) {
        yield put(DrugActions.setQueueExpandedList([]));
        const response = yield drugsApi.getDrugList({ payload: listPostParam });
        const { state: listState, data: listData, messages } = response.data;
        if (listState) {
          yield put(DrugActions.setSelectedQueueType(queueType));
          yield put(DrugActions.getDrugListSuccess(listData));
        } else {
          yield put(
            globalActions.showModal({
              isOpen: true,
              message: getApiErrorMessage(messages),
              modalType: MODAL_TYPES.ERROR,
            }),
          );
        }
        yield put(globalActions.hideSpinner());
      } else {
        const { id, parentId, rootKey } = { ...queueType };
        const queueDetails = queueType.lpSection
          ? { groupKey: id, rootKey, pageIndex, pageSize }
          : { queueId: id, groupKey: parentId, rootKey, pageIndex, pageSize };
        yield put(DrugActions.getDrugQueueRecords({ queueDetails }));
        yield put(DrugActions.setSelectedQueueType(queueType));
      }
    } else {
      yield put(globalActions.hideSpinner());
      yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.DRUGS_ERROR));
  }
}

export function* getDrugsCompareDataAsync({ payload: { selectedIds, isIngestedId } }) {
  try {
    yield put(globalActions.showSpinner());

    const selectedQueueAPI =
      isIngestedId === true ? drugsApi.getIngestedData : drugsApi.getDrugsData;

    const [idOneDetails, idTwoDetails, idThreeDetails, idFourDetails] = yield all([
      selectedQueueAPI(selectedIds[0]),
      selectedQueueAPI(selectedIds[1]),
      selectedIds[2] ? selectedQueueAPI(selectedIds[2]) : '',
      selectedIds[3] ? selectedQueueAPI(selectedIds[3]) : '',
    ]);

    if (idOneDetails && idTwoDetails && idThreeDetails && idFourDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      const { data: idThreeData, state: idThreeState } = idThreeDetails.data;
      const { data: idFourData, state: idFourState } = idFourDetails.data;
      if (idOneState && idTwoState && idThreeState && idFourState) {
        yield put(
          DrugActions.getDrugsCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
            compareIdThree: idThreeData,
            compareIdFour: idFourData,
          }),
        );
      }
    } else if (idOneDetails && idTwoDetails && idThreeDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      const { data: idThreeData, state: idThreeState } = idThreeDetails.data;
      if (idOneState && idTwoState && idThreeState) {
        yield put(
          DrugActions.getDrugsCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
            compareIdThree: idThreeData,
          }),
        );
      }
    } else if (idOneDetails && idTwoDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      if (idOneState && idTwoState) {
        yield put(
          DrugActions.getDrugsCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
          }),
        );
      }
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Something went wrong',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
    yield put(globalActions.hideSpinner());
  }
}

// watchers
export const watchers = [
  function* watchGetDrugListAsync() {
    yield takeEvery(DrugActions.getDrugList, getDrugListAsync);
  },
  function* watchPostDrugDissociationAsync() {
    yield takeEvery(DrugActions.postDrugDissociation, postDrugDissociationAsync);
  },
  function* watchPostDrugTransferAsync() {
    yield takeEvery(DrugActions.postDrugTransfer, postDrugTransferAsync);
  },
  function* watchGetGlobalStatusAsync() {
    yield takeEvery(DrugActions.getGlobalStatus, getGlobalStatusAsync);
  },
  function* watchGetCountriesAsync() {
    yield takeEvery(DrugActions.getCountries, getCountriesAsync);
  },
  function* watchGetProgramLandscapeMasterDataAsync() {
    yield takeEvery(DrugActions.getProgramLandscapeMasterData, getProgramLandscapeMasterDataAsync);
  },
  function* watchGetMasterDataAsync() {
    yield takeEvery(DrugActions.getMasterData, getMasterDataAsync);
  },
  function* watchGetDrugsDataAsync() {
    yield takeEvery(DrugActions.getDrugDetails, getDrugsDataAsync);
  },
  function* watchGetDPLDataAsync() {
    yield takeEvery(DrugActions.getDPLData, getDPLDataAsync);
  },
  function* watchPostDrugsDataAsync() {
    yield takeEvery(DrugActions.postDrugsData, postDrugsDataAsync);
  },
  function* watchGetDrugStampingDetailsAsync() {
    yield takeEvery(DrugActions.getDrugStampingDetails, getDrugStampingDetailsAsync);
  },
  function* watchDeleteDrugAsync() {
    yield takeEvery(DrugActions.deleteDrug, deleteDrugAsync);
  },
  function* watchGetActivityMasterAsync() {
    yield takeEvery(DrugActions.fetchActivityMaster, getActivityMasterAsync);
  },
  function* watchEventTypeAsync() {
    yield takeEvery(DrugActions.getEventTypes, getEventTypeAsync);
  },
  function* watchEventTypeAsync() {
    yield takeEvery(DrugActions.getTrends, getTrendsAsync);
  },
  function* watchEventTypeAsync() {
    yield takeEvery(DrugActions.postTrendsYear, postTrendsYearAsync);
  },
  function* watchEventTypeAsync() {
    yield takeEvery(DrugActions.saveTrendsData, saveTrendsDataAsync);
  },
  function* watchEventTypeAsync() {
    yield takeEvery(DrugActions.deleteTrendsData, deleteTrendsDataAsync);
  },
  function* watchEventTypeAsync() {
    yield takeEvery(DrugActions.deleteTrendsYearData, deleteTrendsYearDataAsync);
  },
  function* watchDevelopmentPhaseAsync() {
    yield takeEvery(DrugActions.getDevelopmentPhase, getDevelopmentPhaseAsync);
  },
  function* watchEventMasterAsync() {
    yield takeEvery(DrugActions.getEventMasterData, getEventMasterDataAsync);
  },
  function* watchGetDrugTypesAsync() {
    yield takeEvery(DrugActions.getDrugTypes, getDrugTypesAsync);
  },
  function* watchGetNotesTypeAsync() {
    yield takeEvery(DrugActions.getNotesType, getNotesTypeAsync);
  },
  function* watchGetProgramChemicalMasterDataAsync() {
    yield takeEvery(DrugActions.getChemicalMasterData, getProgramChemicalMasterDataAsync);
  },
  function* watchGetDrugQueueRecords() {
    yield takeEvery(DrugActions.getDrugQueueRecords, getDrugQueueRecordsAsync);
  },
  function* watchGetTaskDrugQueueRecords() {
    yield takeEvery(DrugActions.getTaskDrugQueueRecords, getDrugQueueRecordsAsync);
  },
  function* watchGetQueueTypes() {
    yield takeEvery(DrugActions.getQueueTypes, getQueueTypesAsync);
  },
  function* watchReleaseDrugUserLockAsync() {
    yield takeEvery(DrugActions.releaseDrugUserLock, releaseDrugUserLockAysnc);
  },
  function* watchUpdateDrugLockDetailsAsync() {
    yield takeEvery(DrugActions.updateDrugsLockDetails, updateDrugLockDetails);
  },
  function* watchGetDrugAssociationAsync() {
    yield takeEvery(DrugActions.getDrugAssociation, getDrugAssociationAsync);
  },
  function* watchGetDrugTrialAssociationAsync() {
    yield takeEvery(DrugActions.getDrugTrialAssociation, getDrugTrialAssociationAsync);
  },
  function* watchGetQueueDashboardData() {
    yield takeEvery(DrugActions.getQueueDashboardData, getQueueDashboardDataAsync);
  },
  function* watchPostExitQueueRecords() {
    yield takeEvery(DrugActions.exitQueue, postExitQueueAysnc);
  },
  function* watchGetMolecularParametersAsync() {
    yield takeEvery(DrugActions.getMolecularParameters, getMolecularParametersAysnc);
  },
  function* watchCreateMolecularDataAsync() {
    yield takeEvery(DrugActions.createMolecularStructureData, createMolecularStructureDataAsync);
  },
  function* watchGetDPLAncillaryMasterDataAsync() {
    yield takeEvery(DrugActions.getDPLAncillaryMasterData, getDPLAncillaryMasterDataAsync);
  },
  function* watchPostDPLAncillaryDataAsync() {
    yield takeEvery(DrugActions.postDPLAncillaryData, postDPLAncillaryDataAsync);
  },
  function* watchgetDrugProximityAsync() {
    yield takeEvery(DrugActions.getDrugsProximityData, getDrugsProximityDataAsync);
  },
  function* watchGetDPLViewData() {
    yield takeEvery(DrugActions.getDPLDataForView, getDPLDataForViewAsync);
  },
  function* watchGetQueueTypesAndRecordsAsync() {
    yield takeEvery(DrugActions.getQueueTypesAndRecords, getQueueTypesAndRecordsAsync);
  },
  function* watchDrugsCompareDataAsync() {
    yield takeEvery(DrugActions.getDrugsCompareData, getDrugsCompareDataAsync);
  },
];
