import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';
import {
  setSelectedTabIndex,
  resetPersonState,
  deletePersonSuccess,
  getPersonListSuccess,
  getPersonDetailsSuccess,
  getPersonMasterDataSuccess,
  savePersonDetailsSuccess,
  updatePersonDetails,
  resetPersonDetails,
  personFeatureSelection,
  personNextFeatureSelection,
  setPreviewSelection,
  getQueueRecordsSuccess,
  getTaskQueueRecordsSuccess,
  getQueueTypesSuccess,
  getTaskQueueTypesSuccess,
  getPersonIngestedDataSuccess,
  updatePersonIngestedAction,
  resetPersonIngestedData,
  updatePersonChildValues,
  setSelectedQueueType,
  getPersonLockDetails,
  getPersonList,
  getQueueRecords,
  resetPersonUserLock,
  setQueueExpandedList,
  getPersonAssociationSuccess,
  getPersonOrgAssociationSuccess,
  setQueueTransactionId,
  setPersonValidationStatus,
  getPersonProximitySuccess,
  searchPersonNoteTypeSuccess,
  savePersonDetails,
  setPersonChangeStatus,
  resetPersonProximityData,
  getPersonCompareDataSuccess,
  resetPersonCompareData,
  getPersonStampingDetailsSuccess,
  setShowPersonValidationModal,
} from '../actions/person';
import {
  getPersonDetailsForUI,
  personAPIKeys,
  setCurrentAndIngestedData,
  updatePersonStampingDetails,
  getPersonStampDates,
} from '../helpers/person';
import { resetIngestionAction, checkIfObjectIsEmpty } from '../../utils/generic/helper';
import validateTabs from '../../utils/generic/validation';

const initialState = InitialState.person;
const person = createReducer(initialState, {
  [personFeatureSelection]: (state, { payload: { value } }) => {
    state.selected = value;
  },
  [personNextFeatureSelection]: (state, { payload }) => {
    const filteredTabs = state.tabs.filter(i => i.value === payload);
    const index = state.tabs.findIndex(i => i.value === payload);
    const isSingleModeActive = state.isSinglePageMode;
    if (filteredTabs.length > 0) {
      const { isTab, selectedTabIndex, tabs } = filteredTabs[0];
      if (isTab) {
        if (selectedTabIndex < tabs.length - 1) {
          state.tabs[index].selectedTabIndex = selectedTabIndex + 1;
        } else if (selectedTabIndex === tabs.length - 1) {
          const tabValue = state.tabs[index + 1].value;
          isSingleModeActive ? (state.previewSelected = tabValue) : (state.selected = tabValue);
          state.tabs[index].selectedTabIndex = 0;
        }
      } else if (isSingleModeActive) {
        state.previewSelected = state.tabs[index + 1].value;
      } else {
        state.selected = state.tabs[index + 1].value;
      }
    }
  },
  [setSelectedTabIndex]: (state, { payload: { id, tabIndex } }) => {
    const index = state.tabs.findIndex(i => i.value === id);
    if (index > -1) {
      state.tabs[index].selectedTabIndex = tabIndex;
    }
  },
  [resetPersonState]: state => {
    const {
      selected,
      original,
      current,
      tabs,
      ingested,
      personAssociation,
      originalStampDates,
    } = initialState;
    state.selected = selected;
    state.tabs = tabs;
    state.original = original;
    state.current = current;
    state.ingested = ingested;
    state.personAssociation = personAssociation;
    state.isStamped = false;
    state.originalStampDates = originalStampDates;
  },

  [getPersonList]: state => {
    state.dashboard.queues.queueRecords = {};
  },
  [getPersonListSuccess]: (state, { payload }) => {
    state.dashboard.queues.queueRecords = payload;
  },

  [getPersonDetailsSuccess]: (state, { payload }) => {
    const personDetails = getPersonDetailsForUI(payload);
    state.original = { ...state.original, ...personDetails };
    state.current = { ...state.current, ...personDetails };

    state.originalStampDates = getPersonStampDates(state.original.personBasicInfo.recordDates);
  },

  [setShowPersonValidationModal]: (state, { payload }) => {
    state.original.showPersonValidationModal = payload.isOpen;
    state.original.personValidationMessages = payload.messages;
  },

  [getPersonMasterDataSuccess]: (state, { payload }) => {
    state.masterData = payload;
  },

  [deletePersonSuccess]: (state, { payload }) => {
    const results = state.dashboard.queues.queueRecords.results;
    state.dashboard.queues.queueRecords.results = results.filter(person => person.id !== payload);
  },

  [savePersonDetails]: (state, { payload: { key } }) => {
    const selected = state.tabs.find(tab => tab.key === key);
    if (selected) {
      selected.hasError = false;
    }
  },

  [savePersonDetailsSuccess]: (state, { payload: { data, key } }) => {
    const convertedData = getPersonDetailsForUI(data);
    const { ingested } = initialState;
    state.original = { ...state.original, ...convertedData, ingestedId: state.current.ingestedId };
    if (state.current.id === 0 && state.ingested.id === 0) {
      state.current = state.original;
    }
    state.current.id = data.id;
    state.current.prodStatusID = data.prodStatusID;
    state.current[key] = convertedData[key];
    state.current.personBasicInfo = {
      ...state.current.personBasicInfo,
      recordDates: { ...state.original.personBasicInfo.recordDates },
      supportingUrls: [...state.original.personBasicInfo.supportingUrls],
    };

    state.originalStampDates = getPersonStampDates(state.original.personBasicInfo.recordDates);
    state.isStamped = false;

    const tabIndex = state.tabs.findIndex(tab => tab.key === key);
    if (tabIndex > -1) {
      if (state.ingested[key] && ingested[key]) {
        state.ingested[key] = ingested[key];
        state.tabs[tabIndex].hasIngestedData = false;
      }
      state.tabs[tabIndex].hasUnSavedData = false;
    }
  },
  [getPersonStampingDetailsSuccess]: (state, { payload: { data } }) => {
    state.isStamped = true;
    const { current, original } = updatePersonStampingDetails(
      {
        ...data.personBasicInfo,
        lastFullReviewBy: data.personBasicInfo.lastFullReviewedBy,
        nextReviewDate: data.personBasicInfo.nextFullReviewDate,
      },
      state.current,
      state.original,
    );
    state.current = current;
    state.original = original;
  },
  [updatePersonDetails]: (state, { payload: { key, data } }) => {
    state.current[key] = data;
  },
  [setPersonValidationStatus]: (state, { payload: tabs = [] }) => {
    tabs.forEach(({ index, isValid }) => {
      state.tabs[index].hasError = !isValid;
    });
  },
  [setPersonChangeStatus]: (state, { payload: tabs = [] }) => {
    tabs.forEach(({ index, hasUnSavedData }) => {
      state.tabs[index].hasUnSavedData = hasUnSavedData;
    });
  },
  [resetPersonDetails]: (state, { payload }) => {
    const selected = state.tabs.find(tab => tab.key === payload);
    if (selected && selected.hasIngestedData) {
      selected.hasUnSavedData = false;
      const { ingestedData, updatedCurrentData } = resetIngestionAction(
        state.ingested[payload],
        state.current[payload],
        state.original[payload],
      );
      state.ingested[payload] = ingestedData;
      state.current[payload] = updatedCurrentData;
    } else {
      if (selected) {
        selected.hasUnSavedData = false;
        selected.hasError =
          selected.hasError &&
          Boolean(validateTabs.person[payload]) &&
          !checkIfObjectIsEmpty(
            validateTabs.person[payload](state.original[payload], state.original),
          );
      }
      state.current[payload] = state.original[payload];
    }
  },
  [setPreviewSelection]: (state, { payload: { val, isActive } }) => {
    state.isSinglePageMode = isActive;
    state.previewSelected = val;
  },
  [setSelectedQueueType]: (state, { payload }) => {
    state.dashboard.queues.queueTypeSelected = payload;
  },
  [setQueueExpandedList]: (state, { payload }) => {
    state.dashboard.queues.queueExpandedList = payload;
  },
  [getQueueRecords]: (state, { payload: { emptyRecords } }) => {
    let queueRecords = emptyRecords ? {} : state.dashboard.queues.queueRecords;
    state.dashboard.queues.queueRecords = queueRecords;
  },
  [getQueueRecordsSuccess]: (state, { payload }) => {
    state.dashboard.queues.queueRecords = payload;
  },
  [getTaskQueueRecordsSuccess]: (state, { payload }) => {
    state.dashboard.taskQueues.taskQueueRecords = payload;
  },
  [setQueueTransactionId]: (state, { payload }) => {
    state.queueTransactionId = payload;
  },
  [getQueueTypesSuccess]: (state, { payload }) => {
    let { data = [] } = payload;
    state.dashboard.queues.queueTypes = data;
  },
  [getTaskQueueTypesSuccess]: (state, { payload }) => {
    let { data = [] } = payload;
    state.dashboard.taskQueues.taskQueueTypes = data;
  },
  [getPersonIngestedDataSuccess]: (state, { payload }) => {
    const { updatedIngestedData, updatedCurrentData, updatedTabs } = setCurrentAndIngestedData(
      { ...state.current },
      { ...payload },
      [...state.tabs],
    );
    state.ingested = { ...updatedIngestedData };
    state.current = { ...state.current, ...updatedCurrentData };
    state.tabs = [...updatedTabs];
  },
  [updatePersonIngestedAction]: (state, { payload: { parentKey, childKey, ingestionAction } }) => {
    const ingestedData = state.ingested[parentKey];
    ingestedData.fieldActions[childKey] = ingestionAction;
    state.ingested[parentKey] = ingestedData;
  },
  [updatePersonChildValues]: (state, { payload: { parentKey, childKey, data } }) => {
    const currentData = state.current[parentKey];
    currentData[childKey] = data;
    state.current[parentKey] = currentData;
  },
  [resetPersonIngestedData]: state => {
    const { ingested } = initialState;
    state.ingested = ingested;
  },
  [getPersonLockDetails]: (state, { payload: { isEditable, lockedBy, userLockPeriod } }) => {
    const updatedData = {
      isLocked: !isEditable,
      lockedBy,
      userLockPeriod,
    };
    state.personLockDetails = updatedData;
  },
  [resetPersonUserLock]: state => {
    const { personLockDetails } = initialState;
    state.personLockDetails = personLockDetails;
  },
  [getPersonAssociationSuccess]: (
    state,
    { payload: { pageSize, currentPage, rowCount, results } },
  ) => {
    state.personAssociation.associatedTrials = {
      associatedTrialsData: results && results.length > 0 ? results[0].result || [] : [],
      lastTrialStartDate: results && results.length > 0 ? results[0].lastTrialStartDate : '',
      pagination: {
        pageSize,
        pageIndex: currentPage,
        rowCount,
      },
    };
  },
  [getPersonOrgAssociationSuccess]: (
    state,
    { payload: { pageSize, currentPage, rowCount, results } },
  ) => {
    state.personAssociation.associatedOrganizations = {
      associatedOrganizationsData: results || [],
      pagination: {
        pageSize,
        pageIndex: currentPage,
        rowCount,
      },
    };
  },
  [searchPersonNoteTypeSuccess]: (state, { payload: { data } }) => {
    if (data) {
      state.original.notes = data;
      state.current.notes = data;
    }
  },
  [getPersonProximitySuccess]: (state, { payload }) => {
    state.proximityComparisonData = payload;
  },
  [resetPersonProximityData]: state => {
    state.proximityComparisonData = { ...initialState.proximityComparisonData };
  },

  [getPersonCompareDataSuccess]: (state, { payload }) => {
    state.compareRecordsData = payload;
  },
  [resetPersonCompareData]: state => {
    state.compareRecordsData = { ...initialState.compareRecordsData };
  },
});

export default person;
