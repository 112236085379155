import { createAction } from '@reduxjs/toolkit';

// action definitions
const countryRoot = 'country/tabs/';

export const getCountryList = createAction(`${countryRoot}getCountryList`);
export const getCountryListSuccess = createAction(`${countryRoot}getCountryListSuccess`);
export const setError = createAction(`${countryRoot}error`);

export const saveCountryDetails = createAction(`${countryRoot}saveCountryDetails`);
export const saveCountryDetailsSuccess = createAction(`${countryRoot}saveCountryDetailsSuccess`);
export const getCountryMasterData = createAction(`${countryRoot}getCountryMasterData`);
export const getCountryMasterDataSuccess = createAction(
  `${countryRoot}getCountryMasterDataSuccess`,
);

export const getCountryDetails = createAction(`${countryRoot}getCountryDetails`);
export const getCountryDetailsSuccess = createAction(`${countryRoot}getCountryDetailsSuccess`);
export const setSelectedTabIndex = createAction(`${countryRoot}setSelectedTabIndex`);
export const countryNextFeatureSelection = createAction(`${countryRoot}goToNextFeature`);
export const countryFeatureSelection = createAction(`${countryRoot}selected`);

export const deleteCountry = createAction(`${countryRoot}deleteCountry`);
export const deleteCountrySuccess = createAction(`${countryRoot}deleteCountrySuccess`);

export const validateCountry = createAction(`${countryRoot}validateCountry`);
export const validateCountrySuccess = createAction(`${countryRoot}validateCountrySuccess`);
export const setCountryValidationStatus = createAction(`${countryRoot}setCountryValidationStatus`);
export const setCountryUnSavedStatus = createAction(`${countryRoot}setCountryUnSavedStatus`);
export const setErrorDetails = createAction(`${countryRoot}serErrorDetails`);
export const publishCountry = createAction(`${countryRoot}publishCountry`);
export const publishCountrySuccess = createAction(`${countryRoot}publishCountrySuccess`);
export const moveOutofScopeCountry = createAction(`${countryRoot}moveOutofScopeCountry`);
export const moveOutofScopeCountrySuccess = createAction(
  `${countryRoot}moveOutofScopeCountrySuccess`,
);
export const getStagedIdCountry = createAction(`${countryRoot}getStagedIdCountry`);
export const getStagedIdCountrySuccess = createAction(`${countryRoot}getStagedIdCountrySuccess`);
export const resetStagedIdCountry = createAction(`${countryRoot}resetStagedIdCountry`);

export const getCountryRecordChangeFieldsList = createAction(
  `${countryRoot}getCountryRecordChangeFieldsList`,
);
export const countryRecordChangeFldsListSuccess = createAction(
  `${countryRoot}countryRecordChangeFldsListSuccess`,
);
export const getCountryStampingDetails = createAction(`${countryRoot}getCountryStampingDetails`);
export const getCountryStampingDetailsSuccess = createAction(
  `${countryRoot}getCountryStampingDetailsSuccess`,
);
export const updateCountryRecordDatesDetail = createAction(
  `${countryRoot}updateCountryRecordDatesDetail`,
);

// Locking Actions
export const getCountryUserLockDetails = createAction(`${countryRoot}getCountryUserLockDetails`);
export const releaseCountryUserLock = createAction(`${countryRoot}releaseCountryUserLock`);
export const resetCountryUserLock = createAction(`${countryRoot}resetCountryUserLock`);
export const updateCountryLockDetails = createAction(`${countryRoot}updateCountryLockDetails`);
