const trialIngestionFields = [
  // Trial Summary
  {
    fieldId: 6001,
    fieldName: 'name',
  },
  {
    fieldId: 6002,
    fieldName: 'source',
  },
  {
    fieldId: 6003,
    fieldName: 'protocolIds',
  },
  {
    fieldId: 6004,
    fieldName: 'therapeuticDiseasePatients',
  },
  {
    fieldId: 6005,
    fieldName: 'phase',
  },
  {
    fieldId: 6006,
    fieldName: 'globalStatus',
  },
  {
    fieldId: 6007,
    fieldName: 'primaryDrugs',
  },
  {
    fieldId: 6008,
    fieldName: 'secondaryDrugs',
  },
  {
    fieldId: 6009,
    fieldName: 'sponsors',
  },
  {
    fieldId: 6010,
    fieldName: 'collaborators',
  },
  {
    fieldId: 6011,
    fieldName: 'associatedCros',
  },
  {
    fieldId: 6012,
    fieldName: 'tags',
  },
  {
    fieldId: 6013,
    fieldName: 'locations',
  },
  {
    fieldId: 6014,
    fieldName: 'primaryUrl',
  },
  {
    fieldId: 6015,
    fieldName: 'supportingUrls',
  },
  // Trial Objective
  {
    fieldId: 6016,
    fieldName: 'title',
  },
  {
    fieldId: 6017,
    fieldName: 'primaryEndPointDetail',
  },
  {
    fieldId: 6018,
    fieldName: 'objectivePrimaryTerms',
  },
  {
    fieldId: 6019,
    fieldName: 'secondaryEndPointDetail',
  },
  {
    fieldId: 6020,
    fieldName: 'objectiveSecondaryTerms',
  },
  // Trial Outcome
  {
    fieldId: 6021,
    fieldName: 'outcomeStatuses',
  },
  {
    fieldId: 6022,
    fieldName: 'detailsDate',
  },
  {
    fieldId: 6023,
    fieldName: 'detail',
  },
  // Trial Timing
  {
    fieldId: 6024,
    fieldName: 'reportedSites', // ingestion not done for this field
  },
  {
    fieldId: 6025,
    fieldName: 'identifiedSites', // ingestion not done for this field
  },
  {
    fieldId: 6026,
    fieldName: 'actualStartDate', // ingestion not done for this field
  },
  {
    fieldId: 6027,
    fieldName: 'anticipatedStartDate', // ingestion not done for this field
  },
  {
    fieldId: 6028,
    fieldName: 'actualEnrollmentCloseDate', // ingestion not done for this field
  },
  {
    fieldId: 6029,
    fieldName: 'anticipatedEnrollmentCloseDate', // ingestion not done for this field
  },
  {
    fieldId: 6030,
    fieldName: 'actualPrimaryCompletionDate', // ingestion not done for this field
  },
  {
    fieldId: 6031,
    fieldName: 'anticipatedPrimaryCompletionDate', // ingestion not done for this field
  },
  {
    fieldId: 6032,
    fieldName: 'actualPrimaryEndpointReported', // ingestion not done for this field
  },
  {
    fieldId: 6033,
    fieldName: 'anticipatedPrimaryEndpointReported', // ingestion not done for this field
  },
  {
    fieldId: 6034,
    fieldName: 'actualFullCompletionDate', // ingestion not done for this field
  },
  {
    fieldId: 6035,
    fieldName: 'anticipatedFullCompletionFinalDate', // ingestion not done for this field
  },
  {
    fieldId: 6036,
    fieldName: 'actualEnrollmentDuration', // ingestion not done for this field
  },
  {
    fieldId: 6037,
    fieldName: 'anticipatedEnrollmentDuration', // ingestion not done for this field
  },
  {
    fieldId: 6038,
    fieldName: 'actualTreatmentDuration', // ingestion not done for this field
  },
  {
    fieldId: 6039,
    fieldName: 'anticipatedTreatmentDuration', // ingestion not done for this field
  },
  {
    fieldId: 6040,
    fieldName: 'actualTimeToPublish', // ingestion not done for this field
  },
  {
    fieldId: 6041,
    fieldName: 'anticipatedTimeToPublish', // ingestion not done for this field
  },
  // Patient Population
  {
    fieldId: 6042,
    fieldName: 'patientPopulation',
  },
  {
    fieldId: 6043,
    fieldName: 'inclusionCriteria',
  },
  {
    fieldId: 6044,
    fieldName: 'exclusionCriteria',
  },
  {
    fieldId: 6045,
    fieldName: 'targetAccrual',
  },
  {
    fieldId: 6046,
    fieldName: 'interimAccrual',
  },
  {
    fieldId: 6047,
    fieldName: 'date',
  },
  {
    fieldId: 6048,
    fieldName: 'actualAccrual',
  },
  {
    fieldId: 6049,
    fieldName: 'dispositionOfPatients',
  },
  {
    fieldId: 6050,
    fieldName: 'priorConcurrentTherapy',
  },
  {
    fieldId: 6051,
    fieldName: 'patientGender',
  },
  {
    fieldId: 6052,
    fieldName: 'minimumAge',
  },
  {
    fieldId: 6053,
    fieldName: 'minimumAgeUnit',
  },
  {
    fieldId: 6054,
    fieldName: 'maximumAge',
  },
  {
    fieldId: 6055,
    fieldName: 'maximumAgeUnit',
  },
  // Trial Treatment Plan
  {
    fieldId: 6056,
    fieldName: 'studyKeywords',
  },
  {
    fieldId: 6057,
    fieldName: 'studyDesign',
  },
  {
    fieldId: 6058,
    fieldName: 'treatmentPlan',
  },
  {
    fieldId: 6059,
    fieldName: 'armDetails',
  },
  // Trial Contacts
  {
    fieldId: 6060,
    fieldName: 'contactList',
  },
  {
    fieldId: 6061,
    fieldName: 'isContactReviewed', // ingestion not done for this field
  },
  // Trial Results
  {
    fieldId: 6062,
    fieldName: 'resultsData',
  },
  // Trial Notes
  {
    fieldId: 6063,
    fieldName: 'notesData',
  },
  // New field added - TARGET ACCRUAL COMMENTS
  {
    fieldId: 6064,
    fieldName: 'targetAccrualComment',
  },
  // New field added - INTERIM ACCRUAL COMMENTS
  {
    fieldId: 6067,
    fieldName: 'interimAccrualComment',
  }

];

export default trialIngestionFields;
