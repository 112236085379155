import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { isValidDate } from '../../../../utils/generic/helper';

export const validate = (values, nextReviewDate) => {
  const errors = {};
  if (!values.reasonForUpdate) {
    errors.reasonForUpdate = 'Please enter reason for update';
  }
  if (!values.reasonForUpdateDescription) {
    errors.reasonForUpdateDescription = 'Please enter description';
  }
  if (values.reasonForUpdateDescription && values.reasonForUpdateDescription.length > 400) {
    errors.reasonForUpdateDescription = 'Please enter only 400 characters';
  }
  if (nextReviewDate && !isValidDate(nextReviewDate)) {
    errors.nextFullReviewDate = 'Please enter a valid date (dd-MMM-yyyy)';
  }
  return errors;
};

export const initialValues = {
  id: 0,
  name: '',
  personType: null,
};

export const layoutSchema = {
  layout: 'row',
  className: 'person-basic-info-container mb-20',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: 'person-fullname-row mt-20',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      headerProps: {
        fontStyle: 'bold',
        size: '16',
      },
      content: [
        {
          layout: 'reasonForUpdate',
          size: 4,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      className: 'person-name-row',
      size: 12,
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'reasonForUpdateDescription',
          size: 4,
          className: '',
        },
      ],
    },
  ],
};

export const formSchema = {
  reasonForUpdate: {
    name: 'reasonForUpdate',
    type: fieldTypes.select,
    label: 'REASON FOR UPDATEs',
    options: [
      { id: 1, label: 'NEW', value: 'new' },
      { id: 2, label: 'Formating change', value: 'formatingChange' },
    ],
    props: {
      height: 'ht-lg',
      isMandatory: true,
      isSearchable: true,
      isClearable: true,
    },
  },
  reasonForUpdateDescription: {
    name: 'reasonForUpdateDescription',
    type: fieldTypes.text,
    label: 'REASON FOR UPDATE - DESCRIPTION',
    props: {
      height: 'ht-lg',
      isMandatory: true,
    },
  },
};
