import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import FeatureNotes from '../../../../generic/features/notes';
import FeatureFooter from '../../../../framework/featureFooter';
import {
  saveCompanyDetails,
  resetCompanyData,
  updateCompanyDetails,
} from '../../../../store/actions/company';
import ClearConfirmation from '../../../../generic/modals/clearConfirmation';
import { isDataEqual } from '../../../../utils/generic/helper';
import { showModal } from '../../../../store/actions/globalActions';
import { ALERT_MESSAGES } from '../../../../utils/generic/constants';
import './index.scss';

const Notes = forwardRef(({ data, permissions: { disableEdit } }, ref) => {
  const dispatch = useDispatch();
  const {
    current: { notes, id },
    original: { notes: originalNotes },
    masterData,
    recordStartDateTime,
  } = useSelector(state => state.company, shallowEqual);
  const { notesTypes = [] } = masterData;
  const [clearModal, setClearModal] = useState(false);
  const [rowId, setRowId] = useState(-1);

  const gridRef = useRef(null);

  const hasNotesChanged = () => !isDataEqual(notes, originalNotes, 'array');
  const isChanged = () =>
    gridRef.current.isChanged() || !isDataEqual(notes, originalNotes, 'array');

  useImperativeHandle(ref, () => ({
    isChanged,
  }));

  const saveNotes = tabData => {
    if (hasNotesChanged()) {
      dispatch(
        saveCompanyDetails({
          request: { id, updateType: 3, notes, recordStartDateTime },
          tabData,
          key: 'notes',
        }),
      );
    } else {
      dispatch(showModal(ALERT_MESSAGES.NOTES_SAVE_ERROR));
    }
  };

  const handleClear = () => {
    setClearModal(true);
  };

  const proceedToClear = status => {
    if (status) {
      dispatch(resetCompanyData('notes'));
      gridRef.current.reset();
    } else {
      setClearModal(false);
    }
  };

  return (
    <div className="company-notes-wrapper">
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item xs={12} className="separate-line">
            <FeatureFooter
              handleSaveAndNext={() => saveNotes(data)}
              handleSave={() => saveNotes(null)}
              handleClear={() => handleClear()}
              disableNext
              disabled={disableEdit}
            />
            <div className="company-notes-form">
            <FeatureNotes
              notesData={notes}
              noteTypes={notesTypes}
              ref={gridRef}
              updateNotes={(notesData, flag) => {
                dispatch(
                  updateCompanyDetails({
                    key: 'notes',
                    data: flag
                      ? notesData.map(item => ({
                          id: rowId,
                          ...item,
                        }))
                      : notesData,
                  }),
                );
                setRowId(rowId - 1);
              }}
              disableEdit={disableEdit}
            />
          </div>
        </Grid>
      </Grid>
      {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
    </div>
  );
});

Notes.propTypes = {
  data: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};
Notes.displayName = 'Notes';

export default Notes;
