import { createAction } from '@reduxjs/toolkit';

// action definitions
const orgRoot = 'organization/tabs/';
export const setSelectedTabIndex = createAction(`${orgRoot}setSelectedTabIndex`);
export const nextFeatureSelection = createAction(`${orgRoot}goToNextFeature`);
export const featureSelection = createAction(`${orgRoot}selected`);

//landing page(list) related actions
export const saveOrganizationData = createAction(`${orgRoot}saveOrganizationData`);
export const getOrganizationList = createAction(`${orgRoot}getOrganizationList`);
export const getOrganizationListSuccess = createAction(`${orgRoot}getOrganizationListSuccess`);
export const resetOrganizationState = createAction(`${orgRoot}resetOrganizationState`);
export const deleteOrganization = createAction(`${orgRoot}deleteOrganization`);
export const deleteMultipleOrganization = createAction(`${orgRoot}deleteMultipleOrganization`);
export const deleteOrganizationSuccess = createAction(`${orgRoot}deleteOrganizationSuccess`);
export const deleteMultipleOrganizationSuccess = createAction(
  `${orgRoot}deleteMultipleOrganizationSuccess`,
);
export const setError = createAction(`${orgRoot}error`);

//details page related actions
export const updateOrganizationDetails = createAction(`${orgRoot}updateOrganizationDetails`);
export const resetOrganizationDetails = createAction(`${orgRoot}resetOrganizationDetails`);

export const fetchOrganizationFeatures = createAction(`${orgRoot}fetchOrganizationFeatures`);
export const fetchOrganizationFeatureSuccess = createAction(
  `${orgRoot}fetchOrganizationFeatureSuccess`,
);

export const postOrganization = createAction(`${orgRoot}postOrganization`);
export const postOrganizationSuccess = createAction(`${orgRoot}postOrganizationSuccess`);
export const searchOrganizationNoteType = createAction(`${orgRoot}searchNoteType`);
export const searchOrganizationNoteTypeSuccess = createAction(`${orgRoot}searchNoteTypeSuccess`);

export const getOrganizationStampingDetails = createAction(
  `${orgRoot}getOrganizationStampingDetails`,
);
export const getOrganizationStampingDetailsSuccess = createAction(
  `${orgRoot}getOrganizationStampingDetailsSuccess`,
);

export const getMasterData = createAction(`${orgRoot}getMasterData`);
export const getMasterDataSuccess = createAction(`${orgRoot}getMasterDataSuccess`);

export const getAddressValidation = createAction(`${orgRoot}getAddressValidation`);
export const setAddressValidation = createAction(`${orgRoot}setAddressValidation`);
export const setAddressValidationSuccess = createAction(`${orgRoot}setAddressValidationSuccess`);
export const clearAddressValidation = createAction(`${orgRoot}clearAddressValidation`);

export const setOrganizationValidationStatus = createAction(
  `${orgRoot}setOrganizationValidationStatus`,
);
export const setOrganizationChangeStatus = createAction(`${orgRoot}setOrganizationChangeStatus`);

//details page -- view tree action
export const getOrganizationTree = createAction(`${orgRoot}getOrganizationTree`);
export const getOrganizationTreeSuccess = createAction(`${orgRoot}getOrganizationTreeSuccess`);

export const setPreviewSelection = createAction(`${orgRoot}setPreviewSelection`);

//Queues
export const setSelectedQueueType = createAction(`${orgRoot}setSelectedQueueType`);
export const getQueueDashboardData = createAction(`${orgRoot}getQueueDashboardData`);
export const exitQueue = createAction(`${orgRoot}exitQueue`);
export const setQueueExpandedList = createAction(`${orgRoot}setQueueExpandedList`);
export const setQueueTransactionId = createAction(`${orgRoot}setQueueTransactionId`);

export const getOrganizationQueueRecords = createAction(`${orgRoot}getQueueRecords`);
export const getOrganizationQueueRecordsSuccess = createAction(`${orgRoot}getQueueRecordsSuccess`);
export const getTaskOrganizationQueueRecords = createAction(
  `${orgRoot}getTaskOrganizationQueueRecords`,
);
export const getTaskQueueRecordsSuccess = createAction(`${orgRoot}getTaskQueueRecordsSuccess`);
export const getQueueTypes = createAction(`${orgRoot}getQueueTypes`);
export const getTaskQueueTypes = createAction(`${orgRoot}getTaskQueueTypes`);
export const getTaskQueueTypesSuccess = createAction(`${orgRoot}getTaskQueueTypesSuccess`);
export const getQueueTypesSuccess = createAction(`${orgRoot}getQueueTypesSuccess`);
export const getQueueTypesAndRecords = createAction(`${orgRoot}getQueueTypesAndRecords`);

//Ingestion actions
export const getOrganizationIngestedDataSuccess = createAction(
  `${orgRoot}getOrganizationIngestedDataSuccess`,
);
export const updateOrganizationIngestedAction = createAction(
  `${orgRoot}updateOrganizationIngestedAction`,
);
export const updateOrganizationChildValues = createAction(
  `${orgRoot}updateOrganizationChildValues`,
);
export const resetOrganizationIngestedData = createAction(
  `${orgRoot}resetOrganizationIngestedData`,
);

//Locking Actions
export const getOrganizationUserLockDetails = createAction(
  `${orgRoot}getOrganizationUserLockDetails`,
);
export const releaseOrganizationUserLock = createAction(`${orgRoot}releaseOrganizationUserLock`);
export const resetOrganizationUserLock = createAction(`${orgRoot}resetOrganizationUserLock`);
export const updateOrganizationLockDetails = createAction(
  `${orgRoot}updateOrganizationLockDetails`,
);

export const getCurrentAssociatedPeople = createAction(`${orgRoot}getCurrentAssociatedPeople`);
export const getCurrentAssociatedPeopleSuccess = createAction(
  `${orgRoot}getCurrentAssociatedPeopleSuccess`,
);
export const getOngoingAssociatedTrial = createAction(`${orgRoot}getOngoingAssociatedTrial`);
export const getOngoingAssociatedTrialSuccess = createAction(
  `${orgRoot}getOngoingAssociatedTrialSuccess`,
);
export const getAllAssociatedTrial = createAction(`${orgRoot}getAllAssociatedTrial`);
export const getAllAssociatedTrialSuccess = createAction(`${orgRoot}getAllAssociatedTrialSuccess`);

export const resetOrganizationAssociation = createAction(`${orgRoot}resetOrganizationAssociation`);

// proximity actions
export const getOrgProximityData = createAction(`${orgRoot}getOrgProximityData`);
export const getOrgProximityDataSuccess = createAction(`${orgRoot}getOrgProximityDataSuccess`);
export const resetOrgProximityData = createAction(`${orgRoot}resetOrgProximityData`);

// compare actions
export const getOrgCompareData = createAction(`${orgRoot}getOrgCompareData`);
export const getOrgCompareDataSuccess = createAction(`${orgRoot}getOrgCompareDataSuccess`);
export const resetOrgCompareData = createAction(`${orgRoot}resetOrgCompareData`);
