import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '../InputLabel';
import IngestionActions from '../../ingestionActions';
import './index.scss';

const InputTextAreaAdvanced = ({
  name,
  text,
  label,
  rowsMin,
  rowsMax,
  isError,
  errorMessage,
  size,
  isMandatory,
  onBlur,
  isDisabled,
  setFieldValue,
  ingestionAction,
  handleIngestionActions,
  isIngestionActionDisabled,
}) => {
  // TO DO: need to pass some prop instead of using label to differentiate between multiple textarea's in same page
  const inputTextAreaId = label
    ? `text-area-advanced-${label.toLowerCase()}`
    : 'text-area-advanced';
  const handleBlur = () => {
    if (!isDisabled) {
      const element = document.getElementById(inputTextAreaId);
      const value = element && element.innerText ? element.innerText : '';
      if (setFieldValue) {
        setFieldValue(name, value)
      }
      onBlur(value);
    }
  };

  return (
    <div name={name} className="input-text-area-advanced">
      {label && (
        <InputLabel
          labelFor="inputTextAreaAdvanced"
          text={label}
          size={size}
          isMandatory={isMandatory}
        />
      )}
      <IngestionActions
        isDisabled={isIngestionActionDisabled}
        ingestionAction={ingestionAction}
        handleIngestionActions={handleIngestionActions}
      >
        <div
          id={inputTextAreaId}
          contentEditable={!isDisabled}
          className={`input-text-area-advanced-control ${isDisabled ? 'disabled' : ''} ${isError ? 'error' : ''
            }`}
          style={{ height: `${rowsMin * 25}px`, maxHeight: `${rowsMax * 25}px` }}
          onBlur={handleBlur}
          dangerouslySetInnerHTML={{ __html: name === 'historicalAuditData' ? text : text?.replace(/</g, '&lt;').replace(/>/g, '&gt;') }}
        ></div>
      </IngestionActions>
      {isError && <p className="error-text">{errorMessage}</p>}
    </div>
  );
};

InputTextAreaAdvanced.propTypes = {
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onBlur: PropTypes.func,
  rowsMin: PropTypes.number,
  rowsMax: PropTypes.number,
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  isMandatory: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  ingestionAction: PropTypes.string,
  handleIngestionActions: PropTypes.func,
  isIngestionActionDisabled: PropTypes.bool,
};
InputTextAreaAdvanced.defaultProps = {
  rowsMin: 10,
  rowsMax: 20,
  label: '',
  size: '16',
  isMandatory: false,
  isError: false,
  errorMessage: '',
  ingestionAction: 'none',
  onBlur: () => { },
  handleIngestionActions: () => { },
  isIngestionActionDisabled: false,
};

export default InputTextAreaAdvanced;
