import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import ListView from '../../../../../../framework/listView';
import Link from '../../../../../../framework/link';
import { DNADataGrid, DefaultCellRender } from '../../../../../../framework';
import { getPersonAssociation } from '../../../../../../store/actions/person';
import PaginationWrapper from '../../../../../../generic/features/paginationWrapper';
import AllAssociatedTrials from '../../../../../organization/features/associations/tabs/allAssociatedTrials';
import './index.scss';

const AssociatedTrials = ({ data }) => {
  const {
    current: { id: personid },
    personAssociation: {
      associatedTrials: {
        associatedTrialsData,
        lastTrialStartDate,
        pagination,
        pagination: { pageSize, pageIndex },
      },
    },
  } = useSelector(state => state.person, shallowEqual);

  const history = useHistory();

  const dispatch = useDispatch();
  const [pageDetails, setPageDetails] = useState({ pageIndex: 1, pageSize: 50 })
  const currPage = pageDetails.pageIndex;
  const currpageSize = pageDetails.pageSize;
  const currentPageRef = useRef({ pageIndex: currPage, pageSize: currpageSize })
  const [sortedColumnName, setSortedColumnName] = useState('');
  const [sortingOrder, setSortingOrder] = useState(false);
  const prevPageState = useRef({ prevSortingColumn: '', prevSortingOrder: false })

  useEffect(() => {
    if (associatedTrialsData.length === 0) {
      dispatch(
        getPersonAssociation({
          pageSize,
          pageIndex,
          entityPrimaryKey: personid,
          sortOrder: [{ columnName: 'All', isDescendingOrder: false }]
        }),
      );
    }
  }, []);

  useEffect(() => {
    currentPageRef.current = { pageIndex: currPage, pageSize: currpageSize }
  }, [currPage, currpageSize])

  const navigateToTrial = id => {
    history.push(`/clinicalTrials/${id}`);
  };

  let prevSortingOrder = false;
  let prevSortingColumn = '';
  const handleTableHeaderClick = (columnName, isDescendingOrder) => {
    setSortedColumnName(columnName)
    setSortingOrder(isDescendingOrder)
    // if condition added to avoid dispatching the action multiple times due to comparator function
    if (prevSortingOrder !== isDescendingOrder || prevSortingColumn !== columnName) {
      prevSortingColumn = columnName
      prevSortingOrder = isDescendingOrder
      dispatch(
        getPersonAssociation({
          pageIndex: currentPageRef.current.pageIndex,
          pageSize: currentPageRef.current.pageSize,
          entityPrimaryKey: personid,
          sortOrder: [
            {
              columnName: columnName,
              isDescendingOrder: isDescendingOrder,
            },
          ],
        }),
      );
    };
  }

  const handleSortChanged = (args) => {
    const { api } = args;
    const sortState = api.getSortModel();
    if (sortState.length === 0) {
      dispatch(
        getPersonAssociation({
          pageIndex: currentPageRef.current.pageIndex,
          pageSize: currentPageRef.current.pageSize,
          entityPrimaryKey: personid,
          sortOrder: [
            {
              columnName: "All",
              isDescendingOrder: false,
            },
          ],
        }),
      );
    };
    setSortedColumnName('')
    setSortingOrder(false)
  }

  const columnDefs = ({ handleTableHeaderClick }) => [
    {
      headerName: 'TRIAL ID',
      field: 'trialId',
      width: 100,
      sortable: true,
      cellRenderer: 'Link',
      cellRendererParams: ({ data }) => {
        if (data.trialId) {
          return {
            link: data.trialId ? data.trialId.toString() : '',
            onClick: () => {
              navigateToTrial(data.trialId);
            },
            customTextToolTip: (data && data.title) || '',
          };
        }
      },
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('trail_id', isDescendingOrder)
      }
    },
    {
      headerName: 'DISEASE',
      headerClass: 'diseaseTypesAssociatedPersonTrials',
      field: 'diseaseTypes',
      width: 100,
      cellRenderer: 'ListView',
      cellRendererParams: ({ data }) => {
        return {
          list: data.diseaseTypes,
        };
      },
    },
    {
      headerName: 'TRIAL STATUS',
      field: 'status',
      width: 100,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('trail_status', isDescendingOrder)
      }
    },
    {
      headerName: 'ROLE',
      field: 'role',
      width: 100,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('role', isDescendingOrder)
      }
    },
    {
      headerName: 'ORGANIZATION NAME',
      field: 'organizationName',
      width: 200,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('org_name', isDescendingOrder)
      }
    },
    {
      headerName: 'CITY',
      field: 'city',
      width: 120,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('city', isDescendingOrder)
      }
    },
    {
      headerName: 'STATE',
      field: 'state',
      width: 120,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('state', isDescendingOrder)
      }
    },
    {
      headerName: 'COUNTRY',
      field: 'country',
      width: 120,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('country', isDescendingOrder)
      }
    },
  ];

  return (
    <PaginationWrapper
      pageInfo={pagination}
      onChange={pageDetails => {
        setPageDetails(pageDetails);
        dispatch(
          getPersonAssociation({
            ...pageDetails,
            entityPrimaryKey: personid,
            sortOrder: [
              {
                columnName: sortedColumnName,
                isDescendingOrder: sortingOrder,
              },
            ],
          }),
        );
      }}
    >
      <div className="last-start-date">
        LAST TRIAL START DATE : &nbsp;
        <span className="date">
          {lastTrialStartDate ? format(new Date(lastTrialStartDate), 'MMM-dd-yyyy') : 'NA'}
        </span>
      </div>
      <DNADataGrid
        handleSortChanged={handleSortChanged}
        // columnDefs={[...columnDefs]}
        columnDefs={columnDefs({ handleTableHeaderClick })}
        rowData={JSON.parse(JSON.stringify(associatedTrialsData))}
        frameworkComponents={{ ListView, Link, DefaultCellRender }}
        rowHeight={60}
      />
    </PaginationWrapper>
  );
};

AllAssociatedTrials.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AssociatedTrials;
