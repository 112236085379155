import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import Modal from '../../../../../../../framework/modal';
import { FormBuilder } from '../../../../../../../framework';
import { getGridRowIndex } from '../../../../../../../utils/generic/helper';
import {
  validate,
  pubChemIdEditFormSchema,
  pubChemIdEditLayoutSchema,
  isPubChemIdExists,
} from '../config';
import './index.scss';

const PubChemCIDModal = ({ chemStructure, row: { rowIndex, rowData }, onClose }) => {
  const initialValues = {
    pubchemCIDs: rowData.pubChemCID,
  };
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmitValidationError = () => {};
  return (
    <Modal isOpen onClose={onClose}>
      <div className="pub-chem-modal">
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            const updatedData = {
              id: rowData.id,
              pubChemCID: Number(values.pubchemCIDs),
            };
            const updatedPubChemCid = [...chemStructure.pubchemCIDs];
            const filteredIndex = getGridRowIndex({ rowIndex, rowData }, updatedPubChemCid);
            updatedPubChemCid.splice(filteredIndex, 1);
            if (isPubChemIdExists(updatedPubChemCid, updatedData)) {
              setErrorMessage(`${values.pubchemCIDs} already exists`);
            } else {
              setErrorMessage('');
              onClose({ rowIndex, rowData: updatedData, type: 'pubChem' });
            }
          }}
          validate={async values => validate(values, true, false)}
        >
          {props => {
            return (
              <FormBuilder
                formikProps={props}
                layoutSchema={pubChemIdEditLayoutSchema}
                formSchema={pubChemIdEditFormSchema}
                onSubmitValidationError={onSubmitValidationError}
              />
            );
          }}
        </Formik>
        {errorMessage && <p className="error-text">{errorMessage}</p>}
      </div>
    </Modal>
  );
};

PubChemCIDModal.propTypes = {
  chemStructure: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PubChemCIDModal;
