/* eslint-disable complexity */
import { omit, isEmpty } from 'lodash';

import {
  convertArrayToString,
  updateCurrentDataBasedOnIngestion,
  formIngestedData,
  checkUserIngestionAction,
} from '../../utils/generic/helper';
import { personDetailsState } from '../../utils/person/featureInitialState';

const convertDropdownDataforUI = data => ({
  value: data.id,
  label: data.value,
});

export const convertDropdownDataforAPI = data => ({
  id: data.value,
  value: data.label,
});

export const convertTagDropdownDataforUI = (data, key, isSelected) => [
  ...data.map(val => ({
    rowId: val.id,
    id: val[key].id,
    value: val[key].id,
    label: val[key].value,
    isSelected,
  })),
];

const convertTagDropdownDataforAPI = (data, key) => [
  ...data.map(val => ({
    id: val.rowId,
    [key]: {
      id: val.id,
      value: val.label,
    },
  })),
];

const convertInputComboDataforUI = data => ({
  key: data.licenseType,
  value: data.licenseValue,
});

const convertInputComboDataforAPI = data => ({
  licenseType: data.key,
  licenseValue: data.value,
});

export const nameFormation = basicInfo => {
  const { firstName, lastName, middleName, nameSuffix } = basicInfo;
  let combinedName = '';
  if (lastName) {
    combinedName = `${lastName}, ${firstName} ${middleName} ${nameSuffix}`;
  } else {
    combinedName = `${firstName} ${middleName} ${nameSuffix}`;
  }
  return combinedName.trim();
};

const parentOrganizationFormation = organization => {
  if (organization) {
    const { id, name, primaryContactInfo, displayAddress, address } = organization;
    return {
      label: displayAddress ? `${id} - ${name} - ${displayAddress}` : `${id} - ${name}`,
      value: id,
      parentOrganizationName: name,
      address,
      phone: primaryContactInfo ? convertArrayToString(primaryContactInfo.phoneNumbers) : '',
      fax: primaryContactInfo ? convertArrayToString(primaryContactInfo.faxNumbers) : '',
      email: primaryContactInfo ? convertArrayToString(primaryContactInfo.emails) : '',
    };
  }
  return null;
};

export const getPersonBasicInfoForUI = (id, personBasicInfo) => {
  const {
    addressId,
    firstName,
    lastName,
    middleName,
    nameSuffix,
    personType,
    specialities,
    degrees,
    licenses,
    synonyms,
    parentOrganization,
    lastFullReviewDate,
    lastFullReviewedBy,
    nextFullReviewDate,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
    contactInfo,
    supportingUrls,
  } = personBasicInfo;

  return {
    addressId,
    id,
    name: nameFormation(personBasicInfo),
    personType: convertDropdownDataforUI(personType),
    firstName,
    lastName,
    middleName,
    nameSuffix,
    specialities: getDropdownDataForPersonUI(specialities, 'speciality'),
    degrees: getDropdownDataForPersonUI(degrees, 'degree'),
    licenses,
    // licenses: licenses.map(lic => convertInputComboDataforUI(lic)),
    synonyms: synonyms ? synonyms.map((synonym) => {
      return {
        id: synonym.id,
        text: synonym.synonym
      }
    }) : [],
    parentOrganization: parentOrganization
      ? {
        ...parentOrganization,
        ...parentOrganizationFormation(parentOrganization),
      }
      : null,
    address: parentOrganization ? parentOrganizationFormation(parentOrganization).address : '',
    parentOrganizationName: parentOrganization
      ? parentOrganizationFormation(parentOrganization).parentOrganizationName
      : '',
    phone: parentOrganization ? parentOrganizationFormation(parentOrganization).phone : '',
    fax: parentOrganization ? parentOrganizationFormation(parentOrganization).fax : '',
    email: parentOrganization ? parentOrganizationFormation(parentOrganization).email : '',
    phoneNumbers:
      contactInfo && contactInfo.phoneNumbers
        ? contactInfo.phoneNumbers.map(text => ({ text })) || []
        : [],
    faxNumbers:
      contactInfo && contactInfo.faxNumbers
        ? contactInfo.faxNumbers.map(text => ({ text })) || []
        : [],
    emails:
      contactInfo && contactInfo.emails ? contactInfo.emails.map(text => ({ text })) || [] : [],
    recordDates: {
      lastFullReviewDate: lastFullReviewDate,
      lastFullReviewBy: lastFullReviewedBy,
      nextReviewDate: nextFullReviewDate,
      createdBy: createdBy || 'user',
      createdDate: createdDate || new Date(),
      modifiedBy: modifiedBy || 'user',
      modifiedDate: modifiedDate || new Date(),
    },
    supportingUrls: supportingUrls
  };
};

export const getPersonDetailsForUI = personDetails => {
  const {
    id,
    personBasicInfo,
    personAdditionalInfo,
    prodStatusID,
    recordInceptionDate,
  } = personDetails;
  return {
    ...personDetails,
    recordInceptionDate: recordInceptionDate || null,
    prodStatusID: prodStatusID || 0,
    // ingestedId: ingestedId || 0,
    personBasicInfo: personBasicInfo ? getPersonBasicInfoForUI(id, personBasicInfo) : null,
    personAdditionalInfo: personAdditionalInfo
      ? {
        ...personAdditionalInfo,
        regulatoryActions: personAdditionalInfo.regulatoryActions.map(action => ({
          ...action,
          regulatoryActionFlag: action.regulatoryActionFlag
            ? omit(action.regulatoryActionFlag, [
              'createdBy',
              'createdDate',
              'modifiedBy',
              'modifiedDate',
            ])
            : null,
        })),
      }
      : null,
  };
};

export const getPersonBasicForAPI = ({
  basicInfo,
  nextReviewDate,
  ingestedId,
  ingestedContentResults,
}) => {
  const {
    addressId,
    id,
    firstName,
    lastName,
    middleName,
    nameSuffix,
    personType,
    specialities,
    degrees,
    licenses,
    parentOrganization,
    phoneNumbers,
    faxNumbers,
    emails,
    recordDates,
    supportingUrls,
    synonyms,
    recordDates: { lastFullReviewBy },
  } = basicInfo;

  return {
    id: id || 0,
    ingestedId,
    updateType: 1,
    ingestedContentResults,
    personBasicInfo: {
      addressId,
      firstName,
      lastName,
      middleName,
      nameSuffix,
      synonyms: synonyms ? synonyms.map(value => {
        return {
          ...value,
          synonym: value.text
        }
      }) : [],
      personType: personType ? convertDropdownDataforAPI(personType) : null,
      specialities: getDropdownDataForPersonAPI(specialities, 'speciality'),
      degrees: getDropdownDataForPersonAPI(degrees, 'degree'),
      licenses,
      // licenses: licenses.map(lic => convertInputComboDataforAPI(lic)),
      parentOrganization: parentOrganization
        ? {
          ...omit(parentOrganization, [
            'label',
            'value',
            'address',
            'fax',
            'phone',
            'email',
            'parentOrganizationName',
          ]),
          id: parentOrganization.value,
        }
        : null,
      contactInfo: {
        phoneNumbers: phoneNumbers ? phoneNumbers.map(value => value.text) : [],
        faxNumbers: faxNumbers ? faxNumbers.map(value => value.text) : [],
        emails: emails ? emails.map(value => value.text) : [],
      },
      ...omit(recordDates, ['lastFullReviewBy', 'nextReviewDate']),
      lastFullReviewedBy: lastFullReviewBy,
      nextFullReviewDate: nextReviewDate,
      supportingUrls: supportingUrls,
    },
  };
};

export const personAPIKeys = {
  BASIC_INFO: 'personBasicInfo',
  ADDITIONAL_INFO: 'personAdditionalInfo',
  NOTES: 'notes',
  PROD_STATUS: 'prodStatusID',
};

export const generateNavbarConfig = original => {
  const navBarConfig = [
    {
      id: 2,
      name: 'PERSON ID',
      value: original.id,
    },
    {
      id: 1,
      name: original.personType.label,
      value: original.name.toUpperCase(),
    },
  ];
  return navBarConfig;
};

export const setCurrentAndIngestedData = (currentData, ingestedData, tabs) => {
  let mappedIngestedData = {
    ...omit(ingestedData, ['ingestedId', 'id', 'updateType']),
  };

  // remove unwanted data from main object
  mappedIngestedData = {
    ...omit(getPersonDetailsForUI(mappedIngestedData), [
      'id',
      'prodStatusID',
      'isReviewed',
      'notes',
      'recordInceptionDate',
    ]),
  };

  // remove unwanted data from personBasicInfo
  mappedIngestedData = {
    ...mappedIngestedData,
    personBasicInfo: mappedIngestedData.personBasicInfo
      ? omit(mappedIngestedData.personBasicInfo, [
        'name',
        'recordDates',
        'address',
        'phone',
        'email',
        'fax',
        'parentOrganizationName',
      ])
      : null,
  };

  const updatedIngestedData = formIngestedData(mappedIngestedData);

  const updatedCurrentData = {
    ...updateCurrentDataBasedOnIngestion(currentData, mappedIngestedData),
    ingestedId: ingestedData.ingestedId,
    ingestedProductionStatusId: ingestedData.prodStatusID,
  };

  const ingestedKeys = Object.keys(updatedIngestedData).filter(key =>
    checkUserIngestionAction(updatedCurrentData[key], updatedIngestedData[key].fieldActions, false),
  );
  const updatedTabs = tabs.map(tab => ({
    ...tab,
    hasIngestedData: ingestedKeys.includes(tab.key),
  }));

  return {
    updatedIngestedData,
    updatedCurrentData,
    updatedTabs,
  };
};

export const getDropdownDataForPersonUI = (data, key) => {
  return data.map(item => ({
    label: item[key].value,
    value: item[key].id,
    id: item.id,
    ...omit(item, [key]),
  }));
};

export const getDropdownDataForPersonAPI = (data, key) => {
  return data.map(d => {
    return {
      id: d.id ? d.id : -1,
      [key]: {
        id: d.value,
        value: d.label,
      },
    };
  });
};

export const updatePersonStampingDetails = (
  { lastFullReviewDate, lastFullReviewBy, nextReviewDate },
  current = personDetailsState,
  original = personDetailsState,
) => {
  const updateStampDatesForObject = (object = personDetailsState) => ({
    ...object,
    personBasicInfo: {
      ...object.personBasicInfo,
      recordDates: {
        ...object.personBasicInfo.recordDates,
        lastFullReviewDate,
        lastFullReviewBy,
        nextReviewDate,
      },
    },
  });
  return {
    current: updateStampDatesForObject(current),
    original: updateStampDatesForObject(original),
  };
};

export const getPersonStampDates = ({ nextReviewDate, lastFullReviewBy, lastFullReviewDate }) => {
  return {
    nextReviewDate,
    lastFullReviewBy,
    lastFullReviewDate,
  };
};
