import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../../framework/modal';
import TreeView from '../../../../../framework/treeView';
import './index.scss';

const OrganizationTreeView = ({ isOpen, onClose, treeData, orgId, onOrgNavigation, name }) => {
  return (
    <div className="organization-tree-view">
      <Modal
        isOpen={isOpen}
        showCloseButton={true}
        onClose={onClose}
        heading="VIEW ORGANIZATION HIERARCHY"
      >
        <div className="org-view-header">
          <p className="org-view-header__topic">
            <span className="org-view-header__topic__type">ORGANIZATION NAME : </span>
            <span className="org-view-header__topic__value">{name}</span>
          </p>
          <p className="org-view-header__hierarchy">ORGANIZATION HIERARCHY</p>
        </div>
        <div className="organisation-tree">
          <TreeView
            viewType="Parent Organization"
            type="hierarchy"
            treeList={treeData}
            defaultId={orgId}
            handleTreeNodeClick={(event, node) => {
              onOrgNavigation(node.id);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

OrganizationTreeView.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  treeData: PropTypes.array.isRequired,
  orgId: PropTypes.number.isRequired,
  onOrgNavigation: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onConfirmationClose: PropTypes.func.isRequired,
  navigateConfirmation: PropTypes.string.isRequired,
};

OrganizationTreeView.defaultProps = {
  isOpen: true,
  navigateConfirmation: '',
};

export default OrganizationTreeView;
