import * as Yup from 'yup';
import { format } from 'date-fns';
import { isEqual, sortBy } from 'lodash';
import { isValid as isDateValid } from 'date-fns';

import { fieldTypes } from '../../../framework/formBuilder/utils';

export const validate = (values, type) => {
  const errors = {};

  if (!values.type) {
    errors.type = 'Please provide details for the Note Type';
  }
  if (!values.date) {
    errors.type = 'Please provide date for the Note Type';
  }
  if (!values.text.trim()) {
    errors.text = 'Please provide details for the Note Text';
  }
  if (values.date && !isDateValid(values.date)) {
    errors.date = 'Please enter a valid date (MMM-dd-yyyy)';
  }
  if (values.date && new Date(values.date).getFullYear() > 2099) {
    errors.date = 'Please enter a valid date (MMM-dd-yyyy)';
  }

  return errors;
};

const notesConfig = {
  initialValues: {
    type: 2,
    date: new Date(),
    text: '',
  },

  layoutSchema: {
    layout: 'row',
    className: 'form-wrapper',
    spacing: 4,
    justify: 'flex-start',
    alignItems: 'flex-start',
    content: [
      {
        layout: 'type',
        size: 2,
        className: 'note-type',
      },
      {
        layout: 'date',
        size: 2,
        className: 'keyboard-ctrl',
      },
      {
        layout: 'text',
        size: 6,
        className: '',
      },
      {
        layout: 'button',
        size: 2,
        className: 'add-button',
      },
    ],
  },

  ModallayoutSchema: {
    layout: 'column',
    className: 'form-wrapper',
    spacing: 4,
    justify: 'flex-start',
    alignItems: 'flex-start',
    content: [
      {
        layout: 'row',
        size: 12,
        className: '',
        spacing: 6,
        justify: 'flex-start',
        alignItems: 'flex-start',
        content: [
          {
            layout: 'type',
            size: 6,
            className: 'note-type',
          },
          {
            layout: 'date',
            size: 6,
            className: 'keyboard-ctrl',
          },
        ]
      },
      {
        layout: 'row',
        size: 12,
        className: '',
        spacing: 3,
        justify: 'flex-start',
        alignItems: 'flex-start',
        content: [
          {
            layout: 'text',
            size: 12,
            className: '',
          },
        ]
      },
      {
        layout: 'row',
        size: 12,
        className: '',
        spacing: 3,
        justify: 'flex-start',
        alignItems: 'flex-start',
        content: [
          {
            layout: 'button',
            size: 6,
            className: 'add-button',
          },
          { 
            layout: 'clearButton', 
            size: 6, 
            className: 'add-button' 
          }
        ]
      },
    ],
  },

  formSchema: {
    type: {
      name: 'type',
      type: fieldTypes.custom_radio,
      label: 'NOTE TYPE',
      options: [],
      props: {
        isMandatory: true,
      },
    },
    date: {
      name: 'date',
      type: fieldTypes.customDate,
      label: 'NOTE DATE',
      props: {
        isKeyboardCtrl: true,
        autoOk: true,
        disablePast: false,
        disabled: true,
        isMandatory: true
      },
    },
    text: {
      name: 'text',
      type: fieldTypes.textArea,
      label: 'NOTE TEXT',
      props: {
        isMandatory: true,
        // rowsMin: 4,
        // rowsMax: 10,
        rows: 10,
        cols: 80,
      },
    },
    button: {
      name: 'button',
      type: fieldTypes.button,
      label: '+ ADD',
    },
  },

  // ag-grid configuration
  columnDefs: (handleDelete, handleEdit, disableEdit) => [
    {
      headerName: 'NOTE TYPE',
      field: 'type.value',
      width: 100,
      sortable: true,
    },
    {
      headerName: 'NOTE DATE',
      field: 'date',
      width: 100,
      sortable: true,
      cellRenderer: ({ data: { date } }) => (date ? format(new Date(date), 'MMM-dd-yyyy') : 'NA'),
    },
    {
      headerName: 'NOTE TEXT',
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      field: 'text',
      width: 100,
      sortable: true,
      cellRenderer: 'customCellRenderWithModalButton',
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { rowIndex, data } = params;
        return {
          handleEdit: () => {
            handleEdit(data, rowIndex);
          },
          canDisableEdit: disableEdit,
        };
      },
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { rowIndex, data } = params;
        return {
          handleDelete: () => {
            handleDelete(data, rowIndex);
          },
          canDisableDelete: disableEdit,
        };
      },
    },
  ],

  isNotesExists: (data, { date, type, text }) => {
    let isDataExists = false;
    // console.log([...data], { date, type, text }, format(new Date(date), 'dd/MM/yyyy'));
    data.map(c => {
      if (
        format(new Date(c.date), 'MMM--dd-yyyy') === format(new Date(date), 'MMM--dd-yyyy') &&
        isEqual(sortBy(c.type), sortBy(type)) &&
        c.text.toLowerCase().trim() === text.toLowerCase().trim()
      ) {
        isDataExists = true;
      }
    });
    return isDataExists;
  },
};

export default notesConfig;
