import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckIcon from '@material-ui/icons/Check';
import { Tooltip } from '@material-ui/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './index.scss';

const SideBarMenu = ({
  handleSideBarClick,
  data,
  selected,
  fullWidth,
  isMenuOpen,
  handleToggle,
  handlePinToggle,
  isSidePinEnable,
}) => {
  const fullWidthClass = fullWidth ? ' full-width' : '';

  const doesNotContainAnyErrorInTab = (item) => {
    let err = false
    if (item.isTab) {
      item.tabs.forEach((tab) => {
        if (tab.hasError) {
          err = true;
        }
      })
    }
    return err;
  }

  const generateSideBar = () => {
    return data.filter(item => !item.disabled).map((item, index) => {
      const isSelected = (item.value || item.name) === selected;
      return (
        <ListItem
          className={isSelected ? 'side-bar-menu-item-selected' : 'side-bar-menu-item'}
          button
          onClick={() => !isSelected && handleSideBarClick(item)}
          key={`${index}${item.name}`}
          disabled={item.disabled}
        >
          <ListItemText primary={item.name} />
          {item.isMandatory && (
            <ListItemIcon>
              <CheckIcon className="check-icon" />
            </ListItemIcon>
          )}
          {(item.hasError || doesNotContainAnyErrorInTab(item)) && !item.disabled && (
            <Tooltip title="Validation Error">
              <ListItemIcon>
                <FontAwesomeIcon color="#ef426f" icon={faExclamationTriangle}></FontAwesomeIcon>
              </ListItemIcon>
            </Tooltip>
          )}
          {item.hasUnSavedData && (
            <Tooltip title="Unsaved Changes">
              <ListItemIcon>
                <FontAwesomeIcon color="#efe342" icon={faExclamationTriangle}></FontAwesomeIcon>
              </ListItemIcon>
            </Tooltip>
          )}
        </ListItem>
      );
    });
  };

  return (
    <div className={isMenuOpen ? 'sideBarMenuOpen' : 'sideBarMenuClose'}>
      <div className={`side-bar-menu${fullWidthClass}`}>
        <div className="sidebar-toggle">

          <Tooltip title={isSidePinEnable ? 'Un-pin' : 'Pin'}>
            <span className={`showPinIcon ${isSidePinEnable ? 'pinDeactivate' : 'pinActive'}`} onClick={() => handlePinToggle(!isSidePinEnable)}></span>
          </Tooltip>

          {isMenuOpen && (
            <Tooltip title="Collapse Tab">
              <div className="toggle-collapse" onClick={() => handleToggle(!isMenuOpen)}></div>
            </Tooltip>
          )}

        </div>
        <List>{generateSideBar()}</List>
      </div>
    </div>
  );
};

SideBarMenu.propTypes = {
  handleSideBarClick: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.string,
  style: PropTypes.object,
  heading: PropTypes.string,
  screen: PropTypes.string,
  fullWidth: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
  handleToggle: PropTypes.func,
  isSidePinEnable: PropTypes.bool,
  handlePinToggle: PropTypes.func,
};

SideBarMenu.defaultProps = {
  fullWidth: false,
  isMenuOpen: true,
  isSidePinEnable: true,
};
export default SideBarMenu;
