import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';
import {
  sideBarSelection,
  setAncillaryData,
  setAncillaryListData,
  updatedSelectRegion,
  updatedSelectedSubRegion,
  updatedSelectedCountry,
  getLocationDataSuccess,
  resetLocationData,
  sideMenuOpen,
  getAncillaryRecordByIdSuccess,
} from '../actions/ancillaryData';

const initialState = InitialState.ancillaryData;
const ancillaryData = createReducer(initialState, {
  [sideBarSelection]: (state, action) => {
    state.selected = action.payload.value;
  },
  [setAncillaryData]: (state, action) => {
    const { data, taxonomy, payload } = action.payload;
    if (payload.pagination) {
      const { results, rowCount } = data;
      state.taxonomyDetails[taxonomy].tableData.data = results;
      state.taxonomyDetails[taxonomy].tableData.rowCount = rowCount;
    } else if (payload.sortRequestBody) {
			const { results } = data;
      state.taxonomyDetails[taxonomy].tableData.data = results;
		} else {
      state.taxonomyDetails[taxonomy].tableData.data = [...data];
    }
  },
  [setAncillaryListData]: (state, action) => {
    const { data, taxonomy } = action.payload;
    state[taxonomy] = [...data];
  },
  [updatedSelectRegion]: (state, action) => {
    const {
      taxonomyDetails: { location },
    } = state;
    location.selectedRegionId = action.payload.regionId;
    location.selectedSubRegionId = action.payload.subRegionId;
    location.selectedCountryId = action.payload.countryId;
  },
  [updatedSelectedSubRegion]: (state, action) => {
    const {
      taxonomyDetails: { location },
    } = state;
    location.selectedSubRegionId = action.payload.subRegionId;
    location.selectedCountryId = action.payload.countryId;
  },
  [updatedSelectedCountry]: (state, action) => {
    const {
      taxonomyDetails: { location },
    } = state;
    location.selectedCountryId = action.payload.countryId;
  },
  [getLocationDataSuccess]: (state, action) => {
    const {
      taxonomyDetails: { location },
    } = state;
    location.locationData = action.payload.data;
    if (action.payload.selected) {
      const { regionId, subRegionId, countryId } = action.payload.selected;
      if (regionId === null && subRegionId) {
        const selectedRegion = action.payload.data.filter(o =>
          o.subRegions.find(o => o.id === subRegionId),
        );
        location.selectedRegionId = selectedRegion.length > 0 ? selectedRegion[0].id : 0;
        location.selectedSubRegionId = subRegionId;
        location.selectedCountryId = countryId;
      } else {
        location.selectedRegionId = regionId;
        location.selectedSubRegionId = subRegionId;
        location.selectedCountryId = countryId;
      }
    }
  },
  [resetLocationData]: state => {
    const {
      taxonomyDetails: { location },
    } = state;
    location.locationData = [];
    location.selectedRegionId = 0;
    location.selectedSubRegionId = 0;
    location.selectedCountryId = 0;
  },
  [sideMenuOpen]: (state, action) => {
    state.isSideBarOpen = action.payload;
  },
  [getAncillaryRecordByIdSuccess]: (state, { payload }) => {
    state.ancillaryRecordById = payload;
  },
});
export default ancillaryData;
