import { isEmpty } from 'lodash';

import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { VALIDATION_REGEX } from '../../../../utils/generic/constants';
import {
  validateUrl,
  checkIfStringIsEmpty,
  regexToAllowOnlyPositiveNumbers, isValidDate,
  is32BitSignedInteger,
} from '../../../../utils/generic/helper';

export const initialValues = {
  relatedCompanyType: null,
  yearAcquired: null,
  ownership: null,
  employees: 0,
  establishedYear: null,
  parentCompany: null,
  website: '',
  phone: [],
  fax: [],
  companySummary: '',
  street1: '',
  street2: '',
  country: null,
  state: null,
  city: '',
  postalCode: '',
};

export const layoutSchema = {
  layout: 'row',
  className: 'company-basic-info-form',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: '',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      header: 'BASIC INFO',
      headerProps: {
        fontStyle: 'bold',
        size: '16',
      },
      content: [
        {
          layout: 'relatedCompanyType',
          size: 3,
          className: '',
        },
        {
          layout: 'yearAcquired',
          size: 2,
          className: '',
        },
        {
          layout: 'ownership',
          size: 2,
          className: '',
        },
        {
          layout: 'establishedYear',
          size: 2,
          className: '',
        },
        {
          layout: 'parentCompany',
          size: 3,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      size: 12,
      className: '',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'website',
          size: 6,
          className: '',
        },
        {
          layout: 'phone',
          size: 3,
          className: '',
        },
        {
          layout: 'fax',
          size: 3,
          className: '',
        },
      ],
    },

    {
      layout: 'row',
      size: 12,
      className: '',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'companySummary',
          size: 9,
          className: '',
        },
      ],
    },

    {
      layout: 'row',
      size: 12,
      className: '',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      header: 'ADDRESS',
      headerProps: {
        fontStyle: 'bold',
        size: '14',
      },
      content: [
        {
          layout: 'street1',
          size: 3,
          className: '',
        },
        {
          layout: 'street2',
          size: 3,
          className: '',
        },
        {
          layout: 'country',
          size: 3,
          className: '',
        },
        {
          layout: 'state',
          size: 3,
          className: '',
        },
      ],
    },

    {
      layout: 'row',
      size: 12,
      className: '',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'city',
          size: 3,
          className: '',
        },
        {
          layout: 'postalCode',
          size: 3,
          className: '',
        },
      ],
    },
  ],
};

export const formSchema = ({
  handleCountryChange,
  searchParentCompany,
  filterCountry,
  filterState,
}) => ({
  relatedCompanyType: {
    name: 'relatedCompanyType',
    type: fieldTypes.select,
    label: 'Acquired/Subsidiary',
    options: [],
    props: {
      height: 'ht-lg',
      isClearable: true,
      isMandatory: true,
    },
  },
  yearAcquired: {
    name: 'yearAcquired',
    type: fieldTypes.date,
    label: 'YEAR ACQUIRED',
    options: [],
    props: {
      views: ['year'],
      format: 'yyyy',
      clearable: true,
      disableFuture: true,
      minDate: new Date('1200-01-01'),
      maxDate: new Date(),
    },
  },
  ownership: {
    name: 'ownership',
    type: fieldTypes.select,
    label: 'OWNERSHIP',
    options: [],
    props: {
      height: 'ht-lg',
      isClearable: true,
    },
  },
  establishedYear: {
    name: 'establishedYear',
    type: fieldTypes.date,
    label: 'ESTABLISHED (YEAR)',
    options: [],
    props: {
      views: ['year'],
      format: 'yyyy',
      clearable: true,
      disableFuture: true,
      minDate: new Date('1200-01-01'),
      maxDate: new Date(),
    },
  },
  parentCompany: {
    name: 'parentCompany',
    type: fieldTypes.select_async,
    label: 'PARENT COMPANY',
    options: [],
    props: {
      onFilter: searchParentCompany,
      isSearchable: true,
      blurInputOnSelect: true,
      controlShouldRenderValue: true,
      isClearable: true,
      inputHeight: 'ht-lg',
    },
  },
  website: {
    name: 'website',
    type: fieldTypes.text,
    label: 'WEBSITE',
    options: [],
    props: {
      placeholder: 'Insert URL...',
      inputHeight: 'ht-lg',
    },
  },
  phone: {
    name: 'phone',
    type: fieldTypes.entry,
    label: 'PHONE',
    options: [],
    props: {
      validate: {
        rule: VALIDATION_REGEX.PHONE,
        message: 'Please enter valid phone number',
      },
      height: 'ht-lg',
    },
  },
  fax: {
    name: 'fax',
    type: fieldTypes.entry,
    label: 'FAX',
    options: [],
    props: {
      validate: {
        rule: VALIDATION_REGEX.PHONE,
        message: 'Please enter valid fax number',
      },
      height: 'ht-lg',
    },
  },
  companySummary: {
    name: 'companySummary',
    type: fieldTypes.textAreaAdvanced,
    label: 'COMPANY SUMMARY',
    options: [],
  },
  street1: {
    name: 'street1',
    type: fieldTypes.text,
    label: 'STREET 1',
    options: [],
    props: {
      inputHeight: 'ht-lg',
    },
  },
  street2: {
    name: 'street2',
    type: fieldTypes.text,
    label: 'STREET 2',
    options: [],
    props: {
      inputHeight: 'ht-lg',
    },
  },
  country: {
    name: 'country',
    type: fieldTypes.select_async,
    label: 'COUNTRY',
    options: [],
    props: {
      isMandatory: true,
      handleSelectChange: handleCountryChange,
      height: 'ht-lg',
      onFilter: filterCountry,
      isSearchable: true,
      blurInputOnSelect: true,
      isClearable: true,
    },
  },
  state: {
    name: 'state',
    type: fieldTypes.text,
    label: 'STATE',
    options: [],
    props: {
      inputHeight: 'ht-lg',
    },
  },
  city: {
    name: 'city',
    type: fieldTypes.text,
    label: 'CITY',
    options: [],
    props: {
      inputHeight: 'ht-lg',
    },
  },
  postalCode: {
    name: 'postalCode',
    type: fieldTypes.text,
    label: 'POSTAL CODE',
    options: [],
    props: {
      inputHeight: 'ht-lg',
    },
  },
});

export const validateBasicAndContactInfoDetails = (formValues, nextReviewDate, prodStatusID) => {
  const {
    employees,
    website,
    country,
    state,
    companySummary,
    relatedCompanyType,
    parentCompany,
  } = formValues;
  let errors = {};
  if (nextReviewDate && !isValidDate(nextReviewDate)) {
    errors = {
      ...errors,
      nextFullReviewDate: 'Please enter a valid date (MMM-dd-yyyy)',
    };
  }

  if (!checkIfStringIsEmpty(employees) && !regexToAllowOnlyPositiveNumbers.test(employees.trim())) {
    errors = {
      ...errors,
      employees: 'Please enter a valid number',
    };
  } else if (is32BitSignedInteger(parseInt(employees, 10))) {
    errors = {
      ...errors,
      employees: 'Value exceeds the limit (2147483647)',
    };
  }

  const urlError = validateUrl(website);
  if (website && website.trim() && urlError) {
    errors = {
      ...errors,
      website: urlError,
    };
  }
  const { label: selectedCountry = '', value: selectedCountryId = '' } = { ...country };
  const { label: selectedState = '', countryId: selectedStateCountryId = '' } = { ...state };
  if (isEmpty(country)) {
    errors = {
      ...errors,
      country: 'Please provide details for the country',
    };
  }

  if (selectedCountry && selectedState && selectedStateCountryId !== selectedCountryId) {
    errors = {
      ...errors,
      state: 'State doesnot match with the selected country. Please select different state',
    };
  }

  if (isEmpty(companySummary) && prodStatusID === 1) {
    errors = {
      ...errors,
      companySummary: 'Please provide details for the company summary',
    };
  }

  if (isEmpty(relatedCompanyType) && !isEmpty(parentCompany)) {
    errors = {
      ...errors,
      relatedCompanyType: 'Please select Acquired or Subsidiary',
    };
  }

  return errors;
};
