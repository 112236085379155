import React from 'react'
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle as faOutlineCircle } from '@fortawesome/free-regular-svg-icons';
import CopyContentModal from '../../../../framework/copyContentModal';

export default function Company({ data }) {
	return (
		<CopyContentModal>
			<span className={data?.name ? `heirarchy-level-${data?.level}` : ''}>
				{data?.isParent && !data?.isLastChild && <span><FontAwesomeIcon icon={faCircle} color="#d1497c" /></span>}
				{data?.isLastChild && <span><FontAwesomeIcon icon={faOutlineCircle} color="#d1497c" /></span>}
				{`${data?.name}`}
			</span>
		</CopyContentModal>
	)
}

Company.propTypes = {
	data: PropTypes.shape({
		name: PropTypes.string,
		level: PropTypes.number,
		isLastChild: PropTypes.bool
	}),
}
