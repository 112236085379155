import React, { useEffect, lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import UnauthorizedPage from '../screens/auth/unauthorizedPage';
import { getUserInfoSuccess } from '../store/actions/auth';

const Administrator = lazy(() => import('../screens/administrator'));
const AncillaryData = lazy(() => import('../screens/ancillaryData'));
const ClinicalTrials = lazy(() => import('../screens/clinicalTrials'));
const Drugs = lazy(() => import('../screens/drugs'));
const Person = lazy(() => import('../screens/person'));
const Organization = lazy(() => import('../screens/organization'));
const Company = lazy(() => import('../screens/company'));
const Sponsors = lazy(() => import('../screens/sponsors'));
const DbStatistics = lazy(() => import('../screens/dbStatistics'));
const Task = lazy(() => import('../screens/task'));
const AddAncillaryData = lazy(() => import('../screens/addAncillaryData'));
const Reporting = lazy(() => import('../screens/reporting'));
const Registry = lazy(() => import('../screens/registry'));
const Country = lazy(() => import('../screens/country'));
const SourceDocument = lazy(() => import('../screens/sourceDocument'));
const Requirement = lazy(() => import('../screens/requirement'));
const Dashboard = lazy(() => import('../screens/dashboard'));

let userGroup = localStorage.getItem('usergroup');
let ProtectedRoutesMenu;

ProtectedRoutesMenu = () => {
  const { user, businessUnit } = useSelector(state => state.auth, shallowEqual);
  let userGroupLanding = null;
  if (businessUnit && businessUnit.length == 1 && userGroup == null) {
    userGroupLanding = businessUnit[0]['businessUserName'];
  } else if (userGroup != null) {
    userGroupLanding = userGroup;
  } else {
    userGroupLanding = 'StarUser';
  }
  if (userGroupLanding === 'RegIntelUser') {
    return (
      <>
        <Route path="/administrator" component={Administrator} />
        <Route path="/ancillaryData/:slug" component={AncillaryData} />
        <Route path="/registry" component={Registry} />
        <Route path="/sourceDocument" component={SourceDocument} />
        <Route path="/requirement" component={Requirement} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/country" component={Country} />
        <Route exact path="/unauthorized" component={UnauthorizedPage} />
        <Route
          exact
          path={[
            '/person',
            '/person-queues',
            '/clinicalTrials',
            '/clinicalTrials-queues',
            '/task',
            '/reporting',
            '/sponsors',
            '/organization',
            '/dbStatistics',
            '/organization-queues',
            '/company',
            '/company-queues',
            '/drugs',
            '/drugs-queues',
            '/ancillaryData',
            '/task',
          ]}
        >
          <Redirect to="/dashboard" />
        </Route>
        <Route exact path="/user">
          <Redirect to="/dashboard" />
        </Route>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        <Route exact path="/ancillaryData">
          <Redirect to="/ancillaryData/:slug" />
        </Route>
        <Route path="/addAncillaryData" component={AddAncillaryData} />
        {/* <Route path="/addDevExtreme" component={DataGrid1} /> */}
      </>
    );
  } else {
    return (
      <>
        <Route path="/administrator" component={Administrator} />
        <Route path="/ancillaryData/:slug" component={AncillaryData} />
        <Route path="/clinicalTrials" component={ClinicalTrials} />
        <Route path="/clinicalTrials-queues" component={ClinicalTrials} />
        <Route path="/drugs" component={Drugs} />
        <Route path="/drugs-queues" component={Drugs} />
        <Route path="/person" component={Person} />
        <Route path="/person-queues" component={Person} />
        <Route path="/company" component={Company} />
        <Route path="/company-queues" component={Company} />
        <Route path="/organization" component={Organization} />
        <Route path="/organization-queues" component={Organization} />
        <Route path="/sponsors" component={Sponsors} />
        <Route path="/dbStatistics" component={DbStatistics} />
        <Route exact path="/unauthorized" component={UnauthorizedPage} />
        <Route
          exact
          path={['/dashboard', '/sourceDocument', '/registry', '/requirement', '/country']}
        >
          <Redirect to="/clinicalTrials" />
        </Route>
        <Route exact path="/task" component={Task} />
        <Route exact path="/user">
          <Redirect to="/clinicalTrials" />
        </Route>
        <Route exact path="/">
          <Redirect to="/clinicalTrials" />
        </Route>
        <Route exact path="/ancillaryData">
          <Redirect to="/ancillaryData/:slug" />
        </Route>
        <Route path="/addAncillaryData" component={AddAncillaryData} />
        <Route path="/reporting" component={Reporting} />
      </>
    );
  }
};

export default ProtectedRoutesMenu;
