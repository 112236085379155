import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputLabel from '../InputLabel';
import './index.scss';

const InputTextArea = ({
  name,
  label,
  value,
  rowsMax,
  rowsMin,
  cols,
  placeholder,
  onChange,
  size,
  isMandatory,
  isError,
  errorMessage,
  disabled,
}) => {
  return (
    <div name={name} className="input-text-area">
      {label && (
        <InputLabel labelFor="inputLabel" text={label} size={size} isMandatory={isMandatory} />
      )}
      <TextareaAutosize
        className={`text-area-control ${isError ? 'error-control' : ''}`}
        value={value}
        onChange={onChange}
        onBlur={() => {
          onChange({ target: { value: value?.trim() } });
        }}
        rowsMin={rowsMin}
        rowsMax={rowsMax}
        cols={cols}
        placeholder={placeholder}
        disabled={disabled}
      />
      {isError && <p className="error-text">{errorMessage}</p>}
    </div>
  );
};

InputTextArea.propTypes = {
  value: PropTypes.string.isRequired,
  rowsMin: PropTypes.number,
  rowsMax: PropTypes.number,
  cols: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  isMandatory: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
};
InputTextArea.defaultProps = {
  rowsMin: 10,
  rowsMax: 20,
  cols: 80,
  placeholder: 'Add text content here',
  size: '16',
  isMandatory: false,
  isError: false,
  errorMessage: '',
  disabled: false,
};

export default InputTextArea;
