import React, { useState, useRef, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import PropTypes from 'prop-types';

import { MODAL_TYPES } from '../../utils/generic/constants';
import ModalOptions from '../../generic/modals/ModalOptions';
import StopWatch from './stopWatch';

const IdleTimerComponent = ({ children, timeout, message, onRelease, warningTime }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [idleTimeout, setIdleTimeout] = useState(timeout * 60000);
  const timerRef = useRef(null);

  useEffect(() => {
    // to convert minutes into milliseconds => min*60*1000
    // eslint-disable-next-line no-mixed-operators
    setIdleTimeout(timeout * 60000 - warningTime);
    timerRef.current.reset();
  }, [timeout]);

  const onIdle = () => {
    if (timerRef.current.getElapsedTime() >= idleTimeout) {
      setShowWarning(true);
      timerRef.current.pause();
    } else {
      timerRef.current.reset();
    }
  };
  const canContinue = action => {
    setShowWarning(false);
    timerRef.current.reset();
    if (!action) {
      onRelease();
    }
  };

  return (
    <>
      <IdleTimer ref={timerRef} element={document} timeout={idleTimeout} onIdle={onIdle}>
        {children}
      </IdleTimer>

      {showWarning && (
        <>
          <ModalOptions
            isOpen
            modalType={MODAL_TYPES.WARNING}
            canContinue={canContinue}
            message={message}
            confirmText="Release"
          >
            <StopWatch start={showWarning} time={warningTime} done={() => canContinue(false)} />
          </ModalOptions>
        </>
      )}
    </>
  );
};

export default IdleTimerComponent;

IdleTimerComponent.propTypes = {
  children: PropTypes.node,
  timeout: PropTypes.number,
  warningTime: PropTypes.number,
  message: PropTypes.string,
  onRelease: PropTypes.func,
};
IdleTimerComponent.defaultProps = {
  timeout: 30,
  warningTime: 10 * 1000,
  message: 'Your record will be released',
  onRelease: () => {},
};
