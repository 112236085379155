import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import './index.scss';

const TypographyText = ({
  variant,
  text,
  color,
  size,
  fontStyle,
  textType,
  padding,
  lineHeight,
}) => {
  let classNames = `font-color-${color} font-style-${fontStyle} text-transform-${textType} padding-${padding} line-height-${lineHeight}`;
  classNames = variant ? classNames : `${classNames} font-size-${size}`;
  return (
    <div className="typography-container">
      <Typography variant={variant} className={classNames}>
        {text}
      </Typography>
    </div>
  );
};

TypographyText.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    '',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'button',
    'caption',
    'overline',
  ]),
  color: PropTypes.oneOf([
    'white',
    'black',
    'red',
    'green',
    'navy-grey',
    'secondary',
    'primary',
    'orange',
    'label',
    'turq-blue',
    'label', // grayscale-9
    'grayscale-15',
  ]),
  size: PropTypes.oneOf([
    'body-large',
    'body-medium',
    'body-small',
    'label-medium',
    'label-small',
    'caption-large',
    'caption-medium',
    'caption-small',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
  ]),
  fontStyle: PropTypes.oneOf(['regular', 'bold', 'italics']),
  textType: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize', 'none']),
  padding: PropTypes.oneOf([
    'none',
    'top',
    'bottom',
    'left',
    'right',
    'top-bottom',
    'left-right',
    'all',
  ]),
  lineHeight: PropTypes.oneOf(['14', '16', '18', '20', '22', '24', '26', '28']),
};

TypographyText.defaultProps = {
  color: 'black',
  size: 'body-small',
  fontStyle: 'regular',
  textType: 'capitalize',
  padding: 'none',
};

export default TypographyText;
