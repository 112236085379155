import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { DROPDOWN_TYPES, TEXT_LIMIT } from '../../../../utils/generic/constants';

export const validate = (values, isValidate, isOtherTab) => {
  const errors = {};
  const effectiveDateComments = values.effectiveDateComments?.replace(/(\r\n|\n|\r)/gm, '').trim();
  const commentsOnPermissibleRegistries = values.commentsOnPermissibleRegistries
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const endDate = new Date(values?.requirementEndDate)?.setHours(0, 0, 0, 0);
  const startDate = new Date(values?.requirementEffectiveDate)?.setHours(0, 0, 0, 0);
  const disclosuRequirementInternalComments = values.disclosuRequirementInternalComments
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  if (
    values.authorityAgencyRequirement?.trim() &&
    values.authorityAgencyRequirement.trim().length > 400
  ) {
    errors.authorityAgencyRequirement = 'Please enter only 400 characters';
  }
  if (values.othersResponsible?.trim() && values.othersResponsible.trim().length > 400) {
    errors.othersResponsible = 'Please enter only 400 characters';
  }
  if (values.ifYesDescribe?.trim() && values.ifYesDescribe.trim().length > 400) {
    errors.ifYesDescribe = 'Please enter only 400 characters';
  }
  if (
    values.effectiveDateComments?.trim() &&
    effectiveDateComments.trim().length > TEXT_LIMIT[3000]
  ) {
    errors.effectiveDateComments = 'Please enter only 3000 characters';
  }
  if (
    values.commentsOnPermissibleRegistries?.trim() &&
    commentsOnPermissibleRegistries.trim().length > TEXT_LIMIT[1000]
  ) {
    errors.commentsOnPermissibleRegistries = 'Please enter only 1000 characters';
  }
  if (values.requirementEndDate) {
    if (endDate < startDate) {
      errors.requirementEndDate = 'End Date should be greater than or equal to Start Date';
    }
  }
  if (
    values.disclosuRequirementInternalComments?.trim() &&
    disclosuRequirementInternalComments.trim().length > TEXT_LIMIT[7000]
  ) {
    errors.disclosuRequirementInternalComments = 'Please enter only 7000 characters';
  }
  if (isValidate && values.isMandatory) {
    if (values.requirementEffectiveDate === null) {
      errors.requirementEffectiveDate = 'Please enter requirement date';
    }
    if (effectiveDateComments === '') {
      errors.effectiveDateComments = 'Please enter comments';
    }
    if (!values.authorityAgencyRequirement?.trim()) {
      errors.authorityAgencyRequirement = 'Please enter authority in charge';
    }
    if (values.permissibleCTRegistry.length === 0) {
      errors.permissibleCTRegistry =
        'Please select permissible or acceptable clinical trial registries';
    }
    if (values.responsibility.length === 0) {
      errors.responsibility = 'Please select who is responsible';
    }
    const disresponsible =
      isOtherTab && values.responsibility?.length > 0
        ? values?.responsibility?.map(x => x.responsible)
        : values?.responsibility;

    const responsibility = disresponsible?.filter(
      ({ value }) => value === 'Other (describe below)',
    );

    if (responsibility && responsibility.length > 0 && !values.othersResponsible?.trim()) {
      errors.othersResponsible = 'Please enter the data';
    }

    if (values.isLocalRepresentive === null) {
      errors.isLocalRepresentive = 'Please select contact required';
    } else if (values.isLocalRepresentive && !values.ifYesDescribe?.trim()) {
      errors.ifYesDescribe = 'Please enter decription';
    }

    if (values.languages.length === 0) {
      errors.languages = 'Please select languages';
    }
  }
  return errors;
};

export const initialValues = {
  requirementEffectiveDate: null,
  requirementEndDate: null,
  effectiveDateComments: '',
  authorityAgencyRequirement: '',
  permissibleCTRegistry: null,
  responsibility: null,
  othersResponsible: '',
  isLocalRepresentive: null,
  ifYesDescribe: '',
  languages: null,
  commentsOnPermissibleRegistries: '',
};

export const layoutSchema = {
  layout: 'row',
  className: 'record-update-container',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: 'reason-for-update',
      spacing: 3,
      type: fieldTypes.select,
      options: [],
      props: {
        height: 'ht-lg',
      },
      content: [
        {
          layout: 'requirementEffectiveDate',
          size: 4,
          className: '',
        },
        {
          layout: 'requirementEndDate',
          size: 4,
          className: 'singleLine',
        },
        {
          layout: 'effectiveDateComments',
          size: 4,
          className: 'singleLine',
        },
        {
          layout: 'authorityAgencyRequirement',
          size: 6,
          className: '',
        },
        {
          layout: 'permissibleCTRegistry',
          size: 6,
          className: 'singleLine',
        },
        {
          layout: 'commentsOnPermissibleRegistries',
          size: 12,
        },
        {
          layout: 'responsibility',
          size: 4,
          className: '',
        },
        {
          layout: 'othersResponsible',
          size: 8,
          className: 'singleLine',
        },
        {
          layout: 'isLocalRepresentive',
          size: 4,
          className: '',
        },
        {
          layout: 'ifYesDescribe',
          size: 8,
          className: 'singleLine',
        },
        {
          layout: 'languages',
          size: 4,
          className: '',
        },
        {
          layout: 'disclosuRequirementInternalComments',
          size: 12,
        },
      ],
    },
  ],
};

const mandatoryFields = []

export const formSchema = {
  requirementEffectiveDate: {
    name: 'requirementEffectiveDate',
    type: fieldTypes.date,
    label: 'Disclosure regulation/requirement effective date',
    props: {
      format: 'dd-MMM-yyyy',
      views: ['year', 'month', 'date'],
      clearable: true,
      // disableFuture: true,
      minDate: new Date('1200-01-01'),
      // maxDate: new Date(),
      isMandatory: true,
    },
  },
  requirementEndDate: {
    name: 'requirementEndDate',
    type: fieldTypes.date,
    label: 'Disclosure regulation/requirement end date',
    props: {
      format: 'dd-MMM-yyyy',
      views: ['year', 'month', 'date'],
      clearable: true,
      // disableFuture: true,
      minDate: new Date('1200-01-01'),
      // maxDate: new Date(),
    },
  },
  effectiveDateComments: {
    name: 'effectiveDateComments',
    type: fieldTypes.textArea,
    label: 'Comments relating to effective or end date',
    props: {
      rowsMin: 10,
      rowsMax: 2.3,
      cols: 150,
      isMandatory: true,
    },
  },
  authorityAgencyRequirement: {
    name: 'authorityAgencyRequirement',
    type: fieldTypes.textArea,
    label: 'What Authority/Agency/Association is in charge of the Requirement system?',
    props: {
      rowsMin: 10,
      rowsMax: 2.3,
      cols: 150,
      isMandatory: true,
    },
  },
  permissibleCTRegistry: {
    name: 'permissibleCTRegistry',
    type: fieldTypes.select_async,
    label: 'Permissible or Acceptable clinical trial registries',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMulti: true,
      showSelectedData: true,
      isMandatory: true,
    },
  },
  commentsOnPermissibleRegistries: {
    label: 'COMMENTS RELATING TO PERMISSIBLE REGISTRIES',
    name: 'commentsOnPermissibleRegistries',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
      placeholder: '',
    },
  },
  responsibility: {
    name: 'responsibility',
    type: fieldTypes.select_async,
    label:
      'Who is RESPONSIBLE for disclosing trial information according to the regulation or requirement?',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMulti: true,
      showSelectedData: true,
      isMandatory: true,
    },
  },
  othersResponsible: {
    name: 'othersResponsible',
    type: fieldTypes.textArea,
    label:
      'If Other is selected above, who else is RESPONSIBLE for disclosing trial information according to the regulation or requirement? (or N/A)',
    props: {
      rowsMin: 10,
      rowsMax: 2.3,
      cols: 150,
      isMandatory: true,
    },
  },
  isLocalRepresentive: {
    name: 'isLocalRepresentive',
    label: 'Is a local representative or contact required? (or N/A)',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: true,
    },
  },
  ifYesDescribe: {
    name: 'ifYesDescribe',
    type: fieldTypes.textArea,
    label: 'If yes, describe here',
    props: {
      rowsMin: 10,
      rowsMax: 2.3,
      cols: 150,
      isMandatory: true,
    },
  },
  languages: {
    name: 'languages',
    type: fieldTypes.select_async,
    label:
      'According to the regulation or requirement, in what language(s) must the data be disclosed?',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMulti: true,
      showSelectedData: true,
      isMandatory: true,
    },
  },
  disclosuRequirementInternalComments: {
    name: 'disclosuRequirementInternalComments',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 10,
      rowsMax: 5,
      cols: 150
    },
  },
};
