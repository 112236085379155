import React, { useEffect, useState, useRef, lazy } from 'react';
import 'devextreme/dist/css/dx.light.css';
import PropTypes from 'prop-types';
import './index.scss';
import DataGrid, {
  Export,
  Column,
  Selection,
  SearchPanel,
  ColumnFixing,
  FilterRow,
  Scrolling,
  Paging,
  LoadPanel,
  Lookup,
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import {
  ListView,
  TableActions,
  DefaultCellRender,
  ProductionStatus,
  Link,
  DrugTypeId,
} from '../../framework';
import format from 'date-fns/format';
import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';
import { Button } from 'devextreme-react';
import MechanismParentChild from '../../screens/ancillaryData/components/hierarchyIndicator/mechanism';
import HierarchyIndicator from '../../screens/ancillaryData/components/hierarchyIndicator/company';
import TableLink from '../../screens/ancillaryData/components/tableLink';
import DrugTypeParentChild from '../../screens/ancillaryData/components/hierarchyIndicator/drugType';
import TargetFamilyParentChild from '../../screens/ancillaryData/components/hierarchyIndicator/targetFamily';
import {
  REGINTEL_ENTITIES,
  sortableColumnNames,
  USE_DEVEXTREME_SCROLL,
} from '../../utils/generic/constants';
import DrugsHierarchy from '../../screens/entitySearch/DrugsHierarchy';
import _ from 'lodash';
const DNADevExtremeDataGrid = ({
  styleName,
  showStickyHeader,
  isHorizontalScrollBar,
  handleSortChanged,
  handleColumnSorting,
  doNotConvertDataForTree,
  setOrderOfSorting,
  orgOrder,
  setSelectedRowsList,
  selectedRowsList,
  gridDataGridRef,
  onGridReady,
  showCheckboxByDefault,
  useDynamicSorting,
  selectedColumnList,
  configuration,
  rowData,
  columnDefs,
  selectAllMode,
  handleFilter = () => {},
  selectedEntity,
  setColumnsPosition,
  columnsPosition,
  searchFlag,
  previousSearchedConditions,
  dropDown,
  enableExporting,
  exportFileName,
  setDefaultFilteredValues,
  defaultFilteredValues,
  customClassName,
  customTop,
  deselectAllSelectedOptions,
}) => {
  const {
    enableSelection,
    enableSearchPanel,
    enableColumnFixing,
    enableFilterRow,
    enableExport,
    enablePaging,
    overrideSelectedColumnCheck,
    enableFiltering,
    overrideEmptyGrid,
  } = configuration;
  const customScroller = useRef(null);
  const scrollRef = useRef(null);
  const [isSticky, setSticky] = useState(false);
  const [datasource, setDatasource] = useState([]);
  const [widthOfScroll, setWidthOfScroll] = useState('2800px');
  const [isExporting, setIsExporting] = useState(false);
  const [height, setHeight] = React.useState(window?.screen?.availHeight);
  const [exportHeight, setExportHeight] = useState(0);
  React.useEffect(() => {
    window.addEventListener('resize', () => setHeight(window?.screen?.availHeight));
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  React.useEffect(() => {
    document.body.classList.add('hide-scrollbar');
    return () => {
      document.body.classList.remove('hide-scrollbar');
    };
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', () => setHeight(window?.screen?.availHeight));
    return () => {
      window.removeEventListener('resize', () => setHeight(window?.screen?.availHeight));
    };
  }, []);

  useEffect(() => {
    if (showStickyHeader) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (showStickyHeader) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (document.getElementById('export-fixed')) {
      const height = document.getElementById('export-fixed').clientHeight;
      setExportHeight(height);
    }
  }, [datasource]);

  useEffect(() => {
    if (!_.isEqual(rowData, datasource)) {
      setDatasource(JSON.parse(JSON.stringify(rowData)));
    }
  }, [rowData]);

  useEffect(() => {
    setTimeout(() => {
      if (
        document.querySelector('.dx-scrollable-content') &&
        document.querySelector('.dx-scrollable-content').scrollWidth &&
        document.querySelector('.dx-scrollable-content').scrollWidth != widthOfScroll
      ) {
        setWidthOfScroll(document.querySelector('.dx-scrollable-content').clientWidth);
      }
      const scrollViewportSelector = '.dx-scrollable-container';
      const scrollWrapperSelector = '.customScrollbar';
      const scrollViewportElement = document.querySelector(scrollViewportSelector);
      const scrollWrapperElement = document.querySelector(scrollWrapperSelector);
      if (scrollViewportElement && scrollWrapperElement) {
        var x = 1;
        scrollViewportElement.onscroll = function() {
          if (x == 1) {
            x = 0;
            scrollWrapperElement.scrollLeft = scrollViewportElement.scrollLeft;
          } else {
            x = 1;
          }
        };
        scrollWrapperElement.onscroll = function() {
          if (x == 1) {
            x = 0;
            scrollViewportElement.scrollLeft = scrollWrapperElement.scrollLeft;
          } else {
            x = 1;
          }
        };
      }
    }, 1000);
  }, [selectedColumnList]);

  const handleScroll = () => {
    // if (scrollRef.current) {
    //   setSticky(window.pageYOffset >= scrollRef.current.offsetTop);
    // }
    const gridHeaderSelctor = '.dx-datagrid-headers';
    const gridHeaderElement = document.querySelector(gridHeaderSelctor);
    // gridHeaderElement.classList.add('sticky-header')
  };

  const convert = (array = []) => {
    if (!array.length) {
      return [];
    }
    var map = {};
    for (var i = 0; i < array.length; i++) {
      var obj = array[i];
      obj.items = [];
      obj.text = obj.displayValue;
      // obj.isExpanded = true
      map[obj.id] = obj;
      if (!obj.parentid) {
        obj.parentid = 0;
      }
      var parent = obj.parentid || obj.parentId || '-';
      if (obj.parent) {
        let parentDescription = array.filter(it => it.id == obj.parent.id);
        if (parentDescription.length) {
          obj.parent.description = parentDescription[0].description;
        }
      }
      if (!map[parent]) {
        map[parent] = {
          items: [],
        };
      }
      map[parent].items.push(obj);
    }
    let k = map['-'].items;
    return k;
  };
  const getCustomComponent = (col, data) => {
    if (typeof col.cellRenderer == 'function') {
      return col.cellRenderer(data);
    } else if (col.cellRenderer == 'listView' || col.cellRenderer == 'ListView') {
      return <ListView {...col.cellRendererParams({ data: data.data, rowIndex: data.rowIndex })} />;
    } else if (col.cellRenderer == 'actions') {
      return (
        <TableActions
          fromTreeGrid={true}
          data={data.data}
          {...col.cellRendererParams({ data: data.data, rowIndex: data.rowIndex })}
        />
      );
    } else if (col.cellRenderer == 'tableLink') {
      return (
        <TableLink {...col.cellRendererParams({ data: data.data, rowIndex: data.rowIndex })} />
      );
    } else if (col.cellRenderer == 'MechanismParentChild') {
      return <MechanismParentChild {...data} />;
    } else if (col.cellRenderer == 'DefaultCellRender') {
      return <DefaultCellRender {...data} />;
    } else if (col.cellRenderer == 'HierarchyIndicator') {
      return <HierarchyIndicator {...data} />;
    } else if (col.cellRenderer == 'DrugTypeParentChild') {
      return <DrugTypeParentChild {...data} />;
    } else if (col.cellRenderer == 'TargetFamilyParentChild') {
      return <TargetFamilyParentChild {...data} />;
    } else if (col.cellRenderer == 'ProductionStatus') {
      return (
        <ProductionStatus
          {...col.cellRendererParams({ data: data.data, rowIndex: data.rowIndex })}
        />
      );
    } else if (col.cellRenderer == 'DrugTypeId') {
      return (
        <DrugTypeId {...col.cellRendererParams({ data: data.data, rowIndex: data.rowIndex })} />
      );
    } else if (col.cellRenderer == 'DrugsHierarchy') {
      return (
        <DrugsHierarchy
          data={data.data}
          {...col.cellRendererParams({ data: data.data, rowIndex: data.rowIndex })}
        />
      );
    } else if (col.cellRenderer == 'Link') {
      return <Link {...col.cellRendererParams({ data: data.data, rowIndex: data.rowIndex })} />;
    }
    return null;
  };

  const fileNameConvert = () => {
    const dateFormat = format(new Date(), 'MM-dd-yyyy');
    if (selectedEntity) {
      let selectedEntityWithCapital =
        selectedEntity.charAt(0).toUpperCase() + selectedEntity.slice(1);
      return `${selectedEntityWithCapital}_${dateFormat}`;
    } else {
      return `${exportFileName}_${dateFormat}`;
    }
  };

  const changeProductionStatusValue = value => {
    const statusValueInNu = Number(value);
    switch (statusValueInNu) {
      case 0:
        return 'Out Of Production';
      case 1:
        return 'In Production';
      case 2:
        return 'Out Of Scope';
      case 3:
        return 'Pending Review';
      case 4:
        return 'Data Check';
      case 5:
        return 'External Source';
      case 6:
        return 'External Source';
      default:
        // return null;
        return 'NA';
    }
  };

  const changeDrugTypeValue = value => {
    if (!isEmpty(value)) {
      return value;
    } else {
      return 'Out Of Production';
    }
  };

  const changeDrugTypeStatus = value => {
    const statusValueInNu = Number(value);
    switch (statusValueInNu) {
      case 1:
        return 'Pharmaprojects Master Drug';
      case 2:
        return 'Pharmaprojects Drug';
      case 3:
        return 'Pharmaprojects Child Drug';
      case 4:
        return 'Trialtrove Only Drug';
      default:
        return null;
    }
  };

  const discloseEntity = ['registry', 'sourceDocument', 'country', 'requirement'];
  const dateFormatInSheet = value => {
    if (value === 'NA') {
      return 'NA';
    } else {
      let formatDate;
      if (discloseEntity.includes(selectedEntity)) {
        formatDate = format(new Date(value), 'dd-MMM-yyyy');
      } else {
        formatDate = format(new Date(value), 'MMM-dd-yyyy');
      }
      return formatDate;
    }
  };

  const columnArrayForSheetValue = [
    'createdDate',
    'nextReviewDate',
    'lastModifiedDate',
    'regulatoryActionDate',
    'nextFullReviewDate',
    'modifiedDate',
    'startDate',
    'primaryCompletionDate',
    'lastFullReviewDate',
    'latestChangeDate',
    'regulatoryDate',
  ];
  const checkIsEmpty = value => {
    return (
      value === undefined ||
      value === null ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0)
    );
  };
  const grid = useRef();
  const onExporting = (exportSelected = false) => {
    setIsExporting(true);
    setTimeout(() => {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      exportDataGrid({
        // component: e.component,
        component: grid.current.instance,
        // worksheet,
        worksheet: worksheet,
        selectedRowsOnly: exportSelected,
        // autoFilterEnabled: false,
        // keepColumnWidths: false,
        // customizeCell: function (options) {
        customizeCell: function({ gridCell, excelCell }) {
          // const excelCell = options;
          // options.excelCell.alignment = { horizontal: 'left' };
          excelCell.alignment = { horizontal: 'left' };
          if (gridCell.rowType === 'data') {
            if (
              (gridCell.column.dataField === 'productionStatus' ||
                gridCell.column.dataField === 'productionStatusId') &&
              selectedEntity !== 'company' &&
              !discloseEntity.includes(selectedEntity)
            ) {
              excelCell.value = changeProductionStatusValue(excelCell.value);
            }
            if (selectedEntity === 'company' && gridCell.column.dataField === 'productionStatus') {
              excelCell.value = changeDrugTypeValue(excelCell.value);
            }
            if (
              discloseEntity.includes(selectedEntity) &&
              gridCell.column.dataField === 'productionStatusId'
            ) {
              excelCell.value = changeProductionStatusValue(excelCell.value - 1);
            }
            if (columnArrayForSheetValue.includes(gridCell.column.dataField)) {
              excelCell.value = dateFormatInSheet(excelCell.value);
            }
            if (gridCell.column.dataField === 'drugTypeId') {
              excelCell.value = changeDrugTypeStatus(excelCell.value);
            }
            if (checkIsEmpty(excelCell.value) && !isEmpty(gridCell.column.dataField)) {
              excelCell.value = 'NA';
            }
          }
        },
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            `${fileNameConvert()}.xlsx`,
          );
          setIsExporting(false);
        });
        if (exportSelected) deselectAllSelectedOptions();
      });
    }, 2000);
  };

  const onOptionChanged = e => {
    if (e.fullName.includes('sortOrder') && useDynamicSorting) {
      let visibleColumn = gridDataGridRef.current.getVisibleColumns();
      let columns = visibleColumn.map(el => el.dataField);
      let num = Number(e.fullName.replace(/\D/g, ''));
      num = gridDataGridRef.current.getVisibleColumnIndex(num);
      let columnNameForSorting = columns[num];
      let k = columnDefs.filter(el => el.fieldName == visibleColumn[num].dataField)[0];
      if (k?.entityTypeId) {
        columnNameForSorting = sortableColumnNames[k.entityTypeId][k.fieldName];
      }
      handleSortChanged({
        useLatestSort: true,
        sortObjects: [{ colId: num || columns[num], sort: e.value, columnName: columns[num] }],
      });
      handleColumnSorting(columnNameForSorting, e.value == 'asc' ? false : true);
    }
    if (e.fullName === 'selectedRowKeys') {
      if (enableSelection) setSelectedRowsList(e.value);
    }
    if (e.fullName.includes('filterValue') && enableFiltering) {
      let visibleColumn = gridDataGridRef.current.getVisibleColumns();
      let columns = visibleColumn.map(el => el.dataField);
      let num = Number(e.fullName.replace(/\D/g, ''));
      num = gridDataGridRef.current.getVisibleColumnIndex(num);
      let columnNameForFiltering = columns[num];

      let defaultFilteredValuescopy = { ...defaultFilteredValues };
      if (setDefaultFilteredValues && defaultFilteredValuescopy) {
        defaultFilteredValuescopy[columnNameForFiltering] = e.value;
        setDefaultFilteredValues(defaultFilteredValuescopy);
      }
      handleFilter(columnNameForFiltering, e.value);
    }
  };
  const isPresentInSelectedColumnList = colId => {
    if (overrideSelectedColumnCheck) return true;
    let filteredColumn = selectedColumnList.filter(col => col.id == colId);
    if (filteredColumn.length) {
      return true;
    } else {
      return false;
    }
  };

  const getSortedLookUpValues = values => {
    let sortedSelectedOptions = values
      ? [...JSON.parse(JSON.stringify(values))].sort(function(a, b) {
          a = a.Name.toLowerCase();
          b = b.Name.toLowerCase();
          if (a == b) return 0;
          return a < b ? -1 : 1;
        })
      : [];
    return sortedSelectedOptions;
  };

  const onEditorPreparing = e => {
    if (e.type !== 'selection') return;
    if (
      selectedEntity === 'dashboard' &&
      e.parentType === 'dataRow' &&
      e.row?.data?.editionStatus !== 'Ready To Publish'
    )
      e.editorOptions.disabled = true;
  };

  const onSelectionChanged = e => {
    const deselectRowKeys = [];
    e.selectedRowsData.forEach(item => {
      if (item?.editionStatus !== 'Ready To Publish' && selectedEntity === 'dashboard')
        deselectRowKeys.push(e.component.keyOf(item));
    });
    if (deselectRowKeys.length) {
      e.component.deselectRows(deselectRowKeys);
    }

    return;
  };

  return (
    <div
      className={`custom-tree-implementation ${styleName} ${
        isSticky ? 'stickyVisible' : ''
      }  ${customClassName}`}
      ref={scrollRef}
      style={{
        '--topDis': `${selectedEntity === 'dashboard' ? customTop : exportHeight + customTop}px`,
      }}
    >
      {isHorizontalScrollBar && (
        <div class="customScrollbar" ref={customScroller}>
          <div class="dummyEle" style={{ width: widthOfScroll }}></div>
        </div>
      )}
      <div className={`dev-data-grid ${!selectAllMode && 'hide-showall-checkbox'}`}>
        {selectedEntity !== 'dashboard' &&
        REGINTEL_ENTITIES.includes(selectedEntity) &&
        datasource.length > 0 &&
        datasource.length <= 1000 ? (
          <Grid
            item
            xs
            className="gridButton exportDevextreme-regintel"
            id="export-fixed"
            style={{ '--topExportDis': `${customTop}px` }}
          >
            <div className="regintel-export">
              <Button
                className="exportButton"
                icon="export"
                text="Export all"
                onClick={() => onExporting()}
              />
              <Button
                className="exportButton"
                icon="export"
                text="Export selected"
                onClick={() => onExporting(true)}
                disabled={selectedRowsList.length === 0}
              />
            </div>
          </Grid>
        ) : (
          ((searchFlag &&
            (selectedEntity === 'clinicalTrials' ||
              'drugs' ||
              'company' ||
              'person' ||
              'organization')) ||
            enableExporting) &&
          datasource.length > 0 &&
          datasource.length <= 1000 && (
            <Grid item xs className="gridButton exportDevextreme">
              <Button
                className="exportButton"
                icon="export"
                text="Export"
                onClick={() => onExporting()}
              />
            </Grid>
          )
        )}

        {datasource.length || overrideEmptyGrid ? (
          <DataGrid
            ref={grid}
            key="newdatagrid"
            dataSource={datasource}
            autoExpandAll={true}
            showRowLines={true}
            showBorders={true}
            columnAutoWidth={false}
            dataStructure="tree"
            showColumnLines={false}
            wordWrapEnabled={true}
            className="treelist-custom-style"
            // onExporting={onExporting}
            onOptionChanged={onOptionChanged}
            onSelectionChanged={onSelectionChanged}
            onContentReady={onGridReady}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnResizingMode={'nextColumn'}
            onEditorPreparing={onEditorPreparing}
            // height={height === 1080 ? 900 : height - 240}
            style={USE_DEVEXTREME_SCROLL ? { height: '1000px', width: '100%' } : { width: '100%' }}
          >
            {
              // enableSelection && showCheckboxByDefault ?
              <Selection
                mode="multiple"
                selectAllMode={selectAllMode}
                allowSelectAll={selectAllMode}
                showCheckBoxesMode={enableSelection && showCheckboxByDefault ? 'always' : 'never'}
              />
            }
            {enableSearchPanel ? <SearchPanel visible={true} /> : null}
            {enableColumnFixing ? <ColumnFixing enabled={true} /> : null}
            {enableFilterRow ? <FilterRow visible={true} /> : null}
            <LoadPanel enabled={true}></LoadPanel>
            {enablePaging ? <Paging enabled={false} /> : null}
            {columnDefs.map((col, index) => {
              return (isPresentInSelectedColumnList(col.id) || col.cellRenderer == 'actions') &&
                col.cellRenderer &&
                !isExporting ? (
                <Column
                  dataField={col.field || col.fieldName}
                  caption={col.headerName}
                  cellRender={data => getCustomComponent(col, data)}
                  // minWidth={col.minWidth}
                  width={col.maxWidth || 250}
                  allowSorting={col.sortable || false}
                  // sortIndex={columnsPosition[col.field || col.fieldName] ? columnsPosition[col.field || col.fieldName] : undefined}
                  fixed={col.pinned ? true : false}
                  fixedPosition={col.pinned}
                  sortOrder={
                    orgOrder[col.field || col.fieldName]
                      ? orgOrder[col.field || col.fieldName]
                      : undefined
                  }
                  key={index}
                  visible={selectedColumnList.find(data => data.id === col.id)}
                  allowReordering={true}
                  // dataType="date"
                  headerCellRender={data => {
                    if (!data.column.caption && document.getElementById(data.column.headerId)) {
                      document.getElementById(data.column.headerId).style.borderRight = 'none';
                    }
                    return <>{data.column.caption}</>;
                  }}
                  filterValue={
                    defaultFilteredValues && defaultFilteredValues[col.field || col.fieldName]
                      ? defaultFilteredValues[col.field || col.fieldName]
                      : undefined
                  }
                  allowExporting={col.cellRenderer == 'actions' ? false : true}
                >
                  {col.isLookup ? (
                    <Lookup
                      dataSource={getSortedLookUpValues(col.lookupValues)}
                      displayExpr="Name"
                      valueExpr="ID"
                      allowClearing={true}
                    />
                  ) : (
                    <FilterRow visible={true} />
                  )}
                </Column>
              ) : isPresentInSelectedColumnList(col.id) || col.cellRenderer == 'actions' ? (
                <Column
                  dataField={col.field || col.fieldName}
                  caption={col.headerName}
                  allowSorting={col.sortable || false}
                  width={col.maxWidth || 250}
                  // minWidth={col.minWidth}
                  // sortIndex={columnsPosition[col.field || col.fieldName] ? columnsPosition[col.field || col.fieldName] : undefined}
                  fixed={col.pinned ? true : false}
                  fixedPosition={col.pinned}
                  sortOrder={
                    orgOrder[col.field || col.fieldName]
                      ? orgOrder[col.field || col.fieldName]
                      : undefined
                  }
                  key={index}
                  visible={selectedColumnList.find(data => data.id === col.id)}
                  allowReordering={true}
                  filterValue={
                    defaultFilteredValues && defaultFilteredValues[col.field || col.fieldName]
                      ? defaultFilteredValues[col.field || col.fieldName]
                      : undefined
                  }
                >
                  {col.isLookup ? (
                    <Lookup
                      dataSource={getSortedLookUpValues(col.lookupValues)}
                      displayExpr="Name"
                      valueExpr="ID"
                      allowClearing={true}
                    />
                  ) : (
                    <FilterRow visible={true} />
                  )}
                </Column>
              ) : null;
            })}
            {isExporting ||
            ((((selectedEntity === 'clinicalTrials' ||
              'drugs' ||
              'company' ||
              'person' ||
              'organization') &&
              searchFlag) ||
              enableExporting) &&
              datasource.length > 250) ? (
              <Scrolling useNative={true} mode="virtual" />
            ) : (
              <Scrolling useNative={true} mode="standard" />
            )}
            {/* <Scrolling useNative={true} mode="virtual" /> */}
          </DataGrid>
        ) : (
          <div>
            <DataGrid
              id="employees"
              keyExpr="id"
              dataSource={[]}
              autoExpandAll={true}
              showRowLines={true}
              showBorders={false}
              parentIdExpr="parentid"
              itemsExpr="items"
              dataStructure="tree"
              showColumnLines={false}
              className="treelist-custom-style"
              style={{ minHeight: '400px' }}
            >
              {columnDefs.map((col, index) => {
                return isPresentInSelectedColumnList() &&
                  col.isVisible != false &&
                  col.cellRenderer ? (
                  <Column
                    dataField={col.field}
                    caption={col.headerName}
                    cellRender={data => getCustomComponent(col, data)}
                    minWidth={col.minWidth}
                    width={col.maxWidth}
                    allowSorting={col.sortable || false}
                    fixed={col.fixed}
                    fixedPosition={col.fixedPosition}
                    key={index}
                    visible={col.isVisible}
                  >
                    <Lookup />
                  </Column>
                ) : isPresentInSelectedColumnList() && col.isVisible != false ? (
                  <Column
                    dataField={col.field}
                    caption={col.headerName}
                    allowSorting={col.sortable || false}
                    fixed={col.fixed}
                    fixedPosition={col.fixedPosition}
                    key={index}
                    visible={col.isVisible}
                  >
                    {' '}
                    <Lookup />
                  </Column>
                ) : null;
              })}
            </DataGrid>
          </div>
        )}
      </div>
    </div>
  );
};

DNADevExtremeDataGrid.propTypes = {
  columnDefs: PropTypes.array,
  rowData: PropTypes.array,
  headerHeight: PropTypes.number,
  styleName: PropTypes.string,
  height: PropTypes.string,
  showStickyHeader: PropTypes.bool,
  rowHeight: PropTypes.number,
  handleSortChanged: PropTypes.func,
  selectAllMode: PropTypes.bool,
  customClassName: PropTypes.string,
  selectedRowsList: PropTypes.array,
};

DNADevExtremeDataGrid.defaultProps = {
  columnDefs: [],
  rowData: [],
  styleName: 'basic-table',
  height: '100%',
  showStickyHeader: true,
  headerHeight: 60,
  rowHeight: 65,
  selectAllMode: false,
  customClassName: '',
  selectedRowsList: [],
};

export default React.memo(DNADevExtremeDataGrid);
