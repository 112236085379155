const drugFeaturesInitialState = {
  id: 0,
  ingestedId: 0,
  key: null,
  summary: {
    drugSummary: null,
    globalStatus: null,
    devStatus: {
      id: 1,
      value: 'Active',
      displayValue: 'Active',
    },
    createdBy: '',
    modifiedBy: '',
    createdDate: null,
    modifiedDate: null,
    latestChangeDate: null,
    latestChangeText: null,
    latestChangeModifiedBy: null,
    lastFullReviewDate: null,
    lastFullReviewBy: '',
    nextReviewDate: null,
    latestChangeDatePublishOnFos: null,
  },
  names: [],
  licensingAvailability: {
    licenses: [],
    agreement: '',
    availability: '',
  },
  notes: [],
  events: [],
  chemStructure: {
    casRegistrys: [],
    id: 0,
    molecularData: {
      cdid: null,
      chemicalName: '',
      formula: '',
      hBondAcceptors: '',
      hBondDonors: '',
      imageUri: '',
      logp: '',
      rotatableBonds: '',
      smilesFormula: '',
      weight: '',
    },
    nbe: false,
    nce: false,
    origin: null,
    patents: [],
    drugDrugTypeClassifications: [],
    pubchemCIDs: [],
  },
  landscapeStatuses: [],
  deletedLandscapeStatuses: [],
  validations: {
    landscapeStatuses: {
      totalCount: 0,
      originatorCompanyId: 0,
      highestCurrentDevStatus: null,
    },
  },
  activity: {
    mechanismOfActions: [],
    deliveryMediums: [],
    deliveryRoutes: [],
    deliveryTechnologies: [],
    therapeuticClasses: [],
    therapeuticClassesStatus: [],
    biologicalTargets: [],
    phramacokinetics: [],
    deletedTherapeuticClasses: [],
  },
  marketing: {
    id: 0,
    approvals: '',
    fillings: '',
    orphanDrugStatus: '',
    expeditedReview: '',
    warnings: '',
  },
  phases: {
    id: 0,
    inVivo: '',
    inVitro: '',
    patents: '',
    phaseI: '',
    phaseII: '',
    phaseIII: '',
  },
  supportingUrls: [],
  forceToFieldUpdate: 0,
  productionStatusId: 0,
  drugType: {
    id: 2,
    value: 'Pharmaprojects Drug',
  },
  masterDrug: null, // it should have values only for child drug
};

export default drugFeaturesInitialState;
