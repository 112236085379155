import { takeEvery, all, put } from 'redux-saga/effects';
import * as OrganizationActions from '../actions/organization';
import * as globalActions from '../actions/globalActions';
import * as TaskActions from '../actions/task';
import { organization as organizationApi, queues as queuesApi, task as taskApi } from '../../api';
import {
  getProdMessage,
  getLockMessageInfo,
  getMultipleDeleteErrorMessage,
} from '../../utils/generic/helper';
import {
  ALERT_MESSAGES,
  MODAL_TYPES,
  updateTypeOnProdStatusSave,
} from '../../utils/generic/constants';
import { getApiErrorMessage } from '../../utils/generic/helper';

export function* getOrganizationListAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield organizationApi.getOrganizationList(payload);
    const { state, data } = response.data;
    if (state) {
      yield put(OrganizationActions.getOrganizationListSuccess(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(OrganizationActions.setError(err));
  }
}

export function* deleteOrganizationAsync({ payload }) {
  try {
    const { organizationId, postParam } = payload;
    yield put(globalActions.showSpinner());
    const response = yield organizationApi.deleteOrganization(organizationId);
    yield put(globalActions.hideSpinner());
    const {
      data: { state, messages },
    } = response;
    if (state) {
      yield put(OrganizationActions.getOrganizationList(postParam));
      yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_DELETE));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.ORGANIZATION_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* deleteMultipleOrganizationAsync({ payload: { orgIds, postParam } }) {
  try {
    yield put(globalActions.showSpinner());
    const actions = {};
    orgIds.map(id => {
      actions[id] = organizationApi.deleteOrganization(id);
    });
    const responses = yield all(actions);
    const { errorMessage, deletedRecordCount } = getMultipleDeleteErrorMessage(responses);
    if (deletedRecordCount) {
      yield put(OrganizationActions.getOrganizationList(postParam));
    }
    const modalData = {
      isOpen: true,
      modalType: MODAL_TYPES.CUSTOM_INFO_LIST,
      modalListMessage: errorMessage,
    };
    yield put(globalActions.showModal(modalData));

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* getMasterDataAsync() {
  try {
    yield put(globalActions.showSpinner());
    const response = yield organizationApi.getMasterData();
    const { state, data } = response.data;
    if (state) {
      yield put(OrganizationActions.getMasterDataSuccess(data));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    yield put(OrganizationActions.setError(err));
    yield put(globalActions.hideSpinner());
  }
}

export function* postOrganizationAsync({ payload: { data: payloadData, key, tabData, history } }) {
  try {
    yield put(globalActions.showSpinner());
    const { prodStatusID, previousProductionStatus } = payloadData;
    const response = yield organizationApi.postOrganizationData(payloadData);
    const { state, data } = response.data;
    if (state) {
      const { duplicateAddressOrganizations = [] } = data;
      yield put(OrganizationActions.postOrganizationSuccess({ key, data }));
      if (
        payloadData.updateType &&
        payloadData.updateType === updateTypeOnProdStatusSave.organization
      ) {
        yield put(globalActions.showAlert(getProdMessage(prodStatusID, previousProductionStatus)));
        // history &&
        //   setTimeout(() => {
        //     history.push(`/organization`);
        //   }, 2000);
      } else {
        if (
          key === 'contactInfo' &&
          duplicateAddressOrganizations &&
          duplicateAddressOrganizations.length
        ) {
          let modalData = {
            isOpen: true,
            message:
              'Organization Record Saved Successfully. Address already used in below record/records',
            modalListMessage: duplicateAddressOrganizations.map(element => ({
              name: `${element.value}`,
              link: `/organization/${element.id}`,
            })),
            modalType: MODAL_TYPES.INFO_LIST,
          };
          yield put({ type: globalActions.showModal.type, payload: modalData });
        } else {
          yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_SAVE));
        }
      }
      if (tabData) {
        yield put(OrganizationActions.nextFeatureSelection(tabData.value));
      }
    } else {
      let message =
        response.data.messages && response.data.messages.length
          ? response.data.messages[0].errorMessage
          : 'Problem with server';
      let modalData = {
        isOpen: true,
        message: `${message.toUpperCase()}`,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put({ type: globalActions.showModal.type, payload: modalData });
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* getOrganizationStampingDetailsAsync({ payload: { body } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield organizationApi.postOrganizationData(body);
    const { state, data } = response.data;
    if (state) {
      yield put(OrganizationActions.getOrganizationStampingDetailsSuccess({ data }));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(OrganizationActions.setError());
  }
}

export function* updateOrganizationLockDetails({ payload }) {
  try {
    yield organizationApi.getUserLockDetails(payload);
  } catch (err) {
    yield put(OrganizationActions.setError());
  }
}

export function* fetchOrganizationFeaturesAsync({ payload: { id, ingestedId } }) {
  try {
    yield put(globalActions.showSpinner());
    let actions = { masterActions: organizationApi.getMasterData() };
    if (ingestedId > 0) {
      actions = { ...actions, ingestedAction: organizationApi.getIngestedData(ingestedId) };
    }
    if (id > 0) {
      actions = {
        ...actions,
        organizationFeaturesAction: organizationApi.fetchOrganizationFeature(id),
        lockDetails: organizationApi.getUserLockDetails(id),
      };
    }
    const { masterActions, organizationFeaturesAction, lockDetails, ingestedAction } = yield all(
      actions,
    );

    if (masterActions) {
      const {
        data: { state: masterState, data: masterData },
      } = masterActions;
      if (masterState) {
        yield put(OrganizationActions.getMasterDataSuccess(masterData));
      }
    }

    if (lockDetails) {
      const {
        data: { state, data, messages },
      } = lockDetails;
      if (state) {
        yield put(OrganizationActions.getOrganizationUserLockDetails(data));
        const { isEditable, lockedBy } = data;
        if (!isEditable) {
          yield put(
            globalActions.showModal({
              isOpen: true,
              message: getLockMessageInfo(lockedBy),
              modalType: MODAL_TYPES.INFO,
            }),
          );
        }
      } else {
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: getApiErrorMessage(messages),
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    }

    if (organizationFeaturesAction) {
      const { state: organizationState, data: organizationData } = organizationFeaturesAction.data;
      if (organizationState) {
        yield put(OrganizationActions.fetchOrganizationFeatureSuccess({ data: organizationData }));
      }
    }

    if (ingestedAction) {
      const { state: ingestedState, data: ingestedData } = ingestedAction.data;
      if (ingestedState) {
        yield put(OrganizationActions.getOrganizationIngestedDataSuccess(ingestedData));
      }
    }

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(OrganizationActions.setError(err));
    yield put(globalActions.hideSpinner());
  }
}

export function* getOrganizationTreeAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    let treeVieData = yield organizationApi.getOrganizationTree(payload);

    const { data: { state = false, data = [] } = {} } = {
      ...treeVieData,
    };
    if (state) {
      yield put(
        OrganizationActions.getOrganizationTreeSuccess({
          data,
        }),
      );
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(OrganizationActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
  }
}
export function* getAddressValidationAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    let awsLocationServiceData = yield organizationApi.validateAddress(payload);

    const { data, data: { validAddresses = [] } = {} } = {
      ...awsLocationServiceData,
    };
    yield put(OrganizationActions.setAddressValidationSuccess({ data }));
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(OrganizationActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
  }
}

//QUEUES

export function* getQueueTypesAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield queuesApi.getQueueTypes(payload);
    yield put(globalActions.hideSpinner());
    const {
      data: { state, data },
    } = response;
    if (state) {
      yield put(OrganizationActions.getQueueTypesSuccess({ data }));
      // yield put(OrganizationActions.setSelectedQueueType(data[0]));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
    }
  } catch (err) {
    yield put(OrganizationActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
  }
}

export function* getOrganizationQueueRecordsAsync({ payload: { queueDetails, isQueue } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = isQueue
      ? yield queuesApi.getOrganizationQueueRecords(queueDetails)
      : yield taskApi.getTaskOrganizationQueueRecords(queueDetails);
    const {
      data: { state, data },
    } = response;
    if (state) {
      isQueue
        ? yield put(OrganizationActions.getOrganizationQueueRecordsSuccess(data))
        : yield put(OrganizationActions.getTaskQueueRecordsSuccess(data));
      yield put(globalActions.hideSpinner());
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
      isQueue
        ? yield put(OrganizationActions.getOrganizationQueueRecordsSuccess([]))
        : yield put(OrganizationActions.getTaskQueueRecordsSuccess([]));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    isQueue
      ? yield put(OrganizationActions.getOrganizationQueueRecordsSuccess([]))
      : yield put(OrganizationActions.getTaskQueueRecordsSuccess([]));
    yield put(OrganizationActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
  }
}

// export function* getOrganizationTaskQueueRecordsAsync({ payload: { queueDetails } }) {
//   try {
//     yield put(globalActions.showSpinner());
//     const response = yield taskApi.getTaskOrganizationQueueRecords(queueDetails);
//     const {
//       data: { state, data },
//     } = response;
//     if (state) {
//       yield put(OrganizationActions.getTaskQueueRecordsSuccess(data));
//       yield put(globalActions.hideSpinner());
//     } else {
//       yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
//       yield put(OrganizationActions.getTaskQueueRecordsSuccess([]));
//       yield put(globalActions.hideSpinner());
//     }
//   } catch (err) {
//     yield put(OrganizationActions.getTaskQueueRecordsSuccess([]));
//     yield put(OrganizationActions.setError(err));
//     yield put(globalActions.hideSpinner());
//     yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
//   }
// }

export function* releaseOrganizationUserLockAsync({ payload }) {
  try {
    yield organizationApi.unlockUserRecords(payload);
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
  }
}

export function* getQueueDashboardDataAsync({
  payload: { entityId, listPostParam, assigneeUserGroup },
}) {
  try {
    yield put(globalActions.showSpinner());

    const actions = {
      queueTypesAction: queuesApi.getQueueTypes(entityId),
      taskQueueActions: taskApi.getTaskQueueTypes(entityId),
      orgListAction: organizationApi.getOrganizationList(listPostParam),
      taskMasterActions: taskApi.getTaskMaster(),
      assigneeUserGroupListActions: taskApi.assigneeUserGroupList(assigneeUserGroup),
    };

    const {
      queueTypesAction,
      taskQueueActions,
      orgListAction,
      taskMasterActions,
      assigneeUserGroupListActions,
    } = yield all(actions);

    if (queueTypesAction) {
      const {
        data: { state, data },
      } = queueTypesAction;

      if (state) {
        yield put(OrganizationActions.getQueueTypesSuccess({ data }));
      }
    }

    if (taskQueueActions) {
      const {
        data: { state, data },
      } = taskQueueActions;

      if (state) {
        yield put(OrganizationActions.getTaskQueueTypesSuccess({ data }));
      }
    }

    if (orgListAction) {
      const {
        data: { state, data },
      } = orgListAction;

      if (state) {
        yield put(OrganizationActions.getOrganizationListSuccess(data));
      }
    }
    if (taskMasterActions) {
      const {
        data: { data, state },
      } = taskMasterActions;

      if (state) {
        yield put(TaskActions.getTaskMasterSuccess({ data }));
      }
    }
    if (assigneeUserGroupListActions) {
      const {
        data: { data, state },
      } = assigneeUserGroupListActions;

      if (state) {
        yield put(TaskActions.assigneeUserGroupListSuccess({ data }));
      }
    }

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* postExitQueueAysnc({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { queueTransactionId } = payload;
    const postExitQueueResponse = yield queuesApi.postExitQueue({ id: queueTransactionId });
    const {
      data: { state, data },
    } = postExitQueueResponse;
    if (state && data) {
      const modalData = {
        isOpen: true,
        message: 'RECORD EXIT DONE SUCCESSFULLY',
        modalType: MODAL_TYPES.SUCCESS,
      };
      yield put({ type: globalActions.showModal.type, payload: modalData });
      yield put(OrganizationActions.getQueueTypesAndRecords(payload));
    } else {
      yield put(globalActions.hideSpinner());
      yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
    }
  } catch (err) {
    yield put(OrganizationActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
  }
}

export function* getQueueTypesAndRecordsAsync({ payload }) {
  try {
    const { queueType, entityId, rowCount, listPostParam, pageIndex, pageSize } = payload;

    const postQueueTypesResponse = yield queuesApi.getQueueTypes(entityId);
    const {
      data: { state, data },
    } = postQueueTypesResponse;

    if (state) {
      yield put(OrganizationActions.getQueueTypesSuccess({ data }));
      if (rowCount === 1) {
        yield put(OrganizationActions.setQueueExpandedList([]));
        const response = yield organizationApi.getOrganizationList(listPostParam);
        const { state, data, messages } = response.data;
        if (state) {
          yield put(OrganizationActions.setSelectedQueueType(queueType));
          yield put(OrganizationActions.getOrganizationListSuccess(data));
        } else {
          yield put(
            globalActions.showModal({
              isOpen: true,
              message: getApiErrorMessage(messages),
              modalType: MODAL_TYPES.ERROR,
            }),
          );
        }
        yield put(globalActions.hideSpinner());
      } else {
        const { id, parentId, rootKey } = { ...queueType };
        const queueDetails = queueType.lpSection
          ? { groupKey: id, rootKey, pageIndex, pageSize }
          : { queueId: id, groupKey: parentId, rootKey, pageIndex, pageSize };
        yield put(OrganizationActions.getOrganizationQueueRecords({ queueDetails }));
        yield put(OrganizationActions.setSelectedQueueType(queueType));
      }
    } else {
      yield put(globalActions.hideSpinner());
      yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
    }
  } catch (err) {
    yield put(OrganizationActions.setError(err));
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
  }
}

export function* getCurrentAssociatedPeopleAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield organizationApi.getCurrentAssociatedPeople(payload);
    const { state, data } = response.data;
    if (state) {
      yield put(OrganizationActions.getCurrentAssociatedPeopleSuccess(data));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
  }
}

export function* getOngoingAssociatedTrialAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield organizationApi.getOngoingAssociatedTrial(payload);
    const { state, data } = response.data;

    if (state) {
      yield put(OrganizationActions.getOngoingAssociatedTrialSuccess(data));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
  }
}

export function* getAllAssociatedTrialAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield organizationApi.getAllAssociatedTrial(payload);
    const { state, data } = response.data;
    if (state) {
      yield put(OrganizationActions.getAllAssociatedTrialSuccess(data));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.ORGANIZATION_ERROR));
  }
}

export function* getOrgProximityDataAsync({ payload: { ingestedId, duplicateIds } }) {
  try {
    yield put(globalActions.showSpinner());
    const [ingestedDetails, duplicateDetails] = yield all([
      organizationApi.getIngestedData(ingestedId),
      organizationApi.fetchOrganizationFeature(duplicateIds[0]),
    ]);
    if (ingestedDetails && duplicateDetails) {
      const { data: ingestedData, state: ingestedState } = ingestedDetails.data;
      const { data: duplicatesData, state: duplicatesState } = duplicateDetails.data;
      if (ingestedState && duplicatesState) {
        yield put(
          OrganizationActions.getOrgProximityDataSuccess({
            ingestedRecordData: ingestedData,
            possibleDuplicatesData: [{ ...duplicatesData }],
          }),
        );
      } else {
        yield put(
          globalActions.showModal({
            isOpen: true,
            message: 'Something went wrong',
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Something went wrong',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
    yield put(globalActions.hideSpinner());
  }
}
export function* searchOrganizationNoteTypeAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield organizationApi.searchOrganizationNoteType(payload);
    const { state, data } = response.data;
    if (state) {
      yield put(OrganizationActions.searchOrganizationNoteTypeSuccess({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(OrganizationActions.setError(err));
  }
}

// TODO: Code Optimization
export function* getOrgCompareDataAsync({ payload: { selectedIds, isIngestedId } }) {
  try {
    yield put(globalActions.showSpinner());
    const selectedQueueAPI =
      isIngestedId === true
        ? organizationApi.getIngestedData
        : organizationApi.fetchOrganizationFeature;
    const [idOneDetails, idTwoDetails, idThreeDetails, idFourDetails] = yield all([
      selectedQueueAPI(selectedIds[0]),
      selectedQueueAPI(selectedIds[1]),
      selectedIds[2] ? selectedQueueAPI(selectedIds[2]) : '',
      selectedIds[3] ? selectedQueueAPI(selectedIds[3]) : '',
    ]);
    if (idOneDetails && idTwoDetails && idThreeDetails && idFourDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      const { data: idThreeData, state: idThreeState } = idThreeDetails.data;
      const { data: idFourData, state: idFourState } = idFourDetails.data;
      if (idOneState && idTwoState && idThreeState && idFourState) {
        yield put(
          OrganizationActions.getOrgCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
            compareIdThree: idThreeData,
            compareIdFour: idFourData,
          }),
        );
      }
    } else if (idOneDetails && idTwoDetails && idThreeDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      const { data: idThreeData, state: idThreeState } = idThreeDetails.data;
      if (idOneState && idTwoState && idThreeState) {
        yield put(
          OrganizationActions.getOrgCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
            compareIdThree: idThreeData,
          }),
        );
      }
    } else if (idOneDetails && idTwoDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      if (idOneState && idTwoState) {
        yield put(
          OrganizationActions.getOrgCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
          }),
        );
      }
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Something went wrong',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
    yield put(globalActions.hideSpinner());
  }
}

export const watchers = [
  function* watchGetOrganizationListAsync() {
    yield takeEvery(OrganizationActions.getOrganizationList, getOrganizationListAsync);
  },
  function* watchDeleteOrganizationAsync() {
    yield takeEvery(OrganizationActions.deleteOrganization, deleteOrganizationAsync);
  },
  function* watchMultipleDeleteOrganizationAsync() {
    yield takeEvery(
      OrganizationActions.deleteMultipleOrganization,
      deleteMultipleOrganizationAsync,
    );
  },
  function* watchGetOrganizationMaterAsync() {
    yield takeEvery(OrganizationActions.getMasterData, getMasterDataAsync);
  },
  function* watchPostOrganizationDataAsync() {
    yield takeEvery(OrganizationActions.postOrganization, postOrganizationAsync);
  },
  function* watchGetOrganizationStampingDataAsync() {
    yield takeEvery(
      OrganizationActions.getOrganizationStampingDetails,
      getOrganizationStampingDetailsAsync,
    );
  },
  function* watchGetOrganizationDetailsync() {
    yield takeEvery(OrganizationActions.fetchOrganizationFeatures, fetchOrganizationFeaturesAsync);
  },
  function* watchGetOrganizationTreesAsync() {
    yield takeEvery(OrganizationActions.getOrganizationTree, getOrganizationTreeAsync);
  },
  function* watchGetOrganizationAddressvalidationAsync() {
    yield takeEvery(OrganizationActions.getAddressValidation, getAddressValidationAsync);
  },
  function* watchGetQueueTypes() {
    yield takeEvery(OrganizationActions.getQueueTypes, getQueueTypesAsync);
  },
  function* watchGetOrgQueueRecords() {
    yield takeEvery(
      OrganizationActions.getOrganizationQueueRecords,
      getOrganizationQueueRecordsAsync,
    );
  },
  function* watchGetOrgQueueRecords() {
    yield takeEvery(
      OrganizationActions.getTaskOrganizationQueueRecords,
      getOrganizationQueueRecordsAsync,
    );
  },
  function* watchReleaseOrganizationUserLockAsync() {
    yield takeEvery(
      OrganizationActions.releaseOrganizationUserLock,
      releaseOrganizationUserLockAsync,
    );
  },
  function* watchUpdateOrganizationLockDetailsAsync() {
    yield takeEvery(
      OrganizationActions.updateOrganizationLockDetails,
      updateOrganizationLockDetails,
    );
  },
  function* watchGetQueueDashboardData() {
    yield takeEvery(OrganizationActions.getQueueDashboardData, getQueueDashboardDataAsync);
  },
  function* watchPostExitQueueRecords() {
    yield takeEvery(OrganizationActions.exitQueue, postExitQueueAysnc);
  },
  function* watchGetCurrentAssociatedPeopleAsync() {
    yield takeEvery(
      OrganizationActions.getCurrentAssociatedPeople,
      getCurrentAssociatedPeopleAsync,
    );
  },
  function* watchGetOngoingAssociatedTrialAsync() {
    yield takeEvery(OrganizationActions.getOngoingAssociatedTrial, getOngoingAssociatedTrialAsync);
  },
  function* watchGetAllAssociatedTrialAsync() {
    yield takeEvery(OrganizationActions.getAllAssociatedTrial, getAllAssociatedTrialAsync);
  },
  function* watchGetOrgProximityDataAsync() {
    yield takeEvery(OrganizationActions.getOrgProximityData, getOrgProximityDataAsync);
  },
  function* watchsearchOrganizationNoteTypeAsync() {
    yield takeEvery(
      OrganizationActions.searchOrganizationNoteType,
      searchOrganizationNoteTypeAsync,
    );
  },
  function* watchGetProximityDataAsync() {
    yield takeEvery(OrganizationActions.getQueueTypesAndRecords, getQueueTypesAndRecordsAsync);
  },
  function* watchGetOrgCompareDataAsync() {
    yield takeEvery(OrganizationActions.getOrgCompareData, getOrgCompareDataAsync);
  },
];
