import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';
import {
  setError,
  showModal,
  hideModal,
  showSpinner,
  hideSpinner,
  showAlert,
  hideAlert,
  setSelectedFeature,
  showAdvancedSearchExpansionPanel,
  hideAdvancedSearchExpansionPanel,
  setUserGroupsData,
  setAncillaryRequestsData,
  getAuditData,
  getAuditDataSuccess,
  resetAuditData,
  setHistoricalAuditData,
  multiplePublishChangeFieldsSuccess,
} from '../actions/globalActions';
import { formatAncillaryRequestsData, convertAuditsToGridData } from '../helpers/global';

const initialState = InitialState.globalData;

const globalReducer = createReducer(initialState, {
  [setSelectedFeature]: (state, { payload }) => {
    state.selectedFeature = payload;
  },
  [setError]: (state, { payload }) => {
    state.error = payload;
  },
  [showModal]: (state, { payload }) => {
    state.modalState = payload;
  },
  [hideModal]: (state, { payload }) => {
    state.modalState = payload;
  },
  [showSpinner]: state => {
    state.showSpinner = true;
  },
  [hideSpinner]: state => {
    state.showSpinner = false;
  },
  [showAlert]: (state, { payload }) => {
    state.alertStatus = payload;
  },
  [hideAlert]: (state, { payload }) => {
    state.alertStatus = payload;
  },
  [showAdvancedSearchExpansionPanel]: state => {
    state.showAdvancedSearchExpansionPanel = true;
  },
  [hideAdvancedSearchExpansionPanel]: state => {
    state.showAdvancedSearchExpansionPanel = false;
  },
  [setUserGroupsData]: (state, { payload: { data, field } }) => {
    state.crossAlertUserGroupsData[field] = data;
  },
  [setAncillaryRequestsData]: (state, { payload }) => {
    const data = formatAncillaryRequestsData(payload);
    state.ancillaryRequests = data;
  },
  [getAuditData]: (state, { payload: { key } }) => {
    state.auditTrial[key] = {
      ...state.auditTrial[key],
      results: [],
    };
  },
  [getAuditDataSuccess]: (state, { payload: { key, data, historicalData } }) => {
    state.auditTrial[key] = {
      ...data,
      results: convertAuditsToGridData(data.results),
      historicalData: state.auditTrial[key].historicalData || historicalData,
    };
  },
  [resetAuditData]: state => {
    const { auditTrial } = initialState;
    state.auditTrial = auditTrial;
  },
  [setHistoricalAuditData]: (state, { payload: { key, historicalData } }) => {
    state.auditTrial[key] = {
      ...state.auditTrial[key],
      historicalData: state.auditTrial[key].historicalData || historicalData,
    };
  },
  [multiplePublishChangeFieldsSuccess]: (state, { payload }) => {
    state.changeFields = payload;
  },
});
export default globalReducer;
