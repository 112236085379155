import React from 'react'; //,{ useEffect }
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import './index.scss';

const InputAddSubIcons = ({
  addDelEntryRow,
  index,
  size,
  showMinus,
  showPlus,
  callToAddInputs,
  removeTextInput,
  name,
  className,
  disabled,
}) => {
  const handleDecrease = () => {
    addDelEntryRow(index, 'Del');
    if (name) {
      removeTextInput(name);
    }
  };

  const handleIncrease = () => {
    addDelEntryRow(index, 'Add');
    callToAddInputs();
  };
  const style1 = showMinus ? 'block' : 'none';
  const style2 = showPlus ? 'block' : 'none';
  return (
    <div className={className}>
      <div className="iconNumber" style={{ marginTop: '25px' }}>
        <button
          disabled={disabled}
          className="button-none"
          onClick={() => handleDecrease()}
          type="button"
        >
          <span style={{ display: style1 }}>
            <FontAwesomeIcon icon={faMinusCircle} size={size} />
          </span>
        </button>
        <button
          disabled={disabled}
          className="button-none"
          onClick={() => {
            handleIncrease();
          }}
          type="button"
        >
          <span style={{ display: style2 }}>
            <FontAwesomeIcon icon={faPlusCircle} size={size} />
          </span>
        </button>
      </div>
    </div>
  );
};

InputAddSubIcons.propTypes = {
  addDelEntryRow: PropTypes.func,
  index: PropTypes.number,
  size: PropTypes.string,
  showPlus: PropTypes.bool,
  showMinus: PropTypes.bool,
  callToAddInputs: PropTypes.func,
  removeTextInput: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
};

InputAddSubIcons.defaultProps = {
  addDelEntryRow: () => {},
  index: '',
  size: '16',
  showPlus: true,
  showMinus: true,
  callToAddInputs: () => {},
  removeTextInput: () => {},
  name: '',
  className: '',
};

export default InputAddSubIcons;
