import {
  checkIfStringIsEmpty,
  validateUrl,
  convertObjectForApi,
  checkIngestionActionForMandatoryFields,
  isValidDate,
  checkIfObjectIsEmpty,
  checkIfArrayIsEmpty,
  convertObjectForSelectDropdown,
  getFormattedUrlStatus,
} from '../../../utils/generic/helper';
import {
  getFormattedDataForDropdowns,
  convertDataForTrialSummaryDropdowns,
} from '../../../utils/clinicalTrials/helper';
import {
  trialSummaryWarningMessages,
  trialStatusConstants,
} from '../../../utils/clinicalTrials/constants';
import { drugsUrl, getTypeaheadSearchUrls } from '../../../api';
import { orderBy } from 'lodash';

export const isTherapeuticDataExists = (data, { therapeuticArea, diseaseType }) => {
  let isDataExists = false;
  data.forEach(d => {
    if (d.therapeuticArea.id === therapeuticArea.value && d.diseaseType.id === diseaseType.value) {
      isDataExists = true;
    }
  });
  return isDataExists;
};

export const isSponsorCollaboratorDataExists = (data, { sponsorCollaborator }) => {
  let isDataExists = false;
  data.forEach(d => {
    if (d.sponsor.id === sponsorCollaborator.value) {
      isDataExists = true;
    }
  });
  return isDataExists;
};

export const dropdownConfig = [
  {
    key: 'phase',
    label: 'TRIAL PHASE',
    placeholder: 'Select Trial Phase',
    isMandatory: true,
    searchKey: '',
    apiKey: '',
  },
  {
    key: 'globalStatus',
    label: 'STATUS',
    placeholder: 'Select Status',
    isMandatory: true,
    searchKey: '',
    apiKey: '',
  },
  {
    key: 'primaryDrugs',
    label: 'PRIMARY DRUG',
    placeholder: 'Search Primary Drug',
    isMandatory: true,
    searchKey: 'descriptiveName',
    apiKey: 'primaryDrug',
  },
  {
    key: 'secondaryDrugs',
    label: 'SECONDARY DRUG',
    placeholder: 'Search Secondary Drug',
    isMandatory: false,
    searchKey: 'descriptiveName',
    apiKey: 'secondaryDrug',
  },
  // {
  //   key: 'sponsors',
  //   label: 'SPONSOR/COLLABORATOR',
  //   placeholder: 'Search Sponsor/collaborator',
  //   isMandatory: true,
  //   searchKey: 'desc',
  //   apiKey: 'sponsor',
  // },
  // {
  //     key: 'collaborators',
  //     label: 'COLLABORATOR',
  //     placeholder: 'Search Collaborator',
  //     isMandatory: false,
  //     searchKey: 'type',
  //     apiKey: 'collaborator',
  // },
  {
    key: 'associatedCros',
    label: 'ASSOCIATED CRO',
    placeholder: 'Search Associated CRO',
    isMandatory: false,
    searchKey: 'desc',
    apiKey: 'associatedCRO',
  },
  {
    key: 'tags',
    label: 'TRIAL TAGS',
    placeholder: 'Search Trial Tags',
    isMandatory: false,
    searchKey: 'desc',
    apiKey: 'tag',
  },
  {
    key: 'locations',
    label: 'LOCATION',
    placeholder: 'Search Location',
    isMandatory: false,
    searchKey: 'name',
    apiKey: 'location',
  },
];

export const updateTemplateUrls = (data, supportingUrl, currentValue) => {
  return [...data].map(d => {
    let formData = { ...d };
    formData.values = formData.values.map(v => {
      let val = { ...v };
      if (val.fieldName.toLowerCase() === 'url' && v.fieldValue === currentValue) {
        val.fieldValue = supportingUrl || '';
      }
      return val;
    });
    return { ...formData };
  });
};

export const validateTrialSummaryDetails = (
  { name, source, globalStatus, phase, primaryDrugs, sponsors, url },
  nextReviewDate,
) => {
  let errors = {};
  if (checkIfStringIsEmpty(name)) {
    errors = {
      ...errors,
      name: 'Please enter the trial title',
    };
  } else if (name.trim().length > 2047) {
    errors = {
      ...errors,
      name: 'The text entered exceeds 2047 characters',
    };
  }

  if (checkIfObjectIsEmpty(globalStatus)) {
    errors = {
      ...errors,
      globalStatus: 'Please select the status',
    };
  }
  if (checkIfObjectIsEmpty(phase)) {
    errors = {
      ...errors,
      phase: 'Please select the trial phase',
    };
  }

  if (checkIfArrayIsEmpty(primaryDrugs) || checkIngestionActionForMandatoryFields(primaryDrugs)) {
    errors = {
      ...errors,
      primaryDrugs: 'Please select the primary drug',
    };
  }

  // if (checkIfArrayIsEmpty(sponsors) || checkIngestionActionForMandatoryFields(sponsors)) {
  //   errors = {
  //     ...errors,
  //     sponsors: 'Please select the sponsor/collaborator',
  //   };
  // }

  if (!checkIfStringIsEmpty(url)) {
    const urlError = validateUrl(url);
    if (urlError) {
      errors = {
        ...errors,
        url: urlError,
      };
    }
  }

  if (!isValidDate(nextReviewDate)) {
    errors.nextFullReviewDate = 'Please enter a valid date (MMM-dd-yyyy)';
  }

  return errors;
};

export const validateTherapeuticDiseasePatients = therapeuticDiseasePatients => {
  if (
    checkIfArrayIsEmpty(therapeuticDiseasePatients) ||
    checkIngestionActionForMandatoryFields(therapeuticDiseasePatients)
  ) {
    return 'A trial record should have at least one combination of therapeutic area and disease type';
  }
  return '';
};

export const validateSponsorCollaborators = sponsors => {
  if (checkIfArrayIsEmpty(sponsors) || checkIngestionActionForMandatoryFields(sponsors)) {
    return 'A trial record should have at least one combination of sponsor/collaborator and role';
  }
  return '';
};

export const validateTerminatedTrialStatus = (
  trialOutcome,
  terminatedTrials,
  productionStatusID,
) => {
  if (
    !trialOutcome.outcomeStatuses.length &&
    terminatedTrials.current === 8 &&
    (productionStatusID === 1 || productionStatusID === 3 || productionStatusID === 4)
  ) {
    return 'Terminated trial requires a terminated Trial Outcome and Trial Outcome Details - please add';
  } else {
    return '';
  }
};

const checkOutcomeDetailsBasedOnTrialStatus = (outcomeStatuses, requiredStatus) => {
  let isDataExists = false;
  outcomeStatuses.forEach(s => {
    if (
      s.outcomeStatus &&
      s.outcomeStatus.value &&
      s.outcomeStatus.value
        .trim()
        .toLowerCase()
        .includes(requiredStatus)
    ) {
      isDataExists = true;
    }
  });
  return isDataExists;
};
// eslint-disable-next-line complexity
export const trialSummaryWarnings = (
  formRefValues,
  { patientPopulation: { actualAccrual, interimAccrual } },
) => {
  let errorMessage = '';
  if (formRefValues) {
    const { globalStatus } = formRefValues;
    const {
      timing,
      actualAccrualOpenStatus,
      actualAccrualPlannedStatus,
      interimAccrualWarning,
    } = trialSummaryWarningMessages;
    const { planned, open, closed, temporarilyClosed } = trialStatusConstants;
    const globalStatusData =
      globalStatus && globalStatus.label ? globalStatus.label.trim().toLowerCase() : '';

    if ((globalStatusData === open || globalStatusData === planned) && actualAccrual) {
      errorMessage = `${errorMessage} ${
        globalStatusData === open ? actualAccrualOpenStatus : actualAccrualPlannedStatus
      }`;
    }

    if (globalStatusData === planned && interimAccrual) {
      errorMessage = `${errorMessage} ${interimAccrualWarning}`;
    }
  }

  return errorMessage.trim();
};

export const trialSummaryErrors = (
  formRefValues,
  {
    trialTiming: {
      actualStartDate,
      actualPrimaryCompletionDate,
      actualPrimaryEndpointReported,
      actualFullCompletionDate,
      actualEnrollmentCloseDate,
    },
  },
) => {
  let errorMessage = '';
  if (formRefValues) {
    const { globalStatus } = formRefValues;
    const { planned, open, closed, temporarilyClosed } = trialStatusConstants;
    const globalStatusData =
      globalStatus && globalStatus.label ? globalStatus.label.trim().toLowerCase() : '';
    if (
      (globalStatusData === planned && actualStartDate) ||
      ((globalStatusData === planned ||
        globalStatusData === open ||
        globalStatusData === closed ||
        globalStatusData === temporarilyClosed) &&
        (actualPrimaryCompletionDate ||
          actualPrimaryEndpointReported ||
          actualFullCompletionDate)) ||
      ((globalStatusData === planned || globalStatusData === open) && actualEnrollmentCloseDate)
    ) {
      errorMessage =
        'Actual dates conflict with the selected trial status. Please correct the dates in Trial Timing';
    }
  }

  return errorMessage.trim();
};

export const validateTrialSummaryDetailsForSubmit = ({
  sponsors,
  therapeuticDiseasePatients,
  nextFullReviewDate,
  ...values
}) => {
  let errors = {};
  if (validateTherapeuticDiseasePatients(therapeuticDiseasePatients)) {
    errors = { therapeuticDiseasePatientsError: true };
  } else if (validateSponsorCollaborators(sponsors)) {
  } else {
    errors = validateTrialSummaryDetails(values, nextFullReviewDate);
  }
  return errors;
};

export const formSponsorCollaboratorDetails = ({
  id,
  sponsorCollaboratorRole,
  sponsorCollaborator,
}) => {
  return {
    id,
    roleId: {
      id: sponsorCollaboratorRole?.id,
      value: sponsorCollaboratorRole?.label,
    },
    sponsor: {
      id:
        typeof sponsorCollaborator?.value === 'string'
          ? sponsorCollaborator?.id
          : sponsorCollaborator?.value,
      value: sponsorCollaborator?.label,
    },
    sponsorType: {
      ...sponsorCollaborator?.sponsorType,
    },
  };
};

export const formTherapeuticAreaDetails = ({
  id,
  therapeuticArea,
  diseaseType,
  patientSegments,
}) => {
  return {
    id,
    therapeuticArea: convertObjectForApi({
      data: therapeuticArea,
      key: 'value',
      value: 'label',
      apiKey: 'id',
      apiValue: 'value',
    }),
    diseaseType: convertObjectForApi({
      data: diseaseType,
      key: 'value',
      value: 'label',
      apiKey: 'id',
      apiValue: 'value',
    }),
    patientSegments: getFormattedDataForDropdowns('patientSegment', patientSegments),
  };
};

export const showSponsorCollaboratorErrorMessage = ({
  sponsorCollaboratorRole,
  sponsorCollaborator,
}) => {
  let errorMessage = '';
  if (
    checkIfObjectIsEmpty(sponsorCollaboratorRole) &&
    (checkIfObjectIsEmpty(sponsorCollaborator) || !sponsorCollaborator?.label)
  ) {
    errorMessage = 'Please add sponsor/collaborator and role';
  } else if (checkIfObjectIsEmpty(sponsorCollaboratorRole)) {
    errorMessage = 'Please add role';
  } else if (checkIfObjectIsEmpty(sponsorCollaborator) || !sponsorCollaborator?.label) {
    errorMessage = 'Please add sponsor/collaborator';
  }
  return errorMessage;
};

export const showTherapeuticGridErrorMessages = ({ therapeuticArea, diseaseType }) => {
  let errorMessage = '';
  if (checkIfObjectIsEmpty(therapeuticArea) && checkIfObjectIsEmpty(diseaseType)) {
    errorMessage = 'Please add therapeutic area and disease type';
  } else if (checkIfObjectIsEmpty(therapeuticArea)) {
    errorMessage = 'Please add therapeutic area';
  } else if (checkIfObjectIsEmpty(diseaseType)) {
    errorMessage = 'Please add disease type';
  } else if (
    !checkIfObjectIsEmpty(therapeuticArea) &&
    therapeuticArea.label &&
    !checkIfObjectIsEmpty(diseaseType) &&
    diseaseType.label
  ) {
    errorMessage = '';
  }
  return errorMessage;
};

export const mapTrialSummaryFormDataToStore = (
  {
    source,
    protocolIds,
    globalStatus,
    phase,
    primaryDrugs,
    secondaryDrugs,
    sponsors,
    collaborators,
    associatedCros,
    tags,
    locations,
    allLocations,
    url,
    status,
    publishOnFos,
  },
  supportingUrlStatuses,
) => {
  return {
    source: checkIfObjectIsEmpty(source)
      ? null
      : convertObjectForApi({
          data: source,
          key: 'value',
          value: 'label',
          apiKey: 'id',
          apiValue: 'value',
        }),
    protocolIds,
    phase: checkIfObjectIsEmpty(phase)
      ? null
      : convertObjectForApi({
          data: phase,
          key: 'value',
          value: 'label',
          apiKey: 'id',
          apiValue: 'value',
        }),
    globalStatus: checkIfObjectIsEmpty(globalStatus)
      ? null
      : convertObjectForApi({
          data: globalStatus,
          key: 'value',
          value: 'label',
          apiKey: 'id',
          apiValue: 'value',
        }),
    primaryDrugs: getFormattedDataForDropdowns('drug', primaryDrugs),
    secondaryDrugs: getFormattedDataForDropdowns('drug', secondaryDrugs),
    // sponsors: getFormattedDataForDropdowns('sponsor', sponsors),
    collaborators: getFormattedDataForDropdowns('collaborator', collaborators),
    associatedCros: getFormattedDataForDropdowns('associatedCRO', associatedCros),
    tags: getFormattedDataForDropdowns('tag', tags),
    locations: getFormattedDataForDropdowns('location', locations),
    // allLocations: getFormattedDataForDropdowns('allLocation', allLocations),
    primaryUrl: checkIfStringIsEmpty(url)
      ? null
      : {
          url,
          status: supportingUrlStatuses.find(s => s.id === status.value),
          publishOnFos,
        },
  };
};

export const getPrimaryUrlValues = primaryUrlData => {
  const {
    url = '',
    status = {
      id: 1,
      value: 'Active',
    },
    publishOnFos = true,
  } = { ...primaryUrlData };
  return {
    url,
    status: getFormattedUrlStatus(status),
    publishOnFos,
  };
};

export const getInitialValuesForTrialSummaryForm = ({
  name,
  source,
  protocolIds,
  phase,
  globalStatus,
  primaryDrugs,
  secondaryDrugs,
  sponsors,
  collaborators,
  associatedCros,
  tags,
  locations,
  allLocations,
  primaryUrl,
}) => {
  return {
    name,
    source: convertObjectForSelectDropdown(source, 'id', 'value'),
    protocolIds,
    therapeuticArea: null,
    diseaseType: null,
    patientSegments: [],
    globalStatus: convertObjectForSelectDropdown(globalStatus, 'id', 'value'),
    phase: convertObjectForSelectDropdown(phase, 'id', 'value'),
    primaryDrugs: convertDataForTrialSummaryDropdowns(primaryDrugs, 'drug'),
    secondaryDrugs: convertDataForTrialSummaryDropdowns(secondaryDrugs, 'drug'),
    sponsors: null,
    collaborators: convertDataForTrialSummaryDropdowns(collaborators, 'collaborator'),
    associatedCros: convertDataForTrialSummaryDropdowns(associatedCros, 'associatedCRO'),
    tags: convertDataForTrialSummaryDropdowns(tags, 'tag'),
    locations: convertDataForTrialSummaryDropdowns(locations, 'location'),
    allLocations: convertDataForTrialSummaryDropdowns(allLocations, 'allLocation'),
    ...getPrimaryUrlValues(primaryUrl),
  };
};

export const getSearchUrlsForTrialSummaryDropdowns = key => {
  let searchUrl = '';
  switch (key) {
    case 'primaryDrug':
    case 'secondaryDrug':
      // Adding drugNameId as part of data modelling changes
      searchUrl = `${drugsUrl}/api/Drug/DrugPrimaryName?isDrugNameId=true`;
      break;
    case 'location':
      searchUrl = getTypeaheadSearchUrls('sites', 'LocationCountry');
      break;
    default:
      searchUrl = getTypeaheadSearchUrls('trials', key);
      break;
  }
  return searchUrl;
};

// Removing appended text to primary drugs and sponsors dropdown
export const removeAppendedText = (key, data, objectFlag) => {
  if ((key === 'primaryDrugs' || key === 'secondaryDrugs') && !objectFlag) {
    return data?.map(item => {
      if (item?.prodStatus === 5) {
        return {
          ...item,
          label: item?.label.replace(' (Ext.So.)', ''),
        };
      } else {
        return item;
      }
    });
  }
  if (key === 'sponsors') {
    if (objectFlag)
      return {
        ...data,
        label: data?.label?.replace(' (Ext.So.)', ''),
      };
    else
      return data?.map(item => {
        return {
          ...item,
          label: item?.label.replace(' (Ext.So.)', ''),
        };
      });
  } else {
    return data;
  }
};

// Formatting data primary drugs and sponsors dropdown
export const formatDropdownData = (key, data) => {
  if (key === 'primaryDrugs' || key === 'secondaryDrugs') {
    return orderBy(
      data?.map(item => {
        return {
          ...item,
          label: item?.prodStatus === 5 ? `${item?.label} (Ext.So.)` : item?.label,
        };
      }),
      ({ label }) => label?.toLowerCase() || '',
      ['asc'],
    );
  } else if (key === 'sponsors') {
    return orderBy(
      data?.map(item => {
        if (item?.sponsor) {
          return {
            ...item,
            sponsor: {
              ...item.sponsor,
              value: `${item.sponsor.value}`,
            },
            label: `${item.sponsor.value}${item.sponsorType.id === 32 ? ' (Ext.So.)' : ''}`,
          };
        } else {
          return {
            ...item,
            label: `${item.label}${item.sponsorType.id === 32 ? ' (Ext.So.)' : ''}`,
          };
        }
      }),
      ({ label }) => label.toLowerCase() || '',
      ['asc'],
    );
  } else {
    return data;
  }
};
