import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual, sortBy, omit } from 'lodash';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';

import { FormBuilder, FeatureFooter } from '../../../../framework';
import { InputLabel } from '../../../../framework/inputs';
import { showModal } from '../../../../store/actions/globalActions';
import {
  updateCompanyDetails,
  saveCompanyDetails,
  resetCompanyData,
  updateCompanyIngestedActions,
} from '../../../../store/actions/company';
import { getAdditionalInfoRequest } from '../../../../store/helpers/company';
import { MODAL_MESSAGES, childFormChangeObject } from '../../../../utils/generic/constants';
import {
  convertDataForSelectDropdown,
  convertObjectForSelectDropdown,
  convertObjectForApi,
  updateIngestedDataBasedOnFieldType,
  checkForIngestionAction,
  validateIngestionAction,
  isDataEqual,
  checkUserIngestionAction,
} from '../../../../utils/generic/helper';
import SupportingUrls from '../../../../generic/features/urls';
import ClearConfirmation from '../../../../generic/modals/clearConfirmation';
import AdditionalInfoAcquiredCompany from './additionalInfoAcquiredCompany';
import AdditionalInfoFinancialEntries from './additionalInfoFinancialEntries';
import { initialValues, layoutSchema, formSchema } from './config';
import './index.scss';

const AdditionalInfo = forwardRef(({ data, permissions, permissions: { disableEdit } }, ref) => {
  const company = useSelector(state => state.company, shallowEqual);
  const {
    original: {
      additionalInfo: originalAdditionalInfo,
      basicAndContactInfo: { ownership },
    },
    current: {
      id,
      ingestedId,
      additionalInfo,
      additionalInfo: { stockSymbol, stockExchange, supportingUrls },
    },
    ingested: {
      additionalInfo: { data: ingestedData, fieldActions: ingestedActions },
    },
    recordStartDateTime,
    masterData: { stockExchangeTypes, relatedCompanyTypes, supportingUrlStatuses },
  } = company;
  const additionalInfoRef = useRef(additionalInfo);
  const stockFormRef = useRef(null);
  const dispatch = useDispatch();
  const [clearModal, setClearModal] = useState(false);

  const supportingUrlsFormRef = useRef(childFormChangeObject);
  const acquiredCompanyFormRef = useRef(childFormChangeObject);
  const financialEntriesFormRef = useRef(childFormChangeObject);

  const getStockFormValues = () => {
    const { values = {} } = { ...stockFormRef.current };
    let formData = { ...values };
    if (isEmpty(formData)) {
      formData = { stockSymbol: '', stockExchange: null };
    } else {
      formData = {
        ...formData,
        stockExchange: isEmpty(formData.stockExchange)
          ? null
          : convertObjectForApi({
            data: formData.stockExchange,
            key: 'value',
            value: 'label',
            apiKey: 'id',
            apiValue: 'value',
          }),
      };
    }
    return formData;
  };

  const getAdditionalInfoDetails = () => {
    return additionalInfoRef.current
      ? {
        ...additionalInfoRef.current,
        ...getStockFormValues(),
      }
      : {};
  };

  useEffect(() => {
    return () => {
      dispatch(
        updateCompanyDetails({
          key: 'additionalInfo',
          data: getAdditionalInfoDetails(),
        }),
      );
    };
  }, []);

  useEffect(() => {
    additionalInfoRef.current = { ...additionalInfo };
  }, [additionalInfo]);

  useImperativeHandle(ref, () => ({
    isChanged,
    validate: () =>
      !data.hasIngestedData ||
      validateIngestionAction({
        ingestedActions,
        data: { ...additionalInfoRef.current, ...getStockFormValues() },
        type: 'company',
      }).isValid,
  }));

  const isChanged = () => {
    return data.hasIngestedData
      ? checkUserIngestionAction(
        { ...additionalInfoRef.current, ...getStockFormValues() },
        ingestedActions,
      )
      : supportingUrlsFormRef.current.isChanged() ||
      acquiredCompanyFormRef.current.isChanged() ||
      financialEntriesFormRef.current.isChanged() ||
      !isDataEqual(
        { ...additionalInfoRef.current, ...getStockFormValues() },
        originalAdditionalInfo,
      );
  };

  const isStockOptionsDisabled = () => {
    let isDisabled = true;
    if (!isEmpty(ownership) && ownership.value && ownership.value.toLowerCase() === 'public') {
      isDisabled = false;
    }
    return isDisabled;
  };

  const handleIngestionActions = ({ option, key, type }) => {
    if (stockFormRef.current) {
      let updatedData = null;
      updatedData = updateIngestedDataBasedOnFieldType({
        type,
        original: originalAdditionalInfo[key],
        ingestionAction: option,
        current: additionalInfo[key],
        ingested: ingestedData[key],
      });
      dispatch(
        updateCompanyIngestedActions({
          parentKey: 'additionalInfo',
          childKey: key,
          ingestionAction: option,
        }),
      );
      stockFormRef.current.setFieldValue(key, updatedData);
    }
  };

  const getIngestionPropsForStockFormFields = ({ key, type }) => {
    if (!isStockOptionsDisabled()) {
      return {
        ingestionAction: ingestedActions[key],
        ingestedData: ingestedData[key],
        handleIngestionActions: option => handleIngestionActions({ option, key, type }),
        isIngestionActionDisabled: disableEdit,
      };
    }
    return null;
  };

  let updatedFormSchema = {
    ...formSchema,
    stockSymbol: {
      ...formSchema.stockSymbol,
      props: {
        ...formSchema.stockSymbol.props,
        ...getIngestionPropsForStockFormFields({ key: 'stockSymbol', type: 'text' }),
        disabled:
          isStockOptionsDisabled() || checkForIngestionAction(ingestedActions['stockSymbol']),
      },
    },
    stockExchange: {
      ...formSchema.stockExchange,
      options: convertDataForSelectDropdown([...stockExchangeTypes], 'id', 'value'),
      props: {
        ...formSchema.stockExchange.props,
        ...getIngestionPropsForStockFormFields({ key: 'stockExchange', type: 'object' }),
        isDisabled:
          isStockOptionsDisabled() || checkForIngestionAction(ingestedActions['stockExchange']),
      },
    }
  };

  let updatedInitialValues = {
    ...initialValues,
    stockSymbol,
    stockExchange: isEmpty(stockExchange)
      ? null
      : convertObjectForSelectDropdown(stockExchange, 'id', 'value')
  };

  const handleSave = tabData => {
    let updatedIngestedActions = { ...ingestedActions };
    if (isStockOptionsDisabled()) {
      updatedIngestedActions = { ...omit(ingestedActions, ['stockSymbol', 'stockExchange']) };
    }
    const {
      isValid,
      data: additionalInfoDetails,
      ingestedContentResults,
    } = validateIngestionAction({
      ingestedActions: updatedIngestedActions,
      data: getAdditionalInfoDetails(),
      type: 'company',
    });
    if (isValid) {
      const request = getAdditionalInfoRequest({
        id,
        ingestedId,
        relatedCompanyTypes,
        ...additionalInfoDetails,
        ingestedContentResults,
        recordStartDateTime,
      });
      dispatch(saveCompanyDetails({ request, tabData, key: 'additionalInfo' }));
    } else {
      dispatch(showModal(MODAL_MESSAGES.INGESTION_ERROR));
    }
  };

  const proceedToClear = status => {
    if (status) {
      stockFormRef.current.resetForm();

      supportingUrlsFormRef.current.reset();
      acquiredCompanyFormRef.current.reset();
      financialEntriesFormRef.current.reset();
      dispatch(resetCompanyData('additionalInfo'));
    } else {
      setClearModal(false);
    }
  };

  const onChangeUrls = result => {
    const currentAdditionalInfo = { ...additionalInfoRef.current, ...getStockFormValues() };
    currentAdditionalInfo['supportingUrls'] = [...result];
    dispatch(
      updateCompanyDetails({
        key: 'additionalInfo',
        data: currentAdditionalInfo,
      }),
    );
  };

  return (
    <div className="company-additional-info">
      <Grid item xs={12} className="separate-line">
        <FeatureFooter
          handleSaveAndNext={() => handleSave(data)}
          handleSave={() => handleSave(null)}
          handleClear={() => setClearModal(true)}
          data={data}
          disabled={disableEdit}
        />
        <Formik enableReinitialize initialValues={updatedInitialValues}>
          {formikProps => {
            stockFormRef.current = formikProps;
            return (
              <>
                <FormBuilder
                  formikProps={formikProps}
                  layoutSchema={layoutSchema}
                  formSchema={updatedFormSchema}
                  onSubmitValidationError={() => { }}
                  disableControls={disableEdit}
                />
              </>
            );
          }}
        </Formik>
        {/* Commented below line as per JEDI-9150 */}
        {/* <AdditionalInfoAcquiredCompany permissions={permissions} ref={acquiredCompanyFormRef} /> */}
        <AdditionalInfoFinancialEntries permissions={permissions} ref={financialEntriesFormRef} />
        <div className="company-urls-label">
          <InputLabel labelFor="supportingUrls" text="SUPPORTING URLs" size="16" fontStyle="bold" />
        </div>
        <SupportingUrls
          data={supportingUrls}
          statuses={supportingUrlStatuses}
          updateData={onChangeUrls}
          disableEdit={disableEdit}
          id={id}
          ref={supportingUrlsFormRef}
        />
      </Grid>
      {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
    </div>
  );
});

AdditionalInfo.propTypes = {
  data: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default AdditionalInfo;
