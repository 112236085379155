import uniq from 'lodash/uniq';
import { onSearch } from '../generic/helper';
import { getTypeaheadSearchUrls, drugs as drugsApi } from '../../api';

const stringCheck = str => {
  if (str === '') {
    return 'Unknown';
  } else {
    return str;
  }
};

export const generateDrugSummaryInfo = featuresEdited => {
  // const company = 'originator';
  const { landscapeStatuses, activity, names, key, masterDrug, id, childDrugIds } = featuresEdited;
  let primaryFilter = names.length > 0 ? names.filter(i => i.isPrimary === true) : [];
  let primaryName = '';
  primaryName = primaryFilter.length > 0 ? primaryFilter[0].descriptiveName : '';
  let companies = [];
  let disease = [];
  let mechanismOfActions =
    activity.mechanismOfActions && activity.mechanismOfActions.length > 0
      ? [...activity.mechanismOfActions]
      : [];
  landscapeStatuses.length > 0 &&
    landscapeStatuses.map(item => {
      item.companyType.id === 1 && companies.push(item.company);
      item.disease && item.disease.indication && disease.push(item.disease.indication);
      return null;
    });
  let companiesString = '';
  let diseaseString = '';
  let moaString = '';
  companies.length > 0 &&
    uniq(companies).map((c, i) => {
      companiesString = companiesString.concat(`${c.name}${i < companies.length - 1 ? ', ' : ''}`);
      return null;
    });
  disease.length > 0 &&
    uniq(disease).map((d, i) => {
      diseaseString = diseaseString.concat(`${d}${i < uniq(disease).length - 1 ? ', ' : ''}`);
      return null;
    });
  mechanismOfActions.length > 0 &&
    mechanismOfActions.map((m, i) => {
      moaString = moaString.concat(
        `${m.mechanismOfAction.description}${i < mechanismOfActions.length - 1 ? ', ' : ''}`,
      );
      return null;
    });
  let drugSummaryText = `${stringCheck(primaryName)} is a ${stringCheck(
    moaString,
  )} under development by ${stringCheck(companiesString)} for the treatment of ${stringCheck(
    diseaseString,
  )} `;
  const masterDrugName = masterDrug ? masterDrug.primaryName.descriptiveName : '';

  return { drugSummaryText, primaryName, key, masterDrugName, id, childDrugIds };
};

export const radioParser = (data, parseDisplay, selectFlag) => {
  const parsedData = data.map(datum => {
    return { ...datum, display: datum[parseDisplay], [selectFlag]: false };
  });
  return parsedData;
};

export const radioDeparser = (obj, selectFlag) => {
  const deParsed = { ...obj };
  delete deParsed.display;
  delete deParsed[selectFlag];
  return deParsed;
};

export const generateNavbarConfig = drugData => {
  const drugSummaryText = drugData && drugData.summary && drugData.summary.drugSummary;
  const druSummaryInfo = generateDrugSummaryInfo(drugData);
  const navBarConfig = [
    {
      id: 2,
      name: 'DRUG KEY',
      value: druSummaryInfo.key,
    },
    {
      id: 3,
      name: 'DRUG ID',
      value: drugData.masterDrug
        ? druSummaryInfo.id
        : druSummaryInfo.childDrugIds != null
        ? druSummaryInfo.id + ', ' + druSummaryInfo.childDrugIds
        : druSummaryInfo.id,
    },
    {
      id: 1,
      name: drugData.masterDrug ? 'CHILD DRUG NAME' : 'DRUG NAME',
      value: druSummaryInfo.primaryName,
    },
  ];
  if (drugData.masterDrug) {
    navBarConfig.push({
      id: 4,
      name: 'MASTER DRUG NAME',
      value: druSummaryInfo.masterDrugName,
    });
  }
  return navBarConfig;
};

export const customSelectParser = (data, parserLabel, isSelectFlag) => {
  if (data && data.length > 0) {
    const parsedData = data.map(datum => {
      return {
        ...datum,
        label: datum[parserLabel],
        value: datum[parserLabel],
        isSelected: isSelectFlag,
      };
    });
    return parsedData;
  }
  return [];
};

export const customSelectDeParser = data => {
  if (data && data.length > 0) {
    const deParsedData = [...data].map(datum => {
      delete datum.label;
      delete datum.value;
      delete datum.isSelected;
      return datum;
    });
    return deParsedData;
  }
  return [];
};

export const inputSelectValueParser = (obj, parserLabel, isSelectFlag) => {
  if (obj) {
    const datum = {
      ...obj,
      label: obj[parserLabel],
      value: obj[parserLabel],
      isSelected: isSelectFlag,
    };
    return datum;
  }
  return obj;
};

export const inputSelectValueDeParser = obj => {
  const datum = { ...obj };
  delete datum.label;
  delete datum.value;
  delete datum.isSelected;
  return datum;
};

export const validateDrugGlobalStatus = (globalStatus, landscapeStatusValidation) => {
  let isInValid = false;
  if (
    globalStatus &&
    globalStatus.indicationRanking &&
    landscapeStatusValidation &&
    landscapeStatusValidation.highestCurrentDevStatus &&
    landscapeStatusValidation.highestCurrentDevStatus.id > 0
  ) {
    if (
      landscapeStatusValidation.highestCurrentDevStatus.indicationRanking !==
      globalStatus.indicationRanking
    ) {
      isInValid = true;
    }
  }
  return isInValid;
};

const findTopParent = (filteredArr, id) => {
  let account = filteredArr.find(acc => acc.id === id);
  if (account.parentid !== null) {
    account = findTopParent(filteredArr, account.parentid);
  }
  return account;
};

export const searchDrugFieldTypeAheads = async (val, key) => {
  let filteredData = [];
  let labelKey = '';
  let urlKey = '';
  switch (key) {
    case 'disease':
      urlKey = getTypeaheadSearchUrls('drugs', 'Indication');
      labelKey = 'indication';
      break;
    case 'immunoconjugatePayload':
      urlKey = getTypeaheadSearchUrls('drugs', 'ImmunoconjugatePayload');
      labelKey = 'payload';
      break;
    case 'company':
      urlKey = getTypeaheadSearchUrls('sites', 'Company');
      labelKey = 'name';
      break;
    case 'country':
      urlKey = getTypeaheadSearchUrls('drugs', 'Country');
      labelKey = 'countryName';
      break;
    case 'mechanismOfActions':
      urlKey = getTypeaheadSearchUrls('drugs', 'MechanismOfAction');
      labelKey = 'description';
      break;
    case 'drugDrugTypeClassifications':
      urlKey = getTypeaheadSearchUrls('drugs', 'DrugTypeClassification');
      labelKey = 'description';
      break;
    case 'biologicalTargets':
      urlKey = getTypeaheadSearchUrls('drugs', 'Target');
      labelKey = 'target';
      break;
    case 'manualTargetFamilies':
      urlKey = getTypeaheadSearchUrls('drugs', 'TargetFamily');
      labelKey = 'targetFamily';
      break;
    case 'therapeuticClass':
    case 'therapeuticClassName':
      urlKey = getTypeaheadSearchUrls('drugs', 'TherapeuticClass');
      labelKey = 'description';
      break;
    default:
      break;
  }
  filteredData = await onSearch({
    searchedData: val,
    url: urlKey,
  });
  return filteredData
    ? [
        ...filteredData.map((data, index, array) => {
          let updatedObj = {
            ...data,
            label: data[labelKey],
            value: data.id,
          };
          if (key === 'company') {
            updatedObj = {
              ...updatedObj,
              parentName: updatedObj.parentCompany ? updatedObj.parentCompany.value : '',
            };
          }
          if (key === 'mechanismOfActions' || key === 'drugType') {
            updatedObj = {
              ...updatedObj,
              hasChild: array.length !== index + 1 && array[index + 1].level > 0,
              rootParentId: findTopParent(array, data.id),
            };
          }
          return updatedObj;
        }),
      ]
    : null;
};

export const validateDPLDataInsert = async data => {
  const response = await drugsApi.validateDPLAddition(data);
  return response;
};

export const addMasterCopyFlag = data => {
  return data?.map(item => ({ ...item, isMasterCopy: true }));
};
