import React from 'react';
import './index.scss';
import DataGrid, {
  Column,
  Editing,
  Button,
  Toolbar,
  Item,
  Scrolling,
  Paging,
  Lookup,
  Popup,
} from 'devextreme-react/data-grid';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Guid from 'devextreme/core/guid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useState } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import { searchDrugFieldTypeAheads } from '../../utils/drugs/helper';
import { InputCheckbox } from '../inputs';

const DNADevExtremeGroupedDataGrid = ({
  columns,
  dataSource,
  orderedGroupConfig,
  disableAddKeys,
  getCustomDataSource,
  saveHandler,
  calculateDisplayValue,
  calculateDisplayValueForGroupedData,
  setGridGroupedKey,
  addClickHandler,
  editClickHandler,
  deleteClickHandler,
  disableDeleteKeys,
  handleDeleteCheck,
  colsToDelete = {},
}) => {
  // const [changes, setChanges] = useState([]);
  // const [editRowKey, setEditRowKey] = useState(null);
  const dispatch = useDispatch();

  const onAddButtonClick = (data, value) => {
    // dispatch(setGridGroupedKey(value));
    setGridGroupedKey(value);
    // const key = new Guid().toString();
    addClickHandler();
    // setChanges([
    //   {
    //     key,
    //     type: 'insert',
    //     insertAfterKey: data,
    //   },
    // ]);
    // setEditRowKey(key);
  };

  const GroupCell = opt => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{orderedGroupConfig[opt.value]}</div>
        {!disableAddKeys.includes(opt.value) && (
          <div>
            <FontAwesomeIcon
              icon={faPlus}
              onClick={() => {
                onAddButtonClick(opt?.row?.key, orderedGroupConfig[opt.value]);
              }}
              style={{ cursor: 'pointer', color: 'blue' }}
            />
          </div>
        )}
      </div>
    );
  };

  const onSaving = e => {
    saveHandler(e);
  };

  const renderTitleHeader = data => {
    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        <>{data.column.caption}</>
        <div onClick={() => handleDeleteCheck(data.column.caption)}>
          <InputCheckbox checked={colsToDelete[data.column.caption]} />
        </div>
      </div>
    );
  };

  const allowDeleting = e => {
    if (e?.row?.data?.disableControls) return false;
    return !disableDeleteKeys.includes(e?.row?.data?.keyForOrder);
  };

  const allowEditing = e => {
    if (e?.row?.data?.disableControls) return false;
    return true;
  };

  // const onEditorPreparing = e => {
  //   if (e.parentType === 'dataRow' && e.row?.data?.type) {
  //     if (disableEditGroups.includes(e.row?.data?.type)) e.editorOptions.disabled = true;
  //     else if (e.row.data.type !== gridGroupKey) dispatch(setGridGroupedKey(e.row.data.type));
  //   }
  // };

  return (
    <>
      <DataGrid
        id="gridContainer"
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        remoteOperations={true}
        className="list-custom-style"
        allowColumnResizing={true}
        columnResizingMode="widget"
        onSaving={onSaving}
        sorting={'none'}
        // onCellPrepared={onCellPrepared}
        // onEditorPreparing={onEditorPreparing}
        // onRowInserted={onRowInserted}
      >
        <Paging enabled={false} />
        <Scrolling />
        <Editing
          mode="popup"
          //   allowAdding={true}
          allowDeleting={allowDeleting}
          allowUpdating={allowEditing}
          confirmDelete={false}
          allowClearing={true}
          useIcons={true}
          // changes={changes}
          // onChangesChange={setChanges}
          // editRowKey={editRowKey}
          // onEditRowKeyChange={setEditRowKey}
        />
        <Column
          dataField="rowType"
          caption="Type"
          width={200}
          fixed={true}
          calculateDisplayValue={rowData => {
            return calculateDisplayValueForGroupedData(rowData);
          }}
        >
          {/* <Lookup
            dataSource={getBackendLookupDataSource(gridGroupKey)}
            displayExpr="label"
            valueExpr="id"
          /> */}
        </Column>
        <Column
          dataField="keyForOrder"
          dataType="string"
          groupIndex={0}
          groupCellRender={GroupCell}
        ></Column>
        {columns?.map(column => (
          <Column
            dataField={column.col}
            calculateDisplayValue={rowData => {
              return calculateDisplayValue(rowData, column.col);
            }}
            headerCellRender={renderTitleHeader}
          >
            {column.isLookUp && (
              <Lookup
                dataSource={getCustomDataSource}
                valueExpr="id"
                displayExpr="label"
                allowClearing={true}
              />
            )}
          </Column>
        ))}
        <Column type="buttons" width={90} fixed={true} fixedPosition="right">
          <Button
            name="delete"
            onClick={e => {
              setGridGroupedKey(e?.row?.data?.type || null);
              deleteClickHandler(e?.row);
            }}
          />
          <Button name="save" />
          <Button name="cancel" />
          <Button
            name="edit"
            onClick={e => {
              setGridGroupedKey(e?.row?.data?.type || null);
              editClickHandler(e?.row);
            }}
          />
        </Column>
      </DataGrid>
    </>
  );
};
export default DNADevExtremeGroupedDataGrid;
