/* eslint-disable array-bracket-newline */
import React, {
  createRef,
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid';

import {
  FormBuilder,
  DNADataGrid,
  TableActions,
  IngestionActions,
  Link,
} from '../../../../../framework';
import {
  updateCompanyDetails,
  updateCompanyChildValues,
} from '../../../../../store/actions/company';
import { showModal } from '../../../../../store/actions/globalActions';
import { MODAL_TYPES } from '../../../../../utils/generic/constants';
import {
  convertDataForRadioOptions,
  updateIngestedDataBasedOnFieldType,
  checkForIngestionAction,
} from '../../../../../utils/generic/helper';
import {
  onFilteringCompany,
  isDuplicateCompany,
  formAcquiredOrSubsidiaryCompanyDetails,
} from '../../../../../utils/company/helper';
import ConfirmationMessage from '../../../../../generic/modals/ConfirmationMessage';
import ModalMessage from '../../../../../generic/modals/ModalMessage';
import AdditionalInfoAcquiredCompanyModal from './additionalInfoAcquiredCompanyModal';
import {
  initialValues,
  layoutSchema,
  formSchema,
  columnDefs,
  validateAcquiredOrSubsidiaryCompanyDetails,
} from './config';
import './index.scss';

const AdditionalInfoAcquiredCompany = forwardRef(({ permissions: { disableEdit } }, ref) => {
  const company = useSelector(state => state.company, shallowEqual);
  const {
    current: {
      id,
      additionalInfo,
      additionalInfo: { acquiredOrSubsidiaryCompanies },
    },
    masterData: { relatedCompanyTypes },
  } = company;
  const formRef = createRef(null);
  const acquiredOrSubsidiaryCompaniesRef = useRef(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const [showEditModal, updateShowEditModal] = useState(false);
  const [rowDetails, updateRowDetails] = useState(null);
  const [errorMessage, updateErrorMessage] = useState('');
  const [deleteConfirmStatus, setDeleteConfirmStatus] = useState(false);
  const [rowId, setRowId] = useState(-1);

  useImperativeHandle(ref, () => ({
    isChanged: () => {
      if (formRef.current) {
        return formRef.current.dirty;
      }
      return false;
    },
    reset: () => {
      if (formRef.current) {
        formRef.current.resetForm();
      }
    },
  }));

  useEffect(() => {
    acquiredOrSubsidiaryCompaniesRef.current = [...acquiredOrSubsidiaryCompanies];
  }, [acquiredOrSubsidiaryCompanies]);

  const searchAcquiredOrSubsidiaryCompanyName = async value => {
    const filteredData = await onFilteringCompany(value, id);
    if (!filteredData) {
      dispatch(
        showModal({
          isOpen: true,
          message: 'There was a problem with the server',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    return filteredData;
  };

  let updatedFormSchema = { ...formSchema(searchAcquiredOrSubsidiaryCompanyName) };

  updatedFormSchema = {
    ...updatedFormSchema,
    companyType: {
      ...updatedFormSchema.companyType,
      options: convertDataForRadioOptions(relatedCompanyTypes, 'id', 'value'),
    },
  };

  const updateRowData = data => {
    updateRowDetails({
      ...data,
      yearAcquired: data.yearAcquired ? new Date(data.yearAcquired, 1, 1) : null,
    });
  };

  const handleDelete = data => {
    updateRowData(data);
    setDeleteConfirmStatus(true);
  };

  const handleEdit = data => {
    updateRowData(data);
    updateShowEditModal(true);
  };

  const proceedToDelete = status => {
    if (status) {
      const currentAcquiredOrSubsidiaryCompanyNames = [...acquiredOrSubsidiaryCompanies];
      currentAcquiredOrSubsidiaryCompanyNames.splice(
        acquiredOrSubsidiaryCompanies.findIndex(a => a.id === rowDetails.id),
        1,
      );
      const currentAdditionalInfo = { ...additionalInfo };
      currentAdditionalInfo[
        'acquiredOrSubsidiaryCompanies'
      ] = currentAcquiredOrSubsidiaryCompanyNames;
      dispatch(
        updateCompanyDetails({
          key: 'additionalInfo',
          data: currentAdditionalInfo,
        }),
      );
    }
    updateRowDetails(null);
    setDeleteConfirmStatus(false);
  };

  const handleClose = () => {
    updateShowEditModal(false);
    updateRowDetails(null);
  };

  const resetAndNavigateToAnotherCompany = companyId => {
    history.push(`/company/${companyId}`);
  };

  const onClick = data => {
    updateRowDetails({
      ...rowDetails,
      acquiredOrSubsidiaryCompanyName: { ...data },
    });
    resetAndNavigateToAnotherCompany(data.id);
  };

  const onErrorModalClose = () => {
    updateErrorMessage('');
  };

  const handleIngestionActions = (option, data) => {
    const updatedData = updateIngestedDataBasedOnFieldType({
      type: 'array',
      ingestionAction: option,
      current: acquiredOrSubsidiaryCompaniesRef.current || [],
      ingested: data,
    });
    dispatch(
      updateCompanyChildValues({
        parentKey: 'additionalInfo',
        childKey: 'acquiredOrSubsidiaryCompanies',
        data: updatedData,
      }),
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={async values => validateAcquiredOrSubsidiaryCompanyDetails(values)}
        onSubmit={async (values, { resetForm }) => {
          const { acquiredOrSubsidiaryCompanyName } = values;
          if (!isEmpty(acquiredOrSubsidiaryCompanyName)) {
            const currentAdditionalInfo = { ...additionalInfo };
            const currentAcquiredOrSubsidiaryCompanyNames = [...acquiredOrSubsidiaryCompanies];
            if (
              isDuplicateCompany(
                currentAcquiredOrSubsidiaryCompanyNames,
                acquiredOrSubsidiaryCompanyName.value,
              )
            ) {
              updateErrorMessage('Company already exists');
            } else {
              currentAcquiredOrSubsidiaryCompanyNames.unshift(
                formAcquiredOrSubsidiaryCompanyDetails({ id: rowId, ...values }),
              );
              currentAdditionalInfo['acquiredOrSubsidiaryCompanies'] = [
                ...currentAcquiredOrSubsidiaryCompanyNames,
              ];
              dispatch(
                updateCompanyDetails({
                  key: 'additionalInfo',
                  data: currentAdditionalInfo,
                }),
              );
              setRowId(rowId - 1);
              resetForm();
              updateErrorMessage('');
            }
          }
        }}
      >
        {formikProps => {
          formRef.current = formikProps;
          return (
            <FormBuilder
              formikProps={formikProps}
              layoutSchema={layoutSchema}
              formSchema={updatedFormSchema}
              onSubmitValidationError={() => {}}
              disableControls={
                disableEdit || checkForIngestionAction([...acquiredOrSubsidiaryCompanies])
              }
            />
          );
        }}
      </Formik>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid item xs={12}>
          <div className="company-subsidary-grid">
            <DNADataGrid
              columnDefs={columnDefs({
                relatedCompanyTypes,
                onClick,
                handleDelete,
                handleEdit,
                handleIngestionActions,
                disableEdit,
                acquiredOrSubsidiaryCompanies,
              })}
              rowData={JSON.parse(JSON.stringify(acquiredOrSubsidiaryCompanies))}
              rowHeight={35}
              frameworkComponents={{
                link: Link,
                actions: TableActions,
                ingestionActions: IngestionActions,
              }}
              getRowClass={({ data }) => (data && data.isDisabled ? 'disable-grid-row' : '')}
            />
          </div>
        </Grid>
      </Grid>
      {showEditModal && (
        <AdditionalInfoAcquiredCompanyModal
          rowDetails={rowDetails}
          onClose={handleClose}
          searchAcquiredOrSubsidiaryCompanyName={searchAcquiredOrSubsidiaryCompanyName}
        />
      )}
      {deleteConfirmStatus && (
        <ConfirmationMessage
          isOpen
          onClose={proceedToDelete}
          message="Do you want to delete this row?"
        />
      )}
      {errorMessage && (
        <ModalMessage
          isOpen
          modalType={MODAL_TYPES.ERROR}
          onClose={onErrorModalClose}
          message={errorMessage}
        />
      )}
    </>
  );
});

AdditionalInfoAcquiredCompany.propTypes = {
  permissions: PropTypes.object.isRequired,
  isDataChanged: PropTypes.func.isRequired,
};

export default AdditionalInfoAcquiredCompany;
