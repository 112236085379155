import { fieldTypes } from '../../../../../../framework/formBuilder/utils';
import {
  getClassNameForIngestion,
  checkForIngestionAction,
} from '../../../../../../utils/generic/helper';

// eslint-disable-next-line
export const handleValidations = ({ origin, nbe, nce, drugDrugTypeClassifications, productionStatusId }) => {
  let error = '';
  if (nce && nbe) {
    error = 'NCE and NBE cannot be “Yes” for same product. Please check again';
  }
  if (!origin) {
    error = 'A drug record should have origin.';
  }
  if (productionStatusId !== 5 && drugDrugTypeClassifications && !drugDrugTypeClassifications.length) {
    error = 'Please select drug type'
  }
  return error;
};

export const validate = (values, pubChemClicked, registryClicked) => {
  const errors = {};
  var regForOnlyNumbers = new RegExp('^[0-9]+$');
  if (registryClicked) {
    if (!values.registries.trim()) {
      errors.registries = 'CAS Registry Number is Required';
    }
    if (values.registries && !regForOnlyNumbers.test(values.registries)) {
      errors.registries = 'CAS Registry Number should be numeric';
    }
    if (values.registries.trim().length > 0 && values.registries.trim().length < 4) {
      errors.registries = 'CAS Registry Number should be atleast 4 digits';
    }
    if (values.registries.trim().length > 11) {
      errors.registries = 'CAS Registry Number should not exceed 11 digits';
    }
  }
  if (pubChemClicked) {
    if (!values.pubchemCIDs.trim()) {
      errors.pubchemCIDs = 'PubChem CID is Required';
    }
    if (values.pubchemCIDs && !regForOnlyNumbers.test(values.pubchemCIDs)) {
      errors.pubchemCIDs = 'PubChem CID should be numeric';
    }
    if (values.pubchemCIDs.trim().length > 9) {
      errors.pubchemCIDs = 'PubChem CID should not exceed 9 digits';
    }
  }
  return errors;
};

export const isRegistryExists = (data, { cas }) => {
  let isDataExists = false;
  data.forEach(c => {
    if (c.cas.toLowerCase().trim() === cas.toLowerCase().trim()) {
      isDataExists = true;
    }
  });
  return isDataExists;
};

export const isPubChemIdExists = (data, { pubChemCID }) => {
  let isDataExists = false;
  data.forEach(c => {
    if (c.pubChemCID && Number(c.pubChemCID) === Number(pubChemCID)) {
      isDataExists = true;
    }
  });
  return isDataExists;
};

export const initialValues = {
  origin: null,
  nce: 1,
  nbe: 1,
  registries: '',
  pubchemCIDs: '',
};

export const registryEditLayoutSchema = {
  layout: 'row',
  className: 'main-section',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'registries',
      size: 8,
      className: '',
    },
    {
      layout: 'button',
      size: 1,
      className: 'add-button',
    },
  ],
};

export const registryLayoutSchema = {
  layout: 'row',
  className: 'registry-form',
  size: 6,
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'registries',
      size: 5,
      className: 'registries-data',
    },
    {
      layout: 'button',
      size: 1,
      className: 'add-button',
    },
  ],
};

export const pubChemIdEditLayoutSchema = {
  layout: 'row',
  className: 'main-section',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'pubchemCIDs',
      size: 8,
      className: '',
    },
    {
      layout: 'pubchemCIDsButton',
      size: 1,
      className: 'add-button',
    },
  ],
};

export const pubChemIdLayoutSchema = {
  layout: 'row',
  className: 'pubchemCIDs-form',
  size: 6,
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'pubchemCIDs',
      size: 5,
      className: 'pubchemCIDs-data',
    },
    {
      layout: 'pubchemCIDsButton',
      size: 1,
      className: 'add-button',
    },
  ],
};

export const layoutSchema = {
  layout: 'column',
  className: 'molecular-data-container mb-20',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'column',
      size: 12,
      className: 'dh-row mt-20',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      header: ' ',
      headerProps: {
        fontStyle: 'bold',
        size: '14',
      },
      content: [
        {
          layout: 'drugDrugTypeClassifications',
          size: 6,
          className: '',
        },
        {
          layout: 'origin',
          size: 3,
          className: 'origin-data',
        },
      ],
    },
    {
      layout: 'row',
      className: 'dl-row',
      size: 12,
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'nce',
          size: 2,
          className: 'nce-data',
        },
        {
          layout: 'nbe',
          size: 2,
          className: 'nbe-data',
        },
      ],
    },
  ],
};

export const registryEditFormSchema = {
  registries: {
    name: 'registries',
    type: fieldTypes.text,
    label: 'CAS REGISTRY NUMBER',
    props: {
      isMandatory: false,
      inputHeight: 'ht-lg',
    },
  },
  button: {
    name: 'button',
    type: fieldTypes.button,
    label: 'SAVE',
  },
};

export const registryFormSchema = {
  registries: {
    name: 'registries',
    type: fieldTypes.text,
    label: 'CAS REGISTRY NUMBER',
    props: {
      isMandatory: false,
      inputHeight: 'ht-lg',
    },
  },
  button: {
    name: 'button',
    type: fieldTypes.customButton,
    label: '+ADD',
    props: {},
  },
};

export const pubChemIdFormSchema = {
  pubchemCIDs: {
    name: 'pubchemCIDs',
    type: fieldTypes.text,
    label: 'PubChem CID',
    props: {
      isMandatory: false,
      inputHeight: 'ht-lg',
    },
  },
  pubchemCIDsButton: {
    name: 'pubchemCIDsButton',
    type: fieldTypes.customButton,
    label: '+ADD',
    props: {},
  },
};

export const pubChemIdEditFormSchema = {
  pubchemCIDs: {
    name: 'pubchemCIDs',
    type: fieldTypes.text,
    label: 'PubChem CID',
    props: {
      isMandatory: false,
      inputHeight: 'ht-lg',
    },
  },
  pubchemCIDsButton: {
    name: 'pubchemCIDsButton',
    type: fieldTypes.button,
    label: 'SAVE',
  },
};

export const formSchema = {
  drugDrugTypeClassifications: {
    name: 'drugDrugTypeClassifications',
    type: fieldTypes.select_tree_async,
    // type: fieldTypes.select_async,
    label: 'DRUG TYPE',
    props: {
      isSearchable: true,
      isMandatory: true,
      isMulti: true,
      showSelectedData: true,
      singleNodeSelectFlag: true,
      enableSingleFamilySelect: false,
    },
  },
  origin: {
    name: 'origin',
    type: fieldTypes.select,
    label: 'ORIGIN',
    props: {
      isMandatory: true,
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
    },
    options: [],
  },
  nce: {
    name: 'nce',
    type: fieldTypes.custom_radio,
    label: 'NCE',
    options: [],
    props: {
      isMandatory: false,
      height: 'ht-lg',
    },
  },
  nbe: {
    name: 'nbe',
    type: fieldTypes.custom_radio,
    label: 'NBE',
    options: [],
    props: {
      isMandatory: false,
      height: 'ht-lg',
      // disabled as per 11700, needs to be removed in future
      disabled: true,
    },
  },
};

export const columnDefs = ({
  handleEdit,
  handleDelete,
  handleIngestionActionsForGrid,
  disableEdit,
  gridData,
}) => [
    {
      headerName: 'CAS REGISTRY NUMBER',
      field: 'cas',
      width: 270,
      sortable: true,
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { rowIndex, data } = params;
        return {
          canDisableEdit: data.isChildCopy || disableEdit || data.isDisabled,
          handleEdit: () => {
            handleEdit(data, rowIndex, 'registry');
          },
        };
      },
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { rowIndex, data } = params;
        return {
          canDisableDelete: data.isChildCopy || disableEdit || data.isDisabled,
          handleDelete: () => {
            handleDelete(data, rowIndex, 'registry');
          },
        };
      },
    },
    {
      headerName: '',
      minWidth: 100,
      maxWidth: 100,
      field: '',
      hide: !checkForIngestionAction(gridData, true),
      cellRenderer: 'ingestionActions',
      cellRendererParams: ({ data: rowData }) => {
        return {
          isDisabled: disableEdit,
          ingestionAction: rowData ? rowData.ingestionAction : 'none',
          handleIngestionActions: option => handleIngestionActionsForGrid(option, rowData),
        };
      },
    },
  ];

export const pubChemColumnDefs = ({
  handleEdit,
  handleDelete,
  handleIngestionActionsForGrid,
  disableEdit,
  gridData,
}) => [
    {
      headerName: 'PUBCHEM CID',
      field: 'pubChemCID',
      width: 270,
      sortable: true,
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { rowIndex, data } = params;
        return {
          canDisableEdit: data.isChildCopy || disableEdit || data.isDisabled,
          handleEdit: () => {
            handleEdit(data, rowIndex, 'pubChem');
          },
        };
      },
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { rowIndex, data } = params;
        return {
          canDisableDelete: data.isChildCopy || disableEdit || data.isDisabled,
          handleDelete: () => {
            handleDelete(data, rowIndex, 'pubChem');
          },
        };
      },
    },
    {
      headerName: '',
      minWidth: 100,
      maxWidth: 100,
      field: '',
      hide: !checkForIngestionAction(gridData, true),
      cellRenderer: 'ingestionActions',
      cellRendererParams: ({ data: rowData }) => {
        return {
          isDisabled: disableEdit,
          ingestionAction: rowData ? rowData.ingestionAction : 'none',
          handleIngestionActions: option => handleIngestionActionsForGrid(option, rowData),
        };
      },
    },
  ];
