import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Popover } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import './index.scss';
const Modal = ({ text, list, handleClose, anchorEl }) => {
  return (
    <Popover
      id="content-copy"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 20,
        horizontal: 20,
      }}
    >
      <Grid
        className="content-copy-grid"
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <div className="copy_container">
          <div className="copy_header">
            <FontAwesomeIcon
              className="close_icon"
              icon={faTimesCircle}
              onClick={handleClose}
            ></FontAwesomeIcon>
          </div>
          <div className="copy_content">{text || list}</div>
        </div>
      </Grid>
    </Popover>
  );
};

Modal.propTypes = {
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  anchorEl: PropTypes.any,
  handleClose: PropTypes.func,
};

Modal.defaultProps = {
  handleClose: () => {},
  text: null,
  list: '',
};

export default Modal;
