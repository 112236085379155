import { createAction } from '@reduxjs/toolkit';

// action definitions
const personRoot = 'person/tabs/';
export const setSelectedTabIndex = createAction(`${personRoot}setSelectedTabIndex`);
export const personNextFeatureSelection = createAction(`${personRoot}goToNextFeature`);
export const personFeatureSelection = createAction(`${personRoot}selected`);

export const getPersonList = createAction(`${personRoot}getPersonList`);
export const getPersonListSuccess = createAction(`${personRoot}getPersonListSuccess`);
export const resetPersonState = createAction(`${personRoot}resetPersonState`);
export const deletePerson = createAction(`${personRoot}deletePerson`);
export const deleteMultiplePerson = createAction(`${personRoot}deleteMultiplePerson`);
export const deletePersonSuccess = createAction(`${personRoot}deletePersonSuccess`);
export const deleteMultiplePersonSuccess = createAction(`${personRoot}deleteMultiplePersonSuccess`);

export const setError = createAction(`${personRoot}error`);

export const getPersonDetails = createAction(`${personRoot}getPersonDetails`);
export const getPersonDetailsSuccess = createAction(`${personRoot}getPersonDetailsSuccess`);
export const savePersonDetails = createAction(`${personRoot}savePersonDetails`);
export const savePersonDetailsSuccess = createAction(`${personRoot}savePersonDetailsSuccess`);
export const getPersonMasterData = createAction(`${personRoot}getPersonMasterData`);
export const getPersonMasterDataSuccess = createAction(`${personRoot}getPersonMasterDataSuccess`);
export const setShowPersonValidationModal = createAction(
  `${personRoot}setShowPersonValidationModal`,
);

export const getPersonStampingDetails = createAction(`${personRoot}getPersonStampingDetails`);
export const getPersonStampingDetailsSuccess = createAction(
  `${personRoot}getPersonStampingDetailsSuccess`,
);

export const updatePersonDetails = createAction(`${personRoot}updatePersonDetails`);
export const resetPersonDetails = createAction(`${personRoot}resetPersonDetails`);
export const setPreviewSelection = createAction(`${personRoot}setPreviewSelection`);

export const setPersonValidationStatus = createAction(`${personRoot}setPersonValidationStatus`);
export const setPersonChangeStatus = createAction(`${personRoot}setPersonChangeStatus`);

export const searchPersonNoteType = createAction(`${personRoot}searchNoteType`);
export const searchPersonNoteTypeSuccess = createAction(`${personRoot}searchNoteTypeSuccess`);

//queues
export const setSelectedQueueType = createAction(`${personRoot}setSelectedQueueType`);
export const setTaskSelectedQueueType = createAction(`${personRoot}setTaskSelectedQueueType`);
export const getQueueDashboardData = createAction(`${personRoot}getQueueDashboardData`);
export const exitQueue = createAction(`${personRoot}exitQueue`);
export const setQueueExpandedList = createAction(`${personRoot}setQueueExpandedList`);
export const setQueueTransactionId = createAction(`${personRoot}setQueueTransactionId`);

export const getQueueRecords = createAction(`${personRoot}getQueueRecords`);
export const getTaskQueueRecords = createAction(`${personRoot}getTaskQueueRecords`);
export const getQueueTypes = createAction(`${personRoot}getQueueTypes`);
export const getTaskQueueTypes = createAction(`${personRoot}getTaskQueueTypes`);
export const getQueueTypesAndRecords = createAction(`${personRoot}getQueueTypesAndRecords`);
export const getQueueRecordsSuccess = createAction(`${personRoot}getQueueRecordsSuccess`);
export const getTaskQueueRecordsSuccess = createAction(`${personRoot}getTaskQueueRecordsSuccess`);
export const getQueueTypesSuccess = createAction(`${personRoot}getQueueTypesSuccess`);
export const getTaskQueueTypesSuccess = createAction(`${personRoot}getTaskQueueTypesSuccess`);

export const getPersonIngestedData = createAction(`${personRoot}getPersonIngestedData`);
export const getPersonIngestedDataSuccess = createAction(
  `${personRoot}getPersonIngestedDataSuccess`,
);
export const updatePersonIngestedAction = createAction(`${personRoot}updatePersonIngestedAction`);
export const updatePersonChildValues = createAction(`${personRoot}updatePersonChildValues`);
export const resetPersonIngestedData = createAction(`${personRoot}resetPersonIngestedData`);

//Locking Actions
export const getPersonLockDetails = createAction(`${personRoot}getPersonLockDetails`);
export const updatePersonLockDetails = createAction(`${personRoot}updatePersonLockDetails`);
export const releasePersonUserLock = createAction(`${personRoot}releasePersonUserLock`);
export const resetPersonUserLock = createAction(`${personRoot}resetPersonUserLock`);

export const getPersonAssociation = createAction(`${personRoot}getPersonAssociation`);
export const getPersonAssociationSuccess = createAction(`${personRoot}getPersonAssociationSuccess`);
export const getPersonOrgAssociation = createAction(`${personRoot}getPersonOrgAssociation`);
export const getPersonOrgAssociationSuccess = createAction(
  `${personRoot}getPersonOrgAssociationSuccess`,
);
//Advanced Search
export const getAdvancedSearchCategoryData = createAction(
  `${personRoot}getAdvancedSearchCategoryData`,
);
export const getAdvancedSearchCategoryDataSuccess = createAction(
  `${personRoot}getAdvancedSearchCategoryDataSuccess`,
);

export const getAdvancedSearchCategoryTitleData = createAction(
  `${personRoot}getAdvancedSearchCategoryTitleData`,
);
export const getAdvancedSearchCategoryTitleDataSuccess = createAction(
  `${personRoot}getAdvancedSearchCategoryTitleDataSuccess`,
);

export const getAllSavedSearches = createAction(`${personRoot}getAllSavedSearches`);
export const getAllSavedSearchesSuccess = createAction(`${personRoot}getAllSavedSearchesSuccess`);
export const addSavedSearch = createAction(`${personRoot}addSavedSearch`);
export const updateSavedSearch = createAction(`${personRoot}updateSavedSearch`);
export const deleteSavedSearch = createAction(`${personRoot}deleteSavedSearch`);
// proximity actions
export const getPersonProximityData = createAction(`${personRoot}getPersonProximityData`);
export const getPersonProximitySuccess = createAction(`${personRoot}getPersonProximitySuccess`);
export const resetPersonProximityData = createAction(`${personRoot}resetPersonProximityData`);

//compare actions
export const getPersonCompareData = createAction(`${personRoot}getPersonCompareData`);
export const getPersonCompareDataSuccess = createAction(`${personRoot}getPersonCompareDataSuccess`);
export const resetPersonCompareData = createAction(`${personRoot}resetPersonCompareData`);
