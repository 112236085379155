import { createAction } from '@reduxjs/toolkit';
export const getTaskMaster = createAction('task/getTaskMaster');
export const getTaskMasterSuccess = createAction('task/getTaskMasterSuccess');
export const assigneeUserGroupList = createAction('task/assigneeUserGroupList');
export const assigneeUserGroupListSuccess = createAction('task/assigneeUserGroupListSuccess');
export const assigneeUserGroupsByQueueIdSuccess = createAction(
  'task/assigneeUserGroupsByQueueIdSuccess',
);
export const resetAssigneeUserGroupsByQueueId = createAction(
  'task/resetAssigneeUserGroupsByQueueId',
);

export const deleteTask = createAction('task/deleteTask');
export const deleteTaskSuccess = createAction('task/deleteTaskSuccess');
export const editTask = createAction('task/editTask');
export const saveCreateTask = createAction('task/saveCreateTask');
export const advanceSearchTaskList = createAction('task/advanceSearchTaskList');
export const advanceSearchTaskListSuccess = createAction('task/advanceSearchTaskListSuccess');

export const getTaskDashboardData = createAction('task/getTaskDashboardData');
