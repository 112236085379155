import React from 'react';
import PropTypes from 'prop-types';
import InputIcon from '../inputs/InputIcon';
import { drugTypeColorRules } from '../../utils/generic/constants';

const DrugTypeId = ({ drugTypeId }) => {
  // console.log('Producetion status', { drugTypeId, prodStsColorRules: colorRules, isRegIntel });
  let typeId = drugTypeId;

  const drugTypeIdColorRules = drugTypeColorRules;

  if (typeof drugTypeId === 'string') {
    typeId = Number(drugTypeId);
  }
  if (typeof drugTypeId === 'object') {
    const { id = 0 } = { ...drugTypeId };
    typeId = id;
  }
  if (typeId || typeId === 0) {
    let color = '';
    drugTypeIdColorRules.map(clr => {
      if (clr.id === typeId) {
        color = clr.iconColor;
      }
    });

    return <InputIcon color={color} starIcon={true} />;
  }
  return '';
};

DrugTypeId.propTypes = {
  drugTypeId: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  prodStsColorRules: PropTypes.array.isRequired,
};

DrugTypeId.defaultProps = {
  prodStsColorRules:
    localStorage.getItem('usergroup') == 'RegIntelUser'
      ? [
          { id: 0, display: 'Draft', iconColor: 'red' },
          { id: 1, display: 'Validated', iconColor: 'orange' },
          { id: 2, display: 'Published', iconColor: 'green' },
          { id: 3, display: 'Out of Scope', iconColor: 'black' },
        ]
      : [
          { id: 3, display: 'Pending Review', iconColor: 'yellow' },
          { id: 2, display: 'Out of scope', iconColor: 'black' },
          { id: 0, display: 'Out of production', iconColor: 'red' },
          { id: 4, display: 'Data check', iconColor: 'orange' },
          { id: 1, display: 'In Production', iconColor: 'green' },
          { id: 5, display: 'External Source', iconColor: 'blue' },
        ],
};

export default DrugTypeId;
