import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import LinearProgressBar from '../../../generic/modals/LinearProgressbar';

const StopWatch = ({ start, time, done }) => {
  const [warningCount, setWarningCount] = useState(0);
  const [timeInSec, setTimeInSec] = useState(time / 1000);

  useEffect(() => {
    let timerCount = timeInSec;
    setWarningCount(timerCount);
    const setWarningTimer = setInterval(() => {
      timerCount -= 1;
      if (timerCount === 0) {
        done();
      } else {
        setWarningCount(timerCount);
      }
    }, 1000);
    if (!start) {
      clearInterval(setWarningTimer);
    }
    return () => clearInterval(setWarningTimer);
  }, [start]);

  useEffect(() => setTimeInSec(time / 1000), [time]);

  return (
    <>
      <div>Will be automatically released in {warningCount} second(s)</div>
      <LinearProgressBar value={warningCount} max={timeInSec} min={0} />
    </>
  );
};

StopWatch.propTypes = {
  start: PropTypes.bool,
  time: PropTypes.number,
  done: PropTypes.func,
};
StopWatch.defaultProps = {
  start: false,
  time: 100,
  done: () => {},
};

export default StopWatch;
