const features = [
  {
    name: 'DRUG NAME',
    value: 'DrugName',
    key: 'names',
    isChildTab: true,
    isTab: false,
    selectedTabIndex: 0,
    sectionName: 'DESCRIPTIVE NAME',
    isMandatory: false,
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
  {
    name: 'DRUG SUMMARY',
    value: 'DrugSummary',
    key: 'summary',
    isChildTab: true,
    isTab: false,
    selectedTabIndex: 0,
    sectionName: 'DescriptiveName',
    isMandatory: false,
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
  {
    name: 'DRUG PROGRAM LANDSCAPE STATUS',
    value: 'DrugProgramLandscapeStatus',
    key: 'landscapeStatuses',
    isChildTab: false,
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
  {
    name: 'ACTIVITY',
    value: 'Activity',
    key: 'activity',
    isChildTab: true,
    isTab: false,
    selectedTabIndex: 0,
    sectionName: 'Activity',
    isMandatory: false,
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
  {
    name: 'DRUG LICENSING AVAILABILITY',
    value: 'DrugLicensingAvailability',
    key: 'licensingAvailability',
    isChildTab: false,
    isTab: true,
    selectedTabIndex: 0,
    tabs: [
      {
        name: 'DRUG LICENSING AVAILABILITY',
        value: 'DrugLicensingAvailability',
        key: 'licenses',
        hasError: false,
        hasIngestedData: false,
        hasUnSavedData: false,
      },
      {
        name: 'AGREEMENT',
        value: 'Agreement',
        key: 'agreement',
        hasError: false,
        hasIngestedData: false,
        hasUnSavedData: false,
      },
      // {
      //   name: 'AVAILABILITY',
      //   value: 'Availability',
      //   key: 'availability',
      //   hasError: false,
      //   hasIngestedData: false,
      //   hasUnSavedData: false,
      // },
    ],
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
  {
    name: 'EVENTS',
    value: 'Events',
    key: 'events',
    isChildTab: false,
    isTab: false,
    selectedTabIndex: 0,
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
  {
    name: 'CHEMICAL DATA',
    value: 'ChemicalData',
    key: 'chemStructure',
    isChildTab: false,
    isTab: true,
    selectedTabIndex: 0,
    tabs: [
      {
        name: 'DESCRIPTIVE DATA',
        value: 'DescriptiveData',
        key: 'descriptiveData',
        keys: ['casRegistrys', 'nbe', 'nce', 'origin'],
        hasError: false,
        hasIngestedData: false,
        hasUnSavedData: false,
      },
      {
        name: 'PATENT',
        value: 'Patent',
        key: 'patents',
        hasError: false,
        hasIngestedData: false,
        hasUnSavedData: false,
      },
      {
        name: 'MOLECULAR DATA',
        value: 'MolecularData',
        key: 'molecularData',
        hasError: false,
        hasIngestedData: false,
        hasUnSavedData: false,
      },
    ],
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
  {
    name: 'MARKETING',
    value: 'Marketing',
    key: 'marketing',
    isChildTab: false,
    isTab: true,
    selectedTabIndex: 0,
    tabs: [
      {
        name: 'APPROVALS',
        value: 'Approvals',
        key: 'approvals',
        hasError: false,
        hasIngestedData: false,
        hasUnSavedData: false,
      },
      // {
      //   name: 'FILINGS',
      //   value: 'Filings',
      //   key: 'fillings',
      //   hasError: false,
      //   hasIngestedData: false,
      //   hasUnSavedData: false,
      // },
      // {
      //   name: 'ORPHAN DRUG STATUS',
      //   value: 'OrphanDrugStatus',
      //   key: 'orphanDrugStatus',
      //   hasError: false,
      //   hasIngestedData: false,
      //   hasUnSavedData: false,
      // },
      // {
      //   name: 'EXPEDITED REVIEW DESIGNATION',
      //   value: 'ExpeditedReviewDesign',
      //   key: 'expeditedReview',
      //   hasError: false,
      //   hasIngestedData: false,
      //   hasUnSavedData: false,
      // },
      // {
      //   name: 'WARNINGS',
      //   value: 'Warnings',
      //   key: 'warnings',
      //   hasError: false,
      //   hasIngestedData: false,
      //   hasUnSavedData: false,
      // },
    ],
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
  {
    name: 'PHASES',
    value: 'Phases',
    key: 'phases',
    isChildTab: false,
    isTab: true,
    selectedTabIndex: 0,
    tabs: [
      {
        name: 'PHASE III',
        value: 'Phase3',
        key: 'phaseIII',
        hasError: false,
        hasIngestedData: false,
        hasUnSavedData: false,
      },
      {
        name: 'PHASE II',
        value: 'Phase2',
        key: 'phaseII',
        hasError: false,
        hasIngestedData: false,
        hasUnSavedData: false,
      },
      {
        name: 'PHASE I',
        value: 'Phase1',
        key: 'phaseI',
        hasError: false,
        hasIngestedData: false,
        hasUnSavedData: false,
      },
      {
        name: 'PRECLINICAL:IN VIVO',
        value: 'PreClinicalInVivo',
        key: 'inVivo',
        hasError: false,
        hasIngestedData: false,
        hasUnSavedData: false,
      },
      // {
      //   name: 'PRECLINICAL:IN VITRO',
      //   value: 'PreClinicalInVitro',
      //   key: 'inVitro',
      //   hasError: false,
      //   hasIngestedData: false,
      //   hasUnSavedData: false,
      // },
      // {
      //   name: 'PRECLINICAL:PATENTS',
      //   value: 'PreClinicalPatents',
      //   key: 'patents',
      //   hasError: false,
      //   hasIngestedData: false,
      //   hasUnSavedData: false,
      // },
    ],
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
  {
    name: 'URLS',
    value: 'Urls',
    key: 'supportingUrls',
    isChildTab: true,
    isTab: false,
    selectedTabIndex: 0,
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
  {
    name: 'TRENDS',
    value: 'Trends',
    key: 'trends',
    isChildTab: false,
    isTab: false,
    selectedTabIndex: 0,
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
  {
    name: 'NOTES',
    value: 'Notes',
    key: 'notes',
    isChildTab: true,
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
  {
    name: 'DRUG ASSOCIATIONS',
    value: 'DrugAssociations',
    key: 'associations',
    isChildTab: true,
    isTab: true,
    selectedTabIndex: 0,
    tabs: [
      { name: 'ASSOCIATED TRIALS', value: 'AssociatedTrials' },
      { name: 'ASSOCIATED DRUGS ', value: 'AssociatedDrugs' },
    ],
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
  {
    name: 'AUDIT TRAIL',
    value: 'AuditTrial',
    key: 'auditTrial',
    isChildTab: true,
    isTab: false,
    selectedTabIndex: 0,
    hasError: false,
    hasIngestedData: false,
    hasUnSavedData: false,
  },
];

export default features;
