import { fieldTypes } from '../../framework/formBuilder/utils';
import { TEXT_LIMIT } from '../../utils/generic/constants';
import { checkIsRegistryModalSelected } from '../protocolRegistration/config';

export const validate = (values, isValidate, isOtherTab) => {
  const errors = {};
  const isOthersSelected = values?.registryResultDisclosureModel?.some(
    value => value?.registryResultDisclose?.value?.trim() === 'Other (described below)',
  );
  const isOtherDataEntryOption = values?.registryDataEntryOptions?.some(
    value => value.dataEntryOption.value?.trim() === 'Other (described below)',
  );
  const isOtherFormat = values?.registryFormatOfFilesUploaded?.some(
    value => value.fileFormatUploaded.value?.trim() === 'Other',
  );
  if (values?.otherInformationSubmitted?.trim()?.length > TEXT_LIMIT[3000]) {
    errors.otherInformationSubmitted = 'Please enter only 3000 characters';
  }
  if (values?.otherDataEntryOption?.trim()?.length > 400) {
    errors.otherDataEntryOption = 'Please enter only 400 characters';
  }
  if (values?.otherFormatOfFiles?.trim()?.length > 400) {
    errors.otherFormatOfFiles = 'Please enter only 400 characters';
  }
  if (values?.stepsResultsDisclosure?.trim()?.length > TEXT_LIMIT[3000]) {
    errors.stepsResultsDisclosure = 'Please enter only 3000 characters';
  }

  if (values.isClinicalTrialResults === null) {
    errors.isClinicalTrialResults = 'Please select the clinical trial results';
  }
  if (isValidate) {
    if (values.isClinicalTrialResults) {
      if (!values.stepsResultsDisclosure?.trim()) {
        errors.stepsResultsDisclosure = 'Please enter registry steps';
      }

      if (!values.registryAddtionalInformation?.length) {
        errors.registryAddtionalInformation = 'Please select registry additional information';
      }

      if (!values.registryFormatOfFilesUploaded?.length) {
        errors.registryFormatOfFilesUploaded = 'Please select file upload format';
      }

      if (!values.registryDataEntryOptions?.length) {
        errors.registryDataEntryOptions = 'Please select data entry options';
      }

      if (!checkIsRegistryModalSelected(values.registryResultDisclosureModel) && isOtherTab) {
        errors.registryResultDisclosureModel = 'Please select the format';
      }
      if (isOtherDataEntryOption && !values.otherDataEntryOption?.trim()) {
        errors.otherDataEntryOption = 'Please enter description';
      }
      if (isOtherFormat && !values.otherFormatOfFiles?.trim()) {
        errors.otherFormatOfFiles = 'Please enter description';
      }
    }
    if (isOthersSelected) {
      if (!values.otherInformationSubmitted?.trim()) {
        errors.otherInformationSubmitted = 'Please enter other information';
      }
    }
  }
  return errors;
};

export const layoutSchema = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  style: {
    gap: '1rem',
  },
  content: [
    {
      layout: 'isClinicalTrialResults',
      size: 12,
    },
  ],
};

export const layoutSchemaOtherInfo = {
  layout: 'row',
  spacing: 2,
  alignItems: 'flex-start',
  justify: 'flex-start',
  style: {
    gap: '1rem',
  },
  className: 'otherInfo',
  content: [
    {
      layout: 'otherInformationSubmitted',
      size: 12,
    },
    {
      layout: 'registryDataEntryOptions',
      size: 5,
      className: '',
    },
    {
      layout: 'otherDataEntryOption',
      size: 7,
      className: '',
    },

    {
      layout: 'registryFormatOfFilesUploaded',
      size: 5,
      className: 'registryFormatOfFilesUploaded',
    },
    {
      layout: 'otherFormatOfFiles',
      size: 7,
      className: 'otherFormatOfFiles',
    },
    {
      layout: 'registryAddtionalInformation',
      size: 5,
      className: 'registryAddtionalInformation',
    },
    {
      layout: 'stepsResultsDisclosure',
      size: 7,
      className: 'stepsResultsDisclosure',
    },
  ],
};

export const formSchema = {
  isClinicalTrialResults: {
    label: 'Does the registry accept Clinical Trial results?',
    name: 'isClinicalTrialResults',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: true,
    },
  },
};

export const formSchemaOtherInfo = {
  otherInformationSubmitted: {
    label: "'Other' information submitted with results (or N/A)",
    name: 'otherInformationSubmitted',
    type: fieldTypes.textArea,
    props: {
      isMandatory: false,
      rowsMin: 2.4,
    },
  },
  registryDataEntryOptions: {
    label: 'Registry Data Entry Options (Check all that apply)',
    name: 'registryDataEntryOptions',
    type: fieldTypes.select_tag,
    props: {
      isMandatory: true,
    },
  },
  otherDataEntryOption: {
    label: "If Other - 'Other' data entry option/imported from another registry (or N/A)",
    name: 'otherDataEntryOption',
    type: fieldTypes.textArea,
    props: {
      isMandatory: false,
      rowsMin: 2.4,
    },
  },
  registryFormatOfFilesUploaded: {
    label: 'Format of file(s) uploaded or emailed',
    name: 'registryFormatOfFilesUploaded',
    type: fieldTypes.select_tag,
    props: {
      isMandatory: true,
      inputHeight: 'ht-lg',
    },
  },
  otherFormatOfFiles: {
    label: 'If other - Format of file(s) uploaded or emailed',
    name: 'otherFormatOfFiles',
    type: fieldTypes.textArea,
    props: {
      isMandatory: false,
      rowsMin: 2.4,
    },
  },
  registryAddtionalInformation: {
    label: 'Registry - additional available information',
    name: 'registryAddtionalInformation',
    type: fieldTypes.select_tag,
    props: {
      isMandatory: true,
    },
  },
  stepsResultsDisclosure: {
    label: 'Registry Steps for results disclosure',
    name: 'stepsResultsDisclosure',
    type: fieldTypes.textArea,
    props: {
      isMandatory: true,
      rowsMin: 2.4,
    },
  },
};

export const columnDefs = ({ handleChange, gridData, updateData, disableEdit }) => {
  return [
    {
      headerName: '',
      width: 400,
      headerClass: [],
      field: 'registryResultDisclose.isSelected',
      cellRenderer: 'checkbox',
      cellRendererParams: params => {
        const { api, rowIndex, value, data } = params;
        return {
          id: rowIndex.toString(),
          checked: value,
          value: '',
          label: data?.registryResultDisclose?.value,
          disabled: disableEdit,
          onChange: ({ isChecked }) => {
            const gridData = [];
            const reset = {};
            for (let index = 0; index < api.getDisplayedRowCount(); index++) {
              let rowData = api.getDisplayedRowAtIndex(index).data;
              rowData = {
                ...rowData,
                registryResultDisclose: {
                  ...rowData.registryResultDisclose,
                  isSelected:
                    index === rowIndex ? isChecked : rowData.registryResultDisclose.isSelected,
                },
                fullyorMostlyPubliclyAvailable:
                  index === rowIndex ? false : rowData.fullyorMostlyPubliclyAvailable,
                notPubliclyAvailable: index === rowIndex ? false : rowData.notPubliclyAvailable,
              };
              gridData.push(rowData);
            }
            const rowData = {
              ...data,
              registryResultDisclose: {
                ...data.registryResultDisclose,
                isSelected: isChecked,
              },
            };
            handleChange({ gridData, rowIndex, rowData });
          },
        };
      },
    },
    {
      headerName: 'FULLY OR MOSTLY PUBLICLY AVAILABLE',
      field: 'fullyorMostlyPubliclyAvailable',
      cellRenderer: 'radio',
      width: 200,
      // valueFormatter: params => isDataEmpty(params ? params.data.person.id : ''),
      cellRendererParams: ({ api, rowIndex, value, data }) => {
        const gridData = [];

        for (let index = 0; index < api.getDisplayedRowCount(); index += 1) {
          const rowInfo = api.getDisplayedRowAtIndex(index).data;
          const updatedRowData = {
            ...rowInfo,
            fullyorMostlyPubliclyAvailable:
              index === rowIndex
                ? data.registryResultDisclose.isSelected
                  ? true
                  : false
                : rowInfo.fullyorMostlyPubliclyAvailable,
            notPubliclyAvailable: index === rowIndex ? false : rowInfo.notPubliclyAvailable,
          };
          gridData.push(updatedRowData);
        }

        // handleChange({ gridData });

        return {
          disabled: data.isChildCopy || disableEdit,
          checked: false,
          onChange: event => {
            handleChange({ gridData });
          },
          name: 'radio-test1',
        };
      },
    },
    {
      headerName: 'NOT PUBLICLY AVAILABLE',
      field: 'notPubliclyAvailable',
      cellRenderer: 'radio',
      // valueFormatter: params => isDataEmpty(params ? params.data.role.id : ''),
      cellRendererParams: ({ api, rowIndex, value, data }) => {
        const gridData = [];
        for (let index = 0; index < api.getDisplayedRowCount(); index += 1) {
          const rowInfo = api.getDisplayedRowAtIndex(index).data;
          const updatedRowData = {
            ...rowInfo,
            fullyorMostlyPubliclyAvailable:
              index === rowIndex ? false : rowInfo.fullyorMostlyPubliclyAvailable,
            notPubliclyAvailable:
              index === rowIndex
                ? data.registryResultDisclose.isSelected
                  ? true
                  : false
                : rowInfo.notPubliclyAvailable,
          };
          gridData.push(updatedRowData);
        }
        return {
          disabled: data.isChildCopy || disableEdit,
          checked: false,
          onChange: () => {
            handleChange({ gridData });
          },
          name: 'radio-test2',
        };
      },
    },
  ];
};
