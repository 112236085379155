import { createAction } from '@reduxjs/toolkit';

//store action the selected side bar value
export const sideBarSelection = createAction('reporting/sidebar/selected');
export const setSelectedTabIndex = createAction('reporting/features/setSelectedTabIndex');
//store action for the sourceURL report
export const getSourceURLReport = createAction('reporting/features/getSourceURLReport');
export const getSourceURLReportSuccess = createAction(
  'reporting/features/getSourceURLReportSuccess',
);
//store action for the diseasing report
export const getDiseasingReport = createAction('reporting/features/getDiseasingReport');
export const getDiseasingReportSuccess = createAction(
  'reporting/features/getDiseasingReportSuccess',
);
//store action for the ageing report
export const getAgingReport = createAction('reporting/features/getAgingReport');
export const getAgingReportSuccess = createAction('reporting/features/getAgingReportSuccess');
