import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import Modal from '../../../../../../../framework/modal';
import { FormBuilder } from '../../../../../../../framework';
import { getGridRowIndex } from '../../../../../../../utils/generic/helper';
import {
  validate,
  registryEditFormSchema,
  registryEditLayoutSchema,
  isRegistryExists,
} from '../config';
import './index.scss';

const RegistryModal = ({ chemStructure, row: { rowIndex, rowData }, onClose }) => {
  const initialValues = {
    registries: rowData.cas.replace(/[^\w\s]/gi, ''),
  };
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmitValidationError = () => {};
  return (
    <Modal isOpen onClose={onClose}>
      <div className="registry-modal-chem">
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            const casFormattedValue =
              values.registries.substring(0, values.registries.length - 3) +
              '-' +
              values.registries.substring(
                values.registries.length - 3,
                values.registries.length - 1,
              ) +
              '-' +
              values.registries.substring(values.registries.length - 1);
            const updatedData = {
              id: rowData.id,
              cas: casFormattedValue,
            };
            const updatedRegistry = [...chemStructure.casRegistrys];
            const filteredIndex = getGridRowIndex({ rowIndex, rowData }, updatedRegistry);
            updatedRegistry.splice(filteredIndex, 1);
            if (isRegistryExists(updatedRegistry, updatedData)) {
              setErrorMessage(`${casFormattedValue} is already exists`);
            } else {
              setErrorMessage('');
              onClose({ rowIndex, rowData: updatedData, type: 'registry' });
            }
          }}
          validate={async values => validate(values, false, true)}
        >
          {props => {
            return (
              <FormBuilder
                formikProps={props}
                layoutSchema={registryEditLayoutSchema}
                formSchema={registryEditFormSchema}
                onSubmitValidationError={onSubmitValidationError}
              />
            );
          }}
        </Formik>
        {errorMessage && <p className="error-text">{errorMessage}</p>}
      </div>
    </Modal>
  );
};

RegistryModal.propTypes = {
  chemStructure: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RegistryModal;
