const features = [
  {
    name: 'DATA ENTRY',
    value: 'DataEntry',
    key: 'dataEntry',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    error: null,
    hasUnSavedData: false,
  },
  {
    name: 'SOURCE DOCUMENTS',
    value: 'SourceDocuments',
    key: 'sourceDocumentModel',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    error: null,
    hasUnSavedData: false,
  },
  {
    name: 'AUDIT TRAIL',
    value: 'AuditTrail',
    key: 'auditTrail',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
];

export default features;
