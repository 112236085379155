import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { FormBuilder, Modal } from '../../../../../../../framework';
import { getGridRowIndex } from '../../../../../../../utils/generic/helper';
import { validate, layoutSchema, isPatentDataExists, dateFormatter } from '../config';
import './index.scss';

const PatentModal = ({ chemStructure, formSchema, row: { rowIndex, rowData }, onClose }) => {
  const initialValues = {
    patentNumber: rowData.patentNumber,
    country: rowData.country
      ? {
          value: rowData.country.id,
          label: rowData.country.countryName,
        }
      : null,
    priorityCountry: rowData.priorityCountry
      ? {
          value: rowData.priorityCountry.id,
          label: rowData.priorityCountry.countryName,
        }
      : null,
    priorityDate: rowData.priorityDate ? new Date(rowData.priorityDate) : null,
  };

  const [errorMessage, setErrorMessage] = useState('');

  const updatedFormSchema = {
    ...formSchema,
    button: {
      ...formSchema.button,
      label: 'SAVE',
    },
  };

  return (
    <Modal isOpen onClose={onClose}>
      <div className="patent-modal-wrapper">
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            const updatedData = {
              id: rowData.id,
              patentNumber: values.patentNumber ? values.patentNumber.trim() : '',
              country: values.country
                ? {
                    id: values.country.value,
                    countryName: values.country.label,
                  }
                : null,
              priorityCountry: values.priorityCountry
                ? {
                    id: values.priorityCountry.value,
                    countryName: values.priorityCountry.label,
                  }
                : null,
              priorityDate: values.priorityDate
                ? dateFormatter(new Date(values.priorityDate))
                : null,
            };

            const updatedPatents = [...chemStructure.patents];
            const filteredIndex = getGridRowIndex({ rowIndex, rowData }, updatedPatents);
            updatedPatents.splice(filteredIndex, 1);
            if (isPatentDataExists(updatedPatents, updatedData)) {
              setErrorMessage('Combination already exists');
            } else {
              setErrorMessage('');
              onClose({ rowIndex, rowData: updatedData });
            }
          }}
          validate={async values => validate(values,{isMandatoryField:true})}
        >
          {props => {
            return (
              <FormBuilder
                formikProps={props}
                layoutSchema={layoutSchema}
                formSchema={updatedFormSchema}
                onSubmitValidationError={() => {}}
              />
            );
          }}
        </Formik>
        {errorMessage && <p className="error-text">{errorMessage}</p>}
      </div>
    </Modal>
  );
};

PatentModal.propTypes = {
  chemStructure: PropTypes.object.isRequired,
  formSchema: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PatentModal;
