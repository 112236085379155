import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { VALIDATION_REGEX } from '../../../../utils/generic/constants';
import { isValidDate } from '../../../../utils/generic/helper';

export const validate = values => {
  const errors = {};

  if (!isValidDate(values.nextFullReviewDate)) {
    errors.nextFullReviewDate = 'Please enter a valid date (MMM-dd-yyyy)';
  }
  if (values.name.trim() === '') {
    errors.name = 'Please enter organization name';
  }
  if (!values.organizationType) {
    errors.organizationType = 'Please select organization type';
  }
  if (values.name.trimEnd().length > 256) {
    errors.name = 'Organization name should be less than or equal to 256 characters';
  }
  return errors;
};

export const initialValues = {
  id: 0,
  name: '',
  organizationType: null,
  licenses: [],
  primaryInvestigators: [],
  parentOrganization: null,
};

export const layoutSchema = {
  layout: 'row',
  className: 'basic-info-container',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      spacing: 3,
      className: 'basic-info-wrapper',
      justify: 'flex-start',
      alignItems: 'flex-start',
      header: 'BASIC INFO',
      headerProps: {
        fontStyle: 'bold',
        size: '16',
      },
      content: [
        {
          layout: 'name',
          size: 9,
          className: '',
        },
        {
          layout: 'permanentlyClosed',
          size: 3,
          className: 'pclosed',
        },
        // {
        //   layout: 'organizationType',
        //   size: 2,
        //   className: '',
        // },
        // {
        //   layout: 'licenses',
        //   size: 5,
        //   className: '',
        // },
      ],
    },
    {
      layout: 'term1',
      size: 4,
      className: '',
    },
    {
      layout: 'term2',
      size: 4,
      className: '',
    },
    {
      layout: 'term3',
      size: 4,
      className: '',
    },

    {
      layout: 'row',
      size: 12,
      className: 'additional-info-wrapper',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'organizationType',
          size: 4,
          className: '',
        },
        {
          layout: 'licenses',
          size: 5,
          className: 'licenses',
        },
        {
          layout: 'synonyms',
          size: 3,
          className: 'synonyms',
        },
      ],
    },
    {
      layout: 'row',
      size: 12,
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'empty',
          size: 4,
          className: '',
        },
        {
          layout: 'healthSystemNetworkId',
          size: 6,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      size: 12,
      className: 'additional-info-wrapper',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      header: 'ADDITIONAL INFO',
      headerProps: {
        fontStyle: 'bold',
        size: '16',
      },
      content: [
        {
          layout: 'primaryInvestigators',
          size: 4,
          className: '',
        },
        {
          layout: 'parentOrganization',
          size: 6,
          className: '',
        },
        {
          layout: 'button',
          className: 'view-btn',
        },
      ],
    },
  ],
};

export const formSchema = {
  name: {
    name: 'name',
    type: fieldTypes.text,
    label: 'ORGANIZATION NAME',
    props: {
      inputHeight: 'ht-lg',
      isMandatory: true,
    },
  },
  permanentlyClosed: {
    name: 'permanentlyClosed',
    type: fieldTypes.checkbox,
    label: 'SITE STATUS: Permanently Closed',
    options: {
      id: 1,
      value: 'Yes',
    },
    props: {
      inputHeight: 'ht-lg',
      hideLabel: true,
    },
  },

  term1: {
    name: 'term1',
    type: fieldTypes.text,
    label: 'Term 1',
    props: {
      inputHeight: 'ht-lg',
    },
  },
  term2: {
    name: 'term2',
    type: fieldTypes.text,
    label: 'Term 2',
    props: {
      inputHeight: 'ht-lg',
    },
  },
  term3: {
    name: 'term3',
    type: fieldTypes.text,
    label: 'Term 3',
    props: {
      inputHeight: 'ht-lg',
    },
  },
  organizationType: {
    name: 'organizationType',
    type: fieldTypes.select,
    label: 'ORGANIZATION TYPE',
    props: {
      isMandatory: true,
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: false,
    },
  },
  licenses: {
    name: 'licenses',
    type: fieldTypes.comboEntry,
    label: 'LICENSE ID NUMBER',
    options: [],
    props: {
      height: 'ht-lg',
      selectProps: { label: 'LICENSE ID TYPE', isSearchable: true, isClearable: true },
      type: 'text',
      errorMessages: {
        select: 'Please select license type',
        entry: 'Please enter license ID number',
        duplicate: 'License data already exists',
      },
      validate: {
        rule: VALIDATION_REGEX.LICENSE,
        message: 'The number entered exceeds 10 digits',
      },
    },
  },
  synonyms: {
    name: 'synonyms',
    type: fieldTypes.entry,
    options: [],
    label: 'Synonyms',
    props: {
      height: 'ht-lg',
      errorMessages: {
        maxLength: 'The text entered exceeds 256 characters',
      },
      max: 256,
    },
  },
  empty: {},
  healthSystemNetworkId: {
    name: 'healthSystemNetworkId',
    type: fieldTypes.select_async,
    label: 'Health System/Network',
    props: {
      key: 'label',
      isSearchable: true,
      blurInputOnSelect: true,
      isClearable: true,
      placeholder: 'Search Organization',
    },
  },
  primaryInvestigators: {
    name: 'primaryInvestigators',
    type: fieldTypes.textArea,
    label: 'PRIMARY INVESTIGATORS',
    props: {
      rowsMin: 4,
      rowsMax: 10,
      cols: 80,
      placeholder: '',
      disabled: true,
    },
  },
  parentOrganization: {
    name: 'parentOrganization',
    type: fieldTypes.select_async,
    label: 'PARENT ORGANIZATION',
    props: {
      key: 'label',
      isSearchable: true,
      blurInputOnSelect: true,
      isClearable: true,
      placeholder: 'Search Organization',
    },
  },
  button: {
    name: 'button',
    type: fieldTypes.customButton,
    label: 'VIEW',
    props: {},
  },
};

const pOptions = [
  { id: 1, value: 'Yes' },
  { id: 0, value: 'No' },
];

export const pPostArr = val => {
  if (val === false) {
    return pOptions[1];
  } else if (val === true) {
    return pOptions[0];
  } else {
    return val;
  }
};
