export const columnDefs = (resetAndNavigateToTrial, handleTableHeaderClick) => [
  {
    headerName: 'TRIAL ID',
    field: 'trialId',
    width: 150,
    sortable: true,
    cellRenderer: 'Link',
    cellRendererParams: ({ data }) => {
      return {
        link: data.trialId ? data.trialId.toString() : '',
        onClick: resetAndNavigateToTrial,
      };
    },
    comparator: (...args) => {
      let isDescendingOrder = args[4]
      handleTableHeaderClick('trail_id', isDescendingOrder)
    }
  },
  {
    headerName: 'THERAPEUTIC AREA',
    headerClass: 'therapeuticAreaCompany',
    field: 'therapeuticAreas',
    width: 250,
    sortable: true,
    cellRenderer: 'ListView',
    cellRendererParams: ({ data }) => {
      return {
        list: data.therapeuticAreas,
      };
    },
  },
  {
    headerName: 'DISEASE TYPE',
    headerClass: 'diseaseTypesCompany',
    field: 'diseaseTypes',
    width: 300,
    sortable: true,
    cellRenderer: 'ListView',
    cellRendererParams: ({ data }) => {
      return {
        list: data.diseaseTypes,
      };
    },
  },
  {
    headerName: 'PHASE',
    headerClass: 'phaseCompany',
    field: 'phase',
    width: 150,
    sortable: true,
  },
  {
    headerName: 'STATUS',
    field: 'status',
    width: 150,
    sortable: true,
    comparator: (...args) => {
      let isDescendingOrder = args[4]
      handleTableHeaderClick('trail_status', isDescendingOrder)
    }
  },
];
