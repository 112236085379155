import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import omit from 'lodash/omit';

import { layoutSchema, validateModalForm } from '../config';
import { FormBuilder, Modal } from '../../../../../../../framework';
import { fieldTypes } from '../../../../../../../framework/formBuilder/utils';
import './index.scss';

const DrugLicensingAvailabilityModal = ({
  licenses,
  row: { rowIndex, rowData },
  onClose,
  formSchema,
  countries,
}) => {
  const initialValues = {
    ...rowData,
    country: { ...rowData.country, label: rowData.country.countryName, value: rowData.country.id },
  };

  const updatedFormSchema = {
    ...formSchema,
    button: {
      ...formSchema.button,
      label: 'SAVE',
    },
    country: {
      ...formSchema.country,
      type: fieldTypes.select,
      options: countries.map(country => ({
        ...country,
        label: country.countryName,
        value: country.id,
      })),
    },
  };

  const onSubmitValidationError = () => {};

  return (
    <Modal isOpen onClose={onClose}>
      <div className="drug-license-modal">
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            const updatedValues = {
              ...values,
              country: omit(values.country, ['label', 'value']),
            };
            onClose({ rowIndex, rowData: updatedValues });
          }}
          validate={async values => validateModalForm(values, licenses, rowIndex)}
        >
          {props => {
            return (
              <FormBuilder
                formikProps={props}
                layoutSchema={layoutSchema}
                formSchema={updatedFormSchema}
                onSubmitValidationError={onSubmitValidationError}
              />
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

DrugLicensingAvailabilityModal.propTypes = {
  licenses: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  formSchema: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
};

export default DrugLicensingAvailabilityModal;
