import { fieldTypes } from '../../../framework/formBuilder/utils';

import { compareAsc, format, isValid } from 'date-fns';
import { dateFormatter, isValidDate } from '../../../utils/generic/helper';

export const initialValues = {
  taskName: '',
  taskType: 'Ad-Hoc',
  taskStatus: { id: 1, label: 'Open', value: 1 },
  taskUrgency: '',
  assignee: [],
  tat: '',
  recurrenceFrequency: '',
  comments: '',
  createdDate: new Date(),
  dueDate: '',
  endDate: '',
};

export const validate = values => {
  let error = {};
  if (!values.taskName) {
    error.taskName = 'Task Name is Mandatory';
  }
  if (!values.taskStatus) {
    error.taskStatus = 'Task Status is Mandatory';
  }
  if (!values.taskUrgency) {
    error.taskUrgency = 'Task Urgency is Mandatory';
  }
  if (values.assignee.length === 0) {
    error.assignee = 'Assignee is Mandatory';
  }
  if (!values.tat) {
    error.tat = 'TAT is Mandatory';
  }
  if (!values.recurrenceFrequency) {
    error.recurrenceFrequency = 'Recurrence Frequency is Mandatory';
  }
  if (values.taskStatus.label === 'Closed' && !values.comments) {
    error.comments = 'Comment is required if task status is closed!';
  }
  if (!values.createdDate) {
    error.createdDate = 'Created Date is Mandatory';
  }
  if (!values.dueDate) {
    error.dueDate = 'Due Date is Mandatory';
  }
  if (values.dueDate && !isValidDate(values.dueDate)) {
    error.dueDate = 'Please enter a valid date (MMM-dd-yyyy)';
  }

  if (
    values.dueDate &&
    isValidDate(values.dueDate) &&
    new Date(dateFormatter(new Date())).getTime() >
      new Date(dateFormatter(new Date(values.dueDate))).getTime()
  ) {
    error.dueDate = "Due Date can't be a past date.";
  }
  if (values.endDate && !isValidDate(values.endDate)) {
    error.endDate = 'Please enter a valid date (MMM-dd-yyyy)';
  }
  let createdDate = values.createdDate ? dateFormatter(values.createdDate) : null;
  let endDate =
    values.endDate && isValidDate(values.endDate) ? dateFormatter(values.endDate) : null;
  let dueDate =
    values.dueDate && isValidDate(values.dueDate) ? dateFormatter(values.dueDate) : null;
  if (
    values.dueDate &&
    values.endDate &&
    isValidDate(values.dueDate) &&
    isValidDate(values.endDate)
  ) {
    let compare = compareAsc(new Date(dueDate), new Date(endDate));
    if (compare == 1) {
      error.endDate = 'End Date is lesser than Due Date';
    }
  }
  if (createdDate === endDate) {
    error.endDate = 'End date should be greater than Created Date';
  }
  return error;
};

export const layoutSchema = {
  layout: 'row',
  className: 'ad-hoc-task-modal',
  spacing: 2,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'taskName',
      size: 4,
      className: 'task-name',
    },
    {
      layout: 'taskType',
      size: 4,
      className: '',
    },
    {
      layout: 'taskStatus',
      size: 4,
      className: '',
    },
    {
      layout: 'taskUrgency',
      size: 4,
      className: '',
    },
    {
      layout: 'assignee',
      size: 4,
      className: '',
    },
    {
      layout: 'tat',
      size: 2,
      className: '',
    },
    {
      layout: 'recurrenceFrequency',
      size: 4,
      className: '',
    },
    {
      layout: 'comments',
      size: 4,
      className: '',
    },
    {
      layout: 'row',
      size: 12,
      className: 'task-date-row',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'createdDate',
          size: 2,
          className: '',
        },
        {
          layout: 'dueDate',
          size: 2,
          className: '',
        },
        {
          layout: 'endDate',
          size: 2,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      size: 12,
      className: 'cancel-submit-btns',
      spacing: 3,
      justify: 'flex-end',
      alignItems: 'flex-end',
      content: [
        {
          layout: 'cancel',
          size: 2,
          className: '',
        },
        {
          layout: 'submit',
          size: 2,
          className: 'submit-btn',
        },
      ],
    },
  ],
};

export const formSchema = {
  taskName: {
    label: 'TASK NAME -',
    labelSuffix: '(Select or Type to Create)',
    name: 'taskName',
    type: fieldTypes.text,
    props: {
      isMandatory: true,
      inputHeight: 'ht-lg',
    },
  },
  taskType: {
    label: 'Task Type',
    name: 'taskType',
    type: fieldTypes.select,
    options: [],
    props: {
      height: 'ht-lg',
    },
  },
  taskStatus: {
    label: 'Task Status',
    name: 'taskStatus',
    type: fieldTypes.select,
    options: [],
    props: {
      isMandatory: true,
      height: 'ht-lg',
    },
  },
  taskUrgency: {
    label: 'Task Urgency',
    name: 'taskUrgency',
    type: fieldTypes.select,
    options: [],
    props: {
      isMandatory: true,
      height: 'ht-lg',
    },
  },
  assignee: {
    label: 'ASSIGNEE -',
    labelSuffix: '(team or user)',
    name: 'assignee',
    type: fieldTypes.selectGroup,
    options: [],
    props: {
      isMandatory: true,
      isMulti: true,
      isSearchable: true,
      allowMultipleSelections: true,
    },
  },
  tat: {
    label: 'TAT',
    name: 'tat',
    type: fieldTypes.select,
    options: [],
    props: {
      isMandatory: true,
      height: 'ht-lg',
    },
    dependent: 'dueDate',
  },
  recurrenceFrequency: {
    label: 'Recurrence Frequency',
    name: 'recurrenceFrequency',
    type: fieldTypes.select,
    options: [],
    props: {
      isMandatory: true,
      height: 'ht-lg',
    },
  },
  comments: {
    label: 'Comments',
    name: 'comments',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2,
      cols: 10,
    },
  },
  createdDate: {
    label: 'Created Date',
    name: 'createdDate',
    type: fieldTypes.customDate,
    props: {
      isMandatory: true,
      disabled: true,
    },
  },
  dueDate: {
    label: 'Due Date',
    name: 'dueDate',
    type: fieldTypes.customDate,
    props: {
      isMandatory: true,
      disablePast: true,
    },
    dependent: 'tat',
  },
  endDate: {
    label: 'End Date',
    name: 'endDate',
    type: fieldTypes.customDate,
    props: {
      disablePast: true,
    },
  },
  cancel: {
    label: 'Cancel',
    name: 'cancel',
    type: fieldTypes.customButton,
    props: {
      buttonType: 'secondary-btn',
    },
  },
  submit: {
    label: 'Submit',
    name: 'submit',
    type: fieldTypes.button,
    props: {
      buttonType: 'primary-btn',
    },
  },
};
