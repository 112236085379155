export const personDetailsState = {
  id: 0,
  ingestedId: 0,
  updateType: 0,
  personBasicInfo: {
    addressId: '',
    id: '',
    name: '',
    personType: null,
    firstName: '',
    lastName: '',
    middleName: '',
    nameSuffix: '',
    specialities: [],
    degrees: [],
    licenses: [],
    parentOrganization: null,
    address: '',
    phone: '',
    fax: '',
    email: '',
    phoneNumbers: [],
    faxNumbers: [],
    emails: [],
    recordDates: {
      lastFullReviewDate: null,
      lastFullReviewBy: '',
      nextReviewDate: null,
      createdBy: '',
      createdDate: null,
      modifiedBy: '',
      modifiedDate: null,
    },
    supportingUrls: [],
  },
  personAdditionalInfo: {
    regulatoryActions: [],
  },

  notes: [],
  prodStatusID: 0,
};
