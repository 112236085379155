import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { nameTypeIds } from '../../../../utils/drugs/constants';
import {
  getClassNameForIngestion,
  checkForIngestionAction,
} from '../../../../utils/generic/helper';

export const validationMessages = {
  duplicateDescriptiveName: 'Descriptive Name already exists',
  innNameAlreadyExists: 'INN name already exists for this record',
  duplicateInnName: 'Duplicate INN name exists',
  duplicateLabCodes: 'One of the Lab Code format already exists',
  primaryDelete:
    'You are about to delete the row which contains Primary name. Please select another Primary Name before deleting',
  primaryNameNotExists: 'Please select the Primary Name',
  recordNotExists: 'Please add atleast one primary name',
  labcodeFormatsNotValid: 'Please add all 3 formats for the lab code',
  campanionDiagonaticsError:
    'You cannot change the Descriptive Name. Please change the related therapeutic class before changing Descriptive Name',
};

export const getOriginalName = name => {
  const originalName = name.replace(/ /g, '');
  return originalName.replace(/-/g, '');
};

const companionDiagonaticsExists = (originalNames, activity) => {
  let isCDExists = false;
  let isTCExists = false;

  if (activity.therapeuticClasses && activity.therapeuticClasses.length > 0) {
    isTCExists =
      activity.therapeuticClasses.filter(tc =>
        tc.therapeuticClass.description.toLowerCase().includes('companion diagnostic'),
      ).length > 0;
  }

  if (originalNames && originalNames.length > 0) {
    isCDExists =
      originalNames.filter(name =>
        name.descriptiveName.toLowerCase().includes('companion diagnostic'),
      ).length > 0;
  }
  return isCDExists && isTCExists;
};

export const validateDrugName = (names, { names: originalNames, activity }, isSave = true) => {
  let isCDExists = false;
  let areLabCodesValid = true;

  names.forEach(drug => {
    const filter = [...names]?.filter(
      name =>
        getOriginalName(name.descriptiveName) === getOriginalName(drug.descriptiveName) &&
        ((name.nameType) ? name?.nameType?.id === drug?.nameType?.id : false),
    );
    areLabCodesValid = areLabCodesValid
      ? filter.length > 0 && filter.length === 3
      : areLabCodesValid;
    isCDExists = isCDExists || drug.descriptiveName.toLowerCase().includes('companion diagnostic');
  });

  const isPrimaryNotAvailable = names.filter(name => name.isPrimary).length === 0;
  const innNameDuplicates = names.filter(name => (name.nameType) ? name.nameType.id === nameTypeIds.innName : false);

  if (names.length === 0) {
    return { isError: true, message: validationMessages.recordNotExists };
  } else if (isPrimaryNotAvailable && names.length > 0) {
    return { isError: true, message: validationMessages.primaryNameNotExists };
  } else if (innNameDuplicates.length > 1) {
    return { isError: true, message: validationMessages.duplicateInnName };
  } else if (isSave && companionDiagonaticsExists(originalNames, activity) && !isCDExists) {
    return { isError: false, message: validationMessages.campanionDiagonaticsError };
  }
  return {};
};

export const validate = (values, drugNames) => {
  const errors = {};
  const duplicates = drugNames.filter(
    name => name.descriptiveName.toLowerCase() === values.descriptiveName.toLowerCase(),
  );
  const innNameDuplicates = drugNames.filter(name => (name.nameType) ? name.nameType.id === nameTypeIds.innName : false);

  if (!values.descriptiveName.trim()) {
    errors.descriptiveName = 'Please enter the Descriptive Name';
  } else if (
    values.nameType === nameTypeIds.tradeName &&
    values.descriptiveName &&
    !/^[A-Z]/.test(values.descriptiveName)
  ) {
    errors.descriptiveName = 'Trade name is not starting with capital letter. Please verify';
  } else if (duplicates.length > 0) {
    errors.descriptiveName = validationMessages.duplicateDescriptiveName;
  } else if (innNameDuplicates.length > 0 && values.nameType === nameTypeIds.innName) {
    errors.descriptiveName = validationMessages.innNameAlreadyExists;
  }

  return errors;
};

export const initialValues = {
  id: 0,
  descriptiveName: '',
  // nameTypeId: nameTypeIds.innName,
  nameType: 0,
  isPrimary: false,
  isChildCopy: false,
};

export const layoutSchema = {
  layout: 'row',
  className: 'form-wrapper',
  spacing: 3,
  justify: 'flex-end',
  alignItems: 'flex-end',
  content: [
    {
      layout: 'row',
      size: 12,
      className: '',
      spacing: 4,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'descriptiveName',
          size: 4,
          className: 'descriptive-name',
        },
        {
          layout: 'nameType',
          size: 7,
          className: 'name-type',
        },
        {
          layout: 'button',
          size: 1,
          className: 'add-button',
        },
      ],
    },
  ],
};

export const formSchema = {
  descriptiveName: {
    name: 'descriptiveName',
    type: fieldTypes.text,
    label: 'DESCRIPTIVE NAME',
    options: [],
    props: {
      isMandatory: true,
      inputHeight: 'ht-lg',
    },
  },
  nameType: {
    name: 'nameType',
    type: fieldTypes.custom_radio,
    label: 'NAME TYPE',
    options: [],
    props: {
      height: 'ht-lg',
      isMandatory: false,
      dependent: { key: 'nameType', value: 'display' },
      // disabled as per 11700, needs to be removed in future
      disabled: true
    },
  },
  button: {
    name: 'button',
    type: fieldTypes.button,
    label: '+ ADD',
  },
};

// ag-grid configuration
export const columnDefs = ({
  handleChange,
  handleDelete,
  handleEdit,
  handleIngestionActions,
  disableEdit,
  names,
}) => [
    {
      headerName: 'DESCRIPTIVE NAMES',
      field: 'descriptiveName',
      // cellClass: ['ag-descriptive-name'],
      cellClass: ({ data }) => [
        'ag-descriptive-name',
        getClassNameForIngestion(data.ingestionAction),
      ],
      width: 350,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      cellRendererParams: params => {
        const {
          data: { isChildCopy }
        } = params;
        return {
          customClassName: isChildCopy && 'level-1',
        };
      },
    },
    {
      headerName: 'NAME TYPE',
      field: 'nameType.value',
      width: 200,
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    },
    {
      headerName: 'PRIMARY NAME',
      field: 'isPrimary',
      width: 170,
      cellRenderer: 'checkBox',
      cellRendererParams: ({ api, rowIndex, value, data }) => {
        const gridData = [];
        for (let index = 0; index < api.getDisplayedRowCount(); index += 1) {
          const rowInfo = api.getDisplayedRowAtIndex(index).data;
          const updatedRowData = {
            ...rowInfo,
            isPrimary: rowIndex === index,
          };
          gridData.push(updatedRowData);
        }
        return {
          disabled: data.isChildCopy,
          checked: value,
          onChange: () => {
            handleChange(gridData);
          },
          name: 'radio-test',
        };
      },
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { rowIndex, data } = params;
        return {
          canDisableEdit: data.isChildCopy || disableEdit || data.isDisabled,
          handleEdit: () => {
            handleEdit(data, rowIndex);
          },
        };
      },
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { rowIndex, data } = params;
        return {
          canDisableDelete: data.isChildCopy || disableEdit || data.isDisabled,
          handleDelete: () => {
            handleDelete(data, rowIndex);
          },
        };
      },
    },
    {
      headerName: '',
      minWidth: 100,
      maxWidth: 100,
      field: '',
      hide: !checkForIngestionAction(names, true),
      cellRenderer: 'ingestionActions',
      cellRendererParams: ({ data: rowData }) => {
        return {
          isDisabled: disableEdit,
          ingestionAction: rowData ? rowData.ingestionAction : 'none',
          handleIngestionActions: option => handleIngestionActions(option, rowData),
        };
      },
    },
  ];
