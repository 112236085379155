import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { InputTextAreaAdvanced } from '../../../../framework/inputs';
import TextArea from '../../../../framework/inputs/textArea';
import FeatureFooter from '../../../../framework/featureFooter';
import {
  resetDrugDetailsWithKey,
  postDrugsData,
  updateDrugChildDetails,
  updateDrugIngestedAction,
} from '../../../../store/actions/drugs';
import './index.scss';
import {
  checkForIngestionAction,
  formatDataForTextArea,
  updateIngestedDataBasedOnFieldType,
  validateIngestionAction,
  isDataEqual,
  checkUserIngestionAction,
  htmlencode
} from '../../../../utils/generic/helper';
import { showModal } from '../../../../store/actions/globalActions';
import { MODAL_MESSAGES } from '../../../../utils/generic/constants';
import ClearConfirmation from '../../../../generic/modals/clearConfirmation';

const TabSingleTextWrapper = forwardRef(
  (
    {
      disableEdit,
      footerData,
      data,
      originalData,
      parentKey,
      childKey,
      drugId,
      ingestedId,
      ingestedData,
      ingestionAction,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const handleChange = value =>
      dispatch(updateDrugChildDetails({ parentKey, childKey, data: value }));
    let updatedText = data[childKey] || '';
    const updatedIngestedText = ingestedData || '';

    if (checkForIngestionAction(ingestionAction)) {
      updatedText = formatDataForTextArea(updatedText, updatedIngestedText);
    }
    const [inputValue, setInputValue] = useState(updatedText);
    useImperativeHandle(ref, () => ({
      isChanged,
      validate: () =>
        !footerData.tabs[footerData.selectedTabIndex].hasIngestedData ||
        validateIngestionAction({
          ingestedActions: { [childKey]: ingestionAction },
          data: ingestionAction,
          type: 'drugs',
        }).isValid,
    }));

    const isChanged = () => {
      return footerData.tabs[footerData.selectedTabIndex].hasIngestedData
        ? checkUserIngestionAction({ [childKey]: ingestionAction }, { [childKey]: ingestionAction })
        : !isDataEqual(data[childKey] || '', originalData[childKey] || '');
    };

    const [clearModal, setClearModal] = useState(false);

    const resetFieldValue = () => {
      setClearModal(true);
    };

    const proceedToClear = status => {
      if (status) {
        dispatch(resetDrugDetailsWithKey({ childKey, parentKey }));
      } else {
        setClearModal(false);
      }
    };

    let forceToFieldUpdate = 10;
    switch (childKey) {
      case 'agreement':
        forceToFieldUpdate = 13;
        break;
      case 'availability':
        forceToFieldUpdate = 14;
        break;
      case 'approvals':
        forceToFieldUpdate = 9;
        break;
      case 'fillings':
        forceToFieldUpdate = 17;
        break;
      case 'orphanDrugStatus':
        forceToFieldUpdate = 18;
        break;
      case 'expeditedReview':
        forceToFieldUpdate = 19;
        break;
      case 'warnings':
        forceToFieldUpdate = 20;
        break;
      case 'inVivo':
        forceToFieldUpdate = 10;
        break;
      case 'inVitro':
        forceToFieldUpdate = 21;
        break;
      case 'patents':
        forceToFieldUpdate = 22;
        break;
      case 'phaseI':
        forceToFieldUpdate = 23;
        break;
      case 'phaseII':
        forceToFieldUpdate = 24;
        break;
      case 'phaseIII':
        forceToFieldUpdate = 25;
        break;
      default:
        break;
    }

    const handleIngestionActions = (option, type) => {
      let ingested = ingestedData || '';
      let original = originalData[childKey] || '';
      let current = data[childKey] || '';

      let updatedData = updateIngestedDataBasedOnFieldType({
        type,
        original,
        ingestionAction: option,
        current,
        ingested,
      });

      dispatch(updateDrugChildDetails({ parentKey, childKey, data: updatedData }));
      dispatch(
        updateDrugIngestedAction({
          parentKey,
          childKey,
          ingestionAction: option,
        }),
      );
    };

    const handleSave = tabData => {
      const updatedActions = { [childKey]: ingestionAction };
      const { isValid, ingestedContentResults } = validateIngestionAction({
        ingestedActions: updatedActions,
        data: {},
        type: 'drugs',
      });
      if (isValid) {
        dispatch(
          postDrugsData({
            id: drugId,
            body: {
              [parentKey]: {
                ...originalData,
                [childKey]: data[childKey],
              },
              forceToFieldUpdate,
              drugType: {
                id: 2,
                value: 'Pharmaprojects Drug',
              },
              ingestedContentResults,
              ingestedId,
            },
            tabData,
            childTabKey: [childKey, 'id'],
          }),
        );
      } else {
        dispatch(showModal(MODAL_MESSAGES.INGESTION_ERROR));
      }
    };

    return (
      <div className="single-text-wrapper">
        <Grid container direction="column" alignItems="flex-start" spacing={3}>
          <FeatureFooter
            handleSaveAndNext={() => handleSave(footerData)}
            handleSave={() => handleSave(null)}
            handleClear={resetFieldValue}
            disabled={disableEdit}
          />
          <Grid item xs={10}>
            {/* <InputTextAreaAdvanced
              rowsMin={20}
              rowsMax={25}
              placeholder="Add text context here"
              isIngestionActionDisabled={disableEdit}
              isDisabled={disableEdit || checkForIngestionAction(ingestionAction)}
              text={updatedText}
              onBlur={e => handleChange(htmlencode(e))}
              ingestionAction={ingestionAction}
              handleIngestionActions={option => handleIngestionActions(option, 'string')}
            /> */}
            <TextArea
              rowsMin={20}
              rowsMax={25}
              placeholder="Add text context here"
              isIngestionActionDisabled={disableEdit}
              isDisabled={disableEdit || checkForIngestionAction(ingestionAction)}
              value={inputValue}
              customBigHeight={true}
              onChange={e => setInputValue(e.target.value)}
              onBlur={e => handleChange(e)}
              ingestionAction={ingestionAction}
              handleIngestionActions={option => handleIngestionActions(option, 'string')}
            />
          </Grid>
        </Grid>
        {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
      </div>
    );
  },
);

TabSingleTextWrapper.propTypes = {
  disableEdit: PropTypes.bool,
  footerData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isTab: PropTypes.bool.isRequired,
    tabs: PropTypes.array.isRequired,
    hasIngestedData: PropTypes.bool.isRequired,
    selectedTabIndex: PropTypes.number.isRequired,
  }),
  data: PropTypes.object,
  originalData: PropTypes.object,
  parentKey: PropTypes.string.isRequired,
  childKey: PropTypes.string.isRequired,
  drugId: PropTypes.number.isRequired,
  ingestedId: PropTypes.number.isRequired,
  ingestionAction: PropTypes.string,
  ingestedData: PropTypes.string,
  parentIngestionAction: PropTypes.object,
};

TabSingleTextWrapper.defaultProps = {
  disableEdit: false,
  ingestionAction: 'none',
  ingestedData: '',
};

export default TabSingleTextWrapper;
