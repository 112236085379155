import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { isEmpty, isDate } from 'lodash';
import Grid from '@material-ui/core/Grid';

import { FormBuilder, FeatureFooter, TimeStamp } from '../../../../framework';
import { InputLabel } from '../../../../framework/inputs';
import {
  updateCompanyDetails,
  saveCompanyDetails,
  resetCompanyData,
  updateCompanyIngestedActions,
} from '../../../../store/actions/company';
import {
  mapFormDataToStore,
  getBasicAndContactInfoRequest,
} from '../../../../store/helpers/company';
import { showModal } from '../../../../store/actions/globalActions';
import {
  convertDataForSelectDropdown,
  convertObjectForSelectDropdown,
  checkForIngestionAction,
  updateIngestedDataBasedOnFieldType,
  validateIngestionAction,
  formatDataForTextArea,
  regexToCheckForHtmlString,
  searchCountry,
  searchState,
  isValidDate,
  isDataEqual,
  checkUserIngestionAction,
  checkIfObjectIsEmpty,
  htmlencode,
} from '../../../../utils/generic/helper';
import ClearConfirmation from '../../../../generic/modals/clearConfirmation';
import ConfirmationMessage from '../../../../generic/modals/ConfirmationMessage';
import {
  MODAL_TYPES,
  MODAL_MESSAGES,
  childFormChangeObject,
} from '../../../../utils/generic/constants';
import { onFilteringCompany } from '../../../../utils/company/helper';
import {
  initialValues,
  layoutSchema,
  formSchema,
  validateBasicAndContactInfoDetails,
} from './config';
import './index.scss';

const BasicAndContactInfo = forwardRef(({ data, permissions: { disableEdit } }, ref) => {
  const dispatch = useDispatch();
  const company = useSelector(state => state.company, shallowEqual);
  const {
    original: { basicAndContactInfo: originalBasicAndContactInfo },
    current: {
      id,
      ingestedId,
      basicAndContactInfo,
      basicAndContactInfo: {
        companyName,
        ownership,
        employees,
        establishedYear,
        parentCompany,
        relatedCompanyType,
        yearAcquired,
        website,
        phone,
        fax,
        companySummary,
        street1,
        street2,
        country,
        state,
        city,
        postalCode,
        recordDates,
      },
      prodStatusID,
      ingestedProductionStatusId,
    },
    ingested: {
      basicAndContactInfo: { data: ingestedData, fieldActions: ingestedActions },
    },
    recordStartDateTime,
    masterData: { relatedCompanyTypes, ownerships },
  } = company;
  const formRef = useRef(null);
  const basicAndContactInfoRef = useRef(null);
  const updatedBasicAndContactInfoDetails = useRef({
    data: {},
    ingestedContentResults: [],
    tabData: null,
  });

  const phoneFormRef = useRef(childFormChangeObject);
  const faxFormRef = useRef(childFormChangeObject);

  const [clearModal, setClearModal] = useState(false);
  const [selectStateAlert, showSelectStateAlert] = useState(false);

  const updateIngestionOptionsForState = updatedStateValue => {
    if (!isEmpty(ingestedActions) && ingestedActions.state) {
      dispatch(
        updateCompanyIngestedActions({
          parentKey: 'basicAndContactInfo',
          childKey: 'state',
          ingestionAction: updatedStateValue ? ingestedActions.state : 'default',
        }),
      );
    }
  };

  useEffect(() => {
    basicAndContactInfoRef.current = { ...basicAndContactInfo };
  }, [basicAndContactInfo]);

  useEffect(() => {
    if (
      formRef.current &&
      ingestedData &&
      ingestedActions &&
      checkForIngestionAction(ingestedActions['companySummary'])
    ) {
      formRef.current.setFieldValue(
        'companySummary',
        formatDataForTextArea(companySummary, ingestedData['companySummary']),
      );
    }
  }, [companySummary, ingestedData, ingestedActions]);

  const getBasicAndContactInfoDetails = values => {
    return basicAndContactInfoRef.current
      ? {
        ...basicAndContactInfoRef.current,
        ...mapFormDataToStore(values),
        companySummary: regexToCheckForHtmlString.test(values.companySummary)
          ? basicAndContactInfoRef.current.companySummary
          : values.companySummary,
      }
      : {};
  };

  useEffect(() => {
    if (data.hasError && !data.hasIngestedData) {
      handleSave(null);
    }

    return () => {
      if (formRef.current) {
        dispatch(
          updateCompanyDetails({
            key: 'basicAndContactInfo',
            data: getBasicAndContactInfoDetails(formRef.current.values),
          }),
        );
      }
    };
  }, []);

  useImperativeHandle(ref, () => ({
    isChanged,
    validate: () => {
      if (formRef.current) {
        if (data.hasIngestedData) {
          return validateIngestionAction({
            ingestedActions,
            data: formRef.current.values,
            type: 'company',
          }).isValid;
        }
        if (
          !checkIfObjectIsEmpty(
            validateBasicAndContactInfoDetails(
              formRef.current.values,
              recordDates ? recordDates.nextReviewDate : '',
              prodStatusID,
            ),
          )
        ) {
          handleSave();
          return false;
        }
      }
      return true;
    },
  }));

  const isChanged = () => {
    if (data.hasIngestedData) {
      return checkUserIngestionAction(formRef.current.values, ingestedActions);
    }
    return (
      phoneFormRef.current.isChanged() ||
      faxFormRef.current.isChanged() ||
      !isDataEqual(
        getBasicAndContactInfoDetails(formRef.current.values),
        originalBasicAndContactInfo,
      )
    );
  };

  const handleCountryChange = () => {
    updateIngestionOptionsForState();
    formRef.current.setFieldValue('state', null);
  };

  const searchParentCompany = async value => {
    const filteredData = await onFilteringCompany(value, id);
    if (!filteredData) {
      dispatch(
        showModal({
          isOpen: true,
          message: 'There was a problem with the server',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    return filteredData;
  };

  const filterCountry = async value => {
    const filteredData = await searchCountry(value);
    if (!filteredData) {
      dispatch(showModal(MODAL_MESSAGES.API_ERROR));
    }
    return filteredData;
  };
  const filterState = async value => {
    const selectedCountry = formRef.current && formRef.current.values.country;
    let filteredData = [];
    if (selectedCountry && selectedCountry.value) {
      filteredData = await searchState(value, selectedCountry.value);
      if (!filteredData) {
        dispatch(showModal(MODAL_MESSAGES.API_ERROR));
      }
    }
    return filteredData;
  };

  const handleIngestionActions = ({ option, ingested, key, type }) => {
    if (formRef.current) {
      const { values, setFieldValue } = formRef.current;
      let updatedData = null;
      if (type === 'array') {
        updatedData = updateIngestedDataBasedOnFieldType({
          type,
          ingestionAction: option,
          current: values[key],
          ingested,
          childKey: 'text',
        });
        updatedFormSchema = {
          ...updatedFormSchema,
          [key]: {
            ...updatedFormSchema[key],
            props: {
              ...updatedFormSchema[key].props,
              disabled: checkForIngestionAction(updatedData),
            },
          },
        };
      } else {
        updatedData = updateIngestedDataBasedOnFieldType({
          type,
          original: originalBasicAndContactInfo[key],
          ingestionAction: option,
          current: basicAndContactInfo[key],
          ingested: ingestedData[key],
        });
        dispatch(
          updateCompanyIngestedActions({
            parentKey: 'basicAndContactInfo',
            childKey: key,
            ingestionAction: option,
          }),
        );
      }
      setFieldValue(key, updatedData);
    }
  };

  const getIngestionProps = ({ key, type, disableKey = 'isDisabled' }) => {
    if (type === 'array') {
      return {
        handleIngestionActions: (option, ingested) =>
          handleIngestionActions({ option, ingested, key, type }),
        [disableKey]: checkForIngestionAction(
          (formRef.current && formRef.current.values[key]) || [],
        ),
        isIngestionActionDisabled: disableEdit,
      };
    } else {
      return {
        ingestionAction: ingestedActions[key],
        ingestedData: ingestedData[key],
        handleIngestionActions: option => handleIngestionActions({ option, key, type }),
        [disableKey]: checkForIngestionAction(ingestedActions[key]),
        isIngestionActionDisabled: disableEdit,
      };
    }
  };

  const getFormattedDate = (date) => {
    if (!date) {
      return null
    } else if (isDate(yearAcquired)) {
      return yearAcquired
    } else if (yearAcquired) {
      return new Date(yearAcquired, 1, 1)
    } else {
      return null
    }
  }

  let updatedFormSchema = {
    ...formSchema({ handleCountryChange, searchParentCompany, filterCountry, filterState }),
  };
  updatedFormSchema = {
    ...updatedFormSchema,
    relatedCompanyType: {
      ...updatedFormSchema.relatedCompanyType,
      options: convertDataForSelectDropdown([...relatedCompanyTypes], 'id', 'value'),
      props: {
        ...updatedFormSchema.relatedCompanyType.props,
        ...getIngestionProps({ key: 'relatedCompanyType', type: 'object' }),
        isDisabled: isEmpty(parentCompany),
        isMandatory: !isEmpty(parentCompany),
      },
    },
    yearAcquired: {
      ...updatedFormSchema.yearAcquired,
      props: {
        ...updatedFormSchema.yearAcquired.props,
        ...getIngestionProps({ key: 'yearAcquired', type: 'date', disableKey: 'disabled' }),
        disabled: isEmpty(parentCompany),
      },
    },
    ownership: {
      ...updatedFormSchema.ownership,
      options: convertDataForSelectDropdown([...ownerships], 'id', 'value'),
      props: {
        ...updatedFormSchema.ownership.props,
        ...getIngestionProps({ key: 'ownership', type: 'object' }),
      },
    },

    establishedYear: {
      ...updatedFormSchema.establishedYear,
      props: {
        ...updatedFormSchema.establishedYear.props,
        ...getIngestionProps({ key: 'establishedYear', type: 'date', disableKey: 'disabled' }),
      },
    },
    parentCompany: {
      ...updatedFormSchema.parentCompany,
      props: {
        ...updatedFormSchema.parentCompany.props,
        ...getIngestionProps({ key: 'parentCompany', type: 'object' }),
        isDisabled: true,
      },
    },
    website: {
      ...updatedFormSchema.website,
      props: {
        ...updatedFormSchema.website.props,
        ...getIngestionProps({ key: 'website', type: 'text', disableKey: 'disabled' }),
      },
    },
    phone: {
      ...updatedFormSchema.phone,
      props: {
        ...updatedFormSchema.phone.props,
        ...getIngestionProps({ key: 'phone', type: 'array', disableKey: 'disabled' }),
        ref: phoneFormRef,
      },
    },
    fax: {
      ...updatedFormSchema.fax,
      props: {
        ...updatedFormSchema.fax.props,
        ...getIngestionProps({ key: 'fax', type: 'array', disableKey: 'disabled' }),
        ref: faxFormRef,
      },
    },
    companySummary: {
      ...updatedFormSchema.companySummary,
      props: {
        ...updatedFormSchema.companySummary.props,
        ...getIngestionProps({ key: 'companySummary', type: 'string' }),
        isMandatory: prodStatusID === 1,
        onBlur: htmlencode
      },
    },
    street1: {
      ...updatedFormSchema.street1,
      props: {
        ...updatedFormSchema.street1.props,
        ...getIngestionProps({ key: 'street1', type: 'text', disableKey: 'disabled' }),
      },
    },
    street2: {
      ...updatedFormSchema.street2,
      props: {
        ...updatedFormSchema.street2.props,
        ...getIngestionProps({ key: 'street2', type: 'text', disableKey: 'disabled' }),
      },
    },
    country: {
      ...updatedFormSchema.country,
      props: {
        ...updatedFormSchema.country.props,
        ...getIngestionProps({ key: 'country', type: 'object' }),
        isDisabled: true,
      },
    },
    state: {
      ...updatedFormSchema.state,
      props: {
        ...updatedFormSchema.state.props,
        ...getIngestionProps({ key: 'state', type: 'text', disableKey: 'disabled' }),
      },
    },
    city: {
      ...updatedFormSchema.city,
      props: {
        ...updatedFormSchema.city.props,
        ...getIngestionProps({ key: 'city', type: 'text', disableKey: 'disabled' }),
      },
    },
    postalCode: {
      ...updatedFormSchema.postalCode,
      props: {
        ...updatedFormSchema.postalCode.props,
        ...getIngestionProps({ key: 'postalCode', type: 'text', disableKey: 'disabled' }),
      },
    },
  };

  let updatedInitialValues = {
    ...initialValues,
    companyId: id,
    companyName,
    relatedCompanyType: isEmpty(relatedCompanyType)
      ? null
      : convertObjectForSelectDropdown(relatedCompanyType, 'id', 'value'),
    yearAcquired: getFormattedDate(yearAcquired),
    ownership: isEmpty(ownership) ? null : convertObjectForSelectDropdown(ownership, 'id', 'value'),
    employees: employees || '',
    establishedYear,
    parentCompany: isEmpty(parentCompany)
      ? null
      : convertObjectForSelectDropdown(parentCompany, 'id', 'value'),
    website,
    phone: phone ? phone : [],
    fax: fax ? fax : [],
    companySummary: companySummary || '',
    street1,
    street2,
    country: isEmpty(country) ? null : convertObjectForSelectDropdown(country, 'id', 'value'),
    state,
    city,
    postalCode,
  };

  const handleSave = tabData => {
    if (formRef.current) {
      const { handleSubmit, values } = formRef.current;
      const {
        isValid,
        data: basicAndContactInfoDetails,
        ingestedContentResults,
      } = validateIngestionAction({
        ingestedActions,
        data: getBasicAndContactInfoDetails(values),
        type: 'company',
      });
      if (isValid) {
        updatedBasicAndContactInfoDetails.current = {
          data: basicAndContactInfoDetails,
          ingestedContentResults,
          tabData,
        };
        const { value: selectedCountry = '' } = {
          ...basicAndContactInfoDetails.country,
        };
        const { stateName: selectedState = '' } = {
          ...basicAndContactInfoDetails.state,
        };
        if (
          selectedCountry &&
          (selectedCountry.trim().toLowerCase() === 'usa' ||
            selectedCountry.trim().toLowerCase() === 'canada') &&
          selectedState.trim() === ''
        ) {
          showSelectStateAlert(true);
        } else {
          handleSubmit();
        }
      } else {
        dispatch(showModal(MODAL_MESSAGES.INGESTION_ERROR));
      }
    }
  };

  const proceedToSaveBasicInfo = status => {
    showSelectStateAlert(false);
    status && formRef.current.handleSubmit();
  };

  const proceedToClear = status => {
    if (status) {
      if (formRef.current) {
        formRef.current.resetForm();
      }

      phoneFormRef.current.reset();
      faxFormRef.current.reset();

      dispatch(resetCompanyData('basicAndContactInfo'));
    } else {
      setClearModal(false);
    }
  };

  const handleDateChange = date => {
    const newDate = date && isValidDate(date) ? date.toISOString() : date;
    basicAndContactInfoRef.current &&
      dispatch(
        updateCompanyDetails({
          key: 'basicAndContactInfo',
          data: {
            ...basicAndContactInfoRef.current,
            recordDates: {
              ...basicAndContactInfoRef.current.recordDates,
              nextReviewDate: newDate,
            },
          },
        }),
      );
  };

  const getSaveBodyPostParams = savePostBodyParam => {
    let postParam = savePostBodyParam;
    if (id === 0 && ingestedId) {
      postParam = { ...postParam, prodStatusID: ingestedProductionStatusId };
    }
    return postParam;
  };

  return (
    <div className="company-basic-info-container">
      <Grid xs={12} item={true} className="separate-line">
        <FeatureFooter
          handleSaveAndNext={() => handleSave(data)}
          handleSave={() => handleSave(null)}
          handleClear={() => setClearModal(true)}
          data={data}
          disabled={disableEdit}
        />
        <Formik
          key={id.toString()}
          enableReinitialize
          initialValues={updatedInitialValues}
          validate={async values =>
            validateBasicAndContactInfoDetails(
              values,
              recordDates.nextReviewDate,
              prodStatusID,
            )
          }
          onSubmit={async () => {
            const {
              data: updatedData,
              ingestedContentResults,
              tabData,
            } = updatedBasicAndContactInfoDetails.current;
            const request = getBasicAndContactInfoRequest({
              id,
              ingestedId,
              basicAndContactInfo: updatedData,
              ingestedContentResults,
              recordStartDateTime,
            });

            dispatch(
              saveCompanyDetails({
                request: getSaveBodyPostParams(request),
                tabData,
                key: 'basicAndContactInfo',
              }),
            );
          }}
        >
          {formikProps => {
            formRef.current = formikProps;
            return (
              <>
                <FormBuilder
                  formikProps={formikProps}
                  layoutSchema={layoutSchema}
                  formSchema={updatedFormSchema}
                  onSubmitValidationError={() => { }}
                  disableControls={disableEdit}
                />
              </>
            );
          }}
        </Formik>
        <div className="company-record-dates">
          <InputLabel labelFor="recordDates" text="Record Dates" />
          <TimeStamp
            data={recordDates ? recordDates : {}}
            handleDateChange={date => handleDateChange(date)}
            disabled={disableEdit}
          />
        </div>
      </Grid>
      {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
      {selectStateAlert && (
        <ConfirmationMessage
          isOpen
          onClose={status => proceedToSaveBasicInfo(status)}
          message="It is preferred to select state for the United States or Canada. Do you still want to proceed without state selection?"
        />
      )}
    </div>
  );
});

BasicAndContactInfo.propTypes = {
  data: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default BasicAndContactInfo;
