import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DNADataGrid, Link, ListView } from '../../../../../framework';
import PaginationWrapper from '../../../../../generic/features/paginationWrapper';
import { getTrialDetailsBasedOnDrug } from '../../../../../store/actions/company';
import { checkIfArrayIsEmpty } from '../../../../../utils/generic/helper';
import { columnDefs } from './config';
import './index.scss';

const AssociationsTrialsGrid = ({ drugId, trials }) => {
  const {
    trialsData,
    pagination,
    pagination: { pageSize, pageIndex, rowCount },
  } = trials;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (checkIfArrayIsEmpty(trialsData)) {
      dispatch(getTrialDetailsBasedOnDrug({ pageSize, pageIndex, entityPrimaryKey: drugId, sortOrder: [{ columnName: 'All', isDescendingOrder: false }] }));
    }
  }, []);

  const resetAndNavigateToTrial = id => {
    history.push(`/clinicalTrials/${id}`);
  };

  let prevSortingOrder = false;
  let prevSortingColumn = '';
  const handleTableHeaderClick = (columnName, isDescendingOrder) => {
    // if condition added to avoid dispatching the action multiple times due to comparator function
    if (prevSortingOrder !== isDescendingOrder || prevSortingColumn !== columnName) {
      prevSortingColumn = columnName
      prevSortingOrder = isDescendingOrder
      dispatch(
        getTrialDetailsBasedOnDrug({
          pageIndex,
          pageSize,
          entityPrimaryKey: drugId,
          sortOrder: [
            {
              columnName: columnName,
              isDescendingOrder: isDescendingOrder,
            },
          ],
        }),
      );
    };
  }

  const handleSortChanged = (args) => {
    const { api } = args;
    const sortState = api.getSortModel();
    if (sortState.length === 0) {
      dispatch(
        getTrialDetailsBasedOnDrug({
          pageIndex,
          pageSize,
          entityPrimaryKey: drugId,
          sortOrder: [
            {
              columnName: "All",
              isDescendingOrder: false,
            },
          ],
        }),
      );
    };
  }

  const renderGrid = () => (
    <DNADataGrid
      handleSortChanged={handleSortChanged}
      columnDefs={columnDefs(resetAndNavigateToTrial, handleTableHeaderClick)}
      rowData={JSON.parse(JSON.stringify(trialsData))}
      frameworkComponents={{ Link, ListView }}
      rowHeight={35}
    />
  );

  return (
    <div className="company-associations-grid-wrapper">
      {rowCount > 50 ? (
        <PaginationWrapper
          pageInfo={pagination}
          onChange={pageDetails =>
            dispatch(
              getTrialDetailsBasedOnDrug({
                ...pageDetails,
                entityPrimaryKey: drugId,
              }),
            )
          }
        >
          {renderGrid()}
        </PaginationWrapper>
      ) : (
        renderGrid()
      )}
    </div>
  );
};

AssociationsTrialsGrid.propTypes = {
  drugId: PropTypes.number.isRequired,
  trials: PropTypes.object.isRequired,
};

export default AssociationsTrialsGrid;
