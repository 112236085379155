import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Typography from '../../../../../framework/typography';
import { InputButton } from '../../../../../framework/inputs';
import { useDispatch } from 'react-redux';
import Modal from '../../../../../framework/modal';
import { setShowPersonValidationModal } from '../../../../../store/actions/person';
import './index.scss';

const PersonValidationModal = ({ isOpen, clickHandler, personValidationMessages }) => {
    const handleButtonClick = value => {
        if (value === 'cancel') {
            return clickHandler('cancel');
        }
        clickHandler();
    };

    const renderInfoList = messages => {
        return (
            <div className="message-modal-content-list">
                <ul>
                    {messages.map((element, index) => {
                        return <li key={index}>{element.errorMessage}</li>;
                    })}
                </ul>
            </div>
        );
    };

    const confirmationText = 'Do you wish to continue?';

    return (
        <>
            {isOpen && (
                <Modal isOpen={isOpen} showCloseButton={true} onClose={() => handleButtonClick('cancel')}>
                    <section className="message-modal-container">
                        <header>
                            <div className="message-modal-header-content">
                                <FontAwesomeIcon className="modal-icon error" icon={faExclamationCircle} />
                                <Typography
                                    variant="h6"
                                    text="error!"
                                    textType="uppercase"
                                    fontStyle="bold"
                                    color="red"
                                    padding="top"
                                />
                            </div>
                        </header>
                        {renderInfoList(personValidationMessages)}
                        <div>{confirmationText}</div>
                        <footer>
                            <InputButton
                                variant="contained"
                                buttonType="primary-btn"
                                text="YES"
                                onClick={() => handleButtonClick()}
                            />
                            <InputButton
                                variant="contained"
                                buttonType="secondary-btn"
                                text="NO"
                                onClick={() => handleButtonClick('cancel')}
                            />
                        </footer>
                    </section>
                </Modal>
            )}
        </>
    );
};

export default PersonValidationModal;

PersonValidationModal.propTypes = {
    isOpen: PropTypes.bool,
    savedSearchValue: PropTypes.string,
    clickHandler: PropTypes.func,
    personValidationMessages: PropTypes.array,
};

PersonValidationModal.defaultProps = {
    isOpen: false,
    savedSearchValue: '',
    clickHandler: () => { },
    personValidationMessages: [],
};
