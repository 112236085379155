import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { spacing } from '@material-ui/system';

import FormBuilderInputs from '../FormBuilderInputs';

const FormBuilderGrid = ({
  control: { layout, size, className },
  formikProps,
  formSchema,
  disableControls,
}) => (
  <Grid item xs={size}>
    <div className={className}>
      <FormBuilderInputs attributes={{ formSchema, layout, formikProps, disableControls }} />
    </div>
  </Grid>
);

FormBuilderGrid.propTypes = {
  control: PropTypes.object.isRequired,
  formikProps: PropTypes.object.isRequired,
  formSchema: PropTypes.object.isRequired,
  disableControls: PropTypes.bool.isRequired,
};

export default FormBuilderGrid;
