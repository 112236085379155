import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DNADataGrid, ListView, Link, DefaultCellRender } from '../../../../../../framework';
import { getDrugAssociation } from '../../../../../../store/actions/drugs';
import PaginationWrapper from '../../../../../../generic/features/paginationWrapper';

const AssociatedDrugs = () => {
  const {
    original: { drugType, key },
    drugAssociation: {
      associatedDrugs: {
        associatedDrugsData,
        pagination,
        pagination: { pageSize, pageIndex },
      },
    },
  } = useSelector(state => state.drugs, shallowEqual);

  const history = useHistory();
  const dispatch = useDispatch();
  const [pageDetails, setPageDetails] = useState({ pageIndex: 1, pageSize: 50 })
  const currPage = pageDetails.pageIndex;
  const currpageSize = pageDetails.pageSize;
  const currentPageRef = useRef({ pageIndex: currPage, pageSize: currpageSize })
  const [sortedColumnName, setSortedColumnName] = useState('');
  const [sortingOrder, setSortingOrder] = useState(false);
  const prevPageState = useRef({ prevSortingColumn: '', prevSortingOrder: false })

  useEffect(() => {
    if (associatedDrugsData.length === 0) {
      dispatch(
        getDrugAssociation({
          typeId: drugType && drugType.id,
          pageSize,
          pageIndex,
          entityPrimaryKey: key,
          sortOrder: [{ columnName: '', isDescendingOrder: false }]
        }),
      );
    }
  }, []);

  useEffect(() => {
    currentPageRef.current = { pageIndex: currPage, pageSize: currpageSize }
  }, [currPage, currpageSize])

  const navigateToDrug = id => {
    window.open(`/drugs/${id}`, '_blank');
  };

  let prevSortingOrder = false;
  let prevSortingColumn = '';
  const handleTableHeaderClick = (columnName, isDescendingOrder) => {
    setSortedColumnName(columnName)
    setSortingOrder(isDescendingOrder)
    // if condition added to avoid dispatching the action multiple times due to comparator function
    if (prevSortingOrder !== isDescendingOrder || prevSortingColumn !== columnName) {
      prevSortingColumn = columnName
      prevSortingOrder = isDescendingOrder
      dispatch(
        getDrugAssociation({
          typeId: drugType && drugType.id,
          pageIndex: currentPageRef.current.pageIndex,
          pageSize: currentPageRef.current.pageSize,
          entityPrimaryKey: key,
          sortOrder: [
            {
              columnName: columnName,
              isDescendingOrder: isDescendingOrder,
            },
          ],
        }),
      );
    };
  }

  const handleSortChanged = (args) => {
    const { api } = args;
    const sortState = api.getSortModel();
    if (sortState.length === 0) {
      dispatch(
        getDrugAssociation({
          typeId: drugType && drugType.id,
          pageIndex: currentPageRef.current.pageIndex,
          pageSize: currentPageRef.current.pageSize,
          entityPrimaryKey: key,
          sortOrder: [
            {
              columnName: "",
              isDescendingOrder: false,
            },
          ],
        }),
      );
    };
    setSortedColumnName('')
    setSortingOrder(false)
  }

  const columnDefs = ({ handleTableHeaderClick }) => [
    {
      headerName: 'PRIMARY NAME',
      field: 'descriptiveName',
      width: 100,
      sortable: true,
      cellRenderer: 'Link',
      cellRendererParams: ({ data }) => {
        if (data.descriptiveName) {
          return {
            link: data.descriptiveName,
            onClick: () => navigateToDrug(data.drugId),
          };
        }
      },
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('name', isDescendingOrder)
      }
    },
    {
      headerName: 'NAME TYPE',
      headerClass: 'nameTypeAssociatedDrugs',
      field: 'nameType',
      width: 100,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
    },
  ];

  return (
    <PaginationWrapper
      pageInfo={pagination}
      onChange={pageDetails => {
        setPageDetails(pageDetails);
        dispatch(
          getDrugAssociation({
            ...pageDetails,
            typeId: drugType && drugType.id,
            entityPrimaryKey: key,
            sortOrder: [
              {
                columnName: sortedColumnName,
                isDescendingOrder: sortingOrder,
              },
            ],
          }),
        );
      }}
    >
      <DNADataGrid
        handleSortChanged={handleSortChanged}
        // columnDefs={[...columnDefs]}
        columnDefs={columnDefs({ handleTableHeaderClick })}
        rowData={JSON.parse(JSON.stringify(associatedDrugsData))}
        frameworkComponents={{ ListView, Link, DefaultCellRender }}
        rowHeight={60}
      />
    </PaginationWrapper>
  );
};
export default AssociatedDrugs;
