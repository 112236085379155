import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import InputLabel from '../InputLabel';
import './index.scss';

const InputRadioOptions = ({
  name,
  value,
  originalValue,
  data,
  handleChange,
  disableRules,
  label,
  labelSize,
  fontStyle,
  inputClassNames,
  isMandatory,
  height,
  isError,
  errorMessage,
  disabled,
  displayKey
}) => {
  const [values, updateValue] = useState(data);
  const update = id => {
    const updatedData = values.map(t => {
      return {
        ...t,
        isSelected: t.id === id,
      };
    });
    updateValue(updatedData);
    handleChange(id);
  };
  useEffect(() => {
    const updatedData = data.map(t => {
      return {
        ...t,
        isSelected: t.id === value,
      };
    });
    updateValue(updatedData);
  }, [value, data]);

  const handleDisable = display => {
    let isStatusDisabled = false;
    const currentData = [...values];
    let selectedIndex = currentData.findIndex(d => d.isSelected);
    // to disbale against the original value not currently selected value
    if (originalValue !== null && originalValue >= 0) {
      selectedIndex = currentData.findIndex(d => d.id === originalValue);
    }
    const ruleset = disableRules[selectedIndex];
    const rules = (ruleset && ruleset.values) || [];
    isStatusDisabled = rules.includes(display);
    return isStatusDisabled;
  };

  return (
    <div name={name} className="radio-options-container">
      {label && (
        <InputLabel
          labelFor="radioOptionsLabel"
          text={label}
          size={labelSize}
          fontStyle={fontStyle}
          isMandatory={isMandatory}
        />
      )}
      <div className={`radio-options-value ${height} ${isError ? 'error' : ''}`}>
        {values.map(({ id, display, isSelected, iconColor, hidden }) => {
          // TO DO: update classNames once we get confirmation on disable logic
          let classNames = isSelected ? 'radio-btn-color' : '';
          const isDisabled = handleDisable(display);
          classNames = isDisabled ? `${classNames} radio-btn-disabled` : classNames;
          return hidden ? (
            <></>
          ) : (
            <div className={`radio-btn ${classNames} ${inputClassNames}`} key={id.toString()}>
              {iconColor && <FiberManualRecordIcon className={`icon-color-${iconColor}`} />}
              <input
                type="button"
                value={display}
                className="radio-btn-text"
                onClick={() => update(id)}
                disabled={isDisabled || disabled 
                  || (name === 'Set Status for Selected Countries' &&
                  id === displayKey) 
                }
              />
            </div>
          );
        })}
      </div>
      {isError && <p className="error-text">{errorMessage}</p>}
    </div>
  );
};

InputRadioOptions.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  originalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      display: PropTypes.string.isRequired,
      isSelected: PropTypes.bool,
      iconColor: PropTypes.string,
      hidden: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  disableRules: PropTypes.arrayOf(
    PropTypes.shape({
      values: PropTypes.array,
    }),
  ).isRequired,
  fontStyle: PropTypes.oneOf(['regular', 'bold', 'italics']),
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  labelSize: PropTypes.string,
  iconColor: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  isMandatory: PropTypes.bool,
  height: PropTypes.string,
  disabled: PropTypes.bool,
};

InputRadioOptions.defaultProps = {
  label: '',
  labelSize: '16',
  disableRules: [],
  originalValue: null,
  isMandatory: false,
  height: 'ht-lg',
  disabled: false,
};

export default InputRadioOptions;
