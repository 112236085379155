const features = [
  {
    name: 'BASIC & ADDITIONAL INFO',
    value: 'BasicAndContactInfo',
    isTab: false,
    selectedTabIndex: 0,
    key: 'organizationBasicInfo',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'ADDRESS',
    value: 'AdditionalInfo',
    isTab: false,
    selectedTabIndex: 0,
    key: 'contactInfo',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'ASSOCIATIONS',
    value: 'Associations',
    isChildTab: true,
    isTab: true,
    selectedTabIndex: 0,
    tabs: [
      { name: 'CURRENT ASSOCIATED PEOPLE', value: 'CurrentAssociatedPeople' },
      { name: 'ONGOING ASSOCIATED TRIALS ', value: 'OngoingAssociatedTrials' },
      { name: 'ALL ASSOCIATED TRIALS', value: 'AllAssociatedTrials' },
    ],
    key: 'associations',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'NOTES',
    value: 'Notes',
    isTab: false,
    selectedTabIndex: 0,
    key: 'notes',
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'AUDIT TRAIL',
    value: 'AuditTrial',
    key: 'auditTrial',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
];

export default features;
