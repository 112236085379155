import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import UnauthorizedPage from '../screens/auth/unauthorizedPage';
import LoadingPage from '../screens/auth/loadingPage';

const AuthRoutes = ({ isAuthenticating }) => {
  return (
    <>
      {isAuthenticating ? (
        <>
          <Route exact path="/user" component={LoadingPage} />
          <Route path="/">
            <Redirect to="/user" />
          </Route>
        </>
      ) : (
        <>
          <Route path="/unauthorized" component={() => <UnauthorizedPage title="application" />} />
          <Route path="/">
            <Redirect to="/unauthorized" />
          </Route>
        </>
      )}
    </>
  );
};

export default AuthRoutes;
