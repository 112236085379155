import { fieldTypes } from '../../../../../../framework/formBuilder/utils';

export const initialValues = {
  formula: '',
  weight: '',
  logp: '',
  hBondDonors: '',
  hBondAcceptors: '',
  rotatableBonds: '',
  chemicalName: '',
  smilesFormula: '',
  imageUri: '',
};

export const layoutSchema = {
  layout: 'row',
  className: 'molecular-data-container mb-20',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'column',
      size: 12,
      className: 'dh-row mt-20',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      header: ' ',
      headerProps: {
        fontStyle: 'bold',
        size: '14',
      },
      content: [
        {
          layout: 'smilesFormula',
          size: 12,
          className: '',
        },
        {
          layout: 'chemicalName',
          size: 12,
          className: '',
        },
        {
          layout: 'formula',
          size: 12,
          className: '',
        },
        {
          layout: 'weight',
          size: 12,
          className: '',
        },
        {
          layout: 'logp',
          size: 12,
          className: '',
        },
        {
          layout: 'hBondDonors',
          size: 12,
          className: '',
        },
        {
          layout: 'hBondAcceptors',
          size: 12,
          className: '',
        },
        {
          layout: 'rotatableBonds',
          size: 12,
          className: '',
        },
      ],
    },
  ],
};

export const formSchema = {
  formula: {
    name: 'formula',
    type: fieldTypes.text,
    label: 'MOLECULAR FORMULA',
    props: {
      isMandatory: false,
      inputHeight: 'ht-lg',
      disabled: true,
    },
  },
  weight: {
    name: 'weight',
    type: fieldTypes.text,
    label: 'MOLECULAR WEIGHT',
    props: {
      isMandatory: false,
      inputHeight: 'ht-lg',
      disabled: true,
    },
  },
  logp: {
    name: 'logp',
    type: fieldTypes.text,
    label: 'LOGP',
    props: {
      isMandatory: false,
      inputHeight: 'ht-lg',
      disabled: true,
    },
  },
  hBondDonors: {
    name: 'hBondDonors',
    type: fieldTypes.text,
    label: 'H BOND DONORS',
    props: {
      isMandatory: false,
      inputHeight: 'ht-lg',
      disabled: true,
    },
  },
  hBondAcceptors: {
    name: 'hBondAcceptors',
    type: fieldTypes.text,
    label: 'H BOND ACCEPTORS',
    props: {
      isMandatory: false,
      inputHeight: 'ht-lg',
      disabled: true,
    },
  },
  rotatableBonds: {
    name: 'rotatableBonds',
    type: fieldTypes.text,
    label: 'ROTATABLE BONDS',
    props: {
      isMandatory: false,
      inputHeight: 'ht-lg',
      disabled: true,
    },
  },
  chemicalName: {
    name: 'chemicalName',
    type: fieldTypes.text,
    label: 'CHEMICAL NAME',
    props: {
      isMandatory: false,
      inputHeight: 'ht-lg',
      inputProps: { tabIndex: -1 },
      onBlurDisable: true,
    },
  },
  smilesFormula: {
    name: 'smilesFormula',
    type: fieldTypes.text,
    label: 'CHEMICAL STRUCTURE (SMILES FORMAT)',
    props: {
      isMandatory: false,
      inputHeight: 'ht-lg',
      inputProps: { tabIndex: -1 },
      onBlurDisable: true,
    },
  },
};
