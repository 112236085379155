import { onSearch, convertDataForSelectDropdown, convertArrayToString } from '../generic/helper';
import { getTypeaheadSearchUrls } from '../../api';

export const searchDegree = async value => {
  const filteredData = await onSearch({
    searchedData: value,
    url: getTypeaheadSearchUrls('sites', 'Degree'),
  });
  return filteredData ? convertDataForSelectDropdown(filteredData, 'id', 'degree') : null;
};

export const searchSpeciality = async value => {
  const filteredData = await onSearch({
    searchedData: value,
    url: getTypeaheadSearchUrls('sites', 'Specialties'),
  });
  return filteredData ? convertDataForSelectDropdown(filteredData, 'id', 'specialties') : null;
};

export const searchOrganization = async value => {
  const results = await onSearch({
    searchedData: value,
    url: getTypeaheadSearchUrls('sites', 'Organization'),
  });
  return results
    ? [...results].map(
        ({
          id,
          name,
          organizationType,
          displayAddress,
          primaryContactInfo,
          nextFullReviewDate,
          licenses,
          address
        }) => ({
          id,
          name,
          parentOrganizationName: name,
          organizationType,
          primaryContactInfo,
          nextFullReviewDate,
          displayAddress,
          licenses,
          label: displayAddress ? `${id} - ${name} - ${displayAddress}` : `${id} - ${name}`,
          value: id,
          address,
          phone: primaryContactInfo ? convertArrayToString(primaryContactInfo.phoneNumbers) : '',
          fax: primaryContactInfo ? convertArrayToString(primaryContactInfo.faxNumbers) : '',
          email: primaryContactInfo ? convertArrayToString(primaryContactInfo.emails) : '',
        }),
      )
    : null;
};

export const getValidPersonName = name => {
  if (name && name.trim() !== '') {
    return name;
  }
  return 'NA';
};

export const getParentOrganization = ({ id, name = '', displayAddress = '' }) => {
  let value = '';
  if (displayAddress) {
    value = `${id} - ${name || 'NA'} - ${displayAddress}`;
  } else {
    value = `${id} - ${name || 'NA'}`;
  }
  return value;
};
