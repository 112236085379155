import { createAction } from '@reduxjs/toolkit';

// action definitions
const sourceDocumentsRoot = 'sourceDocuments/tabs/';
export const setSelectedTabIndex = createAction(`${sourceDocumentsRoot}setSelectedTabIndex`);
export const sourceDocumentsNextFeatureSelection = createAction(
  `${sourceDocumentsRoot}goToNextFeature`,
);
export const sourceDocumentsFeatureSelection = createAction(`${sourceDocumentsRoot}selected`);

export const getSourceDocumentList = createAction(`${sourceDocumentsRoot}getSourceDocumentList`);
export const getSourceDocumentListSuccess = createAction(
  `${sourceDocumentsRoot}getSourceDocumentListSuccess`,
);
export const deleteSourceDocument = createAction(`${sourceDocumentsRoot}deleteSourceDocument`);
export const deleteSourceDocumentSuccess = createAction(
  `${sourceDocumentsRoot}deleteSourceDocumentSuccess`,
);
export const setError = createAction(`${sourceDocumentsRoot}error`);

export const saveSourceDocumentDetails = createAction(
  `${sourceDocumentsRoot}saveSourceDocumentDetails`,
);
export const saveSourceDocumentDetailsSuccess = createAction(
  `${sourceDocumentsRoot}saveSourceDocumentDetailsSuccess`,
);
export const getSourceDocumentMasterData = createAction(
  `${sourceDocumentsRoot}getSourceDocumentMasterData`,
);
export const getSourceDocumentMasterDataSuccess = createAction(
  `${sourceDocumentsRoot}getSourceDocumentMasterDataSuccess`,
);

export const getSourceDocumentDetails = createAction(
  `${sourceDocumentsRoot}getSourceDocumentDetails`,
);
export const getSourceDocumentDetailsSuccess = createAction(
  `${sourceDocumentsRoot}getSourceDocumentDetailsSuccess`,
);

export const saveUploadFiles = createAction(`${sourceDocumentsRoot}saveUploadFiles`);
export const saveUploadFilesSuccess = createAction(`${sourceDocumentsRoot}saveUploadFilesSuccess`);
export const saveDownlaodFiles = createAction(`${sourceDocumentsRoot}saveDownlaodFiles`);

export const publishSourceDocument = createAction(`${sourceDocumentsRoot}publishSourceDocument`);
export const publishSourceDocumentSuccess = createAction(
  `${sourceDocumentsRoot}publishSourceDocumentSuccess`,
);

export const moveOutofScopeSourceDoc = createAction(
  `${sourceDocumentsRoot}moveOutofScopeSourceDoc`,
);
export const getStagedIdSourceDoc = createAction(`${sourceDocumentsRoot}getStagedIdSourceDoc`);
export const moveOutofScopeSourceDocSuccess = createAction(
  `${sourceDocumentsRoot}moveOutofScopeSourceDocSuccess`,
);
export const getStagedIdSourceDocSuccess = createAction(
  `${sourceDocumentsRoot}getStagedIdSourceDocSuccess`,
);
export const resetStagedIdSourceDoc = createAction(`${sourceDocumentsRoot}resetStagedIdSourceDoc`);

export const setErrorDetail = createAction(`${sourceDocumentsRoot}setErrorDetail`);
export const setSourceDocValidationStatus = createAction(
  `${sourceDocumentsRoot}setSourceDocValidationStatus`,
);
export const setSourceDocUnSavedStatus = createAction(
  `${sourceDocumentsRoot}setSourceDocUnSavedStatus`,
);

export const validateSourceDoc = createAction(`${sourceDocumentsRoot}validateSourceDoc`);
export const validateSourceDocSuccess = createAction(
  `${sourceDocumentsRoot}validateSourceDocSuccess`,
);

export const getSourceDocRecordChangeFieldsList = createAction(
  `${sourceDocumentsRoot}getSourceDocRecordChangeFieldsList`,
);
export const sourceDocRecordChangeFldsListSuccess = createAction(
  `${sourceDocumentsRoot}sourceDocRecordChangeFldsListSuccess`,
);
export const getSourceDocumentStampingDetails = createAction(
  `${sourceDocumentsRoot}getSourceDocumentStampingDetails`,
);
export const getSourceDocumentStampingDetailsSuccess = createAction(
  `${sourceDocumentsRoot}getSourceDocumentStampingDetailsSuccess`,
);
export const updateSourceDocumentRecordDatesDetail = createAction(
  `${sourceDocumentsRoot}updateSourceDocumentRecordDatesDetail`,
);

// Locking Actions
export const getSourceDocumentUserLockDetails = createAction(
  `${sourceDocumentsRoot}getSourceDocumentUserLockDetails`,
);
export const releaseSourceDocumentUserLock = createAction(
  `${sourceDocumentsRoot}releaseSourceDocumentUserLock`,
);
export const resetSourceDocumentUserLock = createAction(
  `${sourceDocumentsRoot}resetSourceDocumentUserLock`,
);
export const updateSourceDocumentLockDetails = createAction(
  `${sourceDocumentsRoot}updateSourceDocumentLockDetails`,
);

// compare actions
export const getSrcDocCompareData = createAction(`${sourceDocumentsRoot}getSrcDocCompareData`);
export const getSrcDocCompareDataSuccess = createAction(
  `${sourceDocumentsRoot}getSrcDocCompareDataSuccess`,
);
export const resetSrcDocCompareData = createAction(`${sourceDocumentsRoot}resetSrcDocCompareData`);
