import { format } from 'date-fns';
import { isEqual, sortBy } from 'lodash';
import isValid from 'date-fns/isValid';

import { fieldTypes } from '../../../../framework/formBuilder/utils';
import {
  getClassNameForIngestion,
  checkForIngestionAction,
} from '../../../../utils/generic/helper';

export const validate = values => {
  const errors = {};

  if (!values.date) {
    errors.date = 'Please select the Reg. Action Date';
  }
  if (!values.regulatoryActionType) {
    errors.regulatoryActionType = 'Please select the Reg. Action Type';
  }
  if (!values.actions.trim()) {
    errors.actions = 'Please enter the Regulatory Actions';
  }
  if (values.date && !isValid(values.date)) {
    errors.date = 'Please enter a valid date (MMM-dd-yyyy)';
  }

  return errors;
};

export const isRegulatoryActionsExists = (
  data,
  { date, regulatoryActionType, regulatoryActionFlag, actions },
) => {
  let isDataExists = false;
  data.forEach(c => {
    if (
      format(new Date(c.date), 'MMM-dd-yyyy') === format(new Date(date), 'MMM-dd-yyyy') &&
      isEqual(sortBy(c.regulatoryActionType), sortBy(regulatoryActionType)) &&
      isEqual(sortBy(c.regulatoryActionFlag), sortBy(regulatoryActionFlag)) &&
      c.actions.toLowerCase().trim() === actions.toLowerCase().trim()
    ) {
      isDataExists = true;
    }
  });
  return isDataExists;
};

export const initialValues = {
  date: null,
  regulatoryActionType: null,
  regulatoryActionFlag: null,
  actions: '',
};

export const layoutSchema = {
  layout: 'row',
  className: 'person-additional-info-container',
  spacing: 2,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: '',
      spacing: 2,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'date',
          size: 1.5,
          className: 'reg-date',
        },
        {
          layout: 'regulatoryActionType',
          size: 2,
          className: '',
        },
        {
          layout: 'regulatoryActionFlag',
          size: 2,
          className: '',
        },
        {
          layout: 'actions',
          size: 5,
          className: '',
        },
        {
          layout: 'button',
          size: 0.5,
          className: 'add-button',
        },
      ],
    },
  ],
};

export const formSchema = {
  date: {
    name: 'date',
    type: fieldTypes.customDate,
    label: 'REG. ACTION DATE',
    props: {
      disablePast: false,
      isMandatory: true,
      isKeyboardCtrl: true,
      autoOk: true,
    },
  },
  regulatoryActionType: {
    name: 'regulatoryActionType',
    type: fieldTypes.select,
    label: 'REG. ACTION TYPE',
    options: [],
    props: {
      height: 'ht-lg',
      isMandatory: true,
			isSearchable: true,
			isClearable: true
    },
    dependent: { key: 'regulatoryActionFlag', value: 'flag' },
  },
  regulatoryActionFlag: {
    name: 'regulatoryActionFlag',
    type: fieldTypes.select,
    label: 'REG. ACTION FLAG',
    props: {
      height: 'ht-lg',
      isDisabled: true
    },
  },
  actions: {
    name: 'actions',
    type: fieldTypes.textArea,
    label: 'REGULATORY ACTIONS',
    props: {
      rows: 10,
      cols: 90,
      isMandatory: true,
    },
  },
  button: {
    name: 'button',
    type: fieldTypes.button,
    label: '+ ADD',
  },
};

export const columnDefs = ({
  handleDelete,
  handleEdit,
  handleIngestionActions,
  disableEdit,
  gridData,
}) => [
  {
    headerName: 'REG. ACTION DATE',
    field: 'date',
    width: 150,
    sortable: true,
    cellRenderer: ({ value }) => (value ? format(new Date(value), 'MMM-dd-yyyy') : 'NA'),
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
  },
  {
    headerName: 'REG. ACTION TYPE',
    field: 'regulatoryActionType.description',
    width: 150,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
  },
  {
    headerName: 'REG. ACTION FLAG',
    field: 'regulatoryActionFlag.flag',
    width: 150,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
  },
  {
    headerName: 'REGULATORY ACTIONS',
    field: 'actions',
    sortable: true,
    cellRenderer: 'DefaultCellRender',
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
  },
  {
    headerName: '',
    minWidth: 50,
    maxWidth: 50,
    field: '',
    cellRenderer: 'actions',
    cellRendererParams: params => {
      const { rowIndex, data } = params;
      return {
        handleEdit: () => {
          handleEdit(data, rowIndex);
        },
        canDisableEdit: disableEdit || data.isDisabled,
      };
    },
  },
  {
    headerName: '',
    minWidth: 50,
    maxWidth: 50,
    field: '',
    cellRenderer: 'actions',
    cellRendererParams: params => {
      const { rowIndex, data } = params;
      return {
        handleDelete: () => {
          handleDelete(data, rowIndex);
        },
        canDisableDelete: disableEdit || data.isDisabled,
      };
    },
  },
  {
    headerName: '',
    minWidth: 100,
    maxWidth: 100,
    field: '',
    hide: !checkForIngestionAction(gridData, true),
    cellRenderer: 'ingestionActions',
    cellRendererParams: ({ data: rowData }) => {
      return {
        isDisabled: disableEdit,
        ingestionAction: rowData ? rowData.ingestionAction : 'none',
        handleIngestionActions: option => handleIngestionActions(option, rowData),
      };
    },
  },
];
