import { createAction } from '@reduxjs/toolkit';
// import Axios from "axios";
// const basePath = process.env.REACT_APP_PHARMA_INTELLIGENCE_BASE_API;

//store the selected side bar value
export const sideBarSelection = createAction('ancillary/sidebar/slectedValue');
export const getAncillaryData = createAction('ancillary/getAncillaryData');
export const addAncillaryData = createAction('ancillary/addAncillaryData');
export const addSponsorCollaboratorAncillaryData = createAction('ancillary/addSponsorCollaboratorAncillaryData');
export const updateAncillaryData = createAction('ancillary/updateAncillaryData');
export const updateAncillaryApprovalData = createAction('ancillary/updateAncillaryApprovalData');
export const deleteAncillaryData = createAction('ancillary/deleteAncillaryData');
export const setAncillaryData = createAction('ancillary/setAncillaryData');
export const errorOnFetchingAncillaryData = createAction('ancillary/errorOnFetchingAncillaryData');
export const getListData = createAction('ancillary/getListData');
export const setAncillaryListData = createAction('ancillary/setAncillaryListData');

export const resetLocationData = createAction('ancillary/resetLocationData');
export const getLocationData = createAction('ancillary/getLocationData');
export const getLocationDataSuccess = createAction('ancillary/getLocationDataSuccess');
export const postLocationData = createAction('ancillary/postLocationData');
export const putLocationData = createAction('ancillary/putLocationData');
export const deleteLocationData = createAction('ancillary/deleteLocationData');
export const updatedSelectRegion = createAction('ancillary/updatedSelectRegion');
export const updatedSelectedSubRegion = createAction('ancillary/updatedSelectedSubRegion ');
export const updatedSelectedCountry = createAction('ancillary/updatedSelectedCountry  ');

export const sideMenuOpen = createAction('ancillary/sideMenuOpen');
export const getAncillaryCompanyById = createAction('ancillary/getAncillaryCompanyById');
export const getAncillaryRecordById = createAction('ancillary/getAncillaryRecordById');
export const getAncillaryRecordByIdSuccess = createAction('acillary/getAncillaryRecordByIdSuccess');
