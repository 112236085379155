import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { faArrowLeft, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InputLabel from '../InputLabel';
import './index.scss';

const InputButton = ({
  variant,
  text,
  iconColor,
  onClick,
  type,
  buttonType,
  isDisabled,
  buttonSize,
  showUpDownArrow,
  arrowDirection,
  icon,
  style,
  label
}) => {
  const className = `${buttonType} ${buttonSize}`;

  const handleClick = () => {
    onClick();
  };
  const arrowDirectionIcon =
    arrowDirection === 'down' ? (
      <FontAwesomeIcon icon={faArrowDown} />
    ) : (
      <FontAwesomeIcon icon={faArrowUp} />
    );

  return (
    <div className="btn-container">

        <InputLabel labelFor="inputLabel" text={label} />
      <Button
        variant={variant}
        className={className}
        onClick={handleClick}
        type={type}
        disabled={isDisabled}
        style={style}
      >
        {iconColor && <FontAwesomeIcon icon={faArrowLeft} />}
        {icon && <FontAwesomeIcon icon={icon} />}
        {text}
        {showUpDownArrow ? arrowDirectionIcon : null}
      </Button>
    </div>
  );
};

InputButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string.isRequired,
  iconColor: PropTypes.oneOf(['', 'black']),
  onClick: PropTypes.func,
  type: PropTypes.string,
  buttonType: PropTypes.oneOf(['primary-btn', 'secondary-btn', 'back-btn', 'validate-btn']),
  isDisabled: PropTypes.bool,
  buttonSize: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
  showUpDownArrow: PropTypes.bool,
  arrowDirection: PropTypes.oneOf(['down', 'up']),
  icon: PropTypes.any,
  style: PropTypes.object,
  label: PropTypes.string
};

InputButton.defaultProps = {
  variant: 'text',
  onClick: () => {},
  type: 'button',
  isDisabled: false,
  buttonType: 'secondary-btn',
  buttonSize: 'md',
  showUpDownArrow: false,
  arrowDirection: 'down',
  icon: '',
  style: null,
  label:'Button'
};

export default InputButton;
