import * as TaskActions from '../actions/task';
import * as GlobalActions from '../actions/globalActions';
import * as CompanyActions from '../actions/company';
import * as DrugActions from '../actions/drugs';
import * as TrialActions from '../actions/clinicalTrials';
import * as PersonActions from '../actions/person';
import * as OrganizationActions from '../actions/organization';
import { takeEvery, put, all } from 'redux-saga/effects';

import { task as taskApi } from '../../api';
import { MODAL_TYPES } from '../../utils/generic/constants';

export function* getTaskMasterAsync() {
  try {
    // yield put(GlobalActions.showSpinner());
    const response = yield taskApi.getTaskMaster();
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(TaskActions.getTaskMasterSuccess({ data }));
    }
    // yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(GlobalActions.hideSpinner());
  }
}

export function* assigneeUserGroupAsync({ payload }) {
  try {
    // yield put(GlobalActions.showSpinner());
    const response = yield taskApi.assigneeUserGroupList(payload);
    const {
      data: { data },
    } = response;
    if (response.status) {
      if (payload && payload.length > 0) {
        yield put(TaskActions.assigneeUserGroupsByQueueIdSuccess({ data }));
      } else {
        yield put(TaskActions.assigneeUserGroupListSuccess({ data }));
      }
    }
    // yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(GlobalActions.hideSpinner());
  }
}

export function* deleteTaskAsync({ payload }) {
  const successModalData = {
    isOpen: true,
    message: 'TASK DELETED SUCCESSFULLY!',
    modalType: MODAL_TYPES.SUCCESS,
  };
  const errorModalData = {
    isOpen: true,
    message: 'ERROR! TASK CAN NOT BE DELETED',
    modalType: MODAL_TYPES.ERROR,
  };
  try {
    yield put({ type: GlobalActions.showSpinner.type });
    const response = yield taskApi.deleteTask(payload);
    const {
      data: { data, state },
      status,
    } = response;
    if (status) {
      if (data && state) {
        yield put({
          type: GlobalActions.showModal.type,
          payload: successModalData,
        });
      } else {
        yield put({
          type: GlobalActions.showModal.type,
          payload: errorModalData,
        });
      }
      yield* advanceSearchTaskListAsync({
        payload: payload.payload.advanceSearchTaskListPayload,
      });
      yield put({ type: GlobalActions.hideSpinner.type });
    } else {
      yield put({
        type: GlobalActions.showModal.type,
        payload: errorModalData,
      });
      yield put({ type: GlobalActions.hideSpinner.type });
    }
  } catch (err) {
    yield put({ type: GlobalActions.showModal.type, payload: errorModalData });
    yield put({ type: GlobalActions.hideSpinner.type });
  }
}

export function* editTaskAsync(payload) {
  try {
    yield* saveCreateTaskAsync(payload);
  } catch (err) {
    yield put({ type: GlobalActions.hideSpinner.type });
  }
}

export function* saveCreateTaskAsync({ payload }) {
  const {
    requestWithSearchPayload: {
      request,
      isUpdateOperation,
      advanceSearchTaskListPayload,
      isTaskPage,
    },
  } = payload;

  try {
    yield put({ type: GlobalActions.showSpinner.type });
    const response = yield taskApi.saveCreateTask(request);
    const {
      data: { data, state, messages },
      status,
    } = response;
    if (status) {
      let modalData = null;
      //update task
      if (isUpdateOperation) {
        if (!state && messages) {
          modalData = {
            isOpen: true,
            message: `${messages[0].errorMessage}`,
            modalType: MODAL_TYPES.ERROR,
          };
        } else {
          modalData = {
            isOpen: true,
            message: 'TASK EDITED SUCCESSFULLY',
            modalType: MODAL_TYPES.SUCCESS,
          };
        }
      } else if (!state && messages) {
        modalData = {
          isOpen: true,
          message: `${messages[0].errorMessage}`,
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: 'TASK SUCCESSFULLY CREATED AND ASSIGNED',
          modalType: MODAL_TYPES.SUCCESS,
        };
      }
      yield put({ type: GlobalActions.showModal.type, payload: modalData });

      if (isTaskPage) {
        yield* advanceSearchTaskListAsync({
          payload: advanceSearchTaskListPayload,
        });
      }
    }
    yield put({ type: GlobalActions.hideSpinner.type });
  } catch (err) {
    if (isUpdateOperation) {
      yield put({
        type: GlobalActions.showModal.type,
        payload: {
          isOpen: true,
          message: 'Updation Operation Failed!',
          modalType: MODAL_TYPES.ERROR,
        },
      });
    } else {
      yield put({
        type: GlobalActions.showModal.type,
        payload: {
          isOpen: true,
          message: 'Updation Operation Failed!',
          modalType: MODAL_TYPES.ERROR,
        },
      });
    }
    yield put({ type: GlobalActions.hideSpinner.type });
  }
}

export function* advanceSearchTaskListAsync(payload) {
  try {
    const response = yield taskApi.getAdvancedSearchTaskList(payload.payload);
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put({
        type: TaskActions.advanceSearchTaskListSuccess.type,
        payload: { data },
      });
    }
  } catch (err) {
    yield put({ type: GlobalActions.hideSpinner.type });
  }
}

export function* getTaskDashboardDataAsync({ payload: { assigneeUserGroup, searchPayload } }) {
  try {
    yield put({ type: GlobalActions.showSpinner.type });
    const actions = {
      taskMasterActions: taskApi.getTaskMaster(),
      assigneeUserGroupListActions: taskApi.assigneeUserGroupList(assigneeUserGroup),
      advanceSearchTaskListActions: taskApi.getAdvancedSearchTaskList(searchPayload),
    };
    const {
      taskMasterActions,
      assigneeUserGroupListActions,
      advanceSearchTaskListActions,
    } = yield all(actions);

    if (taskMasterActions) {
      const {
        data: { data, state },
      } = taskMasterActions;

      if (state) {
        yield put(TaskActions.getTaskMasterSuccess({ data }));
      }
    }

    if (assigneeUserGroupListActions) {
      const {
        data: { data, state },
      } = assigneeUserGroupListActions;

      if (state) {
        yield put(TaskActions.assigneeUserGroupListSuccess({ data }));
      }
    }

    if (advanceSearchTaskListActions) {
      const {
        data: { data, state },
      } = advanceSearchTaskListActions;

      if (state) {
        yield put(TaskActions.advanceSearchTaskListSuccess({ data }));
      }
    }

    yield put({ type: GlobalActions.hideSpinner.type });
  } catch (err) {
    yield put({ type: GlobalActions.hideSpinner.type });
  }
}

export const watchers = [
  function* watchGetTask() {
    yield takeEvery(TaskActions.getTaskMaster, getTaskMasterAsync);
  },
  function* watchGetTaskAssignee() {
    yield takeEvery(TaskActions.assigneeUserGroupList, assigneeUserGroupAsync);
  },
  function* watchSaveCreateTask() {
    yield takeEvery(TaskActions.saveCreateTask, saveCreateTaskAsync);
  },
  function* watchAdvanceSearchTaskList() {
    yield takeEvery(TaskActions.advanceSearchTaskList, advanceSearchTaskListAsync);
  },
  function* watchDeleteTask() {
    yield takeEvery(TaskActions.deleteTask, deleteTaskAsync);
  },
  function* watchEditTask() {
    yield takeEvery(TaskActions.editTask, editTaskAsync);
  },
  function* watchGetTaskDashboardData() {
    yield takeEvery(TaskActions.getTaskDashboardData, getTaskDashboardDataAsync);
  },
];
