const organizationInitialState = {
  id: 0,
  ingestedId: 0,
  updateType: 0,
  organizationBasicInfo: {
    name: '',
    organizationType: null,
    licenses: [],
    parentOrganization: null,
    primaryInvestigators: [],
    supportingUrls: [],
    lastFullReviewDate: null,
    lastFullReviewedBy: '',
    nextFullReviewDate: null,
    createdBy: '',
    createdDate: null,
    modifiedBy: '',
    modifiedDate: null,
  },
  contactInfo: [],
  notes: [],
  prodStatusID: 0,
};

export default organizationInitialState;
