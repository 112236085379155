const features = [
  {
    name: 'BASIC & CONTACT INFO',
    value: 'BasicAndContactInfo',
    key: 'personBasicInfo',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'REGULATORY ACTION',
    value: 'AdditionalInfo',
    key: 'personAdditionalInfo',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'ASSOCIATIONS',
    value: 'Associations',
    key: 'associations',
    isChildTab: true,
    isTab: true,
    selectedTabIndex: 0,
    tabs: [
      { name: 'ASSOCIATED TRIALS', value: 'AssociatedTrialsPerson' },
      { name: 'ASSOCIATED ORGANIZATION ', value: 'AssociatedOrganization' },
    ],
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'NOTES',
    value: 'Notes',
    key: 'notes',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'AUDIT TRAIL',
    value: 'AuditTrial',
    key: 'auditTrial',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
];

export default features;
