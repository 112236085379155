import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import ListView from '../../../../../../framework/listView';
import Link from '../../../../../../framework/link';
import { DNADataGrid, DefaultCellRender } from '../../../../../../framework';
import { getPersonOrgAssociation } from '../../../../../../store/actions/person';
import PaginationWrapper from '../../../../../../generic/features/paginationWrapper';

import '../associatedTrials/index.scss';

const AssociatedOrganizations = () => {
  const {
    current: { id: personid },
    personAssociation: {
      associatedOrganizations: {
        associatedOrganizationsData,
        pagination,
        pagination: { pageSize, pageIndex },
      },
    },
  } = useSelector(state => state.person, shallowEqual);

  const dispatch = useDispatch();
  const history = useHistory();

  const [pageDetails, setPageDetails] = useState({ pageIndex: 1, pageSize: 50 })
  const currPage = pageDetails.pageIndex;
  const currpageSize = pageDetails.pageSize;
  const currentPageRef = useRef({ pageIndex: currPage, pageSize: currpageSize })
  const [sortedColumnName, setSortedColumnName] = useState('');
  const [sortingOrder, setSortingOrder] = useState(false);
  const prevPageState = useRef({ prevSortingColumn: '', prevSortingOrder: false })

  useEffect(() => {
    if (associatedOrganizationsData.length === 0) {
      dispatch(
        getPersonOrgAssociation({
          pageSize,
          pageIndex,
          entityPrimaryKey: personid,
          sortOrder: [{ columnName: 'All', isDescendingOrder: false }]
        }),
      );
    }
  }, []);

  useEffect(() => {
    currentPageRef.current = { pageIndex: currPage, pageSize: currpageSize }
  }, [currPage, currpageSize])

  let prevSortingOrder = false;
  let prevSortingColumn = '';
  const handleTableHeaderClick = (columnName, isDescendingOrder) => {
    setSortedColumnName(columnName)
    setSortingOrder(isDescendingOrder)
    // if condition added to avoid dispatching the action multiple times due to comparator function
    if (prevSortingOrder !== isDescendingOrder || prevSortingColumn !== columnName) {
      prevSortingColumn = columnName
      prevSortingOrder = isDescendingOrder
      dispatch(
        getPersonOrgAssociation({
          pageIndex: currentPageRef.current.pageIndex,
          pageSize: currentPageRef.current.pageSize,
          entityPrimaryKey: personid,
          sortOrder: [
            {
              columnName: columnName,
              isDescendingOrder: isDescendingOrder,
            },
          ],
        }),
      );
    };
  }

  const handleSortChanged = (args) => {
    const { api } = args;
    const sortState = api.getSortModel();
    if (sortState.length === 0) {
      dispatch(
        getPersonOrgAssociation({
          pageIndex: currentPageRef.current.pageIndex,
          pageSize: currentPageRef.current.pageSize,
          entityPrimaryKey: personid,
          sortOrder: [
            {
              columnName: "All",
              isDescendingOrder: false,
            },
          ],
        }),
      );
    };
    setSortedColumnName('')
    setSortingOrder(false)
  }

  const navigateToOrganization = id => {
    history.push(`/organization/${id}`);
  };

  const columnDefs = ({ handleTableHeaderClick }) => [
    {
      headerName: 'ORGANIZATION NAME',
      field: 'organizationName',
      width: 200,
      sortable: true,
      cellRenderer: 'Link',
      cellRendererParams: ({ data }) => {
        if (data.organizationName) {
          return {
            link: data.organizationName,
            onClick: () => {
              navigateToOrganization(data.organizationId);
            },
            customTextToolTip: (data && data.organizationName) || '',
          };
        }
      },
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('org_name', isDescendingOrder)
      }
    },
    {
      headerName: 'STREET 1',
      field: 'street1',
      width: 170,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('street1', isDescendingOrder)
      }
    },
    {
      headerName: 'STREET 2',
      field: 'street2',
      width: 180,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('street2', isDescendingOrder)
      }
    },
    {
      headerName: 'CITY',
      field: 'city',
      width: 140,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('city', isDescendingOrder)
      }
    },
    {
      headerName: 'STATE',
      field: 'state',
      width: 140,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('state', isDescendingOrder)
      }
    },
    {
      headerName: 'COUNTRY',
      field: 'country',
      width: 140,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('country', isDescendingOrder)
      }
    },
    {
      headerName: 'POSTAL CODE',
      field: 'postalCode',
      headerClass: 'postalCodePersonAssOrg',
      width: 120,
      sortable: false,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('postal_code', isDescendingOrder)
      }
    },
  ];

  return (
    <PaginationWrapper
      pageInfo={pagination}
      onChange={pageDetails => {
        setPageDetails(pageDetails);
        dispatch(
          getPersonOrgAssociation({
            ...pageDetails,
            entityPrimaryKey: personid,
            sortOrder: [
              {
                columnName: sortedColumnName,
                isDescendingOrder: sortingOrder,
              },
            ],
          }),
        );
      }}
    >
      <DNADataGrid
        handleSortChanged={handleSortChanged}
        // columnDefs={[...columnDefs]}
        columnDefs={columnDefs({ handleTableHeaderClick })}
        rowData={JSON.parse(JSON.stringify(associatedOrganizationsData))}
        frameworkComponents={{ ListView, Link, DefaultCellRender }}
        rowHeight={60}
      />
    </PaginationWrapper>
  );
};

AssociatedOrganizations.propTypes = {
  data: PropTypes.object.isRequired,
};
export default AssociatedOrganizations;
