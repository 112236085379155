import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { faTimes, faSearch, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import { DROPDOWN_TYPES } from '../../../utils/generic/constants';
import {
  getClassNameForIngestion,
  checkIfStringIsEmpty,
  checkIfArrayIsEmpty,
} from '../../../utils/generic/helper';
import IngestionActions from '../../ingestionActions';
import InputLabel from '../InputLabel';
import InputToolTip from '../../toolTip/inputToolTip';
import './index.scss';
import { faCircle as faOuterCircle } from '@fortawesome/free-regular-svg-icons';

const InputMultiSelectAsync = ({
  name,
  label,
  size,
  isMulti,
  isSearchable,
  isClearable,
  placeholder,
  hideSelectedOptions,
  showSelectedData,
  keyValue,
  drugValue,
  onChange,
  onFilter,
  value,
  selected,
  isDisabled,
  isMandatory,
  isError,
  errorMessage,
  blurInputOnSelect,
  ingestionAction,
  ingestedData,
  handleIngestionActions,
  isIngestionActionDisabled,
  type,
  isDependent,
  handleBlur,
  isSelectedValuesDisplayedParallelly,
  handleViewBtn,
}) => {
  const [selectedValue, updateSelectedValue] = useState(null);
  const [isFocussed, updateFocusState] = useState(false);
  // const [selectedOptions, updateSelectedOptions] = useState(selected);
  const selectedOptions = useRef(selected);
  const [inputValue, updateInputValue] = useState('');
  const [multipleValues, setMultipleValues] = useState([]);
  const [dropdownData, updateDropdownData] = useState({
    defaultData: [],
    searchedData: [],
  });
  const [isLoading, updateLoadingState] = useState(false);

  useEffect(() => {
    // updateSelectedOptions(selected);
    selectedOptions.current = selected;
    updateSelectedValue(isEmpty(value) ? null : value);
  }, [selected, value]);

  if (name === 'parentOrganization') {
    handleViewBtn(selectedValue);
  }

  useEffect(() => {
    if (isDependent) {
      updateDropdownData({
        defaultData: [],
        searchedData: [],
      });
    }
  }, [isDependent]);

  const updateSelectedData = (data, selectedData) => {
    const updatedData = data
      ? data.map(d => {
        const matchedData = selectedData.find(s => s.value === d.value);
        return {
          ...d,
          isSelected: Boolean(matchedData),
          isDisabled: (matchedData && matchedData.isDisabled) || false,
        };
      })
      : [];
    const multipleValuesArr = updatedData.filter(updatedVal => updatedVal.isSelected);
    setMultipleValues(multipleValuesArr);
    return updatedData;
  };

  const loadOptions = async (searchValue, callback) => {
    if (!checkIfStringIsEmpty(searchValue)) {
      const response = (await onFilter(searchValue)) || [];
      const updatedData = showSelectedData
        ? updateSelectedData(response, selectedOptions.current)
        : response;
      updateDropdownData({
        ...dropdownData,
        searchedData: updatedData,
      });
      return callback(updatedData);
    }
  };

  const handleInputChange = (val, { action }) => {
    let isInputToBeUpdated = showSelectedData
      ? action === 'input-change'
      : action === 'input-change' || action === 'set-value';
    if (isInputToBeUpdated) {
      updateInputValue(val);
    }
  };

  const updateDropdownValuesBasedOnSelectedData = data => {
    const { defaultData, searchedData } = dropdownData;
    // updateSelectedOptions(data);
    selectedOptions.current = data;
    updateDropdownData({
      defaultData: updateSelectedData(defaultData, data),
      searchedData: updateSelectedData(searchedData, data),
    });
  };

  const handleChange = e => {
    updateFocusState(false);
    if (showSelectedData) {
      let data = [...selectedOptions.current];
      const index = data.findIndex(s => s.value === e.value);
      if (index > -1) {
        data.splice(index, 1);
      } else {
        data.push(e);
      }
      if (name === 'mechanismOfActions') {
        let rootIds = data.map(dat => dat.id);
        if (rootIds.includes(e.parentid) && e.level > 0) {
          data.pop();
        }
        data = data.filter(dat => {
          return dat.parentid !== e.id;
        });
      }

      updateDropdownValuesBasedOnSelectedData(data);
    } else {
      updateSelectedValue(e);
      onChange(e);
    }
  };

  const handleDelete = index => {
    const data = [...selectedOptions.current];
    data.splice(index, 1);
    updateDropdownValuesBasedOnSelectedData(data);
    onChange(data);
  };

  const onClear = () => {
    if (!showSelectedData) {
      updateSelectedValue(null);
      updateInputValue('');
      updateDropdownData({ ...dropdownData, searchedData: [] });
      updateFocusState(false);
      updateLoadingState(false);
      onChange(null);
    }
  };

  const getOptionsClassName = data => {
    let classNames = [];
    if (data.isSelected || data.value === value) {
      classNames.push('selected-option');
      data.isDisabled && classNames.push('disable-option');
    }
    return classNames.join(' ');
  };

  let rootParentIds = [];

  if (name === 'mechanismOfActions') {
    rootParentIds = selectedOptions.map(option => option.id);
  }

  const Option = props => {
    const { data } = props;
    const { value = '' } = { ...selectedValue };
    return (
      <components.Option {...props} className={getOptionsClassName(data)}>
        <div className="option-wrapper" onClick={() => handleChange(data)}>
          {name === 'mechanismOfActions' ? (
            <div className={`level-${data.level}`}>
              {(data.level > 0 && data.isLast === false) || (data.level === 0 && data.hasChild) ? (
                <FontAwesomeIcon icon={faCircle} color="#e60073" size="xs" />
              ) : null}
              {data.level > 0 && data.isLast === true ? (
                <FontAwesomeIcon icon={faOuterCircle} color="#e60073" size="xs" />
              ) : null}{' '}
              <span
                className={
                  rootParentIds.includes(data.parentid) && data.level > 0 ? 'disabledCursor' : ''
                }
              >
                {data.label}
              </span>
            </div>
          ) : (
            data.label
          )}
        </div>
      </components.Option>
    );
  };

  const handleMenuClick = async () => {
    if (
      type === DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD &&
      (checkIfArrayIsEmpty(dropdownData.defaultData) || isDependent)
    ) {
      updateLoadingState(true);
      // to load default options
      const response = (await onFilter('')) || [];
      updateDropdownData({
        ...dropdownData,
        defaultData: showSelectedData
          ? updateSelectedData(response, selectedOptions.current)
          : response,
      });
      updateLoadingState(false);
    } else if (showSelectedData) {
      updateDropdownData({
        ...dropdownData,
        defaultData: updateSelectedData(dropdownData.defaultData, selectedOptions.current),
      });
    }
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        {type === DROPDOWN_TYPES.TYPEAHEAD ? (
          <FontAwesomeIcon icon={faSearch} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </components.DropdownIndicator>
    );
  };

  const ClearIndicator = props => {
    return (
      <components.ClearIndicator {...props}>
        <div
          onMouseDown={({ button }) => {
            // clear only on leftclick
            if (button === 0) {
              onClear();
            }
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </components.ClearIndicator>
    );
  };

  // eslint-disable-next-line react/prop-types
  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <InputToolTip>
          <div>{children}</div>
        </InputToolTip>
      </components.SingleValue>
    );
  };

  const onFocus = () => {
    updateFocusState(true);
  };

  const onBlur = () => {
    updateFocusState(false);
    if (showSelectedData && (drugValue === 'Primary Drug' || drugValue === 'Secondary Drug')) {
      selectedOptions.current = multipleValues;
      onChange(selectedOptions.current);
    } else if (showSelectedData) {
      onChange(selectedOptions.current);
    }
    updateInputValue('');
    handleBlur();
  };

  const formOptionsData = (regions, countries, subRegions) => {
    return [
      {
        label: 'Regions',
        options: regions ? [...regions] : [],
      },
      {
        label: 'SubRegions',
        options: subRegions ? [...subRegions] : [],
      },
      {
        label: 'Countries',
        options: countries ? [...countries] : [],
      },
    ];
  };

  const computeDataForLocation = () => {
    const locationsData = checkIfStringIsEmpty(inputValue)
      ? dropdownData.defaultData
      : dropdownData.searchedData
    const regions = locationsData?.filter(opt => opt.type === 'Region');
    const subRegions = locationsData?.filter(c => c.type === 'Subregion');
    const countries = locationsData?.filter(c => c.type === 'Country');
    const options = formOptionsData(regions, countries, subRegions);
    return options;
  }

  return (
    <div name={name} className="input-select-async-wrapper">
      {label && (
        <InputLabel labelFor="selectLabel" size={size} text={label} isMandatory={isMandatory} />
      )}

      <div className={`${isSelectedValuesDisplayedParallelly ? 'async-selected-wrapper' : ''}`}>
        <div className={`${isSelectedValuesDisplayedParallelly ? 'async-ingestion-wrapper' : ''}`}>
          <AsyncSelect
            className={`async-select-container ${isError ? 'error-control' : ''}`}
            classNamePrefix="async-select"
            hideSelectedOptions={hideSelectedOptions}
            closeMenuOnSelect={!isMulti}
            components={{ Option, DropdownIndicator, ClearIndicator, SingleValue }}
            loadOptions={loadOptions}
            defaultOptions={
              drugValue === 'Location'
                ? computeDataForLocation() :
                checkIfStringIsEmpty(inputValue)
                  ? dropdownData.defaultData
                  : dropdownData.searchedData
            }
            isMulti={isMulti}
            isSearchable={isSearchable}
            isClearable={isClearable}
            placeholder={placeholder}
            value={selectedValue}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            controlShouldRenderValue={isMulti ? false : !isFocussed}
            isDisabled={isDisabled}
            isLoading={isLoading}
            loadingMessage={({ inputValue }) =>
              `${checkIfStringIsEmpty(inputValue) ? 'Loading' : 'Searching'} ${toLower(label)}...`
            }
            noOptionsMessage={({ inputValue }) => {
              return checkIfStringIsEmpty(inputValue)
                ? `Type to search ${toLower(label)}...`
                : `No ${toLower(label)} found`;
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            blurInputOnSelect={blurInputOnSelect}
            onMenuOpen={handleMenuClick}
          />
          {isError && <p className="error-text">{errorMessage}</p>}
          <IngestionActions
            className="pt-10"
            isDisabled={isIngestionActionDisabled}
            ingestionAction={showSelectedData ? 'none' : ingestionAction}
            handleIngestionActions={handleIngestionActions}
          >
            {!isEmpty(ingestedData) && <p className="input-ingested-text">{ingestedData.value}</p>}
          </IngestionActions>
        </div>
        {showSelectedData && (
          <div
            className={`selected-container ${isSelectedValuesDisplayedParallelly ? 'selected-rows-wrapper' : ''
              } elastic-height`}
          >
            {selectedOptions.current.map((s, index) => {
              return (
                <IngestionActions
                  isDisabled={isIngestionActionDisabled}
                  ingestionAction={s.ingestionAction}
                  handleIngestionActions={action => handleIngestionActions(action, s)}
                  key={index.toString()}
                >
                  <div
                    className={`selected-container__selected-value ${isSelectedValuesDisplayedParallelly ? 'selected-rows' : ''
                      }`}
                  >
                    {keyValue === 'primaryDrugs' ? (
                      <Link to={`/drugs/${s.value}`} target="_blank">
                        <span
                          className={`${getClassNameForIngestion(s.ingestionAction)} default-link`}
                        >
                          {s.label}
                        </span>
                      </Link>
                    ) : (
                      <span
                        className={`${getClassNameForIngestion(s.ingestionAction)} elastic-color`}
                      >
                        {s.label}
                      </span>
                    )}
                    <button
                      type="button"
                      onClick={() => handleDelete(index)}
                      disabled={isDisabled || s.isDisabled}
                    >
                      <FontAwesomeIcon icon={faTimes} className="close-icon" />
                    </button>
                  </div>
                </IngestionActions>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

InputMultiSelectAsync.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  showSelectedData: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.object,
  selected: PropTypes.array,
  onChange: PropTypes.func,
  handleViewBtn: PropTypes.func,
  isMandatory: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onFilter: PropTypes.func,
  blurInputOnSelect: PropTypes.bool,
  ingestionAction: PropTypes.string,
  ingestedData: PropTypes.object,
  handleIngestionActions: PropTypes.func,
  isIngestionActionDisabled: PropTypes.bool,
  type: PropTypes.string,
  isDependent: PropTypes.bool,
  handleBlur: PropTypes.func,
  isSelectedValuesDisplayedParallelly: PropTypes.bool,
  keyValue: PropTypes.string,
};

InputMultiSelectAsync.defaultProps = {
  label: '',
  size: '16',
  placeholder: '',
  isMulti: false,
  isSearchable: false,
  isClearable: false,
  hideSelectedOptions: false,
  showSelectedData: false,
  isDisabled: false,
  value: null,
  selected: [],
  onChange: () => { },
  handleViewBtn: () => { },
  isMandatory: false,
  isError: false,
  errorMessage: '',
  blurInputOnSelect: false,
  ingestionAction: 'none',
  ingestedData: null,
  handleIngestionActions: () => { },
  isIngestionActionDisabled: false,
  type: DROPDOWN_TYPES.TYPEAHEAD,
  isDependent: false,
  handleBlur: () => { },
  isSelectedValuesDisplayedParallelly: false,
};

export default InputMultiSelectAsync;
