import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '../InputLabel';
import './index.scss';

const TextArea = ({
  name,
  label,
  value,
  rowsMax,
  rowsMin,
  cols,
  customBigHeight,
  customHeight,
  setFieldValue,
  isDisabled,
  placeholder,
  onChange,
  size,
  onBlur,
  isMandatory,
  isError,
  errorMessage,
  disabled,
}) => {
  const inputTextAreaId = label
    ? `text-area-${label.toLowerCase()}`
    : 'text-area';
  const handleBlur = () => {
    if (!isDisabled) {
      const element = document.getElementById(inputTextAreaId);
      const value = element && element.value ? element.value : '';
      if (setFieldValue) {
        setFieldValue(name, value)
      }
      onBlur(value);
    }
  };
  return (
    <div name={name} className="input-text-area">
      {label && (
        <InputLabel labelFor="inputLabel" text={label} size={size} isMandatory={isMandatory} />
      )}
      <textarea
        className={`text-area-control ${isError ? 'error-control' : ''}${isDisabled ? 'disabled' : ''} ${customHeight ? 'custom-height' : ''}
        ${customBigHeight ? 'custom-big-height' : ''}`}
        value={value}
        id={inputTextAreaId}
        onChange={onChange}
        // rows={rowsMin}
        // rowsMin={rowsMin}
        // rowsMax={rowsMax}
        // minLength={250}
        // style={{ height: '100px' }}
        // maxLength={rowsMax}
        // cols={3}
        disabled={isDisabled}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
      {isError && <p className="error-text">{errorMessage}</p>}
    </div>
  );
};

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  rowsMin: PropTypes.number,
  rowsMax: PropTypes.number,
  cols: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  isMandatory: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
};
TextArea.defaultProps = {
  rowsMin: 10,
  rowsMax: 20,
  cols: 80,
  placeholder: 'Add text content here',
  size: '16',
  isMandatory: false,
  isError: false,
  errorMessage: '',
  disabled: false,
};

export default TextArea;
