import React, {
  createRef,
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { FormBuilder, DNADataGrid, TableActions, IngestionActions } from '../../../../../framework';
import { InputLabel } from '../../../../../framework/inputs';
import {
  updateCompanyDetails,
  updateCompanyChildValues,
} from '../../../../../store/actions/company';
import {
  isFinancialEntriesExists,
  formFinancialEntryDetails,
} from '../../../../../utils/company/helper';
import {
  updateIngestedDataBasedOnFieldType,
  checkForIngestionAction,
} from '../../../../../utils/generic/helper';
import { MODAL_TYPES } from '../../../../../utils/generic/constants';
import ConfirmationMessage from '../../../../../generic/modals/ConfirmationMessage';
import ModalMessage from '../../../../../generic/modals/ModalMessage';
import { initialValues, layoutSchema, formSchema, columnDefs, validate } from './config';
import AdditionalInfoFinancialEntriesModal from './additionalInfoFinancialEntriesModal';
import './index.scss';

const AdditionalInfoFinancialEntries = forwardRef(({ permissions: { disableEdit } }, ref) => {
  const company = useSelector(state => state.company, shallowEqual);
  const {
    current: {
      additionalInfo,
      additionalInfo: { financialEntires },
    },
  } = company;
  const dispatch = useDispatch();

  const formRef = createRef(null);
  const financialEntiresRef = useRef(null);

  const [showEditModal, updateShowEditModal] = useState(false);
  const [rowDetails, updateRowDetails] = useState(null);
  const [errorMessage, updateErrorMessage] = useState('');
  const [deleteConfirmStatus, setDeleteConfirmStatus] = useState(false);
  const [rowId, setRowId] = useState(-1);

  useEffect(() => {
    financialEntiresRef.current = [...financialEntires];
  }, [financialEntires]);

  useImperativeHandle(ref, () => ({
    isChanged: () => {
      if (formRef.current) {
        return formRef.current.dirty;
      }
      return false;
    },
    reset: () => {
      if (formRef.current) {
        formRef.current.resetForm();
      }
    },
  }));

  const handleDelete = data => {
    updateRowDetails({
      ...data,
      financialYearEnd: data.financialYearEnd ? new Date(data.financialYearEnd) : null,
    });
    setDeleteConfirmStatus(true);
  };
  const handleEdit = data => {
    updateRowDetails({
      ...data,
      financialYearEnd: data.financialYearEnd ? new Date(data.financialYearEnd) : null,
    });
    updateShowEditModal(true);
  };

  const proceedToDelete = status => {
    if (status) {
      const currentFinancialEntries = [...financialEntires];
      currentFinancialEntries.splice(
        financialEntires.findIndex(f => f.id === rowDetails.id),
        1,
      );
      const currentAdditionalInfo = { ...additionalInfo };
      currentAdditionalInfo['financialEntires'] = currentFinancialEntries;
      dispatch(
        updateCompanyDetails({
          key: 'additionalInfo',
          data: currentAdditionalInfo,
        }),
      );
    }
    updateRowDetails(null);
    setDeleteConfirmStatus(false);
  };

  const handleClose = () => {
    updateShowEditModal(false);
    updateRowDetails(null);
  };
  const onErrorModalClose = () => {
    updateErrorMessage('');
  };
  const handleIngestionActions = (option, data) => {
    const updatedData = updateIngestedDataBasedOnFieldType({
      type: 'array',
      ingestionAction: option,
      current: financialEntiresRef.current || [],
      ingested: data,
    });
    dispatch(
      updateCompanyChildValues({
        parentKey: 'additionalInfo',
        childKey: 'financialEntires',
        data: updatedData,
      }),
    );
  };

  return (
    <>
      <div className="mt-20">
        <InputLabel labelFor="financialEntry" text="FINANCIAL ENTRY" size="16" fontStyle="bold" />
      </div>
      <Formik
        initialValues={initialValues}
        validate={async values => validate(values)}
        onSubmit={async (values, { resetForm }) => {
          const { financialYearEnd, revenue, rdSpend, noOfEmployees } = values;
          if (!rdSpend && !revenue && !noOfEmployees) {
            updateErrorMessage('Please add details for at least one of the following: Revenue or R&D Spend or Employees');
          }
          if (financialYearEnd && (revenue || rdSpend || noOfEmployees)) {
            const currentAdditionalInfo = { ...additionalInfo };
            const currentFinancialEntires = [...financialEntires];
            if (isFinancialEntriesExists(currentFinancialEntires, values)) {
              updateErrorMessage('Entry already exists for the Financial Year');
            } else {
              currentFinancialEntires.unshift(formFinancialEntryDetails({ id: rowId, ...values }));
              currentAdditionalInfo['financialEntires'] = [...currentFinancialEntires];
              dispatch(
                updateCompanyDetails({
                  key: 'additionalInfo',
                  data: currentAdditionalInfo,
                }),
              );
              setRowId(rowId - 1);
              resetForm();
              updateErrorMessage('');
            }
          }
        }}
      >
        {formikProps => {
          formRef.current = formikProps;
          return (
            <FormBuilder
              formikProps={formikProps}
              layoutSchema={layoutSchema}
              formSchema={formSchema}
              onSubmitValidationError={() => {}}
              disableControls={disableEdit || checkForIngestionAction([...financialEntires])}
            />
          );
        }}
      </Formik>
      <div className="company-financial-grid">
        <DNADataGrid
          columnDefs={columnDefs({
            handleDelete,
            handleEdit,
            handleIngestionActions,
            disableEdit,
            financialEntires,
          })}
          rowData={JSON.parse(JSON.stringify(financialEntires))}
          rowHeight={35}
          frameworkComponents={{ actions: TableActions, ingestionActions: IngestionActions }}
          getRowClass={({ data }) => (data && data.isDisabled ? 'disable-grid-row' : '')}
        />
      </div>
      {showEditModal && (
        <AdditionalInfoFinancialEntriesModal rowDetails={rowDetails} onClose={handleClose} />
      )}
      {deleteConfirmStatus && (
        <ConfirmationMessage
          isOpen
          onClose={proceedToDelete}
          message="Do you want to delete this row?"
        />
      )}
      {errorMessage && (
        <ModalMessage
          isOpen
          modalType={MODAL_TYPES.ERROR}
          onClose={onErrorModalClose}
          message={errorMessage}
        />
      )}
    </>
  );
});

AdditionalInfoFinancialEntries.propTypes = {
  permissions: PropTypes.object.isRequired,
};

export default AdditionalInfoFinancialEntries;
