import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Spinner from '../../../framework/spinner';

const GlobalSpinner = () => {
  const showSpinner = useSelector(state => state.globalState.showSpinner, shallowEqual);

  return <>{showSpinner && <Spinner />}</>;
};

export default GlobalSpinner;
