import { dateFormatter } from '../../utils/generic/helper';
import { format } from 'date-fns';

// TO DO - Remove this once url validation implemented in API side
export const getUpdatedSupportedURLs = (supportingUrls, validatedURLs) => {
  const latestURLs = supportingUrls.map(urlValue => {
    let newUrlValue = { ...urlValue };
    if (urlValue.status.value !== 'InActive') {
      let currentURL = validatedURLs.find(urlData => {
        return urlValue?.url === urlData?.config?.url;
      });

      if (currentURL?.status === 200) {
        newUrlValue.status = { id: 1, value: 'Active' };
      } else {
        newUrlValue.status = { id: 3, value: 'Broken' };
      }
    }
    return newUrlValue;
  });
  return latestURLs;
};

export const formatAncillaryRequestsData = data =>
  [...data].map((obj, index) => ({
    ...obj,
    createdDate: format(new Date(obj.createdDate), 'dd-MMM-yyyy'),
    modifiedDate: format(new Date(obj.modifiedDate), 'dd-MMM-yyyy'),
    selectedValue: index === 0,
  }));

export const convertAuditsToGridData = (data = []) => {
  return data.map(aud => ({
    ...aud,
    feedBack: [
      {
        userName: 'Vikas',
        date: new Date(),
        comment: "@Ajay, Don't spam -_- -_-",
        status: 'Open',
      },
      {
        userName: 'Ajay',
        date: new Date(),
        comment: 'Hello Vikas,Comment No:3',
        status: 'Resolved',
      },
      {
        userName: 'Vikas',
        date: new Date(),
        comment: 'Comment No:2',
        status: 'Resolved',
      },
      {
        userName: 'Ajay',
        date: new Date(),
        comment: 'Comment No:1',
        status: 'Open',
      },
    ],
  }));
};
