import { TEXT_LIMIT } from '../../utils/generic/constants';
import { createCheckIsModalSelected, getCurrentTab } from '../../utils/generic/helper';
import testData from './data.json';

export const modalPostingOptions = [
  'postingRequired',
  'postingVoluntaryOptional',
  'postingNotDiscussedInRegulation',
];

export const modalPubliclyOptions = [
  'fullyPubliclyAvailable',
  'mostPubliclyAvailable',
  'notPubliclyAvailable',
];

export const modalDateOptions = ['beforeThisDate', 'onThisDate', 'afterThisDate'];

export const modalAddtionalOptions = [
  'submissionRequired',
  'submissionOptional',
  'notCoveredRequirement',
];

export const checkIsProtocolModalSelected = (arr, isMandatory) =>
  createCheckIsModalSelected(modalPostingOptions)(arr, isMandatory);

export const checkIsModalDateSelected = (arr, isMandatory) =>
  createCheckIsModalSelected(modalDateOptions)(arr, isMandatory);

export const checkIsModalPubliclySelected = arr =>
  createCheckIsModalSelected(modalPubliclyOptions)(arr);

export const checkIsModalAddtionalSelected = arr =>
  createCheckIsModalSelected(modalAddtionalOptions)(arr);

export const validate = (currentValues, isValidate, isOtherTab, tab) => {
  const values = !isOtherTab
    ? currentValues
    : currentValues.protocolRegistrationDetailsModel?.find(value => value.tabId === tab.tabId) ||
      testData;
  const errors = {};
  const alternativeMilestoneComments = values.alternativeMilestoneComments
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const otherAdditionalInfoDescribe = values.otherAdditionalInfoDescribe
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const milestoneTimingPosted = values.milestoneTimingPosted?.replace(/(\r\n|\n|\r)/gm, '').trim();
  const milestoneTimingExceptions = values.milestoneTimingExceptions
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const otherInfoSubmitted = values.otherInfoSubmitted?.replace(/(\r\n|\n|\r)/gm, '').trim();
  const availableOtherInfoSubmitted = values.availableOtherInfoSubmitted
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const otherDelayDescribe = values.otherDelayDescribe?.replace(/(\r\n|\n|\r)/gm, '').trim();
  const otherUpdatesDueDefine = values.otherUpdatesDueDefine?.replace(/(\r\n|\n|\r)/gm, '').trim();
  const otherUpdatetypesDefine = values.otherUpdatetypesDefine
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const protocolRegistrationInternalComments = values.protocolRegistrationInternalComments
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const protocolRegistrationComments = values.protocolRegistrationComments
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const deferralTrialType = values.deferralTrialType?.replace(/(\r\n|\n|\r)/gm, '').trim();
  const deferralRequestProcess = values.deferralRequestProcess
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const otherDeferralTimePeriods = values.otherDeferralTimePeriods
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const otherDeferralExceptions = values.otherDeferralExceptions
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const deferralAuthorityNotification = values.deferralAuthorityNotification
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const otherExtensionPeriodsDesc = values.otherExtensionPeriodsDesc
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const deferralPublicInfoDesc = values.deferralPublicInfoDesc
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const postDeferralRegisterTime = values.postDeferralRegisterTime
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const ifPartialDesc = values.ifPartialDesc?.replace(/(\r\n|\n|\r)/gm, '').trim();
  const redactionJustificationDetails = values.redactionJustificationDetails
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  if (
    values.alternativeMilestoneComments?.trim() &&
    alternativeMilestoneComments.length > TEXT_LIMIT[3000]
  ) {
    errors.alternativeMilestoneComments = 'Please enter only 3000 characters';
  }
  if (
    values.protocolRegistrationComments &&
    protocolRegistrationComments.length > TEXT_LIMIT[3000]
  ) {
    errors.protocolRegistrationComments = 'Please enter only 3000 characters';
  }
  if (
    values.otherAdditionalInfoDescribe?.trim() &&
    otherAdditionalInfoDescribe.length > TEXT_LIMIT[3000]
  ) {
    errors.otherAdditionalInfoDescribe = 'Please enter only 3000 characters';
  }
  if (values.milestoneTimingPosted?.trim() && milestoneTimingPosted.length > TEXT_LIMIT[3000]) {
    errors.milestoneTimingPosted = 'Please enter only 3000 characters';
  }
  if (
    values.milestoneTimingExceptions?.trim() &&
    milestoneTimingExceptions.length > TEXT_LIMIT[3000]
  ) {
    errors.milestoneTimingExceptions = 'Please enter only 3000 characters';
  }
  if (values.otherInfoSubmitted?.trim() && otherInfoSubmitted.length > TEXT_LIMIT[3000]) {
    errors.otherInfoSubmitted = 'Please enter only 3000 characters';
  }
  if (
    values.availableOtherInfoSubmitted?.trim() &&
    availableOtherInfoSubmitted.length > TEXT_LIMIT[3000]
  ) {
    errors.availableOtherInfoSubmitted = 'Please enter only 3000 characters';
  }
  if (values.otherDelayDescribe?.trim() && otherDelayDescribe.length > TEXT_LIMIT[3000]) {
    errors.otherDelayDescribe = 'Please enter only 3000 characters';
  }
  if (values.otherUpdatesDueDefine?.trim() && otherUpdatesDueDefine.length > TEXT_LIMIT[3000]) {
    errors.otherUpdatesDueDefine = 'Please enter only 3000 characters';
  }
  if (values.otherUpdatetypesDefine?.trim() && otherUpdatetypesDefine.length > TEXT_LIMIT[400]) {
    errors.otherUpdatetypesDefine = 'Please enter only 400 characters';
  }

  if (values.deferralTrialType && deferralTrialType.length > TEXT_LIMIT[1000]) {
    errors.deferralTrialType = 'Please enter only 1000 characters';
  }
  if (values.deferralRequestProcess && deferralRequestProcess.length > TEXT_LIMIT[1000]) {
    errors.deferralRequestProcess = 'Please enter only 1000 characters';
  }
  if (values.otherDeferralTimePeriods && otherDeferralTimePeriods.length > TEXT_LIMIT[500]) {
    errors.otherDeferralTimePeriods = 'Please enter only 500 characters';
  }
  if (values.otherDeferralExceptions && otherDeferralExceptions.length > TEXT_LIMIT[500]) {
    errors.otherDeferralExceptions = 'Please enter only 500 characters';
  }
  if (
    values.deferralAuthorityNotification &&
    deferralAuthorityNotification.length > TEXT_LIMIT[1000]
  ) {
    errors.deferralAuthorityNotification = 'Please enter only 1000 characters';
  }
  if (values.otherExtensionPeriodsDesc && otherExtensionPeriodsDesc.length > TEXT_LIMIT[500]) {
    errors.otherExtensionPeriodsDesc = 'Please enter only 500 characters';
  }
  if (values.deferralPublicInfoDesc && deferralPublicInfoDesc.length > TEXT_LIMIT[1000]) {
    errors.deferralPublicInfoDesc = 'Please enter only 1000 characters';
  }
  if (values.postDeferralRegisterTime && postDeferralRegisterTime.length > TEXT_LIMIT[500]) {
    errors.postDeferralRegisterTime = 'Please enter only 500 characters';
  }
  if (values.ifPartialDesc && ifPartialDesc.length > TEXT_LIMIT[500]) {
    errors.ifPartialDesc = 'Please enter only 500 characters';
  }
  if (
    values.redactionJustificationDetails &&
    redactionJustificationDetails.length > TEXT_LIMIT[1000]
  ) {
    errors.redactionJustificationDetails = 'Please enter only 1000 characters';
  }
  if (
    values.protocolRegistrationInternalComments?.trim() &&
    protocolRegistrationInternalComments.length > TEXT_LIMIT[7000]
  ) {
    errors.protocolRegistrationInternalComments = 'Please enter only 7000 characters';
  }
  if (isValidate) {
    const protocolAdditionalInfoModel = isOtherTab
      ? getCurrentTab(currentValues.protocolAdditionalInfoModel, tab)
      : values.protocolAdditionalInfoModel;
    if (
      protocolAdditionalInfoModel?.length > 0 &&
      !checkIsModalAddtionalSelected(protocolAdditionalInfoModel)
    ) {
      errors.protocolAdditionalInfoModel = 'Please select option';
    }
    const publiclyAvailableProtocolInformationModel = isOtherTab
      ? getCurrentTab(currentValues.publiclyAvailableAdditionalInfoModel, tab)
      : values.publiclyAvailableProtocolInformationModel;
    if (
      publiclyAvailableProtocolInformationModel?.length > 0 &&
      !checkIsModalPubliclySelected(publiclyAvailableProtocolInformationModel)
    ) {
      errors.publiclyAvailableProtocolInformationModel = 'Please select option';
    }
    const publiclyAvailableAdditionalInfoModel = isOtherTab
      ? getCurrentTab(currentValues.publiclyAvailableProtocolInformationModel, tab)
      : values.publiclyAvailableAdditionalInfoModel;
    if (
      publiclyAvailableAdditionalInfoModel?.length > 0 &&
      !checkIsModalPubliclySelected(publiclyAvailableAdditionalInfoModel)
    ) {
      errors.publiclyAvailableAdditionalInfoModel = 'Please select option';
    }
    if (currentValues.isMandatory) {
      if (values.isProtocolRegistered === null || values.isProtocolRegistered === undefined) {
        errors.isProtocolRegistered = 'Please select protocol registered';
      }
    }

    if (values.isProtocolRegistered) {
      if (values.isProtocolUpdatePosted) {
        if (values.protocolUpdateRegistered === null) {
          errors.protocolUpdateRegistered = 'Please select protocol update registered option';
        }
      }
      if (values.protocolRegistered === null) {
        errors.protocolRegistered = 'Please select protocol registered option';
      }
      const protocolsRegisteredModel = isOtherTab
        ? getCurrentTab(currentValues.protocolsRegisteredModel, tab)
        : values.protocolsRegisteredModel;
      if (!checkIsProtocolModalSelected(protocolsRegisteredModel, currentValues.isMandatory)) {
        errors.protocolsRegisteredModel = 'Please select required data';
      }
      const requirementMilestonesModel = isOtherTab
        ? getCurrentTab(currentValues.requirementMilestonesModel, tab)
        : values.requirementMilestonesModel;
      if (!checkIsModalDateSelected(requirementMilestonesModel, currentValues.isMandatory)) {
        errors.requirementMilestonesModel = 'Please select required data';
      }
      if (currentValues.isMandatory) {
        const isAlternativeMilestoneSelected = requirementMilestonesModel?.find(
          ({ requirementMilestones }) =>
            requirementMilestones?.value === 'Other Alternate milestone (defined below)',
        );
        const protocolAdditionalInfoModel = isOtherTab
          ? getCurrentTab(currentValues.protocolAdditionalInfoModel, tab)
          : values.protocolAdditionalInfoModel;
        const isOtherSelected = protocolAdditionalInfoModel?.find(
          ({ protocolAdditionalInfo }) =>
            protocolAdditionalInfo?.value.trim() === 'Other (described below)',
        );
        if (isOtherSelected && !values.otherInfoSubmitted?.trim()) {
          errors.otherInfoSubmitted = 'Please enter the description';
        }
        const publiclyAvailableAdditionalInfoModel = isOtherTab
          ? getCurrentTab(currentValues.publiclyAvailableAdditionalInfoModel, tab)
          : values.publiclyAvailableAdditionalInfoModel;
        const isProtocolOtherSelected = publiclyAvailableAdditionalInfoModel?.find(
          ({ publiclyAvailableAdditionalInformation }) =>
            publiclyAvailableAdditionalInformation?.value.trim() === 'Other (described below)',
        );
        if (isProtocolOtherSelected && !values.availableOtherInfoSubmitted?.trim()) {
          errors.availableOtherInfoSubmitted = 'Please enter the data';
        }

        const protocolRequirementUpdateTypesModel = isOtherTab
          ? getCurrentTab(currentValues.protocolRequirementUpdateTypesModel, tab)
          : values.protocolRequirementUpdateTypesModel;
        const isOtherUpdateType = protocolRequirementUpdateTypesModel?.find(
          ({ requirementUpdateTypes }) =>
            requirementUpdateTypes?.value.trim() === 'Other (list below)',
        );
        if (isOtherUpdateType && !values.otherUpdatetypesDefine?.trim()) {
          errors.otherUpdatetypesDefine = 'Please enter the data';
        }
        const protocolRequirementimeFramesModel = isOtherTab
          ? getCurrentTab(currentValues.protocolRequirementimeFramesModel, tab)?.map(
              x => x.requirementimeFrames,
            )
          : values.protocolRequirementimeFramesModel;
        const isDuesOtherSelected = protocolRequirementimeFramesModel?.find(
          ({ value }) => value === 'Other',
        );
        if (
          !isOtherTab &&
          values?.isOtherDueCurrentlySelected &&
          !values.otherUpdatesDueDefine?.trim()
        ) {
          errors.otherUpdatesDueDefine = 'Please enter the data';
        }
        if (isOtherTab && isDuesOtherSelected && !values.otherUpdatesDueDefine?.trim()) {
          errors.otherUpdatesDueDefine = 'Please enter the data';
        }
        if (isAlternativeMilestoneSelected && !values.alternativeMilestoneComments?.trim()) {
          errors.alternativeMilestoneComments = 'Please enter the alternative milestone comments';
        }

        if (milestoneTimingExceptions?.length === 0) {
          errors.milestoneTimingExceptions = 'Please enter valid data';
        }
        if (milestoneTimingPosted?.length === 0) {
          errors.milestoneTimingPosted = 'Please enter valid data';
        }

        if (values?.isEarliestMilestoneDate === null) {
          errors.isEarliestMilestoneDate = 'Please select regulation require';
        }
        if (values?.isEarliestMilestoneDate !== null) {
          if (values?.isEarliestMilestoneDate === 1 && otherAdditionalInfoDescribe.length === 0) {
            errors.otherAdditionalInfoDescribe = 'Please enter valid data';
          }
        }
        if (values.isProtocolPubliclyAvailable === null) {
          errors.isProtocolPubliclyAvailable = 'Please select protocol information';
        }
        if (
          values?.isProtocolPubliclyAvailable === 1 ||
          values?.isProtocolPubliclyAvailable === true
        ) {
          const publiclyAvailableProtocolInformationModel = isOtherTab
            ? getCurrentTab(currentValues.publiclyAvailableAdditionalInfoModel, tab)
            : values.publiclyAvailableProtocolInformationModel;
          if (publiclyAvailableProtocolInformationModel?.length === 0) {
            errors.publiclyAvailableProtocolInformationModel = 'Please select option';
          }
          if (values?.protocolRequirementEndPointsModel?.length === 0) {
            errors.protocolRequirementEndPointsModel = 'Please select required data';
          }
          if (values?.isDelayDisclosureProtocol === null) {
            errors.isDelayDisclosureProtocol = 'Please select required data';
          }
          if (values?.isDelayDisclosureProtocol !== null) {
            if (
              (values?.isDelayDisclosureProtocol === 1 ||
                values?.isDelayDisclosureProtocol === true) &&
              values?.otherDelayDescribe?.trim()?.length === 0
            ) {
              errors.otherDelayDescribe = 'Please enter valid data';
            }
          }
        }
      }
    }
  }

  return errors;
};
