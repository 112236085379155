import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import notesConfig from './notesConfig';
import { FormBuilder, DNADataGrid, TableActions, DefaultCellRender, Pagination, } from '../../../framework';
import NotesModal from './notesModal';
import ConfirmationMessage from '../../modals/ConfirmationMessage';
import ModalMessage from '../../modals/ModalMessage';
import { MODAL_TYPES, ALERT_MESSAGES, TABLE_FILTER_OPTIONS } from '../../../utils/generic/constants';
import {
  getFormattedDate,
  checkIfStringIsEmpty,
  highlightText,
  htmlencode
} from '../../../utils/generic/helper';
import { InputSearch, InputLabel, InputSelect } from '../../../framework/inputs';
import CopyContentModal from '../../../framework/copyContentModal';
import { showModal } from '../../../store/actions/globalActions';
import { validate } from './notesConfig';
import './index.scss';

const Notes = forwardRef(
  ({ notesData, noteTypes, updateNotes, disableEdit, type, handleSearchText, isChanged }, ref) => {
    const dispatch = useDispatch();
    const { initialValues, formSchema, layoutSchema, columnDefs, isNotesExists } = notesConfig;
    const [editModalStatus, setEditModalStatus] = useState(false);
    const [rowDetails, setRowDetails] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [deleteConfirmStatus, setDeleteConfirmStatus] = useState(false);
    const [taxGridData, setTaxGridData] = useState([...notesData]);
		const [currentPageData, setCurrentPageData] = useState(null)
		const [dropDown, setDropDown] = useState({ value: 10, label: '10 Results' });
		const [curPageSize, setCurPageSize] = useState(dropDown.value);
		const [curPage, setCurPage] = useState(1);
		const [paginationText, setPaginationText] = useState('');
    const [searchText, setSearchText] = useState('');
    const [highlightSearchText, setHighlightSearchText] = useState('');
    const [searchError, setSearchError] = useState('');
		const notesDataListRef = useRef({ notes: [...notesData] })
    const notesRef = useRef(null);
		const [curSortOrder, setCurSortOrder] = useState('')
		
		useEffect(() => {
			notesDataListRef.current.notes = [...notesData]
			sortGridByOrder(curSortOrder, notesData)
      sortGridByString(curSortOrder, notesData)
		}, [notesData, noteTypes]);

		useEffect(() => {
			const gridData = pageGridData(curPage, curPageSize)
			setCurrentPageData(gridData);
		}, [curPage, curPageSize, taxGridData])

    const updatedNoteTypes = noteTypes.map(n => {
      return { ...n, display: n.value };
    });

    const updatedFormSchema = {
      ...formSchema,
      type: { ...formSchema.type, options: updatedNoteTypes },

      date: {
        ...formSchema.date,
        props: {
          ...formSchema.date.props,
          disableFuture: type === 'organization',
        },
      },
    };

    useImperativeHandle(ref, () => ({
      resetFilters: () => {
        setSearchText('');
        setHighlightSearchText('');
      },

      isChanged: () => notesRef.current.dirty || searchText,
      reset: () => {
        notesRef.current.resetForm();

        if (highlightSearchText.trim()) {
          handleSearchText('');
        }
        setSearchText('');
        setHighlightSearchText('');
      },
    }));

		//calculate total rows in grid
		const pageGridData = (pageIndex, pageSize) => {
			const start = ((pageIndex - 1) * pageSize)
			const end =  (pageIndex * pageSize)
			return taxGridData.slice(start, end)
		}

		const onFilterNotesResults = (pageIndex, pageSize) => {
			setCurPageSize(pageSize);
			setCurPage(pageIndex);
		};

		function sortGridByOrder (sortOrder, notesList) {
			
			let sortedData = [...notesList]
			if (sortOrder === 'asc') {
						sortedData = sortedData.sort((a, b) => {
						if (new Date(a.date) < new Date(b.date)) {
							return -1; 
						}
						if (new Date(a.date) > new Date(b.date)) {
							return 1; 
						}
						return 0;
					})
			}
			if (sortOrder === 'desc') {
					sortedData = sortedData.sort((a, b) => {
						if (new Date(b.date) < new Date(a.date)) {
							return -1; 
						}
						if (new Date(b.date) > new Date(a.date)) {
							return 1; 
						}
						return 0;
					})
			}
			
			setTaxGridData(sortedData)
			sortOrder === '' && setTaxGridData(notesDataListRef.current.notes)

		}
    
    function sortGridByString (sortOrder, notesList) {
			let sortedData = [...notesList]
			if (sortOrder === 'asc') {
        sortedData = sortedData.sort((a, b) => {
        if (a.text < b.text) {
          return -1; 
        }
        if (a.text > b.text) {
          return 1; 
        }
        return 0;
      })
			}
			if (sortOrder === 'desc') {
        sortedData = sortedData.sort((a, b) => {
          if (b.text < a.text) {
            return -1; 
          }
          if (b.text > a.text) {
            return 1; 
          }
          return 0;
        })
			}

			setTaxGridData(sortedData)
      notesDataListRef.current.notes = [...sortedData];
			sortOrder === '' && setTaxGridData(notesDataListRef.current.notes)

		}

		const handleSortChanged = ({ api }) => {
			const sortState = api.getSortModel();
			let sortedData = [...notesData]

      if (sortState.length > 0) {
      const [{ sort, colId }] =  sortState
        if(colId == 'date'){
          if(sort !== ''){
            setCurSortOrder(sort)
            sortGridByOrder(sort, sortedData)
          } 
        } else if(colId == 'text'){
          if(sort != ''){
            setCurSortOrder(sort)
            sortGridByString(sort, sortedData)
          }
        }
      } else {
        setCurSortOrder('')
        setTaxGridData(notesDataListRef.current.notes)
      }
		}
	
    const addRowData = values => {
      let noteTextUpdated = htmlencode(values.text)
      values.text = noteTextUpdated
      let updatedGridData = [values, ...notesDataListRef.current.notes];
      // setTaxGridData(updatedGridData);
      updateNotes(updatedGridData, true);
    };
    const handleEdit = (rowData, rowIndex) => {
      setRowDetails({ rowData, rowIndex });
      setEditModalStatus(true);
    };
    const onRowDetailsUpdate = data => {
      if (data) {
        const updatedGridData = [...notesDataListRef.current.notes];
        updatedGridData.splice(data.rowIndex, 1, data.rowData);
        // setTaxGridData(updatedGridData)
        updateNotes(updatedGridData);
      }
      setEditModalStatus(false);
      setRowDetails(null);
    };

    const handleDelete = (rowData, rowIndex) => {
      setRowDetails({ rowData, rowIndex });
      setDeleteConfirmStatus(true);
    };
    const proceedToDelete = status => {
      if (status && rowDetails) {
        const updatedGridData = [...notesDataListRef.current.notes];
        updatedGridData.splice(rowDetails.rowIndex, 1);
        // setTaxGridData(updatedGridData)
        updateNotes(updatedGridData);
      }
      setRowDetails(null);
      setTimeout(() => {
        setDeleteConfirmStatus(false);
      }, 1000);
    };
    const handleSearchTextChange = event => {
      setSearchText(event.target.value);
      setSearchError('');
    };

    const handleSearchNoteText = event => {
      if (event === 'SEARCH_CLICK_EVENT' || event.keyCode === 13) {
        if (isChanged()) {
          dispatch(showModal(ALERT_MESSAGES.NOTES_SEARCH_ERROR));
        } else if (taxGridData.length === 0 && searchText.length == 0) {
          handleSearchText('');
        } else if (searchText.trim()) {
          setHighlightSearchText(searchText);
          handleSearchText(searchText);
        } else {
          setSearchError('Please enter the text to search');
        }
      }
    };
    const handleClearSearch = () => {
      if (isChanged() === false) {
        handleSearchText('');
        setSearchText('');
        setHighlightSearchText('');
      } else {
        dispatch(showModal(ALERT_MESSAGES.NOTES_SEARCH_ERROR));
      }
    };

    const customCellRenderWithModalButton = ({ value }) => (
      <CopyContentModal>
        <span
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: highlightText(value, searchText.trim()),
          }}
        ></span>
      </CopyContentModal>
    );

    return (
      <div className="generic-notes-wrapper">
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { resetForm }) => {
            let updatedValues = {
              ...values,
              type: noteTypes.filter(n => n.id === values.type)[0],
              //date: values.date ? getFormattedDate(values.date) : null,
              date: values.date || null,

            };
            if (isNotesExists(taxGridData, updatedValues)) {
              setErrorMessage('Combination already exists');
            } else {
              addRowData(updatedValues);
              resetForm();
            }
          }}
          validate={async values => validate(values, type)}
        >
          {props => {
            notesRef.current = props;
            return (
              <Box p={2}>
                <div className="form-container">
                  <FormBuilder
                    formikProps={props}
                    layoutSchema={layoutSchema}
                    formSchema={updatedFormSchema}
                    onSubmitValidationError={() => {}}
                    disableControls={disableEdit}
                  />
                </div>
              </Box>
            );
          }}
        </Formik>
				<Grid item xs={12}>
							{type === 'organization' || type === 'person' ? (
								<div className="notes-search-panel">
									<div className="search-input">
										<InputSearch
											label="SEARCH"
											name="navLabel"
											onKeyDown={handleSearchNoteText}
											onClickSearchIcon={() => handleSearchNoteText('SEARCH_CLICK_EVENT')}
											onChange={handleSearchTextChange}
											value={searchText}
											isClearable={!checkIfStringIsEmpty(searchText)}
											handleClearSearch={handleClearSearch}
										/>
										{searchError && checkIfStringIsEmpty(searchText) && (
											<p className="error-text">{searchError}</p>
										)}
									</div>
								</div>
							) : null}
							<div className="table-filter-section">
								<p className="top-pagination-text">{paginationText}</p>
								<div className="optionsRightSide">
									<div className="notes-results-dropdown">
										<InputSelect
												height="ht-lg"
												defaultValue={dropDown}
												data={TABLE_FILTER_OPTIONS}
												onChange={(val) => {
													onFilterNotesResults(1, val.value)
													setDropDown(val);
												}}
										/>
									</div>
								</div>
						</div>
        </Grid>

        <div className="data-grid-container">
          <Box p={3}>
            <DNADataGrid
              columnDefs={columnDefs(handleDelete, handleEdit, disableEdit)}
              rowData={JSON.parse(JSON.stringify(currentPageData))}
              frameworkComponents={{
                actions: TableActions,
                DefaultCellRender,
                customCellRenderWithModalButton,
              }}
              key={`${highlightSearchText}`}
							handleSortChanged={handleSortChanged}
            />
          </Box>
        </div>
				<div className="notes-pagination">
					<Pagination
						pageSize={curPageSize}
						pageNo={curPage}
						totalRows={taxGridData?.length}
						onChange={pageIndex => {
							onFilterNotesResults(pageIndex, dropDown.value);
						}}
						showPaginationText={text => {
							setPaginationText(text);
						}}
						showBottomPaginationText={false}
					/>
				</div>
        {deleteConfirmStatus && (
          <ConfirmationMessage
            isOpen
            onClose={proceedToDelete}
            message="Do you really want to delete this Note?"
          />
        )}
        {editModalStatus && (
          <NotesModal
            notesConfig={notesConfig}
            row={rowDetails}
            onClose={onRowDetailsUpdate}
            formSchema={updatedFormSchema}
            noteTypes={noteTypes}
            notes={taxGridData}
            type={type}
          />
        )}
        {errorMessage && (
          <ModalMessage
            isOpen
            modalType={MODAL_TYPES.ERROR}
            onClose={() => setErrorMessage(null)}
            message={errorMessage}
          />
        )}
      </div>
    );
  },
);

Notes.propTypes = {
  notesData: PropTypes.array.isRequired,
  noteTypes: PropTypes.array.isRequired,
  updateNotes: PropTypes.func.isRequired,
  disableEdit: PropTypes.bool,
  type: PropTypes.string,
  handleSearchText: PropTypes.func,
  isChanged: PropTypes.func,
};

Notes.defaultProps = {
  notesData: [],
  noteTypes: [
    {
      id: 1,
      value: 'public',
    },
    {
      id: 2,
      value: 'private',
    },
  ],
  disableEdit: false,
};
Notes.displayName = 'Notes';

export default Notes;
