import { createReducer } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';

import InitialState from '../initialState';

import {
  getTrialListSuccess,
  getMasterDataSuccess,
  featureSelection,
  nextFeatureSelection,
  setTrialsFeatureData,
  setTrialNotesData,
  setTrialAssociationsData,
  setTrialResultData,
  setLocationsData,
  updateTrialDetails,
  resetTrialsData,
  resetTrialState,
  saveTrialsData,
  saveTrialsDataSuccess,
  resetMasterData,
  deleteTrialDataSuccess,
  searchOrganizationAndPersonDataSuccess,
  resetImportContactsList,
  dashboardFeatureSelection,
  setSelectedQueueType,
  getQueueRecordsSuccess,
  getTaskQueueRecordsSuccess,
  getQueueTypesSuccess,
  getTaskQueueTypesSuccess,
  searchTrialResultsSuccess,
  resetTrialResultsSearchList,
  updateSearchTrialResults,
  searchTrialNotesSuccess,
  resetTrialNotesSearchList,
  updateSearchTrialNotes,
  getTrialsUserLockDetails,
  resetTrialUserLock,
  setPreviewSelection,
  resetIngestedData,
  setIngestedData,
  updateCurrentDataAndIngestedAction,
  updateTrialChildValues,
  setTrialTimingErrors,
  getTrialList,
  getQueueRecords,
  getSponsorTypeSuccess,
  setQueueExpandedList,
  setQueueTransactionId,
  getProximityDataSuccess,
  setTrialValidationStatus,
  setTrialChangeStatus,
  updateTrialResultsAndNotesSearchDataForIngestion,
  resetTrialsProximityData,
  getProximityCompareDataSuccess,
  resetCompareIngestedData,
  // resetTrialStampingDetails,
  getTrialStampingDetailsSuccess,
  setMaxTemplateID,
  setShowTrialsValidationModal,
} from '../actions/clinicalTrials';
import {
  setMasterData,
  setTrialData,
  getImportedContactsData,
  setCurrentAndIngestedData,
  validateCustomDate,
  updateTrialsDataOnSave,
  getNextIngestedTab,
  getTrialStampDates,
  updateTrialStampingDetails,
} from '../helpers/clinicalTrials';
import { resetIngestionAction, checkIfObjectIsEmpty } from '../../utils/generic/helper';
import validateTabs from '../../utils/generic/validation';

const initialState = InitialState.clinicalTrials;

const clinicalTrials = createReducer(initialState, {
  [getTrialsUserLockDetails]: (state, { payload: { isEditable, lockedBy, userLockPeriod } }) => {
    const updatedData = {
      isLocked: !isEditable,
      lockedBy,
      userLockPeriod,
    };
    state.trialLockDetails = updatedData;
  },
  [resetTrialUserLock]: state => {
    const { trialLockDetails } = initialState;
    state.trialLockDetails = trialLockDetails;
  },
  [getMasterDataSuccess]: (state, { payload: data }) => {
    const updatedData = setMasterData(data);
    state.masterData = updatedData;
  },
  [featureSelection]: (state, { payload: { value } }) => {
    state.selected = value;
  },
  [nextFeatureSelection]: (state, { payload }) => {
    const index = state.tabs.findIndex(i => i.value === payload);
    const isSingleModeActive = state.isSinglePageMode;
    if (isSingleModeActive) {
      const { ingestedTab, activeTabIndex } = getNextIngestedTab(
        [...state.tabs],
        state.previewSelected,
      );
      state.previewSelected = ingestedTab
        ? ingestedTab.value
        : state.tabs[activeTabIndex + 1].value;
    } else {
      state.selected = index != -1 ? state.tabs[index + 1].value : state.selected;
    }
  },
  [setTrialsFeatureData]: (state, { payload: data }) => {
    const updatedData = setTrialData(data);
    state.original = updatedData;
    state.current = updatedData;

    state.originalStampDates = getTrialStampDates(state.original.trialSummary);
  },

  [setTrialNotesData]: (state, { payload: data }) => {
    state.original.trialNotes = {
      notesData: data.results[0]?.result || [],
      rowCount: data.rowCount,
      maxTemplateID: data.results[0]?.maxTemplateID || 0,
    };
    state.current.trialNotes = {
      notesData: data.results[0]?.result || [],
      rowCount: data.rowCount,
      maxTemplateID: data.results[0]?.maxTemplateID || 0,
    };
  },

  [setMaxTemplateID]: (state, { payload: { data, type } }) => {
    if (type === 'notes') {
      state.original.trialNotes.maxTemplateID = data;
      state.current.trialNotes.maxTemplateID = data;
    }
    if (type === 'results') {
      state.original.trialResults.maxTemplateID = data;
      state.current.trialResults.maxTemplateID = data;
    }
  },

  [setTrialAssociationsData]: (state, { payload: data }) => {
    const trialContactsObject = {
      ...data?.results[0],
      contactList: data?.results[0]?.result || [],
      rowCount: data?.rowCount || 0,
    };
    delete trialContactsObject['result'];
    state.original.trialContacts = trialContactsObject;
    state.current.trialContacts = trialContactsObject;
  },

  [setTrialResultData]: (state, { payload: data }) => {
    state.original.trialResults = {
      resultsData: data.results[0]?.result || [],
      rowCount: data.rowCount,
      maxTemplateID: data.results[0]?.maxTemplateID || 0,
    };
    state.current.trialResults = {
      resultsData: data.results[0]?.result || [],
      rowCount: data.rowCount,
      maxTemplateID: data.results[0]?.maxTemplateID || 0,
    };

    // state.original.trialResults = data.trialResult;
    // state.original.trialResults.resultsData = data.trialResult?.resultsData || [];
    // state.current.trialResults = data.trialResult;
    // state.current.trialResults.resultsData = data.trialResult?.resultsData || [];
  },

  [setLocationsData]: (state, { payload: data }) => {
    state.current.trialSummary.allLocations = data;
    state.original.trialSummary.allLocations = data;
  },

  [setShowTrialsValidationModal]: (state, { payload }) => {
    state.original.showTrialsValidationModal = payload.isOpen;
    state.original.trialsValidationMessages = payload.messages;
  },

  [updateTrialDetails]: (state, { payload: { key, data } }) => {
    state.current[key] = data;
  },
  [saveTrialsData]: (state, { payload: { key } }) => {
    const selected = state.tabs.find(tab => tab.key === key);
    if (selected) {
      selected.hasError = false;
    }
  },
  [saveTrialsDataSuccess]: (state, { payload: { data, key, currentTab } }) => {
    if (data && key) {
      const { ingested } = initialState;
      const { updatedCurrentData, updatedOriginalData } = updateTrialsDataOnSave({
        apiData: data,
        key,
        currentData: { ...state.current },
        ingestedApiData: { ...state.ingestedApiData },
        currentTab,
      });
      state.current = updatedCurrentData;
      state.original = updatedOriginalData;

      state.originalStampDates = getTrialStampDates(state.original.trialSummary);
      state.isStamped = false;

      const tabIndex = state.tabs.findIndex(tab => tab.key === key);
      if (tabIndex > -1) {
        if (state.ingested[key] && ingested[key]) {
          state.ingested[key] = ingested[key];
          state.tabs[tabIndex].hasIngestedData = false;
        }
        state.tabs[tabIndex].hasUnSavedData = false;
      }
    }
  },
  [setTrialValidationStatus]: (state, { payload: tabs = [] }) => {
    tabs.forEach(({ index, isValid }) => {
      state.tabs[index].hasError = !isValid;
    });
  },
  [setTrialChangeStatus]: (state, { payload: tabs = [] }) => {
    tabs.forEach(({ index, hasUnSavedData }) => {
      state.tabs[index].hasUnSavedData = hasUnSavedData;
    });
  },
  [resetTrialsData]: (state, { payload }) => {
    const selected = state.tabs.find(tab => tab.key === payload);
    if (selected && selected.hasIngestedData) {
      selected.hasUnSavedData = false;
      const { ingestedData, updatedCurrentData } = resetIngestionAction(
        state.ingested[payload],
        state.current[payload],
        state.original[payload],
      );
      state.ingested[payload] = ingestedData;
      state.current[payload] = updatedCurrentData;
    } else {
      if (selected) {
        selected.hasUnSavedData = false;
        selected.hasError =
          selected.hasError &&
          Boolean(validateTabs.clinicalTrial[payload]) &&
          !checkIfObjectIsEmpty(
            validateTabs.clinicalTrial[payload](state.original[payload], state.original),
          );
      }
      state.current[payload] = state.original[payload];
    }
  },
  [resetTrialState]: state => {
    const {
      selected,
      tabs,
      original,
      current,
      ingested,
      trialTimingErrors,
      originalStampDates,
    } = initialState;
    state.selected = selected;
    state.tabs = tabs;
    state.original = original;
    state.current = current;
    state.ingested = ingested;
    state.trialTimingErrors = trialTimingErrors;
    state.isStamped = false;
    state.originalStampDates = originalStampDates;
  },
  [getTrialStampingDetailsSuccess]: (state, { payload: { data } }) => {
    state.isStamped = true;
    const { current, original } = updateTrialStampingDetails(
      data.trialSummary,
      state.current,
      state.original,
    );
    state.current = current;
    state.original = original;
  },
  [resetMasterData]: state => {
    const { masterData } = initialState;
    state.masterData = masterData;
  },
  [getTrialList]: state => {
    state.dashboard.queues.queueRecords = {};
  },
  [getTrialListSuccess]: (state, { payload }) => {
    state.dashboard.queues.queueRecords = payload;
  },
  [deleteTrialDataSuccess]: (state, { payload }) => {
    const results = state.dashboard.queues.queueRecords.results;
    state.dashboard.queues.queueRecords.results = results.filter(
      trial => trial.trialId !== payload,
    );
  },
  [searchOrganizationAndPersonDataSuccess]: (state, { payload }) => {
    const data = {
      ...payload,
      roleData: state.masterData.roleMasters,
    };
    state.importContactsList = getImportedContactsData(data, [
      ...state.current.trialContacts.contactList,
    ]);
  },

  [searchTrialResultsSuccess]: (state, { payload }) => {
    state.trialResultsSearchList = [...payload.resultsData];
  },

  [resetImportContactsList]: state => {
    const { importContactsList } = initialState;
    state.importContactsList = importContactsList;
  },
  [dashboardFeatureSelection]: (state, { payload }) => {
    state.dashboardSelected = payload;
  },
  [setSelectedQueueType]: (state, { payload }) => {
    state.dashboard.queues.queueTypeSelected = payload;
  },
  [setQueueExpandedList]: (state, { payload }) => {
    state.dashboard.queues.queueExpandedList = payload;
  },
  [getQueueRecords]: (state, { payload: { emptyRecords } }) => {
    let queueRecords = emptyRecords ? {} : state.dashboard.queues.queueRecords;
    state.dashboard.queues.queueRecords = queueRecords;
  },
  [getQueueRecordsSuccess]: (state, { payload }) => {
    state.dashboard.queues.queueRecords = payload;
  },
  [getTaskQueueRecordsSuccess]: (state, { payload }) => {
    state.dashboard.taskQueues.taskQueueRecords = payload;
  },
  [getQueueTypesSuccess]: (state, { payload }) => {
    let { data = [] } = payload;
    state.dashboard.queues.queueTypes = data;
  },
  [getTaskQueueTypesSuccess]: (state, { payload }) => {
    let { data = [] } = payload;
    state.dashboard.taskQueues.taskQueueTypes = data;
  },
  [setQueueTransactionId]: (state, { payload }) => {
    state.queueTransactionId = payload;
  },

  [resetTrialResultsSearchList]: state => {
    const { trialResultsSearchList } = initialState;
    state.trialResultsSearchList = trialResultsSearchList;
  },

  [updateSearchTrialResults]: (state, { payload }) => {
    state.trialResultsSearchList = [...payload];
  },

  [searchTrialNotesSuccess]: (state, { payload }) => {
    state.trialNotesSearchList = [...payload.notesData];
  },

  [resetTrialNotesSearchList]: state => {
    const { trialNotesSearchList } = initialState;
    state.trialNotesSearchList = trialNotesSearchList;
  },

  [updateSearchTrialNotes]: (state, { payload }) => {
    state.trialNotesSearchList = [...payload];
  },
  [setPreviewSelection]: (state, { payload: { val, isActive } }) => {
    state.previewSelected = val;
    state.isSinglePageMode = isActive;
  },

  [setIngestedData]: (state, { payload }) => {
    const {
      updatedIngestedData,
      updatedCurrentData,
      ingestedApiData,
      updatedTabs,
    } = setCurrentAndIngestedData({ ...state.current }, { ...payload }, [...state.tabs]);
    state.ingested = { ...updatedIngestedData };
    state.current = { ...updatedCurrentData };
    state.ingestedApiData = { ...ingestedApiData };
    state.tabs = [...updatedTabs];
  },

  [resetIngestedData]: state => {
    const { ingested } = initialState;
    state.ingested = ingested;
  },

  [updateCurrentDataAndIngestedAction]: (
    state,
    { payload: { parentKey, childKey, ingestionAction, data } },
  ) => {
    const ingestedData = state.ingested[parentKey];
    ingestedData.fieldActions[childKey] = ingestionAction;
    state.ingested[parentKey] = ingestedData;
    if (!isUndefined(data)) {
      const currentData = state.current[parentKey];
      currentData[childKey] = data;
      state.current[parentKey] = currentData;
    }
  },
  [updateTrialChildValues]: (state, { payload: { parentKey, childKey, data, tempParentKey } }) => {
    const currentData = state.current[parentKey];
    currentData[childKey] = data;
    state.current[parentKey] = currentData;
    if (state.current[tempParentKey]?.[childKey]?.length) {
      state.current[tempParentKey][childKey] = data;
    } else state.current[tempParentKey] = currentData;
  },
  [setTrialTimingErrors]: (state, { payload }) => {
    state.trialTimingErrors = payload;
  },
  [getSponsorTypeSuccess]: (state, { payload }) => {
    state.sponsorTypeList = payload;
  },
  [getProximityDataSuccess]: (state, { payload }) => {
    state.proximityComparisonData = payload;
  },

  [getProximityCompareDataSuccess]: (state, { payload }) => {
    state.proximityComparisonRecData = payload;
  },
  [resetCompareIngestedData]: (state, { payload }) => {
    const { proximityComparisonRecData } = initialState;
    state.proximityComparisonRecData = proximityComparisonRecData;
  },

  [updateTrialResultsAndNotesSearchDataForIngestion]: (state, { payload }) => {
    const { key, ingestedData } = payload;
    const {
      current: {
        trialResults: { resultsData },
        trialNotes: { notesData },
      },
    } = state;
    const currentData = key === 'trialResults' ? resultsData : notesData;
    const updatedData = currentData.map(
      c => ingestedData.find(i => i.templateId === c.templateId) || c,
    );
    if (key === 'trialResults') {
      state.trialResultsSearchList = [...ingestedData];
      state.current.trialResults = {
        ...state.current.trialResults,
        resultsData: [...updatedData],
      };
    } else {
      state.trialNotesSearchList = [...ingestedData];
      state.current.trialNotes = {
        ...state.current.trialNotes,
        notesData: [...updatedData],
      };
    }
  },
  [resetTrialsProximityData]: state => {
    state.proximityComparisonData = { ...initialState.proximityComparisonData };
  },
});

export default clinicalTrials;
