import React, { useRef } from 'react';
import { Formik } from 'formik';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { addDays } from 'date-fns';

import { FormBuilder } from '../../../framework';
import Modal from '../../../framework/modal';
import { initialValues, layoutSchema, formSchema, validate } from './config';
import './index.scss';

const TaskModal = ({
  getTaskMasterData,
  handleClose,
  handleSubmit,
  isEditModal,
  rowData,
}) => {
  const taskFormRef = useRef(null);
  const { assigneeUserGroupsListData } = useSelector(state => state.task, shallowEqual);
  const handleDueDateChange = value => {
    const updatingSchema = days => {
      const due_date = addDays(new Date(initialValues.createdDate), days);
      taskFormRef.current.setFieldValue('dueDate', due_date);
    };
    switch (value.label) {
      case '24 hrs':
        updatingSchema(1);
        break;
      case '48 hrs':
        updatingSchema(2);
        break;
      case '72 hrs':
        updatingSchema(3);
        break;
      case '1 Week':
        updatingSchema(7);
        break;
      case '2 Week':
        updatingSchema(14);
        break;
      case '1 Month':
        updatingSchema(30);
        break;
      case '2 Month':
        updatingSchema(60);
        break;
      default:
    }
  };

  const updatedFormSchema = {
    ...formSchema,
    ...(isEditModal && {
      taskName: {
        ...formSchema.taskName,
        props: { ...formSchema.taskName.props, disabled: true },
      },
    }),
    assignee: {
      ...formSchema.assignee,
      options: assigneeUserGroupsListData.map(assignee => ({
        id: assignee.groupId,
        value: assignee.groupName,
        childrens: assignee.users.map(item => ({
          id: item.id,
          value: item.name,
        })),
      })),
    },
    taskType: {
      ...formSchema.taskType,
      options: getTaskMasterData?.taskType.map(item => ({
        ...item,
        label: item.value,
        value: item.id,
      })),
    },
    taskStatus: {
      ...formSchema.taskStatus,
      options: getTaskMasterData?.taskStatus.map(item => ({
        ...item,
        label: item.value,
        value: item.id,
      })),
    },
    taskUrgency: {
      ...formSchema.taskUrgency,
      options: getTaskMasterData?.taskUrgency.map(item => ({
        ...item,
        label: item.value,
        value: item.id,
      })),
    },
    tat: {
      ...formSchema.tat,
      options: getTaskMasterData?.tat.map(item => ({
        label: item.value,
        value: item.id,
      })),
      props: {
        ...formSchema.tat.props,
        handleSelectChange: value => handleDueDateChange(value),
      },
    },
    recurrenceFrequency: {
      ...formSchema.recurrenceFrequency,
      options: getTaskMasterData?.recurrenceFrequency.map(item => ({
        label: item.value,
        value: item.id,
      })),
    },
    dueDate: {
      ...formSchema.dueDate,
      props: {
        ...formSchema.dueDate.props,
      },
    },
    cancel: {
      ...formSchema.cancel,
      props: {
        ...formSchema.cancel.props,
        onClick: handleClose,
      },
    },
  };

  const taskType = updatedFormSchema.taskType.options.filter(
    option => rowData.type === option.label,
  )[0];
  const taskStatus = updatedFormSchema.taskStatus.options.filter(
    option => rowData.status === option.label,
  )[0];

  let assign_ary = [];
  if (rowData?.assignee?.length > 0) {
    const groupAssignees = rowData?.assignee?.reduce((prev, current, index, arr) => {
      const {
        assignee: {
          user,
          userGroup: { id, name },
        },
      } = current;
      if (typeof prev[id] === 'undefined') {
        prev[id] = [];
      }
      prev[id].push(current);
      return prev;
    }, {});

    Object.keys(groupAssignees).forEach(i => {
      const array_with_same_id = groupAssignees[i];
      let child = [],
        index = 0;
      array_with_same_id.map(obj => {
        obj.assignee.user.value = obj.assignee.user.name;
        child.push(obj.assignee.user);
        let c = {
          id: obj.assignee.userGroup.id,
          value: obj.assignee.userGroup.name,
          childrens: child,
        };
        index = assign_ary.findIndex(el => el.id == obj.assignee.userGroup.id);
        return index === -1 ? assign_ary.push(c) : null;
      });
    });
  }
  const assignees = assign_ary;
  const taskUrgency = updatedFormSchema.taskUrgency.options.filter(
    option => rowData.urgency === option.label,
  )[0];
  const tat = updatedFormSchema.tat.options.filter(option => rowData.tat === option.label)[0];
  const recurrenceFrequency = updatedFormSchema.recurrenceFrequency.options.filter(
    option => rowData.recurrenceFrequency === option.label,
  )[0];

  const editInitialValues = {
    ...rowData,
    assignee: assignees,
    taskName: rowData.name,
    taskType,
    taskStatus,
    taskUrgency,
    tat,
    createdDate: new Date(rowData.taskCreatedDate),
    dueDate: new Date(rowData.dueDate),
    endDate: rowData.endDate ? new Date(rowData.endDate) : '',
    recurrenceFrequency,
  };

  return (
    <div>
      <Modal
        isOpen
        heading={isEditModal ? 'UPDATE TASK' : 'CREATE TASK'}
        showCloseButton={false}
        onClose={() => handleClose()}
      >
        {/* 
        TODO: To be uncommented this component to show query on task modal when Advance search is implementing
        {isShowQuery && (
          <SearchQueryDisplay label="search query" searchQueryList={searchQueryList} />
        )} */}
        <Formik
          initialValues={isEditModal ? editInitialValues : initialValues}
          onSubmit={async values => {
            handleSubmit(values);
          }}
          validate={async values => validate(values)}
        >
          {props => {
            taskFormRef.current = props;
            return (
              <FormBuilder
                formikProps={props}
                layoutSchema={layoutSchema}
                formSchema={updatedFormSchema}
                onSubmitValidationError={() => { }}
              />
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

TaskModal.propTypes = {
  getTaskMasterData: PropTypes.object.isRequired,
  assigneeUserGroupsListData: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEditModal: PropTypes.bool,
  rowData: PropTypes.object,
  isShowQuery: PropTypes.bool,
};

TaskModal.defaultProps = {
  getTaskMasterData: {},
  assigneeUserGroupsListData: [],
  handleClose: () => { },
  handleSubmit: () => { },
  isEditModal: false,
  rowData: {},
  isShowQuery: false,
};

export default TaskModal;
