import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { DROPDOWN_TYPES, TEXT_LIMIT } from '../../../../utils/generic/constants';
import format from 'date-fns/format';

export const validate = values => {
  const errors = {};
  if (
    values?.languagePlspDisclosed?.trim() &&
    values?.languagePlspDisclosed?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.languagePlspDisclosed = 'Please enter only 500 characters';
  }
  if (
    values?.plspRequiredDescription?.trim() &&
    values?.plspRequiredDescription?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.plspRequiredDescription = 'Please enter only 500 characters';
  }
  if (
    values?.optionToDelayPlspDescription?.trim() &&
    values?.optionToDelayPlspDescription?.trim().length > TEXT_LIMIT[300]
  ) {
    errors.optionToDelayPlspDescription = 'Please enter only 300 characters';
  }
  if (values?.purposeOrScope?.trim() && values?.purposeOrScope?.trim().length > TEXT_LIMIT[500]) {
    errors.purposeOrScope = 'Please enter only 500 characters';
  }
  if (values?.targetAudience?.trim() && values?.targetAudience?.trim().length > TEXT_LIMIT[300]) {
    errors.targetAudience = 'Please enter only 300 characters';
  }
  if (
    values?.regionalTranslationDescription?.trim() &&
    values?.regionalTranslationDescription?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.regionalTranslationDescription = 'Please enter only 500 characters';
  }
  if (values?.characterLimit?.trim() && values?.characterLimit?.trim().length > TEXT_LIMIT[150]) {
    errors.characterLimit = 'Please enter only 150 characters';
  }
  if (values?.pageLimit?.trim() && values?.pageLimit?.trim().length > TEXT_LIMIT[150]) {
    errors.pageLimit = 'Please enter only 150 characters';
  }
  if (values.proposedPlspSectionHeading?.length > 0) {
    for (let i = 0; i < values.proposedPlspSectionHeading.length; i++) {
      let title = `section${i + 1}`;
      let description = `description${i + 1}`;
      if (values[title]?.trim() && values[title].length > TEXT_LIMIT[300]) {
        errors[title] = 'Please enter only 300 characters';
      }
      if (values[description]?.trim() && values[description].length > TEXT_LIMIT[500]) {
        errors[description] = 'Please enter only 500 characters';
      }
    }
  }
  return errors;
};

const columnDefs1 = [
  {
    headerName: 'DOCUMENT ID',
    field: 'value',
    width: 250,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'DOCUMENT TITLE',
    field: 'documentTitle',
    width: 400,
  },
  {
    headerName: 'Regulatory Date',
    field: 'regulatoryDate',
    width: 350,
    sortable: true,
    cellRenderer: ({ value }) => {
      return value ? format(new Date(value), 'dd-MMM-yyyy') : 'NA';
    },
  },
];

export const layoutSchema = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    { layout: 'isFollowsEuRegulationPlsp', size: 12 },
    { layout: 'languagePlspDisclosed', size: 12 },
    { layout: 'isFollowsRequirementsPlsp', size: 12 },
    {
      layout: 'submissionRequirementsPlsp',
      size: 5,
      className: 'timeline',
    },
    {
      layout: 'timingForSubmission',
      size: 7,
    },
    {
      layout: 'isNewPlspRequired',
      size: 5,
    },
    {
      layout: 'plspRequiredDescription',
      size: 7,
    },
    {
      layout: 'isOptionToDelayPlsp',
      size: 5,
    },
    {
      layout: 'optionToDelayPlspDescription',
      size: 7,
    },
    {
      layout: 'purposeOrScope',
      size: 6,
    },
    {
      layout: 'targetAudience',
      size: 6,
    },
    {
      layout: 'requirementPlspEndpoints',
      size: 12,
    },
    {
      layout: 'isRegionalTranslationRequired',
      size: 6,
    },
    {
      layout: 'regionalTranslationDescription',
      size: 6,
    },
    {
      layout: 'requirementPlspSubmissionFormat',
      size: 6,
    },
  ],
};
export const layoutSchema2 = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'characterLimit',
      size: 6,
    },
    {
      layout: 'pageLimit',
      size: 6,
    },
    {
      layout: 'sourceDocs',
      size: 6,
      className: 'sourceDocs',
    },
    {
      layout: 'empty',
      size: 8,
    },
    {
      layout: 'sourceDocsTable',
      size: 12,
      className: 'sourceDocsTable',
    },
  ],
};

const endPointTypes = [
  {
    id: 1,
    value: 'Primary endpoints',
  },
  {
    id: 2,
    value: 'All Secondary endpoints',
  },
  {
    id: 3,
    value: 'Only Key Secondary Endpoints',
  },
  {
    id: 4,
    value: 'Exploratory/Tertiary/Other Endpoints',
  },
  {
    id: 5,
    value: 'Not applicable',
  },
];

export const submissionReqObj = {
  'Submitted as part of protocol': 1,
  'Submitted as a separate document': 2,
};

export const formSchema = {
  isFollowsEuRegulationPlsp: {
    label: 'FOLLOWS EU REGULATION FOR PLSP',
    name: 'isFollowsEuRegulationPlsp',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  languagePlspDisclosed: {
    label:
      'ACCORDING TO THE REGULATION OR REQUIREMENT, IN WHAT LANGUAGE(S) MUST THE PLSP BE DISCLOSED?',
    name: 'languagePlspDisclosed',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
  isFollowsRequirementsPlsp: {
    label: 'FOLLOWS LOCAL REQUIREMENTS FOR PLSP',
    name: 'isFollowsRequirementsPlsp',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  submissionRequirementsPlsp: {
    label: 'What are submission requirements for PLSP',
    name: 'submissionRequirementsPlsp',
    type: fieldTypes.radio,
    options: [
      {
        id: 1,
        value: 'Submitted as part of protocol',
        label: 'Submitted as part of protocol',
      },
      {
        id: 2,
        value: 'Submitted as a separate document',
        label: 'Submitted as a separate document',
      },
    ],
    props: {
      type: 'group',
      className: 'timelineRadioClass',
    },
  },
  timingForSubmission: {
    label: 'Timing for submission',
    name: 'timingForSubmission',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
      isMandatory: false,
    },
  },
  isNewPlspRequired: {
    label: 'Is a new PLSP required for each protocol amendment?',
    name: 'isNewPlspRequired',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  plspRequiredDescription: {
    label: 'If yes, describe here',
    name: 'plspRequiredDescription',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
  isOptionToDelayPlsp: {
    label: 'Is there an option to delay the PLSP?',
    name: 'isOptionToDelayPlsp',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  optionToDelayPlspDescription: {
    label: 'If yes, describe here',
    name: 'optionToDelayPlspDescription',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  purposeOrScope: {
    label: 'Purpose or scope',
    name: 'purposeOrScope',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  targetAudience: {
    label: 'Target audience',
    name: 'targetAudience',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  requirementPlspEndpoints: {
    name: 'requirementPlspEndpoints',
    label: 'What endpoint must be disclosed?',
    type: fieldTypes.checkbox,
    options: endPointTypes,
    props: {
      isMandatory: false,
    },
  },
  isRegionalTranslationRequired: {
    label: 'Regional translations required',
    name: 'isRegionalTranslationRequired',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  regionalTranslationDescription: {
    label: 'If yes, describe here',
    name: 'regionalTranslationDescription',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  requirementPlspSubmissionFormat: {
    label: 'File format for submission',
    name: 'requirementPlspSubmissionFormat',
    type: fieldTypes.select_async,
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  characterLimit: {
    label: 'Character limit',
    name: 'characterLimit',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  pageLimit: {
    label: 'Page limit',
    name: 'pageLimit',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  sourceDocs: {
    name: 'sourceDocs',
    type: fieldTypes.select_async,
    label: 'Linking to Recommended Guidelines',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  sourceDocsTable: {
    name: 'sourceDocsTable',
    type: fieldTypes.dndDataGrid,
    props: {
      columnDefs: columnDefs1,
      rowHeight: 35,
    },
  },
  empty: {},
};

export const voluntaryOrMandatoryOptions = [
  { id: 1, value: 'Mandatory' },
  { id: 2, value: 'Voluntary' },
];

export const publicOrPrivateOptions = [
  { id: 1, value: 'Public' },
  { id: 2, value: 'Private' },
];

export const createDataEntry = ({
  numOfDataEntry,
  values,
  requirementId,
  proposedPlspSectionHeading,
}) =>
  [...Array(numOfDataEntry)].map((_, index) => ({
    id: proposedPlspSectionHeading[index]?.id || 0,
    requirementId,
    productionId: values[`productionId${index + 1}`] || 0,
    section: values[`section${index + 1}`],
    description: values[`description${index + 1}`],
    voluntaryOrMandatory:
      values[`voluntaryOrMandatory${index + 1}`]?.filter(obj => obj.isChecked)?.[0] || undefined,
    publicOrPrivate:
      values[`publicOrPrivate${index + 1}`]?.filter(obj => obj.isChecked)?.[0] || undefined,
    orderId: index + 1,
    voluntaryOrMandatory:
      values[`voluntaryOrMandatory${index + 1}`]?.filter(obj => obj.isChecked)?.[0] || undefined,
    publicOrPrivate:
      values[`publicOrPrivate${index + 1}`]?.filter(obj => obj.isChecked)?.[0] || undefined,
  }));

export const createDataEntryInitialValues = (values = []) => {
  const updatedValues =
    values.length > 0 ? values : [{ section: '', description: '', productionId: 0 }];
  return updatedValues.reduce(
    (init, value, index) => ({
      ...init,
      [`productionId${index + 1}`]: value.productionId,
      [`section${index + 1}`]: value.section,
      [`description${index + 1}`]: value.description,
      [`voluntaryOrMandatory${index + 1}`]: voluntaryOrMandatoryOptions.map(obj => {
        if (obj.id === value.voluntaryOrMandatory?.id) {
          return { ...obj, isChecked: true };
        } else if (value.voluntaryOrMandatory?.id) {
          return { ...obj, disabled: true, isChecked: false };
        } else return obj;
      }),
      [`publicOrPrivate${index + 1}`]: publicOrPrivateOptions.map(obj => {
        if (obj.id === value.publicOrPrivate?.id) {
          return { ...obj, isChecked: true };
        } else if (value.publicOrPrivate?.id) {
          return { ...obj, disabled: true, isChecked: false };
        } else return obj;
      }),
    }),
    {},
  );
};

export const createDateEntryFields = (number, handleAddDelEntry) => {
  const layout = [...Array(number)].reduce(
    (init, _, index) => [
      ...init,
      {
        layout: 'row',
        spacing: 0.5,
        alignItems: 'space-between',
        justify: 'space-between',
        content: [
          {
            layout: `section${index + 1}`,
            size: 2,
          },
          {
            layout: `description${index + 1}`,
            size: 5,
          },
          {
            layout: `icons${index + 1}`,
            size: 1,
          },
          {
            layout: `voluntaryOrMandatory${index + 1}`,
            size: 2,
            className: 'mt-20',
          },
          {
            layout: `publicOrPrivate${index + 1}`,
            size: 1,
            className: 'mt-20',
          },
        ],
      },
    ],
    [],
  );

  const form = [...Array(number)].reduce(
    (init, _, index) => ({
      ...init,
      [`section${index + 1}`]: {
        label: 'section',
        name: `section${index + 1}`,
        type: fieldTypes.text,
        props: {
          isMandatory: false,
          style: {},
        },
      },
      [`description${index + 1}`]: {
        label: 'description',
        name: `description${index + 1}`,
        type: fieldTypes.text,
        props: {
          isMandatory: false,
          style: {},
        },
      },
      [`icons${index + 1}`]: {
        type: fieldTypes.icons,
        props: {
          index: index + 1,
          addDelEntryRow: (index, todo) => {
            handleAddDelEntry(index, todo);
          },
        },
      },
      [`voluntaryOrMandatory${index + 1}`]: {
        type: fieldTypes.checkbox,
        name: `voluntaryOrMandatory${index + 1}`,
        options: voluntaryOrMandatoryOptions,
        props: {},
      },
      [`publicOrPrivate${index + 1}`]: {
        type: fieldTypes.checkbox,
        name: `publicOrPrivate${index + 1}`,
        options: publicOrPrivateOptions,
        props: {},
      },
    }),
    {},
  );

  return {
    layout,
    form,
  };
};
