/* eslint-disable no-case-declarations */
/* eslint-disable max-depth */
import { takeEvery, put, all, delay } from 'redux-saga/effects';
import { remove, isEqual } from 'lodash';

import * as TaskActions from '../actions/task';
import * as TrialActions from '../actions/clinicalTrials';
import * as GlobalActions from '../actions/globalActions';
import { trials as trialsApi, queues as queuesApi, task as taskApi } from '../../api';
import {
  ALERT_MESSAGES,
  MODAL_TYPES,
  updateTypeOnProdStatusSave,
} from '../../utils/generic/constants';
import { getApiErrorMessage, getProdMessage, getLockMessageInfo } from '../../utils/generic/helper';

// workers
// export function* getMasterDataAsync({ payload }) {
//   try {
//     const response = yield trialsApi.getMasterData();
//     const { state, data } = response.data;
//     if (state) {
//       yield put({ type: TrialActions.getMasterDataSuccess.type, payload: data });
//     }
//   } catch (err) {
//     yield put({ type: TrialActions.setError.type, payload: err });
//   }
// }
const getMessage = value => {
  let msg = ALERT_MESSAGES.TRIALS_SAVE;
  switch (value) {
    case 'TrialResults':
      msg = ALERT_MESSAGES.TRIALS_RESULTS_SAVE;
      break;
    case 'TrialNotes':
      msg = ALERT_MESSAGES.TRIALS_NOTES_SAVE;
      break;
    default:
      msg = ALERT_MESSAGES.TRIALS_SAVE;
  }
  return msg;
};

export function* releaseTrialUserLockAsync({ payload }) {
  try {
    yield trialsApi.unlockUserRecords(payload);
  } catch (err) {
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export function* updateTrailLockDetails({ payload }) {
  try {
    yield trialsApi.getUserLockDetails(payload);
  } catch (err) {
    yield put(TrialActions.setError());
  }
}

export function* getTrialsDataAsync({ payload: { trialId, ingestedId } }) {
  try {
    yield put(GlobalActions.showSpinner());
    let actions = {
      masterDetails: trialsApi.getMasterData(),
    };
    const trialActions = {
      trialDetails: trialsApi.getTrialsData(trialId),
    };
    const ingestedActions = { ingestedDetails: trialsApi.getIngestedData(ingestedId) };
    const lockActions = { lockDetails: trialsApi.getUserLockDetails(trialId) };

    if (parseInt(ingestedId) > 0) {
      actions = { ...actions, ...ingestedActions };
    }

    if (parseInt(trialId) > 0) {
      actions = { ...actions, ...trialActions, ...lockActions };
    }
    const { masterDetails, lockDetails, trialDetails, ingestedDetails } = yield all(actions);
    const {
      data: { state, data, messages },
    } = masterDetails;
    if (state) {
      yield put(TrialActions.getMasterDataSuccess(data));
    } else {
      yield put(TrialActions.resetMasterData());
      yield put(
        GlobalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }

    if (lockDetails) {
      const {
        data: { state, data, messages },
      } = lockDetails;
      if (state) {
        yield put(TrialActions.getTrialsUserLockDetails(data));
        const { isEditable, lockedBy } = data;
        if (!isEditable) {
          yield put(
            GlobalActions.showModal({
              isOpen: true,
              message: getLockMessageInfo(lockedBy),
              modalType: MODAL_TYPES.INFO,
            }),
          );
        }
      } else {
        yield put(
          GlobalActions.showModal({
            isOpen: true,
            message: getApiErrorMessage(messages),
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    }

    if (trialDetails) {
      const {
        data: { state: trialState, data: trialsData, messages: trialErrorMessages },
      } = trialDetails;
      if (trialState) {
        yield put(TrialActions.setTrialsFeatureData(trialsData));
        let actions = {
          countriesDetails: trialsApi.getAllCountries(),
          regionDetails: trialsApi.getAllRegions(),
          subRegionDetails: trialsApi.getAllSubRegions(),
        };
        const { countriesDetails, regionDetails, subRegionDetails } = yield all(actions);
        const {
          data: { state, data, messages },
        } = countriesDetails;
        const {
          data: {
            state: regionDetailsState,
            data: regionDetailsData,
            messages: regionDetailsMessages,
          },
        } = regionDetails;

        const {
          data: {
            state: subRegionDetailsState,
            data: subRegionDetailsData,
            messages: subRegionDetailsMessage,
          },
        } = subRegionDetails;

        const mergeResult = [...regionDetailsData, ...subRegionDetailsData, ...data];
        yield put(TrialActions.setLocationsData(mergeResult));
      } else {
        yield put(TrialActions.resetTrialState());
        yield put(
          GlobalActions.showModal({
            isOpen: true,
            message: getApiErrorMessage(trialErrorMessages),
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    }

    if (ingestedDetails) {
      const {
        data: { state: ingestedState, data: ingestedData, messages: ingestedErrorMessages },
      } = ingestedDetails;
      if (ingestedState) {
        yield put(TrialActions.setIngestedData(ingestedData));
      } else {
        yield put(TrialActions.resetIngestedData());
        yield put(
          GlobalActions.showModal({
            isOpen: true,
            message: getApiErrorMessage(ingestedErrorMessages),
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    }
    yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(TrialActions.resetTrialState());
    yield put(TrialActions.resetIngestedData());
    yield put(TrialActions.setError(err));
    yield put(GlobalActions.hideSpinner());
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export function* getTrialsLocationsDataAsync() {
  let actions = {
    countriesDetails: trialsApi.getAllCountries(),
    regionDetails: trialsApi.getAllRegions(),
    subRegionDetails: trialsApi.getAllSubRegions(),
  };
  let masterActions = {
    masterDetails: trialsApi.getMasterData(),
  };
  const { masterDetails } = yield all(masterActions);
  const {
    data: { state: masterState, data: masterData, messages: masterMessages },
  } = masterDetails;
  if (masterState) {
    yield put(TrialActions.getMasterDataSuccess(masterData));
  } else {
    yield put(TrialActions.resetMasterData());
    yield put(
      GlobalActions.showModal({
        isOpen: true,
        message: getApiErrorMessage(masterMessages),
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
  const { countriesDetails, regionDetails, subRegionDetails } = yield all(actions);
  const {
    data: { state, data, messages },
  } = countriesDetails;
  const {
    data: { state: regionDetailsState, data: regionDetailsData, messages: regionDetailsMessages },
  } = regionDetails;

  const {
    data: {
      state: subRegionDetailsState,
      data: subRegionDetailsData,
      messages: subRegionDetailsMessage,
    },
  } = subRegionDetails;

  const mergeResult = [...regionDetailsData, ...subRegionDetailsData, ...data];
  yield put(TrialActions.setLocationsData(mergeResult));
}

export function* saveTrialsDataAsync({ payload }) {
  const {
    request,
    data: { value },
    goToNextStep,
    key,
    history,
    currentTab,
    currentPageIndex,
    currentPageSize,
  } = payload;
  try {
    yield put(GlobalActions.showSpinner());
    const { productionStatusID, previousProductionStatus } = request;
    const response = yield trialsApi.saveTrialsData(request);
    const {
      data: { state, data, messages },
    } = response;
    if (state) {
      yield put(TrialActions.saveTrialsDataSuccess({ data, key, currentTab }));
      if (response) {
        if (request.id) {
          let responseMessage = '',
            responseStateGlobal = '';
          switch (key) {
            case 'trialNotes':
            case 'trialResults':
              const resultNotesResponse = yield trialsApi.getTrialNotesAndResults(
                key === 'trialResults',
                {
                  sortOrder: [
                    {
                      columnName: 'string',
                      isDescendingOrder: true,
                    },
                  ],
                  pageSize: 10,
                  pageIndex: 1,
                  entityPrimaryKey: request.id,
                },
              );
              const {
                data: { state: responseState, data: resultNoteData, messages: resultNoteMessage },
              } = resultNotesResponse;
              responseStateGlobal = responseState;
              responseMessage = resultNoteMessage;
              if (responseState) {
                if (key === 'trialResults') {
                  yield put(TrialActions.setTrialResultData(resultNoteData));
                } else {
                  yield put(TrialActions.setTrialNotesData(resultNoteData));
                }
              }
              break;
            case 'trialContacts':
              const associationsResponse = yield trialsApi.getTrialAssociations({
                payload: {
                  pageSize: currentPageSize,
                  pageIndex: currentPageIndex,
                  entityPrimaryKey: request.id,
                },
              });
              const {
                data: {
                  state: associationsState,
                  data: associationsData,
                  messages: associationsMessages,
                },
              } = associationsResponse;
              responseStateGlobal = associationsState;
              responseMessage = associationsMessages;
              if (associationsState) {
                yield put(TrialActions.setTrialAssociationsData(associationsData));
              }
              break;
            default:
              break;
          }

          if (
            !responseStateGlobal &&
            ['trialContacts', 'trialResults', 'trialNotes'].includes(key)
          ) {
            yield put(
              GlobalActions.showModal({
                isOpen: true,
                message: getApiErrorMessage(responseMessage),
                modalType: MODAL_TYPES.ERROR,
              }),
            );
          }
        }
      }
      if (
        request.forceUpdate &&
        isEqual(request.forceUpdate.sort(), updateTypeOnProdStatusSave.trials.sort())
      ) {
        yield put(
          GlobalActions.showAlert(getProdMessage(productionStatusID, previousProductionStatus)),
        );
        // history &&
        //   setTimeout(() => {
        //     history.push(`/clinicalTrials`);
        //   }, 2000);
      } else {
        yield put(GlobalActions.showAlert(getMessage(value)));
      }

      if (goToNextStep) {
        yield delay(2000);
        yield put(TrialActions.nextFeatureSelection(value));
      }
    } else if (key === 'trialSummary') {
      yield put(
        TrialActions.setShowTrialsValidationModal({
          isOpen: true,
          messages,
        }),
      );
    } else {
      yield put(
        GlobalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    let actions = {
      countriesDetails: trialsApi.getAllCountries(),
      regionDetails: trialsApi.getAllRegions(),
      subRegionDetails: trialsApi.getAllSubRegions(),
    };
    const { countriesDetails, regionDetails, subRegionDetails } = yield all(actions);
    const {
      data: { data: countriesData },
    } = countriesDetails;
    const {
      data: { data: regionDetailsData },
    } = regionDetails;
    const {
      data: { data: subRegionDetailsData },
    } = subRegionDetails;
    const locationsResult = [...regionDetailsData, ...subRegionDetailsData, ...countriesData];
    yield put(TrialActions.setLocationsData(locationsResult));
    yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(GlobalActions.hideSpinner());
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    yield put(TrialActions.setError(err));
  }
}

export function* getTrailStampingDetailsAsync({ payload: { body } }) {
  try {
    yield put(GlobalActions.showSpinner());
    const response = yield trialsApi.saveTrialsData(body);
    const { state, data } = response.data;
    if (state) {
      yield put(TrialActions.getTrialStampingDetailsSuccess({ data }));
    } else {
      yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    }
    yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(GlobalActions.hideSpinner());
    yield put(TrialActions.setError());
  }
}

export function* getTrialsListAsync({ payload }) {
  try {
    yield put(GlobalActions.showSpinner());
    const response = yield trialsApi.getTrialList({ payload });
    const { state, data, messages } = response.data;
    if (state) {
      yield put(TrialActions.getTrialListSuccess(data));
    } else {
      yield put(
        GlobalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(GlobalActions.hideSpinner());
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    yield put(TrialActions.setError(err));
  }
}

export function* deleteTrialsDataAsync({ payload }) {
  try {
    const { trialId, postParam } = payload;
    yield put(GlobalActions.showSpinner());
    const response = yield trialsApi.deleteTrialData(trialId);
    yield put(GlobalActions.hideSpinner());
    const {
      data: { state, messages },
    } = response;
    if (state) {
      //yield put(TrialActions.deleteTrialDataSuccess(payload));
      yield put(TrialActions.getTrialList(postParam));
      yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_DELETE));
    } else {
      yield put(
        GlobalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
  } catch (err) {
    yield put(TrialActions.setError(err));
    yield put(GlobalActions.hideSpinner());
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export function* SearchOrganizationAndPersonDataAsync({
  payload: { organizationIds, personIds, role },
}) {
  const updatedOrganizationIds = [...organizationIds];
  const updatedPersonIds = [...personIds];
  try {
    yield put({ type: GlobalActions.showSpinner.type });
    yield put(GlobalActions.showSpinner());
    const [organizationResponse, personResponse] = yield all([
      trialsApi.searchOrganizationData({
        searchKeyword: '',
        organizationSearchTypeId: 2,
        page: 0,
        pageSize: 0,
        ids: remove(updatedOrganizationIds, id => !isNaN(id)),
      }),
      trialsApi.searchPersonData({
        searchKeyword: '',
        personSearchTypeId: 2,
        page: 0,
        pageSize: 0,
        ids: remove(updatedPersonIds, id => !isNaN(id)),
      }),
    ]);
    const {
      data: {
        state: organizationState = false,
        data: organizationData = [],
        messages: organizationMessages = [],
      } = {},
    } = {
      ...organizationResponse,
    };
    const {
      data: {
        state: personState = false,
        data: personData = [],
        messages: personMessages = [],
      } = {},
    } = {
      ...personResponse,
    };
    if (organizationState && personState) {
      yield put(
        TrialActions.searchOrganizationAndPersonDataSuccess({
          organizationIds,
          organizationData,
          personIds,
          personData,
          role,
        }),
      );
    } else if (!organizationState) {
      yield put(
        GlobalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(organizationMessages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    } else if (!personState) {
      yield put(
        GlobalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(personMessages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(TrialActions.resetImportContactsList());
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    yield put(GlobalActions.hideSpinner());
  }
}

export function* getQueueRecordsAsync({ payload: { queueDetails, isQueue } }) {
  try {
    yield put(GlobalActions.showSpinner());
    // const response = yield queuesApi.getQueueRecords(queueDetails);
    const response = isQueue
      ? yield queuesApi.getQueueRecords(queueDetails)
      : yield taskApi.getTaskQueueRecords(queueDetails);
    const {
      data: { state, data },
    } = response;
    if (state) {
      isQueue
        ? yield put(TrialActions.getQueueRecordsSuccess(data))
        : yield put(TrialActions.getTaskQueueRecordsSuccess(data));
      yield put(GlobalActions.hideSpinner());
    } else {
      yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
      isQueue
        ? yield put(TrialActions.getQueueRecordsSuccess([]))
        : yield put(TrialActions.getTaskQueueRecordsSuccess([]));
      yield put(GlobalActions.hideSpinner());
    }
  } catch (err) {
    isQueue
      ? yield put(TrialActions.getQueueRecordsSuccess([]))
      : yield put(TrialActions.getTaskQueueRecordsSuccess([]));
    yield put(TrialActions.setError(err));
    yield put(GlobalActions.hideSpinner());
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

// export function* getTaskQueueRecordsAsync({ payload: { queueDetails } }) {
//   try {
//     yield put(GlobalActions.showSpinner());
//     const response = yield taskApi.getTaskQueueRecords(queueDetails);
//     const {
//       data: { state, data },
//     } = response;
//     if (state) {
//       yield put(TrialActions.getTaskQueueRecordsSuccess(data));
//       yield put(GlobalActions.hideSpinner());
//     } else {
//       yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
//       yield put(TrialActions.getTaskQueueRecordsSuccess([]));
//       yield put(GlobalActions.hideSpinner());
//     }
//   } catch (err) {
//     yield put(TrialActions.getTaskQueueRecordsSuccess([]));
//     yield put(TrialActions.setError(err));
//     yield put(GlobalActions.hideSpinner());
//     yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
//   }
// }
export function* getQueueTypesAsync({ payload }) {
  try {
    yield put(GlobalActions.showSpinner());
    const response = yield queuesApi.getQueueTypes(payload);
    yield put(GlobalActions.hideSpinner());
    const {
      data: { state, data },
    } = response;
    if (state) {
      yield put(TrialActions.getQueueTypesSuccess({ data }));
    } else {
      yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    }
  } catch (err) {
    yield put(TrialActions.setError(err));
    yield put(GlobalActions.hideSpinner());
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export function* searchTrialResultsAsync({ payload }) {
  try {
    yield put(GlobalActions.showSpinner());
    const response = yield trialsApi.searchTrialResults(payload);
    const {
      data: { state, data, messages },
    } = response;
    if (state) {
      yield put(TrialActions.searchTrialResultsSuccess(data));
    } else {
      yield put(
        GlobalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(GlobalActions.hideSpinner());
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    yield put(TrialActions.setError(err));
  }
}

export function* searchTrialNotesAsync({ payload }) {
  try {
    yield put(GlobalActions.showSpinner());
    const response = yield trialsApi.searchTrialNotes(payload);
    const {
      data: { state, data, messages },
    } = response;
    if (state) {
      yield put(TrialActions.searchTrialNotesSuccess(data));
    } else {
      yield put(
        GlobalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(GlobalActions.hideSpinner());
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    yield put(TrialActions.setError(err));
  }
}

export function* getTrialNotesAsync({ payload: { id, isTrialResult, payloadData } }) {
  try {
    yield put(GlobalActions.showSpinner());
    const response = yield trialsApi.getTrialNotesAndResults(isTrialResult, payloadData);
    const {
      data: { state, data, messages },
    } = response;
    if (state) {
      if (!isTrialResult) {
        yield put(TrialActions.setTrialNotesData(data));
      } else {
        yield put(TrialActions.setTrialResultData(data));
      }
    } else {
      yield put(
        GlobalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(GlobalActions.hideSpinner());
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    yield put(TrialActions.setError(err));
  }
}

export function* getTrialAssociationsAsync({ payload }) {
  try {
    yield put(GlobalActions.showSpinner());
    const response = yield trialsApi.getTrialAssociations({ payload });
    const {
      data: { state, data, messages },
    } = response;
    if (state) {
      yield put(TrialActions.setTrialAssociationsData(data));
    } else {
      yield put(
        GlobalActions.showModal({
          isOpen: true,
          message: getApiErrorMessage(messages),
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(GlobalActions.hideSpinner());
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    yield put(TrialActions.setError(err));
  }
}

export function* getQueueDashboardDataAsync({
  payload: { entityId, listPostParam, assigneeUserGroup },
}) {
  try {
    yield put(GlobalActions.showSpinner());

    const actions = {
      queueTypesAction: queuesApi.getQueueTypes(entityId),
      taskQueueActions: taskApi.getTaskQueueTypes(entityId),
      trialListAction: trialsApi.getTrialList({ payload: listPostParam }),
      taskMasterActions: taskApi.getTaskMaster(),
      assigneeUserGroupListActions: taskApi.assigneeUserGroupList(assigneeUserGroup),
    };

    const {
      queueTypesAction,
      taskQueueActions,
      trialListAction,
      taskMasterActions,
      assigneeUserGroupListActions,
    } = yield all(actions);

    if (queueTypesAction) {
      const {
        data: { state, data },
      } = queueTypesAction;

      if (state) {
        yield put(TrialActions.getQueueTypesSuccess({ data }));
      }
    }
    if (taskQueueActions) {
      const {
        data: { state, data },
      } = taskQueueActions;

      if (state) {
        yield put(TrialActions.getTaskQueueTypesSuccess({ data }));
      }
    }

    if (trialListAction) {
      const {
        data: { state, data },
      } = trialListAction;

      if (state) {
        yield put(TrialActions.getTrialListSuccess(data));
      }
    }
    if (taskMasterActions) {
      const {
        data: { data, state },
      } = taskMasterActions;

      if (state) {
        yield put(TaskActions.getTaskMasterSuccess({ data }));
      }
    }
    if (assigneeUserGroupListActions) {
      const {
        data: { data, state },
      } = assigneeUserGroupListActions;

      if (state) {
        yield put(TaskActions.assigneeUserGroupListSuccess({ data }));
      }
    }

    yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(GlobalActions.hideSpinner());
    yield put(
      GlobalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}
export function* getQueueTypesAndRecordsAsync({ payload }) {
  try {
    const { queueType, entityId, rowCount, listPostParam, pageIndex, pageSize } = payload;
    const postQueueTypesResponse = yield queuesApi.getQueueTypes(entityId);
    const {
      data: { state, data },
    } = postQueueTypesResponse;
    if (state) {
      yield put(TrialActions.getQueueTypesSuccess({ data }));
      if (rowCount === 1) {
        yield put(TrialActions.setQueueExpandedList([]));
        const response = yield trialsApi.getTrialList({ payload: listPostParam });
        const { state, data, messages } = response.data;
        if (state) {
          yield put(TrialActions.setSelectedQueueType(queueType));
          yield put(TrialActions.getTrialListSuccess(data));
        } else {
          yield put(
            GlobalActions.showModal({
              isOpen: true,
              message: getApiErrorMessage(messages),
              modalType: MODAL_TYPES.ERROR,
            }),
          );
        }
        yield put(GlobalActions.hideSpinner());
      } else {
        const { id, parentId, rootKey } = { ...queueType };
        const queueDetails = queueType.lpSection
          ? { groupKey: id, rootKey, pageIndex, pageSize }
          : { queueId: id, groupKey: parentId, rootKey, pageIndex, pageSize };
        yield put(
          TrialActions.getQueueRecords({
            queueDetails,
          }),
        );
        yield put(TrialActions.setSelectedQueueType(queueType));
      }
    } else {
      yield put(GlobalActions.hideSpinner());
      yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    }
  } catch (err) {
    yield put(TrialActions.setError(err));
    yield put(GlobalActions.hideSpinner());
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export function* postExitQueueAysnc({ payload }) {
  try {
    yield put(GlobalActions.showSpinner());
    const { queueTransactionId } = payload;
    const postExitQueueResponse = yield queuesApi.postExitQueue({ id: queueTransactionId });
    const {
      data: { state, data },
    } = postExitQueueResponse;
    if (state && data) {
      const modalData = {
        isOpen: true,
        message: 'RECORD EXIT DONE SUCCESSFULLY',
        modalType: MODAL_TYPES.SUCCESS,
      };
      yield put({ type: GlobalActions.showModal.type, payload: modalData });
      yield put(TrialActions.getQueueTypesAndRecords(payload));
    } else {
      yield put(GlobalActions.hideSpinner());
      yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    }
  } catch (err) {
    yield put(TrialActions.setError(err));
    yield put(GlobalActions.hideSpinner());
    yield put(GlobalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export function* getSponsorTypeAsync() {
  try {
    // yield put({ type: GlobalActions.showSpinner.type });
    const response = yield trialsApi.getSponsorType();
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put({
        type: TrialActions.getSponsorTypeSuccess.type,
        payload: data,
      });
    }
    yield put({ type: GlobalActions.hideSpinner.type });
  } catch (err) {
    yield put({ type: GlobalActions.hideSpinner.type });
  }
}

export function* getProximityDataAsync({ payload: { ingestedId, duplicateIds } }) {
  try {
    yield put(GlobalActions.showSpinner());
    const [masterDetails, ingestedDetails, duplicateDetails] = yield all([
      trialsApi.getMasterData(),
      trialsApi.getIngestedData(ingestedId),
      trialsApi.getTrialsData(duplicateIds[0]),
    ]);
    if (masterDetails) {
      const { data: masterData, state: masterDataState } = masterDetails.data;
      if (masterDataState) {
        yield put(TrialActions.getMasterDataSuccess(masterData));
      } else {
        yield put(TrialActions.resetMasterData());
      }
    }
    if (ingestedDetails && duplicateDetails) {
      const { data: ingestedData, state: ingestedDataState } = ingestedDetails.data;
      const { data: trialData, state: trialDataState } = duplicateDetails.data;
      if (ingestedDataState && trialDataState) {
        let possibleDuplicatesData = [trialData];
        yield put(
          TrialActions.getProximityDataSuccess({
            ingestedRecordData: ingestedData,
            possibleDuplicatesData,
          }),
        );
      } else {
        yield put(
          GlobalActions.showModal({
            isOpen: true,
            message: 'Something went wrong',
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    } else {
      yield put(
        GlobalActions.showModal({
          isOpen: true,
          message: 'Something went wrong',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(GlobalActions.hideSpinner());
    yield put(
      GlobalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* getProximityCompareDataAsync({ payload: { data, isingestedId } }) {
  try {
    yield put(GlobalActions.showSpinner());
    const getCheckids = isingestedId ? trialsApi.getIngestedData : trialsApi.getTrialsData;
    const [ingestedDetails, ingestedDetails1, ingestedDetails2, ingestedDetails3] = yield all([
      getCheckids(data[0]),
      getCheckids(data[1]),
      data[2] ? getCheckids(data[2]) : '',
      data[3] ? getCheckids(data[3]) : '',
    ]);
    if (ingestedDetails && ingestedDetails1 && ingestedDetails2 && ingestedDetails3) {
      const { data: ingestedData, state: ingestedDataState } = ingestedDetails.data;
      const { data: ingestedData1, state: ingestedDataState1 } = ingestedDetails1.data;
      const { data: ingestedData2, state: ingestedDataState2 } = ingestedDetails2.data;
      const { data: ingestedData3, state: ingestedDataState3 } = ingestedDetails3.data;
      if (ingestedDataState && ingestedDataState1 && ingestedDataState2 && ingestedDataState3) {
        yield put(
          TrialActions.getProximityCompareDataSuccess({
            ingestedCompRecordData: ingestedData,
            ingestedCompRecordData1: ingestedData1,
            ingestedCompRecordData2: ingestedData2,
            ingestedCompRecordData3: ingestedData3,
          }),
        );
      } else {
        yield put(
          GlobalActions.showModal({
            isOpen: true,
            message: 'Something went wrong',
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    } else if (ingestedDetails && ingestedDetails1 && ingestedDetails2) {
      const { data: ingestedData, state: ingestedDataState } = ingestedDetails.data;
      const { data: ingestedData1, state: ingestedDataState1 } = ingestedDetails1.data;
      const { data: ingestedData2, state: ingestedDataState2 } = ingestedDetails2.data;
      if (ingestedDataState && ingestedDataState1 && ingestedDataState2) {
        yield put(
          TrialActions.getProximityCompareDataSuccess({
            ingestedCompRecordData: ingestedData,
            ingestedCompRecordData1: ingestedData1,
            ingestedCompRecordData2: ingestedData2,
          }),
        );
      } else {
        yield put(
          GlobalActions.showModal({
            isOpen: true,
            message: 'Something went wrong',
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    } else {
      const { data: ingestedData, state: ingestedDataState } = ingestedDetails.data;
      const { data: ingestedData1, state: ingestedDataState1 } = ingestedDetails1.data;
      if (ingestedDataState && ingestedDataState1) {
        yield put(
          TrialActions.getProximityCompareDataSuccess({
            ingestedCompRecordData: ingestedData,
            ingestedCompRecordData1: ingestedData1,
          }),
        );
      } else {
        yield put(
          GlobalActions.showModal({
            isOpen: true,
            message: 'Something went wrong',
            modalType: MODAL_TYPES.ERROR,
          }),
        );
      }
    }
    yield put(GlobalActions.hideSpinner());
  } catch (err) {
    yield put(GlobalActions.hideSpinner());
    yield put(
      GlobalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

// watchers
export const watchers = [
  // function* watchGetMasterDataAsync() {
  //   yield takeEvery(TrialActions.getMasterData, getMasterDataAsync);
  // },
  function* watchReleaseTrialsLockAsync() {
    yield takeEvery(TrialActions.releaseTrialUserLock, releaseTrialUserLockAsync);
  },
  function* watchUpdatePersonLockDetailsAsync() {
    yield takeEvery(TrialActions.updateTrialLockDetails, updateTrailLockDetails);
  },
  function* watchGetTrialsDataAsync() {
    yield takeEvery(TrialActions.fetchTrialFeatures, getTrialsDataAsync);
  },
  function* watchGetTrialsDataAsync() {
    yield takeEvery(TrialActions.getLocationsData, getTrialsLocationsDataAsync);
  },
  function* watchSaveTrialsDataAsync() {
    yield takeEvery(TrialActions.saveTrialsData, saveTrialsDataAsync);
  },
  function* watchGetTrialStampingDetails() {
    yield takeEvery(TrialActions.getTrialStampingDetails, getTrailStampingDetailsAsync);
  },
  function* watchGetTrialsListAsync() {
    yield takeEvery(TrialActions.getTrialList, getTrialsListAsync);
  },
  function* watchDeleteTrialsDataAsync() {
    yield takeEvery(TrialActions.deleteTrialData, deleteTrialsDataAsync);
  },
  function* watchSearchOrganizationDataAsync() {
    yield takeEvery(
      TrialActions.searchOrganizationAndPersonData,
      SearchOrganizationAndPersonDataAsync,
    );
  },
  function* watchGetQueueRecords() {
    yield takeEvery(TrialActions.getQueueRecords, getQueueRecordsAsync);
  },
  function* watchGetTaskQueueRecords() {
    yield takeEvery(TrialActions.getTaskQueueRecords, getQueueRecordsAsync);
  },
  function* watchGetQueueTypes() {
    yield takeEvery(TrialActions.getQueueTypes, getQueueTypesAsync);
  },
  function* watchSearchTrialResultsAsync() {
    yield takeEvery(TrialActions.searchTrialResults, searchTrialResultsAsync);
  },
  function* watchSearchTrialNotesAsync() {
    yield takeEvery(TrialActions.searchTrialNotes, searchTrialNotesAsync);
  },
  function* watchGetTrialNotesAsync() {
    yield takeEvery(TrialActions.getTrialNotes, getTrialNotesAsync);
  },
  function* watchGetTrialAssociationsAsync() {
    yield takeEvery(TrialActions.getTrialAssociations, getTrialAssociationsAsync);
  },
  function* watchGetQueueDashboardData() {
    yield takeEvery(TrialActions.getQueueDashboardData, getQueueDashboardDataAsync);
  },
  function* watchGetSponsorTypeAsync() {
    yield takeEvery(TrialActions.getSponsorType, getSponsorTypeAsync);
  },
  // function* watchGetCompanyAssociationsAsync() {
  //   yield takeEvery(TrialActions.getCompanyAssociations, getCompanyAssociationsAsync);
  // },
  function* watchPostExitQueueRecords() {
    yield takeEvery(TrialActions.exitQueue, postExitQueueAysnc);
  },
  function* watchGetProximityDataAsync() {
    yield takeEvery(TrialActions.getProximityData, getProximityDataAsync);
  },
  function* watchetQueueTypesAndRecordsAsync() {
    yield takeEvery(TrialActions.getQueueTypesAndRecords, getQueueTypesAndRecordsAsync);
  },
  function* watchGetProximityCompareDataAsync() {
    yield takeEvery(TrialActions.getProximityCompareData, getProximityCompareDataAsync);
  },
];
