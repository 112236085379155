import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useDispatch } from 'react-redux';

import { hideAlert } from '../../../store/actions/globalActions';
import AlertDialog from '../../../framework/alert';

const GlobalAlert = () => {
  const dispatch = useDispatch();
  const { isOpen, alertType, message } = useSelector(
    state => state.globalState.alertStatus,
    shallowEqual,
  );

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        dispatch(hideAlert({ isOpen: false }));
      }, 4000);
    }
  }, [isOpen]);

  const modalProps = {
    message,
    severity: alertType,
  };

  return <> {isOpen && <AlertDialog {...modalProps} />} </>;
};

export default GlobalAlert;
