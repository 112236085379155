const reportingFeatures = [
  {
    name: 'Source URL Statistics',
    value: 'SourceURLStatistics',
    isTab: false,
    sectionName: 'Source URL Statistics',
    isMandatory: false,
  },
  {
    name: 'Ageing Statistics',
    value: 'AgeingStatistics',
    isTab: false,
    sectionName: 'Ageing Statistics',
    isMandatory: false,
  },
  {
    name: 'Disease Statistics',
    value: 'DiseaseStatistics',
    isTab: false,
    sectionName: 'Disease Statistics',
    isMandatory: false,
  },
];

export default reportingFeatures;
