import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import ValidationErrorOnSubmit from './ValidationErrorOnSubmit';
import FormBuilderGrid from './FormBuilderGrid';
import { InputLabel } from '../inputs';
import FocusError from './FocusErrorOnSubmit';

const FormBuilder = ({
  formikProps,
  formikProps: { handleSubmit },
  layoutSchema,
  formSchema,
  onSubmitValidationError,
  disableControls,
}) => {
  const renderGroupItems = layoutSchemaDetails => {
    const { layout, justify, alignItems, spacing, content, className } = layoutSchemaDetails;

    return (
      <div className={className}>
        <Grid
          container
          direction={layout}
          justify={justify}
          alignItems={alignItems}
          spacing={spacing}
        >
          {content.map((control, index) => {
            const gridItemProps = {
              control,
              formikProps,
              formSchema,
              disableControls,
            };
            if (control.layout === 'row' || control.layout === 'column') {
              return (
                <Grid item xs={control.size} key={index.toString()}>
                  {control.header && (
                    <InputLabel text={control.header} {...control.headerProps}></InputLabel>
                  )}
                  {renderGroupItems(control)}
                </Grid>
              );
            }
            return <FormBuilderGrid {...gridItemProps} key={control.layout} />;
          })}
        </Grid>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <FocusError />
      <ValidationErrorOnSubmit formik={formikProps} callback={onSubmitValidationError} />
      {renderGroupItems(layoutSchema)}
    </form>
  );
};

FormBuilder.propTypes = {
  formikProps: PropTypes.object.isRequired,
  layoutSchema: PropTypes.object.isRequired,
  formSchema: PropTypes.object.isRequired,
  onSubmitValidationError: PropTypes.func.isRequired,
  disableControls: PropTypes.bool,
};

FormBuilder.defaultProps = {
  disableControls: false,
};

export default FormBuilder;
