import administrator from './administrator';
import ancillaryData from './ancillaryData';
import drugs from './drugs';
import clinicalTrials from './clinicalTrials';
import globalState from './globalReducer';
import organization from './organization';
import company from './company';
import person from './person';
import auth from './auth';
import task from './task';
import ancillaryRequest from './ancillaryRequests';
import advancedSearch from './advancedSearch';
import sourceDocuments from './sourceDocuments';
import registry from './registry';
import reporting from './reporting';
import country from './country';
import requirement from './requirement';

const rootReducer = {
  auth,
  administrator,
  clinicalTrials,
  drugs,
  ancillaryData,
  globalState,
  organization,
  company,
  person,
  task,
  ancillaryRequest,
  advancedSearch,
  reporting,
  sourceDocuments,
  registry,
  country,
  requirement,
};
export default rootReducer;
