import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { format as formatDate, isValid } from 'date-fns';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import InputField from './InputField';
import './index.scss';
import IngestionActions from '../../ingestionActions';
import { isValidDate as isDateNull } from '../../../utils/generic/helper';

const CustomToolbar = ({ date, isLandscape, openView, setOpenView }) => {
  const handleChangeViewClick = view => () => {
    setOpenView(view);
  };

  return (
    <PickerToolbar className="datePickerCustomToolbar" isLandscape={isLandscape}>
      <ToolbarButton
        onClick={handleChangeViewClick('date')}
        variant="h6"
        selected={openView === 'date'}
        label={formatDate(date, 'do, EEEE')}
      />
      <ToolbarButton
        onClick={handleChangeViewClick('month')}
        variant="h6"
        selected={openView === 'month'}
        label={formatDate(date, 'MMM')}
      />
      <ToolbarButton
        onClick={handleChangeViewClick('year')}
        variant="h6"
        label={formatDate(date, 'yyyy')}
        selected={openView === 'year'}
      />
    </PickerToolbar>
  );
};

const CustomFormatDatePicker = ({
  name,
  label,
  value,
  onChange,
  views,
  minDate,
  isError,
  errorMessage,
  size,
  isMandatory,
  disabled,
  disablePast,
  disableFuture,
  openTo,
  orientation,
  autoOk,
  ingestionAction,
  ingestedData,
  handleIngestionActions,
  isIngestionActionDisabled,
  format,
}) => {
  const [open, setOpen] = useState(false);
  const [caretPosition, setCaretPosition] = useState(0);
  const [shouldFocus, setShouldFocus] = useState(false);

  const isValidDate = date => {
    try {
      const filter = /^([Jj][Aa][Nn]|[Ff][Ee][bB]|[Mm][Aa][Rr]|[Aa][Pp][Rr]|[Mm][Aa][Yy]|[Jj][Uu][Nn]|[Jj][uU][lL]|[aA][Uu][gG]|[Ss][eE][pP]|[oO][Cc][tT]|[Nn][oO][Vv]|[Dd][Ee][Cc])-([012]?\d|3[01])-(16|17|18|19|20|21|22)\d\d$/;
      if (
        filter.test(date) &&
        isValid(new Date(date)) &&
        formatDate(new Date(date), 'MMM-dd-yyyy').toLowerCase() === date.toLowerCase()
      ) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  const convertToDateFormat = date => {
    try {
      const formattedDate = formatDate(date, format);
      return formattedDate;
    } catch (err) {
      return null;
    }
  };

  const [selectedDate, setSelectedDate] = useState();
  const [inputFieldValue, setInputFieldValue] = useState();

  useEffect(() => {
    try {
      const date = value ? new Date(value) : null;
      if (isValid(date)) {
        const formattedDate = convertToDateFormat(date);
        setSelectedDate(formattedDate);
        setInputFieldValue(formattedDate || '');
      } else if (isDateNull(value)) {
        setSelectedDate(null);
        setInputFieldValue('');
      }
    } catch (err) {
      setSelectedDate(null);
      setInputFieldValue('');
    }
  }, [value]);

  const handleDateChange = date => {
    onChange(new Date(date));
  };
  const handleDateChangeFromKeyboard = (date, caretPositionFromInput) => {
    if (!date) {
      onChange(null);
    } else if (isValidDate(date)) {
      onChange(new Date(date));
    } else {
      onChange('INVALID DATE');
    }
    setInputFieldValue(date);
    setCaretPosition(caretPositionFromInput);
  };

  const callbackRef = useCallback(inputElement => {
    if (inputElement && !open && shouldFocus) {
      inputElement.focus();
      inputElement.setSelectionRange(caretPosition, caretPosition);
    }
  });

  const onBlurHandler = () => {
    setShouldFocus(false);
  };

  const renderInputField = () => {
    return (
      <InputField
        value={inputFieldValue}
        openCalendar={setOpen}
        label={label}
        labelSize={size}
        onBlur={onBlurHandler}
        isMandatory={isMandatory}
        valueChange={handleDateChangeFromKeyboard}
        setShouldFocus={setShouldFocus}
        forwardedRef={callbackRef}
        disabled={disabled}
        dateFormat={format}
      />
    );
  };

  return (
    <div name={name} className="custom-keyboard-date-picker-wrapper">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          openTo={openTo}
          autoOk={autoOk}
          disableFuture={disableFuture}
          disablePast={disablePast}
          margin="normal"
          id="date-picker-dialog"
          orientation={orientation}
          disabled={disabled}
          onBlur={handleDateChange}
          views={views}
          value={selectedDate || null}
          onChange={handleDateChange}
          minDate={minDate}
          inputVariant="outlined"
          invalidDateMessage="Please enter a valid date (MMM-dd-yyyy)"
          ToolbarComponent={CustomToolbar}
          onClose={() => setOpen(false)}
          open={open}
          format="mmm-dd-yyyy"
          rifmFormatter={val => val}
          TextFieldComponent={renderInputField}
        />
      </MuiPickersUtilsProvider>
      {isError && <p className="error-text">{errorMessage}</p>}
      <IngestionActions
        className="pt-10"
        isDisabled={isIngestionActionDisabled}
        ingestionAction={ingestionAction}
        handleIngestionActions={handleIngestionActions}
      >
        {ingestedData && (
          <p className="input-ingested-text">{formatDate(new Date(ingestedData), 'MMM-dd-yyyy')}</p>
        )}
      </IngestionActions>
    </div>
  );
};

CustomFormatDatePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  views: PropTypes.array,
  minDate: PropTypes.instanceOf(Date),
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  orientation: PropTypes.string,
  size: PropTypes.string,
  isMandatory: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  openTo: PropTypes.string,
  autoOk: PropTypes.bool,
  disabled: PropTypes.bool,
  ingestionAction: PropTypes.string,
  ingestedData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleIngestionActions: PropTypes.func,
  isIngestionActionDisabled: PropTypes.bool,
  format: PropTypes.string,
};
CustomFormatDatePicker.defaultProps = {
  views: ['date'],
  orientation: 'landscape',
  isError: false,
  errorMessage: '',
  size: '16',
  isMandatory: false,
  disablePast: false,
  disableFuture: false,
  autoOk: true,
  openTo: 'date',
  disabled: false,
  value: null,
  ingestionAction: 'none',
  ingestedData: null,
  handleIngestionActions: () => {},
  isIngestionActionDisabled: false,
  format: 'MMM-dd-yyyy',
};

CustomToolbar.propTypes = {
  date: PropTypes.instanceOf(Date),
  isLandscape: PropTypes.bool,
  openView: PropTypes.string,
  setOpenView: () => {},
};

export default CustomFormatDatePicker;
