/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { faPen, faTimes, faEye, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { REGINTEL_ENTITIES } from '../../utils/generic/constants';

import withClass from '../withClass';
import './index.scss';

const InternalTableActions = ({
  handleDelete,
  handleEdit,
  canDisableEdit,
  canDisableDelete,
  isViewEnable,
}) => {
  console.info('Table Actions ', {
    canDisableEdit,
    canDisableDelete,
  });
  const { selectedFeature } = useSelector(state => state.globalState, shallowEqual);
  const enablePublish = selectedFeature === 'dashboard';
  const enableView = false;
  const enableDelete = enablePublish || enableView ? false : handleDelete;
  console.table('Table Actions ', {
    selectedFeature,
    enablePublish,
    enableView,
    enableDelete,
    isViewEnable,
  });
  return (
    <>
      {handleEdit && (
        <Tooltip title="Edit Record">
          <span>
            <button disabled={canDisableEdit} type="button" onClick={handleEdit}>
              <FontAwesomeIcon icon={faPen} />
            </button>
          </span>
        </Tooltip>
      )}
      {enablePublish && (
        <Tooltip title="Publish Record">
          <span>
            <button disabled={canDisableEdit} type="button" onClick={handleDelete}>
              <FontAwesomeIcon icon={faUpload} />
            </button>
          </span>
        </Tooltip>
      )}
      {enableView && (
        <Tooltip title="View Record">
          <span>
            <button disabled={canDisableDelete} type="button" onClick={handleDelete}>
              <FontAwesomeIcon icon={faEye} />
            </button>
          </span>
        </Tooltip>
      )}
      {enableDelete && (
        <Tooltip title="Delete Record">
          <span>
            <button disabled={canDisableDelete} type="button" onClick={handleDelete}>
              <FontAwesomeIcon icon={faTimes} color="#EF426F" />
            </button>
          </span>
        </Tooltip>
      )}
    </>
  );
};

InternalTableActions.propTypes = {
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  canDisableDelete: PropTypes.bool,
  canDisableEdit: PropTypes.bool,
  isViewEnable: PropTypes.bool,
};

InternalTableActions.defaultProps = {
  handleDelete: null,
  handleEdit: null,
  canDisableDelete: false,
  canDisableEdit: false,
  isViewEnable: false,
};

export default withClass(InternalTableActions, 'action-wrapper');
