import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { isEmpty } from 'lodash';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getClassNameForIngestion } from '../../../utils/generic/helper';
import IngestionActions from '../../ingestionActions';
import InputCheckbox from '../InputCheckbox';
import InputLabel from '../InputLabel';
import './index.scss';
import InputToolTip from '../../toolTip/inputToolTip';

const InputSelect = ({
  selectRef,
  name,
  label: fieldLabel,
  data,
  isMulti,
  isSearchable,
  isClearable,
  placeholder,
  hideSelectedOptions,
  onChange,
  defaultValue,
  isTagInput,
  isCheckInput,
  defaultValuesCustom,
  handleCustomupdate,
  blurInputOnSelect,
  isMandatory,
  error,
  helperText,
  size,
  isDisabled,
  height,
  ingestionAction,
  ingestedData,
  handleIngestionActions,
  isIngestionActionDisabled,
  customClassName,
}) => {
  const [customData, setCustomData] = useState(isTagInput ? [...data] : []);
  const [selectedCustomData, setSelectedCustomData] = useState(
    defaultValuesCustom && isTagInput ? [...defaultValuesCustom] : [],
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, updateInputValue] = useState('');

  useEffect(() => {
    if (isTagInput || isCheckInput) {
      if (defaultValuesCustom.length > 0) {
        const updatedData = data.map(datum => {
          let isMatched = false;
          let matchedObj = null;
          defaultValuesCustom.forEach(element => {
            if (element.id === datum.id) {
              isMatched = true;
              matchedObj = element;
            }
          });
          return isMatched ? { ...datum, ...matchedObj } : { ...datum };
        });
        setCustomData(updatedData);
        setSelectedCustomData([...defaultValuesCustom]);
      } else {
        setSelectedCustomData([]);
        setCustomData([...data]);
      }
    }
  }, [data, defaultValuesCustom]);

  const handleCustomSelect = ({ id, isSelected, isChildCopy, isDisabled }) => {
    if (isDisabled) {
      return false;
    }
    if (!isChildCopy) {
      const updated = customData.map(custom => {
        return custom.id === id ? { ...custom, isSelected } : { ...custom };
      });
      const selected = updated.filter(u => u.isSelected === true);
      setCustomData(updated);
      setSelectedCustomData(selected);
      handleCustomupdate(selected);
    }
  };

  const handleInputChange = (val, { action }) => {
    let isInputToBeUpdated =
      isTagInput || isCheckInput
        ? action === 'input-change'
        : action === 'input-change' || action === 'set-value';
    if (isInputToBeUpdated) {
      updateInputValue(val);
    }
  };

  const onClear = () => {
    if (!(isTagInput || isCheckInput)) {
      onChange(null);
    }
  };

  // TO DO: isChildCopy should be changed to generic name for disabling
  const Option = props => {
    const {
      data: { id, label, value, isSelected, isChildCopy, isDisabled },
    } = props;
    return (
      <components.Option {...props}>
        <div
          className={isSelected ? ` ${isTagInput ? `custom-selected` : 'selected-option'}` : ''}
          onClick={() =>
            handleCustomSelect({ id, isSelected: !isSelected, isChildCopy, isDisabled })
          }
        >
          {isCheckInput && (
            <InputCheckbox id={id.toString()} label="" value={value} checked={isSelected} />
          )}
          {name === 'deliveryMediums' ||
          name === 'deliveryRoutes' ||
          name === 'deliveryTechnologies' ||
          name === 'trialTroveDeliveryMediums' ||
          name === 'trialTroveDeliveryRoutes' ||
          name === 'trialTroveDeliveryTechnologies' ? (
            <div className="">
              {isDisabled ? (
                <label className="disabledCursor">{label}</label>
              ) : (
                <label className="option-label">{label}</label>
              )}
            </div>
          ) : (
            <label className={isChildCopy ? 'custom-selected-disabled' : 'option-label'}>
              {label}
            </label>
          )}
        </div>
      </components.Option>
    );
  };

  const ClearIndicator = props => {
    return (
      <components.ClearIndicator {...props}>
        <div className="custom-clear-indicator">
          <button onClick={() => onClear()}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </components.ClearIndicator>
    );
  };

  const IndicatorSeparator = props => {
    return (
      <span className="custom-indicator-separator">
        <components.IndicatorSeparator {...props}></components.IndicatorSeparator>
      </span>
    );
  };

  // eslint-disable-next-line react/prop-types
  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <InputToolTip>
          <div>{children}</div>
        </InputToolTip>
      </components.SingleValue>
    );
  };

  const getComponent = () => {
    if (isTagInput || isCheckInput) {
      return { Option };
    } else if (isClearable && !isEmpty(defaultValue)) {
      return { ClearIndicator, IndicatorSeparator, SingleValue };
    } else if (isClearable) {
      return { ClearIndicator, IndicatorSeparator };
    } else {
      return { SingleValue };
    }
  };

  const styleName =
    isTagInput || isCheckInput ? 'custom-select-container' : 'input-select-container';
  return (
    <div name={name} className="input-select-wrapper">
      {fieldLabel && (
        <InputLabel
          labelFor="selectLabel"
          text={fieldLabel}
          isMandatory={isMandatory}
          size={size}
        />
      )}
      <Select
        ref={selectRef}
        className={`${styleName} ${height} ${error ? 'error-control' : ''} ${customClassName ||
          ''}`}
        classNamePrefix={isTagInput || isCheckInput ? 'custom-select' : 'input-select'}
        hideSelectedOptions={hideSelectedOptions}
        closeMenuOnSelect={!isMulti}
        options={
          name === 'indicationGroupId'
            ? defaultValue && defaultValue.length >= 2
              ? []
              : data
            : isTagInput || isCheckInput
            ? customData
            : data
        }
        isMulti={isMulti}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder={placeholder}
        isDisabled={isDisabled}
        noOptionsMessage={() => {
          return name === 'indicationGroupId' && defaultValue.length >= 2
            ? 'You can select maximum two options only'
            : 'No options';
        }}
        onChange={e => onChange(e)}
        defaultValue={defaultValue}
        value={defaultValue}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        components={getComponent()}
        blurInputOnSelect={blurInputOnSelect}
        controlShouldRenderValue={(isTagInput || isCheckInput) && false}
        onBlur={() => updateInputValue('')}
      />
      {error && <p className="error-text">{helperText}</p>}
      <IngestionActions
        className="pt-10"
        isDisabled={isIngestionActionDisabled}
        ingestionAction={isTagInput ? 'none' : ingestionAction}
        handleIngestionActions={handleIngestionActions}
      >
        {!isEmpty(ingestedData) && <p className="input-ingested-text">{ingestedData.value}</p>}
      </IngestionActions>
      {isTagInput && (
        <div
          className={`selected-container ${
            isMenuOpen ? 'custom-menuList-opened' : 'custom-menuList-closed'
          }`}
        >
          {selectedCustomData.map(item => {
            return (
              <IngestionActions
                isDisabled={isIngestionActionDisabled}
                ingestionAction={item.ingestionAction}
                handleIngestionActions={action => handleIngestionActions(action, item)}
                key={`${item.id}${item.value}`}
              >
                <div className="selected-container__selected-value">
                  <span className={getClassNameForIngestion(item.ingestionAction)}>
                    {item.label}
                  </span>
                  <button
                    // TO DO: isChildCopy should be changed to generic name
                    // TO DO: finalize the correct value to disable
                    disabled={item.isChildCopy || isDisabled || item.isDisabled}
                    type="button"
                    onClick={() =>
                      handleCustomSelect({
                        id: item.id,
                        isSelected: !item.isSelected,
                        isChildCopy: item.isChildCopy,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faTimes} className="close-icon" />
                  </button>
                </div>
              </IngestionActions>
            );
          })}
        </div>
      )}
    </div>
  );
};

InputSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  data: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  blurInputOnSelect: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isTagInput: PropTypes.bool,
  isCheckInput: PropTypes.bool,
  defaultValuesCustom: PropTypes.array,
  handleCustomupdate: PropTypes.func,
  isMandatory: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  ingestionAction: PropTypes.string,
  ingestedData: PropTypes.object,
  handleIngestionActions: PropTypes.func,
  isIngestionActionDisabled: PropTypes.bool,
};

InputSelect.defaultProps = {
  label: '',
  placeholder: '',
  isMulti: false,
  isSearchable: false,
  isClearable: false,
  hideSelectedOptions: false,
  onChange: () => {},
  defaultValuesCustom: [],
  isMandatory: false,
  error: false,
  helperText: '',
  size: '16',
  height: 'ht-md',
  isDisabled: false,
  blurInputOnSelect: false,
  ingestionAction: 'none',
  ingestedData: null,
  handleIngestionActions: () => {},
  isIngestionActionDisabled: false,
};

export default InputSelect;
