export const heading = 'Drug feature';
export const screenType = 'drugs';

export const drugFeatureKeys = [
  '',
  'summary',
  'names',
  'licensingAvailability',
  'notes',
  'events',
  'chemStructure',
  'landscapeStatuses',
  'activity',
  'marketing',
  'phases',
  'supportingUrls',
  'productionStatusId',
  'licensingAvailability',
  'licensingAvailability',
  'chemStructure',
  'chemStructure',
  'marketing',
  'marketing',
  'marketing',
  'marketing',
  'phases',
  'phases',
  'phases',
  'phases',
  'phases',
];

export const drugFeatureValues = {
  DRUG_PROGRAM_LANDSCAPE: 7,
};

export const drugFeatureProperities = {
  DRUG_PROGRAM_LANDSCAPE: 'landscapeStatuses',
};

export const developmentStatusData = [
  {
    id: 1,
    display: 'ACTIVE',
    isSelected: false,
  },
  {
    id: 2,
    display: 'CEASED',
    isSelected: false,
  },
  {
    id: 3,
    display: 'WIDELY LAUNCHED',
    isSelected: false,
  },
];

export const publishFlags = [
  {
    id: 1,
    display: 'YES',
    isSelected: true,
  },
  {
    id: 2,
    display: 'NO',
    isSelected: false,
  },
];

export const timeStamps = [
  {
    dateLabel: 'RECORD CREATE DATE',
    dateAccessor: 'createdDate',
    userLabel: 'USER ID',
    userAccessor: 'createdBy',
  },
  {
    dateLabel: 'LAST MODIFIED DATE',
    dateAccessor: 'modifiedDate',
    userLabel: 'USER ID',
    userAccessor: 'modifiedBy',
  },
  {
    dateLabel: 'LAST FULL REVIEW  DATE',
    dateAccessor: 'lastFullReviewDate',
    userLabel: 'USER ID',
    userAccessor: 'lastFullReviewBy',
  },
];

export const nameTypeIds = {
  innName: 1,
  labCode: 2,
  approvedName: 3,
  tradeName: 4,
  otherName: 5,
};

export const nameTypes = [
  { id: 1, display: 'INN NAME' },
  { id: 2, display: 'LAB CODE' },
  { id: 3, display: 'OTHER APPROVED NAME' },
  { id: 4, display: 'TRADE NAME' },
];
export const globalStatusdata = [
  {
    id: 'Pipeline',
    label: 'Pipeline',
    options: [
      {
        id: 1,
        label: 'Phase I Clinical Trial',
        value: 'Phase I Clinical Trial',
        groupName: 'Pipeline',
      },
      {
        id: 2,
        label: 'Phase II Clinical Trial',
        value: 'Phase II Clinical Trial',
        groupName: 'Pipeline',
      },
      {
        id: 3,
        label: 'Phase III Clinical Trial',
        value: 'Phase III Clinical Trial',
        groupName: 'Pipeline',
      },
      {
        id: 4,
        label: 'Pre- Registration',
        value: 'Pre- Registration',
        groupName: 'Pipeline',
      },
      {
        id: 5,
        label: 'Clinical Trial',
        value: 'Clinical Trial',
        groupName: 'Pipeline',
      },
      {
        id: 6,
        label: 'Not Applicable',
        value: 'Not Applicable',
        groupName: 'Pipeline',
      },
    ],
  },
  {
    id: 'Approved',
    label: 'Approved',
    options: [
      {
        id: 1,
        label: 'Registered',
        value: 'Registered',
        groupName: 'Approved',
      },
      {
        id: 2,
        label: 'Launched',
        value: 'Launched',
        groupName: 'Approved',
      },
    ],
  },
  {
    id: 'Inactive',
    label: 'Inactive',
    options: [
      {
        id: 1,
        label: 'Suspended',
        value: 'Suspended',
        groupName: 'Inactive',
      },
      {
        id: 2,
        label: 'No Development Reported',
        value: 'No Development Reported',
        groupName: 'Inactive',
      },
      {
        id: 3,
        label: 'Discontinued',
        value: 'Discontinued',
        groupName: 'Inactive',
      },
      {
        id: 4,
        label: 'Withdrwan',
        value: 'Withdrwan',
        groupName: 'Inactive',
      },
    ],
  },
];

export const developmentStatusConstant = [
  {
    id: 1,
    status: 'ACTIVE',
    createdBy: 'string',
    createdDate: '2020-02-10T08:22:15.502Z',
    modifiedBy: 'string',
    modifiedDate: '2020-02-10T08:22:15.502Z',
  },
  {
    id: 2,
    status: 'CEASED',
    createdBy: 'string',
    createdDate: '2020-02-10T08:22:15.502Z',
    modifiedBy: 'string',
    modifiedDate: '2020-02-10T08:22:15.502Z',
  },
  {
    id: 3,
    status: 'WIDELEY LAUNCHED',
    createdBy: 'string',
    createdDate: '2020-02-10T08:22:15.502Z',
    modifiedBy: 'string',
    modifiedDate: '2020-02-10T08:22:15.502Z',
  },
];

export const publishNotes = [
  {
    yes: 1,
    no: 2,
  },
];

export const originType = [
  {
    id: 1,
    display: 'YES',
  },
  {
    id: 2,
    display: 'NO',
  },
];

export const trendsSectionMapping = {
  historyLicenseeModels: {
    label: 'Licensee',
    isMultiple: true,
    rowKey: 'companykey',
    rowLabel: 'companyName',
    cellValueKey: 'status',
    cellValueLabel: 'statusdescription',
    keyForOrder: '4',
  },
  historyCountryStatusModels: {
    label: 'Country',
    isMultiple: true,
    rowKey: 'countrykey',
    rowLabel: 'countryname',
    cellValueKey: 'devstatus',
    cellValueLabel: 'devstatusdescription',
    keyForOrder: '7',
  },
  historyGlobalStatus: {
    label: 'Global Status',
    isMultiple: false,
    cellValueKey: 'worldStatus',
    cellValueLabel: 'worldstatusDescription',
    keyForOrder: '1',
  },
  historyOriginatorModels: {
    label: 'Originator',
    isMultiple: true,
    rowKey: 'originator',
    rowLabel: 'originatorDescription',
    cellValueKey: 'origstatus',
    cellValueLabel: 'origstatusDescription',
    keyForOrder: '3',
  },
  historyTherapeuticClassModels: {
    label: 'Therapeutic Class',
    isMultiple: true,
    rowKey: 'therapykey',
    rowLabel: 'therapykeydescription',
    cellValueKey: 'status',
    cellValueLabel: 'statusdescription',
    keyForOrder: '5',
  },
  historyDevelopmentStatus: {
    label: 'Class',
    isMultiple: false,
    cellValueKey: 'drugClass',
    cellValueLabel: 'drugClassDescription',
    keyForOrder: '2',
  },
  historyDiseaseModels: {
    label: 'Disease',
    isMultiple: true,
    rowKey: 'diseaseid',
    rowLabel: 'diseasedescription',
    cellValueKey: 'status',
    cellValueLabel: 'statusdescription',
    keyForOrder: '6',
  },
};

export const trendsStatusList = [
  { label: 'Launched', value: 'L', id: 'L' },
  { label: 'Registered', value: 'R', id: 'R' },
  { label: 'Pre-registration', value: 'PR', id: 'PR' },
  { label: 'Phase III Clinical Trial', value: 'C3', id: 'C3' },
  { label: 'Phase II Clinical Trial', value: 'C2', id: 'C2' },
  { label: 'Phase I Clinical Trial', value: 'C1', id: 'C1' },
  { label: 'Clinical Trial', value: 'C', id: 'C' },
  { label: 'Preclinical', value: 'P', id: 'P' },
  { label: 'Not Applicable', value: '-', id: '-' },
  { label: 'Suspended', value: 'S', id: 'S' },
  { label: 'Withdrawn', value: 'W', id: 'W' },
  { label: 'No Development Reported', value: 'N', id: 'N' },
  { label: 'Discontinued', value: 'D', id: 'D' },
];

export const trendsValidateStatus = [
  'L',
  'R',
  'PR',
  'C3',
  'C2',
  'C1',
  'C',
  'P',
  '-',
  'S',
  'W',
  'N',
  'D',
];

export const trendsDevStatus = [
  { label: 'Active', value: 'A', id: 'A' },
  { label: 'Widely Launched', value: 'L', id: 'L' },
];

export const trendsCountries = [
  {
    id: 1,
    label: 'Argentina',
    value: 'ARG',
  },
  {
    id: 2,
    label: 'Australia',
    value: 'ASL',
  },
  {
    id: 3,
    label: 'Austria',
    value: 'AUT',
  },
  {
    id: 4,
    label: 'Belgium',
    value: 'BEL',
  },
  {
    id: 7,
    label: 'Brazil',
    value: 'BRA',
  },
  {
    id: 10,
    label: 'Canada',
    value: 'CAN',
  },
  {
    id: 11,
    label: 'Chile',
    value: 'CHL',
  },
  {
    id: 12,
    label: 'China',
    value: 'CHN',
  },
  {
    id: 13,
    label: 'Colombia',
    value: 'COL',
  },
  {
    id: 17,
    label: 'Denmark',
    value: 'DEN',
  },
  {
    id: 19,
    label: 'Finland',
    value: 'FIN',
  },
  {
    id: 20,
    label: 'France',
    value: 'FRA',
  },
  {
    id: 21,
    label: 'Germany',
    value: 'GER',
  },
  {
    id: 22,
    label: 'Greece',
    value: 'GRE',
  },
  {
    id: 23,
    label: 'Hong Kong, S.A.R., China',
    value: 'HKG',
  },
  {
    id: 26,
    label: 'India',
    value: 'IND',
  },
  {
    id: 27,
    label: 'Ireland',
    value: 'IRL',
  },
  {
    id: 28,
    label: 'Israel',
    value: 'ISR',
  },
  {
    id: 29,
    label: 'Italy',
    value: 'ITA',
  },
  {
    id: 30,
    label: 'Japan',
    value: 'JAP',
  },
  {
    id: 32,
    label: 'Luxembourg',
    value: 'LUX',
  },
  {
    id: 33,
    label: 'Malaysia',
    value: 'MAL',
  },
  {
    id: 34,
    label: 'Mexico',
    value: 'MEX',
  },
  {
    id: 37,
    label: 'Netherlands',
    value: 'NET',
  },
  {
    id: 39,
    label: 'New Zealand',
    value: 'NZ',
  },
  {
    id: 38,
    label: 'Norway',
    value: 'NOR',
  },
  {
    id: 40,
    label: 'Peru',
    value: 'PER',
  },
  {
    id: 41,
    label: 'Philippines',
    value: 'PHI',
  },
  {
    id: 43,
    label: 'Portugal',
    value: 'POR',
  },
  {
    id: 44,
    label: 'Russian Federation',
    value: 'RUS',
  },
  {
    id: 45,
    label: 'South Africa',
    value: 'SAF',
  },
  {
    id: 49,
    label: 'South Korea',
    value: 'SOK',
  },
  {
    id: 50,
    label: 'Spain',
    value: 'SPA',
  },
  {
    id: 51,
    label: 'Sweden',
    value: 'SWE',
  },
  {
    id: 52,
    label: 'Switzerland',
    value: 'SWI',
  },
  {
    id: 53,
    label: 'Thailand',
    value: 'THA',
  },
  {
    id: 54,
    label: 'Turkey',
    value: 'TUR',
  },
  {
    id: 56,
    label: 'UK',
    value: 'UK',
  },
  {
    id: 57,
    label: 'USA',
    value: 'USA',
  },
  {
    id: 58,
    label: 'Venezuela',
    value: 'VEN',
  },
];
