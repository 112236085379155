import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DNADataGrid, Link, ListView, DefaultCellRender } from '../../../../../../framework';
import { getDrugTrialAssociation } from '../../../../../../store/actions/drugs';
import PaginationWrapper from '../../../../../../generic/features/paginationWrapper';

const AssociatedTrials = () => {
  const {
    current: { id: drugId },
    drugAssociation: {
      associatedTrials: {
        associatedTrialsData,
        pagination,
        pagination: { pageSize, pageIndex },
      },
    },
  } = useSelector(state => state.drugs, shallowEqual);

  const history = useHistory();
  const dispatch = useDispatch();
  const [pageDetails, setPageDetails] = useState({ pageIndex: 1, pageSize: 50 })
  const currPage = pageDetails.pageIndex;
  const currpageSize = pageDetails.pageSize;
  const currentPageRef = useRef({ pageIndex: currPage, pageSize: currpageSize })
  const [sortedColumnName, setSortedColumnName] = useState('');
  const [sortingOrder, setSortingOrder] = useState(false);
  const prevPageState = useRef({ prevSortingColumn: '', prevSortingOrder: false })

  useEffect(() => {
    dispatch(getDrugTrialAssociation({ pageSize, pageIndex, entityPrimaryKey: drugId, sortOrder: [{ columnName: 'All', isDescendingOrder: false }] }));
  }, []);

  useEffect(() => {
    currentPageRef.current = { pageIndex: currPage, pageSize: currpageSize }
  }, [currPage, currpageSize])

  const navigateToTrial = id => {
    window.open(`/clinicalTrials/${id}`, '_blank');
  };

  let prevSortingOrder = false;
  let prevSortingColumn = '';
  const handleTableHeaderClick = (columnName, isDescendingOrder) => {
    setSortedColumnName(columnName)
    setSortingOrder(isDescendingOrder)
    // if condition added to avoid dispatching the action multiple times due to comparator function
    if (prevSortingOrder !== isDescendingOrder || prevSortingColumn !== columnName) {
      prevSortingColumn = columnName
      prevSortingOrder = isDescendingOrder
      dispatch(
        getDrugTrialAssociation({
          entityPrimaryKey: drugId,
          pageIndex: currentPageRef.current.pageIndex,
          pageSize: currentPageRef.current.pageSize,
          sortOrder: [
            {
              columnName: columnName,
              isDescendingOrder: isDescendingOrder,
            },
          ],
        }),
      );
    };
  }

  const handleSortChanged = (args) => {
    const { api } = args;
    const sortState = api.getSortModel();
    if (sortState.length === 0) {
      dispatch(
        getDrugTrialAssociation({
          entityPrimaryKey: drugId,
          pageIndex: currentPageRef.current.pageIndex,
          pageSize: currentPageRef.current.pageSize,
          sortOrder: [
            {
              columnName: "All",
              isDescendingOrder: false,
            },
          ],
        }),
      );
    };
    setSortedColumnName('')
    setSortingOrder(false)
  }

  const columnDefs = ({ handleTableHeaderClick }) => [
    {
      headerName: 'TRIAL ID',
      field: 'trialId',
      width: 100,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('trail_id', isDescendingOrder)
      }
    },
    {
      headerName: 'TRIAL TITLE',
      field: 'title',
      width: 180,
      sortable: true,
      cellRenderer: 'Link',
      cellRendererParams: ({ data }) => {
        if (data.title) {
          return {
            link: data.title,
            onClick: () => {
              navigateToTrial(data.trialId);
            },
            customTextToolTip: (data && data.title) || '',
          };
        }
      },
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('trail_title', isDescendingOrder)
      }
    },
    {
      headerName: 'PROTOCOL ID',
      headerClass: 'protocolIdAssociatedDrugTrial',
      field: 'protocolIds',
      width: 100,
      cellRenderer: 'ListView',
      cellRendererParams: ({ data }) => {
        return {
          list: data.protocolIds || [],
        };
      },
      sortable: true,
    },
    {
      headerName: 'TRIAL STATUS',
      field: 'status',
      width: 100,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4]
        handleTableHeaderClick('trail_status', isDescendingOrder)
      }
    },
    {
      headerName: 'TRIAL PHASE',
      headerClass: 'phaseAssociatedDrugTrial',
      field: 'phase',
      width: 100,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
    },
    {
      headerName: 'DISEASE TYPE',
      headerClass: 'diseaseTypesAssociatedDrugTrial',
      field: 'diseaseTypes',
      width: 100,
      cellRenderer: 'ListView',
      cellRendererParams: ({ data }) => {
        return {
          list: data.diseaseTypes || [],
        };
      },
      sortable: true,
    },
    {
      headerName: 'PRIMARY DRUG',
      headerClass: 'primaryDrugsAssociatedDrugTrial',
      field: 'primaryDrugs',
      width: 100,
      sortable: true,
      cellRenderer: 'ListView',
      cellRendererParams: ({ data }) => {
        return {
          list: data.primaryDrugs || [],
        };
      },
    },
    {
      headerName: 'SPONSOR',
      headerClass: 'sponsorsAssociatedDrugTrial',
      field: 'sponsors',
      width: 100,
      sortable: true,
      cellRenderer: 'ListView',
      cellRendererParams: ({ data }) => {
        return {
          list: data.sponsors || [],
        };
      },
    },
  ];

  return (
    <PaginationWrapper
      pageInfo={pagination}
      onChange={pageDetails => {
        setPageDetails(pageDetails);
        dispatch(
          getDrugTrialAssociation({
            ...pageDetails,
            entityPrimaryKey: drugId,
            sortOrder: [
              {
                columnName: sortedColumnName,
                isDescendingOrder: sortingOrder,
              },
            ],
          }),
        );
      }}
    >
      <DNADataGrid
        handleSortChanged={handleSortChanged}
        // columnDefs={[...columnDefs]}
        columnDefs={columnDefs({ handleTableHeaderClick })}
        rowData={JSON.parse(JSON.stringify(associatedTrialsData))}
        frameworkComponents={{ ListView, Link, DefaultCellRender }}
        rowHeight={60}
      />
    </PaginationWrapper>
  );
};
export default AssociatedTrials;
