var loggedInUserGroup = localStorage.getItem('usergroup');
const administratorFeatures =
  loggedInUserGroup == 'StarUser'
    ? [
        {
          name: 'DATA SOURCE GROUP (DSG)',
          value: 'DataSourceGroup',
          isTab: false,
          sectionName: 'Data Source Group',
          isMandatory: false,
        },
        {
          name: 'ANCILLARY DATA',
          value: 'AncillaryData',
          isTab: false,
          sectionName: 'Ancillary Data',
          isMandatory: false,
        },
        {
          name: 'DATA SOURCE',
          value: 'AddDataSource',
          isTab: false,
          isMandatory: false,
        },
        {
          name: 'USERS / ROLES & PERMISSION',
          value: 'UsersRolesPermissions',
          isTab: false,
          isMandatory: false,
        },
        {
          name: 'AGEING',
          value: 'Ageing',
          isTab: false,
          isMandatory: false,
        },
        {
          name: 'QUEUE SETUP',
          value: 'QueueSetup',
          isTab: false,
          isMandatory: false,
        },
      ]
    : [
        {
          name: 'ANCILLARY DATA',
          value: 'AncillaryData',
          isTab: false,
          sectionName: 'Ancillary Data',
          isMandatory: false,
        },
        {
          name: 'USERS / ROLES & PERMISSION',
          value: 'UsersRolesPermissions',
          isTab: false,
          isMandatory: false,
        },
        {
          name: 'AGEING',
          value: 'Ageing',
          isTab: false,
          isMandatory: false,
        },
      ];

export default administratorFeatures;
