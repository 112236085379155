import { format } from 'date-fns';

import { fieldTypes } from '../../../../../framework/formBuilder/utils';
import {
  regexToAllowPositiveNumbersAndDecimals,
  regexToAllowOnlyPositiveNumbers,
  checkIfStringIsEmpty,
  getClassNameForIngestion,
  checkForIngestionAction,
  is32BitSignedInteger,
} from '../../../../../utils/generic/helper';

export const initialValues = {
  financialYearEnd: null,
  revenue: null,
  rdSpend: null,
  noOfEmployees: null,
};

export const layoutSchema = {
  layout: 'row',
  className: 'mb-40',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'financialYearEnd',
      size: 2,
      className: '',
    },
    {
      layout: 'revenue',
      size: 2,
      className: '',
    },
    {
      layout: 'rdSpend',
      size: 2,
      className: '',
    },
    {
      layout: 'noOfEmployees',
      size: 2,
      className: '',
    },
    {
      layout: 'button',
      size: 1,
      className: ' ',
    },
  ],
};

export const formSchema = {
  financialYearEnd: {
    name: 'financialYearEnd',
    type: fieldTypes.date,
    label: 'FINANCIAL YEAR END',
    options: [],
    props: {
      views: ['month', 'year'],
      disableFuture: true,
      inputHeight: 'ht-lg',
      format: 'MMM yyyy',
      clearable: true
    },
  },
  revenue: {
    name: 'revenue',
    type: fieldTypes.text,
    label: 'REVENUE ($000)',
    options: [],
    props: {
      inputHeight: 'ht-lg',
    },
  },
  rdSpend: {
    name: 'rdSpend',
    type: fieldTypes.text,
    label: 'R&D SPEND ($000)',
    options: [],
    props: {
      inputHeight: 'ht-lg',
    },
  },
  noOfEmployees: {
    name: 'noOfEmployees',
    type: fieldTypes.text,
    label: 'EMPLOYEES',
    options: [],
    props: {
      inputHeight: 'ht-lg',
    },
  },
  button: {
    name: 'button',
    type: fieldTypes.button,
    label: '+ ADD',
  },
};

const dateFormatter = params => {
  if (params && params.value) {
    return format(new Date(params.value), 'MMM yyyy');
  }
};

const currencyFormatter = params => {
  if (params && params.value) {
    const data = parseFloat(params.value);
    const formattedRevenue = Number.isInteger(data) ? data : data.toFixed(2);
    return `$${formattedRevenue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }
};

export const columnDefs = ({
  handleDelete,
  handleEdit,
  handleIngestionActions,
  disableEdit,
  financialEntires,
}) => [
    {
      headerName: 'FINANCIAL YEAR END',
      field: 'financialYearEnd',
      width: 300,
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
      sortable: true,
      valueFormatter: dateFormatter,
    },
    {
      headerName: 'REVENUE ($000)',
      field: 'revenue',
      width: 300,
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
      sortable: true,
      valueFormatter: currencyFormatter,
    },
    {
      headerName: 'R&D SPEND ($000)',
      field: 'rdSpend',
      width: 300,
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
      sortable: true,
      valueFormatter: currencyFormatter,
      comparator: (a, b) => {
        return a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      }
    },
    {
      headerName: 'EMPLOYEES',
      field: 'noOfEmployees',
      width: 300,
      cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
      sortable: true,
      cellRenderer: 'defaultRenderer'
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { data } = params;
        return {
          handleEdit: () => {
            handleEdit(data);
          },
          canDisableEdit: disableEdit || data.isDisabled,
        };
      },
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      field: '',
      cellRenderer: 'actions',
      cellRendererParams: params => {
        const { data } = params;
        return {
          handleDelete: () => {
            handleDelete(data);
          },
          canDisableDelete: disableEdit || data.isDisabled,
        };
      },
    },
    {
      headerName: '',
      minWidth: 100,
      maxWidth: 100,
      field: '',
      hide: !checkForIngestionAction(financialEntires, true),
      cellRenderer: 'ingestionActions',
      cellRendererParams: ({ data: rowData }) => {
        return {
          ingestionAction: rowData ? rowData.ingestionAction : 'none',
          isDisabled: disableEdit,
          handleIngestionActions: option => handleIngestionActions(option, rowData),
        };
      },
    },
  ];

export const validate = ({ financialYearEnd, revenue, rdSpend, noOfEmployees }) => {
  let errors = {};
  if (!financialYearEnd) {
    errors = {
      ...errors,
      financialYearEnd: 'Please provide details for the financial year end',
    };
  }

  if (
    !checkIfStringIsEmpty(revenue) &&
    !regexToAllowPositiveNumbersAndDecimals.test(revenue.trim())
  ) {
    errors = {
      ...errors,
      revenue: 'Please enter a valid number',
    };
  }

  if (
    !checkIfStringIsEmpty(rdSpend) &&
    !regexToAllowPositiveNumbersAndDecimals.test(rdSpend.trim())
  ) {
    errors = {
      ...errors,
      rdSpend: 'Please enter a valid number',
    };
  }

  if (
    !checkIfStringIsEmpty(noOfEmployees) &&
    !regexToAllowOnlyPositiveNumbers.test(noOfEmployees.trim())
  ) {
    errors = {
      ...errors,
      noOfEmployees: 'Please enter a valid number',
    };
  } else if (is32BitSignedInteger(parseInt(noOfEmployees, 10))) {
    errors = {
      ...errors,
      noOfEmployees: 'Value exceeds the limit (2147483647)',
    };
  }

  return errors;
};
