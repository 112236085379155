import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';
import {
  setSelectedTabIndex,
  getCompanyListSuccess,
  deleteCompanySuccess,
  getMasterDataSuccess,
  getCompanyDataByIdSuccess,
  resetCompanyState,
  resetMasterData,
  updateCompanyDetails,
  saveCompanyDetailsSuccess,
  resetCompanyData,
  featureSelection,
  nextFeatureSelection,
  setPreviewSelection,
  setSelectedQueueType,
  getCompanyQueueRecordsSuccess,
  getQueueTypesSuccess,
  getTaskQueueTypesSuccess,
  getStateDataByCountryIdSuccess,
  setCompanyIngestedData,
  getTaskQueueRecordsSuccess,
  resetCompanyIngestedData,
  updateCompanyChildValues,
  updateCompanyIngestedActions,
  getCompanyUserLockDetails,
  getCompanyList,
  getCompanyQueueRecords,
  resetCompanyUserLock,
  getCompanyAssociationDetails,
  getCompanyAssociationDetailsSuccess,
  getTrialDetailsBasedOnDrug,
  getTrialDetailsBasedOnDrugSuccess,
  setQueueExpandedList,
  setQueueTransactionId,
  setCompanyValidationStatus,
  getTrialDetailsBasedOnDrugError,
  saveCompanyDetails,
  setCompanyChangeStatus,
  getCompanyProximityDataSuccess,
  resetCompanyProximityData,
  getCompanyCompareDataSuccess,
  resetCompanyCompareData,
  getCompanyStampingDetailsSuccess,
} from '../actions/company';
import {
  setMasterData,
  setCompanyData,
  updateCompanyDataOnSave,
  setCompanyCurrentAndIngestedData,
  getNextIngestedTab,
  updateTrialDetailsBasedOnDrugId,
  getCompanyStampDates,
  updateCompanyStampingDetails,
} from '../helpers/company';
import { resetIngestionAction, checkIfObjectIsEmpty } from '../../utils/generic/helper';
import validateTabs from '../../utils/generic/validation';

const initialState = InitialState.company;

const company = createReducer(initialState, {
  [featureSelection]: (state, { payload: { value } }) => {
    state.selected = value;
  },
  [nextFeatureSelection]: (state, { payload }) => {
    const filteredTabs = state.tabs.filter((i) => i.value === payload);
    const index = state.tabs.findIndex((i) => i.value === payload);
    const isSingleModeActive = state.isSinglePageMode;
    if (filteredTabs.length > 0) {
      const { isTab, selectedTabIndex, tabs } = filteredTabs[0];
      if (isTab) {
        if (selectedTabIndex < tabs.length - 1) {
          state.tabs[index].selectedTabIndex = selectedTabIndex + 1;
        } else if (selectedTabIndex === tabs.length - 1) {
          const tabValue = state.tabs[index + 1].value;
          isSingleModeActive ? (state.previewSelected = tabValue) : (state.selected = tabValue);
          state.tabs[index].selectedTabIndex = 0;
        }
      } else if (isSingleModeActive) {
        const { ingestedTab, activeTabIndex } = getNextIngestedTab(
          [...state.tabs],
          state.previewSelected,
        );
        state.previewSelected = ingestedTab
          ? ingestedTab.value
          : state.tabs[activeTabIndex + 1].value;
      } else {
        state.selected = state.tabs[index + 1].value;
      }
    }
  },

  [setSelectedTabIndex]: (state, { payload: { id, tabIndex } }) => {
    const index = state.tabs.findIndex((i) => i.value === id);
    if (index > -1) {
      state.tabs[index].selectedTabIndex = tabIndex;
    }
  },

  [getCompanyList]: (state) => {
    state.dashboard.queues.queueRecords = {};
  },

  [getCompanyListSuccess]: (state, { payload }) => {
    state.dashboard.queues.queueRecords = payload;
  },

  [deleteCompanySuccess]: (state, { payload }) => {
    const results = state.dashboard.queues.queueRecords.results;
    state.dashboard.queues.queueRecords.results = results.filter(
      (company) => company.id !== payload,
    );
  },

  [getMasterDataSuccess]: (state, { payload: data }) => {
    const updatedData = setMasterData(data);
    state.masterData = updatedData;
  },

  [getCompanyDataByIdSuccess]: (state, { payload: data }) => {
    const updatedData = setCompanyData(data);
    state.original = updatedData;
    state.current = updatedData;
    state.recordStartDateTime = new Date().toUTCString();

    state.originalStampDates = getCompanyStampDates(state.original.basicAndContactInfo.recordDates);
  },

  [resetCompanyState]: state => {
    const { tabs, selected, original, current, ingested, companyAssociations,originalStampDates, } = initialState;
    state.tabs = tabs;
    state.selected = selected;
    state.original = original;
    state.current = current;
    state.ingested = ingested;
    state.companyAssociations = companyAssociations;
    state.isStamped = false;
    state.originalStampDates = originalStampDates;
  },

  [resetMasterData]: (state) => {
    const { masterData } = initialState;
    state.masterData = masterData;
  },

  [updateCompanyDetails]: (state, { payload: { key, data } }) => {
    state.current[key] = data;
  },

  [saveCompanyDetails]: (state, { payload: { key } }) => {
    const selected = state.tabs.find((tab) => tab.key === key);
    if (selected) {
      selected.hasError = false;
    }
  },

  [saveCompanyDetailsSuccess]: (state, { payload: { data, key } }) => {
    if (data && key) {
      const { ingested } = initialState;
      const { updatedCurrentData, updatedOriginalData } = updateCompanyDataOnSave({
        apiData: data,
        key,
        currentData: { ...state.current },
      });
      state.current = updatedCurrentData;
      state.original = updatedOriginalData;
      state.recordStartDateTime = new Date().toUTCString();

      state.originalStampDates = getCompanyStampDates(
        state.original.basicAndContactInfo.recordDates,
      );
      state.isStamped = false;

      const tabIndex = state.tabs.findIndex(tab => tab.key === key);
      if (tabIndex > -1) {
        if (state.ingested[key] && ingested[key]) {
          state.ingested[key] = ingested[key];
          state.tabs[tabIndex].hasIngestedData = false;
        }
        state.tabs[tabIndex].hasUnSavedData = false;
      }
    }
  },
  [getCompanyStampingDetailsSuccess]: (state, { payload: { data } }) => {
    state.isStamped = true;
    const { current, original } = updateCompanyStampingDetails(
      {
        ...data.companyBasicInfo,
        lastFullReviewBy: data.companyBasicInfo.lastFullReviewedBy,
        nextReviewDate: data.companyBasicInfo.nextFullReviewDate,
      },
      state.current,
      state.original,
    );
    state.current = current;
    state.original = original;
  },
  [setCompanyValidationStatus]: (state, { payload: tabs = [] }) => {
    tabs.forEach(({ index, isValid }) => {
      state.tabs[index].hasError = !isValid;
    });
  },
  [setCompanyChangeStatus]: (state, { payload: tabs = [] }) => {
    tabs.forEach(({ index, hasUnSavedData }) => {
      state.tabs[index].hasUnSavedData = hasUnSavedData;
    });
  },

  [resetCompanyData]: (state, { payload }) => {
    const selected = state.tabs.find((tab) => tab.key === payload);
    if (selected && selected.hasIngestedData) {
      selected.hasUnSavedData = false;
      const { ingestedData, updatedCurrentData } = resetIngestionAction(
        state.ingested[payload],
        state.current[payload],
        state.original[payload],
      );
      state.ingested[payload] = ingestedData;
      state.current[payload] = updatedCurrentData;
    } else {
      if (selected) {
        selected.hasUnSavedData = false;
        selected.hasError =
          selected.hasError &&
          Boolean(validateTabs.company[payload]) &&
          !checkIfObjectIsEmpty(
            validateTabs.company[payload](state.original[payload], state.original),
          );
      }
      state.current[payload] = state.original[payload];
    }
  },

  [setPreviewSelection]: (state, { payload: { val, isActive } }) => {
    state.isSinglePageMode = isActive;
    state.previewSelected = val;
  },

  //Queues
  [setSelectedQueueType]: (state, { payload }) => {
    state.dashboard.queues.queueTypeSelected = payload;
  },
  [setQueueExpandedList]: (state, { payload }) => {
    state.dashboard.queues.queueExpandedList = payload;
  },

  [getCompanyQueueRecords]: (state, { payload: { emptyRecords } }) => {
    let queueRecords = emptyRecords ? {} : state.dashboard.queues.queueRecords;
    state.dashboard.queues.queueRecords = queueRecords;
  },

  [getCompanyQueueRecordsSuccess]: (state, { payload }) => {
    state.dashboard.queues.queueRecords = payload;
  },

  [getQueueTypesSuccess]: (state, { payload }) => {
    let { data = [] } = payload;
    state.dashboard.queues.queueTypes = data;
  },
  [getTaskQueueTypesSuccess]: (state, { payload }) => {
    let { data = [] } = payload;
    state.dashboard.taskQueues.taskQueueTypes = data;
  },
  [getTaskQueueRecordsSuccess]: (state, { payload }) => {
    state.dashboard.taskQueues.taskQueueRecords = payload;
  },
  [setQueueTransactionId]: (state, { payload }) => {
    state.queueTransactionId = payload;
  },

  [setCompanyIngestedData]: (state, { payload }) => {
    const {
      updatedIngestedData,
      updatedCurrentData,
      updatedTabs,
    } = setCompanyCurrentAndIngestedData({ ...state.current }, { ...payload }, [...state.tabs]);
    state.ingested = { ...updatedIngestedData };
    state.current = { ...updatedCurrentData };
    state.tabs = [...updatedTabs];
  },

  [resetCompanyIngestedData]: (state) => {
    const { ingested } = initialState;
    state.ingested = ingested;
  },

  [updateCompanyIngestedActions]: (
    state,
    { payload: { parentKey, childKey, ingestionAction } },
  ) => {
    const ingestedData = state.ingested[parentKey];
    ingestedData.fieldActions[childKey] = ingestionAction;
    state.ingested[parentKey] = ingestedData;
  },

  [updateCompanyChildValues]: (state, { payload: { parentKey, childKey, data } }) => {
    const currentData = state.current[parentKey];
    currentData[childKey] = data;
    state.current[parentKey] = currentData;
  },

  [getCompanyUserLockDetails]: (state, { payload: { isEditable, lockedBy, userLockPeriod } }) => {
    const updatedData = {
      isLocked: !isEditable,
      lockedBy,
      userLockPeriod,
    };
    state.companyLockDetails = updatedData;
  },
  [resetCompanyUserLock]: (state) => {
    const { companyLockDetails } = initialState;
    state.companyLockDetails = companyLockDetails;
  },

  [getCompanyAssociationDetails]: (state) => {
    state.companyAssociations = { ...initialState.companyAssociations };
  },

  [getCompanyAssociationDetailsSuccess]: (
    state,
    { payload: { pageIndex, pageSize, rowCount, results } },
  ) => {
    state.companyAssociations = {
      ...state.companyAssociations,
      drugsData: results || [],
      pagination: {
        pageIndex,
        pageSize,
        rowCount,
      },
    };
  },

  [getTrialDetailsBasedOnDrugSuccess]: (state, { payload }) => {
    const {
      companyAssociations: { drugsData },
    } = state;
    state.companyAssociations = {
      ...state.companyAssociations,
      drugsData: updateTrialDetailsBasedOnDrugId([...drugsData], payload),
    };
  },

  [getCompanyProximityDataSuccess]: (state, { payload }) => {
    state.proximityComparisonData = payload;
  },

  [getTrialDetailsBasedOnDrugError]: (state, { payload }) => {
    const { entityPrimaryKey } = payload;
    const {
      companyAssociations: { drugsData },
    } = state;
    state.companyAssociations = {
      ...state.companyAssociations,
      drugsData: updateTrialDetailsBasedOnDrugId([...drugsData], {
        drugId: entityPrimaryKey,
        response: { pageSize: 50, currentPage: 1, rowCount: 0, results: [] },
      }),
    };
  },
  [resetCompanyProximityData]: (state) => {
    state.proximityComparisonData = { ...initialState.proximityComparisonData };
  },

  [getCompanyCompareDataSuccess]: (state, { payload }) => {
    state.compareRecordsData = payload;
  },
  [resetCompanyCompareData]: (state) => {
    state.compareRecordsData = { ...initialState.compareRecordsData };
  },
});
export default company;
