const countryFeatures = [
  {
    name: 'Record update and Summary',
    value: 'RecordUpdateAndSummary',
    key: 'recordUpdateAndSummary',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'Clinical Trial Regulation - General Information',
    value: 'RequirementGeneralInformation',
    key: 'clinicalTrialRegulation', //'requirementGeneralInformation',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'Ethics Requirements - General Information',
    value: 'RequirementEthicsInformation',
    key: 'ethicsCommittee',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'Disclosure Regulation - General Information',
    value: 'RequirementSearchstatistics',
    key: 'disclosureRegulationModel', //'requirementSearchstatistics',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'Disclosure Requirement - General Requirements',
    value: 'RequirementWhoEntersTheData',
    key: 'discloseRequirement',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'Trial / Study',
    value: 'RequirementClinicalTrialCharacteristics',
    key: 'clinicalTrialCharacteristicsModel', //'requirementClinicalTrialCharacteristics',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
    tabId: 1,
  },
  {
    name: 'Protocol Registration',
    value: 'ProtocolRegistrationRegistry',
    key: 'protocolRegistrationRequirement', //'protocolRegistrationRegistry',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
    tabId: 1,
  },
  {
    name: 'PLSP Protocol',
    value: 'RequirementPlspProtocol',
    key: 'plsProtocol',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'Results Disclosure',
    value: 'ResultsDisclosureInRegistry',
    key: 'resultDisclosureModel', //'resultsDisclosureInRegistry',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
    tabId: 1,
  },
  {
    name: 'Trial / Study',
    value: 'RequirementClinicalTrialCharacteristicsB',
    key: 'clinicalTrialCharacteristicsModel', //'requirementClinicalTrialCharacteristicsB',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
    disabled: true,
    tabId: 2,
  },
  {
    name: 'Protocol Registration',
    value: 'ProtocolRegistrationRegistryB',
    key: 'protocolRegistrationRequirement',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
    disabled: true,
    tabId: 2,
  },
  {
    name: 'Results Disclosure',
    value: 'ResultsDisclosureInRegistryB',
    key: 'resultDisclosureModel',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
    disabled: true,
    tabId: 2,
  },
  {
    name: 'Trial / Study',
    value: 'RequirementClinicalTrialCharacteristicsC',
    key: 'clinicalTrialCharacteristicsModel', //'requirementClinicalTrialCharacteristicsC',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
    disabled: true,
    tabId: 3,
  },
  {
    name: 'Protocol Registration',
    value: 'ProtocolRegistrationRegistryC',
    key: 'protocolRegistrationRequirement',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
    disabled: true,
    tabId: 3,
  },
  {
    name: 'Results Disclosure',
    value: 'ResultsDisclosureInRegistryC',
    key: 'resultDisclosureModel',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
    disabled: true,
    tabId: 3,
  },
  {
    name: 'PLS Results',
    value: 'RequirementPlsResult',
    key: 'plsResults',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'Enforcement',
    value: 'Enforcement',
    key: 'enforcement',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'Regulation - Contact information',
    value: 'RequirementContactInformation',
    key: 'contactInfoModel', //'requirementContactInformation',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
  {
    name: 'Requirement - Audit Trail',
    value: 'RequirementAuditTrails',
    key: 'requirementAuditTrails',
    isTab: false,
    selectedTabIndex: 0,
    isMandatory: false,
    hasIngestedData: false,
    hasError: false,
    hasUnSavedData: false,
  },
];

export default countryFeatures;
