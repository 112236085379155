import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';

import { getUserInfoSuccess } from '../actions/auth';
import { getUserPermissions } from '../helpers/auth';

const initialState = InitialState.auth;

const authReducer = createReducer(initialState, {
  [getUserInfoSuccess]: (state, { payload }) => {
    const featurePermissions = getUserPermissions(payload);
    state.featurePermissions = featurePermissions;
    state.isAuthenticated = !!payload.user;
    state.isAuthenticating = false;
    state.user = payload.user;
    state.businessUnit = payload.businessUnit;
  },
});
export default authReducer;
