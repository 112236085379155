import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Formik } from 'formik';

import Modal from '../../../framework/modal';
import { FormBuilder } from '../../../framework';
import { assigneeUserGroupList } from '../../../store/actions/task';
import { initialValues, layoutSchema, formSchema } from './config';
import './index.scss';

const AssigneeModal = ({ onClose, queueId, isQueue }) => {
  const dispatch = useDispatch();
  const { assigneeUserGroupsListData } = useSelector(state => state.task, shallowEqual);

  useEffect(() => {
    let QueueId = isQueue ? [parseInt(queueId)] : []
    dispatch(assigneeUserGroupList(QueueId));
  }, []);

  const updatedFormSchema = {
    ...formSchema,
    assignee: {
      ...formSchema.assignee,
      options: assigneeUserGroupsListData.map(assignee => ({
        id: assignee.groupId,
        value: assignee.groupName,
        childrens: assignee.users.map(item => ({
          id: item.id,
          value: item.name,
        })),
      })),
    },
    cancel: {
      ...formSchema.cancel,
      props: {
        onClick: onClose,
      },
    },
  };

  const formRef = useRef(null);
  return (
    <Modal showSpinner isOpen onClose={onClose} heading="ASSIGN TEAMS/USER">
      <div className="generic-assignee-modal">
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            onClose(values);
          }}
        >
          {props => {
            formRef.current = props;
            return (
              <>
                <FormBuilder
                  formikProps={props}
                  layoutSchema={layoutSchema}
                  formSchema={updatedFormSchema}
                  onSubmitValidationError={() => { }}
                />
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default AssigneeModal;
