import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { isEmpty, omit } from 'lodash';

import {
  FormBuilder,
  DNADataGrid,
  TableActions,
  IngestionActions,
  FeatureFooter,
} from '../../../../../../framework';
import {
  getChemicalMasterData,
  postDrugsData,
  updateDrugDetails,
  updateDrugIngestedAction,
  resetDrugDetailsWithKey,
} from '../../../../../../store/actions/drugs';
import { showModal } from '../../../../../../store/actions/globalActions';
import { originType } from '../../../../../../utils/drugs/constants';
import { MODAL_MESSAGES } from '../../../../../../utils/generic/constants';
import {
  ConfirmationMessage,
  ModalMessage,
  ClearConfirmation,
} from '../../../../../../generic/modals';
import { searchDrugFieldTypeAheads } from '../../../../../../utils/drugs/helper';
import {
  getGridRowIndex,
  updateIngestedDataBasedOnFieldType,
  checkForIngestionAction,
  validateIngestionAction,
  isDataEqual,
  convertObjectForApi,
  convertObjectForSelectDropdown,
  checkUserIngestionAction,
} from '../../../../../../utils/generic/helper';
import RegistryModal from './registryModal';
import PubChemCIDModal from './pubChemCIDModal';
import {
  formSchema,
  initialValues,
  layoutSchema,
  validate,
  columnDefs,
  pubChemColumnDefs,
  handleValidations,
  isRegistryExists,
  registryLayoutSchema,
  registryFormSchema,
  pubChemIdFormSchema,
  pubChemIdLayoutSchema,
  isPubChemIdExists,
} from './config';
import './index.scss';

const DescriptiveData = forwardRef(({ data, permissions: { disableEdit } }, ref) => {
  const gridAPI = useRef(null);
  const formRef = useRef(null);
  const gridDataRef = useRef([]);
  const pubChemGridDataRef = useRef([]);
  const dispatch = useDispatch();
  let descriptiveDataRef = useRef(null);

  const {
    origin,
    original: { chemStructure: originalChemStructure, activity },
    current: { chemStructure, id: drugsId, key, drugType, ingestedId, productionStatusId },
    ingested: {
      chemStructure: { data: ingestedData, fieldActions: ingestedActions },
    },
  } = useSelector(state => state.drugs, shallowEqual);

  const [gridData, setGridData] = useState([]);
  const [pubChemGridData, setPubChemGridData] = useState([]);
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [deleteConfirmStatus, setDeleteConfirmStatus] = useState(false);
  const [rowDetails, setRowDetails] = useState(null);
  const [errorModalStatus, setErrorModalStatus] = useState(null);
  const [rowId, setRowId] = useState(-1);
  const [pubChemRowId, setPubChemRowId] = useState(-1);
  const [clearModal, setClearModal] = useState(false);
  const [pubChemClicked, setPubChemClicked] = useState(false);
  const [registryClicked, setRegistryClicked] = useState(false);

  const chemStructureRef = useRef(chemStructure);

  useEffect(() => {
    gridDataRef.current =
      chemStructure && chemStructure.casRegistrys ? [...chemStructure.casRegistrys] : [];

    pubChemGridDataRef.current =
      chemStructure && chemStructure.pubchemCIDs ? [...chemStructure.pubchemCIDs] : [];
  }, [chemStructure]);

  useEffect(() => {
    setGridData(chemStructure && chemStructure.casRegistrys ? chemStructure.casRegistrys : []);
    setPubChemGridData(chemStructure && chemStructure.pubchemCIDs ? chemStructure.pubchemCIDs : []);
    descriptiveDataRef.current = { ...chemStructure };
    chemStructureRef.current = chemStructure;
  }, [chemStructure]);

  useEffect(() => {
    dispatch(getChemicalMasterData());
    if (data.hasError && !data.hasIngestedData) {
      if (handleValidations(activity, mapDataToStore(false))) {
        handleSave(null);
      }
    }

    return () => {
      if (formRef.current && gridDataRef.current) {
        mapDataToStore();
      }
    };
  }, []);

  useImperativeHandle(ref, () => ({
    isChanged,
    validate: () => {
      if (data.tabs[data.selectedTabIndex].hasIngestedData) {
        return validateIngestionAction({
          ingestedActions: omit(ingestedActions, ['molecularData']),
          data: { ...formRef.current.values, casRegistrys: [...gridData] },
          type: 'drugs',
          arrayKey: 'casRegistrys',
        }).isValid;
      } else {
        const errorMessage = handleValidations(
          { ...originalChemStructure, productionStatusId: productionStatusId },
          mapDataToStore(false),
        );
        if (!isEmpty(errorMessage)) {
          setErrorModalStatus(errorMessage);
          return false;
        }
        return true;
      }
    },
  }));

  const isChanged = () => {
    let mappedData;
    mappedData = { ...mapDataToStore(false) };
    mappedData = {
      ...mappedData,
      drugDrugTypeClassifications: mappedData.drugDrugTypeClassifications.map(val => {
        delete val.drugTypeClassification.formatString;
        return { ...val };
      }),
    };
    delete mappedData.productionStatusId;
    return data.tabs[data.selectedTabIndex].hasIngestedData
      ? checkUserIngestionAction(
          {
            ...formRef.current.values,
            casRegistrys: [...gridData],
            pubchemCIDs: [...pubChemGridData],
          },
          ingestedActions,
        )
      : formRef.current.dirty ||
          !isDataEqual(
            mappedData,
            omit(originalChemStructure, [
              'patents',
              'molecularData',
              'parentDrugDrugTypeClassifications',
              'drugDrugTypeClassificationsNew',
            ]),
          );
  };

  const mapDataToStore = (dispatchAction = true) => {
    const formValues = {
      ...formRef.current.values,
      casRegistrys: [...gridDataRef.current],
      pubchemCIDs: [...pubChemGridDataRef.current],
    };

    return generateDescriptiveData(formValues, dispatchAction);
  };

  const isDisabled = val => {
    if (val) {
      if (drugType.value === 'Pharmaprojects Child Drug') {
        return val.isMasterCopy;
      } else {
        return val.isDisabled || val.isChildCopy;
      }
    }
  };

  const parseSelectTagInputs = ({ list, fieldKey, labelKey, valueKey }) => {
    if (list && list.length > 0) {
      return list.map(val => {
        let x = {
          rowId: val.id,
          ...val?.[fieldKey],
          isDisabled: isDisabled(val),
          isChildCopy: val.isChildCopy || false,
          isMasterCopy: val.isMasterCopy || false,
          label: val?.[fieldKey]?.[labelKey],
          value: val?.[fieldKey]?.[valueKey],
          isSelected: true,
        };
        if (data.hasIngestedData) {
          x = { ...x, ingestionAction: val.ingestionAction };
        }
        return x;
      });
    }
    return [];
  };

  const updatedInitialValues = {
    ...initialValues,
    origin: chemStructure.origin
      ? convertObjectForSelectDropdown(chemStructure.origin, 'id', 'description')
      : null,
    nce: chemStructure.nce === true ? 1 : 2,
    // below changes are made as per 11700, nbe: null needs to be removed
    // and below line needs to be uncommented
    // nbe: chemStructure.nbe === true ? 1 : 2,
    nbe: false,
    drugDrugTypeClassifications: parseSelectTagInputs({
      list: chemStructure?.drugDrugTypeClassifications,
      fieldKey: 'drugTypeClassification',
      labelKey: 'description',
      valueKey: 'id',
    }),
  };

  const isDataDisabled = checkForIngestionAction([...gridData]);

  const handleRegistryInputChange = () => {
    setPubChemClicked(false);
    setRegistryClicked(true);
  };

  const handleRegistry = () => {
    if (formRef.current) {
      const {
        values: { registries },
        handleSubmit,
      } = formRef.current;
      const casValue = registries.trim();
      var regForOnlyNumbers = new RegExp('^[0-9]+$');
      const casFormattedValue =
        casValue.substring(0, casValue.length - 3) +
        '-' +
        casValue.substring(casValue.length - 3, casValue.length - 1) +
        '-' +
        casValue.substring(casValue.length - 1);
      if (gridData.length < 6) {
        if (isEmpty(casValue)) {
          formRef.current.setFieldError('registries', 'CAS Registry Number is Required');
        } else if (casValue && !regForOnlyNumbers.test(casValue)) {
          formRef.current.setFieldError('registries', 'CAS Registry Number should be numeric');
        } else if (casValue.trim().length > 0 && casValue.trim().length < 4) {
          formRef.current.setFieldError(
            'registries',
            'CAS Registry Number should be atleast 4 digits',
          );
        } else if (casValue.trim().length > 11) {
          formRef.current.setFieldError(
            'registries',
            'CAS Registry Number should not exceed 11 digits',
          );
        } else {
          let updatedList = [...gridData];
          const updatedRepository = {
            id: rowId,
            cas: casFormattedValue,
          };
          updatedList = [updatedRepository, ...updatedList];

          if (
            isRegistryExists(
              chemStructure.casRegistrys ? chemStructure.casRegistrys : [],
              updatedRepository,
            )
          ) {
            setErrorModalStatus(`${casFormattedValue} is already exists`);
          } else {
            dispatch(
              updateDrugDetails({
                key: 'chemStructure',
                data: { ...chemStructure, casRegistrys: updatedList },
              }),
            );
          }
        }
      } else {
        setErrorModalStatus(
          'CAS registry number cannot be added more than 6 times for a product. Please check again.',
        );
      }
      setRowId(rowId - 1);
      handleSubmit();
    }
  };

  const hanldePubChemIdsInputChange = () => {
    setPubChemClicked(true);
    setRegistryClicked(false);
  };

  const handlePubChemIds = () => {
    if (formRef.current) {
      const {
        values: { pubchemCIDs },
        handleSubmit,
      } = formRef.current;
      const pubChemIdVal = pubchemCIDs.trim();
      var regForOnlyNumbers = new RegExp('^[0-9]+$');
      // if (pubChemGridData.length < 4) {
      if (isEmpty(pubChemIdVal)) {
        formRef.current.setFieldError('pubchemCIDs', 'PubChem CID is Required');
      } else if (pubChemIdVal && !regForOnlyNumbers.test(pubChemIdVal)) {
        formRef.current.setFieldError('pubchemCIDs', 'PubChem CID should be numeric');
      } else if (pubChemIdVal.trim().length > 9) {
        formRef.current.setFieldError('pubchemCIDs', 'PubChem CID should not exceed 9 digits');
      } else {
        let updatedList = [...pubChemGridData];
        const updatedRepository = {
          id: pubChemRowId,
          pubChemCID: Number(pubChemIdVal),
        };
        updatedList = [updatedRepository, ...updatedList];

        if (
          isPubChemIdExists(
            chemStructure.pubchemCIDs ? chemStructure.pubchemCIDs : [],
            updatedRepository,
          )
        ) {
          setErrorModalStatus(`${pubChemIdVal} already exists`);
        } else {
          dispatch(
            updateDrugDetails({
              key: 'chemStructure',
              data: { ...chemStructure, pubchemCIDs: updatedList },
            }),
          );
        }
      }
      // } else {
      //   setErrorModalStatus(
      //     'PubChem CID value cannot be added more than 4 times for a product. Please check again.',
      //   );
      // }
      setPubChemRowId(pubChemRowId - 1);
      handleSubmit();
    }
  };

  const handleIngestionActions = ({
    option,
    fieldKey,
    type,
    original = originalChemStructure[fieldKey],
  }) => {
    let ingested = ingestedData[fieldKey];

    if (ingested) {
      ingested = omit(
        {
          ...ingested,
          value: ingested.description,
        },
        ['description'],
      );
    }

    const { setFieldValue } = formRef.current;
    const updatedData = updateIngestedDataBasedOnFieldType({
      type,
      original,
      ingestionAction: option,
      current: chemStructure[fieldKey],
      ingested,
    });

    setFieldValue(fieldKey, updatedData);
    dispatch(
      updateDrugIngestedAction({
        parentKey: 'chemStructure',
        childKey: fieldKey,
        ingestionAction: option,
      }),
    );
  };
  const handleIngestionActionsForMultiSelect = ({
    option,
    ingested,
    ingestedKey,
    childKey,
    disableKey,
  }) => {
    const { values, setFieldValue } = chemStructureRef.current;
    const updatedData = updateIngestedDataBasedOnFieldType({
      type: 'array',
      ingestionAction: option,
      current: values[ingestedKey],
      ingested,
      childKey,
    });
    setFieldValue(ingestedKey, updatedData);
    updatedFormSchema = {
      ...updatedFormSchema,
      [ingestedKey]: {
        ...updatedFormSchema[ingestedKey],
        props: {
          ...updatedFormSchema[ingestedKey].props,
          [disableKey]: checkForIngestionAction(updatedData),
        },
      },
    };
  };

  const setFormScehmaProps = (values, fieldKey) => ({
    handleIngestionActions: (option, ingested) =>
      handleIngestionActionsForMultiSelect({
        option,
        ingested,
        ingestedKey: fieldKey,
        childKey: 'id',
        disableKey: 'isDisabled',
      }),
    // isDisabled: checkForIngestionAction(values[fieldKey]),
    isIngestionActionDisabled: disableEdit,
  });

  const updatedFormSchema = {
    ...formSchema,
    drugDrugTypeClassifications: {
      ...formSchema.drugDrugTypeClassifications,
      props: {
        ...formSchema.drugDrugTypeClassifications.props,
        ...setFormScehmaProps(formRef?.current?.values, 'drugDrugTypeClassifications'),
        onFilter: val => searchDrugFieldTypeAheads(val, 'drugDrugTypeClassifications'),
        isMandatory: productionStatusId == 5 ? false : true,
        parentDataArr: originalChemStructure?.drugDrugTypeClassificationsNew,
      },
    },
    origin: {
      ...formSchema.origin,
      options: origin
        ? origin.map(e => convertObjectForSelectDropdown(e, 'id', 'description'))
        : [],
      props: {
        ...formSchema.origin.props,
        ingestionAction: ingestedActions.origin,
        ingestedData: ingestedData.origin
          ? omit(
              {
                ...ingestedData.origin,
                value: ingestedData.origin.description,
              },
              ['description'],
            )
          : null,
        handleIngestionActions: option =>
          handleIngestionActions({
            option,
            fieldKey: 'origin',
            type: 'object',
            original: originalChemStructure.origin
              ? omit(
                  {
                    ...originalChemStructure.origin,
                    value: originalChemStructure.origin.description,
                  },
                  ['description'],
                )
              : null,
          }),
        isDisabled: checkForIngestionAction(ingestedActions.origin),
        isIngestionActionDisabled: disableEdit,
      },
    },
    nce: {
      ...formSchema.nce,
      options: originType,
    },
    nbe: {
      ...formSchema.nbe,
      options: originType,
    },
  };

  const updatedRegistryFormSchema = {
    ...registryFormSchema,
    registries: {
      ...registryFormSchema.registries,
      props: {
        ...registryFormSchema.registries.props,
        disabled: isDataDisabled,
        handleInputChange: handleRegistryInputChange,
      },
    },
    button: {
      ...registryFormSchema.button,
      props: {
        ...registryFormSchema.button.props,
        onClick: handleRegistry,
        disabled: isDataDisabled,
      },
    },
  };

  const updatedPubChemIdFormSchema = {
    ...pubChemIdFormSchema,
    pubchemCIDs: {
      ...pubChemIdFormSchema.pubchemCIDs,
      props: {
        ...pubChemIdFormSchema.pubchemCIDs.props,
        disabled: isDataDisabled,
        handleInputChange: hanldePubChemIdsInputChange,
      },
    },
    pubchemCIDsButton: {
      ...pubChemIdFormSchema.pubchemCIDsButton,
      props: {
        ...pubChemIdFormSchema.pubchemCIDsButton.props,
        onClick: handlePubChemIds,
        disabled: isDataDisabled,
      },
    },
  };

  const onGridReady = params => {
    gridAPI.current = params.api;
  };

  const handleEdit = (rowData, rowIndex, type) => {
    setEditModalStatus(true);
    setRowDetails({ rowData, rowIndex, type });
  };

  const handleDelete = (rowData, rowIndex, type) => {
    setRowDetails({ rowData, rowIndex, type });
    setDeleteConfirmStatus(true);
  };

  const proceedToDelete = status => {
    if (status && rowDetails) {
      const updatedList = rowDetails.type === 'registry' ? [...gridData] : [...pubChemGridData];
      const filteredIndex = getGridRowIndex(rowDetails, updatedList);
      updatedList.splice(filteredIndex, 1);
      dispatch(
        updateDrugDetails({
          key: 'chemStructure',
          data: {
            ...chemStructure,
            casRegistrys: rowDetails.type === 'registry' ? updatedList : chemStructure.casRegistrys,
            pubchemCIDs: rowDetails.type === 'pubChem' ? updatedList : chemStructure.pubchemCIDs,
          },
        }),
      );
    }
    setRowDetails(null);
    setTimeout(() => {
      setDeleteConfirmStatus(false);
    }, 1000);
  };

  const onRowDetailsUpdate = rowInfo => {
    if (rowInfo) {
      const updatedList = rowInfo.type === 'registry' ? [...gridData] : [...pubChemGridData];
      const filteredIndex = getGridRowIndex(rowInfo, updatedList);
      updatedList.splice(filteredIndex, 1, rowInfo.rowData);

      dispatch(
        updateDrugDetails({
          key: 'chemStructure',
          data: {
            ...chemStructure,
            casRegistrys: rowInfo.type === 'registry' ? updatedList : chemStructure.casRegistrys,
            pubchemCIDs: rowInfo.type === 'pubChem' ? updatedList : chemStructure.pubchemCIDs,
          },
        }),
      );
    }

    setEditModalStatus(false);
    setRowDetails(null);
  };

  const handleSave = tabData => {
    if (formRef.current) {
      const { values } = formRef.current;
      const {
        isValid,
        data: updatedDescriptiveData,
        ingestedContentResults,
      } = validateIngestionAction({
        ingestedActions: omit(ingestedActions, ['molecularData']),
        data: { ...values, casRegistrys: [...gridData], pubchemCIDs: [...pubChemGridData] },
        type: 'drugs',
        arrayKey: 'casRegistrys',
      });

      if (isValid) {
        const updatedData = generateDescriptiveData(updatedDescriptiveData);
        const errorMessage = handleValidations(updatedData);
        setErrorModalStatus(errorMessage);
        if (isEmpty(errorMessage)) {
          dispatch(
            postDrugsData({
              id: drugsId,
              body: {
                key,
                chemStructure: updatedData,
                forceToFieldUpdate: 6,
                drugType,
                ingestedContentResults,
                ingestedId,
              },
              tabData,
              childTabKey: [
                'origin',
                'casRegistrys',
                'id',
                'drugDrugTypeClassifications',
                'pubchemCIDs',
              ],
            }),
          );
        }
      } else {
        dispatch(showModal(MODAL_MESSAGES.INGESTION_ERROR));
      }
    }
  };

  const generateDescriptiveData = (
    { nbe, nce, origin: updatedOrigin, casRegistrys, drugDrugTypeClassifications, pubchemCIDs },
    dispatchAction = true,
  ) => {
    //left
    const updatedData =
      drugType.id === 2
        ? {
            id: chemStructureRef.current.id,
            origin: updatedOrigin
              ? convertObjectForApi({
                  data: updatedOrigin,
                  key: 'value',
                  apiKey: 'id',
                  value: 'label',
                  apiValue: 'description',
                })
              : null,
            nce: nce === 1,
            // below changes are made as per 11700, nbe: null needs to be removed
            // and below line needs to be uncommented
            // nbe: nbe === 1,
            nbe: false,
            casRegistrys,
            pubchemCIDs,
            drugDrugTypeClassifications,
          }
        : {
            id: chemStructureRef.current.id,
            origin: updatedOrigin
              ? convertObjectForApi({
                  data: updatedOrigin,
                  key: 'value',
                  apiKey: 'id',
                  value: 'label',
                  apiValue: 'description',
                })
              : null,
            nce: nce === 1,
            // below changes are made as per 11700, nbe: null needs to be removed
            // and below line needs to be uncommented
            // nbe: nbe === 1,
            nbe: false,
            casRegistrys,
            pubchemCIDs,
          };
    updatedData.drugDrugTypeClassifications = updatedData?.drugDrugTypeClassifications?.map(
      drugTypes => {
        const rowIdObj = drugTypes.rowId ? { id: drugTypes.rowId } : {};
        const tweakObjectForComparing = !dispatchAction
          ? {
              displayValue: drugTypes.label,
            }
          : {
              isSelected: drugTypes.isSelected,
              isMasterCopy: false,
              label: drugTypes.label,
            };
        return {
          // ...drugTypes,
          ...rowIdObj,
          drugTypeClassification: {
            id: drugTypes.id,
            description: drugTypes.description,
            drugTypeClassificationCode: drugTypes.drugTypeClassificationCode,
            level: drugTypes.level,
            parent: drugTypes.parent,
            isLast: drugTypes.isLast,
            parentid: drugTypes.parentid,
            createdBy: drugTypes.createdBy,
            createdDate: drugTypes.createdDate,
            modifiedBy: drugTypes.modifiedBy,
            modifiedDate: drugTypes.modifiedDate,
            drugTypeClassificationSynonym: drugTypes.drugTypeClassificationSynonym,
            formatString: drugTypes.formatString,
            ...tweakObjectForComparing,
          },

          isChildCopy: false,
        };
      },
    );
    updatedData.productionStatusId = productionStatusId;
    if (dispatchAction) {
      const updatedPayload =
        drugType.id === 2
          ? {
              ...chemStructureRef.current,
              origin: updatedData.origin,
              nce: updatedData.nce,
              nbe: updatedData.nbe,
              casRegistrys,
              pubchemCIDs,
              drugDrugTypeClassifications: updatedData.drugDrugTypeClassifications,
            }
          : {
              ...chemStructureRef.current,
              origin: updatedData.origin,
              nce: updatedData.nce,
              nbe: updatedData.nbe,
              casRegistrys,
              pubchemCIDs,
            };
      dispatch(
        updateDrugDetails({
          key: 'chemStructure',
          data: updatedPayload,
        }),
      );
    }
    return updatedData;
  };

  const proceedToClear = status => {
    if (status) {
      dispatch(resetDrugDetailsWithKey({ childKey: 'origin', parentKey: 'chemStructure' }));
      dispatch(resetDrugDetailsWithKey({ childKey: 'nbe', parentKey: 'chemStructure' }));
      dispatch(resetDrugDetailsWithKey({ childKey: 'nce', parentKey: 'chemStructure' }));
      dispatch(resetDrugDetailsWithKey({ childKey: 'casRegistrys', parentKey: 'chemStructure' }));
      dispatch(resetDrugDetailsWithKey({ childKey: 'pubchemCIDs', parentKey: 'chemStructure' }));
      dispatch(
        resetDrugDetailsWithKey({
          childKey: 'drugDrugTypeClassifications',
          parentKey: 'chemStructure',
        }),
      );
      // dispatch(
      //   resetDrugDetailsWithKey({
      //     childKey: 'drugDrugTypeClassifications',
      //     parentKey: 'chemStructure',
      //   }),
      // );
      dispatch(
        resetDrugDetailsWithKey({
          childKey: 'descriptiveData',
          parentKey: 'chemStructure',
          updateState: false,
        }),
      );
      if (formRef && formRef.current) {
        formRef.current.resetForm();
      }
    } else {
      setClearModal(false);
    }
  };

  const onErrorClose = () => {
    setErrorModalStatus(null);
  };

  const handleIngestionActionsForGrid = (option, rowData) => {
    const updatedData = updateIngestedDataBasedOnFieldType({
      type: 'array',
      ingestionAction: option,
      current: gridDataRef.current || [],
      ingested: rowData,
    });
    dispatch(
      updateDrugDetails({
        key: 'chemStructure',
        data: { ...chemStructure, casRegistrys: JSON.parse(JSON.stringify(updatedData)) },
      }),
    );
    if (gridAPI && gridAPI.current) {
      gridAPI.current.setRowData(updatedData);
    }
  };

  return (
    <>
      <Grid
        container
        key="gridd"
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={2}
        className="descriptive-data-container"
      >
        <Grid item xs={12}>
          <FeatureFooter
            handleSaveAndNext={() => handleSave(data)}
            handleSave={() => handleSave(null)}
            handleClear={() => setClearModal(true)}
            data={data}
            disabled={disableEdit}
          />
          <Formik
            initialValues={updatedInitialValues}
            enableReinitialize={true}
            onSubmit={() => {
              formRef.current.setFieldTouched('registries', false);
              formRef.current.setFieldValue('registries', '');
              formRef.current.setFieldError('registries', '');
              formRef.current.setFieldTouched('pubchemCIDs', false);
              formRef.current.setFieldValue('pubchemCIDs', '');
              formRef.current.setFieldError('pubchemCIDs', '');
            }}
            validate={async values => validate(values, pubChemClicked, registryClicked)}
          >
            {props => {
              formRef.current = props;
              return (
                <div>
                  <FormBuilder
                    formikProps={props}
                    layoutSchema={layoutSchema}
                    formSchema={updatedFormSchema}
                    onSubmitValidationError={() => {}}
                    disableControls={disableEdit}
                  />
                  <FormBuilder
                    formikProps={props}
                    layoutSchema={pubChemIdLayoutSchema}
                    formSchema={updatedPubChemIdFormSchema}
                    onSubmitValidationError={() => {}}
                    disableControls={disableEdit}
                  />
                  <Grid item xs={6}>
                    <DNADataGrid
                      columnDefs={pubChemColumnDefs({
                        handleEdit,
                        handleDelete,
                        handleIngestionActionsForGrid,
                        disableEdit,
                        pubChemGridData,
                      })}
                      rowData={JSON.parse(JSON.stringify(pubChemGridData))}
                      rowHeight={35}
                      frameworkComponents={{
                        actions: TableActions,
                        ingestionActions: IngestionActions,
                      }}
                      onGridReady={onGridReady}
                      getRowClass={({ data: rowData }) => {
                        return rowData && rowData.isDisabled ? 'disable-grid-row' : '';
                      }}
                    />
                  </Grid>
                  <FormBuilder
                    formikProps={props}
                    layoutSchema={registryLayoutSchema}
                    formSchema={updatedRegistryFormSchema}
                    onSubmitValidationError={() => {}}
                    disableControls={disableEdit}
                  />
                  <Grid item xs={6}>
                    <DNADataGrid
                      columnDefs={columnDefs({
                        handleEdit,
                        handleDelete,
                        handleIngestionActionsForGrid,
                        disableEdit,
                        gridData,
                      })}
                      rowData={JSON.parse(JSON.stringify(gridData))}
                      rowHeight={35}
                      frameworkComponents={{
                        actions: TableActions,
                        ingestionActions: IngestionActions,
                      }}
                      onGridReady={onGridReady}
                      getRowClass={({ data: rowData }) => {
                        return rowData && rowData.isDisabled ? 'disable-grid-row' : '';
                      }}
                    />
                  </Grid>
                </div>
              );
            }}
          </Formik>
        </Grid>
      </Grid>

      {editModalStatus &&
        (rowDetails.type === 'registry' ? (
          <RegistryModal
            chemStructure={chemStructure}
            row={rowDetails}
            onClose={onRowDetailsUpdate}
            formSchema={updatedRegistryFormSchema}
          />
        ) : (
          <PubChemCIDModal
            chemStructure={chemStructure}
            row={rowDetails}
            onClose={onRowDetailsUpdate}
            formSchema={updatedPubChemIdFormSchema}
          />
        ))}
      {deleteConfirmStatus && (
        <ConfirmationMessage
          isOpen
          onClose={proceedToDelete}
          message="Do you want to delete this row?"
        />
      )}
      <div className="drug-footer">
        {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
        {errorModalStatus && <ModalMessage onClose={onErrorClose} message={errorModalStatus} />}
      </div>
    </>
  );
});

DescriptiveData.propTypes = {
  permissions: PropTypes.object.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isTab: PropTypes.bool.isRequired,
    tabs: PropTypes.array.isRequired,
    hasIngestedData: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    selectedTabIndex: PropTypes.number.isRequired,
  }),
};

export default DescriptiveData;
