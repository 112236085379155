import { createAction } from '@reduxjs/toolkit';
import { create } from 'istanbul-reports';

const globalRoot = 'global';
export const setError = createAction(`${globalRoot}/setGlobalError`);

export const showModal = createAction(`${globalRoot}/showModal`);
export const hideModal = createAction(`${globalRoot}/hideModal`);

export const showSpinner = createAction(`${globalRoot}/showSpinner`);
export const hideSpinner = createAction(`${globalRoot}/hideSpinner`);

export const showAlert = createAction(`${globalRoot}/showAlert`);
export const hideAlert = createAction(`${globalRoot}/hideAlert`);

export const showAdvancedSearchExpansionPanel = createAction(
  `${globalRoot}/showAdvancedSearchExpansionPanel`,
);
export const hideAdvancedSearchExpansionPanel = createAction(
  `${globalRoot}/hideAdvancedSearchExpansionPanel`,
);

export const validateSupportingURLs = createAction(`${globalRoot}validateSupportingURLs`);

export const setSelectedFeature = createAction(`${globalRoot}/setSelectedFeature`);

export const postAssigneeGroupDetails = createAction(`${globalRoot}/postAssigneeGroupDetails`);

// actions of cross-team-alert for all screens
export const getUserGroupsData = createAction(`${globalRoot}/getUserGroupsData`);

export const setUserGroupsData = createAction(`${globalRoot}/setUserGroupsData`);

export const sendCrossTeamAlert = createAction(`${globalRoot}/sendCrossTeamAlert`);

// actions of additional work flow related to ancillary addition and approval requests.

export const postAdditionalWorkFlow = createAction(`${globalRoot}/postAdditionalWorkFlow`);

export const fetchAncillaryRequests = createAction(`${globalRoot}/fetchAncillaryRequests`);

export const setAncillaryRequestsData = createAction(`${globalRoot}/setAncillaryRequestsData`);

export const updateAncillaryRequestStatus = createAction(
  `${globalRoot}updateAncillaryRequestStatus`,
);
export const fetchCrossTeamComment = createAction(`${globalRoot}/fetchCrossTeamComment`);

export const getAuditData = createAction(`${globalRoot}/getAuditData`);
export const getAuditDataSuccess = createAction(`${globalRoot}/getAuditDataSuccess`);
export const resetAuditData = createAction(`${globalRoot}/resetAuditData`);
export const setHistoricalAuditData = createAction(`${globalRoot}/setHistoricalAuditData`);

export const queueReferenceRecord = createAction(`${globalRoot}queueReferenceRecord`);

export const multiplePublish = createAction(`${globalRoot}multiplePublish`);

export const multiplePublishChangeFieldsSuccess = createAction(
  `${globalRoot}multiplePublishChangeFieldsSuccess`,
);

export const multipleChangeFieldPublish = createAction(`${globalRoot}multipleChangeFieldPublish`);
