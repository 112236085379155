import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import TodayIcon from '@material-ui/icons/Today';
import ClearIcon from '@material-ui/icons/Clear';
import { DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { format as formatDate } from 'date-fns';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import IngestionActions from '../../ingestionActions';
import InputLabel from '../InputLabel';
import { IconButton, InputAdornment } from '@material-ui/core';
import './index.scss';

const CustomToolbar = ({ date, isLandscape, openView, setOpenView, views }) => {
  const handleChangeViewClick = (view) => (e) => {
    setOpenView(view);
  };

  const checkDisableStatus = (view) => {
    return !views.includes(view);
  };

  return (
    <PickerToolbar className="datePickerCustomToolbar" isLandscape={isLandscape}>
      <ToolbarButton
        onClick={handleChangeViewClick('date')}
        variant="h6"
        disabled={checkDisableStatus('date')}
        selected={openView === 'date'}
        label={formatDate(date, 'do, EEEE')}
      />
      <ToolbarButton
        onClick={handleChangeViewClick('month')}
        variant="h6"
        disabled={checkDisableStatus('month')}
        selected={openView === 'month'}
        label={formatDate(date, 'MMM')}
      />
      <ToolbarButton
        onClick={handleChangeViewClick('year')}
        variant="h6"
        disabled={checkDisableStatus('year')}
        label={formatDate(date, 'yyyy')}
        selected={openView === 'year'}
      />
    </PickerToolbar>
  );
};

const CustomDatePicker = ({
  name,
  label,
  value,
  onChange,
  views,
  format,
  minDate,
  isError,
  errorMessage,
  size,
  isMandatory,
  disabled,
  disablePast,
  disableFuture,
  openTo,
  orientation,
  isKeyboardCtrl,
  ingestionAction,
  ingestedData,
  handleIngestionActions,
  isIngestionActionDisabled,
  autoOk,
  clearable,
  placeholder,
  defaultValue,
  ...restInputProps
}) => {
  const [selectedDate, setSelectedDate] = useState(value);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date);
  };
  const renderDatePicker = () => {
    return (
      <>
        {isKeyboardCtrl ? (
          <KeyboardDatePicker
            openTo={openTo}
            autoOk={autoOk}
            disableFuture={disableFuture}
            margin="normal"
            id="date-picker-dialog"
            orientation={orientation}
            disabled={disabled}
            // label="Date picker dialog"
            format="MM/dd/yyyy"
            views={views}
            value={selectedDate ? selectedDate : null}
            onChange={handleDateChange}
            minDate={minDate}
            inputVariant="outlined"
            invalidDateMessage="Please enter a valid date (mm/dd/yyyy)"
            ToolbarComponent={CustomToolbar}
            keyboardIcon={
              <>
                {selectedDate && clearable && !disabled && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDateChange(null);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
                <IconButton>
                  <TodayIcon />
                </IconButton>
              </>
            }
          />
        ) : (
          <DatePicker
            placeholder={placeholder}
            openTo={openTo}
            autoOk={autoOk}
            value={(selectedDate?.length !== 0 && selectedDate) ? selectedDate : (defaultValue || null)}
            disabled={disabled}
            onChange={handleDateChange}
            views={views}
            minDate={minDate}
            disablePast={disablePast}
            disableFuture={disableFuture}
            inputVariant="outlined"
            orientation={orientation}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {selectedDate && clearable && !disabled && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDateChange(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton>
                    <TodayIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            ToolbarComponent={CustomToolbar}
            format={format}
            {...restInputProps}
          />
        )}
      </>
    );
  };

  return (
    <div name={name} className={`width-100 date-wrapper ${isError && 'error-class'}`}>
      <InputLabel text={label} size={size} isMandatory={isMandatory} labelFor="dateLabel" />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>{renderDatePicker()}</MuiPickersUtilsProvider>
      {isError && <p className="error-text">{errorMessage}</p>}
      <IngestionActions
        className="pt-10"
        isDisabled={isIngestionActionDisabled}
        ingestionAction={ingestionAction}
        handleIngestionActions={handleIngestionActions}
      >
        {ingestedData && (
          <p className="input-ingested-text">{formatDate(new Date(ingestedData), format)}</p>
        )}
      </IngestionActions>
    </div>
  );
};

CustomDatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date).isRequired], null, ''),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  views: PropTypes.array,
  format: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date),
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  orientation: PropTypes.string,
  size: PropTypes.string,
  isMandatory: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  openTo: PropTypes.string,
  isKeyboardCtrl: PropTypes.bool,
  autoOk: PropTypes.bool,
  disabled: PropTypes.bool,
  ingestionAction: PropTypes.string,
  ingestedData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleIngestionActions: PropTypes.func,
  isIngestionActionDisabled: PropTypes.bool,
  clearable: PropTypes.bool,
};
CustomDatePicker.defaultProps = {
  format: 'dd MMM yyyy',
  views: ['year', 'month', 'date'],
  orientation: 'landscape',
  isError: false,
  errorMessage: '',
  size: '16',
  isMandatory: false,
  disablePast: false,
  disableFuture: false,
  autoOk: false,
  openTo: 'date',
  isKeyboardCtrl: false,
  ingestionAction: 'none',
  ingestedData: null,
  handleIngestionActions: () => {},
  isIngestionActionDisabled: false,
  disabled: false,
  clearable: true,
};

CustomToolbar.propTypes = {
  date: PropTypes.instanceOf(Date),
  isLandscape: PropTypes.bool,
  openView: PropTypes.string,
  setOpenView: () => {},
};

export default CustomDatePicker;
