import { takeEvery, put, select } from 'redux-saga/effects';
import { upperFirst } from 'lodash';
import * as ancillaryActions from '../actions/ancillaryData';
import { ancillary as ancillaryApi, company as companyApi } from '../../api';
import * as globalActions from '../actions/globalActions';
import * as ancillaryRequestActions from '../actions/ancillaryRequests';
import { MODAL_TYPES } from '../../utils/generic/constants';
import {
  getApiErrorMessage,
  getAncillaryRequestPostData,
  getCompanyDelValidateErrorMessage,
} from '../../utils/generic/helper';
import { getModalMessage, getLocationSelectedIds, getUpdatedIds } from '../helpers/ancillary';

// workers
export function* getAncillaryDataAsync({ payload }) {
  try {
    yield put({ type: globalActions.showSpinner.type });
    const response = yield ancillaryApi.getAncillaryData(payload);
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put({
        type: ancillaryActions.setAncillaryData.type,
        payload: { data, taxonomy: payload.value, payload },
      });
      yield put({ type: globalActions.hideSpinner.type });
    }
  } catch (err) {
    yield put({ type: ancillaryActions.errorOnFetchingAncillaryData.type, payload: err });
    yield put({ type: globalActions.hideSpinner.type });
  }
}

export function* addAncillaryDataAsync({ payload }) {
  const {
    taxonomy,
    taxonomy: { isAdditionalWorkflow = false, name = '' },
    values,
  } = payload;
  try {
    let updatedValues = { ...values };
    if (name === 'Sponsor' || name === 'Collaborator' || name === 'Company') {
      updatedValues = {
        ...updatedValues,
        isApprovalNeeded: isAdditionalWorkflow ? true : false,
      };
    }
    const response = yield ancillaryApi.postAncillaryData(taxonomy, updatedValues);
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        const errorMessage = respData && respData.messages ? respData.messages[0].errorMessage : '';
        const errorCode = respData && respData.messages ? respData.messages[0].errorCode : null;
        let customDuplicateMessage =
          errorCode === 'AAE01' ? 'Request already exists' : `This ${taxonomy.name} already exists`;
        let responseMessage = taxonomy.name === 'State' ? errorMessage : customDuplicateMessage;
        modalData = {
          isOpen: true,
          message: `${responseMessage}`,
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: isAdditionalWorkflow
            ? 'Ancillary Request sent successfully'
            : `${taxonomy.name} Added Successfully`,
          modalType: MODAL_TYPES.SUCCESS,
        };

        const {
          data: {
            data: { id },
          },
        } = response;

        if (isAdditionalWorkflow) {
          const { primaryField, comments, dnaParameter, recordId, recordType, status } = taxonomy;
          const requestPayload = {
            id: 0,
            value: values[primaryField],
            dnaParameter: dnaParameter,
            recordId: recordId,
            recordType: recordType,
            ancillaryId: id,
            source: '',
            sourceUrl: '',
            status: status,
            comment: comments,
          };
          yield put(globalActions.postAdditionalWorkFlow(requestPayload));
        }
      }
      yield put({ type: globalActions.showModal.type, payload: modalData });
      if (isAdditionalWorkflow === false) {
        yield put({ type: ancillaryActions.getAncillaryData.type, payload: taxonomy });
      }
    }
  } catch (err) {
    yield put({ type: ancillaryActions.errorOnFetchingAncillaryData.type, payload: err });
  }
}
export function* addSponsorCollaboratorAncillaryDataAsync({ payload }) {
  const {
    taxonomy,
    taxonomy: { isAdditionalWorkflow = false, name = '' },
    values,
  } = payload;
  try {
    let updatedValues = { ...values };
    if (name === 'Sponsor' || name === 'Collaborator') {
      updatedValues = {
        ...updatedValues,
        isApprovalNeeded: true,
      };
    }
    const response = yield ancillaryApi.postAncillaryData(taxonomy, updatedValues);
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData) {
        modalData = {
          isOpen: true,
          message: 'Record already exist.',
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: isAdditionalWorkflow
            ? 'Ancillary Request sent successfully'
            : `${taxonomy.name} Added Successfully`,
          modalType: MODAL_TYPES.SUCCESS,
        };

        if (isAdditionalWorkflow) {
          const { primaryField, comments, dnaParameter, recordId, recordType, status } = taxonomy;
          const requestPayload = {
            value: values[primaryField],
            dnaParameter: dnaParameter,
            recordId: recordId,
            recordType: recordType,
            source: '',
            sourceUrl: '',
            status: status,
            comment: comments,
          };
          yield put(globalActions.postAdditionalWorkFlow(requestPayload));
        }
      }
      yield put({ type: globalActions.showModal.type, payload: modalData });
      if (isAdditionalWorkflow === false) {
        yield put({ type: ancillaryActions.getAncillaryData.type, payload: taxonomy });
      }
    }
  } catch (err) {
    yield put({ type: ancillaryActions.errorOnFetchingAncillaryData.type, payload: err });
  }
}
export function* deleteAncillaryDataAsync({ payload }) {
  const { taxonomy, values, isAdditionalWorkFlow = false } = payload;
  try {
    let companyFlag = false;
    if (taxonomy?.value === 'company') {
      const validateDeleteResponse = yield companyApi.validateBeforeDelete(values?.id);
      const { validateState: state, data: validateData } = validateDeleteResponse.data;
      if (validateData?.associatedIDModels?.length) {
        companyFlag = true;
        yield put(globalActions.hideSpinner());
        yield put(
          globalActions.showModal({
            isOpen: true,
            modalListMessage: getCompanyDelValidateErrorMessage(validateData?.associatedIDModels),
            modalType: MODAL_TYPES.EXPANDING_ERROR_LIST,
          }),
        );
      }
    }
    if (!companyFlag) {
      const response = yield ancillaryApi.deleteAncillaryData(
        taxonomy,
        isAdditionalWorkFlow ? values.updateData : values,
      );
      if (response.status) {
        const respData = response.data;
        let modalData = null;
        if (respData && !respData.state && respData.messages) {
          if (respData.messages[0].errorCode) {
            modalData = {
              isOpen: true,
              message: `This ${taxonomy.name} is Already Tagged`,
              modalType: MODAL_TYPES.ERROR,
            };
          } else {
            modalData = {
              isOpen: true,
              message: respData.messages[0].errorMessage,
              modalType: MODAL_TYPES.ERROR,
            };
          }
        } else {
          modalData = {
            isOpen: true,
            message: `${taxonomy.name} Deleted Successfully`,
            modalType: MODAL_TYPES.SUCCESS,
          };
          if (isAdditionalWorkFlow) {
            const { workFlowData } = values;
            let updatedRequest = { ...workFlowData };

            yield put(globalActions.updateAncillaryRequestStatus(updatedRequest));
          }
        }
        yield put({ type: globalActions.showModal.type, payload: modalData });
        if (!isAdditionalWorkFlow) {
          yield put({ type: ancillaryActions.getAncillaryData.type, payload: taxonomy });
        }
      }
    }
  } catch (err) {
    if (isAdditionalWorkFlow) {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: err.message,
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put({ type: ancillaryActions.errorOnFetchingAncillaryData.type, payload: err });
  }
}
export function* updateAncillaryDataAsync({ payload }) {
  const { taxonomy, values, isAdditionalWorkFlow = false } = payload;
  try {
    const response = yield ancillaryApi.putAncillaryData(
      taxonomy,
      isAdditionalWorkFlow ? values.updateData : values,
    );
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        const errorMessage = respData && respData.messages ? respData.messages[0].errorMessage : '';
        let responseMessage =
          taxonomy.name === 'State' ||
          taxonomy.value === 'company' ||
          taxonomy.value === 'mechanismOfAction' ||
          taxonomy.value === 'targetFamily'
            ? errorMessage
            : `This ${taxonomy.name} Already Exists`;
        modalData = {
          isOpen: true,
          message: `${responseMessage}`,
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: `${taxonomy.name} ${isAdditionalWorkFlow ? 'created' : 'updated'} Successfully`,
          modalType: MODAL_TYPES.SUCCESS,
        };

        if (isAdditionalWorkFlow) {
          const { workFlowData, dnaParam } = values;
          let updatedRequest = { ...workFlowData };
          if (dnaParam === 'Company') {
            const {
              data: { name },
            } = respData;

            updatedRequest = {
              ...updatedRequest,
              value: name,
            };
          }
          yield put(globalActions.updateAncillaryRequestStatus(updatedRequest));
        }
      }

      if (!isAdditionalWorkFlow) {
        yield put({ type: globalActions.showModal.type, payload: modalData });
        yield put({ type: ancillaryActions.getAncillaryData.type, payload: taxonomy });
      }
    }
  } catch (err) {
    if (isAdditionalWorkFlow) {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: err.message,
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put({ type: ancillaryActions.errorOnFetchingAncillaryData.type, payload: err });
  }
}

export function* updateAncillaryApprovalDataAsync({ payload }) {
  const { taxonomy, values, isAdditionalWorkFlow = false } = payload;
  try {
    if (isAdditionalWorkFlow) {
      const { workFlowData, dnaParam } = values;
      let updatedRequest = { ...workFlowData };
      if (dnaParam === 'Company') {
        const { value } = workFlowData;

        updatedRequest = {
          ...updatedRequest,
          value,
        };
      }
      yield put(globalActions.updateAncillaryRequestStatus(updatedRequest));
    }
  } catch (err) {
    if (isAdditionalWorkFlow) {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: err.message,
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put({ type: ancillaryActions.errorOnFetchingAncillaryData.type, payload: err });
  }
}

export function* getListDataAsync({ payload }) {
  try {
    const state = yield select();
    const { nonAncillaryList } = payload;
    const listKeyLen = payload.listKey.length;
    for (let i = 0; i < listKeyLen; i++) {
      let listPayload = null;
      const isListCall = true;
      const listKey = payload.listKey[i];
      if (nonAncillaryList) {
        listPayload = nonAncillaryList;
      } else {
        listPayload = state.ancillaryData.taxonomyValues.filter(a => a.value === listKey)[0];
      }

      const response = yield ancillaryApi.getAncillaryData(listPayload, isListCall);
      const {
        data: { data = [] },
      } = response;
      if (response.status) {
        yield put({
          type: ancillaryActions.setAncillaryListData.type,
          payload: { data, taxonomy: listKey },
        });
      }
    }
  } catch (err) {
    yield put({ type: ancillaryActions.errorOnFetchingAncillaryData.type, payload: err });
  }
}

export function* getLocationDataAsync({ payload }) {
  try {
    yield put({ type: globalActions.showSpinner.type });
    const response = yield ancillaryApi.getLocationData();
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(ancillaryActions.getLocationDataSuccess({ data, selected: payload }));
    }
    yield put({ type: globalActions.hideSpinner.type });
  } catch (err) {
    yield put({ type: globalActions.hideSpinner.type });
  }
}

export function* postLocationDataAsync({ payload }) {
  try {
    yield put({ type: globalActions.showSpinner.type });
    const response = yield ancillaryApi.postLocationData(payload);
    const {
      data: { data },
    } = response;
    let selectedIds = null;

    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        let errorMessage = getApiErrorMessage(respData.messages);
        if (
          respData.messages.length > 0 &&
          respData.messages[0]?.errorMessage
            ?.trim()
            .toLowerCase()
            .includes('data already exists')
        ) {
          const formattedMessage = respData.messages.map(o =>
            o.errorMessage
              ?.trim()
              ?.toLowerCase()
              ?.replace(/data already exists/g, '')
              ?.trim(),
          );
          errorMessage = `${formattedMessage.map(e => upperFirst(e))} Already Exists`;
        }
        modalData = {
          isOpen: true,
          message: errorMessage,
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        const addedMessage = respData.data
          ? respData.data.map(o => (o.description ? o.description : o.name))
          : '';

        modalData = {
          isOpen: true,
          message: `${addedMessage.map(e => upperFirst(e))} Added Successfully To The Master List`,
          modalType: MODAL_TYPES.SUCCESS,
        };
        selectedIds = getLocationSelectedIds(payload.locationType, respData);
      }
      yield put({ type: globalActions.showModal.type, payload: modalData });
      if (payload.locationType === 'region') {
        yield put(ancillaryActions.getLocationData(selectedIds));
        yield put(ancillaryActions.resetLocationData());
      } else {
        yield put(ancillaryActions.getLocationData(selectedIds));
      }
    }
    yield put({ type: globalActions.hideSpinner.type });
  } catch (err) {
    yield put({ type: globalActions.hideSpinner.type });
  }
}

export function* putLocationDataAsync({ payload }) {
  try {
    yield put({ type: globalActions.showSpinner.type });
    const response = yield ancillaryApi.putLocationData(payload);
    let selectedUpdatedIds = null;
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        modalData = {
          isOpen: true,
          message: getApiErrorMessage(respData.messages),
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: `${getModalMessage(payload.locationType)} Updated Successfully`,
          modalType: MODAL_TYPES.SUCCESS,
        };
        selectedUpdatedIds = getUpdatedIds(payload.locationType, respData);
      }
      yield put({ type: globalActions.showModal.type, payload: modalData });
      yield put(ancillaryActions.getLocationData(selectedUpdatedIds));
    }
    yield put({ type: globalActions.hideSpinner.type });
  } catch (err) {
    yield put({ type: globalActions.hideSpinner.type });
  }
}

export function* deleteLocationDataAsync({ payload }) {
  try {
    const response = yield ancillaryApi.deleteLocationData(payload);
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        modalData = {
          isOpen: true,
          message: `This ${getModalMessage(payload.locationType)} is Already Tagged`,
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: `${getModalMessage(
            payload.locationType,
          )}  Deleted Successfully From The Master List`,
          modalType: MODAL_TYPES.SUCCESS,
        };
      }
      yield put({ type: globalActions.showModal.type, payload: modalData });
      if (payload.locationType === 'region') {
        yield put(ancillaryActions.getLocationData());
        yield put(ancillaryActions.resetLocationData());
      } else {
        yield put(ancillaryActions.getLocationData());
      }
    }
  } catch (err) {
    yield put({ type: ancillaryActions.errorOnFetchingAncillaryData.type });
  }
}

export function* getAncillaryCompanyByIdAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield ancillaryApi.getAncillaryCompany(payload);
    const {
      data: { data, state },
    } = response;
    if (state) {
      yield put(ancillaryRequestActions.setActiveCompany(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getAncillaryRecordByIdAsync({ payload }) {
  const { type, id } = payload;
  try {
    yield put(globalActions.showSpinner());
    const response = yield ancillaryApi.getAncillaryRecordById(type, id);
    const {
      data: { data, state },
    } = response;
    if (state) {
      yield put(ancillaryActions.getAncillaryRecordByIdSuccess(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

// watchers
export const watchers = [
  function* watchGetAncillaryDataAsync() {
    yield takeEvery(ancillaryActions.getAncillaryData, getAncillaryDataAsync);
  },
  function* watchAddAncillaryDataAsync() {
    yield takeEvery(ancillaryActions.addAncillaryData, addAncillaryDataAsync);
  },
  function* watchAddSponsorCollaboratorAncillaryDataAsync() {
    yield takeEvery(
      ancillaryActions.addSponsorCollaboratorAncillaryData,
      addSponsorCollaboratorAncillaryDataAsync,
    );
  },
  function* watchUpdateAncillaryDataAsync() {
    yield takeEvery(ancillaryActions.updateAncillaryData, updateAncillaryDataAsync);
  },
  function* watchUpdateAncillaryApprovalDataAsync() {
    yield takeEvery(ancillaryActions.updateAncillaryApprovalData, updateAncillaryApprovalDataAsync);
  },
  function* watchDeleteAncillaryDataAsync() {
    yield takeEvery(ancillaryActions.deleteAncillaryData, deleteAncillaryDataAsync);
  },
  function* watchgetListDataAsync() {
    yield takeEvery(ancillaryActions.getListData, getListDataAsync);
  },
  function* watchGetLocationDataAsync() {
    yield takeEvery(ancillaryActions.getLocationData, getLocationDataAsync);
  },
  function* watchPostLocationDataAsync() {
    yield takeEvery(ancillaryActions.postLocationData, postLocationDataAsync);
  },
  function* watchPutLocationDataAsync() {
    yield takeEvery(ancillaryActions.putLocationData, putLocationDataAsync);
  },
  function* watchDeleteLocationDataAsync() {
    yield takeEvery(ancillaryActions.deleteLocationData, deleteLocationDataAsync);
  },
  function* watchGetAncillaryCompanyById() {
    yield takeEvery(ancillaryActions.getAncillaryCompanyById, getAncillaryCompanyByIdAsync);
  },
  function* watchGetAncillaryRecordById() {
    yield takeEvery(ancillaryActions.getAncillaryRecordById, getAncillaryRecordByIdAsync);
  },
];
