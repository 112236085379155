import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import './index.scss';

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 500,
  },
}));

const InputToolTip = ({ children, showToolTip }) => {
  const [value, setValue] = useState('');
  const classes = useStyles();

  const updateOverFlow = event => {
    const {
      target: { scrollWidth, clientWidth, value: targeVal, textContent },
    } = event;
    setValue(scrollWidth > clientWidth ? textContent || targeVal : '');
  };

  return (
    <>
      {showToolTip ? (
        <Tooltip title={value} classes={{ tooltip: classes.customWidth }}>
          <div className="tooltip-child" onMouseEnter={updateOverFlow}>
            {children}
          </div>
        </Tooltip>
      ) : (
        { children }
      )}
    </>
  );
};

InputToolTip.propTypes = {
  showToolTip: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

InputToolTip.defaultProps = {
  showToolTip: true,
};

export default InputToolTip;
