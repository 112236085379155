import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { faPen, faTimes, faEye, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { REGINTEL_ENTITIES } from '../../utils/generic/constants';

import withClass from '../withClass';
import './index.scss';

const TableActions = ({
  handleView,
  handleDelete,
  handleEdit,
  handlePublish,
  canDisableEdit,
  canDisableDelete,
  isPublish,
  isView,
  fromTreeGrid,
  ...params
}) => {
  const currentBussinessGroup = localStorage.getItem('usergroup');
  const { recordEntity } = params.data || { recordEntity: '' };
  const isShowDelete = params?.data?.productionStatusId !== 2 ? true : false;
  const { selectedFeature } = useSelector(state => state.globalState, shallowEqual);
  const { featurePermissions } = useSelector(state => state.auth, shallowEqual);
  const { selected } = useSelector(state => state.administrator, shallowEqual);
  const enablePublish = selectedFeature === 'dashboard';
  const enableView = REGINTEL_ENTITIES.includes(selectedFeature) && selectedFeature !== 'dashboard';
  let enableDelete = false;
  if (currentBussinessGroup === 'RegIntelUser') {
    enableDelete = selectedFeature === 'administrator' && selected === 'Ageing' ? false : true;
    if (
      ['requirement', 'registry', 'country', 'sourceDocument'].includes(selectedFeature) &&
      !isShowDelete
    ) {
      enableDelete = false;
    }
    //selectedFeature === 'dashboard' || (selectedFeature === 'requirement' && isShowDelete); //enableView ? false : handleDelete;
  } else {
    enableDelete = enableView ? false : handleDelete;
  }
  if (REGINTEL_ENTITIES.includes(selectedFeature) && selectedFeature !== 'dashboard') {
    canDisableEdit = featurePermissions[selectedFeature]?.disableEdit;
  }
  if (REGINTEL_ENTITIES.includes(selectedFeature) && selectedFeature == 'dashboard') {
    // console.log('dashboard', { handleDelete, handleEdit, canDisableEdit, canDisableDelete });
  }
  function isDelete() {
    if (selectedFeature !== 'dashboard') {
      return canDisableDelete;
    }
    if (
      recordEntity !== 'Country' &&
      recordEntity !== 'Registry' &&
      recordEntity !== 'Requirement' &&
      recordEntity !== 'Sourcedocument' &&
      selectedFeature === 'dashboard'
    ) {
      return true;
    }
    return canDisableDelete;
  }
  return (
    <>
      {handleEdit && (
        <Tooltip title="Edit Record">
          <span>
            <button disabled={canDisableEdit} type="button" onClick={handleEdit}>
              <FontAwesomeIcon icon={faPen} />
            </button>
          </span>
        </Tooltip>
      )}
      {enablePublish && isPublish && (
        <Tooltip title="Publish Record">
          <span>
            <button disabled={canDisableEdit} type="button" onClick={handlePublish}>
              <FontAwesomeIcon icon={faUpload} />
            </button>
          </span>
        </Tooltip>
      )}
      {enableView && isView && (
        <Tooltip title="View Record">
          <span>
            {/* <button disabled={canDisableDelete} type="button" onClick={handleDelete}>
              <FontAwesomeIcon icon={faEye} />
            </button> */}
            <button disabled={canDisableDelete} type="button" onClick={handleView}>
              <FontAwesomeIcon icon={faEye} />
            </button>
          </span>
        </Tooltip>
      )}

      {fromTreeGrid
        ? enableDelete &&
          handleDelete && (
            <Tooltip title="Delete Record">
              <span>
                <button disabled={isDelete()} type="button" onClick={handleDelete}>
                  <FontAwesomeIcon icon={faTimes} color="#EF426F" />
                </button>
              </span>
            </Tooltip>
          )
        : enableDelete && (
            <Tooltip title="Delete Record">
              <span>
                <button disabled={isDelete()} type="button" onClick={handleDelete}>
                  <FontAwesomeIcon icon={faTimes} color="#EF426F" />
                </button>
              </span>
            </Tooltip>
          )}
    </>
  );
};

TableActions.propTypes = {
  handleView: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handlePublish: PropTypes.func,
  canDisableDelete: PropTypes.bool,
  canDisableEdit: PropTypes.bool,
};

TableActions.defaultProps = {
  handleView: null,
  handleDelete: null,
  handleEdit: null,
  handlePublish: null,
  canDisableDelete: false,
  canDisableEdit: false,
};

export default React.memo(withClass(TableActions, 'action-wrapper'));
