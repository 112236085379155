import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { DNADataGrid, ListView, Link, DefaultCellRender } from '../../../../../../framework';
import { getCurrentAssociatedPeople } from '../../../../../../store/actions/organization';
import PaginationWrapper from '../../../../../../generic/features/paginationWrapper';

const CurrentAssociatedPeople = () => {
  const {
    current: { id: organizationId },
    organizationAssociation: {
      currentAssociatedPeople: {
        currentPeople,
        pagination,
        pagination: { pageSize, pageIndex },
      },
    },
  } = useSelector(state => state.organization, shallowEqual);

  const history = useHistory();
  const dispatch = useDispatch();

  const [pageDetails, setPageDetails] = useState({ pageIndex: 1, pageSize: 50 });
  const currPage = pageDetails.pageIndex;
  const currpageSize = pageDetails.pageSize;
  const currentPageRef = useRef({ pageIndex: currPage, pageSize: currpageSize });
  const [sortedColumnName, setSortedColumnName] = useState('');
  const [sortingOrder, setSortingOrder] = useState(false);
  const prevPageState = useRef({ prevSortingColumn: '', prevSortingOrder: false });

  useEffect(() => {
    if (currentPeople.length === 0) {
      dispatch(
        getCurrentAssociatedPeople({
          pageSize,
          pageIndex,
          entityPrimaryKey: organizationId,
          sortOrder: [{ columnName: 'All', isDescendingOrder: false }],
        }),
      );
    }
  }, []);

  useEffect(() => {
    currentPageRef.current = { pageIndex: currPage, pageSize: currpageSize };
  }, [currPage, currpageSize]);

  const navigateToTrial = id => {
    window.open(`/clinicalTrials/${id}`);
  };

  let prevSortingOrder = false;
  let prevSortingColumn = '';
  const handleTableHeaderClick = (columnName, isDescendingOrder) => {
    setSortedColumnName(columnName);
    setSortingOrder(isDescendingOrder);
    // if condition added to avoid dispatching the action multiple times due to comparator function
    if (prevSortingOrder !== isDescendingOrder || prevSortingColumn !== columnName) {
      prevSortingColumn = columnName;
      prevSortingOrder = isDescendingOrder;
      dispatch(
        getCurrentAssociatedPeople({
          pageIndex: currentPageRef.current.pageIndex,
          pageSize: currentPageRef.current.pageSize,
          entityPrimaryKey: organizationId,
          sortOrder: [
            {
              columnName: columnName,
              isDescendingOrder: isDescendingOrder,
            },
          ],
        }),
      );
    }
  };

  const handleSortChanged = args => {
    const { api } = args;
    const sortState = api.getSortModel();
    if (sortState.length === 0) {
      dispatch(
        getCurrentAssociatedPeople({
          pageIndex: currentPageRef.current.pageIndex,
          pageSize: currentPageRef.current.pageSize,
          entityPrimaryKey: organizationId,
          sortOrder: [
            {
              columnName: 'All',
              isDescendingOrder: false,
            },
          ],
        }),
      );
    }
  };

  const columnDefs = ({ handleTableHeaderClick }) => [
    {
      headerName: 'CTID',
      field: 'trialId',
      width: 100,
      sortable: true,
      cellRenderer: 'Link',
      cellRendererParams: ({ data: { trialId } }) => {
        if (trialId) {
          return {
            link: trialId ? trialId.toString() : '',
            onClick: () => {
              navigateToTrial(trialId);
            },
          };
        }
      },
      comparator: (...args) => {
        let isDescendingOrder = args[4];
        handleTableHeaderClick('ctid', isDescendingOrder);
      },
    },
    {
      headerName: 'ROLE',
      field: 'personType',
      width: 100,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4];
        handleTableHeaderClick('role', isDescendingOrder);
      },
    },
    {
      headerName: 'NAME',
      field: 'personName',
      width: 100,
      sortable: true,
      cellRenderer: 'DefaultCellRender',
      comparator: (...args) => {
        let isDescendingOrder = args[4];
        handleTableHeaderClick('name', isDescendingOrder);
      },
    },
    {
      headerName: 'DISEASE TYPE',
      headerClass: 'diseaseTypesCurrAssociatedPeopleOrg',
      field: 'diseaseTypes',
      width: 100,
      sortable: true,
      cellRenderer: 'ListView',
      cellRendererParams: ({ data }) => {
        return {
          list: data.diseaseTypes,
        };
      },
    },
    {
      headerName: 'SPECIALITY',
      headerClass: 'specialitiesCurrAssociatedPeopleOrg',
      field: 'specialities',
      width: 100,
      sortable: true,
      cellRenderer: 'ListView',
      cellRendererParams: ({ data }) => {
        return {
          list: data.specialities,
        };
      },
    },
  ];

  return (
    <PaginationWrapper
      pageInfo={pagination}
      onChange={pageDetails => {
        setPageDetails(pageDetails);
        dispatch(
          getCurrentAssociatedPeople({
            ...pageDetails,
            entityPrimaryKey: organizationId,
            sortOrder: [
              {
                columnName: sortedColumnName,
                isDescendingOrder: sortingOrder,
              },
            ],
          }),
        );
      }}
    >
      <DNADataGrid
        handleSortChanged={handleSortChanged}
        // columnDefs={[...columnDefs]}
        columnDefs={columnDefs({ handleTableHeaderClick })}
        rowData={JSON.parse(JSON.stringify(currentPeople))}
        frameworkComponents={{ ListView, Link, DefaultCellRender }}
        rowHeight={60}
      />
    </PaginationWrapper>
  );
};

export default CurrentAssociatedPeople;
