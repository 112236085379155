import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

import { InputTextField, InputButton } from '../inputs';
import { convertValueToThousands } from '../../utils/generic/helper';
import './index.scss';

export const Pagination = ({
  pageSize,
  totalRows,
  onChange,
  pageNo,
  showPaginationText,
  showBottomPaginationText,
  restrictPagination,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [userInput, setUserInput] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const totalPage = Math.ceil(totalRows / pageSize);
    setTotalPages(totalPage);
    setCurrentPage(1);
    setStartPage(1);
    setUserInput(1);
  }, [pageSize]);

  useEffect(() => {
    const totalPage = Math.ceil(totalRows / pageSize);
    setTotalPages(totalPage);
  }, [totalRows]);

  useEffect(() => {
    setCurrentPage(pageNo);
    if (pageNo > 5) {
      setStartPage(pageNo - 4);
    } else {
      setStartPage(1);
    }
  }, [pageNo]);

  useEffect(() => {
    setUserInput(currentPage);
  }, [currentPage]);

  const goToFirstPage = () => {
    setCurrentPage(1);
    updateStartPage(1);
    setErrorMessage('');
  };

  const onPreviousPage = () => {
    const previousPage = currentPage - 1;
    setCurrentPage(previousPage);
    updateStartPage(previousPage);
    if (previousPage > totalPages) {
      setErrorMessage('Page Number does not exist.');
    } else {
      setErrorMessage('');
    }
  };

  const onPreviousSection = () => {
    const pageNum = currentPage - 10;
    setCurrentPage(pageNum);
    updateStartPage(pageNum);
    if (pageNum > totalPages) {
      setErrorMessage('Page Number does not exist.');
    } else {
      setErrorMessage('');
    }
  };

  const onPageSelection = pageNum => {
    setCurrentPage(pageNum);
    updateStartPage(pageNum);
    setErrorMessage('');
  };

  const onNextPage = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    updateStartPage(nextPage);
  };

  const onNextSection = () => {
    const pageNum = currentPage + 10;
    setCurrentPage(pageNum);
    updateStartPage(pageNum);
  };

  const updateStartPage = pageNum => {
    if (pageNum > 5) {
      setStartPage(pageNum - 4);
    } else {
      setStartPage(1);
    }
    if (restrictPagination) {
      setCurrentPage(pageNo);
      if (pageNo > 5) {
        setStartPage(pageNo - 4);
      } else {
        setStartPage(1);
      }
    }
    onChange(pageNum);
  };

  const goBtnClick = () => {
    let pageNum = userInput;
    if (userInput == 0) {
      pageNum = 1;
    } else if (userInput > totalPages) {
      setErrorMessage('Page Number does not exist.');
    } else {
      setErrorMessage('');
      onPageSelection(parseInt(pageNum, 10));
    }
  };

  const getPaginationCount = () => {
    let startNum = 1;
    let endNum = pageSize;
    if (currentPage > 1) {
      // eslint-disable-next-line no-mixed-operators
      startNum = pageSize * (currentPage - 1) + 1;
      endNum = pageSize * currentPage;
    }
    endNum = endNum > totalRows ? totalRows : endNum;
    const paginationText = `Showing ${convertValueToThousands(
      startNum,
    )} - ${convertValueToThousands(endNum)} out of ${convertValueToThousands(totalRows)}`;
    if (showBottomPaginationText) {
      return <p className="pagination-text">{paginationText}</p>;
    } else {
      showPaginationText(paginationText);
    }
  };

  const currentPages = [];
  for (let index = startPage; index < startPage + 10; index += 1) {
    if (index <= totalPages) {
      currentPages.push(index);
    }
  }

  return (
    <Grid item xs={12}>
      <div className="pagination-outer">
        <div className="pages-section">
          {currentPage > 1 && (
            <a onClick={() => goToFirstPage()} className="pagination-color">
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </a>
          )}
          {currentPage > 10 && <a onClick={() => onPreviousSection()}>- 10</a>}
          {currentPage > 1 && (
            <a onClick={() => onPreviousPage()} className="pagination-color">
              <FontAwesomeIcon icon={faAngleLeft} />
            </a>
          )}
          {currentPages.map((page, index) => {
            const activeClass =
              page === currentPage ? 'pages-section-active' : 'pages-section-inactive';
            return (
              <a key={index} className={activeClass} onClick={() => onPageSelection(page)}>
                {page}
              </a>
            );
          })}
          {currentPage !== totalPages && (
            <a onClick={() => onNextPage()} className="pagination-color">
              <FontAwesomeIcon icon={faAngleRight} />
            </a>
          )}
          {currentPage + 10 <= totalPages && (
            <a className="pagination-color" onClick={() => onNextSection()}>
              + 10
            </a>
          )}
        </div>
        <Grid item xs={12}>
          <div className="userPageNo">
            <InputTextField
              value={userInput}
              inputHeight="ht-lg"
              onChange={event => {
                const inputValue = /^[0-9]*$/.test(event.target.value)
                  ? event.target.value
                  : userInput;
                setUserInput(inputValue);
              }}
              onKeyPress={event => {
                if (event.key === 'Enter' && userInput != currentPage) {
                  goBtnClick();
                } else {
                  setErrorMessage('');
                }
              }}
            />
            <InputButton
              buttonType="secondary-btn"
              buttonSize="md"
              text="Go"
              onClick={() => (userInput === currentPage ? setErrorMessage('') : goBtnClick())}
            />
            {errorMessage && <p className="error-text">{errorMessage}</p>}
          </div>
        </Grid>
        {getPaginationCount()}
      </div>
    </Grid>
  );
};

Pagination.propTypes = {
  totalRows: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageNo: PropTypes.number,
  showPaginationText: PropTypes.func,
  showBottomPaginationText: PropTypes.bool,
};

Pagination.defaultProps = {
  pageNo: 1,
  showBottomPaginationText: true,
};

export default Pagination;
