/* eslint-disable camelcase */
import { omit } from 'lodash';

import {
  formIngestedData,
  updateCurrentDataBasedOnIngestion,
  checkUserIngestionAction,
  checkIfStringIsEmpty,
} from '../../utils/generic/helper';
import drugFeaturesInitialState from '../../utils/drugs/featuresInitialState';
import drugTabs from '../../utils/drugs/tabs';

export const setCurrentAndIngestedData = (currentData, ingestedData, tabs = drugTabs) => {
  let mappedIngestedData = {
    ...omit(ingestedData, [
      'forceToFieldUpdate',
      'id',
      'isReviewed',
      'ingestedId',
      'key',
      'productionStatusId',
      'drugType',
      'masterDrug',
    ]),
  };

  // remove unwanted data from drug summary
  mappedIngestedData = {
    ...mappedIngestedData,
    summary: mappedIngestedData.summary
      ? omit(mappedIngestedData.summary, [
          'createdBy',
          'createdDate',
          'devStatus',
          'drugSummary',
          'latestChangeDate',
          'latestChangeModifiedBy',
          'latestChangeDatePublishOnFos',
          'modifiedBy',
          'modifiedDate',
          'nextReviewDate',
        ])
      : null,
  };

  // remove unwanted data from drug properities
  mappedIngestedData = {
    ...mappedIngestedData,
    licensingAvailability: mappedIngestedData.licensingAvailability
      ? omit(mappedIngestedData.licensingAvailability, ['id'])
      : null,
    chemStructure: mappedIngestedData.chemStructure
      ? omit(mappedIngestedData.chemStructure, ['id', 'nbe', 'nce', 'molecularData'])
      : null,
    marketing: mappedIngestedData.marketing ? omit(mappedIngestedData.marketing, ['id']) : null,
    phases: mappedIngestedData.phases ? omit(mappedIngestedData.phases, ['id']) : null,
  };

  const updatedIngestedData = formIngestedData(mappedIngestedData);

  const updatedCurrentData = {
    ...updateCurrentDataBasedOnIngestion(currentData, mappedIngestedData),
    ingestedId: ingestedData.ingestedId,
    ingestedProductionStatusId: ingestedData.productionStatusId,
  };

  const ingestedKeys = Object.keys(updatedIngestedData).filter(key =>
    checkUserIngestionAction(updatedCurrentData[key], updatedIngestedData[key].fieldActions, false),
  );

  let updatedTabs = tabs.map(tab => ({
    ...tab,
    hasIngestedData: ingestedKeys.includes(tab.key),
  }));

  updatedTabs = updatedTabs.map(tab => {
    if (tab.isTab && tab.hasIngestedData) {
      if (tab.key === 'chemStructure') {
        return {
          ...tab,
          tabs: tab.tabs.map(child => {
            if (child.key === 'descriptiveData') {
              return {
                ...child,
                hasIngestedData: checkUserIngestionAction(
                  omit(updatedCurrentData[tab.key], [
                    'id',
                    'nbe',
                    'nce',
                    'molecularData',
                    'patents',
                  ]),
                  updatedIngestedData[tab.key].fieldActions,
                  false,
                ),
              };
            } else if (child.key === 'patents') {
              return {
                ...child,
                hasIngestedData: checkUserIngestionAction(
                  updatedCurrentData[tab.key][child.key],
                  {},
                  false,
                ),
              };
            }
            return child;
          }),
        };
      }
      return {
        ...tab,
        tabs: tab.tabs.map(child => {
          return {
            ...child,
            hasIngestedData: checkUserIngestionAction(
              Array.isArray(updatedCurrentData[tab.key][child.key])
                ? updatedCurrentData[tab.key][child.key]
                : { [child.key]: updatedCurrentData[tab.key][child.key] },
              { [child.key]: updatedIngestedData[tab.key].fieldActions[child.key] },
              false,
            ),
          };
        }),
      };
    }
    return tab;
  });

  return {
    updatedIngestedData,
    updatedCurrentData,
    updatedTabs,
  };
};

export const getNextIngestedTab = (tabs, selected) => {
  const activeTabIndex = tabs.findIndex(tab => tab.value === selected);
  const ingestedTab = tabs.find((tab, index) => tab.hasIngestedData && index > activeTabIndex);
  return {
    ingestedTab,
    activeTabIndex,
  };
};

export const getChemicalMolecularParameters = (currentData, parameters) => {
  if (parameters) {
    const {
      cd_formula,
      cd_molweight,
      logP,
      intDonorCount,
      intAcceptorCount,
      intRotatableBondCount,
      smilesFormula,
      chemicalName,
      cd_id,
    } = parameters;
    return {
      ...currentData,
      chemStructure: {
        ...currentData.chemStructure,
        molecularData: {
          ...currentData.chemStructure.molecularData,
          formula: checkIfStringIsEmpty(cd_formula) ? '' : `${cd_formula}`,
          weight: checkIfStringIsEmpty(cd_molweight) ? '' : `${cd_molweight}`,
          logp: checkIfStringIsEmpty(logP) ? '' : `${logP}`,
          hBondDonors: checkIfStringIsEmpty(intDonorCount) ? '' : `${intDonorCount}`,
          hBondAcceptors: checkIfStringIsEmpty(intAcceptorCount) ? '' : `${intAcceptorCount}`,
          rotatableBonds: checkIfStringIsEmpty(intRotatableBondCount)
            ? ''
            : `${intRotatableBondCount}`,
          smilesFormula,
          chemicalName,
          cdid: cd_id,
        },
      },
    };
  }
  return currentData;
};

const formatMolecularData = data => {
  const {
    cdid = null,
    chemicalName = '',
    formula = '',
    hBondAcceptors = '',
    hBondDonors = '',
    imageUri = '',
    logp = '',
    rotatableBonds = '',
    smilesFormula = '',
    weight = '',
  } = data;
  return {
    ...data,
    cdid,
    chemicalName: chemicalName || '',
    formula: formula || '',
    hBondAcceptors: hBondAcceptors || '',
    hBondDonors: hBondDonors || '',
    imageUri: imageUri || '',
    logp: logp || '',
    rotatableBonds: rotatableBonds || '',
    smilesFormula: smilesFormula || '',
    weight: weight || '',
  };
};

// eslint-disable-next-line complexity
export const formatDrugDataToStore = (data = drugFeaturesInitialState) => {
  const {
    phases,
    phases: {
      id: phaseId = 0,
      inVitro = '',
      inVivo = '',
      patents = '',
      phaseI = '',
      phaseII = '',
      phaseIII = '',
    },
    marketing,
    marketing: {
      approvals = '',
      expeditedReview = '',
      fillings = '',
      id: marketingId = 0,
      orphanDrugStatus = '',
      warnings = '',
    },
    licensingAvailability,
    licensingAvailability: {
      agreement = '',
      availability = '',
      id: licensingId = 0,
      licenses = '',
    },
    validations,
    chemStructure,
    chemStructure: {
      nbe = false,
      nce = false,
      molecularData = null,
      id: chemStructureId = 0,
      casRegistrys = [],
      pubchemCIDs = [],
      drugDrugTypeClassifications = [],
      patents: chemStructurePatents = [],
    },
  } = data;

  return {
    ...data,
    phases: {
      ...phases,
      id: phaseId || 0,
      inVitro: inVitro || '',
      inVivo: inVivo || '',
      patents: patents || '',
      phaseI: phaseI || '',
      phaseII: phaseII || '',
      phaseIII: phaseIII || '',
    },
    marketing: {
      ...marketing,
      approvals: approvals || '',
      expeditedReview: expeditedReview || '',
      fillings: fillings || '',
      id: marketingId || 0,
      orphanDrugStatus: orphanDrugStatus || '',
      warnings: warnings || '',
    },
    licensingAvailability: {
      ...licensingAvailability,
      agreement: agreement || '',
      availability: availability || '',
      id: licensingId || 0,
      licenses: licenses || [],
    },
    validations,
    chemStructure: {
      ...chemStructure,
      nce: Boolean(nce),
      nbe: Boolean(nbe),
      molecularData: formatMolecularData(
        molecularData || drugFeaturesInitialState.chemStructure.molecularData,
      ),
      casRegistrys: casRegistrys || [],
      drugDrugTypeClassifications: drugDrugTypeClassifications || [],
      id: chemStructureId || 0,
      patents: chemStructurePatents || [],
      pubchemCIDs: pubchemCIDs || [],
    },
  };
};

export const updateDrugStampingDetails = (
  { lastFullReviewDate, lastFullReviewBy, nextReviewDate },
  current = drugFeaturesInitialState,
  original = drugFeaturesInitialState,
) => {
  const updateStampDatesForObject = (object = drugFeaturesInitialState) => ({
    ...object,
    summary: {
      ...object.summary,
      lastFullReviewDate,
      lastFullReviewBy,
      nextReviewDate,
    },
  });
  return {
    current: updateStampDatesForObject(current),
    original: updateStampDatesForObject(original),
  };
};

export const getDrugStampDates = ({ lastFullReviewDate, lastFullReviewBy, nextReviewDate }) => {
  return {
    lastFullReviewDate,
    lastFullReviewBy,
    nextReviewDate,
  };
};
