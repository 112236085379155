/* eslint-disable multiline-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';

import Typography from '../../typography';
import { InputRadioOptions, InputButton } from '../../inputs';
import CustomIcon from '../../customIcon';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { ConfirmationMessage } from '../../../generic/modals';
import { REGINTEL_ENTITIES, statusColorRules } from '../../../utils/generic/constants';

const NavbarFooter = props => {
  const {
    setSinglePagePreviewState,
    psData: { psStatus, disabled },
    onPsStatusSubmit,
    onOutofscopeSubmit,
    disablePreview,
    isIngestedRecord,
    paginationState,
    isStamped,
    recordData,
    onStamp,
    showStampIcon,
  } = props;
  const [showSingle, setShowSingle] = useState(false);
  const [selectedPsStatus, setSelectedPsStatus] = useState(psStatus);
  const [confirmMessage, setConfirmMessage] = useState(null);
  if (selectedPsStatus === 6) {
    setSelectedPsStatus(isIngestedRecord ? 5 : 0);
  }
  const history = useHistory();
  useEffect(() => {
    setSelectedPsStatus(psStatus);
  }, [psStatus]);

  const { selectedFeature } = useSelector(state => state.globalState, shallowEqual);

  const disableOutofScope = selectedPsStatus !== 2;
  const disableRadio = REGINTEL_ENTITIES.includes(selectedFeature) ? true : disabled;
  const isRegIntel = REGINTEL_ENTITIES.includes(selectedFeature);
  const disableValidate = isRegIntel ? selectedPsStatus !== 0 || disabled : disabled;
  const onBack = () => {
    history.push({
      pathname: `/${paginationState.entity}-queues`,
      state: { pageIndex: paginationState.pageIndex },
    });
  };

  const proceedToStamp = confirmation => {
    setConfirmMessage(null);
    if (confirmation) {
      onStamp(!isStamped, selectedPsStatus);
    }
  };

  return (
    <div className="navbar-footer">
      <div className="footer-back-btn">
        <InputButton
          variant="contained"
          buttonType="back-btn"
          buttonSize="lg"
          isDisabled="true"
          text="BACK"
          iconColor="black"
          onClick={() => onBack()}
        />
      </div>
      <div className="footer-options-box">
        <Typography
          text="Production Status"
          color="white"
          size="caption-large"
          textType="uppercase"
        />
        <div className="footer-options">
          <InputRadioOptions
            data={
              isRegIntel
                ? statusColorRules
                : [
                    { id: 3, display: 'Pending Review', iconColor: 'yellow' },
                    { id: 2, display: 'Out of scope', iconColor: 'black' },
                    { id: 0, display: 'Out of production', iconColor: 'red' },
                    { id: 4, display: 'Data check', iconColor: 'orange' },
                    { id: 1, display: 'In Production', iconColor: 'green' },
                    { id: 5, display: 'External Source', iconColor: 'blue' },
                  ]
            }
            disableRules={
              recordData.entity === 'TRAILS'
                ? [
                    { values: [] },
                    { values: [] },
                    { values: [] },
                    { values: ['Pending Review'] },
                    { values: ['Pending Review'] },
                    { values: ['Pending Review'] },
                  ]
                : [
                    { values: [] },
                    { values: [] },
                    { values: [] },
                    { values: ['Pending Review'] },
                    { values: ['Pending Review'] },
                    { values: [] },
                  ]
            }
            value={selectedPsStatus}
            color="gray"
            handleChange={data => setSelectedPsStatus(data)}
            originalValue={psStatus}
            disabled={disableRadio}
          />
        </div>
        <div className="submit-btn">
          <InputButton
            isDisabled={disableValidate}
            variant="contained"
            buttonType="validate-btn"
            buttonSize="lg"
            text="VALIDATE"
            onClick={() => onPsStatusSubmit(selectedPsStatus)}
          />
        </div>
        {isRegIntel && (
          <div className="submit-btn">
            <InputButton
              isDisabled={disableOutofScope}
              variant="contained"
              buttonType="primary-btn"
              buttonSize="lg"
              text="OUT OF SCOPE"
              onClick={() => onOutofscopeSubmit()}
            />
          </div>
        )}

        {
          //!isRegIntel &&
          <>
            {showStampIcon && (
              <div
                className={`stamp-btn ${disabled ? 'stamp-btn-disabled' : ''}`}
                role="button"
                onClick={() => {
                  setConfirmMessage(true);
                }}
              >
                <Tooltip title={isStamped ? 'Stamped' : 'Stamp'}>
                  <div>
                    <CustomIcon styleName="stamp"></CustomIcon>
                  </div>
                </Tooltip>
              </div>
            )}
            {confirmMessage && (
              <ConfirmationMessage
                isOpen
                onClose={proceedToStamp}
                message="Last Full Review Date will updated to current date. Are you sure you want to continue?"
              />
            )}
          </>
        }
      </div>
      {!isRegIntel && (
        <div
          className={`preview-section ${disablePreview && 'preview-disabled'}`}
          tabIndex="0"
          role="button"
          onClick={() => {
            setSinglePagePreviewState(!showSingle);
            setShowSingle(!showSingle);
          }}
        >
          <div className="preview-icon-container">
            <FontAwesomeIcon icon={faFileAlt} className="preview-icon" size="lg" />
          </div>
          <Typography
            text={showSingle ? 'multi page preview' : 'single page preview'}
            color="white"
            size="caption-large"
            textType="capitalize"
          />
        </div>
      )}
    </div>
  );
};

NavbarFooter.propTypes = {
  psData: PropTypes.object.isRequired,
  onPsStatusSubmit: PropTypes.func.isRequired,
  onOutofscopeSubmit: PropTypes.func.isRequired,
  setSinglePagePreviewState: PropTypes.func,
  disablePreview: PropTypes.bool.isRequired,
  isIngestedRecord: PropTypes.bool,
  isStamped: PropTypes.bool,
  onStamp: PropTypes.func,
  showStampIcon: PropTypes.bool,
};

NavbarFooter.defaultProps = {
  psData: {
    psStatus: 0,
    disabled: true,
  },
  onPsStatusSubmit: () => {},
  onOutofscopeSubmit: () => {},
  isIngestedRecord: false,
  isStamped: false,
  onStamp: () => {},
  showStampIcon: true,
};

export default NavbarFooter;
