import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import { InputCustomFormatDatePicker } from '../inputs';
import { timeStamps } from '../../utils/drugs/constants';
import { disclosetimeStamps } from '../../utils/generic/constants';
import { isValidDate } from '../../utils/generic/helper';
import './index.scss';

const currentBussinessGroup = localStorage.getItem('usergroup');
const timestamp = currentBussinessGroup === 'RegIntelUser' ? disclosetimeStamps : timeStamps;
const TimeStamp = ({ data, handleDateChange, disabled }) => {
  const [nextFullReviewDateError, setNextFullReviewDateError] = useState(false);
  useEffect(() => {
    setNextFullReviewDateError(data && data.nextReviewDate && !isValidDate(data.nextReviewDate));
  }, [data]);
  const dateFormat = currentBussinessGroup === 'RegIntelUser' ? 'dd-MMM-yyyy' : 'MMM-dd-yyyy';
  const generateStamp = (stamp, partition, key) => {
    return (
      <div className="timestamp-item" key={key}>
        <div className="timestamp-item__record-date">
          <div className="timestamp-item__record-date__label">{stamp.dateLabel}</div>
          <div className="timestamp-item__record-date__value">
            {data[stamp.dateAccessor]
              ? format(new Date(data[stamp.dateAccessor]), dateFormat)
              : '-'}
          </div>
        </div>
        <div className="timestamp-item__userid">
          <div className="timestamp-item__userid__label">{stamp.userLabel}</div>
          <div className="timestamp-item__userid__value">{data[stamp.userAccessor] || '-'}</div>
        </div>
      </div>
    );
  };
  return (
    <div className="timestamp-wrapper">
      <div className="timestamp-wrapper__container">
        <div className="timestamp-wrapper__container__items">
          {timestamp.map((stamp, index) => {
            let partition = index < timeStamps.length - 1;
            return generateStamp(stamp, partition, `${index}${stamp.dateLabel}`);
          })}
        </div>
        <div className="time-stamp-next-review">
          <div className="time-stamp-next-review__date">
            <InputCustomFormatDatePicker
              name="nextFullReviewDate"
              label="NEXT FULL REVIEW DATE"
              disablePast={false}
              value={data.nextReviewDate}
              format={dateFormat} //"dd MMM yyyy"
              onChange={date => {
                handleDateChange(date);
              }}
              isError={nextFullReviewDateError}
              errorMessage={`Please enter a valid date ${dateFormat}`}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

TimeStamp.propTypes = {
  data: PropTypes.object.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TimeStamp.defaultProps = {
  disabled: false,
};

export default TimeStamp;
