/* eslint-disable complexity */
/* eslint-disable max-depth */
import { isAfter } from 'date-fns';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import { isValid } from 'date-fns';

export const timingConstants = {
  TIMING_KEY: 'trialTiming',
  HIGHLITER_MESSAGE: 'Values conflict with auto-calculation',
};

const convertDateToString = date => {
  return date ? date.toDateString() : date;
};

const dateRangeCheck = (secondDate, firstDate, futureCheck) => {
  if (futureCheck) {
    return isAfter(secondDate, firstDate);
  } else if (firstDate && secondDate && secondDate.toDateString() === firstDate.toDateString()) {
    return true;
  } else {
    return isAfter(secondDate, firstDate);
  }
};

export const convertToDuration = (startDate, endDate) => {
  const decimalManthValue = 30.42;
  let days = differenceInCalendarDays(endDate, startDate);
  const duration = days / decimalManthValue;
  return duration.toFixed(2);
};

export const convertToDate = (date, duration) => {
  const decimalManthValue = 30.42;
  const calculatedDuration = decimalManthValue * duration;
  let calculatedDate = null;
  if (calculatedDuration) {
    calculatedDate = addDays(date, parseInt(calculatedDuration.toFixed(), 10));
  }
  return calculatedDate;
};
export const convertToDateBackward = (date, duration) => {
  const decimalManthValue = 30.42;
  const calculatedDuration = decimalManthValue * duration;
  let calculatedDate = null;
  if (calculatedDuration) {
    calculatedDate = subDays(date, parseInt(calculatedDuration.toFixed(), 10));
  }
  return calculatedDate;
};

export const convertDataForAPI = formValues => {
  let parsedFormValues = {
    reportedSites: parseInt(formValues.reportedSites, 10),
    identifiedSites: formValues.identifiedSites,
    startDateActual: convertDateToString(formValues.actualStartDate),
    startDateTarget: convertDateToString(formValues.anticipatedStartDate),
    enrollmentCloseDateActual: convertDateToString(formValues.actualEnrollmentCloseDate),
    enrollmentCloseDateTarget: convertDateToString(formValues.anticipatedEnrollmentCloseDate),
    primaryCompletionDateActual: convertDateToString(formValues.actualPrimaryCompletionDate),
    primaryCompletionDateTarget: convertDateToString(formValues.anticipatedPrimaryCompletionDate),
    primaryEndPointsReportedActual: convertDateToString(formValues.actualPrimaryEndpointReported),
    primaryEndPointsReportedTarget: convertDateToString(
      formValues.anticipatedPrimaryEndpointReported,
    ),
    fullCompletionDateTargetFinal: convertDateToString(formValues.anticipatedFullCompletionFinalDate),
    fullCompletionDateActual: convertDateToString(formValues.actualFullCompletionDate),
    enrollmentDurationActual: parseFloat(formValues.actualEnrollmentDuration),
    enrollmentDurationTarget: parseFloat(formValues.anticipatedEnrollmentDuration),
    treatmentDurationActual: parseFloat(formValues.actualTreatmentDuration),
    treatmentDurationTarget: parseFloat(formValues.anticipatedTreatmentDuration),
    timetoPublishActual: parseFloat(formValues.actualTimeToPublish),
    timetoPublishTarget: parseFloat(formValues.anticipatedTimeToPublish),
  };
  return parsedFormValues;
};

export const durationErrors = (formRef, initialValues, trialTimingErrors) => {
  const formValues = { ...formRef.values };
  if (initialValues.actualEnrollmentDuration === formValues.actualEnrollmentDuration) {
    if (trialTimingErrors.actualEnrollmentDuration) {
      formRef.setFieldError('actualEnrollmentDuration', trialTimingErrors.actualEnrollmentDuration);
    }
  }
  if (initialValues.actualTreatmentDuration === formValues.actualTreatmentDuration) {
    if (trialTimingErrors.actualTreatmentDuration) {
      formRef.setFieldError('actualTreatmentDuration', trialTimingErrors.actualTreatmentDuration);
    }
  }
  if (initialValues.actualTimeToPublish === formValues.actualTimeToPublish) {
    if (trialTimingErrors.actualTimeToPublish) {
      formRef.setFieldError('actualTimeToPublish', trialTimingErrors.actualTimeToPublish);
    }
  }
  if (initialValues.anticipatedEnrollmentDuration === formValues.anticipatedEnrollmentDuration) {
    if (trialTimingErrors.anticipatedEnrollmentDuration) {
      formRef.setFieldError(
        'anticipatedEnrollmentDuration',
        trialTimingErrors.anticipatedEnrollmentDuration,
      );
    }
  }
  if (initialValues.anticipatedTreatmentDuration === formValues.anticipatedTreatmentDuration) {
    if (trialTimingErrors.anticipatedTreatmentDuration) {
      formRef.setFieldError(
        'anticipatedTreatmentDuration',
        trialTimingErrors.anticipatedTreatmentDuration,
      );
    }
  }
  if (initialValues.anticipatedTimeToPublish === formValues.anticipatedTimeToPublish) {
    if (trialTimingErrors.anticipatedTimeToPublish) {
      formRef.setFieldError('anticipatedTimeToPublish', trialTimingErrors.anticipatedTimeToPublish);
    }
  }
};

// eslint-disable-next-line complexity
// eslint-disable-next-line max-params
export const validate = (values, formRef, trialStatus, isSave = true) => {
  let open = trialStatus ? trialStatus.toLowerCase() === 'open' : false;
  let planned = trialStatus ? trialStatus.toLowerCase() === 'planned' : false;
  let closed = trialStatus ? trialStatus.toLowerCase() === 'closed' : false;
  let temporarilyClosed = trialStatus ? trialStatus.toLowerCase() === 'temporarily closed' : false;
  let errors = {};

  /**** Validation of enrollment close dates ****/

  if (isValid(values.actualStartDate) && isValid(values.actualEnrollmentCloseDate)) {
    if (!dateRangeCheck(values.actualEnrollmentCloseDate, values.actualStartDate)) {
      errors.actualEnrollmentCloseDate =
        'Actual Enrollment date must be equal or later than actual start date';
    }
  }
  if (
    !values.actualStartDate &&
    !values.actualEnrollmentCloseDate &&
    isValid(values.anticipatedStartDate) &&
    isValid(values.anticipatedEnrollmentCloseDate)
  ) {
    if (!dateRangeCheck(values.anticipatedEnrollmentCloseDate, values.anticipatedStartDate)) {
      errors.anticipatedEnrollmentCloseDate =
        'Anticipated Enrollment close date must be equal or later than anticipated start date';
    }
  }
  if (
    isValid(values.actualStartDate) &&
    isValid(values.anticipatedEnrollmentCloseDate) &&
    !values.actualEnrollmentCloseDate
  ) {
    if (!dateRangeCheck(values.anticipatedEnrollmentCloseDate, values.actualStartDate)) {
      errors.anticipatedEnrollmentCloseDate =
        'Anticipated Enrollment close date must be equal or later than actual  start date';
    }
  }
  if (
    isValid(values.actualEnrollmentCloseDate) &&
    isValid(values.anticipatedStartDate) &&
    !values.actualStartDate
  ) {
    if (!dateRangeCheck(values.actualEnrollmentCloseDate, values.anticipatedStartDate)) {
      errors.actualEnrollmentCloseDate =
        'Actual Enrollment date must be equal or later than anticipated the start date';
    }
  }

  /**** Validation of primary completion dates dates ****/
  if (isValid(values.actualPrimaryCompletionDate) && isValid(values.actualStartDate)) {
    if (!dateRangeCheck(values.actualPrimaryCompletionDate, values.actualStartDate)) {
      errors.actualPrimaryCompletionDate =
        'Actual Primary completion date must be equal or later than actual start date';
    }
  }
  if (isValid(values.actualPrimaryCompletionDate) && isValid(values.actualEnrollmentCloseDate)) {
    if (!dateRangeCheck(values.actualPrimaryCompletionDate, values.actualEnrollmentCloseDate)) {
      errors.actualPrimaryCompletionDate =
        'Actual Primary completion date must be equal or later than actual enrollment close date';
    }
  }
  //
  if (
    !values.actualStartDate &&
    !values.actualPrimaryCompletionDate &&
    isValid(values.anticipatedStartDate) &&
    isValid(values.anticipatedPrimaryCompletionDate)
  ) {
    if (
      !dateRangeCheck(
        values.anticipatedPrimaryCompletionDate,
        values.anticipatedStartDate,
      )
    ) {
      errors.anticipatedPrimaryCompletionDate =
        'Anticipated Primary completion date must be equal or later than anticipated start date';
    }
  }
  if (
    !values.actualEnrollmentCloseDate &&
    !values.actualPrimaryCompletionDate &&
    isValid(values.anticipatedEnrollmentCloseDate) &&
    isValid(values.anticipatedPrimaryCompletionDate)
  ) {
    if (
      !dateRangeCheck(
        values.anticipatedPrimaryCompletionDate,
        values.anticipatedEnrollmentCloseDate,
      )
    ) {
      errors.anticipatedPrimaryCompletionDate =
        'Anticipated Primary completion date must be equal or later than anticipated enrollment close date';
    }
  }
  //
  if (
    isValid(values.actualStartDate) &&
    isValid(values.anticipatedPrimaryCompletionDate) &&
    !values.actualPrimaryCompletionDate
  ) {
    if (
      !dateRangeCheck(values.anticipatedPrimaryCompletionDate, values.actualStartDate)
    ) {
      errors.anticipatedPrimaryCompletionDate =
        'Anticipated Primary completion date must be equal or later than actual start date';
    }
  }
  if (
    isValid(values.actualEnrollmentCloseDate) &&
    isValid(values.anticipatedPrimaryCompletionDate) &&
    !values.actualPrimaryCompletionDate
  ) {
    if (
      !dateRangeCheck(values.anticipatedPrimaryCompletionDate, values.actualEnrollmentCloseDate)
    ) {
      errors.anticipatedPrimaryCompletionDate =
        'Anticipated Primary completion date must be equal or later than actual enrollment close date';
    }
  }
  //
  if (
    isValid(values.actualPrimaryCompletionDate) &&
    isValid(values.anticipatedStartDate) &&
    !values.actualStartDate
  ) {
    if (
      !dateRangeCheck(values.actualPrimaryCompletionDate, values.anticipatedStartDate)
    ) {
      errors.actualPrimaryCompletionDate =
        'Actual Primary completion date must be equal or later than anticipated start date';
    }
  }
  if (
    isValid(values.actualPrimaryCompletionDate) &&
    isValid(values.anticipatedEnrollmentCloseDate) &&
    !values.actualEnrollmentCloseDate
  ) {
    if (
      !dateRangeCheck(values.actualPrimaryCompletionDate, values.anticipatedEnrollmentCloseDate)
    ) {
      errors.actualEnrollmentCloseDate =
        'Actual Primary completion date must be equal or later than anticipated enrollment close date';
    }
  }

  /**** Validation of primary endpoint reported dates ****/
  if (
    isValid(values.actualStartDate) &&
    isValid(values.actualPrimaryEndpointReported)
  ) {
    if (!dateRangeCheck(values.actualPrimaryEndpointReported, values.actualStartDate)) {
      errors.actualPrimaryEndpointReported =
        'Actual Primary endpoint reported date must be equal or later than actual start date';
    }
  }
  if (
    isValid(values.actualEnrollmentCloseDate) &&
    isValid(values.actualPrimaryEndpointReported)
  ) {
    if (!dateRangeCheck(values.actualPrimaryEndpointReported, values.actualEnrollmentCloseDate)) {
      errors.actualPrimaryEndpointReported =
        'Actual Primary endpoint reported date must be equal or later than actual enrollment close date';
    }
  }
  if (
    isValid(values.actualPrimaryCompletionDate) &&
    isValid(values.actualPrimaryEndpointReported)
  ) {
    if (!dateRangeCheck(values.actualPrimaryEndpointReported, values.actualPrimaryCompletionDate)) {
      errors.actualPrimaryEndpointReported =
        'Actual Primary endpoint reported date must be equal or later than actual primary completion date';
    }
  }
  //

  if (
    !values.actualStartDate &&
    !values.actualPrimaryEndpointReported &&
    isValid(values.anticipatedStartDate) &&
    isValid(values.anticipatedPrimaryEndpointReported)
  ) {
    if (
      !dateRangeCheck(
        values.anticipatedPrimaryEndpointReported,
        values.anticipatedStartDate,
      )
    ) {
      errors.anticipatedPrimaryEndpointReported =
        'Anticipated Primary endpoint reported date must be equal or later than anticipated start date';
    }
  }
  if (
    !values.actualEnrollmentCloseDate &&
    !values.actualPrimaryEndpointReported &&
    isValid(values.anticipatedEnrollmentCloseDate) &&
    isValid(values.anticipatedPrimaryEndpointReported)
  ) {
    if (
      !dateRangeCheck(
        values.anticipatedPrimaryEndpointReported,
        values.anticipatedEnrollmentCloseDate,
      )
    ) {
      errors.anticipatedPrimaryEndpointReported =
        'Anticipated Primary endpoint reported date must be equal or later than anticipated enrollment close date';
    }
  }
  if (
    !values.actualPrimaryCompletionDate &&
    !values.actualPrimaryEndpointReported &&
    isValid(values.anticipatedPrimaryCompletionDate) &&
    isValid(values.anticipatedPrimaryEndpointReported)
  ) {
    if (
      !dateRangeCheck(
        values.anticipatedPrimaryEndpointReported,
        values.anticipatedPrimaryCompletionDate,
      )
    ) {
      errors.anticipatedPrimaryEndpointReported =
        'Anticipated Primary endpoint reported date must be equal or later than anticipated primary completion date';
    }
  }
  //
  if (
    isValid(values.actualStartDate) &&
    isValid(values.anticipatedPrimaryEndpointReported) &&
    !values.actualPrimaryEndpointReported &&
    !values.anticipatedStartDate
  ) {
    if (
      !dateRangeCheck(values.anticipatedPrimaryEndpointReported, values.actualStartDate)
    ) {
      errors.anticipatedPrimaryEndpointReported =
        'Anticipated Primary endpoint reported date must be equal or later than actual start date';
    }
  }
  if (
    isValid(values.actualEnrollmentCloseDate) &&
    isValid(values.anticipatedPrimaryEndpointReported) &&
    !values.actualPrimaryEndpointReported &&
    !values.anticipatedEnrollmentCloseDate
  ) {
    if (
      !dateRangeCheck(values.anticipatedPrimaryEndpointReported, values.actualEnrollmentCloseDate)
    ) {
      errors.anticipatedPrimaryEndpointReported =
        'Anticipated Primary endpoint reported date must be equal or later than actual enrollment close date';
    }
  }
  if (
    isValid(values.actualPrimaryCompletionDate) &&
    isValid(values.anticipatedPrimaryEndpointReported) &&
    !values.actualPrimaryEndpointReported &&
    !values.anticipatedPrimaryCompletionDate
  ) {
    if (
      !dateRangeCheck(values.anticipatedPrimaryEndpointReported, values.actualPrimaryCompletionDate)
    ) {
      errors.anticipatedPrimaryEndpointReported =
        'Anticipated Primary endpoint reported date must be equal or later than actual primary completion date';
    }
  }
  //
  if (
    isValid(values.actualPrimaryEndpointReported) &&
    isValid(values.anticipatedStartDate) &&
    !values.actualStartDate &&
    !values.anticipatedPrimaryEndpointReported
  ) {
    if (
      !dateRangeCheck(values.actualPrimaryEndpointReported, values.anticipatedStartDate)
    ) {
      errors.actualPrimaryEndpointReported =
        'Actual Primary endpoint reported date must be equal or later than anticipated start date';
    }
  }
  if (
    isValid(values.actualPrimaryEndpointReported) &&
    isValid(values.anticipatedEnrollmentCloseDate) &&
    !values.anticipatedPrimaryEndpointReported &&
    !values.actualEnrollmentCloseDate
  ) {
    if (
      !dateRangeCheck(values.actualPrimaryEndpointReported, values.anticipatedEnrollmentCloseDate)
    ) {
      errors.actualPrimaryEndpointReported =
        'Actual Primary endpoint reported date must be equal or later than anticipated enrollment close date';
    }
  }
  if (
    isValid(values.actualPrimaryEndpointReported) &&
    isValid(values.anticipatedPrimaryCompletionDate) &&
    !values.actualPrimaryCompletionDate &&
    !values.anticipatedPrimaryEndpointReported
  ) {
    if (
      !dateRangeCheck(values.actualPrimaryEndpointReported, values.anticipatedPrimaryCompletionDate)
    ) {
      errors.actualPrimaryEndpointReported =
        'Actual Primary endpoint reported date must be equal or later than anticipated primary completion date';
    }
  }
  //

  /**** Validation based on futuristic condition ****/
  if (values.actualStartDate) {
    if (dateRangeCheck(values.actualStartDate, new Date(), true)) {
      errors.actualStartDate = 'Actual dates cannot be futuristic';
    }
  }
  if (values.actualEnrollmentCloseDate) {
    if (dateRangeCheck(values.actualEnrollmentCloseDate, new Date(), true)) {
      errors.actualEnrollmentCloseDate = 'Actual dates cannot be futuristic';
    }
  }
  if (values.actualPrimaryCompletionDate) {
    if (dateRangeCheck(values.actualPrimaryCompletionDate, new Date(), true)) {
      errors.actualPrimaryCompletionDate = 'Actual dates cannot be futuristic';
    }
  }
  if (values.actualPrimaryEndpointReported) {
    if (dateRangeCheck(values.actualPrimaryEndpointReported, new Date(), true)) {
      errors.actualPrimaryEndpointReported = 'Actual dates cannot be futuristic';
    }
  }
  if (values.actualFullCompletionDate) {
    if (dateRangeCheck(values.actualFullCompletionDate, new Date(), true)) {
      errors.actualFullCompletionDate = 'Actual dates cannot be futuristic';
    }
  }

  /**** Validation based on trial status ****/
  if (isSave) {
    if (values.actualStartDate) {
      if (planned) {
        errors.actualStartDate = 'Actual start date is not allowed for the selected trial status';
      }
    }
    if (values.actualEnrollmentCloseDate) {
      if (planned || open) {
        errors.actualEnrollmentCloseDate =
          'Actual enrollment close date is not allowed for the selected trial status';
      }
    }
    if (values.actualPrimaryCompletionDate) {
      if (planned || open || closed || temporarilyClosed) {
        errors.actualPrimaryCompletionDate =
          'Actual primary completion date is not allowed for the selected trial status';
      }
    }
    if (values.actualPrimaryEndpointReported) {
      if (planned || open || closed || temporarilyClosed) {
        errors.actualPrimaryEndpointReported =
          'Actual primary endpoint reported date is not allowed for the selected trial status';
      }
    }
    if (values.actualFullCompletionDate) {
      if (planned || open || closed || temporarilyClosed) {
        errors.actualFullCompletionDate =
          'Actual full completion date is not allowed for the selected trial status';
      }
    }
  }
  let errorsFromHadler = { ...formRef.errors };
  Object.keys(values).forEach(key => {
    if (values[key]) {
      errorsFromHadler[key] =
        errorsFromHadler[key] === timingConstants.HIGHLITER_MESSAGE
          ? errorsFromHadler[key]
          : errors[key];
    } else {
      errorsFromHadler[key] = '';
    }
  });

  let flag = true;
  for (let i in values) {
    if (errorsFromHadler[i]) {
      flag = false;
      break;
    }
  }

  if (flag) {
    return {};
  } else {
    return { ...errorsFromHadler };
  }
};
export const validateDates = values => {
  let errors = {};
  if (values.actualStartDate) {
    if (!isValid(values.actualStartDate)) {
      errors.actualStartDate = 'Invalid Date Format';
    }
  }
  if (values.actualEnrollmentCloseDate) {
    if (!isValid(values.actualEnrollmentCloseDate)) {
      errors.actualEnrollmentCloseDate = 'Invalid Date Format';
    }
  }
  if (values.actualPrimaryCompletionDate) {
    if (!isValid(values.actualPrimaryCompletionDate)) {
      errors.actualPrimaryCompletionDate = 'Invalid Date Format';
    }
  }
  if (values.actualPrimaryEndpointReported) {
    if (!isValid(values.actualPrimaryEndpointReported)) {
      errors.actualPrimaryEndpointReported = 'Invalid Date Format';
    }
  }
  if (values.actualFullCompletionDate) {
    if (!isValid(values.actualFullCompletionDate)) {
      errors.actualFullCompletionDate = 'Invalid Date Format';
    }
  }
  if (values.anticipatedStartDate) {
    if (!isValid(values.anticipatedStartDate)) {
      errors.anticipatedStartDate = 'Invalid Date Format';
    }
  }
  if (values.anticipatedEnrollmentCloseDate) {
    if (!isValid(values.anticipatedEnrollmentCloseDate)) {
      errors.anticipatedEnrollmentCloseDate = 'Invalid Date Format';
    }
  }
  if (values.anticipatedPrimaryCompletionDate) {
    if (!isValid(values.anticipatedPrimaryCompletionDate)) {
      errors.anticipatedPrimaryCompletionDate = 'Invalid Date Format';
    }
  }
  if (values.anticipatedPrimaryEndpointReported) {
    if (!isValid(values.anticipatedPrimaryEndpointReported)) {
      errors.anticipatedPrimaryEndpointReported = 'Invalid Date Format';
    }
  }
  if (values.anticipatedFullCompletionFinalDate) {
    if (!isValid(values.anticipatedFullCompletionFinalDate)) {
      errors.anticipatedFullCompletionFinalDate = 'Invalid Date Format';
    }
  }
  return errors;
};

// eslint-disable-next-line complexity
export const timingsLogic = ({ fieldId, value, values, formRef, trialStatus }) => {
  let open = trialStatus ? trialStatus.toLowerCase() === 'open' : false;
  let planned = trialStatus ? trialStatus.toLowerCase() === 'planned' : false;
  let closed = trialStatus ? trialStatus.toLowerCase() === 'closed' : false;
  let temporarilyClosed = trialStatus ? trialStatus.toLowerCase() === 'temporarily closed' : false;
  let completed = trialStatus ? trialStatus.toLowerCase() === 'completed' : false;
  let terminated = trialStatus ? trialStatus.toLowerCase() === 'terminated' : false;
  let formFields = { ...values };
  switch (fieldId) {
    case 'actualStartDate':
      if (value) {
        if (formFields.actualEnrollmentCloseDate && !formRef.errors.actualEnrollmentDuration) {
          const duration = convertToDuration(value, formFields.actualEnrollmentCloseDate);
          formRef.setFieldValue('actualEnrollmentDuration', duration >= 0 ? duration : '', false);
          if (!formRef.errors.anticipatedEnrollmentDuration) {
            formRef.setFieldValue('anticipatedEnrollmentDuration', '');
          }
          formRef.setFieldValue('actualStartDate', value);
        }
        if (!formFields.actualEnrollmentCloseDate && formFields.actualEnrollmentDuration) {
          const date = convertToDate(value, formFields.actualEnrollmentDuration);
          formRef.setFieldValue('actualEnrollmentCloseDate', date, false);
          formRef.setFieldValue('actualStartDate', value, false);
          formRef.setFieldError('actualEnrollmentDuration', '');
          //Futuristic check
          // eslint-disable-next-line max-depth
          if (dateRangeCheck(date, new Date(), true)) {
            formRef.setFieldError('actualEnrollmentCloseDate', 'Actual dates cannot be futuristic');
          } else {
            formRef.setFieldError('actualEnrollmentCloseDate', '');
          }
          //chain calculations
          if (planned || open || closed || temporarilyClosed) {
            // eslint-disable-next-line max-depth
            if (
              !formFields.anticipatedPrimaryCompletionDate &&
              formFields.actualTreatmentDuration
            ) {
              const chainDate = convertToDate(date, formFields.actualTreatmentDuration);
              formRef.setFieldValue('anticipatedPrimaryCompletionDate', chainDate, false);
            }
            // eslint-disable-next-line max-depth
            if (
              !formFields.anticipatedPrimaryCompletionDate &&
              !formFields.actualTreatmentDuration &&
              formFields.anticipatedTreatmentDuration
            ) {
              const chainDate = convertToDate(date, formFields.anticipatedTreatmentDuration);
              formRef.setFieldValue('anticipatedPrimaryCompletionDate', chainDate, false);
            }
            if (
              formFields.anticipatedPrimaryCompletionDate &&
              !formFields.actualTreatmentDuration
            ) {
              const duration = convertToDuration(date, formFields.anticipatedPrimaryCompletionDate);
              formRef.setFieldValue(
                'anticipatedTreatmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              // eslint-disable-next-line max-depth
              if (
                !dateRangeCheck(formFields.anticipatedPrimaryCompletionDate, new Date(), true) &&
                dateRangeCheck(formFields.anticipatedPrimaryCompletionDate, date)
              ) {
                formRef.setFieldError(
                  'anticipatedPrimaryCompletionDate',
                  'Anticipated Primary completion date must be equal or later than the actual enrollment close date',
                );
              } else {
                formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
              }
            }

            formRef.setFieldError(
              'actualEnrollmentCloseDate',
              'Actual Enrollment close date is not allowed for the selected trial status',
            );
          } else {
            //Chain calculations for completed and termindated status
            // eslint-disable-next-line no-lonely-if
            // eslint-disable-next-line max-depth
            if (formFields.actualPrimaryCompletionDate && !formFields.actualTreatmentDuration) {
              const duration = convertToDuration(date, formFields.actualPrimaryCompletionDate);
              formRef.setFieldValue('actualTreatmentDuration', duration >= 0 ? duration : '', false);
              // eslint-disable-next-line max-depth
              if (dateRangeCheck(formFields.actualPrimaryCompletionDate, date)) {
                formRef.setFieldError('actualPrimaryCompletionDate', '');
              } else {
                formRef.setFieldError(
                  'actualPrimaryCompletionDate',
                  'Actual Primary completion date must be equal or later than the actual enrollment close date',
                );
              }
            }
            // eslint-disable-next-line max-depth
            if (!formFields.actualPrimaryCompletionDate && formFields.actualTreatmentDuration) {
              const chainDate = convertToDate(date, formFields.actualTreatmentDuration);
              formRef.setFieldValue('actualPrimaryCompletionDate', chainDate, false);
            }
            // eslint-disable-next-line max-depth
            if (
              formFields.anticipatedPrimaryCompletionDate &&
              !formFields.actualTreatmentDuration &&
              !formFields.actualPrimaryCompletionDate
            ) {
              const duration = convertToDuration(date, formFields.anticipatedPrimaryCompletionDate);
              formRef.setFieldValue(
                'anticipatedTreatmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              // eslint-disable-next-line max-depth
              if (dateRangeCheck(formFields.anticipatedPrimaryCompletionDate, date)) {
                formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
              } else {
                formRef.setFieldError(
                  'anticipatedPrimaryCompletionDate',
                  'Anticipated Primary completion date must be equal or later than the actual enrollment close date',
                );
              }
            }
            // eslint-disable-next-line max-depth
            if (
              !formFields.anticipatedPrimaryCompletionDate &&
              !formFields.actualTreatmentDuration &&
              formFields.anticipatedTreatmentDuration &&
              !formFields.actualPrimaryCompletionDate
            ) {
              const chainDate = convertToDate(date, formFields.anticipatedTreatmentDuration);
              formRef.setFieldValue('anticipatedPrimaryCompletionDate', chainDate, false);
            }
          }
        }
        if (!formFields.actualEnrollmentDuration && !formFields.actualEnrollmentCloseDate) {
          if (
            formFields.anticipatedEnrollmentCloseDate &&
            !formRef.errors.anticipatedEnrollmentDuration
          ) {
            const duration = convertToDuration(value, formFields.anticipatedEnrollmentCloseDate);

            // eslint-disable-next-line max-depth
            if (dateRangeCheck(formFields.anticipatedEnrollmentCloseDate, value)) {
              formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
            } else {
              formRef.setFieldError(
                'anticipatedEnrollmentCloseDate',
                'Anticipated Enrollment close date must be equal or later than actual  start date',
              );
            }
            formRef.setFieldValue(
              'anticipatedEnrollmentDuration',
              duration >= 0 ? duration : '',
              false,
            );

            formRef.setFieldValue('actualStartDate', value, false);
          }
          if (
            !formFields.anticipatedEnrollmentCloseDate &&
            formFields.anticipatedEnrollmentDuration
          ) {
            const date = convertToDate(value, formFields.anticipatedEnrollmentDuration);
            formRef.setFieldValue('anticipatedEnrollmentCloseDate', date, false);
            formRef.setFieldValue('actualStartDate', value, false);
            formRef.setFieldError('anticipatedEnrollmentDuration', '');
          }
        }
        //default
        formRef.setFieldValue('actualStartDate', value, false);
        //Futuristic check
        if (dateRangeCheck(value, new Date(), true)) {
          formRef.setFieldError('actualStartDate', 'Actual dates cannot be futuristic');
        } else {
          formRef.setFieldError('actualStartDate', '');
        }
        if (planned) {
          formRef.setFieldError(
            'actualStartDate',
            'Actual start date is not allowed for the selected trial status',
          );
        }
      } else {
        if (
          formFields.actualEnrollmentDuration &&
          formRef.errors.actualEnrollmentDuration &&
          formFields.actualEnrollmentCloseDate
        ) {
          const date = convertToDateBackward(
            formFields.actualEnrollmentCloseDate,
            formFields.actualEnrollmentDuration,
          );
          formRef.setFieldValue('actualStartDate', date, false);
          //Futuristic check
          if (dateRangeCheck(date, new Date(), true)) {
            formRef.setFieldError('actualStartDate', 'Actual dates cannot be futuristic');
          } else {
            formRef.setFieldError('actualStartDate', '');
          }
        }
        if (
          formFields.actualEnrollmentDuration &&
          !formRef.errors.actualEnrollmentDuration &&
          formFields.actualEnrollmentCloseDate
        ) {
          if (formFields.anticipatedStartDate && !formFields.anticipatedEnrollmentDuration) {
            const duration = convertToDuration(
              formFields.anticipatedStartDate,
              formFields.actualEnrollmentCloseDate,
            );
            formRef.setFieldValue(
              'anticipatedEnrollmentDuration',
              duration >= 0 ? duration : '',
              false,
            );
            // eslint-disable-next-line max-depth
            if (formFields.actualEnrollmentCloseDate &&
              formFields.anticipatedStartDate) {
              if (
                dateRangeCheck(formFields.actualEnrollmentCloseDate, formFields.anticipatedStartDate)
              ) {
                formRef.setFieldError('actualEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'actualEnrollmentCloseDate',
                  'Actual Enrollment close date must be equal or later than the anticipated started date',
                );
              }
            }
            formRef.setFieldValue('actualEnrollmentDuration', '', false);
            formRef.setFieldValue('actualStartDate', null, false);
          } else if (!formFields.anticipatedStartDate && formFields.anticipatedEnrollmentDuration) {
            const date = convertToDateBackward(
              formFields.actualEnrollmentCloseDate,
              formFields.anticipatedEnrollmentDuration,
            );
            formRef.setFieldValue('anticipatedStartDate', date, false);
            formRef.setFieldValue('actualStartDate', null, false);
            formRef.setFieldError('actualStartDate', '');
            formRef.setFieldValue(
              'anticipatedEnrollmentDuration',
              formFields.anticipatedEnrollmentDuration,
              false,
            );
            formRef.setFieldValue('actualEnrollmentDuration', '', false);
            formRef.setFieldError('anticipatedEnrollmentDuration', '');
          } else {
            formRef.setFieldValue('actualStartDate', null, false);
            formRef.setFieldError('actualStartDate', '');
            formRef.setFieldValue('actualEnrollmentDuration', '', false);
          }
        }
        if (
          (formFields.actualEnrollmentCloseDate &&
            !dateRangeCheck(formFields.actualEnrollmentCloseDate, formFields.actualStartDate)) ||
          !formFields.actualEnrollmentDuration
        ) {
          if (planned || open) {
            formRef.setFieldError(
              'actualEnrollmentCloseDate',
              'Actual enrollment close date is not allowed for the selected trial status',
            );
          } else {
            formRef.setFieldError('actualEnrollmentCloseDate', '');
          }
          if (formFields.anticipatedStartDate && !formFields.anticipatedEnrollmentDuration) {
            const duration = convertToDuration(
              formFields.anticipatedStartDate,
              formFields.actualEnrollmentCloseDate,
            );
            formRef.setFieldValue(
              'anticipatedEnrollmentDuration',
              duration >= 0 ? duration : '',
              false,
            );
            if (formFields.actualEnrollmentCloseDate &&
              formFields.anticipatedStartDate) {
              if (
                dateRangeCheck(formFields.actualEnrollmentCloseDate, formFields.anticipatedStartDate)
              ) {
                formRef.setFieldError('actualEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'actualEnrollmentCloseDate',
                  'Actual Enrollment close date must be equal or later than the anticipated started date',
                );
              }
            }
            formRef.setFieldValue('actualEnrollmentDuration', '', false);
            formRef.setFieldValue('actualStartDate', null, false);
          } else if (!formFields.anticipatedStartDate && formFields.anticipatedEnrollmentDuration) {
            const date = convertToDateBackward(
              formFields.actualEnrollmentCloseDate,
              formFields.anticipatedEnrollmentDuration,
            );
            formRef.setFieldValue('anticipatedStartDate', date, false);
            formRef.setFieldValue('actualStartDate', null, false);
            formRef.setFieldError('actualStartDate', '');
            formRef.setFieldValue(
              'anticipatedEnrollmentDuration',
              formFields.anticipatedEnrollmentDuration,
              false,
            );
            formRef.setFieldValue('actualEnrollmentDuration', '', false);
            formRef.setFieldError('anticipatedEnrollmentDuration', '');
          } else {
            formRef.setFieldValue('actualStartDate', null, false);
            formRef.setFieldError('actualStartDate', '');
            formRef.setFieldValue('actualEnrollmentDuration', '', false);
          }
        }
        if (!formFields.actualEnrollmentDuration && !formFields.actualEnrollmentCloseDate) {
          if (
            !formFields.anticipatedStartDate &&
            formFields.anticipatedEnrollmentCloseDate &&
            formFields.anticipatedEnrollmentDuration
          ) {
            // eslint-disable-next-line max-depth
            if (formRef.errors.anticipatedEnrollmentDuration) {
              const date = convertToDateBackward(
                formFields.anticipatedEnrollmentCloseDate,
                formFields.anticipatedEnrollmentDuration,
              );
              formRef.setFieldValue('anticipatedStartDate', date, false);
              formRef.setFieldValue('actualStartDate', null, false);
              formRef.setFieldError('actualStartDate', '');
              formRef.setFieldError('anticipatedEnrollmentDuration', '');
            }
            // eslint-disable-next-line max-depth
            if (!formRef.errors.anticipatedEnrollmentDuration) {
              formRef.setFieldValue('actualStartDate', null, false);
              formRef.setFieldError('actualStartDate', '');
              formRef.setFieldValue('anticipatedEnrollmentDuration', '', false);
              formRef.setFieldError('anticipatedEnrollmentDuration', '');
            }
          } else if (
            formFields.anticipatedStartDate &&
            formFields.anticipatedEnrollmentCloseDate &&
            formFields.anticipatedEnrollmentDuration
          ) {
            // eslint-disable-next-line max-depth
            if (!formRef.errors.anticipatedEnrollmentDuration) {
              const duration = convertToDuration(
                formFields.anticipatedStartDate,
                formFields.anticipatedEnrollmentCloseDate,
              );
              formRef.setFieldValue(
                'anticipatedEnrollmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              if (formFields.anticipatedEnrollmentCloseDate &&
                formFields.anticipatedStartDate) {
                if (
                  dateRangeCheck(
                    formFields.anticipatedEnrollmentCloseDate,
                    formFields.anticipatedStartDate,
                  )
                ) {
                  formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
                } else {
                  formRef.setFieldError(
                    'anticipatedEnrollmentCloseDate',
                    'Anticipated Enrollment close date must be equal or later than anticipated start date',
                  );
                }
              }
              formRef.setFieldValue('actualStartDate', null, false);
              formRef.setFieldError('actualStartDate', '');
            }
          } else if (
            formFields.anticipatedStartDate &&
            formFields.anticipatedEnrollmentCloseDate &&
            !formFields.anticipatedEnrollmentDuration
          ) {
            const duration = convertToDuration(
              formFields.anticipatedStartDate,
              formFields.anticipatedEnrollmentCloseDate,
            );
            formRef.setFieldValue(
              'anticipatedEnrollmentDuration',
              duration >= 0 ? duration : '',
              false,
            );
            if (formFields.anticipatedEnrollmentCloseDate &&
              formFields.anticipatedStartDate) {
              if (
                dateRangeCheck(
                  formFields.anticipatedEnrollmentCloseDate,
                  formFields.anticipatedStartDate,
                )
              ) {
                formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'anticipatedEnrollmentCloseDate',
                  'Anticipated Enrollment close date must be equal or later than anticipated start date',
                );
              }
            }
            formRef.setFieldValue('actualStartDate', null, false);
            formRef.setFieldError('actualStartDate', '');
          } else if (
            !formFields.anticipatedStartDate &&
            formFields.anticipatedEnrollmentCloseDate &&
            !formFields.anticipatedEnrollmentDuration
          ) {
            formRef.setFieldValue('actualStartDate', null, false);
            formRef.setFieldError('actualStartDate', '');
            formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
          } else {
            formRef.setFieldValue('actualStartDate', null, false);
            formRef.setFieldError('actualStartDate', '');
          }
        }
      }
      break;

    case 'actualEnrollmentCloseDate':
      if (value) {
        // First block related calculations starts

        if (formFields.actualStartDate && !formRef.errors.actualEnrollmentDuration) {
          const duration = convertToDuration(formFields.actualStartDate, value);
          formRef.setFieldValue('actualEnrollmentDuration', duration >= 0 ? duration : '', false);
          if (!formRef.errors.anticipatedEnrollmentDuration) {
            formRef.setFieldValue('anticipatedEnrollmentDuration', '');
          }
          formRef.setFieldValue('actualEnrollmentCloseDate', value);
        }
        if (!formFields.actualStartDate && formFields.actualEnrollmentDuration) {
          const date = convertToDateBackward(value, formFields.actualEnrollmentDuration);
          formRef.setFieldValue('actualEnrollmentCloseDate', value, false);
          formRef.setFieldValue('actualStartDate', date, false);
          formRef.setFieldError('actualEnrollmentDuration', '');
          //Futuristic check
          if (dateRangeCheck(date, new Date(), true)) {
            formRef.setFieldError('actualStartDate', 'Actual dates cannot be futuristic');
          } else {
            formRef.setFieldError('actualStartDate', '');
          }
          if (planned) {
            formRef.setFieldError(
              'actualStartDate',
              'Actual start date is not allowed for the selected trial status',
            );
          }
        }
        if (!formFields.actualEnrollmentDuration && !formFields.actualStartDate) {
          if (formFields.anticipatedStartDate && !formRef.errors.anticipatedEnrollmentDuration) {
            const duration = convertToDuration(formFields.anticipatedStartDate, value);
            formRef.setFieldValue(
              'anticipatedEnrollmentDuration',
              duration >= 0 ? duration : '',
              false,
            );
            formRef.setFieldValue('actualEnrollmentCloseDate', value);
          }
          if (!formFields.anticipatedStartDate && formFields.anticipatedEnrollmentDuration) {
            const date = convertToDateBackward(value, formFields.anticipatedEnrollmentDuration);
            formRef.setFieldValue('anticipatedStartDate', date, false);
            formRef.setFieldValue('actualEnrollmentCloseDate', value, false);
            formRef.setFieldError('anticipatedEnrollmentDuration', '');
          }
        }

        // Second block related calculation starts here
        if (completed || terminated) {
          if (formFields.actualPrimaryCompletionDate && !formRef.errors.actualTreatmentDuration) {
            const duration = convertToDuration(value, formFields.actualPrimaryCompletionDate);
            formRef.setFieldValue('actualTreatmentDuration', duration >= 0 ? duration : '', false);
            if (!formRef.errors.anticipatedTreatmentDuration) {
              formRef.setFieldValue('anticipatedTreatmentDuration', '');
            }
            formRef.setFieldValue('actualEnrollmentCloseDate', value);
          }
          if (!formFields.actualPrimaryCompletionDate && formFields.actualTreatmentDuration) {
            const date = convertToDate(value, formFields.actualTreatmentDuration);
            formRef.setFieldValue('actualPrimaryCompletionDate', date, false);
            if (dateRangeCheck(date, new Date())) {
              formRef.setFieldError(
                'actualPrimaryCompletionDate',
                'Actual dates cannot be futuristic',
              );
            } else {
              formRef.setFieldError('actualPrimaryCompletionDate', '');
            }
            formRef.setFieldValue('actualEnrollmentCloseDate', value, false);
            formRef.setFieldError('actualTreatmentDuration', '');
          }
        } else {
          if (
            formFields.anticipatedPrimaryCompletionDate &&
            !formRef.errors.actualTreatmentDuration &&
            formFields.actualTreatmentDuration
          ) {
            const duration = convertToDuration(value, formFields.anticipatedPrimaryCompletionDate);
            formRef.setFieldValue('actualTreatmentDuration', duration >= 0 ? duration : '', false);
            if (!formRef.errors.anticipatedTreatmentDuration) {
              formRef.setFieldValue('anticipatedTreatmentDuration', '');
            }
            formRef.setFieldValue('actualEnrollmentCloseDate', value);
          }
          // eslint-disable-next-line no-lonely-if
          if (!formFields.anticipatedPrimaryCompletionDate && formFields.actualTreatmentDuration) {
            const date = convertToDate(value, formFields.actualTreatmentDuration);
            formRef.setFieldValue('anticipatedPrimaryCompletionDate', date, false);
            formRef.setFieldValue('actualEnrollmentCloseDate', value, false);
            formRef.setFieldError('actualTreatmentDuration', '');
          }
        }
        if (!formFields.actualTreatmentDuration && !formFields.actualPrimaryCompletionDate) {
          if (
            formFields.anticipatedPrimaryCompletionDate &&
            !formRef.errors.anticipatedTreatmentDuration
          ) {
            const duration = convertToDuration(value, formFields.anticipatedPrimaryCompletionDate);
            formRef.setFieldValue(
              'anticipatedTreatmentDuration',
              duration >= 0 ? duration : '',
              false,
            );
            formRef.setFieldValue('actualEnrollmentCloseDate', value, false);
            if (dateRangeCheck(formFields.anticipatedPrimaryCompletionDate, value)) {
              formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
            } else {
              formRef.setFieldError(
                'anticipatedPrimaryCompletionDate',
                'Anticipated Primary completion date must be equal or later than the actual enrollment close date',
              );
            }
          }
          if (
            !formFields.anticipatedPrimaryCompletionDate &&
            formFields.anticipatedTreatmentDuration
          ) {
            const date = convertToDate(value, formFields.anticipatedTreatmentDuration);
            formRef.setFieldValue('anticipatedPrimaryCompletionDate', date, false);
            formRef.setFieldValue('actualEnrollmentCloseDate', value, false);
            formRef.setFieldError('anticipatedTreatmentDuration', '');
          }
        }

        //default
        formRef.setFieldValue('actualEnrollmentCloseDate', value, false);
        if (dateRangeCheck(value, new Date(), true)) {
          formRef.setFieldError('actualEnrollmentCloseDate', 'Actual dates cannot be futuristic');
        } else {
          formRef.setFieldError('actualEnrollmentCloseDate', '');
        }
        if (planned || open) {
          formRef.setFieldError(
            'actualEnrollmentCloseDate',
            'Actual enrollment close date is not allowed for the selected trial status',
          );
        }
      } else {
        if (formRef.errors.actualEnrollmentDuration && formRef.errors.actualTreatmentDuration) {
          const date = convertToDate(
            formFields.actualStartDate,
            formFields.actualEnrollmentDuration,
          );
          formRef.setFieldValue('actualEnrollmentCloseDate', date, false);
          formRef.setFieldError('actualEnrollmentDuration', '');
          //Futuristic check
          if (dateRangeCheck(date, new Date(), true)) {
            formRef.setFieldError('actualEnrollmentCloseDate', 'Actual dates cannot be futuristic');
          } else {
            formRef.setFieldError('actualEnrollmentCloseDate', '');
          }
          if (planned || open) {
            formRef.setFieldError(
              'actualEnrollmentCloseDate',
              'Actual enrollment close date is not allowed for the selected trial status',
            );
          }
          // if ((completed || terminated) && dateRangeCheck(date, new Date())) {
          //   formRef.setFieldError(
          //     'actualEnrollmentCloseDate',
          //     'Actual enrollment close date is should not be futuristic',
          //   );
          // }
        }
        if (formRef.errors.actualEnrollmentDuration && !formRef.errors.actualTreatmentDuration) {
          const date = convertToDate(
            formFields.actualStartDate,
            formFields.actualEnrollmentDuration,
          );
          formRef.setFieldValue('actualEnrollmentCloseDate', date, false);
          if (dateRangeCheck(date, new Date())) {
            formRef.setFieldError('actualEnrollmentCloseDate', 'Actual dates cannot be futuristic');
          } else {
            formRef.setFieldError('actualEnrollmentCloseDate', '');
          }
          formRef.setFieldValue('actualTreatmentDuration', '', false);
          formRef.setFieldError('actualEnrollmentDuration', '');
          if (
            planned ||
            open ||
            closed ||
            temporarilyClosed ||
            ((completed || terminated) && !formFields.actualPrimaryCompletionDate)
          ) {
            if (formFields.anticipatedPrimaryCompletionDate) {
              const duration = convertToDuration(date, formFields.anticipatedPrimaryCompletionDate);
              if (
                formRef.errors.anticipatedTreatmentDuration &&
                formFields.anticipatedTreatmentDuration
              ) {
                if (
                  parseFloat(duration) == formFields.anticipatedTreatmentDuration ||
                  Math.round(duration) == formFields.anticipatedTreatmentDuration
                ) {
                  formRef.setFieldValue(
                    'anticipatedTreatmentDuration',
                    duration >= 0 ? duration : '',
                    false,
                  );
                  formRef.setFieldError('anticipatedTreatmentDuration', '');
                }
              } else {
                formRef.setFieldValue(
                  'anticipatedTreatmentDuration',
                  duration >= 0 ? duration : '',
                  false,
                );
              }
              if (dateRangeCheck(formFields.anticipatedPrimaryCompletionDate, date)) {
                formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
              } else {
                formRef.setFieldError(
                  'anticipatedPrimaryCompletionDate',
                  'Anticipated Primary completion date must be equal or later than the actual enrollment close date',
                );
              }
            }

            if ((completed || terminated) && formFields.actualPrimaryCompletionDate) {
              if (formFields.actualPrimaryCompletionDate) {
                const duration = convertToDuration(date, formFields.actualPrimaryCompletionDate);
                if (formFields.actualTreatmentDuration) {
                  formRef.setFieldValue(
                    'actualTreatmentDuration',
                    duration >= 0 ? duration : '',
                    false,
                  );
                } else {
                  formRef.setFieldValue(
                    'actualTreatmentDuration',
                    duration >= 0 ? duration : '',
                    false,
                  );
                }
                if (dateRangeCheck(formFields.actualPrimaryCompletionDate, date)) {
                  formRef.setFieldError('actualPrimaryCompletionDate', '');
                } else {
                  formRef.setFieldError(
                    'actualPrimaryCompletionDate',
                    'Actual Primary completion date must be equal or later than the actual enrollment close date',
                  );
                }
              }
            }

            if (planned || open) {
              formRef.setFieldError(
                'actualEnrollmentCloseDate',
                'Actual enrollment close date is not allowed for the selected trial status',
              );
            }
            // if ((completed || terminated) && dateRangeCheck(date, new Date())) {
            //   formRef.setFieldError(
            //     'actualEnrollmentCloseDate',
            //     'Actual enrollment close date is should not be futuristic',
            //   );
            // }
          }
        }
        if (!formRef.errors.actualEnrollmentDuration && formRef.errors.actualTreatmentDuration) {
          formRef.setFieldValue('actualEnrollmentDuration', '', false);
          formRef.setFieldError('actualEnrollmentCloseDate', '');

          if (planned || open || closed || temporarilyClosed) {
            formRef.setFieldValue('actualEnrollmentCloseDate', null, false);
            const date = convertToDateBackward(
              formFields.anticipatedPrimaryCompletionDate,
              formFields.actualTreatmentDuration,
            );
            const conditionalDate = formFields.anticipatedEnrollmentCloseDate
              ? formFields.anticipatedEnrollmentCloseDate
              : date;
            formRef.setFieldValue('anticipatedEnrollmentCloseDate', conditionalDate, false);
            if (!formFields.anticipatedEnrollmentCloseDate) {
              formRef.setFieldError('actualTreatmentDuration', '');
            }
            if (formFields.actualStartDate) {
              const duration = convertToDuration(formFields.actualStartDate, conditionalDate);
              formRef.setFieldValue(
                'anticipatedEnrollmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              formRef.setFieldError('anticipatedEnrollmentDuration', '');
              if (dateRangeCheck(conditionalDate, formFields.actualStartDate)) {
                formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'anticipatedEnrollmentCloseDate',
                  'Anticipated Enrollment close date must be equal or later than the actual start date',
                );
              }
            }
            if (!formFields.anticipatedStartDate && formFields.anticipatedStartDate) {
              const duration = convertToDuration(formFields.anticipatedStartDate, conditionalDate);
              formRef.setFieldValue(
                'anticipatedEnrollmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              formRef.setFieldError('anticipatedEnrollmentDuration', '');
              if (dateRangeCheck(conditionalDate, formFields.anticipatedStartDate)) {
                formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'anticipatedEnrollmentCloseDate',
                  'Anticipated Enrollment close date must be equal or later than the anticipated start date',
                );
              }
            }
          }

          if (completed || terminated) {
            let date = convertToDateBackward(
              formFields.actualPrimaryCompletionDate,
              formFields.actualEnrollmentCloseDate,
            );
            formRef.setFieldValue('actualEnrollmentCloseDate', date, false);
            //Futuristic check
            if (dateRangeCheck(date, new Date(), true)) {
              formRef.setFieldError(
                'actualEnrollmentCloseDate',
                'Actual dates cannot be futuristic',
              );
            } else {
              formRef.setFieldError('actualEnrollmentCloseDate', '');
            }
            if (formFields.actualEnrollmentDuration) {
              const duration = convertToDuration(formFields.actualStartDate, date);
              formRef.setFieldValue(
                'actualEnrollmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              if (dateRangeCheck(date, new Date())) {
                formRef.setFieldError(
                  'actualEnrollmentCloseDate',
                  'Actual dates cannot be futuristic',
                );
              } else if (dateRangeCheck(date, formFields.actualStartDate)) {
                formRef.setFieldError('actualEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'actualEnrollmentCloseDate',
                  'Actual enrollment close date must be equal or later than the actual start date',
                );
              }
            }
            if (!formFields.actualStartDate && formFields.anticipatedStartDate) {
              const duration = convertToDuration(formFields.anticipatedStartDate, date);
              formRef.setFieldValue(
                'anticipatedEnrollmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              formRef.setFieldError('anticipatedEnrollmentDuration', '');
              if (dateRangeCheck(date, formFields.anticipatedStartDate)) {
                formRef.setFieldError('actualEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'actualEnrollmentCloseDate',
                  'Actual enrollment close date must be equal or later than the anticipated start date',
                );
              }
            }
          }
        }
        if (!formRef.errors.actualEnrollmentDuration && !formRef.errors.actualTreatmentDuration) {
          formRef.setFieldValue('actualEnrollmentCloseDate', null, false);
          formRef.setFieldError('actualEnrollmentCloseDate', '');

          formRef.setFieldValue('actualEnrollmentDuration', '', false);
          formRef.setFieldValue('actualTreatmentDuration', '', false);
          formRef.setFieldError('actualEnrollmentCloseDate', '');

          //Futuristic check
          if (formFields.actualPrimaryCompletionDate) {
            if (dateRangeCheck(formFields.actualPrimaryCompletionDate, new Date(), true)) {
              formRef.setFieldError(
                'actualPrimaryCompletionDate',
                'Actual dates cannot be futuristic',
              );
            } else {
              formRef.setFieldError('actualPrimaryCompletionDate', '');
            }
          }
          if (formFields.anticipatedEnrollmentCloseDate) {
            if (formFields.actualStartDate) {
              const duration = convertToDuration(
                formFields.actualStartDate,
                formFields.anticipatedEnrollmentCloseDate,
              );
              formRef.setFieldValue(
                'anticipatedEnrollmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              formRef.setFieldError('anticipatedEnrollmentDuration', '');
              if (
                dateRangeCheck(
                  formFields.anticipatedEnrollmentCloseDate,
                  formFields.actualStartDate,
                )
              ) {
                formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'anticipatedEnrollmentCloseDate',
                  'Anticipated Enrollment close date must be equal or later than the actual start date',
                );
              }
            }
            if (!formFields.actualStartDate && formFields.anticipatedStartDate) {
              const duration = convertToDuration(
                formFields.anticipatedStartDate,
                formFields.anticipatedEnrollmentCloseDate,
              );
              formRef.setFieldValue(
                'anticipatedEnrollmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              formRef.setFieldError('anticipatedEnrollmentDuration', '');
              if (
                dateRangeCheck(
                  formFields.anticipatedEnrollmentCloseDate,
                  formFields.anticipatedStartDate,
                )
              ) {
                formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'anticipatedEnrollmentCloseDate',
                  'Anticipated Enrollment close date must be equal or later than the anticipated start date',
                );
              }
            }
            if (
              planned ||
              open ||
              closed ||
              temporarilyClosed ||
              ((completed || terminated) && !formFields.actualPrimaryCompletionDate)
            ) {
              if (formFields.anticipatedPrimaryCompletionDate) {
                const duration = convertToDuration(
                  formFields.anticipatedEnrollmentCloseDate,
                  formFields.anticipatedPrimaryCompletionDate,
                );
                formRef.setFieldValue(
                  'anticipatedTreatmentDuration',
                  duration >= 0 ? duration : '',
                  false,
                );
                formRef.setFieldError('anticipatedTreatmentDuration', '');
                if (
                  dateRangeCheck(
                    formFields.anticipatedPrimaryCompletionDate,
                    formFields.anticipatedEnrollmentCloseDate,
                  )
                ) {
                  formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
                } else {
                  formRef.setFieldError(
                    'anticipatedPrimaryCompletionDate',
                    'Anticipated Primary Completion date must be equal or later than the anticipated enrollment close date',
                  );
                }
              }
            }
            if ((completed || terminated) && formFields.actualPrimaryCompletionDate) {
              if (formFields.actualPrimaryCompletionDate) {
                const duration = convertToDuration(
                  formFields.anticipatedEnrollmentCloseDate,
                  formFields.actualPrimaryCompletionDate,
                );
                formRef.setFieldValue(
                  'anticipatedTreatmentDuration',
                  duration >= 0 ? duration : '',
                  false,
                );
                formRef.setFieldError('anticipatedTreatmentDuration', '');
                if (
                  dateRangeCheck(
                    formFields.actualPrimaryCompletionDate,
                    formFields.anticipatedEnrollmentCloseDate,
                  )
                ) {
                  formRef.setFieldError('actualPrimaryCompletionDate', '');
                } else {
                  formRef.setFieldError(
                    'actualPrimaryCompletionDate',
                    'Actual Primary Completion date must be equal or later than the anticipated enrollment close date',
                  );
                }
              }
            }
          } else {
            if (
              formFields.anticipatedPrimaryCompletionDate &&
              formFields.anticipatedTreatmentDuration
            ) {
              formRef.setFieldValue('actualEnrollmentCloseDate', null, false);
              formRef.setFieldError('actualEnrollmentCloseDate', '');
              formRef.setFieldValue('anticipatedTreatmentDuration', '', false);
              formRef.setFieldError('anticipatedTreatmentDuration', '');
            }
            if (formFields.anticipatedStartDate && formFields.anticipatedEnrollmentDuration) {
              formRef.setFieldValue('actualEnrollmentCloseDate', null, false);
              formRef.setFieldError('actualEnrollmentCloseDate', '');
              formRef.setFieldValue('anticipatedEnrollmentDuration', '', false);
              formRef.setFieldError('anticipatedEnrollmentDuration', '');
            }
          }
        }
      }
      break;

    case 'actualEnrollmentDuration':
      if (value) {
        if (formFields.anticipatedEnrollmentDuration) {
          formRef.setFieldError('anticipatedEnrollmentDuration', timingConstants.HIGHLITER_MESSAGE);
        }
        if (formFields.actualStartDate && formFields.actualEnrollmentCloseDate) {
          const duration = convertToDuration(
            formFields.actualStartDate,
            formFields.actualEnrollmentCloseDate,
          );
          if (parseFloat(value) == duration || value == Math.round(duration)) {
            formRef.setFieldValue('actualEnrollmentDuration', value, false);
            formRef.setFieldError('actualEnrollmentDuration', '');
          } else {
            formRef.setFieldValue('actualEnrollmentDuration', value, false);
            formRef.setFieldError('actualEnrollmentDuration', timingConstants.HIGHLITER_MESSAGE);
          }
        }
        if (formFields.actualStartDate && !formFields.actualEnrollmentCloseDate) {
          const date = convertToDate(formFields.actualStartDate, value);
          formRef.setFieldValue('actualEnrollmentCloseDate', date, false);
          formRef.setFieldValue('actualEnrollmentDuration', value, false);

          //Futuristic check
          if (dateRangeCheck(date, new Date(), true)) {
            formRef.setFieldError('actualEnrollmentCloseDate', 'Actual dates cannot be futuristic');
          } else if (planned || open) {
            formRef.setFieldError(
              'actualEnrollmentCloseDate',
              'Actual enrollment close date is not allowed for the selected trial status',
            );
          } else {
            formRef.setFieldError('actualEnrollmentCloseDate', '');
          }

          //Chain calculations

          if (planned || open || closed || temporarilyClosed) {
            if (
              formFields.anticipatedPrimaryCompletionDate &&
              !formFields.actualTreatmentDuration
            ) {
              const duration = convertToDuration(date, formFields.anticipatedPrimaryCompletionDate);
              formRef.setFieldValue(
                'anticipatedTreatmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              if (dateRangeCheck(formFields.anticipatedPrimaryCompletionDate, date)) {
                formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
              } else {
                formRef.setFieldError(
                  'anticipatedPrimaryCompletionDate',
                  'Anticipated Primary completion date must be equal or later than the actual enrollment close date',
                );
              }
            }
            if (
              !formFields.anticipatedPrimaryCompletionDate &&
              formFields.actualTreatmentDuration &&
              !formFields.anticipatedTreatmentDuration
            ) {
              const chainDate = convertToDate(date, formFields.actualTreatmentDuration);
              formRef.setFieldValue('anticipatedPrimaryCompletionDate', chainDate, false);
            }
            if (
              !formFields.anticipatedPrimaryCompletionDate &&
              !formFields.actualTreatmentDuration &&
              formFields.anticipatedTreatmentDuration
            ) {
              const chainDate = convertToDate(date, formFields.anticipatedTreatmentDuration);
              formRef.setFieldValue('anticipatedPrimaryCompletionDate', chainDate, false);
            }
            if (
              formFields.anticipatedPrimaryCompletionDate &&
              !formFields.actualTreatmentDuration
            ) {
              const duration = convertToDuration(date, formFields.anticipatedPrimaryCompletionDate);
              formRef.setFieldValue(
                'anticipatedTreatmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              // eslint-disable-next-line max-depth
              if (
                !dateRangeCheck(formFields.anticipatedPrimaryCompletionDate, new Date()) &&
                dateRangeCheck(formFields.anticipatedPrimaryCompletionDate, date)
              ) {
                formRef.setFieldError(
                  'anticipatedPrimaryCompletionDate',
                  'Anticipated Primary completion date must be equal or later than the actual enrollment close date',
                );
              } else {
                formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
              }
            }
          } else {
            //Chain calculations for completed and termindated status
            // eslint-disable-next-line no-lonely-if
            if (formFields.actualPrimaryCompletionDate && !formFields.actualTreatmentDuration) {
              const duration = convertToDuration(date, formFields.actualPrimaryCompletionDate);
              formRef.setFieldValue('actualTreatmentDuration', duration >= 0 ? duration : '', false);
              if (dateRangeCheck(formFields.actualPrimaryCompletionDate, date)) {
                formRef.setFieldError('actualPrimaryCompletionDate', '');
              } else {
                formRef.setFieldError(
                  'actualPrimaryCompletionDate',
                  'Actual Primary completion date must be equal or later than the actual enrollment close date',
                );
              }
            }
            if (!formFields.actualPrimaryCompletionDate && formFields.actualTreatmentDuration) {
              const chainDate = convertToDate(date, formFields.actualTreatmentDuration);
              formRef.setFieldValue('actualPrimaryCompletionDate', chainDate, false);
            }
            if (
              formFields.anticipatedPrimaryCompletionDate &&
              !formFields.actualTreatmentDuration &&
              !formFields.anticipatedTreatmentDuration &&
              !formFields.actualPrimaryCompletionDate
            ) {
              const duration = convertToDuration(date, formFields.anticipatedPrimaryCompletionDate);
              formRef.setFieldValue(
                'anticipatedTreatmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              if (dateRangeCheck(formFields.anticipatedPrimaryCompletionDate, date)) {
                formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
              } else {
                formRef.setFieldError(
                  'anticipatedPrimaryCompletionDate',
                  'Anticipated Primary completion date must be equal or later than the actual enrollment close date',
                );
              }
            }
            if (
              !formFields.anticipatedPrimaryCompletionDate &&
              !formFields.actualTreatmentDuration &&
              formFields.anticipatedTreatmentDuration &&
              !formFields.actualPrimaryCompletionDate
            ) {
              const chainDate = convertToDate(date, formFields.anticipatedTreatmentDuration);
              formRef.setFieldValue('anticipatedPrimaryCompletionDate', chainDate, false);
            }
          }
        }
        if (!formFields.actualStartDate && formFields.actualEnrollmentCloseDate) {
          const date = convertToDateBackward(formFields.actualEnrollmentCloseDate, value);
          formRef.setFieldValue('actualStartDate', date, false);
          formRef.setFieldValue('actualEnrollmentDuration', value, false);
          formRef.setFieldError('actualEnrollmentDuration', '');
          //Futuristic check
          if (dateRangeCheck(date, new Date(), true)) {
            formRef.setFieldError('actualStartDate', 'Actual dates cannot be futuristic');
          } else {
            formRef.setFieldError('actualStartDate', '');
          }
        }
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          formFields.anticipatedStartDate &&
          formFields.anticipatedEnrollmentCloseDate
        ) {
          const duration = convertToDuration(
            formFields.anticipatedStartDate,
            formFields.anticipatedEnrollmentCloseDate,
          );
          if (parseFloat(value) == duration || value == Math.round(duration)) {
            formRef.setFieldValue('actualEnrollmentDuration', value, false);
            formRef.setFieldError('actualEnrollmentDuration', '');
          } else {
            formRef.setFieldValue('actualEnrollmentDuration', value, false);
            formRef.setFieldError('actualEnrollmentDuration', timingConstants.HIGHLITER_MESSAGE);
          }
        }
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          formFields.anticipatedStartDate &&
          !formFields.anticipatedEnrollmentCloseDate
        ) {
          const date = convertToDate(formFields.anticipatedStartDate, value);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', date, false);
          formRef.setFieldValue('actualEnrollmentDuration', value, false);
          formRef.setFieldError('actualEnrollmentDuration', '');
        }
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          !formFields.anticipatedStartDate &&
          formFields.anticipatedEnrollmentCloseDate
        ) {
          const date = convertToDateBackward(formFields.anticipatedEnrollmentCloseDate, value);
          formRef.setFieldValue('anticipatedStartDate', date, false);
          formRef.setFieldValue('actualEnrollmentDuration', value, false);
          formRef.setFieldError('actualEnrollmentDuration', '');
        }
      } else {
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          !formFields.anticipatedStartDate &&
          !formFields.anticipatedEnrollmentCloseDate
        ) {
          formRef.setFieldValue('actualEnrollmentDuration', value);
        }
        if (formFields.actualStartDate && formFields.actualEnrollmentCloseDate) {
          const duration = convertToDuration(
            formFields.actualStartDate,
            formFields.actualEnrollmentCloseDate,
          );
          formRef.setFieldValue('actualEnrollmentDuration', duration >= 0 ? duration : '', false);
          formRef.setFieldError('actualEnrollmentDuration', '');
        }
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          formFields.anticipatedStartDate &&
          formFields.anticipatedEnrollmentCloseDate
        ) {
          const duration = convertToDuration(
            formFields.anticipatedStartDate,
            formFields.anticipatedEnrollmentCloseDate,
          );
          formRef.setFieldValue(
            'anticipatedEnrollmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          formRef.setFieldValue('actualEnrollmentDuration', value, false);
          formRef.setFieldError('anticipatedEnrollmentDuration', '');
          formRef.setFieldError('actualEnrollmentDuration', '');
        }
      }

      break;

    case 'actualPrimaryCompletionDate':
      if (value) {
        // Second block related calculations starts

        if (completed || terminated) {
          if (formFields.actualEnrollmentCloseDate && !formRef.errors.actualTreatmentDuration) {
            const duration = convertToDuration(formFields.actualEnrollmentCloseDate, value);
            formRef.setFieldValue('actualTreatmentDuration', duration >= 0 ? duration : '', false);
            if (!formRef.errors.anticipatedTreatmentDuration) {
              formRef.setFieldValue('anticipatedTreatmentDuration', '');
            }
            formRef.setFieldValue('actualPrimaryCompletionDate', value);
          }
          if (!formFields.actualEnrollmentCloseDate && formFields.actualTreatmentDuration) {
            const date = convertToDateBackward(value, formFields.actualTreatmentDuration);
            formRef.setFieldValue('actualPrimaryCompletionDate', value, false);
            formRef.setFieldValue('actualEnrollmentCloseDate', date, false);

            //Futuristic check
            if (dateRangeCheck(date, new Date(), true)) {
              formRef.setFieldError(
                'actualEnrollmentCloseDate',
                'Actual dates cannot be futuristic',
              );
            } else {
              formRef.setFieldError('actualEnrollmentCloseDate', '');
            }
            formRef.setFieldError('actualTreatmentDuration', '');

            //Chain calculations
            if (formFields.actualStartDate && !formFields.actualEnrollmentDuration) {
              const duration = convertToDuration(formFields.actualStartDate, date);
              formRef.setFieldValue(
                'actualEnrollmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              if (dateRangeCheck(date, new Date())) {
                formRef.setFieldError(
                  'actualEnrollmentCloseDate',
                  'Actual dates cannot be futuristic',
                );
              } else if (dateRangeCheck(date, formFields.actualStartDate)) {
                formRef.setFieldError('actualEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'actualEnrollmentCloseDate',
                  'Actual Enrollment date must be equal or later than the actual start date',
                );
              }
              if (formFields.anticipatedEnrollmentDuration) {
                formRef.setFieldError(
                  'anticipatedEnrollmentDuration',
                  timingConstants.HIGHLITER_MESSAGE,
                );
              }
            }

            //Chain calculations
            if (!formFields.actualStartDate && formFields.actualEnrollmentDuration) {
              const chainDate = convertToDateBackward(date, formFields.actualEnrollmentDuration);
              formRef.setFieldValue('actualStartDate', chainDate, false);
            }

            //Chain calculation - anticipated

            if (
              !formFields.actualStartDate &&
              !formFields.actualEnrollmentDuration &&
              formFields.anticipatedStartDate
            ) {
              const duration = convertToDuration(formFields.anticipatedStartDate, date);
              formRef.setFieldValue(
                'anticipatedEnrollmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              if (dateRangeCheck(date, formFields.anticipatedStartDate)) {
                formRef.setFieldError('actualEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'actualEnrollmentCloseDate',
                  'Actual Enrollment date must be equal or later than the anticipated start date',
                );
              }
            }
          }
        }
        if (!formFields.actualTreatmentDuration && !formFields.actualEnrollmentCloseDate) {
          if (
            formFields.anticipatedEnrollmentCloseDate &&
            !formRef.errors.anticipatedTreatmentDuration
          ) {
            const duration = convertToDuration(formFields.anticipatedEnrollmentCloseDate, value);
            formRef.setFieldValue(
              'anticipatedTreatmentDuration',
              duration >= 0 ? duration : '',
              false,
            );
            if (dateRangeCheck(value, formFields.anticipatedEnrollmentCloseDate)) {
              formRef.setFieldError('actualPrimaryCompletionDate', '');
            } else {
              formRef.setFieldError(
                'actualPrimaryCompletionDate',
                'Actual Primary completion date must be equal or later than the anticipated enrollment close date',
              );
            }
            formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
            formRef.setFieldValue('actualPrimaryCompletionDate', value, false);
          }
          if (
            !formFields.anticipatedEnrollmentCloseDate &&
            formFields.anticipatedEnrollmentDuration
          ) {
            const date = convertToDateBackward(value, formFields.anticipatedTreatmentDuration);
            formRef.setFieldValue('anticipatedEnrollmentCloseDate', date, false);
            formRef.setFieldValue('actualPrimaryCompletionDate', value, false);
            formRef.setFieldError('anticipatedTreatmentDuration', '');
          }
        }

        // Third block related calculation starts here

        if (formFields.actualPrimaryEndpointReported && !formRef.errors.actualTimeToPublish) {
          const duration = convertToDuration(value, formFields.actualPrimaryEndpointReported);
          formRef.setFieldValue('actualTimeToPublish', duration >= 0 ? duration : '', false);
          if (!formRef.errors.anticipatedTimeToPublish) {
            formRef.setFieldValue('anticipatedTimeToPublish', '');
          }
          formRef.setFieldValue('actualPrimaryCompletionDate', value);
        }
        if (!formFields.actualPrimaryEndpointReported && formFields.actualTimeToPublish) {
          const date = convertToDate(value, formFields.actualTimeToPublish);
          formRef.setFieldValue('actualPrimaryEndpointReported', date, false);

          //Futuristic check

          if (dateRangeCheck(date, new Date(), true)) {
            formRef.setFieldError(
              'actualPrimaryEndpointReported',
              'Actual dates cannot be futuristic',
            );
          } else {
            formRef.setFieldError('actualPrimaryEndpointReported', '');
          }
          formRef.setFieldValue('actualPrimaryCompletionDate', value, false);
          formRef.setFieldError('actualTimeToPublish', '');
        }
        if (!formFields.actualTimeToPublish && !formFields.actualPrimaryEndpointReported) {
          if (
            formFields.anticipatedPrimaryEndpointReported &&
            !formRef.errors.anticipatedTimeToPublish
          ) {
            const duration = convertToDuration(
              value,
              formFields.anticipatedPrimaryEndpointReported,
            );
            formRef.setFieldValue('anticipatedTimeToPublish', duration >= 0 ? duration : '', false);
            formRef.setFieldValue('actualPrimaryCompletionDate', value, false);
            if (dateRangeCheck(formFields.anticipatedPrimaryEndpointReported, value)) {
              formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
            } else {
              formRef.setFieldError(
                'anticipatedPrimaryEndpointReported',
                'Anticipated Primary endpoint reported date must be equal or later than the actual primary completion date',
              );
            }
          }
          if (
            !formFields.anticipatedPrimaryEndpointReported &&
            formFields.anticipatedTimeToPublish
          ) {
            const date = convertToDate(value, formFields.anticipatedTimeToPublish);
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', date, false);
            formRef.setFieldValue('actualPrimaryCompletionDate', value, false);
            formRef.setFieldError('anticipatedTimeToPublish', '');
          }
        }

        //default
        formRef.setFieldValue('actualPrimaryCompletionDate', value, false);

        //Futuristic check
        if (dateRangeCheck(value, new Date(), true)) {
          formRef.setFieldError('actualPrimaryCompletionDate', 'Actual dates cannot be futuristic');
        } else {
          formRef.setFieldError('actualPrimaryCompletionDate', '');
        }
        if (planned || open || closed || temporarilyClosed) {
          formRef.setFieldError(
            'actualPrimaryCompletionDate',
            'Actual primary completion date is not allowed for the selected trial status',
          );
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (completed || terminated) {
          if (formRef.errors.actualTreatmentDuration && formRef.errors.actualTimeToPublish) {
            const date = convertToDate(
              formFields.actualEnrollmentCloseDate,
              formFields.actualTreatmentDuration,
            );
            formRef.setFieldValue('actualPrimaryCompletionDate', date, false);
            formRef.setFieldError('actualTreatmentDuration', '');
            //Futuristic check
            if (dateRangeCheck(date, new Date(), true)) {
              formRef.setFieldError(
                'actualPrimaryCompletionDate',
                'Actual dates cannot be futuristic',
              );
            } else if (planned || open || closed || temporarilyClosed) {
              formRef.setFieldError(
                'actualPrimaryCompletionDate',
                'Actual primary completion date is not allowed for the selected trial status',
              );
            } else {
              formRef.setFieldError('actualPrimaryCompletionDate', '');
            }
          }
          if (formRef.errors.actualTreatmentDuration && !formRef.errors.actualTimeToPublish) {
            const date = convertToDate(
              formFields.actualEnrollmentCloseDate,
              formFields.actualTreatmentDuration,
            );
            //Futuristic check
            if (dateRangeCheck(date, new Date(), true)) {
              formRef.setFieldError(
                'actualPrimaryCompletionDate',
                'Actual dates cannot be futuristic',
              );
            } else {
              formRef.setFieldError('actualPrimaryCompletionDate', '');
            }
            formRef.setFieldValue('actualPrimaryCompletionDate', date, false);
            formRef.setFieldValue('actualTimeToPublish', '', false);
            formRef.setFieldError('actualTreatmentDuration', '');
            if (
              !formFields.actualPrimaryEndpointReported &&
              formFields.anticipatedPrimaryEndpointReported
            ) {
              const duration = convertToDuration(
                date,
                formFields.anticipatedPrimaryEndpointReported,
              );
              if (formFields.anticipatedTimeToPublish) {
                if (
                  parseFloat(duration) == formFields.anticipatedTimeToPublish ||
                  Math.round(duration) == formFields.anticipatedTimeToPublish
                ) {
                  formRef.setFieldValue(
                    'anticipatedTimeToPublish',
                    duration >= 0 ? duration : '',
                    false,
                  );
                  formRef.setFieldError('anticipatedTimeToPublish', '');
                }
              } else {
                formRef.setFieldValue(
                  'anticipatedTimeToPublish',
                  duration >= 0 ? duration : '',
                  false,
                );
              }
              if (dateRangeCheck(formFields.anticipatedPrimaryEndpointReported, date)) {
                formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
              } else {
                formRef.setFieldError(
                  'anticipatedPrimaryEndpointReported',
                  'Anticipated Primary endpoint reported date must be equal or later than the actual primary completion date',
                );
              }
            }

            if (formFields.actualPrimaryEndpointReported) {
              const duration = convertToDuration(date, formFields.actualPrimaryEndpointReported);
              if (formFields.actualTimeToPublish) {
                formRef.setFieldValue('actualTimeToPublish', duration >= 0 ? duration : '', false);
              } else {
                formRef.setFieldValue('actualTimeToPublish', duration >= 0 ? duration : '', false);
              }
              if (dateRangeCheck(formFields.actualPrimaryEndpointReported, date)) {
                formRef.setFieldError('actualPrimaryEndpointReported', '');
              } else {
                formRef.setFieldError(
                  'actualPrimaryEndpointReported',
                  'Actual Primary endpoint reported date must be equal or later than the actual primary completion date',
                );
              }
            }

            if (planned || open || closed || temporarilyClosed) {
              formRef.setFieldError(
                'actualPrimaryCompletionDate',
                'Actual primary completion date is not allowed for the selected trial status',
              );
            }
          }
          if (!formRef.errors.actualTreatmentDuration && formRef.errors.actualTimeToPublish) {
            formRef.setFieldValue('actualTreatmentDuration', '', false);
            formRef.setFieldError('actualPrimaryCompletionDate', '');

            let date = convertToDateBackward(
              formFields.actualPrimaryEndpointReported,
              formFields.actualPrimaryCompletionDate,
            );
            formRef.setFieldValue('actualPrimaryCompletionDate', date, false);
            if (dateRangeCheck(date, new Date(), true)) {
              formRef.setFieldError(
                'actualPrimaryCompletionDate',
                'Actual dates cannot be futuristic',
              );
            } else {
              formRef.setFieldError('actualPrimaryCompletionDate', '');
            }
            if (formFields.actualTreatmentDuration) {
              const duration = convertToDuration(formFields.actualEnrollmentCloseDate, date);
              formRef.setFieldValue('actualTreatmentDuration', duration >= 0 ? duration : '', false);
              //Futuristic check
              if (dateRangeCheck(date, new Date(), true)) {
                formRef.setFieldError(
                  'actualPrimaryCompletionDate',
                  'Actual dates cannot be futuristic',
                );
              } else if (dateRangeCheck(date, formFields.actualEnrollmentCloseDate)) {
                formRef.setFieldError('actualPrimaryCompletionDate', '');
              } else {
                formRef.setFieldError(
                  'actualPrimaryCompletionDate',
                  'Actual primary completion date must be equal or later than the actual enrollment close date',
                );
              }
            }
            if (
              !formFields.actualEnrollmentCloseDate &&
              formFields.anticipatedEnrollmentCloseDate
            ) {
              const duration = convertToDuration(formFields.anticipatedEnrollmentCloseDate, date);
              formRef.setFieldValue(
                'anticipatedTreatmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              formRef.setFieldError('anticipatedTreatmentDuration', '');
              if (dateRangeCheck(date, formFields.anticipatedEnrollmentCloseDate)) {
                formRef.setFieldError('actualPrimaryCompletionDate', '');
              } else {
                formRef.setFieldError(
                  'actualPrimaryCompletionDate',
                  'Actual primary completion date must be equal or later than the anticipated enrollment close date',
                );
              }
            }
          }
          if (!formRef.errors.actualTreatmentDuration && !formRef.errors.actualTimeToPublish) {
            formRef.setFieldValue('actualPrimaryCompletionDate', null, false);
            formRef.setFieldValue('actualTimeToPublish', '', false);
            formRef.setFieldValue('actualTreatmentDuration', '', false);
            formRef.setFieldError('actualPrimaryCompletionDate', '');
            if (formFields.anticipatedPrimaryCompletionDate) {
              if (formFields.actualEnrollmentCloseDate) {
                const duration = convertToDuration(
                  formFields.actualEnrollmentCloseDate,
                  formFields.anticipatedPrimaryCompletionDate,
                );
                formRef.setFieldValue(
                  'anticipatedTreatmentDuration',
                  duration >= 0 ? duration : '',
                  false,
                );
                formRef.setFieldError('anticipatedTreatmentDuration', '');
                if (
                  dateRangeCheck(
                    formFields.anticipatedPrimaryCompletionDate,
                    formFields.actualEnrollmentCloseDate,
                  )
                ) {
                  formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
                } else {
                  formRef.setFieldError(
                    'anticipatedPrimaryCompletionDate',
                    'Anticipated primary completion date must be equal or later than the actual enrollment close date',
                  );
                }
              }
              if (
                !formFields.actualEnrollmentCloseDate &&
                formFields.anticipatedEnrollmentCloseDate
              ) {
                const duration = convertToDuration(
                  formFields.anticipatedEnrollmentCloseDate,
                  formFields.anticipatedPrimaryCompletionDate,
                );
                formRef.setFieldValue(
                  'anticipatedTreatmentDuration',
                  duration >= 0 ? duration : '',
                  false,
                );
                formRef.setFieldError('anticipatedTreatmentDuration', '');
                if (
                  dateRangeCheck(
                    formFields.anticipatedPrimaryCompletionDate,
                    formFields.anticipatedEnrollmentCloseDate,
                  )
                ) {
                  formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
                } else {
                  formRef.setFieldError(
                    'anticipatedPrimaryCompletionDate',
                    'Anticipated primary completion date must be equal or later than the anticipated Enrollment close date',
                  );
                }
              }
              if (
                !formFields.actualPrimaryEndpointReported &&
                formFields.anticipatedPrimaryEndpointReported
              ) {
                const duration = convertToDuration(
                  formFields.anticipatedPrimaryCompletionDate,
                  formFields.anticipatedPrimaryEndpointReported,
                );
                formRef.setFieldValue(
                  'anticipatedTimeToPublish',
                  duration >= 0 ? duration : '',
                  false,
                );
                formRef.setFieldError('anticipatedTimeToPublish', '');
                if (
                  dateRangeCheck(
                    formFields.anticipatedPrimaryEndpointReported,
                    formFields.anticipatedPrimaryCompletionDate,
                  )
                ) {
                  formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
                } else {
                  formRef.setFieldError(
                    'anticipatedPrimaryEndpointReported',
                    'Anticipated Primary endpoint reported date must be equal or later than the anticipated primary completion date',
                  );
                }
              }
              if (formFields.actualPrimaryEndpointReported) {
                const duration = convertToDuration(
                  formFields.anticipatedPrimaryCompletionDate,
                  formFields.actualPrimaryEndpointReported,
                );
                formRef.setFieldValue(
                  'anticipatedTimeToPublish',
                  duration >= 0 ? duration : '',
                  false,
                );
                formRef.setFieldError('anticipatedTimeToPublish', '');
                if (
                  dateRangeCheck(
                    formFields.actualPrimaryEndpointReported,
                    formFields.anticipatedPrimaryCompletionDate,
                  )
                ) {
                  formRef.setFieldError('actualPrimaryEndpointReported', '');
                } else {
                  formRef.setFieldError(
                    'actualPrimaryEndpointReported',
                    'Actual Primary endpoint reported date must be equal or later than the anticipated primary completion date',
                  );
                }
              }
            } else {
              if (
                formFields.anticipatedEnrollmentCloseDate &&
                formFields.anticipatedTreatmentDuration
              ) {
                formRef.setFieldValue('actualPrimaryCompletionDate', null, false);
                formRef.setFieldError('actualPrimaryCompletionDate', '');
                formRef.setFieldValue('anticipatedTreatmentDuration', '', false);
                formRef.setFieldError('anticipatedTreatmentDuration', '');
              }
              if (
                formFields.anticipatedPrimaryEndpointReported &&
                formFields.anticipatedTimeToPublish
              ) {
                formRef.setFieldValue('actualPrimaryCompletionDate', null, false);
                formRef.setFieldError('actualPrimaryCompletionDate', '');
                formRef.setFieldValue('anticipatedTimeToPublish', '', false);
                formRef.setFieldError('anticipatedTimeToPublish', '');
              }
            }
          }
        } else {
          if (formRef.errors.actualTimeToPublish) {
            formRef.setFieldError('actualPrimaryCompletionDate', '');

            let date = convertToDateBackward(
              formFields.actualPrimaryEndpointReported,
              formFields.actualPrimaryCompletionDate,
            );
            formRef.setFieldValue('actualPrimaryCompletionDate', date, false);
            if (dateRangeCheck(date, new Date(), true)) {
              formRef.setFieldError(
                'actualPrimaryCompletionDate',
                'Actual dates cannot be futuristic',
              );
            } else {
              formRef.setFieldError('actualPrimaryCompletionDate', '');
            }
          }
          if (!formRef.errors.actualTimeToPublish) {
            formRef.setFieldValue('actualPrimaryCompletionDate', null, false);
            formRef.setFieldValue('actualTimeToPublish', '', false);
            formRef.setFieldError('actualPrimaryCompletionDate', '');
            if (formFields.anticipatedPrimaryCompletionDate) {
              if (
                !formFields.actualPrimaryEndpointReported &&
                formFields.anticipatedPrimaryEndpointReported
              ) {
                const duration = convertToDuration(
                  formFields.anticipatedPrimaryCompletionDate,
                  formFields.anticipatedPrimaryEndpointReported,
                );
                formRef.setFieldValue(
                  'anticipatedTimeToPublish',
                  duration >= 0 ? duration : '',
                  false,
                );
                formRef.setFieldError('anticipatedTimeToPublish', '');
                if (
                  dateRangeCheck(
                    formFields.anticipatedPrimaryEndpointReported,
                    formFields.anticipatedPrimaryCompletionDate,
                  )
                ) {
                  formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
                } else {
                  formRef.setFieldError(
                    'anticipatedPrimaryEndpointReported',
                    'Anticipated Primary endpoint reported date must be equal or later than the anticipated primary completion date',
                  );
                }
              }
              if (formFields.actualPrimaryEndpointReported) {
                const duration = convertToDuration(
                  formFields.anticipatedPrimaryCompletionDate,
                  formFields.actualPrimaryEndpointReported,
                );
                formRef.setFieldValue(
                  'anticipatedTimeToPublish',
                  duration >= 0 ? duration : '',
                  false,
                );
                formRef.setFieldError('anticipatedTimeToPublish', '');
                if (
                  dateRangeCheck(
                    formFields.actualPrimaryEndpointReported,
                    formFields.anticipatedPrimaryCompletionDate,
                  )
                ) {
                  formRef.setFieldError('actualPrimaryEndpointReported', '');
                } else {
                  formRef.setFieldError(
                    'actualPrimaryEndpointReported',
                    'Actual Primary endpoint reported date must be equal or later than the anticipated primary completion date',
                  );
                }
              }
            } else if (
              formFields.anticipatedPrimaryEndpointReported &&
              formFields.anticipatedTimeToPublish
            ) {
              formRef.setFieldValue('actualPrimaryCompletionDate', null, false);
              formRef.setFieldError('actualPrimaryCompletionDate', '');
              formRef.setFieldValue('anticipatedTimeToPublish', '', false);
              formRef.setFieldError('anticipatedTimeToPublish', '');
            }
          }
          formRef.setFieldError('actualPrimaryCompletionDate', '');
          formRef.setFieldValue('actualPrimaryCompletionDate', null, false);
        }
      }
      break;

    case 'actualTreatmentDuration':
      if (value) {
        if (formFields.anticipatedTreatmentDuration) {
          formRef.setFieldError('anticipatedTreatmentDuration', timingConstants.HIGHLITER_MESSAGE);
          // formRef.setFieldValue('actualEnrollmentDuration', value);
        }
        if (completed || terminated) {
          if (formFields.actualEnrollmentCloseDate && formFields.actualPrimaryCompletionDate) {
            const duration = convertToDuration(
              formFields.actualEnrollmentCloseDate,
              formFields.actualPrimaryCompletionDate,
            );
            if (parseFloat(value) == duration || value == Math.round(duration)) {
              formRef.setFieldValue('actualTreatmentDuration', value, false);
              formRef.setFieldError('actualTreatmentDuration', '');
            } else {
              formRef.setFieldValue('actualTreatmentDuration', value, false);
              formRef.setFieldError('actualTreatmentDuration', timingConstants.HIGHLITER_MESSAGE);
            }
          }
          if (formFields.actualEnrollmentCloseDate && !formFields.actualPrimaryCompletionDate) {
            const date = convertToDate(formFields.actualEnrollmentCloseDate, value);
            formRef.setFieldValue('actualPrimaryCompletionDate', date, false);
            //Futuristic check
            if (dateRangeCheck(date, new Date(), true)) {
              formRef.setFieldError(
                'actualPrimaryCompletionDate',
                'Actual dates cannot be futuristic',
              );
            } else {
              formRef.setFieldError('actualPrimaryCompletionDate', '');
            }
            formRef.setFieldValue('actualTreatmentDuration', value, false);
            formRef.setFieldError('actualTreatmentDuration', '');
            formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
          }
          if (!formFields.actualEnrollmentCloseDate && formFields.actualPrimaryCompletionDate) {
            const date = convertToDateBackward(formFields.actualPrimaryCompletionDate, value);
            formRef.setFieldValue('actualEnrollmentCloseDate', date, false);
            //Futuristic check
            if (dateRangeCheck(date, new Date(), true)) {
              formRef.setFieldError(
                'actualEnrollmentCloseDate',
                'Actual dates cannot be futuristic',
              );
            } else {
              formRef.setFieldError('actualEnrollmentCloseDate', '');
            }
            formRef.setFieldValue('actualTreatmentDuration', value, false);
            formRef.setFieldError('actualTreatmentDuration', '');

            //Chain calculations
            if (formFields.actualStartDate && !formFields.actualEnrollmentDuration) {
              const duration = convertToDuration(formFields.actualStartDate, date);
              formRef.setFieldValue(
                'actualEnrollmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              //Futuristic check
              if (dateRangeCheck(date, new Date(), true)) {
                formRef.setFieldError(
                  'actualEnrollmentCloseDate',
                  'Actual dates cannot be futuristic',
                );
              } else if (dateRangeCheck(date, formFields.actualStartDate)) {
                formRef.setFieldError('actualEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'actualEnrollmentCloseDate',
                  'Actual Enrollment date must be equal or later than the actual start date',
                );
              }
              if (formFields.anticipatedEnrollmentDuration) {
                formRef.setFieldValue('anticipatedEnrollmentDuration', '', false);
              }
            }

            //Chain calculations
            if (!formFields.actualStartDate && formFields.actualEnrollmentDuration) {
              const chainDate = convertToDateBackward(date, formFields.actualEnrollmentDuration);
              formRef.setFieldValue('actualStartDate', chainDate, false);
            }

            //Chain calculation - anticipated

            if (
              !formFields.actualStartDate &&
              !formFields.actualEnrollmentDuration &&
              formFields.anticipatedStartDate
            ) {
              const duration = convertToDuration(formFields.anticipatedStartDate, date);
              formRef.setFieldValue(
                'anticipatedEnrollmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              if (dateRangeCheck(date, formFields.anticipatedStartDate)) {
                formRef.setFieldError('actualEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'actualEnrollmentCloseDate',
                  'Actual Enrollment date must be equal or later than the anticipated start date',
                );
              }
            }
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (formFields.actualEnrollmentCloseDate && formFields.anticipatedPrimaryCompletionDate) {
            const duration = convertToDuration(
              formFields.actualEnrollmentCloseDate,
              formFields.anticipatedPrimaryCompletionDate,
            );
            if (parseFloat(value) == duration || value == Math.round(duration)) {
              formRef.setFieldValue('actualTreatmentDuration', value, false);
              formRef.setFieldError('actualTreatmentDuration', '');
            } else {
              formRef.setFieldValue('actualTreatmentDuration', value, false);
              formRef.setFieldError('actualTreatmentDuration', timingConstants.HIGHLITER_MESSAGE);
            }
          }
          if (
            formFields.actualEnrollmentCloseDate &&
            !formFields.anticipatedPrimaryCompletionDate
          ) {
            const date = convertToDate(formFields.actualEnrollmentCloseDate, value);
            formRef.setFieldValue('anticipatedPrimaryCompletionDate', date, false);
            formRef.setFieldValue('actualTreatmentDuration', value, false);
            formRef.setFieldError('actualTreatmentDuration', '');
          }
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          formFields.anticipatedEnrollmentCloseDate &&
          formFields.anticipatedPrimaryCompletionDate
        ) {
          const duration = convertToDuration(
            formFields.anticipatedEnrollmentCloseDate,
            formFields.anticipatedPrimaryCompletionDate,
          );
          if (parseFloat(value) == duration || value == Math.round(duration)) {
            formRef.setFieldValue('actualTreatmentDuration', value, false);
            formRef.setFieldError('actualTreatmentDuration', '');
          } else {
            formRef.setFieldValue('actualTreatmentDuration', value, false);
            formRef.setFieldError('actualTreatmentDuration', timingConstants.HIGHLITER_MESSAGE);
          }
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          formFields.anticipatedEnrollmentCloseDate &&
          !formFields.anticipatedPrimaryCompletionDate
        ) {
          const date = convertToDate(formFields.anticipatedEnrollmentCloseDate, value);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', date, false);
          formRef.setFieldValue('actualTreatmentDuration', value, false);
          formRef.setFieldError('actualTreatmentDuration', '');
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          !formFields.anticipatedEnrollmentCloseDate &&
          formFields.anticipatedPrimaryCompletionDate
        ) {
          const date = convertToDateBackward(formFields.anticipatedPrimaryCompletionDate, value);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', date, false);
          formRef.setFieldValue('actualTreatmentDuration', value, false);
          formRef.setFieldError('actualTreatmentDuration', '');
        }
        //default
        // formRef.setFieldValue('actualEnrollmentDuration', value);
      } else {
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          !formFields.anticipatedEnrollmentCloseDate &&
          !formFields.anticipatedPrimaryCompletionDate
        ) {
          formRef.setFieldValue('actualTreatmentDuration', value);
        }
        if (
          formFields.actualEnrollmentCloseDate &&
          formFields.actualPrimaryCompletionDate &&
          (completed || terminated)
        ) {
          const duration = convertToDuration(
            formFields.actualEnrollmentCloseDate,
            formFields.actualPrimaryCompletionDate,
          );
          formRef.setFieldValue('actualTreatmentDuration', duration >= 0 ? duration : '', false);
          formRef.setFieldError('actualTreatmentDuration', '');
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          formFields.anticipatedEnrollmentCloseDate &&
          formFields.anticipatedPrimaryCompletionDate
        ) {
          const duration = convertToDuration(
            formFields.anticipatedEnrollmentCloseDate,
            formFields.anticipatedPrimaryCompletionDate,
          );
          formRef.setFieldValue(
            'anticipatedTreatmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          formRef.setFieldValue('actualTreatmentDuration', value, false);
          formRef.setFieldError('anticipatedTreatmentDuration', '');
          formRef.setFieldError('actualTreatmentDuration', '');
        }
      }
      break;
    case 'actualPrimaryEndpointReported':
      if (value) {
        // Third block related calculations starts

        if (formFields.actualPrimaryCompletionDate && !formRef.errors.actualTimeToPublish) {
          const duration = convertToDuration(formFields.actualPrimaryCompletionDate, value);
          formRef.setFieldValue('actualTimeToPublish', duration >= 0 ? duration : '', false);
          if (!formRef.errors.anticipatedTimeToPublish) {
            formRef.setFieldValue('anticipatedTimeToPublish', '');
          }
          formRef.setFieldValue('actualPrimaryEndpointReported', value);
        }
        if (!formFields.actualPrimaryCompletionDate && formFields.actualTimeToPublish) {
          const date = convertToDateBackward(value, formFields.actualTimeToPublish);
          formRef.setFieldValue('actualPrimaryEndpointReported', value, false);
          formRef.setFieldValue('actualPrimaryCompletionDate', date, false);
          //Futuristic check
          if (dateRangeCheck(value, new Date(), true)) {
            formRef.setFieldError(
              'actualPrimaryCompletionDate',
              'Actual dates cannot be futuristic',
            );
          } else {
            formRef.setFieldError('actualPrimaryCompletionDate', '');
          }
          formRef.setFieldError('actualTimeToPublish', '');
        }
        if (!formFields.actualTimeToPublish && !formFields.actualPrimaryCompletionDate) {
          if (
            formFields.anticipatedPrimaryCompletionDate &&
            !formRef.errors.anticipatedTimeToPublish
          ) {
            const duration = convertToDuration(formFields.anticipatedPrimaryCompletionDate, value);
            formRef.setFieldValue('anticipatedTimeToPublish', duration >= 0 ? duration : '', false);
            formRef.setFieldValue('actualPrimaryEndpointReported', value, false);
            if (dateRangeCheck(value, formFields.anticipatedPrimaryCompletionDate)) {
              formRef.setFieldError('actualPrimaryEndpointReported', '');
            } else {
              formRef.setFieldError(
                'actualPrimaryEndpointReported',
                'Actual Primary endpoint reported date must be equal or later than anticipated  primary completion date',
              );
            }
            formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
          }
          if (!formFields.anticipatedPrimaryCompletionDate && formFields.anticipatedTimeToPublish) {
            const date = convertToDateBackward(value, formFields.anticipatedTimeToPublish);
            formRef.setFieldValue('anticipatedPrimaryCompletionDate', date, false);
            formRef.setFieldValue('actualPrimaryEndpointReported', value, false);
            formRef.setFieldError('anticipatedTimeToPublish', '');
          }
        }

        //default
        formRef.setFieldValue('actualPrimaryEndpointReported', value, false);

        //Futuristic check
        if (dateRangeCheck(value, new Date(), true)) {
          formRef.setFieldError(
            'actualPrimaryEndpointReported',
            'Actual dates cannot be futuristic',
          );
        } else {
          formRef.setFieldError('actualPrimaryEndpointReported', '');
        }
        if (planned || open || closed || temporarilyClosed) {
          formRef.setFieldError(
            'actualPrimaryEndpointReported',
            'Actual primary endpoint reported date is not allowed for the selected trial status',
          );
        }
      } else {
        if (
          formFields.actualTimeToPublish &&
          formRef.errors.actualTimeToPublish &&
          formFields.actualPrimaryCompletionDate
        ) {
          const date = convertToDate(
            formFields.actualPrimaryCompletionDate,
            formFields.actualTimeToPublish,
          );
          formRef.setFieldValue('actualPrimaryEndpointReported', date, false);
          //Futuristic check

          if (dateRangeCheck(date, new Date(), true)) {
            formRef.setFieldError(
              'actualPrimaryEndpointReported',
              'Actual dates cannot be futuristic',
            );
          } else {
            formRef.setFieldError('actualPrimaryEndpointReported', '');
          }
          formRef.setFieldError('actualTimeToPublish', '');
          formRef.setFieldError('actualPrimaryEndpointReported', '');
        }
        if (
          formFields.actualTimeToPublish &&
          !formRef.errors.actualTimeToPublish &&
          formFields.actualPrimaryCompletionDate
        ) {
          if (
            formFields.anticipatedPrimaryEndpointReported &&
            !formFields.anticipatedTimeToPublish
          ) {
            const duration = convertToDuration(
              formFields.actualPrimaryCompletionDate,
              formFields.anticipatedPrimaryEndpointReported,
            );
            if (
              dateRangeCheck(
                formFields.anticipatedPrimaryEndpointReported,
                formFields.actualPrimaryCompletionDate,
              )
            ) {
              formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
            } else {
              formRef.setFieldError(
                'anticipatedPrimaryEndpointReported',
                'Anticipated Primary endpoint reported date must be equal or later than the actual primary completion date',
              );
            }
            formRef.setFieldValue('anticipatedTimeToPublish', duration >= 0 ? duration : '', false);
            formRef.setFieldValue('actualTimeToPublish', '', false);
            formRef.setFieldValue('actualPrimaryEndpointReported', null, false);
            formRef.setFieldError('actualPrimaryEndpointReported', '');
          } else if (
            !formFields.anticipatedPrimaryEndpointReported &&
            formFields.anticipatedTimeToPublish
          ) {
            const date = convertToDate(
              formFields.actualPrimaryCompletionDate,
              formFields.anticipatedTimeToPublish,
            );
            formRef.setFieldValue('actualPrimaryEndpointReported', null, false);
            formRef.setFieldError('actualPrimaryEndpointReported', '');
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', date, false);
            formRef.setFieldError('anticipatedTimeToPublish', '');
            formRef.setFieldValue('actualTimeToPublish', '', false);
          } else {
            formRef.setFieldValue('actualPrimaryEndpointReported', null, false);
            formRef.setFieldError('actualPrimaryEndpointReported', '');
            formRef.setFieldValue('actualTimeToPublish', '', false);
          }
        }
        if (
          formFields.actualPrimaryCompletionDate &&
          !dateRangeCheck(
            formFields.actualPrimaryEndpointReported,
            formFields.actualPrimaryCompletionDate,
          )
        ) {
          if (
            formFields.anticipatedPrimaryEndpointReported &&
            !formFields.anticipatedTimeToPublish
          ) {
            const duration = convertToDuration(
              formFields.actualPrimaryCompletionDate,
              formFields.anticipatedPrimaryEndpointReported,
            );
            if (
              dateRangeCheck(
                formFields.anticipatedPrimaryEndpointReported,
                formFields.actualPrimaryCompletionDate,
              )
            ) {
              formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
            } else {
              formRef.setFieldError(
                'anticipatedPrimaryEndpointReported',
                'Anticipated Primary endpoint reported date must be equal or later than the actual primary completion date',
              );
            }
            formRef.setFieldValue('anticipatedTimeToPublish', duration >= 0 ? duration : '', false);
            formRef.setFieldValue('actualTimeToPublish', '', false);
            formRef.setFieldValue('actualPrimaryEndpointReported', null, false);
            formRef.setFieldError('actualPrimaryEndpointReported', '');
          } else if (
            !formFields.anticipatedPrimaryEndpointReported &&
            formFields.anticipatedTimeToPublish
          ) {
            const date = convertToDate(
              formFields.actualPrimaryCompletionDate,
              formFields.anticipatedTimeToPublish,
            );
            formRef.setFieldValue('actualPrimaryEndpointReported', null, false);
            formRef.setFieldError('actualPrimaryEndpointReported', '');
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', date, false);
            formRef.setFieldError('anticipatedTimeToPublish', '');
            formRef.setFieldValue('actualTimeToPublish', '', false);
          } else {
            formRef.setFieldValue('actualPrimaryEndpointReported', null, false);
            formRef.setFieldError('actualPrimaryEndpointReported', '');
            formRef.setFieldValue('actualTimeToPublish', '', false);
          }
        }

        if (!formFields.actualTimeToPublish && !formFields.actualPrimaryCompletionDate) {
          if (
            !formFields.anticipatedPrimaryEndpointReported &&
            formFields.anticipatedPrimaryCompletionDate &&
            formFields.anticipatedTimeToPublish
          ) {
            // eslint-disable-next-line max-depth
            if (formRef.errors.anticipatedTimeToPublish) {
              const date = convertToDate(
                formFields.anticipatedPrimaryCompletionDate,
                formFields.anticipatedTimeToPublish,
              );
              formRef.setFieldValue('anticipatedPrimaryEndpointReported', date, false);
              formRef.setFieldValue('actualPrimaryEndpointReported', null, false);
              formRef.setFieldError('actualPrimaryEndpointReported', '');
              formRef.setFieldError('anticipatedTimeToPublish', '');
            }
            // eslint-disable-next-line max-depth
            if (!formRef.errors.anticipatedTimeToPublish) {
              // let date = convertToDate(
              //   formFields.anticipatedPrimaryCompletionDate,
              //   formFields.anticipatedTimeToPublish,
              // );
              // formRef.setFieldValue('anticipatedPrimaryEndpointReported', date, false);
              formRef.setFieldValue('actualPrimaryEndpointReported', null, false);
              formRef.setFieldError('actualPrimaryEndpointReported', '');
              formRef.setFieldValue('anticipatedTimeToPublish', '', false);
              formRef.setFieldError('anticipatedTimeToPublish', '');
            }
          } else if (
            formFields.anticipatedPrimaryCompletionDate &&
            formFields.anticipatedPrimaryEndpointReported &&
            formFields.anticipatedTimeToPublish
          ) {
            // eslint-disable-next-line max-depth
            if (!formRef.errors.anticipatedTimeToPublish) {
              const duration = convertToDuration(
                formFields.anticipatedPrimaryCompletionDate,
                formFields.anticipatedPrimaryEndpointReported,
              );
              formRef.setFieldValue(
                'anticipatedTimeToPublish',
                duration >= 0 ? duration : '',
                false,
              );
              if (
                dateRangeCheck(
                  formFields.anticipatedPrimaryEndpointReported,
                  formFields.anticipatedPrimaryCompletionDate,
                )
              ) {
                formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
              } else {
                formRef.setFieldError(
                  'anticipatedPrimaryEndpointReported',
                  'Anticipated Primary endpoint reported date must be equal or later than the anticipated primary completion date',
                );
              }
              formRef.setFieldValue('actualPrimaryEndpointReported', null, false);
              formRef.setFieldError('actualPrimaryEndpointReported', '');
            }
          } else if (
            formFields.anticipatedPrimaryCompletionDate &&
            formFields.anticipatedPrimaryEndpointReported &&
            !formFields.anticipatedTimeToPublish
          ) {
            const duration = convertToDuration(
              formFields.anticipatedPrimaryCompletionDate,
              formFields.anticipatedPrimaryEndpointReported,
            );
            if (
              dateRangeCheck(
                formFields.anticipatedPrimaryEndpointReported,
                formFields.anticipatedPrimaryCompletionDate,
              )
            ) {
              formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
            } else {
              formRef.setFieldError(
                'anticipatedPrimaryEndpointReported',
                'Anticipated Primary endpoint reported date must be equal or later than the anticipated primary completion date',
              );
            }
            formRef.setFieldValue('anticipatedTimeToPublish', duration >= 0 ? duration : '', false);
            formRef.setFieldValue('actualPrimaryEndpointReported', null);
            formRef.setFieldError('actualPrimaryEndpointReported', '');
          } else {
            formRef.setFieldValue('actualPrimaryEndpointReported', null, false);
            formRef.setFieldError('actualPrimaryEndpointReported', '');
          }
        }

        //default
      }
      break;

    case 'actualTimeToPublish':
      if (value) {
        if (formFields.anticipatedTimeToPublish) {
          formRef.setFieldError('anticipatedTimeToPublish', timingConstants.HIGHLITER_MESSAGE);
          // formRef.setFieldValue('actualEnrollmentDuration', value);
        }
        if (formFields.actualPrimaryCompletionDate && formFields.actualPrimaryEndpointReported) {
          const duration = convertToDuration(
            formFields.actualPrimaryCompletionDate,
            formFields.actualPrimaryEndpointReported,
          );
          if (parseFloat(value) == duration || value == Math.round(duration)) {
            formRef.setFieldValue('actualTimeToPublish', value, false);
            formRef.setFieldError('actualTimeToPublish', '');
          } else {
            formRef.setFieldValue('actualTimeToPublish', value, false);
            formRef.setFieldError('actualTimeToPublish', timingConstants.HIGHLITER_MESSAGE);
          }
        }
        if (formFields.actualPrimaryCompletionDate && !formFields.actualPrimaryEndpointReported) {
          const date = convertToDate(formFields.actualPrimaryCompletionDate, value);
          formRef.setFieldValue('actualPrimaryEndpointReported', date, false);
          //Futuristic check

          if (dateRangeCheck(date, new Date(), true)) {
            formRef.setFieldError(
              'actualPrimaryEndpointReported',
              'Actual dates cannot be futuristic',
            );
          } else {
            formRef.setFieldError('actualPrimaryEndpointReported', '');
          }
          formRef.setFieldValue('actualTimeToPublish', value, false);
          formRef.setFieldError('actualTimeToPublish', '');
          formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
        }
        if (!formFields.actualPrimaryCompletionDate && formFields.actualPrimaryEndpointReported) {
          const date = convertToDateBackward(formFields.actualPrimaryEndpointReported, value);
          formRef.setFieldValue('actualPrimaryCompletionDate', date, false);
          //Futuristic check
          if (dateRangeCheck(date, new Date(), true)) {
            formRef.setFieldError(
              'actualPrimaryCompletionDate',
              'Actual dates cannot be futuristic',
            );
          } else {
            formRef.setFieldError('actualPrimaryCompletionDate', '');
          }
          formRef.setFieldValue('actualTimeToPublish', value, false);
          formRef.setFieldError('actualTimeToPublish', '');
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          formFields.anticipatedPrimaryCompletionDate &&
          formFields.anticipatedPrimaryEndpointReported
        ) {
          const duration = convertToDuration(
            formFields.anticipatedPrimaryCompletionDate,
            formFields.anticipatedPrimaryEndpointReported,
          );
          if (parseFloat(value) == duration || value == Math.round(duration)) {
            formRef.setFieldValue('actualTimeToPublish', value, false);
            formRef.setFieldError('actualTimeToPublish', '');
          } else {
            formRef.setFieldValue('actualTimeToPublish', value, false);
            formRef.setFieldError('actualTimeToPublish', timingConstants.HIGHLITER_MESSAGE);
          }
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          formFields.anticipatedPrimaryCompletionDate &&
          !formFields.anticipatedPrimaryEndpointReported
        ) {
          const date = convertToDate(formFields.anticipatedPrimaryCompletionDate, value);
          formRef.setFieldValue('anticipatedPrimaryEndpointReported', date, false);
          formRef.setFieldValue('actualTimeToPublish', value, false);
          formRef.setFieldError('actualTimeToPublish', '');
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          !formFields.anticipatedPrimaryCompletionDate &&
          formFields.anticipatedPrimaryEndpointReported
        ) {
          const date = convertToDateBackward(formFields.anticipatedPrimaryEndpointReported, value);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', date, false);
          formRef.setFieldValue('actualTimeToPublish', value, false);
          formRef.setFieldError('actualTimeToPublish', '');
        }
        //default
        // formRef.setFieldValue('actualEnrollmentDuration', value);
      } else {
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          !formFields.anticipatedPrimaryCompletionDate &&
          !formFields.anticipatedPrimaryEndpointReported
        ) {
          formRef.setFieldValue('actualTimeToPublish', value);
        }
        if (formFields.actualPrimaryCompletionDate && formFields.actualPrimaryEndpointReported) {
          const duration = convertToDuration(
            formFields.actualPrimaryCompletionDate,
            formFields.actualPrimaryEndpointReported,
          );
          formRef.setFieldValue('actualTimeToPublish', duration >= 0 ? duration : '', false);
          formRef.setFieldError('actualTimeToPublish', '');
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          formFields.anticipatedPrimaryCompletionDate &&
          formFields.anticipatedPrimaryEndpointReported
        ) {
          const duration = convertToDuration(
            formFields.anticipatedPrimaryCompletionDate,
            formFields.anticipatedPrimaryEndpointReported,
          );
          formRef.setFieldValue('anticipatedTimeToPublish', duration >= 0 ? duration : '', false);
          formRef.setFieldValue('actualTimeToPublish', value, false);
          formRef.setFieldError('anticipatedTimeToPublish', '');
          formRef.setFieldError('actualTimeToPublish', '');
        }
      }
      break;

    /******* Anticipate ********/

    case 'anticipatedStartDate':
      if (value) {
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          formFields.actualEnrollmentDuration &&
          !formRef.errors.actualEnrollmentDuration &&
          !formFields.anticipatedEnrollmentCloseDate
        ) {
          let date = convertToDate(value, formFields.actualEnrollmentDuration);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', date);
          formRef.setFieldValue('anticipatedStartDate', value);
        }
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          formFields.actualEnrollmentDuration &&
          formFields.anticipatedEnrollmentCloseDate &&
          formFields.anticipatedStartDate
        ) {
          formRef.setFieldError('actualEnrollmentDuration', timingConstants.HIGHLITER_MESSAGE);
          formRef.setFieldValue('anticipatedStartDate', value, false);
        }
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualEnrollmentDuration &&
          formFields.anticipatedEnrollmentDuration &&
          !formFields.anticipatedEnrollmentCloseDate
        ) {
          let date = convertToDate(value, formFields.anticipatedEnrollmentDuration);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', date);
          formRef.setFieldValue('anticipatedStartDate', value);
        }
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualEnrollmentDuration &&
          formFields.anticipatedEnrollmentCloseDate &&
          !formRef.errors.anticipatedEnrollmentDuration
        ) {
          let duration = convertToDuration(value, formFields.anticipatedEnrollmentCloseDate);
          formRef.setFieldValue(
            'anticipatedEnrollmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          formRef.setFieldValue('anticipatedStartDate', value);
        }
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          formFields.actualEnrollmentDuration &&
          !formFields.anticipatedEnrollmentCloseDate
        ) {
          let date = convertToDate(value, formFields.actualEnrollmentDuration);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', date);
          formRef.setFieldValue('anticipatedStartDate', value);
        }
        if (
          !formFields.actualStartDate &&
          formFields.actualEnrollmentCloseDate &&
          !formFields.actualEnrollmentDuration &&
          !formRef.errors.anticipatedEnrollmentDuration
        ) {
          let duration = convertToDuration(value, formFields.actualEnrollmentCloseDate);
          formRef.setFieldValue(
            'anticipatedEnrollmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          formRef.setFieldValue('anticipatedStartDate', value);
        }
        //default
        formRef.setFieldValue('anticipatedStartDate', value, false);
      } else {
        if (
          !formFields.anticipatedEnrollmentDuration &&
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          formFields.actualEnrollmentDuration &&
          formFields.anticipatedEnrollmentCloseDate
        ) {
          if (formRef.errors.actualEnrollmentDuration) {
            let date = convertToDateBackward(
              formFields.anticipatedEnrollmentCloseDate,
              formFields.actualEnrollmentDuration,
            );
            formRef.setFieldValue('anticipatedStartDate', date, false);
            formRef.setFieldError('actualEnrollmentDuration', '');
          }
          if (!formRef.errors.actualEnrollmentDuration) {
            formRef.setFieldValue('anticipatedStartDate', null, false);
            formRef.setFieldError('anticipatedStartDate', '');
            formRef.setFieldValue('actualEnrollmentDuration', '', false);
          }
        }
        if (
          formFields.anticipatedEnrollmentDuration &&
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          formFields.actualEnrollmentDuration &&
          formFields.anticipatedEnrollmentCloseDate
        ) {
          if (formRef.errors.actualEnrollmentDuration) {
            let date = convertToDateBackward(
              formFields.anticipatedEnrollmentCloseDate,
              formFields.actualEnrollmentDuration,
            );
            formRef.setFieldValue('anticipatedStartDate', date, false);
            formRef.setFieldError('actualEnrollmentDuration', '');
          }
          if (!formRef.errors.actualEnrollmentDuration) {
            let date = convertToDateBackward(
              formFields.anticipatedEnrollmentCloseDate,
              formFields.anticipatedEnrollmentDuration,
            );
            formRef.setFieldValue('anticipatedStartDate', date, false);
            formRef.setFieldError('anticipatedEnrollmentDuration', '');
            formRef.setFieldValue('actualEnrollmentDuration', '', false);
          }
        }
        if (
          formFields.anticipatedEnrollmentDuration &&
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualEnrollmentDuration &&
          formFields.anticipatedEnrollmentCloseDate
        ) {
          if (formRef.errors.anticipatedEnrollmentDuration) {
            let date = convertToDateBackward(
              formFields.anticipatedEnrollmentCloseDate,
              formFields.anticipatedEnrollmentDuration,
            );
            formRef.setFieldValue('anticipatedStartDate', date, false);
            formRef.setFieldError('anticipatedEnrollmentDuration', '');
          }
          if (!formRef.errors.anticipatedEnrollmentDuration) {
            formRef.setFieldValue('anticipatedStartDate', null, false);
            formRef.setFieldError('anticipatedStartDate', '');
            formRef.setFieldError('anticipatedEnrollmentDuration', '');
            formRef.setFieldValue('anticipatedEnrollmentDuration', '', false);
          }
        }
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualEnrollmentDuration &&
          formFields.anticipatedEnrollmentCloseDate &&
          !dateRangeCheck(
            formFields.anticipatedEnrollmentCloseDate,
            formFields.anticipatedStartDate,
          )
        ) {
          formRef.setFieldValue('anticipatedStartDate', null, false);
          formRef.setFieldError('anticipatedStartDate', '');
          formRef.setFieldError('anticipatedEnrollmentDuration', '');
          formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
          formRef.setFieldValue('anticipatedEnrollmentDuration', '', false);
        }
        if (
          !formFields.actualStartDate &&
          formFields.actualEnrollmentCloseDate &&
          formFields.anticipatedEnrollmentDuration &&
          !formFields.actualEnrollmentDuration
        ) {
          if (formRef.errors.anticipatedEnrollmentDuration) {
            let date = convertToDateBackward(
              formFields.actualEnrollmentCloseDate,
              formFields.anticipatedEnrollmentDuration,
            );
            formRef.setFieldValue('anticipatedStartDate', date, false);
            formRef.setFieldError('anticipatedEnrollmentDuration', '');
          }
          if (!formRef.errors.anticipatedEnrollmentDuration) {
            formRef.setFieldValue('anticipatedStartDate', null, false);
            formRef.setFieldError('anticipatedStartDate', '');
            formRef.setFieldValue('anticipatedEnrollmentDuration', '', false);
          }
        }
        if (
          !formFields.anticipatedEnrollmentDuration &&
          !formFields.actualStartDate &&
          !formFields.anticipatedEnrollmentCloseDate
        ) {
          formRef.setFieldValue('anticipatedStartDate', null, false);
          formRef.setFieldError('anticipatedStartDate', '');
          formRef.setFieldError('actualEnrollmentCloseDate', '');
        }
        if (formFields.actualStartDate) {
          formRef.setFieldValue('anticipatedStartDate', null, false);
          formRef.setFieldError('anticipatedStartDate', '');
        }
      }
      break;

    case 'anticipatedEnrollmentDuration':
      if (value) {
        if (formFields.actualEnrollmentDuration) {
          formRef.setFieldValue('anticipatedEnrollmentDuration', value, false);

          formRef.setFieldError('anticipatedEnrollmentDuration', timingConstants.HIGHLITER_MESSAGE);
        } else {
          if (
            !formFields.actualStartDate &&
            formFields.actualEnrollmentCloseDate &&
            formFields.anticipatedStartDate
          ) {
            const duration = convertToDuration(
              formFields.anticipatedStartDate,
              formFields.actualEnrollmentCloseDate,
            );
            if (parseFloat(value) == duration || value == Math.round(duration)) {
              formRef.setFieldValue('anticipatedEnrollmentDuration', value, false);
              formRef.setFieldError('anticipatedEnrollmentDuration', '');
            } else {
              formRef.setFieldValue('anticipatedEnrollmentDuration', value, false);

              formRef.setFieldError(
                'anticipatedEnrollmentDuration',
                timingConstants.HIGHLITER_MESSAGE,
              );
            }
          }
          if (
            formFields.actualStartDate &&
            !formFields.actualEnrollmentCloseDate &&
            formFields.anticipatedEnrollmentCloseDate
          ) {
            const duration = convertToDuration(
              formFields.actualStartDate,
              formFields.anticipatedEnrollmentCloseDate,
            );
            if (parseFloat(value) == duration || value == Math.round(duration)) {
              formRef.setFieldValue('anticipatedEnrollmentDuration', value, false);
              formRef.setFieldError('anticipatedEnrollmentDuration', '');
            } else {
              formRef.setFieldValue('anticipatedEnrollmentDuration', value, false);

              formRef.setFieldError(
                'anticipatedEnrollmentDuration',
                timingConstants.HIGHLITER_MESSAGE,
              );
            }
          }
          if (
            !formFields.actualStartDate &&
            !formFields.actualEnrollmentCloseDate &&
            formFields.anticipatedEnrollmentCloseDate &&
            formFields.anticipatedStartDate
          ) {
            const duration = convertToDuration(
              formFields.anticipatedStartDate,
              formFields.anticipatedEnrollmentCloseDate,
            );
            if (parseFloat(value) == duration || value == Math.round(duration)) {
              formRef.setFieldValue('anticipatedEnrollmentDuration', value, false);
              formRef.setFieldError('anticipatedEnrollmentDuration', '');
            } else {
              formRef.setFieldValue('anticipatedEnrollmentDuration', value, false);

              formRef.setFieldError(
                'anticipatedEnrollmentDuration',
                timingConstants.HIGHLITER_MESSAGE,
              );
            }
          }
          if (
            !formFields.actualStartDate &&
            !formFields.actualEnrollmentCloseDate &&
            formFields.anticipatedEnrollmentCloseDate &&
            !formFields.anticipatedStartDate
          ) {
            const date = convertToDateBackward(formFields.anticipatedEnrollmentCloseDate, value);
            formRef.setFieldValue('anticipatedStartDate', date, false);
            formRef.setFieldValue('anticipatedEnrollmentDuration', value, false);
          }
          if (
            !formFields.actualStartDate &&
            !formFields.actualEnrollmentCloseDate &&
            !formFields.anticipatedEnrollmentCloseDate &&
            formFields.anticipatedStartDate
          ) {
            const date = convertToDate(formFields.anticipatedStartDate, value);
            formRef.setFieldValue('anticipatedEnrollmentCloseDate', date, false);
            formRef.setFieldValue('anticipatedEnrollmentDuration', value, false);
          }
          if (
            formFields.actualStartDate &&
            !formFields.actualEnrollmentCloseDate &&
            !formFields.anticipatedEnrollmentCloseDate
          ) {
            const date = convertToDate(formFields.actualStartDate, value);
            formRef.setFieldValue('anticipatedEnrollmentCloseDate', date, false);
            formRef.setFieldValue('anticipatedEnrollmentDuration', value, false);
          }
          if (
            !formFields.actualStartDate &&
            formFields.actualEnrollmentCloseDate &&
            !formFields.anticipatedStartDate
          ) {
            const date = convertToDateBackward(formFields.actualEnrollmentCloseDate, value);
            formRef.setFieldValue('anticipatedStartDate', date, false);
            formRef.setFieldValue('anticipatedEnrollmentDuration', value, false);
          }
        }
      } else {
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          !formFields.anticipatedStartDate &&
          !formFields.anticipatedEnrollmentCloseDate
        ) {
          formRef.setFieldValue('anticipatedEnrollmentDuration', '', false);
          formRef.setFieldError('anticipatedEnrollmentDuration', '');
        }
        if (
          !formFields.actualStartDate &&
          formFields.actualEnrollmentCloseDate &&
          formFields.anticipatedStartDate
        ) {
          const duration = convertToDuration(
            formFields.anticipatedStartDate,
            formFields.actualEnrollmentCloseDate,
          );

          formRef.setFieldValue(
            'anticipatedEnrollmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          formRef.setFieldError('anticipatedEnrollmentDuration', '');
        }
        if (
          formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          formFields.anticipatedEnrollmentCloseDate
        ) {
          const duration = convertToDuration(
            formFields.actualStartDate,
            formFields.anticipatedEnrollmentCloseDate,
          );
          formRef.setFieldValue(
            'anticipatedEnrollmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          formRef.setFieldError('anticipatedEnrollmentDuration', '');
        }
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          formFields.anticipatedEnrollmentCloseDate &&
          formFields.anticipatedStartDate
        ) {
          const duration = convertToDuration(
            formFields.anticipatedStartDate,
            formFields.anticipatedEnrollmentCloseDate,
          );
          formRef.setFieldValue(
            'anticipatedEnrollmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          formRef.setFieldError('anticipatedEnrollmentDuration', '');
        }
      }
      break;

    case 'anticipatedEnrollmentCloseDate':
      if (value) {
        // First block related calculations

        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          formFields.actualEnrollmentDuration &&
          !formRef.errors.actualEnrollmentDuration &&
          !formFields.anticipatedStartDate
        ) {
          let date = convertToDateBackward(value, formFields.actualEnrollmentDuration);
          formRef.setFieldValue('anticipatedStartDate', date);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', value);
        }
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          formFields.actualEnrollmentDuration &&
          formFields.anticipatedEnrollmentCloseDate &&
          formFields.anticipatedStartDate
        ) {
          formRef.setFieldError('actualEnrollmentDuration', timingConstants.HIGHLITER_MESSAGE);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', value, false);
        }
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualEnrollmentDuration &&
          formFields.anticipatedStartDate &&
          !formRef.errors.anticipatedEnrollmentDuration
        ) {
          let duration = convertToDuration(formFields.anticipatedStartDate, value);
          formRef.setFieldValue(
            'anticipatedEnrollmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', value);
        }
        if (
          formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualEnrollmentDuration
        ) {
          if (
            formFields.anticipatedEnrollmentDuration &&
            !formRef.errors.anticipatedEnrollmentDuration
          ) {
            let duration = convertToDuration(formFields.actualStartDate, value);
            formRef.setFieldValue(
              'anticipatedEnrollmentDuration',
              duration >= 0 ? duration : '',
              false,
            );
            if (dateRangeCheck(value, formFields.actualStartDate)) {
              formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
            } else {
              formRef.setFieldError(
                'anticipatedEnrollmentCloseDate',
                'Anticipated Enrollment close date must be equal or later than actual start date',
              );
            }
            formRef.setFieldValue('anticipatedEnrollmentCloseDate', value, false);
          }
          if (!formFields.anticipatedEnrollmentDuration) {
            let duration = convertToDuration(formFields.actualStartDate, value);
            formRef.setFieldValue(
              'anticipatedEnrollmentDuration',
              duration >= 0 ? duration : '',
              false,
            );
            if (dateRangeCheck(value, formFields.actualStartDate)) {
              formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
            } else {
              formRef.setFieldError(
                'anticipatedEnrollmentCloseDate',
                'Anticipated Enrollment close date must be equal or later than actual start date',
              );
            }
            formRef.setFieldValue('anticipatedEnrollmentCloseDate', value, false);
          }
        }
        if (
          !formFields.actualStartDate &&
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualEnrollmentDuration &&
          !formFields.anticipatedStartDate &&
          !formRef.errors.anticipatedEnrollmentDuration &&
          formFields.anticipatedEnrollmentDuration
        ) {
          let date = convertToDateBackward(value, formFields.anticipatedEnrollmentDuration);
          formRef.setFieldValue('anticipatedStartDate', date);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', value);
        }

        // Second block related calculations

        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          formFields.actualTreatmentDuration &&
          !formRef.errors.actualTreatmentDuration &&
          !formFields.anticipatedPrimaryCompletionDate
        ) {
          let date = convertToDate(value, formFields.actualTreatmentDuration);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', date);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', value);
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          formFields.actualTreatmentDuration &&
          formFields.anticipatedPrimaryCompletionDate &&
          formFields.anticipatedEnrollmentCloseDate
        ) {
          formRef.setFieldError('actualTreatmentDuration', timingConstants.HIGHLITER_MESSAGE);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', value, false);
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualTreatmentDuration &&
          formFields.anticipatedTreatmentDuration &&
          !formFields.anticipatedPrimaryCompletionDate
        ) {
          let date = convertToDate(value, formFields.anticipatedTreatmentDuration);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', date);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', value);
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualTreatmentDuration &&
          formFields.anticipatedPrimaryCompletionDate &&
          !formRef.errors.anticipatedTreatmentDuration
        ) {
          let duration = convertToDuration(value, formFields.anticipatedPrimaryCompletionDate);
          formRef.setFieldValue(
            'anticipatedTreatmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', value);
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          formFields.actualTreatmentDuration &&
          !formFields.anticipatedPrimaryCompletionDate
        ) {
          let date = convertToDate(value, formFields.actualTreatmentDuration);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', date);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', value);
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          formFields.actualPrimaryCompletionDate &&
          !formFields.actualTreatmentDuration &&
          !formRef.errors.anticipatedTreatmentDuration
        ) {
          let duration = convertToDuration(value, formFields.actualPrimaryCompletionDate);
          formRef.setFieldValue(
            'anticipatedTreatmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          if (dateRangeCheck(formFields.actualPrimaryCompletionDate, value)) {
            formRef.setFieldError('actualPrimaryCompletionDate', '');
          } else {
            formRef.setFieldError(
              'actualPrimaryCompletionDate',
              'Actual Primary completion date must be equal or later than the anticipated enrollment close date',
            );
          }
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', value, false);
        }
        //default
        formRef.setFieldValue('anticipatedEnrollmentCloseDate', value, false);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (formFields.actualEnrollmentCloseDate) {
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', null, false);
          formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
          // if (!formRef.errors.anticipatedEnrollmentDuration) {
          //   formRef.setFieldValue('anticipatedEnrollmentDuration', '', false);
          // }
          // if (!formRef.errors.anticipatedTreatmentDuration) {
          //   formRef.setFieldValue('anticipatedTreatmentDuration', '', false);
          // }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (
            !formRef.errors.anticipatedTreatmentDuration &&
            !formRef.errors.anticipatedEnrollmentDuration
          ) {
            formRef.setFieldValue('anticipatedEnrollmentCloseDate', null, false);
            formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
            formRef.setFieldValue('anticipatedEnrollmentDuration', '', false);
            formRef.setFieldValue('anticipatedTreatmentDuration', '', false);
          }

          if (formRef.errors.anticipatedEnrollmentDuration) {
            const conditionalDate = formFields.actualStartDate
              ? formFields.actualStartDate
              : formFields.anticipatedStartDate;
            if (conditionalDate) {
              const date = convertToDate(conditionalDate, formFields.anticipatedEnrollmentDuration);
              formRef.setFieldValue('anticipatedEnrollmentCloseDate', date, false);
              formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
              formRef.setFieldError('anticipatedEnrollmentDuration', '');
            }
          }
          if (
            !formRef.errors.anticipatedEnrollmentDuration &&
            formRef.errors.anticipatedTreatmentDuration
          ) {
            let conditionalDate = null;
            if (completed || terminated) {
              conditionalDate = formFields.actualPrimaryCompletionDate
                ? formFields.actualPrimaryCompletionDate
                : formFields.anticipatedPrimaryCompletionDate;
            } else {
              conditionalDate = formFields.anticipatedPrimaryCompletionDate;
            }
            const date = convertToDateBackward(
              conditionalDate,
              formFields.anticipatedTreatmentDuration,
            );
            const conditionalStartDate = formFields.actualStartDate
              ? formFields.actualStartDate
              : formFields.anticipatedStartDate;
            if (conditionalStartDate) {
              const duration = convertToDuration(conditionalStartDate, date);
              formRef.setFieldValue(
                'anticipatedEnrollmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
              formRef.setFieldValue('anticipatedEnrollmentCloseDate', date, false);
              if (dateRangeCheck(date, conditionalStartDate)) {
                formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'anticipatedEnrollmentCloseDate',
                  `Anticipated Enrollment close date must be equal or later than ${formFields.actualStartDate ? 'actual' : 'anticipated'
                  }  start date`,
                );
              }
            }
          }
          if (!formFields.actualStartDate && formFields.actualEnrollmentDuration) {
            formRef.setFieldValue('anticipatedEnrollmentCloseDate', null, false);
            formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
            formRef.setFieldValue('actualEnrollmentDuration', '', false);
            formRef.setFieldError('actualEnrollmentDuration', '');
          }
        }
      }
      break;

    case 'anticipatedTreatmentDuration':
      if (value) {
        if (formFields.actualTreatmentDuration) {
          formRef.setFieldValue('anticipatedTreatmentDuration', value, false);

          formRef.setFieldError('anticipatedTreatmentDuration', timingConstants.HIGHLITER_MESSAGE);
        } else {
          if (completed || terminated) {
            if (
              !formFields.actualEnrollmentCloseDate &&
              formFields.actualPrimaryCompletionDate &&
              formFields.anticipatedEnrollmentCloseDate
            ) {
              const duration = convertToDuration(
                formFields.anticipatedEnrollmentCloseDate,
                formFields.actualPrimaryCompletionDate,
              );
              if (parseFloat(value) == duration || value == Math.round(duration)) {
                formRef.setFieldValue('anticipatedTreatmentDuration', value, false);
                formRef.setFieldError('anticipatedTreatmentDuration', '');
              } else {
                formRef.setFieldValue('anticipatedTreatmentDuration', value, false);

                formRef.setFieldError(
                  'anticipatedTreatmentDuration',
                  timingConstants.HIGHLITER_MESSAGE,
                );
              }
            }
          }
          if (
            (formFields.actualEnrollmentCloseDate &&
              !formFields.actualPrimaryCompletionDate &&
              formFields.anticipatedPrimaryCompletionDate &&
              (completed || terminated)) ||
            ((planned || open || closed || temporarilyClosed) &&
              formFields.actualEnrollmentCloseDate &&
              formFields.anticipatedPrimaryCompletionDate)
          ) {
            const duration = convertToDuration(
              formFields.actualEnrollmentCloseDate,
              formFields.anticipatedPrimaryCompletionDate,
            );
            if (parseFloat(value) == duration || value == Math.round(duration)) {
              formRef.setFieldValue('anticipatedTreatmentDuration', value, false);
              formRef.setFieldError('anticipatedTreatmentDuration', '');
            } else {
              formRef.setFieldValue('anticipatedTreatmentDuration', value, false);

              formRef.setFieldError(
                'anticipatedTreatmentDuration',
                timingConstants.HIGHLITER_MESSAGE,
              );
            }
          }
          if (
            (!formFields.actualEnrollmentCloseDate &&
              !formFields.actualPrimaryCompletionDate &&
              formFields.anticipatedPrimaryCompletionDate &&
              formFields.anticipatedEnrollmentCloseDate &&
              (completed || terminated)) ||
            (!formFields.actualEnrollmentCloseDate &&
              formFields.anticipatedPrimaryCompletionDate &&
              formFields.anticipatedEnrollmentCloseDate &&
              (planned || open || closed || temporarilyClosed))
          ) {
            const duration = convertToDuration(
              formFields.anticipatedEnrollmentCloseDate,
              formFields.anticipatedPrimaryCompletionDate,
            );
            if (parseFloat(value) == duration || value == Math.round(duration)) {
              formRef.setFieldValue('anticipatedTreatmentDuration', value, false);
              formRef.setFieldError('anticipatedTreatmentDuration', '');
            } else {
              formRef.setFieldValue('anticipatedTreatmentDuration', value, false);

              formRef.setFieldError(
                'anticipatedTreatmentDuration',
                timingConstants.HIGHLITER_MESSAGE,
              );
            }
          }
          if (
            !formFields.actualEnrollmentCloseDate &&
            formFields.anticipatedPrimaryCompletionDate &&
            !formFields.anticipatedEnrollmentCloseDate
          ) {
            const date = convertToDateBackward(formFields.anticipatedPrimaryCompletionDate, value);
            formRef.setFieldValue('anticipatedEnrollmentCloseDate', date, false);
            formRef.setFieldValue('anticipatedTreatmentDuration', value, false);

            //Chain Calculations
            if (!formFields.actualEnrollmentCloseDate && formFields.actualStartDate) {
              if (dateRangeCheck(date, formFields.actualStartDate)) {
                formRef.setFieldError('anticipatedEnrollmentCloseDate', '');
              } else {
                formRef.setFieldError(
                  'anticipatedEnrollmentCloseDate',
                  'Anticipated Enrollment close date must be equal or later than actual  start date',
                );
              }
              const duration = convertToDuration(formFields.actualStartDate, date);
              formRef.setFieldValue(
                'anticipatedEnrollmentDuration',
                duration >= 0 ? duration : '',
                false,
              );
            }
          }
          if (
            (!formFields.actualEnrollmentCloseDate &&
              !formFields.actualPrimaryCompletionDate &&
              !formFields.anticipatedPrimaryCompletionDate &&
              formFields.anticipatedEnrollmentCloseDate &&
              (completed || terminated)) ||
            (!formFields.actualEnrollmentCloseDate &&
              !formFields.anticipatedPrimaryCompletionDate &&
              formFields.anticipatedEnrollmentCloseDate &&
              (planned || open || closed || temporarilyClosed))
          ) {
            const date = convertToDate(formFields.anticipatedEnrollmentCloseDate, value);
            formRef.setFieldValue('anticipatedPrimaryCompletionDate', date, false);
            formRef.setFieldValue('anticipatedTreatmentDuration', value, false);
          }
          if (
            formFields.actualEnrollmentCloseDate &&
            !formFields.actualPrimaryCompletionDate &&
            !formFields.anticipatedPrimaryCompletionDate
          ) {
            const date = convertToDate(formFields.actualEnrollmentCloseDate, value);
            formRef.setFieldValue('anticipatedPrimaryCompletionDate', date, false);
            formRef.setFieldValue('anticipatedTreatmentDuration', value, false);
          }
          if (
            !formFields.actualEnrollmentCloseDate &&
            formFields.actualPrimaryCompletionDate &&
            !formFields.anticipatedEnrollmentCloseDate
          ) {
            const date = convertToDateBackward(formFields.actualPrimaryCompletionDate, value);
            formRef.setFieldValue('anticipatedEnrollmentCloseDate', date, false);
            formRef.setFieldValue('anticipatedTreatmentDuration', value, false);
          }
        }
      } else {
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          !formFields.anticipatedEnrollmentCloseDate &&
          !formFields.anticipatedPrimaryCompletionDate
        ) {
          formRef.setFieldValue('anticipatedTreatmentDuration', '', false);
          formRef.setFieldError('anticipatedTreatmentDuration', '');
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          formFields.actualPrimaryCompletionDate &&
          formFields.anticipatedEnrollmentCloseDate
        ) {
          const duration = convertToDuration(
            formFields.anticipatedEnrollmentCloseDate,
            formFields.actualPrimaryCompletionDate,
          );

          formRef.setFieldValue(
            'anticipatedTreatmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          formRef.setFieldError('anticipatedTreatmentDuration', '');
        }
        if (
          formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          formFields.anticipatedPrimaryCompletionDate
        ) {
          const duration = convertToDuration(
            formFields.actualEnrollmentCloseDate,
            formFields.anticipatedPrimaryCompletionDate,
          );
          formRef.setFieldValue(
            'anticipatedTreatmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          formRef.setFieldError('anticipatedTreatmentDuration', '');
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          formFields.anticipatedPrimaryCompletionDate &&
          formFields.anticipatedEnrollmentCloseDate
        ) {
          const duration = convertToDuration(
            formFields.anticipatedEnrollmentCloseDate,
            formFields.anticipatedPrimaryCompletionDate,
          );
          formRef.setFieldValue(
            'anticipatedTreatmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          formRef.setFieldError('anticipatedTreatmentDuration', '');
        }
      }
      break;

    case 'anticipatedTimeToPublish':
      if (value) {
        if (formFields.actualTimeToPublish) {
          formRef.setFieldValue('anticipatedTimeToPublish', value, false);

          formRef.setFieldError('anticipatedTimeToPublish', timingConstants.HIGHLITER_MESSAGE);
        } else {
          if (
            !formFields.actualPrimaryCompletionDate &&
            formFields.actualPrimaryEndpointReported &&
            formFields.anticipatedPrimaryCompletionDate
          ) {
            const duration = convertToDuration(
              formFields.anticipatedPrimaryCompletionDate,
              formFields.actualPrimaryEndpointReported,
            );
            if (parseFloat(value) == duration || value == Math.round(duration)) {
              formRef.setFieldValue('anticipatedTimeToPublish', value, false);
              formRef.setFieldError('anticipatedTimeToPublish', '');
            } else {
              formRef.setFieldValue('anticipatedTimeToPublish', value, false);

              formRef.setFieldError('anticipatedTimeToPublish', timingConstants.HIGHLITER_MESSAGE);
            }
          }
          if (
            formFields.actualPrimaryCompletionDate &&
            !formFields.actualPrimaryEndpointReported &&
            formFields.anticipatedPrimaryEndpointReported
          ) {
            const duration = convertToDuration(
              formFields.actualPrimaryCompletionDate,
              formFields.anticipatedPrimaryEndpointReported,
            );
            if (parseFloat(value) == duration || value == Math.round(duration)) {
              formRef.setFieldValue('anticipatedTimeToPublish', value, false);
              formRef.setFieldError('anticipatedTimeToPublish', '');
            } else {
              formRef.setFieldValue('anticipatedTimeToPublish', value, false);

              formRef.setFieldError('anticipatedTimeToPublish', timingConstants.HIGHLITER_MESSAGE);
            }
          }
          if (
            !formFields.actualPrimaryCompletionDate &&
            !formFields.actualPrimaryEndpointReported &&
            formFields.anticipatedPrimaryEndpointReported &&
            formFields.anticipatedPrimaryCompletionDate
          ) {
            const duration = convertToDuration(
              formFields.anticipatedPrimaryCompletionDate,
              formFields.anticipatedPrimaryEndpointReported,
            );
            if (parseFloat(value) == duration || value == Math.round(duration)) {
              formRef.setFieldValue('anticipatedTimeToPublish', value, false);
              formRef.setFieldError('anticipatedTimeToPublish', '');
            } else {
              formRef.setFieldValue('anticipatedTimeToPublish', value, false);

              formRef.setFieldError('anticipatedTimeToPublish', timingConstants.HIGHLITER_MESSAGE);
            }
          }
          if (
            !formFields.actualPrimaryCompletionDate &&
            !formFields.actualPrimaryEndpointReported &&
            formFields.anticipatedPrimaryEndpointReported &&
            !formFields.anticipatedPrimaryCompletionDate
          ) {
            const date = convertToDateBackward(
              formFields.anticipatedPrimaryEndpointReported,
              value,
            );
            formRef.setFieldValue('anticipatedPrimaryCompletionDate', date, false);
            formRef.setFieldValue('anticipatedTimeToPublish', value, false);
          }
          if (
            !formFields.actualPrimaryCompletionDate &&
            !formFields.actualPrimaryEndpointReported &&
            !formFields.anticipatedPrimaryEndpointReported &&
            formFields.anticipatedPrimaryCompletionDate
          ) {
            const date = convertToDate(formFields.anticipatedPrimaryCompletionDate, value);
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', date, false);
            formRef.setFieldValue('anticipatedTimeToPublish', value, false);
          }
          if (
            formFields.actualPrimaryCompletionDate &&
            !formFields.actualPrimaryEndpointReported &&
            !formFields.anticipatedPrimaryEndpointReported
          ) {
            const date = convertToDate(formFields.actualPrimaryCompletionDate, value);
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', date, false);
            formRef.setFieldValue('anticipatedTimeToPublish', value, false);
          }
          if (
            !formFields.actualPrimaryCompletionDate &&
            formFields.actualPrimaryEndpointReported &&
            !formFields.anticipatedPrimaryCompletionDate
          ) {
            const date = convertToDateBackward(formFields.actualPrimaryEndpointReported, value);
            formRef.setFieldValue('anticipatedPrimaryCompletionDate', date, false);
            formRef.setFieldValue('anticipatedTimeToPublish', value, false);
          }
        }
      } else {
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          !formFields.anticipatedPrimaryCompletionDate &&
          !formFields.anticipatedPrimaryEndpointReported
        ) {
          formRef.setFieldValue('anticipatedTimeToPublish', '', false);
          formRef.setFieldError('anticipatedTimeToPublish', '');
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          formFields.actualPrimaryEndpointReported &&
          formFields.anticipatedPrimaryCompletionDate
        ) {
          const duration = convertToDuration(
            formFields.anticipatedPrimaryCompletionDate,
            formFields.actualPrimaryEndpointReported,
          );

          formRef.setFieldValue('anticipatedTimeToPublish', duration >= 0 ? duration : '', false);
          formRef.setFieldError('anticipatedTimeToPublish', '');
        }
        if (
          formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          formFields.anticipatedPrimaryEndpointReported
        ) {
          const duration = convertToDuration(
            formFields.actualPrimaryCompletionDate,
            formFields.anticipatedPrimaryEndpointReported,
          );
          formRef.setFieldValue('anticipatedTimeToPublish', duration >= 0 ? duration : '', false);
          formRef.setFieldError('anticipatedTimeToPublish', '');
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          formFields.anticipatedPrimaryEndpointReported &&
          formFields.anticipatedPrimaryCompletionDate
        ) {
          const duration = convertToDuration(
            formFields.anticipatedPrimaryCompletionDate,
            formFields.anticipatedPrimaryEndpointReported,
          );
          formRef.setFieldValue('anticipatedTimeToPublish', duration >= 0 ? duration : '', false);
          formRef.setFieldError('anticipatedTimeToPublish', '');
        }
      }
      break;

    case 'anticipatedPrimaryCompletionDate':
      if (value) {
        // Second block related calculations
        if (
          !trialStatus.toLowerCase() === 'completed' &&
          !trialStatus.toLowerCase() === 'terminated'
        ) {
          if (
            formFields.actualEnrollmentCloseDate &&
            !formFields.anticipatedTreatmentDuration &&
            !formFields.anticipatedEnrollmentCloseDate &&
            formFields.value.actualTreatmentDuration &&
            !formRef.errors.actualTreatmentDuration
          ) {
            let duration = convertToDuration(formFields.actualEnrollmentCloseDate, value);
            formRef.setFieldValue('actualTreatmentDuration', duration >= 0 ? duration : '', false);
            formRef.setFieldValue('anticipatedPrimaryCompletionDate', value);
          }
        }

        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          formFields.actualTreatmentDuration &&
          !formRef.errors.actualTreatmentDuration &&
          !formFields.anticipatedEnrollmentCloseDate
        ) {
          let date = convertToDateBackward(value, formFields.actualTreatmentDuration);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', date);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', value);
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          formFields.actualTreatmentDuration &&
          formFields.anticipatedPrimaryCompletionDate &&
          formFields.anticipatedEnrollmentCloseDate
        ) {
          formRef.setFieldError('actualTreatmentDuration', timingConstants.HIGHLITER_MESSAGE);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', value, false);
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualTreatmentDuration &&
          formFields.anticipatedEnrollmentCloseDate &&
          !formRef.errors.anticipatedTreatmentDuration
        ) {
          let duration = convertToDuration(formFields.anticipatedEnrollmentCloseDate, value);
          formRef.setFieldValue(
            'anticipatedTreatmentDuration',
            duration >= 0 ? duration : '',
            false,
          );
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', value);
        }

        if (
          formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualTreatmentDuration
        ) {
          if (
            formFields.anticipatedTreatmentDuration &&
            !formRef.errors.anticipatedTreatmentDuration
          ) {
            let duration = convertToDuration(formFields.actualEnrollmentCloseDate, value);
            formRef.setFieldValue(
              'anticipatedTreatmentDuration',
              duration >= 0 ? duration : '',
              false,
            );
            formRef.setFieldValue('anticipatedPrimaryCompletionDate', value, false);
            if (dateRangeCheck(value, formFields.actualEnrollmentCloseDate)) {
              formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
            } else {
              formRef.setFieldError(
                'anticipatedPrimaryCompletionDate',
                'Anticipated Primary completion date must be equal or later than the actual enrollment close date',
              );
            }
          }
          if (!formFields.anticipatedTreatmentDuration) {
            let duration = convertToDuration(formFields.actualEnrollmentCloseDate, value);
            formRef.setFieldValue(
              'anticipatedTreatmentDuration',
              duration >= 0 ? duration : '',
              false,
            );
            if (dateRangeCheck(value, formFields.actualEnrollmentCloseDate)) {
              formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
            } else {
              formRef.setFieldError(
                'anticipatedPrimaryCompletionDate',
                'Anticipated Primary completion date must be equal or later than the actual enrollment close date',
              );
            }
            formRef.setFieldValue('anticipatedPrimaryCompletionDate', value, false);
          }
        }
        if (
          !formFields.actualEnrollmentCloseDate &&
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualTreatmentDuration &&
          !formFields.anticipatedEnrollmentCloseDate &&
          !formRef.errors.anticipatedTreatmentDuration &&
          formFields.anticipatedTreatmentDuration
        ) {
          let date = convertToDateBackward(value, formFields.anticipatedTreatmentDuration);
          formRef.setFieldValue('anticipatedEnrollmentCloseDate', date);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', value);
        }

        // Third block related calculations

        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          formFields.actualTimeToPublish &&
          !formRef.errors.actualTimeToPublish &&
          !formFields.anticipatedPrimaryEndpointReported
        ) {
          let date = convertToDate(value, formFields.actualTimeToPublish);
          formRef.setFieldValue('anticipatedPrimaryEndpointReported', date);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', value);
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          formFields.actualTimeToPublish &&
          formFields.anticipatedPrimaryEndpointReported &&
          formFields.anticipatedPrimaryCompletionDate
        ) {
          formRef.setFieldError('actualTimeToPublish', timingConstants.HIGHLITER_MESSAGE);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', value, false);
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          !formFields.actualTimeToPublish &&
          formFields.anticipatedTimeToPublish &&
          !formFields.anticipatedPrimaryEndpointReported
        ) {
          let date = convertToDate(value, formFields.anticipatedTimeToPublish);
          formRef.setFieldValue('anticipatedPrimaryEndpointReported', date);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', value);
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          !formFields.actualTimeToPublish &&
          formFields.anticipatedPrimaryEndpointReported &&
          !formRef.errors.anticipatedTimeToPublish
        ) {
          let duration = convertToDuration(value, formFields.anticipatedPrimaryEndpointReported);
          formRef.setFieldValue('anticipatedTimeToPublish', duration);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', value);
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          formFields.actualTimeToPublish &&
          !formFields.anticipatedPrimaryEndpointReported
        ) {
          let date = convertToDate(value, formFields.actualTimeToPublish);
          formRef.setFieldValue('anticipatedPrimaryEndpointReported', date);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', value);
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          formFields.actualPrimaryEndpointReported &&
          !formFields.actualTimeToPublish &&
          !formRef.errors.anticipatedTimeToPublish
        ) {
          let duration = convertToDuration(value, formFields.actualPrimaryEndpointReported);
          formRef.setFieldValue('anticipatedTimeToPublish', duration >= 0 ? duration : '', false);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', value, false);
          if (dateRangeCheck(formFields.actualPrimaryEndpointReported, value)) {
            formRef.setFieldError('actualPrimaryEndpointReported', '');
          } else {
            formRef.setFieldError(
              'actualPrimaryEndpointReported',
              'Actual Primary endpoint reported date must be equal or later than anticipated  primary completion date',
            );
          }
        }

        //default
        formRef.setFieldValue('anticipatedPrimaryCompletionDate', value, false);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (formFields.actualPrimaryCompletionDate && (completed || terminated)) {
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', null, false);
          formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
          // if (!formRef.errors.anticipatedTimeToPublish) {
          //   formRef.setFieldValue('anticipatedTimeToPublish', '', false);
          // }
          // if (!formRef.errors.anticipatedTreatmentDuration) {
          //   formRef.setFieldValue('anticipatedTreatmentDuration', '', false);
          // }
        } else {
          // eslint-disable-next-line keyword-spacing
          // eslint-disable-next-line no-lonely-if
          if (
            completed ||
            terminated ||
            ((planned || open || closed || temporarilyClosed) &&
              !formFields.actualTreatmentDuration)
          ) {
            if (
              !formRef.errors.anticipatedTreatmentDuration &&
              !formRef.errors.anticipatedTimeToPublish
            ) {
              formRef.setFieldValue('anticipatedPrimaryCompletionDate', null, false);
              formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
              formRef.setFieldValue('anticipatedTimeToPublish', '', false);
              formRef.setFieldValue('anticipatedTreatmentDuration', '', false);
            }

            if (formRef.errors.anticipatedTreatmentDuration) {
              const conditionalDate = formFields.actualEnrollmentCloseDate
                ? formFields.actualEnrollmentCloseDate
                : formFields.anticipatedEnrollmentCloseDate;
              if (conditionalDate) {
                const date = convertToDate(
                  conditionalDate,
                  formFields.anticipatedTreatmentDuration,
                );
                formRef.setFieldValue('anticipatedPrimaryCompletionDate', date, false);
                formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
                formRef.setFieldError('anticipatedTreatmentDuration', '');
              }
            }
            if (
              !formRef.errors.anticipatedTreatmentDuration &&
              formRef.errors.anticipatedTimeToPublish
            ) {
              let conditionalDate = null;
              conditionalDate = formFields.actualPrimaryEndpointReported
                ? formFields.actualPrimaryEndpointReported
                : formFields.anticipatedPrimaryEndpointReported;

              const date = convertToDateBackward(
                conditionalDate,
                formFields.anticipatedTimeToPublish,
              );
              const conditionalStartDate = formFields.actualEnrollmentCloseDate
                ? formFields.actualEnrollmentCloseDate
                : formFields.anticipatedEnrollmentCloseDate;
              if (conditionalStartDate) {
                const duration = convertToDuration(conditionalStartDate, date);
                formRef.setFieldValue(
                  'anticipatedTreatmentDuration',
                  duration >= 0 ? duration : '',
                  false,
                );
                formRef.setFieldValue('anticipatedPrimaryCompletionDate', date, false);
                if (dateRangeCheck(date, conditionalStartDate)) {
                  formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
                } else {
                  formRef.setFieldError(
                    'anticipatedPrimaryCompletionDate',
                    `Anticipated Primary Completion date must be equal or later than ${formFields.actualEnrollmentCloseDate ? 'actual' : 'anticipated'
                    }  enrollment close date`,
                  );
                }
              }
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (
              !formRef.errors.actualTreatmentDuration &&
              !formRef.errors.anticipatedTimeToPublish
            ) {
              formRef.setFieldValue('anticipatedPrimaryCompletionDate', null, false);
              formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
              formRef.setFieldValue('anticipatedTimeToPublish', '', false);
              formRef.setFieldValue('actualTreatmentDuration', '', false);
            }
            if (formRef.errors.actualTreatmentDuration) {
              const conditionalDate = formFields.actualEnrollmentCloseDate;
              if (conditionalDate) {
                const date = convertToDate(conditionalDate, formFields.actualTreatmentDuration);
                formRef.setFieldValue('anticipatedPrimaryCompletionDate', date, false);
                formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
                formRef.setFieldError('actualTreatmentDuration', '');
              }
            }
            if (
              !formRef.errors.actualTreatmentDuration &&
              formRef.errors.anticipatedTimeToPublish
            ) {
              formRef.setFieldValue(
                'anticipatedPrimaryCompletionDate',
                formFields.anticipatedPrimaryCompletionDate,
                false,
              );
            }
            if (!formFields.actualEnrollmentDuration && formFields.actualTreatmentDuration) {
              formRef.setFieldValue('anticipatedPrimaryCompletionDate', null, false);
              formRef.setFieldError('anticipatedPrimaryCompletionDate', '');
              formRef.setFieldValue('actualTreatmentDuration', '', false);
              formRef.setFieldError('actualTreatmentDuration', '');
            }
          }
          // eslint-disable-next-line no-lonely-if
        }
      }
      break;

    case 'anticipatedPrimaryEndpointReported':
      if (value) {
        // Third block related calculations

        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          formFields.actualTimeToPublish &&
          !formRef.errors.actualTimeToPublish &&
          !formFields.anticipatedPrimaryCompletionDate
        ) {
          let date = convertToDateBackward(value, formFields.actualEnrollmentDuration);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', date);
          formRef.setFieldValue('anticipatedPrimaryEndpointReported', value);
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          formFields.actualTimeToPublish &&
          formFields.anticipatedPrimaryEndpointReported &&
          formFields.anticipatedPrimaryCompletionDate
        ) {
          formRef.setFieldError('actualTimeToPublish', timingConstants.HIGHLITER_MESSAGE);
          formRef.setFieldValue('anticipatedPrimaryEndpointReported', value, false);
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          !formFields.actualTimeToPublish &&
          formFields.anticipatedPrimaryCompletionDate &&
          !formRef.errors.anticipatedTimeToPublish
        ) {
          let duration = convertToDuration(formFields.anticipatedPrimaryCompletionDate, value);
          formRef.setFieldValue('anticipatedTimeToPublish', duration >= 0 ? duration : '', false);
          formRef.setFieldValue('anticipatedPrimaryEndpointReported', value);
        }
        if (
          formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          !formFields.actualTimeToPublish
        ) {
          if (formFields.anticipatedTimeToPublish && !formRef.errors.anticipatedTimeToPublish) {
            let duration = convertToDuration(formFields.actualPrimaryCompletionDate, value);
            formRef.setFieldValue('anticipatedTimeToPublish', duration >= 0 ? duration : '', false);
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', value, false);
            if (dateRangeCheck(value, formFields.actualPrimaryCompletionDate)) {
              formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
            } else {
              formRef.setFieldError(
                'anticipatedPrimaryEndpointReported',
                'Anticipated Primary endpoint reported date must be equal or later than the actual primary completion date',
              );
            }
          }
          if (!formFields.anticipatedTimeToPublish) {
            let duration = convertToDuration(formFields.actualPrimaryCompletionDate, value);
            formRef.setFieldValue('anticipatedTimeToPublish', duration >= 0 ? duration : '', false);
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', value, false);
            if (dateRangeCheck(value, formFields.actualPrimaryCompletionDate)) {
              formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
            } else {
              formRef.setFieldError(
                'anticipatedPrimaryEndpointReported',
                'Anticipated Primary endpoint reported date must be equal or later than the actual primary completion date',
              );
            }
          }
        }
        if (
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          !formFields.actualTimeToPublish &&
          !formFields.anticipatedPrimaryCompletionDate &&
          !formRef.errors.anticipatedTimeToPublish &&
          formFields.anticipatedTimeToPublish
        ) {
          let date = convertToDateBackward(value, formFields.anticipatedTimeToPublish);
          formRef.setFieldValue('anticipatedPrimaryCompletionDate', date);
          formRef.setFieldValue('anticipatedPrimaryEndpointReported', value);
        }
        //default
        formRef.setFieldValue('anticipatedPrimaryEndpointReported', value, false);
      } else {
        // Third block related calculations
        if (
          !formFields.anticipatedTimeToPublish &&
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          formFields.actualTimeToPublish &&
          formFields.anticipatedPrimaryCompletionDate
        ) {
          if (formRef.errors.actualTimeToPublish) {
            let date = convertToDate(
              formFields.anticipatedPrimaryCompletionDate,
              formFields.actualTimeToPublish,
            );
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', date, false);
            formRef.setFieldError('actualTimeToPublish', '');
          }
          if (!formRef.errors.actualTimeToPublish) {
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', null, false);
            formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
            formRef.setFieldValue('actualTimeToPublish', '', false);
          }
        }
        if (
          formFields.anticipatedTimeToPublish &&
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          formFields.actualTimeToPublish &&
          formFields.anticipatedPrimaryCompletionDate
        ) {
          if (formRef.errors.actualTimeToPublish) {
            let date = convertToDate(
              formFields.anticipatedPrimaryCompletionDate,
              formFields.actualTimeToPublish,
            );
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', date, false);
            formRef.setFieldError('actualTimeToPublish', '');
          }
          if (!formRef.errors.actualTimeToPublish) {
            let date = convertToDate(
              formFields.anticipatedPrimaryCompletionDate,
              formFields.anticipatedTimeToPublish,
            );
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', date, false);
            formRef.setFieldError('anticipatedTimeToPublish', '');
            formRef.setFieldValue('actualTimeToPublish', '', false);
          }
        }
        if (
          formFields.anticipatedTimeToPublish &&
          !formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          !formFields.actualTimeToPublish &&
          formFields.anticipatedPrimaryCompletionDate
        ) {
          if (formRef.errors.anticipatedTimeToPublish) {
            let date = convertToDate(
              formFields.anticipatedPrimaryCompletionDate,
              formFields.anticipatedTimeToPublish,
            );
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', date, false);
            formRef.setFieldError('anticipatedTimeToPublish', '');
          }
          if (!formRef.errors.anticipatedTimeToPublish) {
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', null, false);
            formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
            formRef.setFieldError('anticipatedTimeToPublish', '');
            formRef.setFieldValue('anticipatedTimeToPublish', '', false);
          }
        }
        if (
          formFields.anticipatedTimeToPublish &&
          formFields.actualPrimaryCompletionDate &&
          !formFields.actualPrimaryEndpointReported &&
          !formFields.actualTimeToPublish
        ) {
          if (formRef.errors.anticipatedTimeToPublish) {
            let date = convertToDate(
              formFields.actualPrimaryCompletionDate,
              formFields.anticipatedTimeToPublish,
            );
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', date, false);
            formRef.setFieldError('anticipatedTimeToPublish', '');
          }
          if (!formRef.errors.anticipatedTimeToPublish) {
            formRef.setFieldValue('anticipatedPrimaryEndpointReported', null, false);
            formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
            formRef.setFieldError('anticipatedTimeToPublish', '');
            formRef.setFieldValue('anticipatedTimeToPublish', '', false);
          }
        }
        if (
          !formFields.anticipatedTimeToPublish &&
          !formFields.actualPrimaryEndpointReported &&
          !formFields.actualTimeToPublish
        ) {
          formRef.setFieldValue('anticipatedPrimaryEndpointReported', null, false);
          formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
        }
        if (
          formFields.actualPrimaryEndpointReported &&
          !formFields.actualPrimaryCompletionDate &&
          formFields.anticipatedPrimaryCompletionDate &&
          formFields.anticipatedTimeToPublish
        ) {
          formRef.setFieldValue('anticipatedPrimaryEndpointReported', null, false);
          formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
        }
        if (
          formFields.actualPrimaryEndpointReported &&
          formFields.actualPrimaryCompletionDate &&
          formFields.anticipatedPrimaryCompletionDate &&
          !formFields.anticipatedTimeToPublish
        ) {
          formRef.setFieldValue('anticipatedPrimaryEndpointReported', null, false);
          formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
        }
        if (!formFields.actualPrimaryCompletionDate && formFields.actualTimeToPublish) {
          formRef.setFieldValue('anticipatedPrimaryEndpointReported', null, false);
          formRef.setFieldError('anticipatedPrimaryEndpointReported', '');
          formRef.setFieldValue('actualTimeToPublish', '', false);
          formRef.setFieldError('actualTimeToPublish', '');
        }
      }
      break;
    case 'actualFullCompletionDate':
      formRef.setFieldValue('actualFullCompletionDate', value);
      break;
    case 'anticipatedFullCompletionFinalDate':
      formRef.setFieldValue('anticipatedFullCompletionFinalDate', value);
      break;
    case 'identifiedSites':
      formRef.setFieldValue('identifiedSites', value);
      break;
    case 'reportedSites':
      formRef.setFieldValue('reportedSites', value);
      break;
    default:
  }
  return formFields;
};
