import { takeEvery, put, all } from 'redux-saga/effects';

import * as advancedSearchActions from '../actions/advancedSearch';
import * as globalActions from '../actions/globalActions';
import * as TaskActions from '../actions/task';
import { advancedsearch as advancedSearchApi, task as taskApi } from '../../api';
import {
  MODAL_TYPES,
  ALERT_MESSAGES,
  requirementStaticHeader,
  sourceDocumentStaticHeader,
  registryStaticHeader,
  countryStaticHeader,
  dashboardStaticHeader,
} from '../../utils/generic/constants';

export function* getAdvancedSearchCategoryDataAsync({ payload: selectedEntity }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield advancedSearchApi.getAdvancedSearchCategoryData(selectedEntity);
    const { state, data } = response.data;
    if (state) {
      yield put(advancedSearchActions.getAdvancedSearchCategoryDataSuccess(data));
      yield put(globalActions.hideSpinner());
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getSearchResultsAsync({ payload }) {
  try {
    const { searchData, selectedEntity } = payload;
    yield put(globalActions.showSpinner());
    const response = yield advancedSearchApi.getSearchResults(selectedEntity, searchData);
    const { state, data } = response.data;
    if (state) {
      yield put(advancedSearchActions.getSearchResultsSuccess(data));
      yield put(globalActions.hideSpinner());
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(
      advancedSearchActions.getSearchResultsSuccess({
        results: [],
        rowCount: 0,
      }),
    );
    yield put(globalActions.hideSpinner());
  }
}

export function* getAdvancedSearchCategoryTitleDataAsync({ payload }) {
  try {
    const { endPoint, selectedEntity } = payload;
    yield put(globalActions.showSpinner());
    const response = yield advancedSearchApi.getAdvancedSearchCategoryTitleData(
      selectedEntity,
      endPoint,
    );
    const { state, data } = response.data;
    if (state) {
      yield put(advancedSearchActions.getAdvancedSearchCategoryTitleDataSuccess(data));
      yield put(globalActions.hideSpinner());
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getAllSavedSearchesAsync({ payload }) {
  try {
    const { selectedEntity, selectedUserId, searchSubTypeId } = payload;
    yield put(globalActions.showSpinner());
    const response = yield advancedSearchApi.getAllSavedSearches(
      selectedEntity,
      selectedUserId,
      searchSubTypeId,
    );
    const { state, data } = response.data;
    if (state) {
      yield put(advancedSearchActions.getAllSavedSearchesSuccess(data));
      yield put(globalActions.hideSpinner());
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getAdvancedSearchDashboardAsync({
  payload: { entityId, isQuickSearch, advancedSearchCategoryParam },
}) {
  try {
    yield put(globalActions.showSpinner());

    const actions = {
      // assigneeUserGroupListActions: taskApi.assigneeUserGroupList([]),
      // taskMasterActions: taskApi.getTaskMaster(),
      getEntityColDefActions: advancedSearchApi.getEntityColDef({ entityId, isQuickSearch }),
      getAdvancedSearchCategoryActions: advancedSearchApi.getAdvancedSearchCategoryData(
        advancedSearchCategoryParam,
      ),
    };

    const {
      getAdvancedSearchCategoryActions,
      getEntityColDefActions,
      taskMasterActions,
      assigneeUserGroupListActions,
    } = yield all(actions);

    if (getAdvancedSearchCategoryActions) {
      const {
        data: { data, state },
      } = getAdvancedSearchCategoryActions;
      if (state) {
        yield put(advancedSearchActions.getAdvancedSearchCategoryDataSuccess(data));
      }
    }
    if (getEntityColDefActions) {
      const {
        data: { data, state },
      } = getEntityColDefActions;

      if (state) {
        yield put(advancedSearchActions.getEntityColDefSuccess(data));
      } else {
        let header = null;
        if (entityId === 10) {
          header = registryStaticHeader;
        } else if (entityId === 7) {
          header = sourceDocumentStaticHeader;
        } else if (entityId === 8) {
          header = countryStaticHeader;
        } else if (entityId === 9) {
          header = requirementStaticHeader;
        } else if (entityId === 11) {
          header = dashboardStaticHeader;
        }
        yield put(advancedSearchActions.getEntityColDefSuccess(header));
      }
    }
    if (taskMasterActions) {
      const {
        data: { data, state },
      } = taskMasterActions;

      if (state) {
        yield put(TaskActions.getTaskMasterSuccess({ data }));
      }
    }
    if (assigneeUserGroupListActions) {
      const {
        data: { data, state },
      } = assigneeUserGroupListActions;

      if (state) {
        yield put(TaskActions.assigneeUserGroupListSuccess({ data }));
      }
    }
    // yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.ADVANCED_SEARCH_ERROR));
  }
}

export function* addSavedSearchAsync({ payload }) {
  try {
    const { selectedEntity, savedSearchData, selectedUserId, searchSubTypeId } = payload;
    yield put(globalActions.showSpinner());
    const response = yield advancedSearchApi.addSavedSearch(
      selectedEntity,
      savedSearchData,
      searchSubTypeId,
    );
    const { state, data, messages } = response.data;
    if (state && data) {
      yield put(
        globalActions.showModal({
          isOpen: true,
          modalType: MODAL_TYPES.SUCCESS,
          message: 'Search saved successfully!',
        }),
      );
      yield put(
        advancedSearchActions.getAllSavedSearches({
          selectedEntity,
          selectedUserId,
          searchSubTypeId,
        }),
      );
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: messages[0].errorMessage,
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* editSavedSearchAsync({ payload }) {
  try {
    const { selectedEntity, savedSearchData, selectedUserId, searchSubTypeId } = payload;
    yield put(globalActions.showSpinner());
    const response = yield advancedSearchApi.editSavedSearch(selectedEntity, savedSearchData);
    const { state, data, messages } = response.data;
    if (state && data) {
      yield put(
        globalActions.showModal({
          isOpen: true,
          modalType: MODAL_TYPES.SUCCESS,
          message: 'Search updated successfully!',
        }),
      );
      yield put(
        advancedSearchActions.getAllSavedSearches({
          selectedEntity,
          selectedUserId,
          searchSubTypeId,
        }),
      );
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: messages[0].errorMessage,
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* updateSavedSearchAsync({ payload }) {
  try {
    const { endPoint } = payload;
    yield put(globalActions.showSpinner());
    const response = yield advancedSearchApi.getAdvancedSearchCategoryTitleData(endPoint);
    const { state, data } = response.data;
    if (state) {
      yield put(
        globalActions.showModal({
          isOpen: true,
          modalType: MODAL_TYPES.SUCCESS,
          message: `Search Updated Successfully!`,
        }),
      );
      yield put(advancedSearchActions.getAdvancedSearchCategoryTitleDataSuccess(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* deleteSavedSearchAsync({ payload }) {
  try {
    const { id, name, selectedEntity, selectedUserId, searchSubTypeId } = payload;
    yield put(globalActions.showSpinner());
    const response = yield advancedSearchApi.deleteSavedSearch(selectedEntity, id);
    const { state, data, messages } = response.data;
    if (state && data) {
      yield put(
        advancedSearchActions.getAllSavedSearches({
          selectedEntity,
          selectedUserId,
          searchSubTypeId,
        }),
      );
      yield put(
        globalActions.showModal({
          isOpen: true,
          modalType: MODAL_TYPES.SUCCESS,
          message: `"${name}" deleted successfully!`,
        }),
      );
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: messages[0].errorMessage,
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export const watchers = [
  function* watchSearchResults() {
    yield takeEvery(advancedSearchActions.getSearchResults, getSearchResultsAsync);
  },
  function* watchGetAdvancedSearchCategoryData() {
    yield takeEvery(
      advancedSearchActions.getAdvancedSearchCategoryData,
      getAdvancedSearchCategoryDataAsync,
    );
  },
  function* watchGetAdvancedSearchCategoryTitleData() {
    yield takeEvery(
      advancedSearchActions.getAdvancedSearchCategoryTitleData,
      getAdvancedSearchCategoryTitleDataAsync,
    );
  },
  function* watchGetAllSavedSearches() {
    yield takeEvery(advancedSearchActions.getAllSavedSearches, getAllSavedSearchesAsync);
  },

  function* watchAddSavedSearch() {
    yield takeEvery(advancedSearchActions.addSavedSearch, addSavedSearchAsync);
  },

  function* watchEditSavedSearch() {
    yield takeEvery(advancedSearchActions.editSavedSearch, editSavedSearchAsync);
  },

  function* watchUpdateSavedSearch() {
    yield takeEvery(advancedSearchActions.updateSavedSearch, updateSavedSearchAsync);
  },

  function* watchDeleteSavedSearch() {
    yield takeEvery(advancedSearchActions.deleteSavedSearch, deleteSavedSearchAsync);
  },
  function* watchGetAdvancedSearchDashboard() {
    yield takeEvery(
      advancedSearchActions.getAdvancedSearchDashboard,
      getAdvancedSearchDashboardAsync,
    );
  },
];
