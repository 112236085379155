export const registryDetailsState = {
    id: 0,
    ingestedId: 0,
    updateType: 0,
    notes: [],
    prodStatusID: 0,
    registryInfo: {
      // id: '',
      reasonForUpdate: [],
      reasonForUpdateDescription: '',
      executiveSummary: '',
    },
    contactInfo:{
      contactEmail:'',
      contactPhone:'',
    },
    whoEntersData: {
      registryResponsibleInfoModel: [],
      othersResponsibleForInformation: null,
      dataRestriction: null,
      registryAuthorizedInfoModel: [],
      othersAuthorizedForInformation: null,
    },
    generalInformation: {
      sourceDocs: [],
      registryURL: []
    }
  };