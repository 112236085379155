/* eslint-disable generator-star-spacing */
import { takeEvery, put, all } from 'redux-saga/effects';

import * as adminActions from '../actions/administrator';
import * as globalActions from '../actions/globalActions';
import { administrator as adminApi } from '../../api';
import { ALERT_MESSAGES, MODAL_TYPES } from '../../utils/generic/constants';

export function* getAdminDataAsync() {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.getAdminData();
    const { data } = response;
    if (response.status) {
      yield put(adminActions.setAdministratorData({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* addAdminDataAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.postAdminData(payload);
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        modalData = {
          isOpen: true,
          message: 'DATA ALREADY EXISTS',
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: `DATA SOURCE GROUP "${respData.data.dataSourceGroupName}" SAVED SUCCESSFULLY`,
          modalType: MODAL_TYPES.SUCCESS,
        };
      }
      yield put(globalActions.showModal(modalData));
      yield put(adminActions.setAdministratorData(response.data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* deleteAdminDataAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.deleteAdminData(payload);
    if (response.status) {
      const modalData = response.data.state
        ? {
          isOpen: true,
          message: 'DATA DELETED SUCCESSFULLY',
          modalType: MODAL_TYPES.SUCCESS,
        }
        : {
          isOpen: true,
          message: response.data.messages[0].errorMessage,
          modalType: MODAL_TYPES.ERROR,
        };
      yield put(globalActions.showModal(modalData));

      const getResponse = yield adminApi.getAdminData({ payload });
      if (getResponse.status) {
        yield put(adminActions.setAdministratorDeleteData(getResponse.data));
      }
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* updateAdminDataAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.putAdminData(payload);
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        modalData = {
          isOpen: true,
          message: 'DATA ALREADY EXISTS',
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: 'DATA UPDATED SUCCESSFULLY',
          modalType: MODAL_TYPES.SUCCESS,
        };
      }
      yield put(globalActions.showModal(modalData));
      const getResponse = yield adminApi.getAdminData({ payload });
      if (getResponse.status) {
        yield put(adminActions.setAdministratorDeleteData(getResponse.data));
      }
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}
export function* getRoleGroupAsync() {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.getRoleGroup();
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.setRoleGroup({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* saveRoleAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.saveRole(payload);
    if (response.status) {
      let message = '';
      if (response.data.state) {
        yield put(adminActions.getRoles());
        message = ` ${payload.id ? ' Role Updated' : `Role Created`} Successfully`;
      } else {
        message = response.data.messages[0].errorMessage;
      }
      const modalData = response.data.state ?
        {
          isOpen: true,
          message,
          modalType: MODAL_TYPES.SUCCESS
        } :
        {
          isOpen: true,
          message,
          modalType: MODAL_TYPES.ERROR
        }
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getRolesAsync() {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.getRoles();
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.setRoles({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getRoleByIdAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const [roleData, rolePermission] = yield all([
      adminApi.getRoleById(payload),
      adminApi.getRolesPermission(),
    ]);
    const {
      data: { data: roleDetail },
    } = roleData;
    const {
      data: { data: permissions },
    } = rolePermission;
    if (roleData.status && rolePermission.status) {
      yield put(adminActions.setRoleById({ roleDetail, permissions }));
    }

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getRolesPermissionsAsync() {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.getRolesPermission();
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.setRolesPermissions({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* searchRolesAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.searchRoles(payload);
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.setRoles({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* deleteRoleAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.deleteRole(payload.id);
    if (response.status) {
      yield put(adminActions.getRoles());

      const modalData = response.data.state
        ? {
          isOpen: true,
          message: `Role Deleted Successfully`,
          modalType: MODAL_TYPES.SUCCESS,
        }
        : {
          isOpen: true,
          message: response.data.messages[0].errorMessage,
          modalType: MODAL_TYPES.ERROR,
        };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getUsersAsync() {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.getUsers();
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.setUsers({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* searchUsersAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.searchUsers(payload);
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.setUsers({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* saveAssignRoles({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.saveAssignRoles(payload);
    if (response.status) {
      yield put(adminActions.getUsers());
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: `Role(s) & User Company Assigned/Unassigned successfully`,
          modalType: MODAL_TYPES.SUCCESS,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getDataSourceAsync() {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.getDataSource();
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.setDataSource({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* postDataSourceAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.postDataSource(payload.updatedValue);

    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        modalData = {
          isOpen: true,
          message: 'DATA ALREADY EXISTS',
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: 'DATA SAVED SUCCESSFULLY',
          modalType: MODAL_TYPES.SUCCESS,
        };
      }
      yield put(globalActions.showModal(modalData));
      yield put(adminActions.getDataSource());
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* putDataSourceAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.putDataSource(payload);
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        modalData = {
          isOpen: true,
          message: 'DATA ALREADY EXISTS',
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: 'DATA UPDATED SUCCESSFULLY',
          modalType: MODAL_TYPES.SUCCESS,
        };
      }
      yield put(globalActions.showModal(modalData));
      yield put(adminActions.getDataSource());
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getProcessorAsync() {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.getProcessor();
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.setProcessor({ data }));
      yield put(adminActions.setRoleGroup({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* deleteProcessorAsync({ payload: { value, searchValue } }) {
  try {
    const response = yield adminApi.deleteProcessor(value.id);
    if (response.status) {
      const modalData = response.data.state
        ? {
          isOpen: true,
          message: `User Company Deleted Successfully`,
          modalType: MODAL_TYPES.SUCCESS,
        }
        : {
          isOpen: true,
          message: `User Company cannot be deleted as it is associated to roles/users`,
          modalType: MODAL_TYPES.ERROR,
        };
      yield put(globalActions.showModal(modalData));
      if (searchValue) {
        yield put(adminActions.searchProcessor(searchValue));
      } else {
        yield put(adminActions.getProcessor());
      }
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* searchProcessorAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.searchProcessor(payload);
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.setProcessor({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.PROCESSOR_ERROR));
  }
}

export function* saveProcessorAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.saveProcessor(payload);
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        modalData = {
          isOpen: true,
          message: ` User Company Already Exists`,
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: ` User Company Created Successfully`,
          modalType: MODAL_TYPES.SUCCESS,
        };
      }
      yield put(globalActions.showModal(modalData));
      yield put(adminActions.getProcessor());
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* putProcessorAsync({ payload: { editedData } }) {
  try {
    const response = yield adminApi.putProcessor(editedData);
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        modalData = {
          isOpen: true,
          message: `User Company Already Exists`,
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: 'User Company Updated Successfully',
          modalType: MODAL_TYPES.SUCCESS,
        };
      }
      yield put(globalActions.showModal(modalData));
      yield put(adminActions.getProcessor());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getAgeingAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.getAgeing(payload);
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.getAgeingSuccess({ data, type: payload.type }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* putAgeingAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.putAgeing(payload);
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        modalData = {
          isOpen: true,
          message: 'DATA ALREADY EXISTS',
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: 'AGEING PERIOD IS SUCCESSFULLY UPDATED',
          modalType: MODAL_TYPES.SUCCESS,
        };
      }
      yield put(globalActions.showModal(modalData));
      yield put(adminActions.getAgeing(payload));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getUserGroupAsync() {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.getUserGroup();
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.getUserGroupSuccess({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* deleteUserGroupAsync({ payload: { value, searchValue } }) {
  try {
    const response = yield adminApi.deleteUserGroup(value.id);
    if (response.status) {
      const modalData = response.data.state
        ? {
          isOpen: true,
          message: `User Group Deleted Successfully`,
          modalType: MODAL_TYPES.SUCCESS,
        }
        : {
          isOpen: true,
          message: `User Group cannot be deleted. Queue/Task/Users are associated to the group`,
          modalType: MODAL_TYPES.ERROR,
        };
      yield put(globalActions.showModal(modalData));
      if (searchValue) {
        yield put(adminActions.searchUserGroup(searchValue));
      } else {
        yield put(adminActions.getUserGroup());
      }
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* searchUserGroupAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.searchUserGroup(payload);
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.getUserGroupSuccess({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* saveUserGroupAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.saveUserGroup(payload);
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        modalData = {
          isOpen: true,
          message: `User Group Already Exists`,
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: `User Group Created Successfully`,
          modalType: MODAL_TYPES.SUCCESS,
        };
      }
      yield put(globalActions.showModal(modalData));
      yield put(adminActions.getUserGroup());
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* putUserGroupAsync({ payload: { editedData } }) {
  try {
    const response = yield adminApi.putUserGroup(editedData);
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        modalData = {
          isOpen: true,
          message: `User Group Already Exists`,
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: `User Group Updated Successfully`,
          modalType: MODAL_TYPES.SUCCESS,
        };
      }
      yield put(globalActions.showModal(modalData));
      yield put(adminActions.getUserGroup());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* saveAssignUserGroupsAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.saveAssignUserGroups(payload);
    if (response.status) {
      yield put(adminActions.getUsers());
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: `User Group(s) Assigned/Unassigned Successfully`,
          modalType: MODAL_TYPES.SUCCESS,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getQueueAsync() {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.getQueue();
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.getQueueSuccess({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* putQueueAsync({ payload }) {
  try {
    const response = yield adminApi.putQueue(payload);
    if (response.status) {
      const respData = response.data;
      let modalData = null;
      if (respData && !respData.state && respData.messages) {
        modalData = {
          isOpen: true,
          message: `Queue Name Already Exists`,
          modalType: MODAL_TYPES.ERROR,
        };
      } else {
        modalData = {
          isOpen: true,
          message: `Queue Updated Successfully`,
          modalType: MODAL_TYPES.SUCCESS,
        };
      }
      yield put(globalActions.showModal(modalData));
      yield put(adminActions.getQueue());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

export function* getQueueMasterAsync() {
  try {
    yield put(globalActions.showSpinner());
    const response = yield adminApi.getQueueMaster();
    const {
      data: { data },
    } = response;
    if (response.status) {
      yield put(adminActions.getQueueMasterSuccess({ data }));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
  }
}

// watchers
export const watchers = [
  function* watchgetAdminDataAsync() {
    yield takeEvery(adminActions.getAdministratorData, getAdminDataAsync);
  },
  function* watchAddAdminDataAsync() {
    yield takeEvery(adminActions.addAdministratorData, addAdminDataAsync);
  },
  function* watchUpdateAdminDataAsync() {
    yield takeEvery(adminActions.updateAdministratorData, updateAdminDataAsync);
  },
  function* watchUpdateAdminDataAsync() {
    yield takeEvery(adminActions.deleteAdministratorData, deleteAdminDataAsync);
  },
  function* watchGetRoleGroup() {
    yield takeEvery(adminActions.getRoleGroup, getRoleGroupAsync);
  },
  function* watchSaveRole() {
    yield takeEvery(adminActions.saveRole, saveRoleAsync);
  },
  function* watchGetRoles() {
    yield takeEvery(adminActions.getRoles, getRolesAsync);
  },
  function* watchGetRoleById() {
    yield takeEvery(adminActions.getRoleById, getRoleByIdAsync);
  },
  function* watchGetRolesPermissions() {
    yield takeEvery(adminActions.getRolesPermissions, getRolesPermissionsAsync);
  },
  function* watchDeleteRole() {
    yield takeEvery(adminActions.deleteRole, deleteRoleAsync);
  },
  function* watchSearchRoles() {
    yield takeEvery(adminActions.searchRoles, searchRolesAsync);
  },
  function* watchgetUsers() {
    yield takeEvery(adminActions.getUsers, getUsersAsync);
  },
  function* watchSaveAssignRoles() {
    yield takeEvery(adminActions.saveAssignRoles, saveAssignRoles);
  },
  function* watchgetUsers() {
    yield takeEvery(adminActions.searchUsers, searchUsersAsync);
  },
  function* watchGetDataSource() {
    yield takeEvery(adminActions.getDataSource, getDataSourceAsync);
  },
  function* watchPostDataSource() {
    yield takeEvery(adminActions.postDataSource, postDataSourceAsync);
  },
  function* watchPutDataSource() {
    yield takeEvery(adminActions.putDataSource, putDataSourceAsync);
  },
  function* watchGetProcessor() {
    yield takeEvery(adminActions.getProcessor, getProcessorAsync);
  },
  function* watchDeleteProcessor() {
    yield takeEvery(adminActions.deleteProcessor, deleteProcessorAsync);
  },
  function* watchSearchProcessor() {
    yield takeEvery(adminActions.searchProcessor, searchProcessorAsync);
  },
  function* watchSaveProcessor() {
    yield takeEvery(adminActions.saveProcessor, saveProcessorAsync);
  },
  function* watchPutProcessor() {
    yield takeEvery(adminActions.putProcessor, putProcessorAsync);
  },
  function* watchGetAgeing() {
    yield takeEvery(adminActions.getAgeing, getAgeingAsync);
  },
  function* watchPutAgeing() {
    yield takeEvery(adminActions.putAgeing, putAgeingAsync);
  },
  function* watchGetUserGroup() {
    yield takeEvery(adminActions.getUserGroup, getUserGroupAsync);
  },
  function* watchDeleteUserGroup() {
    yield takeEvery(adminActions.deleteUserGroup, deleteUserGroupAsync);
  },
  function* watchSearchUserGroup() {
    yield takeEvery(adminActions.searchUserGroup, searchUserGroupAsync);
  },
  function* watchSaveUserGroup() {
    yield takeEvery(adminActions.saveUserGroup, saveUserGroupAsync);
  },
  function* watchPutUserGroup() {
    yield takeEvery(adminActions.putUserGroup, putUserGroupAsync);
  },
  function* watchGetQueue() {
    yield takeEvery(adminActions.getQueue, getQueueAsync);
  },
  function* watchPutQueue() {
    yield takeEvery(adminActions.putQueue, putQueueAsync);
  },
  function* watchGetQueueMasters() {
    yield takeEvery(adminActions.getQueueMaster, getQueueMasterAsync);
  },
  function* watchSaveAssignUserGroup() {
    yield takeEvery(adminActions.saveAssignUserGroups, saveAssignUserGroupsAsync);
  },
];
