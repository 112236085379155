import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';

import { checkIfArrayIsEmpty } from '../../../utils/generic/helper';
import InputCheckbox from '../InputCheckbox';
import InputLabel from '../InputLabel';
import './index.scss';

const InputSelectGrouping = ({
  name,
  label,
  labelSuffix,
  options,
  selectedOptions,
  isMulti,
  isSearchable,
  isClearable,
  placeholder,
  hideSelectedOptions,
  blurInputOnSelect,
  controlShouldRenderValue,
  value,
  isMandatory,
  isDisabled,
  error,
  helperText,
  size,
  onChange,
  allowMultipleSelections,
  showSelectAllOptions,
}) => {
  const [dropdownData, updateDropdownData] = useState({
    defaultData: [],
    searchedData: [],
  });
  const [inputValue, updateInputValue] = useState('');

  useEffect(() => {
    let isAllParentChecked = true;
    let mappedData = options.map(d => {
      let updatedData = {
        ...d,
        isExpanded: d.isExpanded || false,
        isSelected: d.isSelected || false,
        childrens: d.childrens.map(c => {
          return {
            ...c,
            isSelected: c.isSelected || false,
          };
        }),
      };
      const selectedData = selectedOptions.find(s => s.id === d.id);
      if (selectedData) {
        const { childrens } = selectedData;
        const updatedChildrens = d.childrens.map(c => {
          return {
            ...c,
            isSelected: childrens.findIndex(i => i.id === c.id) > -1 || c.isSelected,
          };
        });
        updatedData = {
          ...d,
          isExpanded: !checkIfArrayIsEmpty(updatedChildrens.filter(u => u.isSelected)),
          isSelected: checkIfArrayIsEmpty(updatedChildrens.filter(u => !u.isSelected)),
          childrens: updatedChildrens,
        };
      }
      isAllParentChecked = isAllParentChecked && updatedData.isSelected;
      return { ...updatedData };
    });
    if (showSelectAllOptions && mappedData) {
      mappedData = [
        {
          id: 'allOptions',
          value: 'Deselect All / Select all',
          isSelected: isAllParentChecked,
          childrens: [],
        },
        ...mappedData,
      ];
    }
    updateDropdownData({ defaultData: mappedData, searchedData: mappedData });
  }, [options, selectedOptions]);

  const checkIsDefaultParam = ({ defaultChildren, type, parentData }) => {
    if (checkIfArrayIsEmpty(defaultChildren)) {
      return type === 'parent' ? parentData.isSelected : true;
    }
    return isEmpty(defaultChildren.find(u => !u.isSelected));
  };

  const handleSelectDeselectAll = isChecked => {
    let defaultResult = [...dropdownData.defaultData];
    defaultResult = defaultResult.map(element => {
      return {
        ...element,
        isExpanded: false,
        isSelected: isChecked,
        childrens: element.childrens.map(c => {
          return {
            ...c,
            isSelected: isChecked,
          };
        }),
      };
    });
    updateDropdownData({ defaultData: defaultResult, searchedData: defaultResult });
  };

  const checkParentDefaultValue = ({ defaultResult, searchedResult }) => {
    const isAllParentSelected = defaultResult.slice(1).find(element => !element.isSelected);
    const updateDefaultResult = [
      {
        ...defaultResult[0],
        isSelected: isEmpty(isAllParentSelected),
      },
      ...defaultResult.slice(1),
    ];
    const updateSearchResult =
      searchedResult.length &&
      searchedResult[0].id &&
      searchedResult[0].id.toString() === 'allOptions'
        ? [
            {
              ...searchedResult[0],
              isSelected: isEmpty(isAllParentSelected),
            },
            ...searchedResult.slice(1),
          ]
        : [...searchedResult];
    return { updateDefaultResult, updateSearchResult };
  };

  const handleChange = ({ type, parentData, childId = 0 }) => {
    let searchedResult = [...dropdownData.searchedData];
    const index = searchedResult.findIndex(r => r.id === parentData.id);
    if (index > -1) {
      const updatedChildData = parentData.childrens.map(c => {
        const isChildSelected = c.id === childId ? !c.isSelected : c.isSelected;
        return {
          ...c,
          isSelected: type === 'parent' ? parentData.isSelected : isChildSelected,
        };
      });

      searchedResult.splice(index, 1, {
        ...parentData,
        isSelected:
          type === 'parent'
            ? parentData.isSelected
            : checkIfArrayIsEmpty(updatedChildData.filter(u => !u.isSelected)),
        childrens: updatedChildData,
      });

      if (!allowMultipleSelections) {
        searchedResult = searchedResult.map((s, i) => {
          if (i !== index) {
            return {
              ...s,
              isSelected: false,
              childrens: s.childrens.map(c => {
                return {
                  ...c,
                  isSelected: false,
                };
              }),
            };
          }
          return s;
        });
      }
    }
    let defaultResult = [...dropdownData.defaultData];
    const defaultIndex = defaultResult.findIndex(r => r.id === parentData.id);
    if (defaultIndex > -1) {
      let defaultChildren = [...defaultResult[defaultIndex].childrens];
      if (type === 'parent') {
        // to update only filtered options selection
        parentData.childrens.forEach(p => {
          const cIndex = defaultChildren.findIndex(x => x.id === p.id);
          if (cIndex > -1) {
            defaultChildren.splice(cIndex, 1, {
              ...defaultChildren[cIndex],
              isSelected: parentData.isSelected,
            });
          }
        });
      } else {
        const cIndex = defaultChildren.findIndex(x => x.id === childId);
        if (cIndex > -1) {
          defaultChildren.splice(cIndex, 1, {
            ...defaultChildren[cIndex],
            isSelected: !defaultChildren[cIndex].isSelected,
          });
        }
      }
      defaultResult.splice(defaultIndex, 1, {
        ...parentData,
        isSelected: checkIsDefaultParam({ defaultChildren, type, parentData }),
        childrens: defaultChildren,
      });

      if (!allowMultipleSelections) {
        defaultResult = defaultResult.map((d, i) => {
          if (i !== defaultIndex) {
            return {
              ...d,
              isSelected: false,
              childrens: d.childrens.map(c => {
                return {
                  ...c,
                  isSelected: false,
                };
              }),
            };
          }
          return d;
        });
      }
    }
    if (showSelectAllOptions) {
      const { updateDefaultResult, updateSearchResult } = checkParentDefaultValue({
        defaultResult,
        searchedResult,
      });
      defaultResult = updateDefaultResult;
      searchedResult = updateSearchResult;
    }
    updateDropdownData({ defaultData: defaultResult, searchedData: searchedResult });
  };

  const onExpansion = id => {
    const expandData = data =>
      data.map(r => {
        return {
          ...r,
          isExpanded: r.id === id ? !r.isExpanded : r.isExpanded,
        };
      });
    updateDropdownData({
      defaultData: expandData(dropdownData.defaultData),
      searchedData: expandData(dropdownData.searchedData),
    });
  };

  const onClickingParent = ({ data, parentId, isChecked }) => {
    if (showSelectAllOptions && parentId.toString() === 'allOptions') {
      handleSelectDeselectAll(isChecked);
    } else {
      handleChange({
        type: 'parent',
        parentData: {
          ...data,
          isSelected: isChecked,
        },
      });
    }
  };

  const Option = props => {
    const { data } = props;
    const {
      id: parentId,
      isExpanded,
      value: parentName,
      isSelected: isParentSelected,
      childrens,
    } = data;
    const isChildrenAvailable = !checkIfArrayIsEmpty(childrens);
    return (
      <components.Option {...props}>
        <ExpansionPanel
          expanded={isExpanded}
          onChange={() => {
            if (isChildrenAvailable) {
              onExpansion(parentId);
            } else {
              onClickingParent({ data, parentId, isChecked: !isParentSelected });
            }
          }}
          className="option-expansion-panel"
        >
          <ExpansionPanelSummary
            id={parentId.toString()}
            className="option-expansion-panel-summary"
            expandIcon={
              isChildrenAvailable ? (
                <FontAwesomeIcon icon={faChevronDown} className="option-expansion-icon" />
              ) : null
            }
          >
            <div
              className={`panel-parent-data ${
                parentId.toString() === 'allOptions' ? 'selectDeselectAll' : ''
              }`}
            >
              <InputCheckbox
                id={parentId.toString()}
                label=""
                value={parentName}
                checked={isParentSelected}
                onChange={({ isChecked }) => onClickingParent({ data, parentId, isChecked })}
              />
              <span className="panel-parent-data-name">{parentName}</span>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="option-expansion-panel-details">
            {childrens.map((child, index) => {
              const { id: childId, value: childValue, isSelected: isChildSelected } = child;
              return (
                <div
                  key={index.toString()}
                  className="panel-child-data"
                  onClick={() =>
                    handleChange({
                      type: 'child',
                      parentData: data,
                      childId,
                    })
                  }
                >
                  <InputCheckbox
                    id={`${parentId}-${childId}`}
                    label=""
                    value={childValue}
                    checked={isChildSelected}
                  />
                  <span>{childValue}</span>
                </div>
              );
            })}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </components.Option>
    );
  };

  const filterOptions = val => {
    return dropdownData.defaultData
      .map(d => {
        if (d.value.toLowerCase().indexOf(val) < 0) {
          const updatedChildren = d.childrens
            .map(c => {
              if (c.value.toLowerCase().indexOf(val) > -1) {
                return {
                  ...c,
                  isSelected: c.isSelected || false,
                };
              }
              return null;
            })
            .filter(e => e !== null);
          return updatedChildren.length > 0
            ? {
                ...d,
                isExpanded: true,
                isSelected: checkIfArrayIsEmpty(updatedChildren.filter(u => !u.isSelected)),
                childrens: updatedChildren,
              }
            : null;
        }
        return { ...d };
      })
      .filter(e => e !== null);
  };

  const removeAllOptionOnFilter = options => {
    let updateOptions = options;
    if (
      showSelectAllOptions &&
      options.length &&
      options[0].id &&
      options[0].id.toString() === 'allOptions'
    ) {
      updateOptions = updateOptions.slice(1);
    }
    return updateOptions;
  };

  const handleInputChange = (val, { action }) => {
    if (action === 'input-change') {
      updateInputValue(val);
      updateDropdownData({
        ...dropdownData,
        searchedData: isEmpty(val)
          ? filterOptions(val.toLowerCase())
          : removeAllOptionOnFilter(filterOptions(val.toLowerCase())),
      });
    }
  };
  const getSelectedOptions = () => {
    return dropdownData.defaultData
      .map(r => {
        const childrens = r.childrens.filter(c => c.isSelected);
        if (r.isSelected || childrens.length > 0) {
          return {
            ...r,
            childrens,
          };
        }
        return null;
      })
      .filter(r => r !== null);
  };

  const onBlur = () => {
    let selectedDataOption = getSelectedOptions();
    if (
      showSelectAllOptions &&
      selectedDataOption.length &&
      selectedDataOption[0].id &&
      selectedDataOption[0].id.toString() === 'allOptions'
    ) {
      selectedDataOption = selectedDataOption.slice(1);
    }
    onChange(selectedDataOption);
    updateInputValue('');
    updateDropdownData({ ...dropdownData, searchedData: dropdownData.defaultData });
  };

  const handleDelete = (parentIndex, childIndex) => {
    const data = getSelectedOptions();
    let parentData = data[parentIndex];
    if (parentData) {
      const childrens = [...parentData.childrens];
      childrens.splice(childIndex, 1);
      if (checkIfArrayIsEmpty(childrens)) {
        data.splice(parentIndex, 1);
      } else {
        parentData = {
          ...parentData,
          childrens,
        };
        data[parentIndex] = parentData;
      }
    }
    onChange(data);
  };

  return (
    <div name={name} className="input-select-grouping-wrapper">
      {label && (
        <InputLabel
          labelFor="inputSelectGroupingLabel"
          text={label}
          textSuffix={labelSuffix}
          isMandatory={isMandatory}
          size={size}
        />
      )}
      <Select
        className={`input-select-grouping-container ${error ? 'error-control' : ''}`}
        classNamePrefix="input-select-grouping"
        hideSelectedOptions={hideSelectedOptions}
        closeMenuOnSelect={!isMulti}
        options={dropdownData.searchedData}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder={placeholder}
        isDisabled={isDisabled}
        value={value}
        components={{ Option }}
        onBlur={onBlur}
        blurInputOnSelect={blurInputOnSelect}
        controlShouldRenderValue={controlShouldRenderValue}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        filterOption={() => true}
      />
      {error && <p className="error-text">{helperText}</p>}
      <div className="selected-container">
        {selectedOptions.map((item, index) => {
          return (
            <div key={index.toString()} className="parent-data">
              <div className="parent-data-inner">
                <span>{item.value}</span>
                {item.childrens.length === 0 && (
                  <button type="button" onClick={() => handleDelete(index)}>
                    <FontAwesomeIcon icon={faTimes} className="close-icon" />
                  </button>
                )}
              </div>
              <div className="child-container">
                {item.childrens.map((c, childIndex) => {
                  return (
                    <div className="child-data" key={childIndex.toString()}>
                      <span>{c.value}</span>
                      <button type="button" onClick={() => handleDelete(index, childIndex)}>
                        <FontAwesomeIcon icon={faTimes} className="close-icon" />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

InputSelectGrouping.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  blurInputOnSelect: PropTypes.bool,
  controlShouldRenderValue: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.object,
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func,
  isMandatory: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  size: PropTypes.string,
  allowMultipleSelections: PropTypes.bool,
  showSelectAllOptions: PropTypes.bool,
};

InputSelectGrouping.defaultProps = {
  label: '',
  placeholder: '',
  isMulti: false,
  isSearchable: false,
  isClearable: false,
  hideSelectedOptions: false,
  blurInputOnSelect: false,
  controlShouldRenderValue: false,
  isDisabled: false,
  value: null,
  selectedOptions: [],
  onChange: () => {},
  isMandatory: false,
  error: false,
  helperText: '',
  size: '16',
  allowMultipleSelections: false,
  showSelectAllOptions: false,
  // structure for options
  // options: [
  //   {
  //     id: 1,
  //     value: 'Informa Editors',
  //     childrens: [
  //       { id: 1, value: 'Senior Admin - WNS' },
  //       { id: 2, value: 'Stamper - WNS' },
  //       { id: 3, value: 'Editor - WNS' },
  //     ],
  //   },
  // ],
};

export default InputSelectGrouping;
