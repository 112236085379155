import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';

import { FormBuilder, FeatureFooter } from '../../../../../../framework';
import { InputButton } from '../../../../../../framework/inputs';
import { showModal } from '../../../../../../store/actions/globalActions';
import {
  postDrugsData,
  resetDrugDetailsWithKey,
  createMolecularStructureData,
  setDrugValidationErrors,
  updateDrugDetails,
} from '../../../../../../store/actions/drugs';
import { ALERT_MESSAGES } from '../../../../../../utils/generic/constants';
import { validateIngestionAction, isDataEqual } from '../../../../../../utils/generic/helper';
import ClearConfirmation from '../../../../../../generic/modals/clearConfirmation';
import { formSchema, initialValues, layoutSchema } from './config';
import './index.scss';

const MolecularData = forwardRef(({ data, permissions: { disableEdit } }, ref) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const {
    current: {
      chemStructure,
      chemStructure: { molecularData },
      id: drugsId,
      key,
      drugType,
      ingestedId,
    },
    original: {
      chemStructure: { molecularData: originalMolecularData },
    },
    ingested: {
      chemStructure: { fieldActions: ingestedActions },
    },
    errors: {
      chemStructure: {
        molecularData: molecularDataErrors,
        molecularData: { chemicalName: invalidName, smilesFormula: invalidSmilesData },
      },
    },
  } = useSelector(state => state.drugs, shallowEqual);

  const cdId = molecularData ? molecularData.cdid || 0 : 0;
  const currentSmilesFormula = molecularData ? molecularData.smilesFormula || '' : '';
  const currentChemicalName = molecularData ? molecularData.chemicalName || '' : '';

  const [marvinInstance, setMarvinInstance] = useState(null);
  const [clearModal, setClearModal] = useState(false);
  const [disableIcons, setDisableIcons] = useState(false);

  const chemStructureRef = useRef(chemStructure);

  const updatedInitialValues = {
    ...initialValues,
    ...molecularData,
    smilesFormula: currentSmilesFormula,
  };

  useEffect(() => {
    document.getElementById('sketch').addEventListener('load', () => {
      // listen when the document in the iframe is loaded
      const eframeWin = document.getElementById('sketch').contentWindow;
      if (typeof eframeWin != 'undefined') {
        // if the contentWindow property of iframe is available, you can access inner content
        try {
          const marvin = eframeWin.marvin; // reference to marvin library in the iframe
          if (marvin != null) {
            marvin.onReady(() => {
              // onReady is performed when loading of the marvin API is ready (hopefully, sketcher is already instantiated)
              // an editor instance is created automatically in the iframe
              if (typeof marvin.sketcherInstance != 'undefined') {
                setMarvinInstance(marvin.sketcherInstance);

                if (formRef.current) {
                  const { values } = formRef.current;
                  // TO DO: enable this once import chemical name is working
                  // if (values.chemicalName.trim()) {
                  //   marvin.sketcherInstance
                  //     .importStructure('name', values.chemicalName)
                  //     .catch(error => dispatch(showModal(error)));
                  // } else
                  if (values.smilesFormula.trim()) {
                    marvin.sketcherInstance
                      .importStructure('smiles', values.smilesFormula)
                      .catch(() => {
                        dispatch(
                          setDrugValidationErrors({
                            data: {
                              ...molecularDataErrors,
                              smilesFormula: true,
                            },
                            parentKey: 'chemStructure',
                            childTabKey: 'molecularData',
                          }),
                        );
                        dispatch(showModal(ALERT_MESSAGES.DRUGS_SMILES_ERROR));
                      });
                  }
                }
              }
            });
          }
        } catch (e) {
          dispatch(showModal(ALERT_MESSAGES.DRUGS_SMILES_ERROR));
        }
      }
    });

    if (invalidSmilesData) {
      dispatch(showModal(ALERT_MESSAGES.DRUGS_SMILES_ERROR));
    } else if (invalidName) {
      dispatch(showModal(ALERT_MESSAGES.DRUGS_CHEMICAL_NAME_ERROR));
    }

    return () => {
      if (formRef.current) {
        dispatch(
          updateDrugDetails({
            key: 'chemStructure',
            data: {
              ...chemStructureRef.current,
              molecularData: formRef.current.values,
            },
          }),
        );
      }
    };
  }, []);

  useEffect(() => {
    chemStructureRef.current = chemStructure;
  }, [chemStructure]);

  useImperativeHandle(ref, () => ({
    isChanged,
    validate: () => {
      if (data.tabs[data.selectedTabIndex].hasIngestedData) {
        return validateIngestionAction({
          data: { ...chemStructure },
          ingestedActions,
          type: 'drugs',
        }).isValid;
      }
      if (invalidSmilesData) {
        dispatch(showModal(ALERT_MESSAGES.DRUGS_SMILES_ERROR));
        return false;
      }
      if (invalidName) {
        dispatch(showModal(ALERT_MESSAGES.DRUGS_CHEMICAL_NAME_ERROR));
        return false;
      }
      return true;
    },
  }));

  const isChanged = () => !isDataEqual(formRef.current.values, originalMolecularData);

  const upsertJChemDatabase = ({ smilesFormula, chemicalName }) => {
    const requestData = {
      operationType: cdId > 0 ? 'UPDATE' : 'INSERT',
      // eslint-disable-next-line
      // cd_id: cdId, removed due to new microservice changes
      molecule: smilesFormula,
      id: drugsId,
      additionalData: {
        DrugKey: key,
        DrugID: drugsId,
      },
    };
    dispatch(
      createMolecularStructureData({ requestData, params: { smilesFormula, chemicalName } }),
    );
  };

  const loadMolecularParameters = () => {
    dispatch(
      setDrugValidationErrors({
        data: {
          smilesFormula: false,
          chemicalName: false,
        },
        parentKey: 'chemStructure',
        childTabKey: 'molecularData',
      }),
    );
    marvinInstance.exportStructure('smiles').then(smilesFormula => {
      if (smilesFormula) {
        formRef.current.setFieldValue('smilesFormula', smilesFormula);
        if (smilesFormula !== currentSmilesFormula.trim()) {
          setDisableIcons(true);
          marvinInstance
            .exportStructure('name')
            .then(chemicalName => {
              formRef.current.setFieldValue('chemicalName', chemicalName);
              if (smilesFormula && chemicalName !== currentChemicalName.trim()) {
                upsertJChemDatabase({ chemicalName, smilesFormula });
              }
              setDisableIcons(false);
            })
            .catch(() => {
              upsertJChemDatabase({ chemicalName: '', smilesFormula });
              setDisableIcons(false);
            });
        }
      } else {
        dispatch(showModal(ALERT_MESSAGES.DRUGS_EMPTY_STRUCTURE));
      }
    });
  };

  const getMolecularStructure = ({ type, primaryKey, secondaryType, secondaryKey }, original) => {
    if (formRef.current) {
      const { values } = original ? { values: original } : formRef.current;
      let canFetchParameters = values[primaryKey].trim() !== currentSmilesFormula.trim();
      if (type === 'name') {
        canFetchParameters = values[primaryKey].trim() !== currentChemicalName.trim();
      }
      if (values[primaryKey].trim()) {
        setDisableIcons(true);
        dispatch(
          setDrugValidationErrors({
            data: {
              smilesFormula: false,
              chemicalName: false,
            },
            parentKey: 'chemStructure',
            childTabKey: 'molecularData',
          }),
        );
        marvinInstance
          .importStructure(type, values[primaryKey])
          .then(() => {
            marvinInstance
              .exportStructure(secondaryType)
              .then(response => {
                formRef.current.setFieldValue(secondaryKey, response);
                if (response && canFetchParameters) {
                  upsertJChemDatabase({
                    [secondaryKey]: response,
                    [primaryKey]: values[primaryKey],
                  });
                }
                setDisableIcons(false);
              })
              .catch(() => {
                if (secondaryType === 'name' && canFetchParameters) {
                  upsertJChemDatabase({
                    [secondaryKey]: '',
                    [primaryKey]: values[primaryKey],
                  });
                } else {
                  dispatch(
                    setDrugValidationErrors({
                      data: {
                        ...molecularDataErrors,
                        smilesFormula: true,
                      },
                      parentKey: 'chemStructure',
                      childTabKey: 'molecularData',
                    }),
                  );
                  dispatch(showModal(ALERT_MESSAGES.DRUGS_SMILES_ERROR));
                }
                setDisableIcons(false);
              });
          })
          .catch(() => {
            if (type === 'smiles') {
              dispatch(showModal(ALERT_MESSAGES.DRUGS_SMILES_ERROR));
              dispatch(
                setDrugValidationErrors({
                  data: {
                    ...molecularDataErrors,
                    smilesFormula: true,
                  },
                  parentKey: 'chemStructure',
                  childTabKey: 'molecularData',
                }),
              );
            } else {
              dispatch(showModal(ALERT_MESSAGES.DRUGS_CHEMICAL_NAME_ERROR));
              dispatch(
                setDrugValidationErrors({
                  data: {
                    ...molecularDataErrors,
                    chemicalName: true,
                  },
                  parentKey: 'chemStructure',
                  childTabKey: 'molecularData',
                }),
              );
            }
            setDisableIcons(false);
          });
      } else {
        marvinInstance.clear();
        dispatch(
          updateDrugDetails({
            key: 'chemStructure',
            data: {
              ...chemStructure,
              molecularData: {
                ...chemStructure.molecularData,
                ...initialValues,
              },
            },
          }),
        );
      }
    }
  };

  const updatedFormSchema = {
    ...formSchema,
    smilesFormula: {
      ...formSchema.smilesFormula,
      props: {
        ...formSchema.smilesFormula.props,
        onBlur: () =>
          getMolecularStructure({
            type: 'smiles',
            primaryKey: 'smilesFormula',
            secondaryType: 'name',
            secondaryKey: 'chemicalName',
          }),
      },
    },
    chemicalName: {
      ...formSchema.chemicalName,
      props: {
        ...formSchema.chemicalName.props,
        onBlur: () =>
          getMolecularStructure({
            type: 'name',
            primaryKey: 'chemicalName',
            secondaryType: 'smiles',
            secondaryKey: 'smilesFormula',
          }),
      },
    },
  };

  const handleSave = tabData => {
    if (formRef.current) {
      const { values } = formRef.current;
      if (invalidSmilesData) {
        dispatch(showModal(ALERT_MESSAGES.DRUGS_SMILES_ERROR));
      } else if (invalidName) {
        dispatch(showModal(ALERT_MESSAGES.DRUGS_CHEMICAL_NAME_ERROR));
      } else {
        dispatch(
          postDrugsData({
            id: drugsId,
            body: {
              key,
              chemStructure: {
                ...chemStructure,
                molecularData: values,
              },
              forceToFieldUpdate: 16,
              drugType,
              ingestedContentResults: [],
              ingestedId,
            },
            tabData,
            childTabKey: ['molecularData', 'id'],
          }),
        );
      }
    }
  };

  const proceedToClear = status => {
    if (status) {
      dispatch(
        setDrugValidationErrors({
          data: {
            smilesFormula: false,
            chemicalName: false,
          },
          parentKey: 'chemStructure',
          childTabKey: 'molecularData',
        }),
      );
      dispatch(resetDrugDetailsWithKey({ childKey: 'molecularData', parentKey: 'chemStructure' }));
      if (originalMolecularData.smilesFormula) {
        getMolecularStructure(
          {
            type: 'smiles',
            primaryKey: 'smilesFormula',
            secondaryType: 'name',
            secondaryKey: 'chemicalName',
          },
          originalMolecularData,
        );
      } else if (originalMolecularData.chemicalName) {
        getMolecularStructure(
          {
            type: 'name',
            primaryKey: 'chemicalName',
            secondaryType: 'smiles',
            secondaryKey: 'smilesFormula',
          },
          originalMolecularData,
        );
      }
      if (formRef && formRef.current) {
        formRef.current.resetForm();
      }
    } else {
      setClearModal(false);
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={2}
        className="molecular-data-container"
      >
        <FeatureFooter
          handleSaveAndNext={() => handleSave(data)}
          handleSave={() => handleSave(null)}
          handleClear={() => setClearModal(true)}
          data={data}
          disabled={disableEdit || disableIcons}
        />
        <Grid item xs={4}>
          <Formik initialValues={updatedInitialValues} enableReinitialize>
            {props => {
              formRef.current = props;
              return (
                <FormBuilder
                  formikProps={props}
                  layoutSchema={layoutSchema}
                  formSchema={updatedFormSchema}
                  onSubmitValidationError={() => {}}
                  disableControls={disableEdit}
                />
              );
            }}
          </Formik>
        </Grid>
        <Grid item xs={8}>
          <div className="editor-buttons">
            <InputButton
              text="Get Parameters from Structure"
              buttonType="secondary-btn"
              buttonSize="md"
              onClick={() => loadMolecularParameters()}
              isDisabled={disableEdit}
            ></InputButton>
          </div>
          <div className={disableEdit ? 'marvin-editor' : ''}>
            <iframe
              id="sketch"
              width="800px"
              height="700px"
              src={`${window.location.origin}/marvinjs-20.12.0/editorws.html`}
            ></iframe>
          </div>
        </Grid>
      </Grid>
      {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
    </>
  );
});

MolecularData.propTypes = {
  permissions: PropTypes.object.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isTab: PropTypes.bool.isRequired,
    tabs: PropTypes.array.isRequired,
    hasIngestedData: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    selectedTabIndex: PropTypes.number.isRequired,
  }),
};

MolecularData.displayName = 'MolecularData';

export default MolecularData;
