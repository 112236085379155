import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Grid, Divider, Paper } from '@material-ui/core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';

import Modal from '../../../framework/modal';
import NavbarHeader from '../../../framework/navbar/navbarHeader';
import { InputSelect, InputTextArea, InputButton } from '../../../framework/inputs';
import {
  getUserGroupsData,
  sendCrossTeamAlert,
  fetchCrossTeamComment,
} from '../../../store/actions/globalActions';
import { TEAM_ENTITY_TYPES } from './config';
import './index.scss';

const CrossTeamAlertModal = ({
  onClose,
  navBarConfig,
  recordData,
  recordData: { recordId, entity },
}) => {
  const [userGroups, setUserGroups] = useState([]);
  const data = useSelector(state => state.globalState.crossAlertUserGroupsData, shallowEqual);
  const {
    userGroupsList,
    latestComment: { comment, createdBy, createdDate },
  } = data;
  const dispatch = useDispatch();
  const formFields = {
    userGroup: '',
    commentText: '',
  };
  const [formValues, setFormValues] = useState(formFields);
  const [fieldErrors, setFieldErrors] = useState({
    userGroup: false,
    commentText: false,
  });
  const isInvalid = value => {
    return value === '' || value === null || value === undefined;
  };
  useEffect(() => {
    dispatch(getUserGroupsData());
  }, []);
  useEffect(() => {
    const formattedData = [
      ...userGroupsList.map(userGrp => ({
        ...userGrp,
        label: userGrp.userGroup,
        value: userGrp.queueId,
      })),
    ];
    setUserGroups(formattedData);
  }, [userGroupsList]);

  useEffect(() => {
    const { userGroup } = formValues;
    if (!isInvalid(userGroup)) {
      const { queueId } = userGroup;
      dispatch(fetchCrossTeamComment({ recordId, queueId }));
    }
  }, [formValues.userGroup]);

  const handleFieldChange = (event, field) => {
    if (field === 'commentText') {
      event.stopPropagation();
    }
    setFieldErrors(values => ({
      ...values,
      [field]: false,
    }));
    const data = field === 'commentText' ? event.target.value : event;
    setFormValues(values => ({
      ...values,
      [field]: data,
    }));
  };

  const handleSubmit = e => {
    const { userGroup, commentText } = formValues;
    if (!userGroup) {
      setFieldErrors(values => ({
        ...values,
        userGroup: true,
      }));
    }
    if (!commentText || (commentText && commentText.trim().length === 0)) {
      setFieldErrors(values => ({
        ...values,
        commentText: true,
      }));
    }
    if (!isInvalid(userGroup) && !isInvalid(commentText)) {
      const postAlertData = {
        queueId: userGroup.value,
        comment: commentText.trim(),
        crossTeamRecordId: recordId,
        crossTeamEntityTypeId: TEAM_ENTITY_TYPES[entity],
      };
      dispatch(sendCrossTeamAlert({ postAlertData, recordId }));
      onClose();
    }
  };
  return (
    <Modal isOpen showSpinner onClose={onClose} heading="CROSS TEAM ALERT">
      <div className="cross-team-modal-wrapper">
        <NavbarHeader detailPage navBarConfig={navBarConfig} showAlertFlag={false} modalView />
        <InputSelect
          height="ht-lg"
          isClearable
          data={userGroups}
          placeholder="Select a Team"
          value={formValues.userGroup}
          onChange={data => handleFieldChange(data, 'userGroup')}
          isMandatory
          label="TEAM NAME"
          error={fieldErrors.userGroup}
          helperText="No team in the selected user group. Please select a team."
        />
        <Divider variant="middle" />
        {comment ? (
          <Paper className="comment-note" elevation={0} variant="outlined" square>
            <div>
              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={2}>
                  <FontAwesomeIcon icon={faUser} /> {createdBy}
                </Grid>
                <Grid item xs={2}>
                  {format(new Date(createdDate), 'dd-MMM-yyyy')}
                </Grid>
                <Grid item xs={1}>
                  {format(new Date(createdDate), 'kk-mm')}
                </Grid>
              </Grid>
              {comment}
            </div>
          </Paper>
        ) : null}

        <Paper className="comment-section" variant="outlined" square>
          <Grid container direction="row" justify="center" spacing={2} alignItems="flex-start">
            <Grid item xs={10}>
              <InputTextArea
                value={formValues.commentText}
                rowsMin={2}
                rowsMax={2}
                onChange={e => handleFieldChange(e, 'commentText')}
                placeholder="Enter comments"
                isError={fieldErrors.commentText}
                errorMessage="Please enter a comment"
              />
            </Grid>
            <Grid item xs={2}>
              <InputButton
                type="submit"
                text="SUBMIT"
                buttonType="primary-btn"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </Modal>
  );
};

CrossTeamAlertModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

CrossTeamAlertModal.defaultProps = {
  onClose: () => {},
};

export default CrossTeamAlertModal;
