export const validate = values => {
  const errors = {};
  if (!values.teamName.trim()) {
    errors.teamName = 'Please provide team name';
  }

  return errors;
};

export const TEAM_ENTITY_TYPES = {
  TRAILS: 1,
  DRUGS: 2,
  COMPANY: 3,
  PERSON: 4,
  ORGANIZATION: 5,
};
