import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { scrollElementByName } from '../../../utils/generic/helper';

const FocusError = () => {
  const { errors, isSubmitting, isValidating, values } = useFormikContext();
  useEffect(() => {
    if (isSubmitting && !isValidating) {
      let keys = Object.keys(errors);
      if (keys.length > 0) {
        scrollElementByName(keys[0], values.fieldName);
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

export default FocusError;
