import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import './index.scss';

const ToolTipInputIcon = ({ size, color, label, labelSize }) => {
  const classNames = `font-size-${size} icon-color-${color}`;
  return (
    <Tooltip title="Active">
    <div className="icon-container">
      <FontAwesomeIcon className={`${classNames}`} icon={faCircle} />
      <label className={`font-size-${labelSize}`}>{label}</label>
    </div>
    </Tooltip>
  );
};

ToolTipInputIcon.propTypes = {
  label: PropTypes.string,
  labelSize: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
};

ToolTipInputIcon.defaultProps = {
  size: '16',
  color: 'green',
};

export default ToolTipInputIcon;
