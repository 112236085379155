import * as Yup from 'yup';
import { fieldTypes } from '../../framework/formBuilder/utils';
import { TEXT_LIMIT } from '../../utils/generic/constants';
import { validateUrl } from '../../utils/generic/helper';

export const validate = (values, isValidate) => {
  const errors = {};

  const isTrialSearchOptionsShown = values.searchOption?.some(value =>
    [1, 2].includes(value.searchOptionType?.id || value.id),
  );
  if (values.registryStatisticsLink && values.registryStatisticsLink.length > 400) {
    errors.registryStatisticsLink = 'Please enter only 400 characters';
  }
  if (values.registryStatisticsLink && validateUrl(values.registryStatisticsLink)) {
    errors.registryStatisticsLink = 'Please enter the valid URL';
  }
  if (values.trialSearchOptions?.trim() && values.trialSearchOptions.length > TEXT_LIMIT[3000]) {
    errors.trialSearchOptions = 'Please enter only 3000 characters';
  }
  if (values.numOfDataEntry > 0) {
    for (let i = 0; i < values.numOfDataEntry; i++) {
      let title = `title${i + 1}`;
      let section = `section${i + 1}`;
      let description = `description${i + 1}`;
      if (values[title]?.trim() && values[title].length > 400) {
        errors[title] = 'Please enter only 400 characters';
      }
      if (values[section]?.trim() && values[section].length > 400) {
        errors[section] = 'Please enter only 400 characters';
      }
      if (values[description]?.trim() && values[description].length > TEXT_LIMIT[3000]) {
        errors[description] = 'Please enter only 3000 characters';
      }
    }
    if (values.dataEntryField?.length > 0) {
      values.dataEntryField.forEach((value, index) => {
        if (!value.title?.trim()) {
          errors[`title${index + 1}`] = 'Please enter title';
        }
      });
    } else {
      errors.title1 = 'Please enter title';
    }
  }
  if (isValidate) {
    // if (values.dataEntryField?.length > 0) {
    //   values.dataEntryField.forEach((value, index) => {
    //     if (!value.title?.trim()) {
    //       errors[`title${index + 1}`] = 'Please enter title';
    //     }
    //   });
    // } else {
    //   errors.title1 = 'Please enter title';
    // }
    if (!values.searchOption.length) {
      errors.searchOption = 'Please select trial search options';
    }

    if (
      isTrialSearchOptionsShown &&
      !(values.isCurrentTab === true
        ? values.trialSearchOptions?.trim()
        : values.searchOption[0]?.listParameters)
    ) {
      errors.trialSearchOptions = 'Please enter trial search options';
    }

    if (values.registryStatistics === null) {
      errors.registryStatistics = 'Please enter registry statistics';
    }

    if (values.isApiAvailable === null) {
      errors.isApiAvailable = 'Please select registry api available';
    }
  }
  return errors;
};

export const voluntaryOrMandatoryOptions = [
  { id: 1, value: 'Mandatory' },
  { id: 2, value: 'Voluntary' },
  { id: 3, value: 'Conditionally required' },
  { id: 4, value: 'Read only' },
];

export const publicOrPrivateOptions = [
  { id: 1, value: 'Public' },
  { id: 2, value: 'Private' },
];

export const createDataEntry = ({ numOfDataEntry, values, registryId, dataEntryField }) =>
  [...Array(numOfDataEntry)].map((_, index) => ({
    id: dataEntryField[index]?.id || 0,
    registryId,
    productionId: values[`productionId${index + 1}`] || 0,
    section: values[`section${index + 1}`],
    title: values[`title${index + 1}`],
    description: values[`description${index + 1}`],
    voluntaryOrMandatory:
      values[`voluntaryOrMandatory${index + 1}`]?.filter(obj => obj.isChecked)?.[0] || undefined,
    publicOrPrivate:
      values[`publicOrPrivate${index + 1}`]?.filter(obj => obj.isChecked)?.[0] || undefined,
    orderId: index + 1,
    voluntaryOrMandatory:
      values[`voluntaryOrMandatory${index + 1}`]?.filter(obj => obj.isChecked)?.[0] || undefined,
    publicOrPrivate:
      values[`publicOrPrivate${index + 1}`]?.filter(obj => obj.isChecked)?.[0] || undefined,
  }));

export const createDataEntryInitialValues = (values = []) => {
  const updatedValues =
    values.length > 0 ? values : [{ section: '', title: '', description: '', productionId: 0 }];
  return updatedValues.reduce(
    (init, value, index) => ({
      ...init,
      [`productionId${index + 1}`]: value.productionId,
      [`section${index + 1}`]: value.section,
      [`title${index + 1}`]: value.title,
      [`description${index + 1}`]: value.description,
      [`voluntaryOrMandatory${index + 1}`]: voluntaryOrMandatoryOptions.map(obj => {
        if (obj.id === value.voluntaryOrMandatory?.id) {
          return { ...obj, isChecked: true };
        } else if (value.voluntaryOrMandatory?.id) {
          return { ...obj, disabled: true, isChecked: false };
        } else return obj;
      }),
      [`publicOrPrivate${index + 1}`]: publicOrPrivateOptions.map(obj => {
        if (obj.id === value.publicOrPrivate?.id) {
          return { ...obj, isChecked: true };
        } else if (value.publicOrPrivate?.id) {
          return { ...obj, disabled: true, isChecked: false };
        } else return obj;
      }),
    }),
    {},
  );
};

export const createDateEntryFields = (number, handleAddDelEntry) => {
  const layout = [...Array(number)].reduce(
    (init, _, index) => [
      ...init,
      {
        layout: 'row',
        spacing: 0.5,
        alignItems: 'space-between',
        justify: 'space-between',
        content: [
          {
            layout: `section${index + 1}`,
            size: 1,
          },
          {
            layout: `title${index + 1}`,
            size: 2,
          },
          {
            layout: `description${index + 1}`,
            size: 3,
          },
          {
            layout: `icons${index + 1}`,
            size: 1,
          },
          {
            layout: `voluntaryOrMandatory${index + 1}`,
            size: 2,
            className: 'mt-20',
          },
          {
            layout: `publicOrPrivate${index + 1}`,
            size: 1,
            className: 'mt-20',
          },
        ],
      },
    ],
    [],
  );

  const form = [...Array(number)].reduce(
    (init, _, index) => ({
      ...init,
      [`section${index + 1}`]: {
        label: 'section',
        name: `section${index + 1}`,
        type: fieldTypes.text,
        props: {
          isMandatory: false,
          style: {},
        },
      },
      [`title${index + 1}`]: {
        label: 'title',
        name: `title${index + 1}`,
        type: fieldTypes.text,
        props: {
          isMandatory: true,
          style: {},
        },
      },
      [`description${index + 1}`]: {
        label: 'description',
        name: `description${index + 1}`,
        type: fieldTypes.text,
        props: {
          isMandatory: false,
          style: {},
        },
      },
      [`icons${index + 1}`]: {
        type: fieldTypes.icons,
        props: {
          index: index + 1,
          addDelEntryRow: (index, todo) => {
            handleAddDelEntry(index, todo);
          },
        },
      },
      [`voluntaryOrMandatory${index + 1}`]: {
        type: fieldTypes.checkbox,
        name: `voluntaryOrMandatory${index + 1}`,
        options: voluntaryOrMandatoryOptions,
        props: {},
      },
      [`publicOrPrivate${index + 1}`]: {
        type: fieldTypes.checkbox,
        name: `publicOrPrivate${index + 1}`,
        options: publicOrPrivateOptions,
        props: {},
      },
    }),
    {},
  );

  return {
    layout,
    form,
  };
};

export const createValidationSchema = number => {
  const validation = [...Array(number)].reduce((init, _, index) => {
    return {
      ...init,
      [`title${index + 1}`]: Yup.string().required('value is required'),
      [`description${index + 1}`]: Yup.string().required('value is required'),
    };
  }, {});
  return Yup.object({
    searchOption: Yup.number().required('value is required'),
    registryStatistics: Yup.number().required('value is required'),
    isApiAvailable: Yup.number().required('value is required'),
    ...validation,
  });
};
export const layoutSchema = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  style: {
    gap: '1rem',
  },
  content: [
    {
      layout: 'searchOption',
      size: 5,
    },
    {
      layout: 'trialSearchOptions',
      size: 10,
    },
    {
      layout: 'registryStatistics',
      size: 3,
    },
    {
      layout: 'isApiAvailable',
      size: 4,
    },
    // {
    //   layout: 'numberOfDataEntryFields',
    //   size: 12,
    //   className: 'isApiAvailable',
    // },
  ],
};
export const layoutSchemaCount = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'numberOfDataEntryFields',
      size: 5,
      className: 'isApiAvailable',
    },
    { layout: 'exportButton', size: 2 },
    { layout: 'importButton', size: 2 },
  ],
};

export const formSchemaCount = {
  numberOfDataEntryFields: {
    label: 'Number of data entry fields',
    name: 'numberOfDataEntryFields',
    type: fieldTypes.selectNumber,
    props: {},
  },
  exportButton: {
    name: 'button',
    type: fieldTypes.button,
    label: 'Export',
  },

  importButton: {
    name: 'fileName',
    type: fieldTypes.attachFile,
    label: 'IMPORT',
    props: {
      height: 'ht-lg',
      isMandatory: false,
      multiple: false,
      acceptFileType: '.xlsx, .xls',
      customFilePicker: true,
    },
  },
};

export const formSchema = {
  searchOption: {
    label: 'Trial search options (check all that apply)',
    name: 'searchOption',
    type: fieldTypes.select_tag,
    options: [
      { id: 1, value: 'Basic', label: 'Basic' },
      { id: 2, value: 'Advanced', label: 'Advanced' },
      { id: 3, value: 'None', label: 'None' },
    ],
    props: {
      isMandatory: true,
    },
  },
  trialSearchOptions: {
    label: 'trial search options-list advanced search parameters',
    name: 'trialSearchOptions',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 5,
      cols: 10,
      placeholder: '',
      isMandatory: true,
    },
  },
  registryStatistics: {
    label: 'registry statistics',
    name: 'registryStatistics',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: true,
    },
  },
  isApiAvailable: {
    label: 'registry api available?',
    name: 'isApiAvailable',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: true,
    },
  },
  // numberOfDataEntryFields: {
  //   label: 'Number of data entry fields',
  //   name: 'numberOfDataEntryFields',
  //   type: fieldTypes.selectNumber,
  //   props: {},
  // },
};

export const mandatoryFields = [
  'disclosureCountries',
  'registryAbbreviatedName',
  'registryName',
  'isGeographicalRequirement',
  'primaryOrPartner',
  'isICMJEComplaint',
  'isProspectiveRegistration',
  'isRetrospectiveRegistration',
  'registryLanguage',
  'isQcReview',
  'registryGovernance',
  'url',
];

export const setTouchedMandatoryFields = value =>
  mandatoryFields.reduce((init, field) => {
    return { ...init, [field]: value };
  }, {});

export const createTitleFileds = (num, value) =>
  [...Array(num)].reduce((init, _, index) => {
    return {
      ...init,
      [`title${index + 1}`]: value,
    };
  }, {});
