import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';
import {
  sideBarSelection,
  setSelectedTabIndex,
  getSourceURLReportSuccess,
  getAgingReportSuccess,
  getDiseasingReportSuccess,
} from '../actions/reporting';

const initialState = InitialState.reporting;

const reporting = createReducer(initialState, {
  [sideBarSelection]: (state, action) => {
    state.selected = action.payload.value;
  },
  [setSelectedTabIndex]: (state, { payload: { id, tabIndex } }) => {
    const index = state.tabs.findIndex((i) => i.value === id);
    if (index > -1) {
      state.tabs[index].selectedTabIndex = tabIndex;
    }
  },

  [getSourceURLReportSuccess]: (state, action) => {
    state.sourceURLReportData = action.payload;
  },
  [getAgingReportSuccess]: (state, action) => {
    state.agingReportData = action.payload;
  },
  [getDiseasingReportSuccess]: (state, action) => {
    state.diseasingReportData = action.payload;
  },
});

export default reporting;
