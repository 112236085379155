import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';
import {
  deleteCountrySuccess,
  countryFeatureSelection,
  countryNextFeatureSelection,
  setSelectedTabIndex,
  saveCountryDetails,
  saveCountryDetailsSuccess,
  getCountryMasterDataSuccess,
  getCountryDetailsSuccess,
  validateCountrySuccess,
  setCountryValidationStatus,
  setCountryUnSavedStatus,
  setErrorDetails,
  publishCountrySuccess,
  moveOutofScopeCountrySuccess,
  getStagedIdCountrySuccess,
  countryRecordChangeFldsListSuccess,
  getCountryStampingDetailsSuccess,
  updateCountryRecordDatesDetail,
  getCountryUserLockDetails,
  resetCountryUserLock,
  resetStagedIdCountry,
} from '../actions/country';

const initialState = InitialState.country;
const country = createReducer(initialState, {
  [countryFeatureSelection]: (state, { payload: { value } }) => {
    state.selected = value;
  },
  [countryNextFeatureSelection]: (state, { payload }) => {
    const filteredTabs = state.tabs.filter(i => i.value === payload);
    const index = state.tabs.findIndex(i => i.value === payload);
    const isSingleModeActive = state.isSinglePageMode;
    if (filteredTabs.length > 0) {
      const { isTab, selectedTabIndex, tabs } = filteredTabs[0];
      if (isTab) {
        if (selectedTabIndex < tabs.length - 1) {
          state.tabs[index].selectedTabIndex = selectedTabIndex + 1;
        } else if (selectedTabIndex === tabs.length - 1) {
          const tabValue = state.tabs[index + 1].value;
          isSingleModeActive ? (state.previewSelected = tabValue) : (state.selected = tabValue);
          state.tabs[index].selectedTabIndex = 0;
        }
      } else if (isSingleModeActive) {
        state.previewSelected = state.tabs[index + 1].value;
      } else {
        state.selected = state.tabs[index + 1].value;
      }
    }
  },
  [setSelectedTabIndex]: (state, { payload: { id, tabIndex } }) => {
    const index = state.tabs.findIndex(i => i.value === id);
    if (index > -1) {
      state.tabs[index].selectedTabIndex = tabIndex;
    }
  },
  [deleteCountrySuccess]: (state, { payload }) => {
    const results = state.dashboard.queues.queueRecords.results;
    state.dashboard.queues.queueRecords.results = results.filter(entry => entry.id !== payload);
  },
  [saveCountryDetails]: (state, { payload: { key } }) => {
    const selected = state.tabs.find(tab => tab.key === key);
    if (selected) {
      selected.hasError = false;
    }
  },
  [saveCountryDetailsSuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
    for (let i = 0; i < state.tabs.length; i++) {
      state.tabs[i].hasError = false;
      state.tabs[i].hasUnSavedData = false;
      state.tabs[i].error = null;
    }
    state.isStamped = false;
  },
  [getCountryMasterDataSuccess]: (state, { payload }) => {
    state.masterData = payload;
  },
  [getCountryDetailsSuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },
  [validateCountrySuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },
  [publishCountrySuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },
  [moveOutofScopeCountrySuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },
  [setCountryValidationStatus]: (state, { payload }) => {
    const { tabId, hasError } = payload;
    state.tabs[tabId].hasError = hasError;
  },
  [setErrorDetails]: (state, data = {}) => {
    const index = data.payload.tabs.findIndex(i => i.key === data.payload.tabIndex);
    state.tabs[index].error = data.payload.errorDetails;
  },
  [setCountryUnSavedStatus]: (state, { payload }) => {
    const selected = payload === 'RecordUpdateAndSummary' ? 0 : 1;
    state.tabs[selected].hasUnSavedData = true;
  },
  [getStagedIdCountrySuccess]: (state, { payload }) => {
    state.stageId = payload;
  },
  [countryRecordChangeFldsListSuccess]: (state, { payload }) => {
    state.changeFields = payload;
  },
  [getCountryStampingDetailsSuccess]: (state, { payload: { data } }) => {
    state.isStamped = true;
    state.current = data;
    state.original = data;
  },
  [updateCountryRecordDatesDetail]: (state, { payload: { key, data } }) => {
    state.current[key] = data;
    state.original[key] = data;
  },
  [getCountryUserLockDetails]: (state, { payload: { isEditable, lockedBy, userLockPeriod } }) => {
    const updatedData = {
      isLocked: !isEditable,
      lockedBy,
      userLockPeriod,
    };
    state.countryLockDetails = updatedData;
  },
  [resetCountryUserLock]: state => {
    const { countryLockDetails } = initialState;
    state.countryLockDetails = countryLockDetails;
  },
  [resetStagedIdCountry]: state => {
    state.stageId = null;
  },
});

export default country;
