import { format } from 'date-fns';
import { isEmpty, omit } from 'lodash';

import {
  formIngestedData,
  updateCurrentDataBasedOnIngestion,
  formatIngestedData,
  checkForIngestionAction,
  checkIfStringIsEmpty,
  checkUserIngestionAction,
} from '../../utils/generic/helper';
import trialFeaturesInitialState from '../../utils/clinicalTrials/featuresInitialState';

export const checkForValidDate = date => {
  let isValidDate = false;
  if (date) {
    const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    isValidDate = regex.test(format(new Date(date), 'dd/MM/yyyy'));
  }
  return isValidDate ? date : null;
};

export const validateCustomDate = date => {
  return date !== null && date !== '0001-01-01T00:00:00' ? new Date(date) : null;
};

export const getPatientPopulationAgeValues = patientPopulation => {
  let data = {
    minimumAge: '',
    minimumAgeUnit: null,
    maximumAge: '',
    maximumAgeUnit: null,
  };
  if (!isEmpty(patientPopulation.minimumAge)) {
    const { minimumAge, minimumAgeUnitId, minimumAgeUnitDesc } = patientPopulation.minimumAge;
    data = {
      ...data,
      minimumAge: minimumAge ? minimumAge.toString() : '',
      minimumAgeUnit:
        minimumAgeUnitId && minimumAgeUnitDesc
          ? {
            label: minimumAgeUnitDesc,
            value: minimumAgeUnitId,
          }
          : null,
    };
  }

  if (!isEmpty(patientPopulation.maximumAge)) {
    const { maximumAge, maximumAgeUnitId, maximumAgeUnitDesc } = patientPopulation.maximumAge;
    data = {
      ...data,
      maximumAge: maximumAge ? maximumAge.toString() : '',
      maximumAgeUnit:
        maximumAgeUnitId && maximumAgeUnitDesc
          ? {
            label: maximumAgeUnitDesc,
            value: maximumAgeUnitId,
          }
          : null,
    };
  }

  return data;
};

export const setMasterData = data => {
  const {
    source,
    therapeuticArea,
    phase,
    globalStatus,
    productionStatus,
    armType,
    patientSegment,
    sponsorType,
    terminationReason,
    role,
    person,
    company,
    timePointUnits,
    outcomeStatus,
    resultTypes,
    sourceTypes,
    journalNames,
    meetingNames,
    resultForms,
    noteTypes,
    notesForms,
    supportingUrlStatuses,
  } = data;
  return {
    sourceMasters: source || [],
    therapeuticAreaMasters: therapeuticArea || [],
    phaseMasters: phase || [],
    globalStatusMasters: globalStatus || [],
    productionStatusMasters: productionStatus || [],
    armTypeMasters: armType || [],
    patientSegmentMasters: patientSegment || [],
    sponsorTypeMasters: sponsorType || [],
    terminationReasonMasters: terminationReason || [],
    roleMasters: role || [],
    personMasters: person || [],
    companyMasters: company || [],
    outcomeMasters: outcomeStatus || [],
    timePointUnits: timePointUnits || [],
    resultTypeMasters: resultTypes || [],
    sourceTypeMasters: sourceTypes || [],
    journalNames: journalNames || [],
    meetingNames: meetingNames || [],
    defaultSchemaForSourceType: resultForms || [],
    noteTypeMasters: noteTypes || [],
    defaultSchemaForNoteType: notesForms || [],
    supportingUrlStatuses: [
      { id: 1, value: 'Active' },
      { id: 2, value: 'InActive' },
      { id: 3, value: 'Broken' },
    ],
    genderTypes: [
      { label: 'Male', value: 1 },
      { label: 'Female', value: 2 },
      { label: 'Both', value: 3 },
      { label: 'N/A', value: 4 },
    ],
    ageUnits: [
      { label: 'Year(s)', value: 1 },
      { label: 'Month(s)', value: 2 },
      { label: 'Week(s)', value: 3 },
      { label: 'Day(s)', value: 4 },
      { label: 'Minute(s)', value: 9 },
      { label: 'Hour(s)', value: 10 },
    ],
  };
};

// eslint-disable-next-line complexity
export const setTrialData = data => {
  const {
    id,
    ingestedId,
    trialSummary,
    trialTiming,
    trialContact,
    patientPopulation,
    outcome,
    objective,
    trialTreatmentPlan,
    trialResult,
    trialNotes,
    productionStatusID,
  } = data;
  let result = { id, ingestedId };
  const {
    name = '',
    source = null,
    protocolIds = [],
    therapeuticDiseasePatients = [],
    phase = null,
    globalStatus = null,
    productionStatus = null,
    primaryDrugs = [],
    secondaryDrugs = [],
    sponsors = [],
    collaborators = [],
    associatedCROs = [],
    tags = [],
    locations = [],
    allLocations = [],
    primaryURL = null,
    supportingURL = [],
    createdDate = null,
    createdBy = '',
    modifiedDate = null,
    modifiedBy = '',
    lastFullReviewDate = null,
    lastFullReviewedBy = '',
    nextFullReviewDate = null,
    recordInceptionDate = null,
  } = { ...trialSummary };

  result = {
    ...result,
    trialSummary: {
      name,
      source,
      protocolIds: protocolIds
        ? protocolIds.map(p => {
          return {
            text: p,
          };
        })
        : [],
      therapeuticDiseasePatients: therapeuticDiseasePatients || [],
      phase,
      globalStatus,
      productionStatus,
      primaryDrugs: primaryDrugs || [],
      secondaryDrugs: secondaryDrugs || [],
      sponsors: sponsors || [],
      collaborators: collaborators || [],
      associatedCros: associatedCROs || [],
      tags: tags || [],
      locations: locations || [],
      allLocations: allLocations || [],
      primaryUrl: primaryURL,
      supportingUrls: supportingURL || [],
      createdDate,
      createdBy,
      modifiedDate,
      modifiedBy,
      lastFullReviewDate,
      lastFullReviewedBy,
      nextFullReviewDate,
      recordInceptionDate,
    },
  };

  const {
    reportedSites = '',
    identifiedSites = '',
    startDateActual = null,
    startDateTarget = null,
    enrollmentCloseDateActual = null,
    enrollmentCloseDateTarget = null,
    primaryCompletionDateActual = null,
    primaryCompletionDateTarget = null,
    primaryEndPointsReportedActual = null,
    primaryEndPointsReportedTarget = null,
    fullCompletionDateTargetFinal = null,
    fullCompletionDateActual = null,
    enrollmentDurationActual = '',
    enrollmentDurationTarget = '',
    treatmentDurationActual = '',
    treatmentDurationTarget = '',
    timetoPublishActual = '',
    timetoPublishTarget = '',
  } = { ...trialTiming };

  result = {
    ...result,
    trialTiming: {
      reportedSites,
      identifiedSites,
      actualStartDate: validateCustomDate(startDateActual),
      actualEnrollmentCloseDate: validateCustomDate(enrollmentCloseDateActual),
      actualPrimaryCompletionDate: validateCustomDate(primaryCompletionDateActual),
      actualPrimaryEndpointReported: validateCustomDate(primaryEndPointsReportedActual),
      actualFullCompletionDate: validateCustomDate(fullCompletionDateActual),
      anticipatedStartDate: validateCustomDate(startDateTarget),
      anticipatedEnrollmentCloseDate: validateCustomDate(enrollmentCloseDateTarget),
      anticipatedPrimaryCompletionDate: validateCustomDate(primaryCompletionDateTarget),
      anticipatedPrimaryEndpointReported: validateCustomDate(primaryEndPointsReportedTarget),
      anticipatedFullCompletionFinalDate: validateCustomDate(fullCompletionDateTargetFinal),
      actualEnrollmentDuration: enrollmentDurationActual !== null ? enrollmentDurationActual : '',
      actualTreatmentDuration: treatmentDurationActual !== null ? treatmentDurationActual : '',
      actualTimeToPublish: timetoPublishActual !== null ? timetoPublishActual : '',
      anticipatedEnrollmentDuration:
        enrollmentDurationTarget !== null ? enrollmentDurationTarget : '',
      anticipatedTreatmentDuration: treatmentDurationTarget !== null ? treatmentDurationTarget : '',
      anticipatedTimeToPublish: timetoPublishTarget !== null ? timetoPublishTarget : '',
    },
    productionStatusID: productionStatusID ? productionStatusID : 0,
  };

  const {
    id: patientPopulationId = 0,
    patient_Population = '',
    inclusionCriteria = '',
    exclusionCriteria = '',
    targetAccrual = '',
    targetAccrualComment = '',
    interimAccrual = '',
    interimAccrualComment = '',
    interimAccrualDate = null,
    actualAccrual = '',
    dispositionOfPatients = '',
    priorTherapy = '',
    gender = '',
    minimumAge = null,
    maximumAge = null,
  } = { ...patientPopulation };
  result = {
    ...result,
    patientPopulation: {
      id: patientPopulationId || 0,
      patientPopulation: patient_Population || '',
      inclusionCriteria: inclusionCriteria || '',
      exclusionCriteria: exclusionCriteria || '',
      targetAccrual: isNaN(parseInt(targetAccrual)) ? '' : targetAccrual.toString(),
      targetAccrualComment: targetAccrualComment || '',
      interimAccrual: isNaN(parseInt(interimAccrual)) ? '' : interimAccrual.toString(),
      interimAccrualComment: interimAccrualComment || '',
      date: validateCustomDate(interimAccrualDate),
      actualAccrual: isNaN(parseInt(actualAccrual)) ? '' : actualAccrual.toString(),
      dispositionOfPatients: dispositionOfPatients || '',
      priorConcurrentTherapy: priorTherapy || '',
      patientGender: gender,
      ...getPatientPopulationAgeValues({ minimumAge, maximumAge }),
    },
  };
  const { outcomeStatuses = [], detailsDate = null, detail = '' } = { ...outcome };
  result = {
    ...result,
    trialOutcome: {
      outcomeStatuses,
      detailsDate: validateCustomDate(detailsDate),
      detail: detail || '',
    },
  };

  const {
    title = '',
    primaryEndPointDetail = '',
    objectivePrimaryTerms = [],
    secondaryEndPointDetail = [],
    objectiveSecondaryTerms = [],
  } = { ...objective };
  result = {
    ...result,
    trialObjective: {
      title: title || '',
      primaryEndPointDetail: primaryEndPointDetail || '',
      objectivePrimaryTerms: objectivePrimaryTerms || [],
      secondaryEndPointDetail: secondaryEndPointDetail || '',
      objectiveSecondaryTerms: objectiveSecondaryTerms || [],
    },
  };

  const {
    contactList = [],
    isContactReviewed = false,
    contactReviewedBy = '',
    contactReviewedDate = null,
  } = { ...trialContact };

  result = {
    ...result,
    trialContacts: {
      contactList: contactList
        ? contactList.map(x => ({
          ...omit(x, ['createdBy', 'createdDate', 'modifiedBy', 'modifiedDate']),
        }))
        : [],
      isContactReviewed,
      lastReviewedBy: contactReviewedBy || '',
      lastReviewDate: validateCustomDate(contactReviewedDate),
    },
  };

  const { studykeywords = [], studyDesign = '', treatmentPlan = '', armDetails = [] } = {
    ...trialTreatmentPlan,
  };
  result = {
    ...result,
    trialTreatmentPlan: {
      studyKeywords: studykeywords || [],
      studyDesign: studyDesign || '',
      treatmentPlan: treatmentPlan || '',
      armDetails: armDetails || [],
    },
  };

  const { key: resultsKey = '', resultsData = [] } = { ...trialResult };
  result = {
    ...result,
    trialResults: {
      key: resultsKey || '',
      resultsData: resultsData || [],
    },
  };

  const { key: notesKey = '', notesData = [] } = { ...trialNotes };
  result = {
    ...result,
    trialNotes: {
      key: notesKey || '',
      notesData: notesData || [],
    },
  };
  return result;
};

export const getTrialSummaryRequest = data => {
  const {
    id,
    ingestedId,
    isIngestedNew,
    ingestedContentResults,
    isValidationRequired,
    trialSummary: {
      name,
      source,
      protocolIds,
      therapeuticDiseasePatients,
      phase,
      globalStatus,
      productionStatus,
      primaryDrugs,
      secondaryDrugs,
      sponsors,
      collaborators,
      associatedCros,
      tags,
      locations,
      primaryUrl,
      supportingUrls,
      createdDate,
      createdBy,
      modifiedDate,
      modifiedBy,
      lastFullReviewDate,
      lastFullReviewedBy,
      nextFullReviewDate,
    },
  } = data;
  return {
    trialSummary: {
      name,
      source,
      protocolIds: protocolIds.map(p => p.text),
      therapeuticDiseasePatients,
      phase,
      globalStatus,
      productionStatus,
      primaryDrugs,
      secondaryDrugs,
      sponsors,
      collaborators,
      associatedCROs: associatedCros,
      tags,
      locations,
      primaryURL: primaryUrl,
      supportingURL: supportingUrls,
      createdDate,
      createdBy,
      modifiedDate,
      modifiedBy,
      lastFullReviewDate,
      lastFullReviewedBy,
      nextFullReviewDate: nextFullReviewDate ? new Date(nextFullReviewDate).toDateString() : null,
    },
    isValidationRequired,
    ingestedContentResults,
    isIngestedNew,
    id: parseInt(id),
    ingestedId: parseInt(ingestedId),
    forceUpdate: [1],
  };
};

export const getTrialContactsRequest = data => {
  const {
    id,
    ingestedId,
    isIngestedNew,
    isContactReviewed,
    contactList,
    lastReviewedBy,
    lastReviewDate,
    ingestedContentResults,
    currentPageSize,
    currentPageIndex,
  } = data;
  return {
    trialContact: {
      isContactReviewed,
      contactList,
      contactReviewedBy: lastReviewedBy,
      contactReviewedDate: lastReviewDate ? lastReviewDate.toISOString() : null,
    },
    ingestedContentResults,
    id: parseInt(id),
    isIngestedNew,
    ingestedId: parseInt(ingestedId),
    forceUpdate: [7],
    page: currentPageIndex,
    limit: currentPageSize,
  };
};

export const getTrialObjectiveRequest = data => {
  const {
    id,
    ingestedId,
    isIngestedNew,
    ingestedContentResults,
    trialObjective,
    trialObjective: {
      title,
      primaryEndPointDetail,
      objectivePrimaryTerms,
      secondaryEndPointDetail,
      objectiveSecondaryTerms,
    },
  } = data;
  return {
    objective: {
      ...trialObjective,
      title: title ? title.trim() : '',
      primaryEndPointDetail: primaryEndPointDetail ? primaryEndPointDetail.trim() : '',
      objectivePrimaryTerms,
      secondaryEndPointDetail: secondaryEndPointDetail ? secondaryEndPointDetail.trim() : '',
      objectiveSecondaryTerms,
    },
    ingestedContentResults,
    id: parseInt(id),
    isIngestedNew,
    ingestedId: parseInt(ingestedId),
    forceUpdate: [2],
  };
};

export const getTrialTreatmentPlanRequest = data => {
  const {
    id,
    ingestedId,
    isIngestedNew,
    ingestedContentResults,
    trialTreatmentPlan: { studyKeywords, studyDesign, treatmentPlan, armDetails },
  } = data;
  return {
    trialTreatmentPlan: {
      studykeywords: studyKeywords,
      studyDesign,
      treatmentPlan,
      armDetails,
    },
    ingestedContentResults,
    id: parseInt(id),
    isIngestedNew,
    ingestedId: parseInt(ingestedId),
    forceUpdate: [6],
  };
};

export const getTrialResultsRequest = data => {
  const {
    id,
    ingestedId,
    isIngestedNew,
    ingestedContentResults,
    trialResults: { resultsData },
  } = data;
  return {
    trialResult: {
      key: id,
      resultsData,
    },
    ingestedContentResults,
    isIngestedNew,
    id: parseInt(id),
    ingestedId: parseInt(ingestedId),
    forceUpdate: [8],
  };
};
export const getTrialNotesRequest = data => {
  const {
    id,
    ingestedId,
    isIngestedNew,
    ingestedContentResults,
    trialNotes: { notesData },
  } = data;
  return {
    trialNotes: {
      key: id,
      notesData,
    },
    ingestedContentResults,
    id: parseInt(id),
    isIngestedNew,
    ingestedId: parseInt(ingestedId),
    forceUpdate: [9],
  };
};

export const getPatientPopulationAgeRequest = ({
  maximumAge,
  maximumAgeUnit,
  minimumAge,
  minimumAgeUnit,
}) => {
  let data = {
    maximumAge: null,
    minimumAge: null,
  };
  if (maximumAge && !isEmpty(maximumAgeUnit)) {
    data = {
      ...data,
      maximumAge: {
        maximumAge: maximumAge ? parseFloat(maximumAge) : '',
        maximumAgeUnitId: !isEmpty(maximumAgeUnit) ? maximumAgeUnit.value : '',
        maximumAgeUnitDesc: !isEmpty(maximumAgeUnit) ? maximumAgeUnit.label : '',
      },
    };
  }
  if (minimumAge && !isEmpty(minimumAgeUnit)) {
    data = {
      ...data,
      minimumAge: {
        minimumAge: minimumAge ? parseFloat(minimumAge) : '',
        minimumAgeUnitId: !isEmpty(minimumAgeUnit) ? minimumAgeUnit.value : '',
        minimumAgeUnitDesc: !isEmpty(minimumAgeUnit) ? minimumAgeUnit.label : '',
      },
    };
  }
  return data;
};

export const getPatientPopulationRequest = ({
  id,
  ingestedId,
  isIngestedNew,
  ingestedContentResults,
  patientPopulation: {
    id: patientPopulationId,
    patientPopulation,
    inclusionCriteria,
    exclusionCriteria,
    targetAccrual,
    targetAccrualComment,
    interimAccrual,
    interimAccrualComment,
    date,
    actualAccrual,
    dispositionOfPatients,
    priorConcurrentTherapy,
    patientGender,
    maximumAge,
    maximumAgeUnit,
    minimumAge,
    minimumAgeUnit,
  },
}) => {
  return {
    patientPopulation: {
      id: patientPopulationId,
      patient_Population: patientPopulation || null,
      inclusionCriteria: inclusionCriteria || null,
      exclusionCriteria: exclusionCriteria || null,
      targetAccrual: !checkIfStringIsEmpty(targetAccrual) ? parseInt(targetAccrual) : null,
      targetAccrualComment: targetAccrualComment || null,
      interimAccrual: !checkIfStringIsEmpty(interimAccrual) ? parseInt(interimAccrual) : null,
      interimAccrualComment: interimAccrualComment || null,
      interimAccrualDate: date ? date.toDateString() : date,
      actualAccrual: !checkIfStringIsEmpty(actualAccrual) ? parseInt(actualAccrual) : null,
      dispositionOfPatients: dispositionOfPatients || null,
      priorTherapy: priorConcurrentTherapy || null,
      gender: patientGender || null,
      ...getPatientPopulationAgeRequest({ maximumAge, maximumAgeUnit, minimumAge, minimumAgeUnit }),
    },
    ingestedContentResults,
    id: parseInt(id),
    isIngestedNew,
    ingestedId: parseInt(ingestedId),
    forceUpdate: [5],
  };
};

export const getImportedContactsData = (
  { organizationIds, organizationData, personIds, personData, role, roleData },
  contactList,
) => {
  const validOrganizationIds = organizationData.map(o => o.id);
  const result = [];
  organizationIds.map(o => {
    result.push({
      organization: {
        id: o,
        data: { ...organizationData.find(t => t.id === o) },
        isError: validOrganizationIds.includes(o) || isNaN(o) ? '' : 'Invalid organization id',
      },
    });
  });

  const validPersonIds = personData.map(o => o.id);

  personIds.map((o, index) => {
    const d = { ...result[index] };
    result[index] = {
      ...d,
      person: {
        id: o,
        data: { ...personData.find(t => t.id === o) },
        isError: validPersonIds.includes(o) || isNaN(o) ? '' : 'Invalid person id',
      },
    };
  });

  const validrole = roleData.map(o => o.value.trim().toLowerCase());

  role.map((o, index) => {
    const d = { ...result[index] };
    const isInvalidData = o.toString().toLowerCase() === 'na' || o.toString().trim() === '';
    console.log('row', o.toString().toLowerCase(), isInvalidData);
    result[index] = {
      ...d,
      role: {
        id: isInvalidData ? '' : o,
        data: { ...roleData.find(t => t.value.trim().toLowerCase() === o.trim().toLowerCase()) },
        isError: validrole.includes(o.trim().toLowerCase()) || isInvalidData ? '' : 'Invalid role',
      },
    };
  });

  const data = [...contactList];

  const updateImportContactList = result.map(o => {
    const organizationDetails = o.organization.data;
    const personDetails = o.person.data;
    const roleDetails = o.role.data;
    let isDataExists = false;
    data.map(d => {
      if (
        ((isEmpty(organizationDetails) && isEmpty(d.organization)) ||
          (!isEmpty(organizationDetails) &&
            !isEmpty(d.organization) &&
            d.organization.id === organizationDetails.id)) &&
        ((isEmpty(personDetails) && isEmpty(d.person)) ||
          (!isEmpty(personDetails) && !isEmpty(d.person) && d.person.id === personDetails.id)) &&
        ((isEmpty(roleDetails) && isEmpty(d.role)) ||
          (!isEmpty(roleDetails) && !isEmpty(d.role) && d.role.id === roleDetails.id))
      ) {
        isDataExists = true;
      }
    });

    return {
      ...o,
      isDataExists,
    };
  });

  const checkEmptyData = updateImportContactList.map(d => {
    let isDataNullCheck = '';
    if (isNaN(d.organization.id) && isNaN(d.person.id) && checkIfStringIsEmpty(d.role.id)) {
      isDataNullCheck = 'Please fill organization id or person id';
    } else if (isNaN(d.person.id) && !checkIfStringIsEmpty(d.role.id)) {
      isDataNullCheck = 'Please select a person for the role';
    }
    return {
      ...d,
      isDataNullCheck,
    };
  });

  return checkEmptyData;
};

export const setCurrentAndIngestedData = (currentData, ingestedData, tabs) => {
  const mappedIngestedData = setTrialData(ingestedData);
  const updatedData = {
    ...omit(mappedIngestedData, [
      'id',
      'ingestedId',
      'productionStatusID',
      'trialSummary.productionStatus',
      'trialSummary.createdDate',
      'trialSummary.createdBy',
      'trialSummary.modifiedDate',
      'trialSummary.modifiedBy',
      'trialSummary.lastFullReviewDate',
      'trialSummary.lastFullReviewedBy',
      'trialSummary.nextFullReviewDate',
      'trialTiming.actualEnrollmentDuration',
      'trialTiming.actualTreatmentDuration',
      'trialTiming.actualTimeToPublish',
      'trialTiming.anticipatedEnrollmentDuration',
      'trialTiming.anticipatedTreatmentDuration',
      'trialTiming.anticipatedTimeToPublish',
      'trialTiming.identifiedSites',
      'patientPopulation.id',
      'trialContacts.isContactReviewed',
      'trialContacts.lastReviewedBy',
      'trialContacts.lastReviewDate',
      'trialResults.key',
      'trialNotes.key',
    ]),
  };
  const updatedIngestedData = formIngestedData(updatedData);
  const updatedCurrentData = {
    ...updateCurrentDataBasedOnIngestion(currentData, mappedIngestedData),
    ingestedId: ingestedData.ingestedId,
    ingestedProductionStatusId: ingestedData.productionStatusID,
  };

  const ingestedKeys = Object.keys(updatedIngestedData).filter(key =>
    checkUserIngestionAction(updatedCurrentData[key], updatedIngestedData[key].fieldActions, false),
  );

  const updatedTabs = tabs.map(tab => ({
    ...tab,
    hasIngestedData: ingestedKeys.includes(tab.key),
  }));

  return {
    updatedIngestedData,
    updatedCurrentData,
    ingestedApiData: mappedIngestedData,
    updatedTabs,
  };
};

export const updateTrialsDataOnSave = ({
  apiData,
  key,
  currentData,
  ingestedApiData,
  currentTab,
}) => {
  const updatedApiData = setTrialData(apiData);
  const {
    id,
    ingestedId,
    productionStatusID,
    trialSummary: {
      createdDate,
      createdBy,
      modifiedDate,
      modifiedBy,
      lastFullReviewedBy,
      lastFullReviewDate,
      nextFullReviewDate,
      supportingUrls: apiSupportingUrls,
    },
    trialTiming: { identifiedSites },
    patientPopulation: { patientGender, id: patientPopulationId },
    trialResults: { resultsData: apiResultsData },
    trialNotes: { notesData: apiNotesData },
  } = updatedApiData;

  let updatedCurrentData = {
    ...currentData,
    [key]: updatedApiData[key],
  };

  if (currentData.id === 0 && currentData.ingestedId === 0) {
    updatedCurrentData = { ...updatedApiData };
  }
  updatedCurrentData = {
    ...updatedCurrentData,
    id,
    ingestedId: parseInt(ingestedId, 10) || null,
    productionStatusID: parseInt(productionStatusID, 10) || 0,
    trialSummary: {
      ...updatedCurrentData.trialSummary,
      createdDate,
      createdBy,
      modifiedDate,
      modifiedBy,
      lastFullReviewDate,
      lastFullReviewedBy,
      nextFullReviewDate,
    },
  };

  const {
    trialSummary: { supportingUrls: currentSupportingUrls },
    trialResults: { resultsData: currentResultsData },
    trialNotes: { notesData: currentNotesData },
  } = updatedCurrentData;
  const {
    trialSummary: { supportingUrls: ingestedSupportingUrls },
    trialResults: { resultsData: ingestedResultsData },
    trialNotes: { notesData: ingestedNotesData },
  } = ingestedApiData;

  switch (key) {
    case 'trialSummary': {
      updatedCurrentData = {
        ...updatedCurrentData,
        patientPopulation: {
          ...updatedCurrentData.patientPopulation,
          patientGender,
          id: patientPopulationId,
        },
        trialResults: {
          ...updatedCurrentData.trialResults,
          resultsData: checkForIngestionAction(currentResultsData)
            ? formatIngestedData(apiResultsData, ingestedResultsData)
            : [...apiResultsData],
        },
        trialNotes: {
          ...updatedCurrentData.trialNotes,
          notesData: checkForIngestionAction(currentNotesData)
            ? formatIngestedData(apiNotesData, ingestedNotesData)
            : [...apiNotesData],
        },
      };
      break;
    }
    case 'trialResults': {
      updatedCurrentData = {
        ...updatedCurrentData,
        trialContacts: {
          ...updatedCurrentData.trialContacts,
          isContactReviewed: updatedApiData.trialContacts.isContactReviewed,
        },
        tempTrialResults: {},
      };
      break;
    }
    case 'trialNotes': {
      updatedCurrentData = {
        ...updatedCurrentData,
        trialSummary: {
          ...updatedCurrentData.trialSummary,
          supportingUrls: checkForIngestionAction(currentSupportingUrls)
            ? formatIngestedData(currentSupportingUrls, ingestedSupportingUrls)
            : [...apiSupportingUrls],
        },
        trialContacts: {
          ...updatedCurrentData.trialContacts,
          isContactReviewed: updatedApiData.trialContacts.isContactReviewed,
        },
        tempTrialNotes: {},
      };
      break;
    }
    case 'trialContacts': {
      updatedCurrentData = {
        ...updatedCurrentData,
        trialTiming: {
          ...updatedCurrentData.trialTiming,
          identifiedSites,
        },
      };
      break;
    }
    default:
      break;
  }
  // resetting and setting data in results and notes tab since we have separated out the APIS
  if (currentTab === 'TrialResults') {
    updatedApiData.trialResults = {
      ...updatedCurrentData?.trialResults,
    };
  } else {
    updatedCurrentData.trialResults = {
      key: '',
      resultsData: [],
    };
  }
  if (currentTab === 'TrialNotes') {
    updatedApiData.trialNotes = {
      ...updatedCurrentData?.trialNotes,
    };
  } else {
    updatedCurrentData.trialNotes = {
      key: '',
      notesData: [],
    };
  }

  return {
    updatedCurrentData,
    updatedOriginalData: { ...updatedApiData },
  };
};

export const getNextIngestedTab = (tabs, selected) => {
  const activeTabIndex = tabs.findIndex(tab => tab.value === selected);
  const ingestedTab = tabs.find((tab, index) => tab.hasIngestedData && index > activeTabIndex);
  return {
    ingestedTab,
    activeTabIndex,
  };
};

export const updateTrialStampingDetails = (
  { lastFullReviewDate, lastFullReviewedBy, nextFullReviewDate },
  current = trialFeaturesInitialState,
  original = trialFeaturesInitialState,
) => {
  const updateStampDatesForObject = (object = trialFeaturesInitialState) => ({
    ...object,
    trialSummary: {
      ...object.trialSummary,
      lastFullReviewDate,
      lastFullReviewedBy,
      nextFullReviewDate,
    },
  });
  return {
    current: updateStampDatesForObject(current),
    original: updateStampDatesForObject(original),
  };
};

export const getTrialStampDates = ({
  lastFullReviewDate,
  lastFullReviewedBy,
  nextFullReviewDate,
}) => {
  return {
    lastFullReviewDate,
    lastFullReviewedBy,
    nextFullReviewDate,
  };
};
