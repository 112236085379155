let userGroup = localStorage.getItem('usergroup');
let menuItems = [];
if (userGroup === 'RegIntelUser') {
  menuItems['RegIntelUser'] = [
    { name: 'Dashboard', link: 'dashboard' },
    { name: 'Administrator', link: 'administrator' },
    { name: 'Registry', link: 'registry' },
    { name: 'Source Documents', link: 'sourceDocument' },
    { name: 'Country', link: 'country' },
    { name: 'Requirement', link: 'requirement' },
    { name: 'Ancillary Data', link: 'ancillaryData/sourceName' },
  ];
} else {
  menuItems['StarUser'] = [
    { name: 'Administrator', link: 'administrator' },
    { name: 'Clinical Trials', link: 'clinicalTrials' },
    { name: 'Drugs', link: 'drugs' },
    { name: 'Company', link: 'company' },
    { name: 'Person', link: 'person' },
    { name: 'Organization', link: 'organization' },
    { name: 'Sponsors', link: 'sponsors' },
    { name: 'Ancillary Data', link: 'ancillaryData/armType' },
    { name: 'Task', link: 'task' },
    { name: 'Ancillary Request', link: 'addAncillaryData' },
    { name: 'Reporting', link: 'reporting' },
  ];
}

export const dashboards = menuItems;
