import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { DROPDOWN_TYPES, TEXT_LIMIT } from '../../../../utils/generic/constants';

export const validate = (values, isValidate) => {
  const errors = {};
  const internalComments = values.internalComments?.replace(/(\r\n|\n|\r)/gm, '').trim();
  if (values.internalComments?.trim() && internalComments?.trim().length > TEXT_LIMIT[7000]) {
    errors.internalComments = 'Please enter only 7000 characters';
  }
  if (isValidate) {
    if (values?.countries === null) {
      errors.applicableCountry = 'Please enter applicable country';
    }
    if (values?.applicableCountry === null) {
      errors.applicableCountry = 'Please enter applicable country';
    }
  }
  return errors;
};

export const initialValues = {
  id: 0,
  reasonForUpdate: null,
  reasonForUpdateDescription: '',
  executiveSummary: '',
};

export const layoutSchema = {
  layout: 'row',
  className: 'general-information-container',
  spacing: 2,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'applicableCountry',
      className: 'applicableCountry',
      size: 5,
    },
    {
      layout: 'internalComments',
      size: 12,
    },
  ],
};

export const formSchema = {
  applicableCountry: {
    name: 'applicableCountry',
    type: fieldTypes.select_async,
    label: 'To what country or region is this record applicable?',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      // showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMandatory: true,
    },
  },
  internalComments: {
    name: 'internalComments',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 10,
      rowsMax: 5,
      cols: 150
    },
  },
  empty: {},
};
