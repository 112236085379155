import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { DROPDOWN_TYPES } from '../../../../utils/generic/constants';

export const validate = (values, isValidate, isOtherTab) => {
  const errors = {};
  const toCheckauthorize = isOtherTab
    ? values?.registryAuthorizedInfoModel?.map(x => x.authorizedInformation)
    : values?.registryAuthorizedInfoModel;
  const toCheckResponsible = isOtherTab
    ? values?.registryResponsibleInfoModel?.map(x => x.responsibleInformation)
    : values?.registryResponsibleInfoModel;

  const isOthersAuhtorizeSelected = toCheckauthorize?.some(
    value => value?.value?.trim() === 'Other (describe below)',
  );
  const isOthersResponsibleSelected = toCheckResponsible?.some(
    value => value?.value?.trim() === 'Other (describe below)',
  );

  if (
    values.othersAuthorizedForInformation?.trim() &&
    values.othersAuthorizedForInformation.length > 400
  ) {
    errors.othersAuthorizedForInformation = 'Please enter only 400 characters';
  }
  if (
    values.othersResponsibleForInformation?.trim() &&
    values.othersResponsibleForInformation.length > 400
  ) {
    errors.othersResponsibleForInformation = 'Please enter only 400 characters';
  }
  if (values.dataRestriction?.trim() && values.dataRestriction.length > 400) {
    errors.dataRestriction = 'Please enter only 400 characters';
  }
  if (isValidate) {
    if (!values.registryResponsibleInfoModel?.length) {
      errors.registryResponsibleInfoModel = 'Please select who is responsible';
    }
    if (!values.dataRestriction?.trim()) {
      errors.dataRestriction = 'Please enter data restriction';
    }
    if (isOthersResponsibleSelected && !values.othersResponsibleForInformation?.trim()) {
      errors.othersResponsibleForInformation = 'Please enter other responsible information';
    }
    if (isOthersAuhtorizeSelected && !values.othersAuthorizedForInformation?.trim()) {
      errors.othersAuthorizedForInformation = 'Please enter other authorize information';
    }
  }
  return errors;
};

export const initialValues = {
  registryResponsibleInfoModel: null,
  othersResponsibleForInformation: '',
  dataRestriction: '',
  othersAuthorizedForInformation: '',
  registryAuthorizedInfoModel: null,
};

export const layoutSchema = {
  layout: 'row',
  className: 'record-update-container',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: 'reason-for-update',
      spacing: 3,
      type: fieldTypes.select,
      options: [],
      props: {
        height: 'ht-lg',
      },
      content: [
        {
          layout: 'registryResponsibleInfoModel',
          size: 4,
          className: '',
        },
        {
          layout: 'othersResponsibleForInformation',
          size: 8,
          className: 'singleLine',
        },
      ],
    },
    {
      layout: 'row',
      size: 12,
      className: 'reason-for-update',
      spacing: 3,
      type: fieldTypes.select,
      options: [],
      props: {
        height: 'ht-lg',
      },
      content: [
        {
          layout: 'registryAuthorizedInfoModel',
          size: 4,
          className: '',
        },
        {
          layout: 'othersAuthorizedForInformation',
          size: 8,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      size: 12,
      className: 'reason-for-update',
      spacing: 3,
      type: fieldTypes.select,
      options: [],
      props: {
        height: 'ht-lg',
      },
      content: [
        {
          layout: 'dataRestriction',
          size: 10,
          className: '',
        },
      ],
    },
  ],
};

export const formSchema = {
  registryResponsibleInfoModel: {
    name: 'registryResponsibleInfoModel',
    type: fieldTypes.select_async,
    label: 'WHO IS RESPONSIBLE FOR ENTERING THE INFORMATION?',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMulti: true,
      showSelectedData: true,
      isMandatory: true,
    },
  },
  othersResponsibleForInformation: {
    name: 'othersResponsibleForInformation',
    type: fieldTypes.text,
    label: 'IF OTHER - WHO ELSE IS RESPONSIBLE FOR ENTERING THE INFORMATION (OR N/A)?',
    props: {
      isMandatory: false,
    },
  },
  dataRestriction: {
    name: 'dataRestriction',
    type: fieldTypes.textArea,
    label: 'ARE THERE ANY RESTRICTIONS TO WHO CAN ENTER DATA ON THE REGISTRY (OR N/A)?',
    props: {
      rowsMin: 10,
      rowsMax: 5,
      cols: 150,
      isMandatory: true,
    },
  },
  registryAuthorizedInfoModel: {
    name: 'registryAuthorizedInfoModel',
    type: fieldTypes.select_async,
    label: 'WHO IS AUTHORIZED TO ENTER THE INFORMATION?',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMulti: true,
      showSelectedData: true,
    },
  },
  othersAuthorizedForInformation: {
    name: 'othersAuthorizedForInformation',
    type: fieldTypes.text,
    label: 'IF OTHER - WHO ELSE IS AUTHORIZED TO ENTER THE INFORMATION? (OR N/A) ',
    props: {
      isMandatory: false,
    },
  },
};
