import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const ValidationErrorOnSubmit = props => {
  const { callback, formik } = props;

  const effect = () => {
    if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
      return callback(formik);
    }
  };
  useEffect(effect, [formik.submitCount, formik.isSubmitting]);

  return <></>;
};

ValidationErrorOnSubmit.propTypes = {
  formik: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
};

export default ValidationErrorOnSubmit;
